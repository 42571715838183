import { AdminTransferStatementApi } from 'http/modules/admin_transfer_statement'
import { TransferStatementPrintPageStore } from 'pages/transfer_statement_print_page/transfer_statement_print_page_store'

export class AdminTransferStatementPrintService {
  /**
   * @param monthlyTransferId
   */
  static async loadTransferStatement(monthlyTransferId: number): Promise<void> {
    try {
      TransferStatementPrintPageStore.updateIsLoading(true)
      const response = (await AdminTransferStatementApi.show(monthlyTransferId)).data
      TransferStatementPrintPageStore.updateTransferStatement(response.transfer_statement)
      TransferStatementPrintPageStore.updateStartCalcPaidDate(response.start_calc_paid_date)
      TransferStatementPrintPageStore.updateEndCalcPaidDate(response.end_calc_paid_date)
    } finally {
      TransferStatementPrintPageStore.updateIsLoading(false)
    }
  }
}
