import {
  CreateChildParams,
  FacilityParentApi,
  isAxiosError,
  isConfirmationError,
  isConfirmationWarning,
} from 'http/modules/facility_parent'
import { ParentsService } from 'pages/parents_page/services/parents_service'
import { ParentsEditPageStore } from 'pages/parents_page/parent_edit/parent_edit_page_store'
import { EditableParent, InputConfirmationError, Parent } from 'pages/parents_page/model'
import { SnackbarService } from 'shared/services/snackbar_service'
import { FacilityParentChildApi } from 'http/modules/facility_parent_child_api'
import { DialogService } from 'shared/services/dialog_service'
import { FormSubmittingService } from 'pages/parents_page/services/form_submitting_service'
import { groupBy } from 'shared/utils/groupBy'

type EditParentsOption = {
  onValidate: () => boolean
  onSuccess: () => void
  id: number
}

export class ParentEditService {
  /**
   *  登録ボタンを押した際の処理
   *  ParentImportRequestApi.createへのリクエストを行う前に、重複チェック、エラーチェックをする
   */
  // eslint-disable-next-line max-lines-per-function
  static startUpdateConfirmation = async (options: EditParentsOption) => {
    if (ParentsEditPageStore.parent === null) {
      return
    }

    // NOTE: サーバーと通信しないタイプのバリデーションを行う
    if (!options.onValidate()) return
    ParentsEditPageStore.updateIsDisabled(true)

    try {
      const requestBody = {
        id: ParentsEditPageStore.parent.id,
        first_name: ParentsEditPageStore.parent.firstName,
        last_name: ParentsEditPageStore.parent.lastName,
        code: ParentsEditPageStore.parent.code,
        school_parent_children:
          ParentsEditPageStore.parent.schoolParentChildren.map<CreateChildParams>((child) => ({
            id: child.id,
            first_name: child.firstName,
            code: child.code,
            school_class_id: child.schoolClassId ? Number(child.schoolClassId) : undefined,
          })),
      }
      await FacilityParentApi.inputConfirmation(ParentsService.getTargetFacilityId(), [requestBody])
      // NOTE: updateするだけ。重複・エラーの場合はcatchされる
      ParentEditService.startUpdate(options.onSuccess)
    } catch (e) {
      if (!isAxiosError(e) || e.response === undefined || e.response.status === 500) {
        DialogService.openMessage({
          message: ['不正なエラーが発生しました。しばらく経ってからお試しください。'],
          title: 'エラー',
        })
        return
      }

      const { error, warn } = (e.response.data as InputConfirmationError).errors
      if (isConfirmationError(e)) {
        ParentEditService.setErrors(error)
      } else if (isConfirmationWarning(e)) {
        ParentEditService.setWarns(warn, options.onSuccess, ParentsEditPageStore.parent)
      }
    } finally {
      ParentsEditPageStore.updateIsDisabled(false)
    }
  }

  // eslint-disable-next-line max-lines-per-function
  static async loadParent(): Promise<void> {
    try {
      const parent = (
        await FacilityParentApi.show({
          facilityId: ParentsService.getTargetFacilityId(),
          parentId: ParentsService.getTargetParentId(),
        })
      ).data

      // 既存の情報 (シャローコピー)
      const currentParent = ParentsEditPageStore.parent
        ? {
            ...ParentsEditPageStore.parent,
            schoolParentChildren: ParentsEditPageStore.parent.schoolParentChildren,
          }
        : null

      const editableParent = ParentEditService.toBeEditableParent(parent)

      ParentsEditPageStore.updateParent({
        ...currentParent,
        id: editableParent?.id ?? currentParent?.id,
        temporaryId: currentParent?.temporaryId ?? editableParent.temporaryId ?? -1,
        firstName: currentParent?.firstName ?? editableParent.firstName ?? '',
        lastName: currentParent?.lastName ?? editableParent.lastName ?? '',
        code: currentParent?.code ?? editableParent.code ?? '',
        canBeDeletable: editableParent.canBeDeletable,
        canAddChildren: editableParent.canAddChildren,
        lineConnected: editableParent.lineConnected,
        schoolParentChildren: (
          currentParent?.schoolParentChildren ?? editableParent.schoolParentChildren
        ).map((schoolParentChild, i) => {
          const editableChild = editableParent.schoolParentChildren[i] ?? null
          if (!editableChild) {
            return schoolParentChild
          }
          return {
            ...schoolParentChild,
            firstName: editableChild.firstName ?? schoolParentChild.firstName ?? '',
            canBeDeletable: editableChild.canBeDeletable,
            codmonId: editableChild.codmonId,
          }
        }),
      })
    } catch {
      SnackbarService.open('保護者情報の取得に失敗しました', 'error', {
        variant: 'flat',
        showIcon: true,
        location: 'bottom right',
      })
    }
  }

  private static toBeEditableParent = (parent: Parent) => ({
    id: parent.id,
    temporaryId: ParentsEditPageStore.generateParentId(),
    firstName: parent?.first_name,
    lastName: parent?.last_name,
    code: parent?.code,
    lineConnected: parent?.line_connected,
    canBeDeletable: parent.can_be_deletable,
    canAddChildren: parent.can_add_children,
    schoolParentChildren: parent?.school_parent_children.map((child) => ({
      id: child.id,
      temporaryId: ParentsEditPageStore.generateChildId(),
      firstName: child.first_name,
      code: child.code,
      canBeDeletable: child.can_be_deletable,
      schoolClassId: `${child.school_class.id}`,
      codmonId: child.codmon_id,
    })),
  })

  /**
   *  FacilityParentApi.createへのリクエストを行う
   *  この前段で重複チェック、エラーチェックは済んでいる
   *  @params onSuccess 登録成功した際の処理
   */
  private static startUpdate = async (onSuccess: () => void): Promise<void> => {
    const facilityId = ParentsService.getTargetFacilityId()
    const parent = ParentsEditPageStore.parent
    if (!parent || !parent.id) return

    try {
      const requestBody = {
        first_name: parent.firstName,
        last_name: parent.lastName,
        code: parent.code,
        school_parent_children: parent.schoolParentChildren.map<CreateChildParams>((child) => ({
          id: child.id,
          first_name: child.firstName,
          code: child.code,
          school_class_id: child.schoolClassId ? Number(child.schoolClassId) : undefined,
        })),
      }

      // eslint-disable-next-line no-unused-expressions
      ;(await FacilityParentApi.update(facilityId, parent.id, requestBody)).data
      onSuccess()
    } catch (e) {
      if (!isAxiosError(e) || e.response === undefined || e.response.status === 500) {
        DialogService.openMessage({
          message: ['不正なエラーが発生しました。しばらく経ってからお試しください。'],
          title: 'エラー',
        })
      }
    }
  }

  // eslint-disable-next-line max-lines-per-function
  static setErrors = (errors: InputConfirmationError['errors']['error']) => {
    if (ParentsEditPageStore.parent === null || errors.length === 0) {
      ParentsEditPageStore.updateParent({
        ...ParentsEditPageStore.parent,
        errors: {
          attributeName: '',
          errorType: '',
        },
        schoolParentChildren: ParentsEditPageStore.parent?.schoolParentChildren.map((child) => ({
          ...child,
          errors: {
            attributeName: '',
            errorType: '',
          },
        })),
      } as EditableParent)
    } else {
      const parentErrors = errors.filter(
        ({ resource_name, attribute_name }) =>
          resource_name === 'school_parent' && attribute_name === 'code'
      )
      const childrenErrors = groupBy(
        errors.filter((error) => error.resource_name === 'school_parent_child'),
        (error) => error.child_index ?? '-1'
      )
      // 親の errors にエラー情報を付加させる
      ParentsEditPageStore.updateParent({
        ...ParentsEditPageStore.parent,
        errors: {
          attributeName: (parentErrors && parentErrors[0]?.attribute_name) ?? '',
          errorType: (parentErrors && parentErrors[0]?.error_type) ?? '',
        },
        schoolParentChildren: ParentsEditPageStore.parent.schoolParentChildren.map(
          (child, indexInChild) => ({
            ...child,
            errors: {
              attributeName:
                (childrenErrors[indexInChild] && childrenErrors[indexInChild][0]?.attribute_name) ??
                '',
              errorType:
                (childrenErrors[indexInChild] && childrenErrors[indexInChild][0]?.error_type) ?? '',
            },
          })
        ),
      })
    }
  }

  /**
   *  FacilityParentApi.input_confirmationへのリクエスト後、重複エラーが発生した際の処理
   *  @params parents 登録する親の情報
   */
  // eslint-disable-next-line max-lines-per-function
  private static setWarns = (
    warns: InputConfirmationError['errors']['warn'],
    onSuccess: () => void,
    parent: EditableParent
  ) => {
    const {
      duplicateNamesOnInputs,
      duplicateParentAndChildNameOnDb,
      duplicateParentNamesBetweenNewRecordAndSavedRecordOnInputs,
      duplicateParentNamesBetweenNewRecordAndSavedRecordOnDb,
    } = FormSubmittingService.groupingWarns(warns)

    switch (true) {
      // A: 保護者・子どもの重複がある場合
      case duplicateParentAndChildNameOnDb.length > 0:
        FormSubmittingService.handleDuplicateParentAndChildNameWarns({
          warn: duplicateParentAndChildNameOnDb,
          execute: () => ParentEditService.startUpdate(onSuccess),
          parents: [parent],
        })
        break
      // A: 保護者・子どもの重複がある場合
      case duplicateNamesOnInputs.length > 0:
        FormSubmittingService.handleDuplicateParentAndChildNameWarns({
          warn: duplicateNamesOnInputs,
          execute: () => ParentEditService.startUpdate(onSuccess),
          parents: [parent],
        })
        break
      // B: 保護者のみの重複がある場合
      case duplicateParentNamesBetweenNewRecordAndSavedRecordOnInputs.length > 0:
        FormSubmittingService.handleDuplicateParentNamesBetweenNewRecordAndSavedRecordWarns({
          warn: duplicateParentNamesBetweenNewRecordAndSavedRecordOnInputs,
          execute: () => ParentEditService.startUpdate(onSuccess),
          parents: [parent],
        })
        break
      // B: 保護者のみの重複がある場合
      case duplicateParentNamesBetweenNewRecordAndSavedRecordOnDb.length > 0:
        FormSubmittingService.handleDuplicateParentNamesBetweenNewRecordAndSavedRecordWarns({
          warn: duplicateParentNamesBetweenNewRecordAndSavedRecordOnDb,
          execute: () => ParentEditService.startUpdate(onSuccess),
          parents: [parent],
        })
        break
      default:
        DialogService.openMessage({
          message: ['警告の表示に失敗しました。しばらく経ってからお試しください。'],
          title: 'エラー',
        })
    }
  }

  static deleteParent = async (facilityId: number, parentId: number) => {
    await FacilityParentApi.deleteParent({ facilityId, parentId }).catch((error) => error.response)
  }

  static deleteChild = async (facilityId: number, childId: number) => {
    await FacilityParentChildApi.delete(facilityId, childId).catch((error) => error.response)
  }
}
