import { TopPageStore } from '../top_page_store'
import { InvoiceStatusWithBeforeRegister } from '../models'

/**
 * 請求書関連のモーダルダイアログに関するロジックを持ちます
 */
export class InvoiceModalService {
  static openInvoicesModal(parentId: number): void {
    TopPageStore.updateInvoicesModalState({ isShow: true, parentId })
  }

  static closeInvoicesModal(): void {
    TopPageStore.updateInvoicesModalState({ isShow: false, parentId: undefined })
  }

  static openInvoiceFormModal(parentId: number, status: InvoiceStatusWithBeforeRegister): void {
    TopPageStore.updateInvoiceFormModalState({ isShow: true, parentId, status })
  }

  static closeInvoiceFormModal(): void {
    TopPageStore.updateInvoiceFormModalState({
      isShow: false,
      parentId: undefined,
      status: 'before_register',
    })
  }
}
