<!-- 保護者一括登録 重複している保護者・子どもがいる場合に表示するメッセージ -->
<template>
  <div>
    <span class="text-red font-weight-bold">
      以下の保護者・子どもが重複しています。このまま保存しますか？
    </span>
    <div class="pt-4 pb-5">
      <template v-for="(error, index) in duplicatedErrors" :key="index + 'name'">
        <v-row style="margin: -1px 0">
          <v-col cols="2" class="grey-out border pa-0">
            <p class="grid-content__text text-center ma-4">{{ index + 1 }}組目</p>
          </v-col>
          <v-col cols="2" class="border pa-0">
            <p class="grid-content__text text-center ma-4">保護者名</p>
            <v-divider></v-divider>
            <p class="grid-content__text text-center ma-4">子ども名</p>
          </v-col>
          <v-col cols="8" class="border pa-0 border-eight">
            <p class="grid-content__text text-left ma-4">
              {{ getParentName(error[0]) }}
            </p>
            <v-divider></v-divider>
            <div class="d-flex px-4">
              <span
                :key="error[0].error_detail.parent_id"
                class="grid-content__text text-left my-4"
              >
                {{ getChildName(error[0]) }}
              </span>
            </div>
          </v-col>
        </v-row>
      </template>
    </div>
    <div>
      入力間違いの場合は、修正してください。
      <br />
      同姓同名の場合は、このまま保存してください。
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { DuplicationWarning } from 'pages/import_parents_page/model'

export default defineComponent({
  props: {
    duplicatedErrors: Array as PropType<DuplicationWarning[][]>,
  },
  setup() {
    const getParentName = (parent?: DuplicationWarning) =>
      parent
        ? `${parent.error_detail.parent_last_name} ${parent.error_detail.parent_first_name}さん`
        : '-'
    const getChildName = (parent?: DuplicationWarning) =>
      parent && parent.error_detail.child_name ? `${parent.error_detail.child_name}さん` : '-'

    return {
      getParentName,
      getChildName,
    }
  },
})
</script>

<style scoped>
.grey-out {
  background: #f6f6f5;
  pointer-events: none;
}
.border {
  border: 1px solid #e0e0e0;
  border-right: 0px;
}
.border-right {
  border-right: 1px solid #e0e0e0;
}
.grid-content__text {
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  font-weight: 500;
}
</style>
