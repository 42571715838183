import { reactive } from 'vue'
import { CsvImportColumnCandidate } from 'shared/components/import_panel/models'
import { StoreHelperService } from 'shared/services/store_helper_service'

type ImportInvoicesPageState = {
  columnCandidates: CsvImportColumnCandidate[]
  targetYear: string
  targetMonth: string
}

const state = reactive<ImportInvoicesPageState>({
  columnCandidates: [],
  /** '--' または yyyy */
  targetYear: '--',
  /** '--' またはMM */
  targetMonth: '--',
})

export class ImportInvoicesPageStore {
  static get columnCandidates(): CsvImportColumnCandidate[] {
    return state.columnCandidates
  }

  static updateColumnCandidates(columnCandidates: CsvImportColumnCandidate[]): void {
    StoreHelperService.updateArrayState(state.columnCandidates, columnCandidates)
  }

  static get targetYear(): string {
    return state.targetYear
  }

  static updateTargetYear(targetYear: string): void {
    state.targetYear = targetYear
  }

  static get targetMonth(): string {
    return state.targetMonth
  }

  static updateTargetMonth(targetMonth: string): void {
    state.targetMonth = targetMonth
  }
}
