<template>
  <span>
    <v-card-title class="text-h5 font-weight-regular text-white bg-primary-lighten-1 px-6">
      CSV・Excelの記入方法について
    </v-card-title>
    <v-card-text class="text-h6 font-weight-bold text-black px-6 mt-6"> CSVの記入方法 </v-card-text>
    <v-card-text class="text-body-1 text-black px-6">
      <span class="font-weight-bold">請求内容ごとに新しい行を下に追加します。</span><br />
      子ども1人に対する請求内容が複数行にまたがる点にご注意ください。<br />
      他システムとの連携時などに便利です。
    </v-card-text>
    <div>
      <S3Image path="/invoice_import/format_csv.png" class="px-6 mb-2" />
    </div>
    <v-card-text class="text-h6 font-weight-bold text-black px-6 mt-8">
      Excelの記入方法
    </v-card-text>
    <v-card-text class="text-body-1 text-black px-6">
      <span class="font-weight-bold"
        >請求内容マスタ設定に登録されている項目の列が自動で挿入されます。</span
      ><br />
      単価・数量を埋めるだけで、記入が完了します。<br />
      マスタ登録していない請求内容の列を追加することもできます。
    </v-card-text>
    <div>
      <S3Image path="/invoice_import/format_excel.png" class="px-6 mb-2" />
    </div>
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import S3Image from 'shared/components/s_3_image/S3Image.vue'

export default defineComponent({
  components: {
    S3Image,
  },
})
</script>
