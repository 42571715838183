import { CorporationApi } from 'http/modules/corporation'
import { CorporationFacilityApi } from 'http/modules/corporation_facility'
import { AvailableFacility, FacilityApi } from 'http/modules/facility'
import { AppStore } from 'shared/app_store'
import { Mode } from 'shared/models'
import { isAxiosError } from 'http/modules/invoice'

/**
 * ログイン中の法人アカウント情報に関するロジック
 */
export class AuthService {
  static async getAvailableFacilities(mode: Mode): Promise<AvailableFacility[]> {
    if (AppStore.hasFacilitiesLoaded) {
      return AppStore.availableFacilities
    }
    const availableFacilities = (
      await (() => {
        switch (mode) {
          case 'corporation':
            return CorporationFacilityApi.index('has_permission')
          case 'facility':
            return FacilityApi.index()
          default:
            throw Error
        }
      })()
    ).data.facilities
    AppStore.updateAvailableFacilities(availableFacilities)
    AppStore.updateHasFacilitiesLoaded(true)
    return AppStore.availableFacilities
  }

  static async hasCorporationPermission(): Promise<boolean> {
    try {
      await CorporationApi.self()
      return true
    } catch (error) {
      if (isAxiosError(error) && error.response !== undefined && error.response.status === 403) {
        return false
      }
      throw error
    }
  }
}
