<template>
  <div class="corporation-apply-navigation-button mb-5 ml-9">
    <Button
      v-show="isShowBackButton"
      color="normal"
      variant="tonal"
      class="corporation-back-button mr-3"
      @click="decrementPanelStep()"
    >
      戻る
    </Button>
    <Button v-show="isShowNextButton" @click="incrementPanelStep()"> 次へ </Button>
    <Button v-show="isShowRegisterButton" :disabled="isSubmitting" @click="registerCorporation()">
      登録する
    </Button>
    <div
      v-if="!isInitial && !isValid && getCorporationApplyPanelStep < 9"
      class="text-xs-h6 mt-3 text-error"
    >
      入力内容に誤りがあります。
    </div>
    <div v-for="message in errorMessages" :key="message" class="mt-3 ml-0 text-error">
      {{ message }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { CorporationApplyPageStore } from 'pages/corporation_apply_page/corporation_apply_page_store'
import { PanelStepService } from 'pages/corporation_apply_page/services/panel_step_service'
import { RegisterCorporationService } from 'pages/corporation_apply_page/services/register_corporation_service'
import Button from 'shared/components/Button.vue'

const props = defineProps<{
  oemPartnerId: string
}>()

const getCorporationApplyPanelStep = computed(() => CorporationApplyPageStore.panelStep)
const isShowBackButton = computed(() => PanelStepService.isShowBackButton())
const isShowNextButton = computed(() => PanelStepService.isShowNextButton())
const isShowRegisterButton = computed(() => PanelStepService.isShowRegisterButton())
const isValid = computed(
  () =>
    CorporationApplyPageStore.validState[CorporationApplyPageStore.getCurrentPanelInfo().refName]
)
const isInitial = computed(
  () =>
    CorporationApplyPageStore.initializedState[
      CorporationApplyPageStore.getCurrentPanelInfo().refName
    ]
)
const errorMessages = computed(() => CorporationApplyPageStore.errorMessages)
const isSubmitting = computed(() => CorporationApplyPageStore.isSubmitting)

const moveScrollBarTop = () => {
  // スクロール位置をページの最上部に設定する。これをしないと前のページのスクロール位置が維持されてしまう
  const body = document.querySelector('body')
  if (body) body.scrollIntoView(true)
}
const incrementPanelStep = () => {
  if (!PanelStepService.incrementPanelStep()) return
  moveScrollBarTop()
}
const decrementPanelStep = () => {
  if (PanelStepService.decrementPanelStep()) return
  moveScrollBarTop()
}
const registerCorporation = () => {
  RegisterCorporationService.registerCorporation(props.oemPartnerId)
}
</script>
