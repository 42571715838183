<template>
  <AppFrame>
    <template #header>
      <CorporationHeader></CorporationHeader>
    </template>
    <v-sheet color="transparent" width="100%" max-width="1092px" class="mx-auto mt-6">
      <v-row class="mx-0 mt-0 mb-4">
        <v-col class="pa-0">
          <TitleArea />
        </v-col>
      </v-row>
      <v-row class="mx-0 mt-8 mb-4">
        <v-col class="pa-0">
          <SummaryArea />
        </v-col>
      </v-row>
      <v-row class="mx-0 mb-6">
        <v-col class="pa-0">
          <InvoiceSearchForm />
        </v-col>
      </v-row>
      <div class="mt-6 summary-list-area">
        <FacilitySummaryList></FacilitySummaryList>
      </div>
    </v-sheet>
    <Dialog />
  </AppFrame>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AppFrame from 'shared/components/AppFrame.vue'
import CorporationHeader from 'shared/components/header/CorporationHeader.vue'
import { InvoiceManagementService } from './services/invoice_management_service'
import TitleArea from './areas/title_area/TitleArea.vue'
import SummaryArea from './areas/summary_area/SummaryArea.vue'
import InvoiceSearchForm from './areas/invoice_search_form/InvoiceSearchForm.vue'
import FacilitySummaryList from './areas/facility_summary_list/FacilitySummaryList.vue'
import Dialog from 'shared/components/Dialog.vue'
import { MetaService } from 'shared/services/meta_service'

export default defineComponent({
  components: {
    AppFrame,
    CorporationHeader,
    FacilitySummaryList,
    TitleArea,
    InvoiceSearchForm,
    Dialog,
    SummaryArea,
  },
  setup() {
    MetaService.addTitle({ mode: 'corporation', page: 'invoiceManagement' })
    InvoiceManagementService.loadInitialData()
  },
})
</script>
<style lang="scss" scoped>
$app-bar-height: 64px;
$top-area-height: 176px;
.summary-list-area {
  height: calc(100vh - $top-area-height - $app-bar-height);
  overflow-y: auto;
}
</style>
