<template>
  <AppFrame :has-browser-alert="!isMobile()">
    <div class="signInPageContents">
      <div class="inner">
        <div class="sign-TitleArea signInPageTitleArea">
          <h1
            v-if="oemPartnerLogoPath"
            :class="{
              'sign-ServiceLogo': true,
              ...(/enpay_logo/.test(oemPartnerLogoPath)
                ? { signInPageServiceLogo: true }
                : { signInPageOemServiceLogo: true }),
            }"
          >
            <S3Image :path="oemPartnerLogoPath" />
          </h1>
          <h2 v-if="title" class="sign-Title">
            <span class="sign-Title_item">{{ title }}</span>
          </h2>
        </div>
        <ErrorAlert v-if="errorMessages.length > 0" :messages="errorMessages" />
        <SuccessAlert v-else-if="noticeMessages.length > 0" :messages="noticeMessages" />
        <div class="text-center">
          <slot />
        </div>
      </div>
    </div>
  </AppFrame>
</template>

<script setup lang="ts">
import AppFrame from 'shared/components/AppFrame.vue'
import S3Image from 'shared/components/s_3_image/S3Image.vue'
import ErrorAlert from 'shared/components/alert/ErrorAlert.vue'
import SuccessAlert from 'shared/components/alert/SuccessAlert.vue'
import { ref } from 'vue'
import { isMobile } from 'shared/utils/browserDetection'

const props = defineProps<{
  title?: string
  oemPartnerLogoPath?: string
  errorMessage?: string | string[]
  noticeMessage?: string | string[]
}>()

const errorMessages = ref<string[]>([])
const noticeMessages = ref<string[]>([])

if (props.errorMessage) {
  if (typeof props.errorMessage === 'string') {
    errorMessages.value = [props.errorMessage]
  } else {
    errorMessages.value = props.errorMessage
  }
} else if (props.noticeMessage) {
  if (typeof props.noticeMessage === 'string') {
    noticeMessages.value = [props.noticeMessage]
  } else {
    noticeMessages.value = props.noticeMessage
  }
}
</script>

<style lang="scss" scoped>
.sign-TitleArea {
  padding: 40px 0;
}
.signInPageTitleArea {
  padding-top: 24px;
}

.signInPageOemServiceLogo {
  width: 650px;
}

.signInPageServiceLogo {
  width: 150px;
}

.signInPageContents {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.signInPageContents .inner {
  margin: auto;
}
.signInPageContents .inner .text-center {
  margin-bottom: 24px;
}
</style>
