import { AxiosResponse } from 'axios'
import { HTTP } from '../common'

export type SchoolClass = {
  id: number
  display_order: number
  name: string
  selected: boolean
}

export type IndexResponse = {
  school_classes: SchoolClass[]
}

export class ClassApi {
  static index(
    facilityId: number,
    target_year_and_month?: string
  ): Promise<AxiosResponse<IndexResponse>> {
    if (!target_year_and_month) {
      return HTTP.get(`/facilities/${facilityId}/classes`)
    }
    return HTTP.get(
      `/facilities/${facilityId}/classes/?target_year_and_month=${target_year_and_month}`
    )
  }

  static create(facilityId: number, name: string): Promise<any> {
    return HTTP.post(`/facilities/${facilityId}/classes`, { name })
  }

  static update(facilityId: number, class_id: number, name: string): Promise<any> {
    return HTTP.patch(`/facilities/${facilityId}/classes/${class_id}`, { name })
  }

  static destroy(facilityId: number, class_id: any): Promise<any> {
    return HTTP.delete(`/facilities/${facilityId}/classes/${class_id}`)
  }
}
