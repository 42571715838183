import { computed, reactive, SetupContext } from 'vue'
import { YearMonthRange } from 'http/modules/corporations_transfer'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)

const selectedRange = reactive<YearMonthRange>({
  from: '',
  to: '',
})
/**
 * 不正な値（from > to）になった場合、後に選択された方を基準にfromとtoの値を合わせる。fromだけ未入力の場合はtoの値を入れる
 */
const correctRange = (changedFormName: 'from' | 'to') => {
  if (dayjs(selectedRange.from).isAfter(dayjs(selectedRange.to)) || !selectedRange.from) {
    // 変えた方のformの値を活かす
    if (changedFormName === 'from') {
      selectedRange.to = selectedRange.from
    } else if (changedFormName === 'to') {
      selectedRange.from = selectedRange.to
    }
  }
}
export type YearMonthRangeSelectorPropType = {
  startDate: dayjs.Dayjs
  endDate: dayjs.Dayjs
  defaultRange: Record<string, any>
  clearable: boolean
}
export default function useYearMonthRangeSelector(
  props: Readonly<YearMonthRangeSelectorPropType>,
  context: SetupContext
) {
  const selectableMonths = computed(() => {
    const months = []
    const endDate: dayjs.Dayjs = props.endDate || dayjs().tz('Asia/Tokyo')
    for (
      let yearMonth = endDate.endOf('month');
      yearMonth.isAfter(props.startDate.startOf('month'));
      yearMonth = yearMonth.subtract(1, 'month')
    ) {
      months.push(yearMonth.format('YYYY-MM'))
    }
    return months
  })
  const selectedFrom = computed({
    get: () => selectedRange.from,
    set: (value: string) => {
      selectedRange.from = value
      correctRange('from')
      context.emit('change', selectedRange)
    },
  })
  const selectedTo = computed({
    get: () => selectedRange.to,
    set: (value: string) => {
      selectedRange.to = value
      correctRange('to')
      context.emit('change', selectedRange)
    },
  })
  if (props.defaultRange) {
    selectedFrom.value = props.defaultRange.from
    selectedTo.value = props.defaultRange.to
  }
  return { selectableMonths, selectedFrom, selectedTo }
}
