<template>
  <div class="text-body-2">
    <p class="mb-4">
      本当に<strong>{{ parent.lastName }} {{ parent.firstName }}</strong
      >さんを削除しますか？
    </p>
    <p class="mb-4">
      削除した保護者は、当月（{{
        thisMonth
      }}月）から請求画面に表示されなくなります。<br />この操作は取り消せません。ご注意ください。
    </p>
    <InfoAlert text="当月以降お支払い済みの請求がある場合は、該当月に表示されます。" />
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { EditableParent } from 'pages/parents_page/model'
import { getMonth } from 'shared/utils/date'
import InfoAlert from 'shared/components/alert/InfoAlert.vue'

export default defineComponent({
  components: { InfoAlert },
  props: {
    parent: {
      type: Object as PropType<EditableParent>,
      required: true,
    },
  },
  setup() {
    const thisMonth = getMonth(new Date())

    return { thisMonth }
  },
})
</script>
<style scoped lang="scss">
.black-font {
  color: #333;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
</style>
