/**
 * https://github.com/turbolinks/turbolinks
 * @param queryParams クエリパラメータ
 */
export const pushHistory = (queryParams: string) => {
  const Turbolinks = (window as any).Turbolinks
  // NOTE: pushHistoryWithLocationAndRestorationIdentifier は、pushStateと同じ動きをする。
  Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(
    queryParams,
    Turbolinks.uuid()
  )
}

// https://github.com/turbolinks/turbolinks/blob/17719bb21941b9de6e230af731576afe1358b9ff/src/controller.ts#L70
export const visit = (location: string) => {
  const Turbolinks = (window as any).Turbolinks
  Turbolinks.controller.visit(location)
}
