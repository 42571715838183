<template>
  <SearchForm>
    <template #header-open>
      <div class="d-flex justify-end">
        <Button
          :color="'#FFFFFF'"
          class="font-weight-medium text-body-1 text-grey d-flex align-center"
          @click="toggleSearchPanelMode"
        >
          <span class="mr-1">{{ searchPanelModeToggleLabel }}</span>
          <v-icon>mdi-sync</v-icon>
        </Button>
        <v-divider vertical class="mx-1" />
      </div>
    </template>

    <template #header-close>
      <FilterSummary />
    </template>

    <div
      class="filter-container"
      :class="{ default: searchPanelMode === 'default', compact: searchPanelMode === 'compact' }"
    >
      <div class="class-filter">
        <ClassFilter :input-mode="searchPanelMode" />
      </div>
      <div class="line-status-filter"><LineStatusFilter /></div>
      <div class="name-filter"><NameFilter /></div>
    </div>
  </SearchForm>
</template>

<script setup lang="ts">
import ClassFilter from 'pages/top_page/components/ParentFilters/components/ClassFilter.vue'
import LineStatusFilter from 'pages/top_page/components/ParentFilters/components/LineStatusFilter.vue'
import NameFilter from 'pages/top_page/components/ParentFilters/components/NameFilter.vue'
import Button from 'shared/components/Button.vue'
import SearchForm from 'shared/components/search_form/SearchForm.vue'
import useSearchPanelMode from 'pages/top_page/components/ParentFilters/composables/use_search_panel_mode'
import FilterSummary from 'pages/top_page/components/ParentFilters/components/FilterSummary.vue'

const { searchPanelMode, searchPanelModeToggleLabel, toggleSearchPanelMode } = useSearchPanelMode()
</script>

<style scoped lang="scss">
.filter-container {
  display: grid;
  row-gap: 16px;
  column-gap: 16px;
  padding-bottom: 16px;
}

.default {
  grid-template-rows: repeat(2, max-content);
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    'class-filter class-filter'
    'line-status-filter name-filter';
}

.compact {
  grid-template-columns: 1fr 180px 200px;
  grid-template-areas: 'class-filter line-status-filter name-filter';
}

.class-filter {
  grid-area: class-filter;
}

.line-status-filter {
  grid-area: line-status-filter;
}

.name-filter {
  grid-area: name-filter;
}
</style>
