import axios from 'axios'

export const HTTP = axios.create({
  baseURL: '/api/v1/',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN':
      document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') ?? '',
  },
})
