<template>
  <div class="section_inner_title">
    {{ monthlyInvoiceData.target_year_and_month }}
    <div
      :class="{
        'color-paid float-right': monthlyInvoiceData.has_unpaid_invoice,
        'float-right': !monthlyInvoiceData.has_unpaid_invoice,
      }"
    >
      {{ monthlyInvoiceData.total_price }}
    </div>
  </div>
  <template v-for="invoice in monthlyInvoiceData.invoices" :key="invoice.id">
    <InvoiceItem
      :invoice-status="invoice.status"
      :credit-card-payment-url="invoice.payment_info.credit_card_url"
      :cvs-payment-url="invoice.payment_info.cvs_url"
      :line-pay-payment-url="invoice.payment_info.line_pay_url"
      :pay-pay-payment-url="invoice.payment_info.paypay_url"
      :au-pay-payment-url="invoice.payment_info.au_pay_url"
      :enpay-wallet-payment-url="invoice.payment_info.enpay_wallet_url"
      :use-enpay-wallet="invoice.payment_info.use_enpay_wallet"
      :mer-pay-payment-url="invoice.payment_info.mer_pay_url"
      :use-mer-pay="invoice.payment_info.use_mer_pay"
      :recent_parent_cvs_fee_apply_status="invoice.recent_parent_cvs_fee_apply_status"
    >
      <template #school_icon>
        <span class="icon">
          <S3Image :path="iconImage(invoice.status)" />
        </span>
      </template>
      <template #school_name>
        <span class="school_name">{{ invoice.school_name }}</span>
      </template>
      <template #invoice_total_price>
        <div>{{ invoice.total_price }}</div>
      </template>
      <template #invoice_status>
        <template v-if="invoice.status === 'paid'">
          <p>支払い日：{{ invoice.paid_at }}</p>
          <p v-if="invoice.payment_method">支払い方法:{{ invoice.payment_method }}</p>
        </template>
        <template v-else>
          <span class="badge-warning">{{ invoice.status_i18n }}</span>
        </template>
      </template>
      <template #invoice_items>
        <div>
          <div v-if="invoice.tax != '0円'" class="py-2">
            コンビニ支払い手数料
            <div class="float-right">
              {{ invoice.tax }}
            </div>
          </div>
          <template v-if="invoice.has_no_child">
            <div class="float-right">
              {{ invoice.invoice_items.total_price }}
            </div>
            <table>
              <thead>
                <tr>
                  <th class="text-left">{{ invoice.invoice_items.name_label }}</th>
                  <th>{{ invoice.invoice_items.count_label }}</th>
                  <th>{{ invoice.invoice_items.unit_price_label }}</th>
                  <th class="text-right">{{ invoice.invoice_items.subtotal_label }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="item in invoice.invoice_items.items" :key="item.id">
                  <tr>
                    <td>{{ item.name }}</td>
                    <td class="text-center">{{ item.count }}</td>
                    <td class="text-center">{{ item.unit_price }}</td>
                    <td class="text-right">{{ item.subtotal }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </template>
          <template v-for="child in invoice.school_parent_children" :key="child.id">
            {{ `${child.first_name} さん` }}
            <div class="float-right">
              {{ child.total_price }}
            </div>
            <table>
              <thead>
                <tr>
                  <th class="text-left">{{ child.name_label }}</th>
                  <th>{{ child.count_label }}</th>
                  <th>{{ child.unit_price_label }}</th>
                  <th class="text-right">{{ child.subtotal_label }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="item in child.items" :key="item.id">
                  <tr>
                    <td>{{ item.name }}</td>
                    <td class="text-center">{{ item.count }}</td>
                    <td class="text-center">{{ item.unit_price }}</td>
                    <td class="text-right">{{ item.subtotal }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </template>
          <template v-if="invoice.status === 'paid'">
            <div>
              <a class="button-normal" :href="invoice.receipt_url">領収書をメールで送る</a>
            </div>
          </template>
        </div>
      </template>
    </InvoiceItem>
  </template>
</template>
<script setup lang="ts">
import InvoiceItem from 'pages/line_invoice_history_page/components/InvoiceItem.vue'
import S3Image from 'shared/components/s_3_image/S3Image.vue'
import { MonthlyInvoiceData } from 'pages/line_invoice_history_page/model'

defineProps<{
  monthlyInvoiceData: MonthlyInvoiceData
}>()

const iconImage = (invoiceStatus: string) =>
  invoiceStatus === 'paid' ? '/icons/icon_school_blue.png' : '/icons/icon_school_black.png'
</script>
