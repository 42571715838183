<template>
  <div>
    <div class="header">
      <h2>クレジットカード</h2>
      <div class="header-contents">
        <div class="price-title">
          <div class="icon">
            <S3Image :path="'/icons/icon_card.png'" />
          </div>
          <h4>支払い金額</h4>
        </div>
        <div class="price">
          {{ totalPrice }}
          <span>円</span>
        </div>
      </div>
    </div>

    <div class="credit-card">
      <section class="section">
        <div class="section_inner">
          <noscript>
            <div class="errorMessage">
              <ul class="errorMessage_list">
                <li>JavaScript を有効にしてください</li>
              </ul>
            </div>
          </noscript>
          <ErrorMessageList :error-messages="errorMessages.token" />
          <ErrorMessageList :error-messages="errorMessages.card_no" />

          <h4>カード番号</h4>
          <input
            id="card_no"
            v-model="cardNo"
            placeholder="カード番号を入力してください"
            class="form-Item_inputText"
          />
        </div>
        <div class="section_inner">
          <ErrorMessageList :error-messages="errorMessages.holder_name" />
          <h4>カード名義（例: TARO ENPAY）</h4>
          <input
            id="holder_name"
            v-model="holderName"
            placeholder="カード名義を入力してください"
            class="form-Item_inputText"
          />
        </div>
        <div class="section_inner">
          <ErrorMessageList :error-messages="errorMessages.expire_date_month" />
          <ErrorMessageList :error-messages="errorMessages.expire_date_year" />

          <h4>有効期限</h4>
          <input
            id="expire_date_month"
            v-model="expireDateMonth"
            placeholder="MM"
            class="form-Item_inputText-sm"
          />
          月
          <input
            id="expire_date_year"
            v-model="expireDateYear"
            placeholder="YY"
            class="form-Item_inputText-sm"
          />
          年
        </div>
      </section>
      <div>
        <input id="shop_id" type="hidden" name="shop_id" :value="gmoShopId" autocomplete="off" />
      </div>

      <form
        id="new_credit_card_payment_form"
        ref="newCreditCardPaymentForm"
        :action="actionUrl"
        accept-charset="UTF-8"
        method="post"
        class="new_credit_card_payment_form"
        @submit="fillInToken($event)"
      >
        <input
          type="hidden"
          name="authenticity_token"
          :value="authenticityToken"
          autocomplete="off"
        />
        <section class="section">
          <div class="section_inner">
            <ErrorMessageList :error-messages="errorMessages.cvc" />
            <h4>セキュリティーコード</h4>
            <input
              id="cvc"
              v-model="cvc"
              type="password"
              name="credit_card_payment_form[cvc]"
              class="form-Item_inputText-sm"
            />

            <span>
              <a
                target="_blank"
                href="https://www.gmo-pg.com/service/mulpay/security/securitycode/"
                class="help"
                >？</a
              >
            </span>
          </div>
          <div>
            <input
              id="token"
              v-model="token"
              type="hidden"
              name="credit_card_payment_form[token]"
            />
          </div>
          <template v-if="!readTermsAndPrivacyPolicyAt">
            <div class="credit_card_payment_term_and_privacy_policy_area">
              利用規約・プライバシーポリシーに同意した上でご利用ください。
              <ul class="credit_card_payment_term_links">
                <li>
                  <a target="_blank" :href="termsOfServicesSchoolParentUrl">利用規約</a>
                </li>
                <li>
                  <a target="_blank" :href="privacyPolicyUrl">プライバシーポリシー</a>
                </li>
                <li>
                  <a target="_blank" :href="externalTransmissionsUrl">
                    利用者情報の外部送信について
                  </a>
                </li>
              </ul>
            </div>
            <div class="credit_card_payment_agree_box_area">
              <label>
                <input
                  type="checkbox"
                  class="form-Item_inputCheck credit_card_payment_agree_button"
                  autocomplete="off"
                  @click="updateSubmitting"
                />
                確認しました
              </label>
            </div>
          </template>
          <div class="section_inner">
            <input
              type="submit"
              name="commit"
              value="支払いをする"
              :disabled="isSubmitting"
              class="payment credit-card button-normal credit_card_payment_button-normal_disabled"
            />
          </div>
        </section>
      </form>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount, nextTick } from 'vue'
import ErrorMessageList from './components/ErrorMessageList.vue'
import S3Image from 'shared/components/s_3_image/S3Image.vue'
import ExternalLink from 'shared/consts/external_link'

const props = withDefaults(
  defineProps<{
    gmoShopId: string
    totalPrice: string
    actionUrl: string
    readTermsAndPrivacyPolicyAt?: string
    termsOfServicesSchoolParentUrl: string
    privacyPolicyUrl: string
    errorMessages: Record<
      'token' | 'card_no' | 'holder_name' | 'expire_date_month' | 'expire_date_year' | 'cvc',
      string[]
    >
    token?: string
    cvc?: string
    authenticityToken: string
  }>(),
  {
    token: '',
    cvc: '',
    readTermsAndPrivacyPolicyAt: '',
  }
)

const newCreditCardPaymentForm = ref<HTMLFormElement | null>(null)
const callbackFunctionName = `_callback_${Date.now()}` as const
// 利用規約及びプラポリの同意ボタンがある場合は非活性としておく
// TODO: isSubmitting という名前は適切ではないと思うが，これがどこで使われているかわからないので一旦これで。
const isSubmitting = ref(!props.readTermsAndPrivacyPolicyAt)
const token = ref(props.token)
const cardNo = ref('')
const holderName = ref('')
const expireDateMonth = ref('')
const expireDateYear = ref('')
const cvc = ref(props.cvc)
const expireDate = computed(() => `${expireDateYear.value}${expireDateMonth.value}`)

onMounted(() => {
  // GMO のコールバックはJSONPで呼ばれるため、グローバルに参照可能できる必要がある
  window[callbackFunctionName] = (response: {
    resultCode: string
    tokenObject: { token: string }
  }) => {
    const { resultCode, tokenObject } = response

    if (resultCode !== '000') {
      const code = Number(resultCode)
      let message = 'カード番号トークン取得時にエラーが発生しました。'
      if (code >= 100 && code <= 102) {
        message = 'カード番号の形式が正しくありません。'
      }
      if (code >= 110 && code <= 113) {
        message = '有効期限の形式が正しくありません。'
      }
      if (code >= 131 && code <= 132) {
        message = 'カード名義の形式が正しくありません。'
      }
      // eslint-disable-next-line no-alert
      alert(`${message}:${resultCode}`)
      isSubmitting.value = false
    } else {
      token.value = tokenObject.token

      nextTick(() => {
        if (newCreditCardPaymentForm.value) {
          newCreditCardPaymentForm.value.submit()
        }
      })
    }
  }
})
onBeforeUnmount(() => {
  delete window[callbackFunctionName]
})
const fillInToken = (e: Event) => {
  e.preventDefault()
  isSubmitting.value = true
  const regex = new RegExp(/^[A-Z\s]+$/)

  if (!regex.test(holderName.value)) {
    // eslint-disable-next-line no-alert
    alert('カード名義は半角ローマ字の大文字で入力してください。')
    isSubmitting.value = false
  } else {
    Multipayment.init(props.gmoShopId)
    Multipayment.getToken(
      {
        cardno: cardNo.value,
        expire: expireDate.value,
        securitycode: cvc.value,
        holdername: holderName.value,
        tokennumber: '1',
      },
      callbackFunctionName
    )
  }
}
const updateSubmitting = () => {
  isSubmitting.value = !isSubmitting.value
}

const externalTransmissionsUrl = ExternalLink.externalTransmissionsUrl
</script>
<style lang="scss" scoped>
.credit_card_payment {
  &_button-normal {
    &_disabled[disabled],
    &_disabled[disabled]:hover {
      background: #e2e2e2;
      color: #a1a1a1 !important;
      border: 1px solid #e2e2e2;
      cursor: default;
      opacity: initial;
    }
  }

  &_term_and_privacy_policy_area {
    background-color: #f6f6f6;
    padding: 16px;
    font-size: 14px;
  }

  &_term_links {
    margin-top: 8px;
    list-style-type: disc;

    li {
      margin-left: 24px;

      a {
        line-height: 21px;
        font-size: 14px;
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }

  &_agree_box_area {
    text-align: center;
    margin-top: 20px;
  }
}
</style>
