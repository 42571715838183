<template>
  <Button :color="buttonStyle" :loading="loading" :disabled="disabled" @click.stop="handleClick()">
    <span
      v-if="showBadge"
      class="count d-flex justify-center align-center mr-2 px-1"
      data-testid="count-btn-count"
    >
      {{ numCount }}
    </span>
    {{ text }}
  </Button>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import Button from 'shared/components/Button.vue'
import { ButtonStyle } from 'shared/models'

type CountButtonPropType = {
  disabled: boolean
  loading: boolean
  count: number
  text: string
  buttonStyle: ButtonStyle
}

export default defineComponent<CountButtonPropType>({
  components: {
    Button,
  },
  props: {
    disabled: Boolean,
    loading: Boolean,
    count: { type: Number, default: 0 },
    text: String,
    buttonStyle: String,
  },
  emits: ['click'],
  setup(props, context) {
    const handleClick = () => context.emit('click')

    const showBadge = computed(() => props.count > 0)
    const numCount = computed(() => (props.count > 999 ? '999+' : props.count.toString()))

    return {
      handleClick,
      showBadge,
      numCount,
    }
  },
})
</script>
<style scoped>
.v-btn .count {
  background: #fff;
  border-radius: 14px;
  color: #0068b7;
  display: inline-block;
  height: 24px;
  min-width: 24px;
}
</style>
