import { isFutureDate } from 'shared/utils/date'
import type { CorporationApplyForm } from '../models'
import dayjs from 'dayjs'
import { CorporationApplyPageStore } from 'pages/corporation_apply_page/corporation_apply_page_store'

const hankakuRegexp = /[ -~｡-ﾟ]/
const mailRegexp = /.+@.+\..+/
const zenkakuKanaRegexp = /^[ァ-ヶー]+$/
const dateDelimitedHyphenRegexp = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/
const postalCodeRegexp = /^[0-9]{3}[0-9]{4}$/
const addressKanaRegexp = /^([ァ-ヶー－]|[ー]|[０-９]|[Ａ-Ｚ]|[ａ-ｚ])+$/
const phoneNumberRegexp = /^0\d{1,4}-\d{1,5}-\d{1,5}$/
const corporationNumberRegexp = /^[0-9]{13}$/
const hankakuNumRegexp = /[0-9]/
const urlRegexp = /^(https?:)\/\/([\w.-]+)(.*)$/
const streetNumberRegexp = /^(?:[ー－−]|[０-９])+$/
const spaceRegexp = /\s/

/**
 * 法人情報登録画面のvalidationに関するロジックを持つ。
 */
/* eslint-disable max-lines */
export class CorporationValidatorsService {
  static isEmpty(name: string) {
    return (v: string) => !!v || `${name}を入力してください`
  }

  static isZenkaku() {
    return (v: string) => !hankakuRegexp.test(v) || '全角で入力してください'
  }

  static isZenkakuKana() {
    return (v: string) => zenkakuKanaRegexp.test(v) || '全角カナで入力してください'
  }

  static isDateDelimitedHyphenUntilToday() {
    return (v: string) => {
      if (!dateDelimitedHyphenRegexp.test(v)) {
        return '2020-01-01の形式で入力してください'
      }
      if (dayjs(v).unix() - dayjs().unix() > 0) {
        return '未来の日付は選択できません'
      }

      return true
    }
  }

  static isDateDelimitedHyphen() {
    return (v: string) => dateDelimitedHyphenRegexp.test(v) || '2020-01-01の形式で入力してください'
  }

  static isPostalCode() {
    return (v: string) => postalCodeRegexp.test(v) || '半角数字7桁で入力してください'
  }

  static isNotExceededMaxCharacters(char: string, maxNum: number, itemName = '') {
    return (
      (char && char.length <= maxNum) || `${itemName + maxNum.toString()}文字以下で入力してください`
    )
  }

  static isStreetName() {
    return (v: string) => streetNumberRegexp.test(v) || '全角数字とハイフンで入力してください'
  }

  static isAddressKana() {
    return (v: string) => addressKanaRegexp.test(v) || '全角英数カナで入力してください'
  }

  static isPhoneNumber() {
    return (v: string) =>
      phoneNumberRegexp.test(v) || '半角ハイフンと半角数字で合計13桁以内で入力してください'
  }

  static isHankakuNum(num: number) {
    return hankakuNumRegexp.test(num.toString()) || '半角数字で入力してください'
  }

  static isUrl(url: string) {
    return urlRegexp.test(url) || 'URLの形式で入力してください'
  }

  static includedSpace() {
    return (v: string) => !spaceRegexp.test(v) || 'スペースは入力しないでください'
  }

  static validateEmailMatch(
    name: 'email' | 'confirmEmail',
    email: string,
    confirmationEmail: string
  ) {
    return [
      this.isEmpty('メールアドレス'),
      this.includedSpace(),
      (v: string) => mailRegexp.test(v) || 'メールアドレスの形式で入力してください',
      // メールアドレス入力時に確認メールアドレスが空の場合はバリデーションをスキップする
      (name === 'email' && confirmationEmail === '') ||
        email === confirmationEmail ||
        'メールアドレスと確認用メールアドレスの入力が一致しません',
    ]
  }

  static checkboxRules() {
    return [(v: boolean) => !!v || 'チェックボックスをONにしてください']
  }

  static checkEmailPanel = (
    email: string,
    confirmationEmail: string,
    signUpCheckbox: boolean,
    signUpPolicyAgainstAntiSocialForces: boolean
  ) => {
    const isValidEmail =
      this.isEmpty('メールアドレス')(email) && this.includedSpace()(email) && mailRegexp.test(email)
    if (
      isValidEmail &&
      email === confirmationEmail &&
      signUpCheckbox &&
      signUpPolicyAgainstAntiSocialForces
    ) {
      CorporationApplyPageStore.updateValidState({
        ...CorporationApplyPageStore.validState,
        emailPanel: true,
      })
    } else {
      CorporationApplyPageStore.updateValidState({
        ...CorporationApplyPageStore.validState,
        emailPanel: false,
      })
    }
  }

  static representativeNameRules(
    itemName: '代表者姓' | '代表者名',
    formValue: CorporationApplyForm
  ) {
    const representativeFullName =
      formValue.representativeLastName + formValue.representativeFirstName
    const rules = []

    rules.push(this.isEmpty(itemName))
    rules.push(this.isZenkaku())
    rules.push(this.isNotExceededMaxCharacters(representativeFullName, 100, '姓と名は合わせて'))

    return rules
  }

  static representativeNameKanaRules(name: '代表者姓カナ', formValue: CorporationApplyForm) {
    const representativeFullNameKana =
      formValue.representativeLastNameKana + formValue.representativeFirstNameKana

    const rules = []

    rules.push(this.isEmpty(name))
    rules.push(this.isZenkakuKana())
    rules.push(
      this.isNotExceededMaxCharacters(representativeFullNameKana, 100, 'カナ姓とカナ名は合わせて')
    )

    return rules
  }

  static representativeBirthdateRules(name: '生年月日') {
    return [this.isEmpty(name), this.isDateDelimitedHyphenUntilToday()]
  }

  static postalCodeRules(name: '郵便番号') {
    return [this.isEmpty(name), this.isPostalCode()]
  }

  static representativeAddressNameRules(
    name: '都道府県・市区町村・町名・番地',
    formValue: CorporationApplyForm
  ) {
    return [
      this.isEmpty(name),
      ...this.representativeBuildingNameRules(formValue),
      this.includedSpace(),
    ]
  }

  static representativeStreetNameRules(
    name: '都道府県・市区町村・町名・番地',
    formValue: CorporationApplyForm
  ) {
    const address =
      formValue.representativePrefectureName +
      formValue.representativeCityName +
      formValue.representativeTownName +
      formValue.representativeStreetName +
      formValue.representativeBuildingName

    return [
      this.isEmpty(name),
      this.isStreetName(),
      this.isNotExceededMaxCharacters(
        address,
        500,
        '都道府県・市区町村・町名・番地・建物名は合わせて'
      ),
      (v: string) => this.isNotExceededMaxCharacters(v, 100),
    ]
  }

  static representativeBuildingNameRules(formValue: CorporationApplyForm) {
    const address =
      formValue.representativePrefectureName +
      formValue.representativeCityName +
      formValue.representativeTownName +
      formValue.representativeStreetName +
      formValue.representativeBuildingName

    return [
      this.isZenkaku(),
      this.isNotExceededMaxCharacters(
        address,
        500,
        '都道府県・市区町村・町名・番地・建物名は合わせて'
      ),
      (v: string) => this.isNotExceededMaxCharacters(v, 100),
    ]
  }

  static representativeAddressNameKanaWithoutBuildingNameRules(
    name: '都道府県（フリガナ）・市区町村（フリガナ）・町名（フリガナ）・番地（フリガナ）',
    formValue: CorporationApplyForm
  ) {
    const rules = []
    rules.push(this.isEmpty(name))
    rules.push(...this.representativeBuildingNameKanaRules(formValue))
    return rules
  }

  static representativeBuildingNameKanaRules(formValue: CorporationApplyForm) {
    const address =
      formValue.representativePrefectureNameKana +
      formValue.representativeCityNameKana +
      formValue.representativeTownNameKana +
      formValue.representativeStreetName +
      formValue.representativeBuildingNameKana

    return [
      this.isAddressKana(),
      this.isNotExceededMaxCharacters(
        address,
        500,
        '都道府県（フリガナ）・市区町村（フリガナ）・町名（フリガナ）・番地・建物名（フリガナ）は合わせて'
      ),
      (v: string) => this.isNotExceededMaxCharacters(v, 100),
    ]
  }

  static phoneNumberRules(name: '電話番号') {
    return [
      this.isEmpty(name),
      this.isPhoneNumber(),
      (v: string) => this.isNotExceededMaxCharacters(v, 13),
    ]
  }

  static addressNameRules(name: '都道府県・市区町村・町名・番地', formValue: CorporationApplyForm) {
    const rules = []
    rules.push(this.isEmpty(name))
    rules.push(...this.buildingNameRules(formValue))
    rules.push(this.includedSpace())
    return rules
  }

  static streetNameRules(name: '都道府県・市区町村・町名・番地', formValue: CorporationApplyForm) {
    const address =
      formValue.representativePrefectureName +
      formValue.representativeCityName +
      formValue.representativeTownName +
      formValue.representativeStreetName +
      formValue.representativeBuildingName

    return [
      this.isEmpty(name),
      this.isStreetName(),
      this.isNotExceededMaxCharacters(
        address,
        500,
        '都道府県・市区町村・町名・番地・建物名は合わせて'
      ),
      (v: string) => this.isNotExceededMaxCharacters(v, 100),
    ]
  }

  static buildingNameRules(formValue: CorporationApplyForm) {
    const address =
      formValue.prefectureName +
      formValue.cityName +
      formValue.townName +
      formValue.streetName +
      formValue.buildingName

    return [
      this.isZenkaku(),
      this.isNotExceededMaxCharacters(
        address,
        500,
        '都道府県・市区町村・町名・番地・建物名は合わせて'
      ),
      (v: string) => this.isNotExceededMaxCharacters(v, 100),
    ]
  }

  static addressNameKanaWithoutBuildingNameRules(
    name: '都道府県（フリガナ）・市区町村（フリガナ）・町名（フリガナ）・番地（フリガナ）',
    formValue: CorporationApplyForm
  ) {
    const rules = []
    rules.push(this.isEmpty(name))
    rules.push(...this.buildingNameKanaRules(formValue))
    return rules
  }

  static buildingNameKanaRules(formValue: CorporationApplyForm) {
    const address =
      formValue.prefectureNameKana +
      formValue.cityNameKana +
      formValue.townNameKana +
      formValue.streetName +
      formValue.buildingNameKana

    return [
      this.isAddressKana(),
      this.isNotExceededMaxCharacters(
        address,
        500,
        '都道府県（フリガナ）・市区町村（フリガナ）・町名（フリガナ）・番地・建物名（フリガナ）は合わせて'
      ),
      (v: string) => this.isNotExceededMaxCharacters(v, 100),
    ]
  }

  static corporationNumberRules(name: '法人番号', formValue: CorporationApplyForm) {
    const rules = []
    rules.push(this.isEmpty(name))
    if (formValue.businessType === '法人') {
      rules.push((v: string) => corporationNumberRegexp.test(v) || '半角数字13桁で入力してください')
    }
    return rules
  }

  static corporationNameRules(name: '会社名') {
    return [
      this.isEmpty(name),
      this.isZenkaku(),
      (v: string) => this.isNotExceededMaxCharacters(v, 100),
    ]
  }

  static corporationNameKanaRules(name: '会社名（フリガナ）') {
    return [
      this.isEmpty(name),
      this.isZenkakuKana(),
      (v: string) => this.isNotExceededMaxCharacters(v, 100),
    ]
  }

  static establishedDateRules(name: '設立年月日') {
    return [
      this.isEmpty(name),
      this.isDateDelimitedHyphenUntilToday(),
      (v: string) => isFutureDate(v, '1849-12-31') || '1850年以降の日付で入力してください',
    ]
  }

  static numberOfPeopleAndAmountRules() {
    const rules = []
    rules.push((v: number) => v === 0 || this.isHankakuNum(v))
    rules.push((v: number) => v >= 0 || '0以上の数字を入力してください')
    return rules
  }

  static homePageUrlRules(name: 'ホームページURL') {
    return [
      this.isEmpty(name),
      (v: string) => this.isUrl(v),
      this.includedSpace(),
      (v: string) => this.isNotExceededMaxCharacters(v, 1000),
    ]
  }

  static businessDescriptionRules(name: '事業内容') {
    return [
      this.isEmpty(name),
      this.isZenkaku(),
      (v: string) => this.isNotExceededMaxCharacters(v, 1000),
    ]
  }

  static doSpecialBusinessRadioRules(formValue: CorporationApplyForm) {
    const rules = []
    if (formValue.doSpecialBusiness && !this.hasSpecialBusiness(formValue)) {
      rules.push('「該当する」を選択した場合はチェックボックスのいずれかをONにしてください。')
    }
    return rules
  }

  private static hasSpecialBusiness(formValue: CorporationApplyForm) {
    return (
      formValue.doDoorToDoorSales ||
      formValue.doSpecifiedContinuousServiceProvision ||
      formValue.doTelemarketingRelatedSales ||
      formValue.doChainReactionSales ||
      formValue.doFreelanceSales ||
      formValue.providedPrepaidTransactions
    )
  }
}
