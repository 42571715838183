import { AxiosResponse } from 'axios'
import { HTTP } from 'http/common'
import { InvoiceItemHistoriesResponse } from 'pages/export_invoice_items_page/model'

type IndexResponse = InvoiceItemHistoriesResponse

export class CorporationInvoiceItemHistoriesApi {
  /**
   * @param page ページ番号 任意
   * ページネーション時に使う。指定しなければ1ページ目を取得します。
   * @param perPage 1ページあたりの件数 任意
   * ページネーション時に使う。指定しなければ50件を取得します。
   */
  static index(page = 1, perPage = 50): Promise<AxiosResponse<IndexResponse[]>> {
    return HTTP.get(`/corporations/me/invoice_item_histories?page=${page}&per_page=${perPage}`)
  }
}
