import { CorporationApi } from 'http/modules/corporation'
import { CorporationsTransferApi, IndexResponseData } from 'http/modules/corporations_transfer'
import { CorporationsTransferSummaryApi } from 'http/modules/corporations_transfer_summary'
import { TransferPageStore } from '../transfer_page_store'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { SnackbarService } from 'shared/services/snackbar_service'
import { StateChanger } from 'vue-infinite-loading'
dayjs.extend(utc)
dayjs.extend(timezone)

export class TransferService {
  static async handleInfiniteLoad(stateChanger: StateChanger) {
    if (TransferPageStore.searchCursor === 0) {
      stateChanger.complete()
      return
    }
    const searchResult = await TransferService.searchTransferSummaries()
    TransferPageStore.updateSearchCursor(searchResult.next_cursor)
    if (searchResult.hits.length !== 0) {
      TransferPageStore.updateFacilityAccountingSummaries([
        ...TransferPageStore.facilityAccountingSummaries,
        ...searchResult.hits,
      ])
      stateChanger.loaded()
    } else {
      stateChanger.complete()
    }
  }

  static async loadInitialData() {
    await TransferService.loadCorporationInfo()
    const now = dayjs().tz('Asia/Tokyo')
    // まず年月範囲の初期値を設定する
    if (
      // 選択可能な範囲が今月からならば、年月の初期値を今月~今月に設定する
      now.isBefore(TransferPageStore.firstSelectableYearMonth!.endOf('month'))
    ) {
      TransferPageStore.updateTargetYearMonthRange({
        from: now.format('YYYY-MM'),
        to: now.format('YYYY-MM'),
      })
    } else {
      TransferPageStore.updateTargetYearMonthRange({
        from: now.subtract(1, 'month').format('YYYY-MM'),
        to: now.format('YYYY-MM'),
      })
    }
    TransferService.loadTransferSummariesFirstPage()
    TransferService.loadAllFacilitiesTransferSummary()
  }

  /**
   * 以下のように動作します。
   * 1. storeにあるsearchCursorを空に
   * 2. infinite-loadingのidentifierをインクリメントする
   * 3. 検索し、結果を返却
   */
  static async loadTransferSummariesFirstPageWithInputValue(): Promise<void> {
    TransferPageStore.updateSearchCursor(undefined)
    TransferPageStore.updateInfiniteLoadingIdentifier(
      TransferPageStore.infiniteLoadingIdentifier + 1
    )
    await TransferService.loadTransferSummaries()
  }

  /**
   * 以下のように動作します。
   * 1. storeにある検索文字列とsearchCursor空にする
   * 2. infinite-loadingのidentifierをインクリメントする
   * 3. 検索し、結果を返却
   * 検索文字列を空にせず、storeにあるものを使いたい場合は、
   * TransferService#loadTransferSummariesFirstPageWithInputValue を使ってください
   */
  static async loadTransferSummariesFirstPage(): Promise<void> {
    TransferPageStore.updateSearchKeyword('')
    await TransferService.loadTransferSummariesFirstPageWithInputValue()
  }

  /**
   * storeに持っている年月、検索文字列、cursorを使って、検索し、
   * 返却されたリストとcursorをstoreに保持させます。
   */
  private static async loadTransferSummaries(): Promise<void> {
    TransferPageStore.updateIsFacilityListLoading(true)
    try {
      const searchResult = await TransferService.searchTransferSummaries()
      TransferPageStore.updateFacilityAccountingSummaries(searchResult.hits)
      TransferPageStore.updateSearchCursor(searchResult.next_cursor)
    } catch (error) {
      SnackbarService.open('施設リストの取得に失敗しました', 'error')
    } finally {
      TransferPageStore.updateIsFacilityListLoading(false)
    }
  }

  /**
   * storeに持っている年月、検索のカーソル、検索文字列を使って検索し、その結果を返します。
   */
  private static async searchTransferSummaries(): Promise<IndexResponseData> {
    if (TransferPageStore.targetYearMonthRange === undefined) {
      throw new Error('targetYearMonthRangeがundefinedです')
    }
    const response = (
      await CorporationsTransferApi.index(
        TransferPageStore.targetYearMonthRange!,
        TransferPageStore.searchCursor,
        TransferPageStore.searchKeyword
      )
    ).data
    return response
  }

  static async loadAllFacilitiesTransferSummary(): Promise<void> {
    if (TransferPageStore.targetYearMonthRange === undefined) {
      return
    }
    TransferPageStore.updateIsTransferSummaryLoading(true)
    try {
      const apiResponse = await CorporationsTransferSummaryApi.summary(
        TransferPageStore.targetYearMonthRange
      )
      TransferPageStore.updateCorporationsTransferSummary(apiResponse.data)
    } catch (error) {
      SnackbarService.open('振込合計額情報の取得に失敗しました', 'error')
    } finally {
      TransferPageStore.updateIsTransferSummaryLoading(false)
    }
  }

  private static async loadCorporationInfo(): Promise<void> {
    const apiResponse = await CorporationApi.self()
    TransferPageStore.updateFirstSelectableYearMonth(
      dayjs(apiResponse.data.oldest_facility_transferred_at).startOf('month')
    )
  }
}
