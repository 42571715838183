import { CorporationInvoiceItemHistoriesApi } from 'http/modules/corporation_invoice_item_histories'
import { CorporationInvoiceItemSummaryApi } from 'http/modules/corporation_invoice_item_summary'
import { CorporationMasterInvoiceItemsApi } from 'http/modules/corporation_master_invoice_items'
import { ExportInvoiceItemsPageStore } from 'pages/export_invoice_items_page/export_invoice_items_page_store'
import { ConvertToInvoiceItemNameCandidateService } from 'pages/export_invoice_items_page/services/convert_to_invoice_item_name_candidate_service'
import { ConvertToQueryParamsService } from 'pages/export_invoice_items_page/services/convert_to_query_params_service'
import { PaginationStore } from 'shared/components/pagination/pagination_store'
import { SwitchMode } from 'pages/export_invoice_items_page/model'

export class CorporationExportInvoiceItemSummaryService {
  /**
   * 初期値が欲しい時に呼ばれる関数
   */
  static async loadInitialData() {
    await CorporationExportInvoiceItemSummaryService.loadInvoiceItemSummary()
    await CorporationExportInvoiceItemSummaryService.loadInvoiceItemNameCandidates()
  }

  /**
   * 法人の請求内容一覧サマリを取得する関数
   */
  static async loadInvoiceItemSummary(mode?: SwitchMode) {
    const { from, to, termType, status, includeKeyword, itemNames, facilityIds } =
      ConvertToQueryParamsService.convertToQueryParams()

    const apiResponse = await CorporationInvoiceItemSummaryApi.show({
      from,
      to,
      termType,
      itemNames,
      status,
      includeKeyword,
      facilityIds,
      perPage: 50,
    })
    ExportInvoiceItemsPageStore.updateInvoiceItemSummary(apiResponse.data)

    if (mode === 'isRefresh') {
      PaginationStore.updateCurrentPage(1)
    }
  }

  /**
   * 請求内容マスタAPIと請求内容履歴APIを叩いて、請求内容の選択肢を作成してstateにセットする
   * サマリの総ページ数を取得して、総ページ数分それぞれのAPIを叩く
   */
  private static async loadInvoiceItemNameCandidates(): Promise<void> {
    const totalPage = ExportInvoiceItemsPageStore.invoiceItemSummary.total.pages.invoice_items
    const [historyResponse, masterResponse] = await Promise.all([
      Promise.all(
        [...Array(totalPage)].map((_, index) => CorporationInvoiceItemHistoriesApi.index(index + 1))
      ),
      Promise.all(
        [...Array(totalPage)].map((_, index) => CorporationMasterInvoiceItemsApi.index(index + 1))
      ),
    ])

    const invoiceItemNameCandidate =
      ConvertToInvoiceItemNameCandidateService.convertToInvoiceItemNameCandidate(
        masterResponse,
        historyResponse
      )
    ExportInvoiceItemsPageStore.updateInvoiceItemNameCandidates(invoiceItemNameCandidate)
  }
}
