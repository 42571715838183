<template>
  <Doughnut :chart-data="chartData" :chart-options="chartOptions" />
</template>

<script setup>
import { computed } from 'vue'
import { Doughnut } from 'vue-chartjs'
import 'chart.js/auto'

// プロパティの定義
const props = defineProps({
  chartData: {
    type: Object,
    required: true,
  },
  options: {
    type: Object,
    default: () => ({}),
  },
})

const chartData = computed(() => props.chartData)
const chartOptions = computed(() => props.options)
</script>
