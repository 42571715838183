import { AxiosResponse } from 'axios'
import { HTTP } from 'http/common'
import { ParentTotal, QueryParams } from 'pages/parents_page/model'

type ShowResponse = ParentTotal
type Params = {
  facilityId: number
  queryParams: QueryParams
}

export class FacilityParentTotalApi {
  static show({ facilityId, queryParams = {} }: Params): Promise<AxiosResponse<ShowResponse>> {
    const query = new URLSearchParams(queryParams)
    return HTTP.get(
      `/facilities/${facilityId}/parent_summary${
        query.toString().length > 0 ? `?${query.toString()}` : ''
      }`
    )
  }
}
