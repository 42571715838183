<template>
  <v-form ref="corpInfoPanel2" v-model="isValid.corpInfoPanel2">
    <div class="ml-3">
      <v-text-field
        v-if="formValue.businessType === '法人'"
        v-model="formValue.corporationNumber"
        label="法人番号"
        :rules="corporationNumberRules"
      ></v-text-field>
      <v-text-field
        v-model="formValue.corporationName"
        label="会社名"
        :rules="corporationNameRules"
        class="mb-3"
      ></v-text-field>
      <span class="text-grey mb-2" style="line-height: initial"
        >事業形態で「個人、その他団体」を選択した場合、施設名・団体名・学校名のいずれかを入力してください。存在しない場合は代表者の氏名を入力してください。</span
      >
      <v-text-field
        v-model="formValue.corporationNameKana"
        label="会社名（フリガナ）"
        :rules="corporationNameKanaRules"
        class="mb-3"
      ></v-text-field>
      <v-row class="mb-3">
        <v-col cols="12" sm="6" md="4">
          <v-menu
            ref="menu"
            v-model="menu"
            v-model:return-value="formValue.establishedDate"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
          >
            <template #activator="{ props }">
              <v-text-field
                v-bind="props"
                v-model="formValue.establishedDate"
                label="設立年月日"
                prepend-icon="mdi-calendar"
                :rules="establishedDateRules"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="establishedDate"
              hide-header
              color="primary"
              min="1850-01-01"
              :max="dayjs().format('YYYY-MM-DD')"
            >
              <template #actions>
                <Button variant="text" @click="menu = false">キャンセル</Button>
                <Button variant="text" @click="menu = false">OK</Button>
              </template>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <CommaSeparatedField
        v-model.number="formValue.capitalStock"
        label="資本金（円）"
        type="number"
        hint="資本金がない場合は0を入力してください"
        :rules="capitalStockRules"
        class="mb-3"
      >
      </CommaSeparatedField>
      <CommaSeparatedField
        v-model.number="formValue.employeesNumber"
        label="従業員数（人）"
        type="number"
        hint="従業員がいない場合は0を入力してください"
        :rules="employeesNumberRules"
        class="mb-3"
      ></CommaSeparatedField>
      <CommaSeparatedField
        v-model.number="formValue.annualTurnover"
        label="年商（円）"
        type="number"
        hint="年商がない場合は0を入力してください"
        :rules="annualTurnoverRules"
        class="mb-3"
      ></CommaSeparatedField>
      <v-text-field
        v-model="formValue.homePageUrl"
        label="ホームページURL（※ホームページが存在しない場合は、https://www.enpay.co.jp/を記入してください）"
        hint="例）https://www.enpay.co.jp/"
        :rules="homePageUrlRules"
      ></v-text-field>
      <div v-show="false">{{ isInitial.corpInfoPanel2 }}</div>
    </div>
  </v-form>
</template>

<script>
import { CorporationApplyPageStore } from '../corporation_apply_page_store'
import { CorporationValidatorsService } from '../services/corporation_validators_service'
import CommaSeparatedField from '../../../shared/components/comma_separeted_field/CommaSeparatedField'
import Button from 'shared/components/Button.vue'
import dayjs from 'dayjs'
import { getFormatDate } from 'shared/utils/date'

export default {
  components: { CommaSeparatedField, Button },
  data: () => ({
    menu: false,
    modal: false,
  }),
  computed: {
    dayjs() {
      return dayjs
    },
    formValue() {
      return CorporationApplyPageStore.formValue
    },
    establishedDate: {
      get() {
        return this.formValue.establishedDate
      },
      set(value) {
        this.formValue.establishedDate = getFormatDate(value, 'YYYY-MM-DD')
      },
    },
    isValid() {
      return CorporationApplyPageStore.validState
    },
    isInitial() {
      if (!CorporationApplyPageStore.initializedState.corpInfoPanel2) {
        this.$refs.corpInfoPanel2.validate()
      }
      return CorporationApplyPageStore.initializedState
    },
    corporationNumberRules() {
      return CorporationValidatorsService.corporationNumberRules('法人番号', this.formValue)
    },
    corporationNameRules() {
      return CorporationValidatorsService.corporationNameRules('会社名')
    },
    corporationNameKanaRules() {
      return CorporationValidatorsService.corporationNameKanaRules('会社名（フリガナ）')
    },
    establishedDateRules() {
      return CorporationValidatorsService.establishedDateRules('設立年月日')
    },
    capitalStockRules() {
      return CorporationValidatorsService.numberOfPeopleAndAmountRules()
    },
    employeesNumberRules() {
      return CorporationValidatorsService.numberOfPeopleAndAmountRules()
    },
    annualTurnoverRules() {
      return CorporationValidatorsService.numberOfPeopleAndAmountRules()
    },
    homePageUrlRules() {
      return CorporationValidatorsService.homePageUrlRules('ホームページURL')
    },
  },
}
</script>
