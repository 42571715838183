<template>
  <img :src="imageUrl" :alt="alt ?? ''" />
</template>
<script setup lang="ts">
import { computed, defineProps } from 'vue'
import useS3Image from './use_s_3_image'

/**
 * S3のホストに渡されたpath('/'始まり)を結合して、画像を表示します。
 */
const props = defineProps<{
  path: string
  alt?: string
}>()
const imageUrl = computed(() => useS3Image(props.path).imageUrl)
</script>
<style lang="scss" scoped>
img {
  max-width: 100%;
}
</style>
