<template>
  <v-form ref="screeningPanel1" v-model="isValid.screeningPanel1">
    <div class="ml-3">
      <p>販売方法は、特商法・前払いに該当しますか</p>
      <v-radio-group
        v-model="formValue.doSpecialBusiness"
        :rules="doSpecialBusinessRadioRules"
        :error="doSpecialBusinessRadioRules.length > 0"
        :hide-details="!doSpecialBusinessRadioRules.length > 0"
      >
        <v-radio color="primary" label="該当する" :value="true"></v-radio>
        <v-radio color="primary" label="該当しない" :value="false"></v-radio>
      </v-radio-group>
      <v-container v-if="formValue.doSpecialBusiness" class="px-0" fluid>
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <v-checkbox
              v-model="formValue.doDoorToDoorSales"
              label="訪問販売を行っている"
            ></v-checkbox>
            <v-checkbox
              v-model="formValue.doSpecifiedContinuousServiceProvision"
              label="特定継続的役務提供を行っている"
            ></v-checkbox>
            <v-checkbox
              v-model="formValue.doTelemarketingRelatedSales"
              label="電話勧誘販売を行っている"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-checkbox
              v-model="formValue.doChainReactionSales"
              label="連鎖販売を行っている"
            ></v-checkbox>
            <v-checkbox
              v-model="formValue.doFreelanceSales"
              label="業務提供誘引販売を行っている"
            ></v-checkbox>
            <v-checkbox
              v-model="formValue.providedPrepaidTransactions"
              label="前払い式取引の提供を行っている"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-container>
      <div v-show="false">{{ isInitial.screeningPanel1 }}</div>
    </div>
  </v-form>
</template>

<script>
import { CorporationApplyPageStore } from '../corporation_apply_page_store'
import { CorporationValidatorsService } from '../services/corporation_validators_service'

export default {
  computed: {
    formValue() {
      return CorporationApplyPageStore.formValue
    },
    isValid() {
      return CorporationApplyPageStore.validState
    },
    isInitial() {
      if (!CorporationApplyPageStore.initializedState.screeningPanel1) {
        this.$refs.screeningPanel1.validate()
      }
      return CorporationApplyPageStore.initializedState
    },
    doSpecialBusinessRadioRules() {
      return CorporationValidatorsService.doSpecialBusinessRadioRules(this.formValue)
    },
  },
}
</script>
