<template>
  <AppFrame>
    <template #header>
      <FacilityHeader />
    </template>
    <div class="contents">
      <div class="container">
        <div class="mt-10">
          <div class="text-subtitle-1 text-center mb-2">振込明細書</div>
          <div class="text-subtitle-2 text-center">各月の振込明細の内容を確認・印刷できます。</div>
        </div>
        <div class="mt-5">
          <v-select
            v-model="selected_year"
            :items="yearOptions"
            class="px-16 selected_year"
            item-title="label"
            item-value="value"
            variant="underlined"
            label="年選択"
          />
          <ul v-if="showableDates.length === 0" class="stackList transferStatementList">
            <li class="stackList_item">表示できる振込明細書がありません</li>
          </ul>

          <ul v-for="date in showableDates" :key="date" class="stackList transferStatementList">
            <li class="stackList_item">
              <div class="paidDate">
                振込予定日：
                <span>{{ transferDate(date) }}</span>
                <p class="memo">
                  ({{ startCalcPaidDate(date) }} - {{ endCalcPaidDate(date) }} 集金合計額)
                </p>
              </div>
              <div class="print-button">
                <a class="button-md button-normal" :href="linkToPrintView(date)" target="_blank"
                  >確認する</a
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </AppFrame>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue'
import FacilityHeader from 'shared/components/header/FacilityHeader.vue'
import { MetaService } from 'shared/services/meta_service'
import AppFrame from 'shared/components/AppFrame.vue'

const props = defineProps<{
  baseurlForPrint: string
  transferStartedAt: string
  visibleTransferDates: {
    [key: string]: {
      transferred_date: string
      start_calc_paid_date: string
      end_calc_paid_date: string
    }
  }
}>()

// 表示中の年
const selected_year = ref(new Date().getFullYear())
// 振り込み明細の年度の候補のリストを返す
const yearOptions = computed(() => {
  const currentYear = new Date().getFullYear()
  const startYear = new Date(props.transferStartedAt).getFullYear()
  const results = []

  for (let year = currentYear; year >= startYear; year -= 1) {
    results.push({
      value: year,
      label: `${year}年`,
    })
  }

  return results
})
/**
 * 振込開始日から、どの月の明細を表示するかを判断するメソッド
 * @returns {Array | null}
 */
const showableDates = computed(() => {
  const now = new Date()
  const transferStartedAt = new Date(props.transferStartedAt)

  const showableDates = []
  while (
    !(
      now.getFullYear() < transferStartedAt.getFullYear() ||
      (now.getFullYear() === transferStartedAt.getFullYear() &&
        now.getMonth() < transferStartedAt.getMonth())
    )
  ) {
    showableDates.push(new Date(transferStartedAt))
    transferStartedAt.setMonth(transferStartedAt.getMonth() + 1)
  }
  const selectedDatesByYear = showableDates.filter(
    (date) => date.getFullYear() === selected_year.value
  )
  selectedDatesByYear.sort((a, b) => Math.sign(b.getTime() - a.getTime()))
  return selectedDatesByYear
})
/**
 * 月が２桁になるよう０埋めして返す
 * @param {Date} date
 * @returns {string} month
 */
const zeroPaddingMonth = (date: Date) => ('0' + (date.getMonth() + 1)).slice(-2)

/**
 * 振込予定日を算出する
 * @param {Date} date
 * @returns {string} 'YYYY年M月D日'
 */
const transferDate = (date: Date) => {
  const key = date.getFullYear() + '-' + zeroPaddingMonth(date)

  if (!props.visibleTransferDates[key]['transferred_date']) {
    throw new Error('transferDateString is not given.')
  }
  return props.visibleTransferDates[key]['transferred_date']
}

const startCalcPaidDate = (date: Date) => {
  const key = date.getFullYear() + '-' + zeroPaddingMonth(date)

  if (!props.visibleTransferDates[key]['start_calc_paid_date']) {
    throw new Error('start_calc_paid_date is not given.')
  }
  return props.visibleTransferDates[key]['start_calc_paid_date']
}

const endCalcPaidDate = (date: Date) => {
  const key = date.getFullYear() + '-' + zeroPaddingMonth(date)

  if (!props.visibleTransferDates[key]['end_calc_paid_date']) {
    throw new Error('end_calc_paid_date is not given.')
  }
  return props.visibleTransferDates[key]['end_calc_paid_date']
}

/**
 * 印刷
 * @param {Date} date
 * @returns {string} url
 */
const linkToPrintView = (date: Date) =>
  `${props.baseurlForPrint}/${date.getFullYear() + '-' + zeroPaddingMonth(date)}`

MetaService.addTitle({ mode: 'facility', page: 'transferStatements' })
</script>
<style lang="scss" scoped>
#transfer-statement-list {
  p.memo {
    font-size: 12px;
    margin: 1mm 0 0;
  }
}

.container {
  margin-top: 64px;
}
</style>
