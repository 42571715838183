<template>
  <v-dialog v-model="isShow" width="500px">
    <v-card>
      <v-card-title class="bg-primary-lighten-1 text-white">
        <span>振込明細一覧ダウンロード</span>
      </v-card-title>
      <v-card-text>
        <div class="text-body-1 mt-8">期間選択</div>
        <div class="my-6">
          <YearMonthRangeSelector
            :start-date="selectableYearMonthRange.from"
            :end-date="selectableYearMonthRange.to"
            :default-range="initialSelectedRange"
            :clearable="false"
            @change="onChangeYearMonth"
          ></YearMonthRangeSelector>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <Button color="normal" variant="text" @click="close()"> キャンセル </Button>
        <Button variant="text" @click="download()"> ダウンロード </Button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import { computed, defineComponent } from 'vue'
import YearMonthRangeSelector from 'shared/components/year_month_range_selector/YearMonthRangeSelector.vue'
import { YearMonthRange } from 'http/modules/corporations_transfer'
import { TransferPageStore } from '../transfer_page_store'
import { TransferStatementDialogService } from '../services/transfer_statement_dialog_service'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import Button from 'shared/components/Button.vue'
dayjs.extend(utc)
dayjs.extend(timezone)

export default defineComponent({
  components: {
    YearMonthRangeSelector,
    Button,
  },
  setup() {
    // computed
    const selectableYearMonthRange = computed(() => ({
      from: dayjs(TransferPageStore.firstSelectableYearMonth),
      to: dayjs().tz('Asia/Tokyo'),
    }))
    const initialSelectedRange = computed(() => TransferPageStore.targetYearMonthRange)
    const isShow = computed({
      get: () => TransferPageStore.transferStatementDialogState.isShow,
      set: (value) => TransferStatementDialogService.updateIsShow(value),
    })
    // methods
    const onChangeYearMonth = (event: YearMonthRange) => {
      TransferStatementDialogService.updateTargetYearMonthRange(event)
    }
    const close = () => TransferStatementDialogService.updateIsShow(false)
    const download = () => TransferStatementDialogService.downloadTransferStatementCsv()
    return {
      selectableYearMonthRange,
      initialSelectedRange,
      isShow,
      onChangeYearMonth,
      close,
      download,
    }
  },
})
</script>
<style lang="scss" scoped>
.term-selector {
  width: 200px;
}
</style>
