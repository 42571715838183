/**
 * 文字列長をマルチバイト文字も考慮して厳密にカウントします
 * @param str
 * @returns 文字列長
 * @see https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/String/length
 * @see https://qiita.com/bon127/items/491b25e90208188dafbd
 */
export const countLengthStrictly = (str: string): number => {
  const segmenter = new Intl.Segmenter('ja', { granularity: 'grapheme' })
  const segments = segmenter.segment(str)
  return [...segments].length
}
