<template>
  <transition name="modal">
    <div v-if="isShowModal" class="modal active" style="display: flex" @click.self="closeModal()">
      <div class="modal_inner">
        <p class="modal_title">
          <slot name="title" />
        </p>
        <div :class="panelWidthClass" class="panel">
          <div class="panel_inner">
            <div v-show="$root.errorMessageModal" class="errorMessage">
              <ul class="errorMessage_list">
                <li v-if="$root.errorMessageModalHtmlSafe" v-html="$root.errorMessageModal" />
                <li v-else>{{ $root.errorMessageModal }}</li>
              </ul>
            </div>
            <slot name="body" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    panelWidthClass: String,
    isShowModal: Boolean,
  },
  emits: ['close'],
  methods: {
    closeModal: function () {
      const showModals = this.$root.showModals
      Object.keys(showModals).map((value) => (showModals[value] = false))
      this.$emit('close')
    },
  },
}
</script>
