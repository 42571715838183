<template>
  <SimpleLayout title="請求内容マスタ設定">
    <template #headerNav>
      <FacilityHeader />
    </template>

    <template #subTitle>
      <p v-if="isExistingItemNameCandidates()" class="text-subtitle-1">
        登録された請求内容の一覧です。
      </p>
      <p v-else class="text-subtitle-1">登録された請求内容はありません。</p>
    </template>

    <template #headerContent>
      <Button size="large" class="font-weight-bold flat-button" @click="openAddModal">
        <v-avatar start color="white" size="20px" icon="mdi-plus" class="mr-2 text-primary" /><span
          class="fs-16"
          >新規作成</span
        >
      </Button>
    </template>

    <template #main>
      <div class="contents px-0" style="width: 832px">
        <v-container class="px-0 py-0">
          <template v-if="showNoticeMessages">
            <ul
              v-for="noticeMessage in noticeMessages"
              :key="noticeMessage"
              class="noticeMessage_list"
            >
              <li>{{ noticeMessage }}</li>
            </ul>
          </template>
          <v-list v-if="isExistingItemNameCandidates()">
            <div
              v-for="(item_name_candidate, index) in itemNameCandidates"
              :key="index"
              class="my-2 item-name-candidate-list"
            >
              <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
                {{ item_name_candidate.name }}
              </div>
              <div>{{ getUnitPrice(item_name_candidate.unit_price) }}</div>
              <div>{{ item_name_candidate.code }}</div>
              <Button
                color="normal"
                variant="tonal"
                @click="openEditModal(item_name_candidate, index)"
              >
                編集する
              </Button>
              <Button color="error" @click="openDeleteModal(item_name_candidate, index)">
                削除する
              </Button>
            </div>
          </v-list>
          <ModalComponent
            :is-show-modal="showModals.addModal"
            panel-width-class="panel-md"
            @close="closeAddModal"
          >
            <template #title>
              <div>請求内容新規登録</div>
            </template>
            <template #body>
              <div>
                <div v-show="errorMessages" class="errorMessage">
                  <ul
                    v-for="errorMessage in errorMessages"
                    :key="errorMessage"
                    class="errorMessage_list"
                  >
                    <li>{{ errorMessage }}</li>
                  </ul>
                </div>
                <form
                  id="school_invoice_item_name_candidate_form"
                  accept-charset="UTF-8"
                  method="post"
                >
                  <v-row>
                    <v-col cols="12" sm="4" md="4" class="mt-2">
                      <label class="form-Item_label">請求内容(必須)</label>
                    </v-col>
                    <v-col cols="12" sm="8" md="8">
                      <v-text-field
                        id="school_invoice_item_name_candidate_name"
                        name="school_master_invoice_item[name]"
                        placeholder="例）延長保育料"
                        hide-details="auto"
                        variant="outlined"
                        density="compact"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="4" md="4" class="mt-2">
                      <label class="form-Item_label">金額</label>
                    </v-col>
                    <v-col cols="12" sm="8" md="8">
                      <v-text-field
                        id="school_invoice_item_name_candidate_unit_price"
                        name="school_master_invoice_item[unit_price]"
                        placeholder="例）10000"
                        hide-details="auto"
                        variant="outlined"
                        density="compact"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="4" md="4" class="mt-2">
                      <label class="form-Item_label">コード</label>
                    </v-col>
                    <v-col cols="12" sm="8" md="8">
                      <v-text-field
                        id="school_invoice_item_name_candidate_code"
                        name="school_master_invoice_item[code]"
                        placeholder="管理用のコードがあればご入力ください"
                        hide-details="auto"
                        variant="outlined"
                        density="compact"
                      />
                    </v-col>
                  </v-row>
                  <input
                    id="school_invoice_item_name_candidate_master_flg"
                    name="school_master_invoice_item[master_flg]"
                    value="true"
                    type="hidden"
                  />
                  <v-row class="justify-center pt-8">
                    <Button size="x-large" class="mx-2" @click="addSchoolInvoiceItemNameCandidate">
                      登録する
                    </Button>
                  </v-row>
                </form>
              </div>
            </template>
          </ModalComponent>
          <ModalComponent
            :is-show-modal="showModals.editModal"
            panel-width-class="panel-md"
            @close="closeEditModal"
          >
            <template #title>
              <div>請求内容編集</div>
            </template>
            <template #body>
              <div>
                <div v-show="errorMessages" class="errorMessage">
                  <ul
                    v-for="errorMessage in errorMessages"
                    :key="errorMessage"
                    class="errorMessage_list"
                  >
                    <li>{{ errorMessage }}</li>
                  </ul>
                </div>
                <form
                  id="edit_school_invoice_item_name_candidate_form"
                  accept-charset="UTF-8"
                  method="post"
                >
                  <v-row>
                    <v-col cols="12" sm="4" md="4" class="mt-2">
                      <label class="form-Item_label">請求内容(必須)</label>
                    </v-col>
                    <v-col cols="12" sm="8" md="8">
                      <v-text-field
                        id="school_invoice_item_name_candidate_name"
                        v-model="selectedschoolInvoiceItemNameCandidate.name"
                        name="school_master_invoice_item[name]"
                        placeholder="例）延長保育料"
                        hide-details="auto"
                        variant="outlined"
                        density="compact"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="4" md="4" class="mt-2">
                      <label class="form-Item_label">金額</label>
                    </v-col>
                    <v-col cols="12" sm="8" md="8">
                      <v-text-field
                        id="school_invoice_item_name_candidate_unit_price"
                        v-model="selectedschoolInvoiceItemNameCandidate.unit_price"
                        name="school_master_invoice_item[unit_price]"
                        placeholder="例）10000"
                        variant="outlined"
                        density="compact"
                        hide-details="auto"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="4" md="4" class="mt-2">
                      <label class="form-Item_label">コード</label>
                    </v-col>
                    <v-col cols="12" sm="8" md="8">
                      <v-text-field
                        id="school_invoice_item_name_candidate_code"
                        v-model="selectedschoolInvoiceItemNameCandidate.code"
                        name="school_master_invoice_item[code]"
                        placeholder="管理用のコードがあればご入力ください"
                        hide-details="auto"
                        variant="outlined"
                        density="compact"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="justify-center pt-8">
                    <Button
                      size="x-large"
                      class="mx-2"
                      @click="
                        updateSchoolInvoiceItemNameCandidate(
                          selectedschoolInvoiceItemNameCandidate_old,
                          selectedIndex,
                          $event
                        )
                      "
                    >
                      変更する
                    </Button>
                  </v-row>
                </form>
              </div>
            </template>
          </ModalComponent>
          <ModalComponent
            :is-show-modal="showModals.deleteModal"
            panel-width-class="panel-md"
            @close="closeDeleteModal"
          >
            <template #title>
              <div>請求内容削除</div>
            </template>
            <template #body>
              <div>
                <div v-show="errorMessages" class="errorMessage">
                  <ul
                    v-for="errorMessage in errorMessages"
                    :key="errorMessage"
                    class="errorMessage_list"
                  >
                    <li>{{ errorMessage }}</li>
                  </ul>
                </div>
                <v-row>
                  <v-col class="justify-center">
                    <label>{{ selectedschoolInvoiceItemNameCandidate.name }}</label>
                  </v-col>
                </v-row>
                <v-row class="justify-center">
                  <v-col class="mt-2">
                    <label>この請求内容の登録を削除してよろしいですか？</label>
                  </v-col>
                </v-row>
                <v-row class="justify-center pt-8">
                  <Button
                    size="x-large"
                    class="mx-2"
                    @click="
                      deleteSchoolInvoiceItemNameCandidate(
                        selectedschoolInvoiceItemNameCandidate,
                        selectedIndex,
                        $event
                      )
                    "
                  >
                    削除する
                  </Button>
                </v-row>
              </div>
            </template>
          </ModalComponent>
        </v-container>
      </div>
    </template>
  </SimpleLayout>
</template>
<script>
/* eslint-disable max-lines */
import ModalComponent from './ModalComponent.vue'
import InvoiceItemNameCandidateApi from '../../../http/modules/invoice_item_name_candidate'
import { RouteService } from '../../../shared/services/route_service'
import SimpleLayout from 'shared/templates/SimpleLayout.vue'
import FacilityHeader from 'shared/components/header/FacilityHeader.vue'
import Button from 'shared/components/Button.vue'
import { MetaService } from 'shared/services/meta_service'

export default {
  components: {
    ModalComponent,
    SimpleLayout,
    FacilityHeader,
    Button,
  },
  props: {
    schoolInvoiceItemNameCandidates: Object,
  },
  data: () => ({
    showModals: {
      addModal: false,
      editModal: false,
      deleteModal: false,
    },
    itemNameCandidates: [],
    errorMessages: [],
    noticeMessages: [],
    selectedschoolInvoiceItemNameCandidate: null,
    selectedschoolInvoiceItemNameCandidate_old: null,
    selectedIndex: 0,
  }),
  computed: {
    showNoticeMessages() {
      return this.noticeMessages.length !== 0
    },
  },
  created() {
    this.itemNameCandidates = this.schoolInvoiceItemNameCandidates.schoolInvoiceItemNameCandidates
    MetaService.addTitle({ mode: 'facility', page: 'itemNameCandidates' })
  },
  methods: {
    getUnitPrice(unitPrice) {
      return unitPrice !== null ? unitPrice + '円' : '-円'
    },
    clearMessage() {
      this.errorMessages = []
      this.noticeMessages = []
    },
    openAddModal() {
      this.clearMessage()
      this.showModals.addModal = true
    },
    closeAddModal() {
      this.showModals.addModal = false
    },
    openEditModal(school_invoice_item_name_candidate, index) {
      this.clearMessage()
      this.selectedschoolInvoiceItemNameCandidate = school_invoice_item_name_candidate
      this.selectedschoolInvoiceItemNameCandidate_old = { ...school_invoice_item_name_candidate }
      this.selectedIndex = index
      this.showModals.editModal = true
    },
    closeEditModal() {
      this.showModals.editModal = false
    },
    openDeleteModal(school_invoice_item_name_candidate, index) {
      this.clearMessage()
      this.selectedschoolInvoiceItemNameCandidate = school_invoice_item_name_candidate
      this.selectedIndex = index
      this.showModals.deleteModal = true
    },
    closeDeleteModal() {
      this.showModals.deleteModal = false
    },
    addSchoolInvoiceItemNameCandidate(e) {
      e.preventDefault()
      const form = document.querySelector('#school_invoice_item_name_candidate_form')
      const params = new FormData(form)
      params['master_flg'] = true
      InvoiceItemNameCandidateApi.create(this.getTargetFacilityId(), params)
        .then((res) => {
          this.itemNameCandidates.push(res.data.school_master_invoice_item)
          this.noticeMessages = res.data.messages
          this.closeAddModal()
        })
        .catch((error) => {
          // TODO エラーハンドリング処理を共通化する
          if (error.response.status === '401') {
            window.location = '/'
          }
          this.errorMessages = error.response.data.messages
        })
    },
    // 請求内容を変更する
    updateSchoolInvoiceItemNameCandidate(school_invoice_item_name_candidate, index, e) {
      e.preventDefault()
      const form = document.querySelector('#edit_school_invoice_item_name_candidate_form')
      const params = new FormData(form)
      InvoiceItemNameCandidateApi.update(
        this.getTargetFacilityId(),
        school_invoice_item_name_candidate.id,
        params
      )
        .then((res) => {
          this.itemNameCandidates[index] = res.data.school_master_invoice_item
          this.noticeMessages = res.data.messages
          this.closeEditModal()
        })
        .catch((error) => {
          // TODO エラーハンドリング処理を共通化する
          if (error.response.status === '401') {
            window.location = '/'
          }
          this.errorMessages = error.response.data.messages
        })
    },
    deleteSchoolInvoiceItemNameCandidate(school_invoice_item_name_candidate, index, _e) {
      InvoiceItemNameCandidateApi.destroy(
        this.getTargetFacilityId(),
        school_invoice_item_name_candidate.id
      )
        .then((res) => {
          this.itemNameCandidates.splice(index, 1)
          this.noticeMessages = res.data.messages
          this.closeDeleteModal()
        })
        .catch((error) => {
          // TODO エラーハンドリング処理を共通化する
          if (error.response.status === '401') {
            window.location = '/'
          }
          this.errorMessages = error.response.data.messages
        })
    },
    getTargetFacilityId() {
      return Number(RouteService.getPathParamByIndex(1))
    },
    isExistingItemNameCandidates() {
      return this.itemNameCandidates.length !== 0
    },
  },
}
</script>

<style lang="scss" scoped>
.fs-16 {
  font-size: 16px;
}
.flat-button {
  line-height: 24px;
}
.button-bottom {
  right: 24px;
  bottom: 180px;
}
.item-name-candidate-list {
  display: grid;
  align-items: center;
  grid-template-columns: 300px 1fr 1fr max-content max-content;
  column-gap: 4px;
  padding: 12px 16px;
}
</style>
