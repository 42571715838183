import { AxiosResponse } from 'axios'
import {
  InvoiceItemHistoriesResponse,
  MasterInvoiceItemResponse,
} from 'pages/export_invoice_items_page/model'
import { InvoiceItemNameCandidate } from 'shared/models'

/**
 * 検索ボックスの請求内容の選択肢を作成する
 * マスターと履歴のAPIレスポンスを結合して、請求内容の選択肢を作成する
 * マスタ→履歴の順番で結合する
 * @param masterResponse 請求内容マスタAPIを叩いたレスポンス結果
 * @param historyResponse 請求内容履歴APIを叩いたレスポンス結果
 */
export class ConvertToInvoiceItemNameCandidateService {
  static convertToInvoiceItemNameCandidate(
    masterResponse: AxiosResponse<MasterInvoiceItemResponse[]>[],
    historyResponse: AxiosResponse<InvoiceItemHistoriesResponse[]>[]
  ): InvoiceItemNameCandidate[] {
    const master = masterResponse
      .flatMap((res) => res.data)
      .map((item) => ({
        name: item.name,
        master_flg: true,
      }))

    const histories = historyResponse
      .flatMap((res) => res.data)
      .map((item) => ({
        name: item.name,
        master_flg: false,
      }))

    const invoiceItemNameCandidate = [...master, ...histories]

    return invoiceItemNameCandidate
  }
}
