import { InvoiceItem, InvoiceItemsResponse } from 'pages/export_invoice_items_page/model'
import PaymentMethod from 'shared/consts/string/payment_method'
import { getFormatDate } from 'shared/utils/date'

export class ConvertToInvoiceItemsService {
  static convertToInvoiceItems(apiResponse: InvoiceItemsResponse): InvoiceItem[] {
    return apiResponse.map((res) => ({
      facilityId: res.facility.id,
      facilityName: res.facility.name,
      targetYearAndMonth: res.invoice.target_year_and_month.replace(/-/g, ''),
      lastName: res.school_parent.last_name,
      firstName: res.school_parent.first_name,
      className: res.school_class.name || '-',
      childId: res.school_parent_child.id || '-',
      childCode: res.school_parent_child.code || '-',
      childName: res.school_parent_child.first_name || '-',
      seq: res.invoice.seq,
      sentAt: res.invoice.sent_at ? getFormatDate(res.invoice.sent_at, 'YYYY/MM/DD HH:mm') : '-',
      paymentMethod: PaymentMethod[res.invoice.payment_method] || '-',
      paidAt: res.invoice.paid_at ? getFormatDate(res.invoice.paid_at, 'YYYY/MM/DD HH:mm') : '-',
      invoiceItemName: res.name,
      masterInvoiceItemCode: res.school_master_invoice_item.code || '-',
      count: res.count.toLocaleString(),
      unitPrice: res.unit_price.toLocaleString(),
      price: res.price.toLocaleString(),
    }))
  }
}
