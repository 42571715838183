import { AxiosResponse } from 'axios'
import { HTTP } from 'http/common'
import { OemPartner } from 'shared/models'

export type ShowResponse = OemPartner

export class CorporationOemPartnerApi {
  static show(): Promise<AxiosResponse<ShowResponse>> {
    return HTTP.get('/corporations/me/oem_partner')
  }
}
