import { pushHistory } from 'shared/utils/history'
import { SearchQuery } from 'pages/parents_page/model'
import { ParentsPageStore } from 'pages/parents_page/parents_page_store'
import { RouteService } from 'shared/services/route_service'

export class ParentUrlService {
  static copyQueryParamsToStore(): void {
    const queryParams = RouteService.getQueryParams()

    // NOTE: all、connected、not_connected 以外の場合、デフォルト値をallに上書き
    const lineConnected =
      queryParams['line_connected'] &&
      ['all', 'connected', 'not_connected'].includes(queryParams['line_connected'])
        ? (queryParams['line_connected'] as SearchQuery['line_connected'])
        : 'all'

    ParentsPageStore.updateSearchQuery({
      ...(queryParams as SearchQuery),
      line_connected: lineConnected,
    })
  }

  /**
   * storeの値からクエリパラメータを上書きする関数
   */
  static overwriteQueryParams(): void {
    const { class_id, parent_name, child_name, line_connected, page } =
      ParentUrlService.encodeQueryParams(ParentsPageStore.searchQuery)
    const queryParams = `?class_id=${class_id || ''}&parent_name=${parent_name || ''}&child_name=${
      child_name || ''
    }&line_connected=${line_connected}&page=${page}`

    // 検索条件を変更した履歴を残しておきたいので、pushHistoryを使う
    pushHistory(queryParams)
  }

  static encodeQueryParams(query: SearchQuery): SearchQuery {
    // NOTE: 記号かどうかを判別するパターン
    const symbolPattern = /[!@#$%^&*()_+{}[\]:;<>,.?~\\|-]/

    return {
      class_id: encodeURIComponent(query.class_id),
      parent_name: symbolPattern.test(query.parent_name)
        ? encodeURIComponent(query.parent_name)
        : query.parent_name,
      child_name: symbolPattern.test(query.child_name)
        ? encodeURIComponent(query.child_name)
        : query.child_name,
      line_connected: encodeURIComponent(query.line_connected) as SearchQuery['line_connected'],
      page: encodeURIComponent(query.page),
      per_page: encodeURIComponent('50') as SearchQuery['per_page'],
    }
  }
}
