type Params = { facilityId: number }

export class FacilityParentAccountsCsvApi {
  /**
   * LINE登録状況 を取得する
   * @param facilityId 施設ID 必須
   */
  static index({ facilityId }: Params): void {
    window.location.href = `${location.origin}/api/v1/facilities/${facilityId}/parent_accounts.csv`
  }
}
