<template>
  <div class="text-body-1 font-weight-bold">
    <span> 請求内容が確定している施設のみ、請求を締めました。</span>
    <div class="mt-4 font-weight-bold">
      <span>成功: {{ successFacilityTotal }} 施設</span>
      <br />
      <span class="text-red">失敗: {{ failureFacilityTotal }} 施設</span>
    </div>
    <div class="mt-4">
      <span class="mt-4">
        保存中の請求がある施設は、請求が締められません。
        <br />
        請求を送信するか、保存中の請求を削除してください。
      </span>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    successFacilityTotal: Number,
    failureFacilityTotal: Number,
  },
})
</script>
