import { HTTP } from '../common'

export type SettingKey = 'invoice_page_initial_target_month'

export type SettingValues = Map<SettingKey, string>

export type ShowResponse = {
  invoice_page_initial_target_month: string
}

export type SaveResponse = {
  data: {
    message: string
  }
}

export class CorporationsAccountSettingApi {
  static async show(): Promise<SettingValues> {
    const map = new Map<SettingKey, string>()
    const response = (await HTTP.get<ShowResponse>(`/corporations/me/account_settings`)).data
    ;(Object.keys(response) as (keyof ShowResponse)[]).forEach((key) => {
      map.set(key, response[key])
    })
    return map
  }
}
