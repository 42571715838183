import { CorporationAccountApi } from 'http/modules/corporation_account'
import { CorporationOemPartnerApi } from 'http/modules/corporation_oem_partner'
import { FacilityOemPartnerApi } from 'http/modules/facility_oem_partner'
import { AppStore } from 'shared/app_store'
import { RouteService } from 'shared/services/route_service'

export class OemPartnerService {
  /**
   * oem提携先の情報をサーバから読み込み、AppStoreに保存します。
   */
  static async loadForCorporation(): Promise<void> {
    const response = (await CorporationOemPartnerApi.show()).data
    AppStore.updateOemPartner(response)
  }

  static async loadForFacility(): Promise<void> {
    const facilityId = await OemPartnerService.getTargetFacilityId()
    if (!facilityId) return
    const response = (await FacilityOemPartnerApi.show(facilityId)).data
    AppStore.updateOemPartner(response)
  }

  private static async getTargetFacilityId(): Promise<number | undefined> {
    if (isNaN(Number(RouteService.getPathParamByIndex(1)))) {
      const fetchedFacilityId = await CorporationAccountApi.self()
      return fetchedFacilityId.data.id
    }
    return Number(RouteService.getPathParamByIndex(1))
  }
}
