<template>
  <Button variant="outlined" class="text-subtitle-1" @click="$emit('click')">
    <v-icon size="small">mdi-lock</v-icon>&nbsp;
    <span class="font-weight-bold">請求を締める</span>
  </Button>
</template>
<script>
import { defineComponent } from 'vue'
import Button from 'shared/components/Button.vue'

export default defineComponent({
  components: {
    Button,
  },
  emits: ['click'],
})
</script>
