import {
  FacilityParentApi,
  isAxiosError,
  isConfirmationError,
  isConfirmationWarning,
} from 'http/modules/facility_parent'
import { ParentsService } from 'pages/parents_page/services/parents_service'
import { ParentsAddPageStore } from 'pages/parents_page/parent_add/parent_adds_page_store'
import { InputConfirmationError, EditableParent } from 'pages/parents_page/model'
import { DialogService } from 'shared/services/dialog_service'
import { FormSubmittingService } from 'pages/parents_page/services/form_submitting_service'
import { groupBy } from 'shared/utils/groupBy'

type CreateParentsOption = {
  onValidate: () => boolean
  onSuccess: () => void
}

export class ParentAddService {
  /**
   *  登録ボタンを押した際の処理
   *  ParentImportRequestApi.createへのリクエストを行う前に、重複チェック、エラーチェックをする
   */
  static startCreateConfirmation = async (options: CreateParentsOption) => {
    // NOTE: サーバーと通信しないタイプのバリデーションを行う
    if (!options.onValidate()) return
    ParentsAddPageStore.updateIsDisabled(true)

    const requestBody = ParentsAddPageStore.parents.map((parent) => ({
      id: parent.id,
      first_name: parent.firstName,
      last_name: parent.lastName,
      code: parent.code,
      school_parent_children: parent.schoolParentChildren.map((child) => ({
        first_name: child.firstName,
        code: child.code,
        school_class_id: child.schoolClassId ? Number(child.schoolClassId) : undefined,
      })),
    }))

    try {
      await FacilityParentApi.inputConfirmation(ParentsService.getTargetFacilityId(), requestBody)
      // NOTE: createするだけ。重複・エラーの場合はcatchされる
      await ParentAddService.startCreate(options.onSuccess)
    } catch (e) {
      if (!isAxiosError(e) || e.response === undefined || e.response.status === 500) {
        DialogService.openMessage({
          message: ['不正なエラーが発生しました。しばらく経ってからお試しください。'],
          title: 'エラー',
        })
        return
      }

      const { error, warn } = (e.response.data as InputConfirmationError).errors
      if (isConfirmationError(e)) {
        ParentAddService.setErrors(error)
      } else if (isConfirmationWarning(e)) {
        ParentAddService.setWarns(warn, options.onSuccess, ParentsAddPageStore.parents)
      }
    } finally {
      ParentsAddPageStore.updateIsDisabled(false)
    }
  }

  /**
   *  FacilityParentApi.input_confirmationへのリクエスト後、エラーが発生した際の処理
   *  @params errors エラー情報
   */
  static setErrors = (errors: InputConfirmationError['errors']['error']) => {
    const parentErrors = errors.filter(
      ({ resource_name, attribute_name }) =>
        resource_name === 'school_parent' && attribute_name === 'code'
    )
    const childrenErrors = groupBy(
      errors.filter((error) => error.resource_name === 'school_parent_child'),
      (error) => error.child_index ?? '-1'
    )
    ParentsAddPageStore.updateParents(
      ParentsAddPageStore.parents.map((parent, _index) => ({
        ...parent,
        errors: {
          attributeName: (parentErrors && parentErrors[0]?.attribute_name) ?? '',
          errorType: (parentErrors && parentErrors[0]?.error_type) ?? '',
        },
        schoolParentChildren: parent.schoolParentChildren.map((child, indexInChild) => ({
          ...child,
          errors: {
            attributeName:
              (childrenErrors[indexInChild] && childrenErrors[indexInChild][0]?.attribute_name) ??
              '',
            errorType:
              (childrenErrors[indexInChild] && childrenErrors[indexInChild][0]?.error_type) ?? '',
          },
        })),
      }))
    )
  }

  /**
   *  FacilityParentApi.input_confirmationへのリクエスト後、重複エラーが発生した際の処理
   *  @params parents 登録する親の情報
   */
  // eslint-disable-next-line max-lines-per-function
  private static setWarns = (
    warns: InputConfirmationError['errors']['warn'],
    onSuccess: () => void,
    parents: EditableParent[]
  ) => {
    const {
      duplicateNamesOnInputs,
      duplicateParentAndChildNameOnDb,
      duplicateParentNamesBetweenNewRecordAndSavedRecordOnInputs,
      duplicateParentNamesBetweenNewRecordAndSavedRecordOnDb,
    } = FormSubmittingService.groupingWarns(warns)

    switch (true) {
      // A: 保護者・子どもの重複がある場合
      case duplicateParentAndChildNameOnDb.length > 0:
        FormSubmittingService.handleDuplicateParentAndChildNameWarns({
          warn: duplicateParentAndChildNameOnDb,
          execute: () => ParentAddService.startCreate(onSuccess),
          parents: parents,
        })
        break
      // A: 保護者・子どもの重複がある場合
      case duplicateNamesOnInputs.length > 0:
        FormSubmittingService.handleDuplicateParentAndChildNameWarns({
          warn: duplicateNamesOnInputs,
          execute: () => ParentAddService.startCreate(onSuccess),
          parents: parents,
        })
        break
      // B: 保護者のみの重複がある場合
      case duplicateParentNamesBetweenNewRecordAndSavedRecordOnInputs.length > 0:
        FormSubmittingService.handleDuplicateParentNamesBetweenNewRecordAndSavedRecordWarns({
          warn: duplicateParentNamesBetweenNewRecordAndSavedRecordOnInputs,
          execute: () => ParentAddService.startCreate(onSuccess),
          parents: parents,
        })
        break
      // B: 保護者のみの重複がある場合
      case duplicateParentNamesBetweenNewRecordAndSavedRecordOnDb.length > 0:
        FormSubmittingService.handleDuplicateParentNamesBetweenNewRecordAndSavedRecordWarns({
          warn: duplicateParentNamesBetweenNewRecordAndSavedRecordOnDb,
          execute: () => ParentAddService.startCreate(onSuccess),
          parents: parents,
        })
        break
      default:
        DialogService.openMessage({
          message: ['警告の表示に失敗しました。しばらく経ってからお試しください。'],
          title: 'エラー',
        })
    }
  }

  /**
   *  FacilityParentApi.createへのリクエストを行う
   *  この前段で重複チェック、エラーチェックは済んでいる
   *  @params onSuccess 登録成功した際の処理
   */
  private static startCreate = async (onSuccess: () => void): Promise<void> => {
    const facilityId = ParentsService.getTargetFacilityId()

    try {
      await Promise.all(
        ParentsAddPageStore.parents.map(async (parent: EditableParent) => {
          const requestBody = {
            first_name: parent.firstName,
            last_name: parent.lastName,
            code: parent.code,
            school_parent_children: parent.schoolParentChildren.map((child: any) => ({
              first_name: child.firstName,
              code: child.code,
              school_class_id: child.schoolClassId ? Number(child.schoolClassId) : undefined,
            })),
          }
          return (await FacilityParentApi.create(facilityId, requestBody)).data
        })
      )
      onSuccess()
    } catch (e) {
      if (!isAxiosError(e) || e.response === undefined || e.response.status === 500) {
        DialogService.openMessage({
          message: ['不正なエラーが発生しました。しばらく経ってからお試しください。'],
          title: 'エラー',
        })
      }
    }
  }
}
