import { ParentsPageStore } from 'pages/parents_page/parents_page_store'
import { BulkOperationMode, ChildId, Parent } from 'pages/parents_page/model'

/**
 * 保護者一覧の一括操作に関するロジックを持ちます。
 */
export class BulkOperationService {
  /**
   * 保護者の一括操作に関するロジック
   * 保護者にチェックが入ったら、呼ばれる。
   * @param selectedParentIds 選択された保護者のid
   * @param parent 保護者
   */
  static parent(selectedParentIds: Parent['id'][], parent: Parent): void {
    const isChecked = selectedParentIds.includes(parent.id)
    parent.school_parent_children.forEach((child) => {
      const newChildId = {
        parentId: parent.id,
        childId: child.id,
      }
      // NOTE: 既にチェックされている親・子どもを見て、新しく入れようとしている値と重複するか確認する
      const isDuplicate = ParentsPageStore.selectedChildIds.some(
        (item) => item.parentId === newChildId.parentId && item.childId === newChildId.childId
      )
      // NOTE: 親にチェックが入る && まだチェックが入っていない場合、子どもにもチェックを入れる。
      if (isChecked && !isDuplicate) {
        ParentsPageStore.updateSelectedChildIds([
          ...ParentsPageStore.selectedChildIds,
          {
            parentId: parent.id,
            childId: child.id,
          },
        ])
      }
      // NOTE: 親のチェックが外れたら、子どものチェックも外す
      else if (!isChecked) {
        ParentsPageStore.updateSelectedChildIds(
          ParentsPageStore.selectedChildIds.filter((item) => item.childId !== child.id)
        )
      }
    })

    // NOTE: 親のチェックが入ったら、親のidをselectedParentIdsに追加する
    if (isChecked) {
      ParentsPageStore.updateSelectedParentIds([...ParentsPageStore.selectedParentIds, parent.id])
    }
    // NOTE: 親のチェックが外れたら、その親のチェックも外す
    else {
      ParentsPageStore.updateSelectedParentIds(
        ParentsPageStore.selectedParentIds.filter((id) => id !== parent.id)
      )
    }
  }

  /**
   * 子どもの一括操作に関するロジック
   * 子どもにチェックが入ったら、呼ばれる。
   * @param selectedChildIds 選択された子どものid
   * @param parent 保護者
   */
  static child(selectedChildIds: ChildId[], parent: Parent): void {
    ParentsPageStore.updateSelectedChildIds(selectedChildIds)
    // NOTE: 親に紐づく子どものIds
    const parentChildIds = parent.school_parent_children.map((child) => child.id)
    // NOTE: 選択されている子どものIds
    const inputChildIds = selectedChildIds.map((item) => item.childId)
    // NOTE: 親に紐づく子どもが全てチェックされているかどうか
    const isSubset = parentChildIds.every((childId) => inputChildIds.includes(childId))
    // NOTE: 全てチェックされていたら、親にチェックを入れる
    if (isSubset) {
      ParentsPageStore.updateSelectedParentIds([...ParentsPageStore.selectedParentIds, parent.id])
    }
    // NOTE: 子どものチェックが1つでも外れていたら、親のチェックを外す
    else {
      ParentsPageStore.updateSelectedParentIds(
        ParentsPageStore.selectedParentIds.filter((id) => id !== parent.id)
      )
    }
  }

  /**
   * ページ内の保護者・子どもが全て選択されているかどうかを判定する
   * @param parents 保護者複数
   * @returns boolean 全て選択されているかどうか
   */
  static isAllParentsSelectedInPage(
    parents: Parent[],
    bulkOperationMode: BulkOperationMode
  ): boolean {
    const selectedParentIds = ParentsPageStore.selectedParentIds
    const selectedChildIds = ParentsPageStore.selectedChildIds
    if (bulkOperationMode === 'delete') {
      const selectableParentIds = parents
        .filter((parent) => !parent.has_codmon_children)
        .map((parent) => parent.id)
      const selectableChildIds = parents.flatMap((parent) =>
        parent.school_parent_children
          .filter((child) => !child.codmon_id)
          .map((child) => ({ parentId: parent.id, childId: child.id }))
      )

      return (
        selectedParentIds.length === selectableParentIds.length &&
        selectedChildIds.length === selectableChildIds.length &&
        selectableParentIds.every((id) => selectedParentIds.includes(id))
      )
    }

    const selectableParentIds = parents.map((parent) => parent.id)

    return (
      selectedParentIds.length === selectableParentIds.length &&
      selectableParentIds.every((id) => selectedParentIds.includes(id))
    )
  }

  /**
   * ページ内の全選択がONになったときに呼ばれる。
   * ページ内の保護者・子どもが全て選択される。
   * @param parents 保護者複数
   * @param bulkOperationMode 一括操作の種類
   */
  static selectAllParentsInPage(parents: Parent[], bulkOperationMode: BulkOperationMode): void {
    let parentIds
    let childIds

    if (bulkOperationMode === 'delete') {
      // NOTE: コドモン連携している子ども・子ども姓名及びクラスの検索にマッチしなかった子どもが存在する場合は選択対象から除外する
      parentIds = parents.flatMap((parent) =>
        !parent.has_codmon_children && parent.school_parent_children.every((child) => child.matched)
          ? parent.id
          : []
      )
      childIds = parents.flatMap((parent) =>
        parent.school_parent_children.flatMap((child) =>
          !child.codmon_id && child.matched ? { parentId: parent.id, childId: child.id } : []
        )
      )
    } else {
      parentIds = parents.map((parent) => parent.id)
      childIds = parents.flatMap((parent) =>
        parent.school_parent_children.map((child) => ({ parentId: parent.id, childId: child.id }))
      )
    }

    ParentsPageStore.updateSelectedParentIds(parentIds)
    ParentsPageStore.updateSelectedChildIds(childIds)
  }

  /**
   * 全選択がOFFになったときに呼ばれる。
   * 保護者・子どもの選択状態をリセットする。
   */
  static resetParentsSelected(resetChild?: boolean): void {
    ParentsPageStore.updateSelectedParentIds([])
    if (resetChild) {
      ParentsPageStore.updateSelectedChildIds([])
    }
  }
}
