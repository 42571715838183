import {
  ConvertFormatResponse,
  InvoiceRegisterTemplateApi,
  ConvertAlert,
} from 'http/modules/invoice_register_template'
import { ImportPanelStore } from '../import_panel_store'
import Axios from 'axios'
import { SnackbarService } from 'shared/services/snackbar_service'
import { EventTracker } from 'shared/utils/_event_tracking'

export type ConvertAlertMessage = {
  index?: string
  message: string
}

/**
 * excel -> csvに変換するロジックを持ちます。
 * 暫定対応のため単一のサービスクラスにまとめます。
 */
export class ExcelConvertService {
  static async convertTemplateFileFormat(facilityId: number): Promise<void> {
    ImportPanelStore.updateIsConvertingToCsv(true)
    ImportPanelStore.updateConvertToCsvErrors([])
    if (!ImportPanelStore.uploadedExcel.length) return
    try {
      const response: ConvertFormatResponse = await InvoiceRegisterTemplateApi.convertFormat(
        facilityId,
        ImportPanelStore.uploadedExcel[0],
        ImportPanelStore.targetYearAndMonth
      )

      ImportPanelStore.updateConvertToCsvErrors(response.data.errors)
      if (ImportPanelStore.convertToCsvErrors.filter((e) => e.type !== 'report').length === 0) {
        ExcelConvertService.downloadCsv(response.data.csv, response.data.file_name)
      }
    } catch (error) {
      // @ts-ignore
      if (Axios.isAxiosError(error) && error?.response?.data?.message) {
        // @ts-ignore
        SnackbarService.open(error.response.data.message, 'error')
        return
      }
      SnackbarService.open('変換に失敗しました。', 'error')
      return
    } finally {
      ImportPanelStore.updateIsConvertingToCsv(false)
      ImportPanelStore.updateUploadedExcel([])
    }
  }

  static downloadCsv(csv: string, fileName: string): void {
    const bom = new Uint8Array([0xef, 0xbb, 0xbf])
    const url = window.URL.createObjectURL(new Blob([bom, csv], { type: 'text/csv' }))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
  }

  /* eslint-disable max-lines-per-function */
  static buildErrorMessage(error: ConvertAlert): ConvertAlertMessage {
    const colName = error.col ? ExcelConvertService.convertFromNumberToColumnName(error.col) : ''
    switch (error.message) {
      case 'empty_worksheet':
        return {
          message:
            'ヘッダー（項目名）以外の内容が入力されていません。誤ったファイルを選択していないかご確認ください。',
        }
      case 'invalid_format_or_empty_invoice_item':
        return {
          message:
            '指定のテンプレートを使用していないか、請求項目が入力されていません。誤ったファイルを選択していないかご確認ください。',
        }
      case 'invalid_format':
        return {
          message:
            '指定のテンプレートを使用していない可能性があります。ステップ①でダウンロードしたテンプレートをご利用ください。',
        }
      case 'empty_invoice_item_prices':
        return {
          message:
            '単価が入力されていません。ステップ①でダウンロードしたテンプレートにご入力の上、変換してください。',
        }
      case 'skip_same_value':
        return {
          index: `・${error.row}行、${colName}列 `,
          message: '重複している、もしくは単価が0の請求項目をスキップしました。',
        }
      case 'no_exist_school_parent_child':
        return {
          index: `・${error.row}行`,
          message:
            '  誤った子どもIDが入力されています。テンプレートにもともと記載されていた子どもIDに修正してください。',
        }
      case 'invalid_unit_price':
        return {
          index: `・${error.row}行、${colName}列`,
          message:
            '  単価または数量が、空欄か数字以外の文字になっています。正しい値を数字で入力するか、または請求が不要な場合は、単価・数量を両方とも空欄にしてください。',
        }
      case 'empty_invoice_item_name':
        return {
          index: `・${error.row}行、${colName}列`,
          message: '  請求項目名が空です。正しい請求項目名を入力してください。',
        }
      case 'invalid_minus_total_price':
        return {
          index: `・${error.row}行`,
          message:
            '  請求金額の合計額がマイナスになっているため請求ができません。内容を修正してください。',
        }
      case 'paid_invoice':
        return {
          index: `・${error.row}行`,
          message: `${error.child} さんは支払い済みのためスキップしました。`,
        }
      case 'unpaid_invoice':
        EventTracker.trackEvent('unpaid_error_when_convert_excel_to_csv', {})
        return {
          index: `・${error.row}行`,
          message: `${error.child} さんは未払いのためスキップしました。`,
        }
      case 'child_id_empty':
        return {
          index: `・${error.row}行`,
          message: '子どもIDが空欄のためスキップしました。',
        }
      default:
        return { message: '不正なデータがあります。' }
    }
  }

  // excelの列番号を、列名（A,B,C...AA,AB,AC...）に変換する
  static convertFromNumberToColumnName(index: number): string {
    const surplus = index % 26
    if (index > 26) {
      // 桁数分計算するまで再帰的に呼び出す
      return `${ExcelConvertService.convertFromNumberToColumnName(
        (index - surplus) / 26
        // 数字のcharcode + 64で目的のalphabetになる
      )}${String.fromCharCode(64 + surplus)}`
    }
    return `${String.fromCharCode(64 + surplus)}`
  }
}
