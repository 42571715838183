import { reactive } from 'vue'
import { ParentAccount } from 'http/modules/parent_account'

type ParentIdPrintPageState = {
  isLoading: boolean
  parentAccount: ParentAccount | undefined
  qrCodeImageSource: string
  canUseEnpayWallet: boolean
}

/**
 * LINE登録用ID印刷ページページの状態。
 */
const state = reactive<ParentIdPrintPageState>({
  isLoading: false,
  parentAccount: undefined,
  qrCodeImageSource: '',
  canUseEnpayWallet: false,
})

export class ParentIdPrintPageStore {
  static get isLoading(): boolean {
    return state.isLoading
  }

  static updateIsLoading(isLoading: boolean): void {
    state.isLoading = isLoading
  }

  static get parentAccount(): ParentAccount | undefined {
    return state.parentAccount
  }

  static updateParentAccount(parentAccount: ParentAccount): void {
    state.parentAccount = parentAccount
  }

  static get qrCodeImageSource(): string {
    return state.qrCodeImageSource
  }

  static updateQrCodeImageSource(qrCodeImageSource: string): void {
    state.qrCodeImageSource = qrCodeImageSource
  }

  static get canUseEnpayWallet(): boolean {
    return state.canUseEnpayWallet
  }

  static updateCanUseEnpayWallet(canUseEnpayWallet: boolean): void {
    state.canUseEnpayWallet = canUseEnpayWallet
  }
}
