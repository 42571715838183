<template>
  <div class="bar">
    <div class="d-flex flex-row-reverse px-12 py-4">
      <PrintButton size="x-large" />
    </div>
    <v-divider />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import PrintButton from './PrintButton.vue'

export default defineComponent({
  components: {
    PrintButton,
  },
})
</script>
<style lang="scss" scoped>
@media print {
  .bar {
    display: none;
  }
}
.bar {
  background-color: white;
}
</style>
