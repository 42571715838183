import { YearMonthRange } from 'http/modules/corporations_transfer'

export class CorporationTransferStatementApi {
  /**
   * ログインしている法人に所属する全施設の振込明細をダウンロードします。
   */
  static downloadTransferStatementCsv(targetYearMonthRange: YearMonthRange): void {
    window.location.href =
      location.origin +
      '/api/v1/corporations/me/transfer_statement.csv?from=' +
      targetYearMonthRange.from +
      '&to=' +
      targetYearMonthRange.to
  }
}
