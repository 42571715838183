/**
 * ファイルの種類をチェックするサービス
 * @param file ファイル
 */
export class FileService {
  static isCsvFile(file: File): boolean {
    const csvMimeType = 'text/csv'
    const csvExtensionRegex = /\.csv$/i
    return file.type === csvMimeType || csvExtensionRegex.test(file.name)
  }

  static isExcelFile(file: File): boolean {
    const excelMimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    const excelExtensionRegex = /\.xlsx$/i
    return file.type === excelMimeType || excelExtensionRegex.test(file.name)
  }
}
