<template>
  <v-row>
    <v-col cols="2">
      <div class="batchActions">
        <Button
          :ripple="false"
          rounded
          variant="outlined"
          class="mb-3 no-background-hover"
          data-testid="invoice-seq"
        >
          {{ getSeq }}回目
        </Button>
      </div>
      <p class="text-primary mb-1 d-flex justify-center" data-testid="invoice-total-price">
        <AmountLabel :amount="calculateTotalPriceOfInvoice(invoice)" :size="'L'" />
      </p>
      <Button
        v-if="isPaidInvoice(invoice)"
        :href="'/facilities/' + facility_id + '/invoices/' + invoice.id + '/receipt'"
        class="mb-3"
        variant="outlined"
        size="small"
        target="_blank"
        @click="$eventTracker.trackEvent('click_btn_sending_receipt', {})"
      >
        領収書発行
      </Button>
      <p v-if="isPaidInvoice(invoice)" class="blue-grey-text text-caption">
        支払日 : {{ formatDateTime(displayedPaidAt(invoice)) }}
      </p>
      <p v-else-if="isSentInvoice(invoice)" class="blue-grey-text text-caption">
        請求日 : {{ formatDateTime(invoice.sent_at) }}
      </p>
      <div v-if="invoiceEditable()">
        <Button size="small" @click="showInvoiceFormModal()">詳細を開く</Button>
      </div>
    </v-col>
    <v-col cols="10">
      <v-row>
        <v-col v-if="convenienceStoreFeeOfInvoice > 0" cols="12">
          <v-row>
            <v-col cols="6" class="py-3 text-h6 cvs-store-fee-text">
              <p class="text-left mb-0 text-black">コンビニ支払い手数料</p>
            </v-col>
            <v-col cols="6" class="py-3 text-primary text-h5">
              <p class="text-right mb-5">
                <AmountLabel :amount="convenienceStoreFeeOfInvoice" :size="'M'" />
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="getInvoiceItems().length > 0" cols="6" data-testid="child-row">
          <v-row class="text-primary text-h5">
            <v-col>
              <p class="text-right mb-5" data-testid="total-price-of-child">
                <AmountLabel :amount="calculateTotalAmountWithChildId()" :size="'M'" />
              </p>
            </v-col>
          </v-row>
          <v-divider class="mb-5" />
          <v-row v-for="invoice_item in getInvoiceItems()" :key="invoice_item.id" class="mt-3">
            <v-col cols="6" class="py-0">
              <p class="text-left mb-1" data-testid="invoice-item-name">{{ invoice_item.name }}</p>
            </v-col>
            <v-col cols="6" class="py-0">
              <p class="text-right mb-1" data-testid="invoice-item-subtotal">
                <AmountLabel :amount="calculateInvoiceItemSubtotal(invoice_item)" :size="'S'" />
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-for="child in parent.school_parent_children"
          :key="child.id"
          cols="6"
          data-testid="child-row"
        >
          <v-row class="text-primary text-h5">
            <v-col cols="6">
              <p class="text-left mb-0" data-testid="child-name">{{ child.first_name }}</p>
            </v-col>
            <v-col cols="6">
              <p class="text-right mb-5" data-testid="total-price-of-child">
                <AmountLabel :amount="calculateTotalAmountWithChildId(child.id)" :size="'M'" />
              </p>
            </v-col>
          </v-row>
          <v-divider class="mb-5" />
          <v-row
            v-for="invoice_item in getInvoiceItems(child.id)"
            :key="invoice_item.id"
            class="mt-3"
          >
            <v-col cols="6" class="py-0">
              <p class="text-left mb-1" data-testid="invoice-item-name">{{ invoice_item.name }}</p>
            </v-col>
            <v-col cols="6" class="py-0">
              <p class="text-right mb-1" data-testid="invoice-item-subtotal">
                <AmountLabel :amount="calculateInvoiceItemSubtotal(invoice_item)" :size="'S'" />
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { SchoolParentService } from '../services/school_parent_service'
import { InvoiceService } from '../services/invoice_service'
import { InvoiceItemInputService } from 'pages/top_page/services/invoice_item_input_service'
import { InvoiceItemService } from 'pages/top_page/services/invoice_item_service'
import AmountLabel from 'shared/components/AmountLabel.vue'
import { InvoiceModalService } from '../services/invoice_modal_service'
import { FilterService } from 'shared/services/filter_service'
import { SchoolService } from '../services/school_service'
import Button from 'shared/components/Button.vue'

export default {
  components: {
    AmountLabel,
    Button,
  },
  props: {
    parentId: Number,
    invoiceId: Number, // 未保存の新規請求書の場合にはここがundefinedの場合がある
  },
  data: () => ({
    facility_id: SchoolService.getTargetFacilityId(),
  }),
  computed: {
    parent() {
      return SchoolParentService.getParentById(this.parentId)
    },
    invoice() {
      return this.parent.invoices.find((invoice) => invoice.id === this.invoiceId)
    },
    getSeq() {
      if (!this.invoice) {
        return this.parent.invoices.length + 1
      }
      return this.invoice.seq
    },
    convenienceStoreFeeOfInvoice() {
      return this.calculateConvenienceStoreFeeOfInvoice(this.invoice)
    },
  },
  methods: {
    formatDateTime: function (date) {
      return FilterService.formatDateTime(date)
    },
    /**
     * @param {number | undefined} childId
     * @returns {Array} invoice_itemの配列か、invoice_item_inputの配列を返します。
     */
    getInvoiceItems(childId) {
      if (!this.invoice) {
        return InvoiceItemInputService.getItemInputs(this.parentId, childId).filter(
          (item) => item.name
        )
      }

      return InvoiceService.getInvoiceItemsLegacy(this.parent, this.invoice, childId).filter(
        // コンビニ支払い手数料は子どもごとの請求内容としては表示しない
        (item) => !item.is_convenience_store_fee && item.name
      )
    },
    /**
     * @param {number | undefined} childId school_parent_child_idのこと
     * @returns {number}
     */
    calculateTotalAmountWithChildId(childId) {
      if (!this.invoice) {
        return InvoiceItemInputService.calculateInputTotalAmountOfChild(this.parentId, childId)
      }
      return InvoiceItemService.calculateTotalAmountOfChild(this.invoiceId, childId)
    },
    calculateTotalPriceOfInvoice(invoice) {
      if (!invoice) {
        return InvoiceItemInputService.calculateInputTotalAmountByParentId(this.parentId)
      }
      return InvoiceService.calculateTotalAmountOfInvoiceLegacy(invoice.id)
    },
    calculateConvenienceStoreFeeOfInvoice(invoice) {
      if (!invoice) {
        return 0
      }
      return InvoiceService.calculateConvenienceStoreFeeOfInvoice(invoice)
    },
    isPaidInvoice(invoice) {
      if (!invoice) return false
      return invoice.status === 'paid' && invoice.paid_at
    },
    isSentInvoice(invoice) {
      if (!invoice) return false
      return invoice.status === 'unpaid' && invoice.sent_at
    },
    displayedPaidAt(invoice) {
      if (!invoice) return false
      return invoice.displayed_paid_at || invoice.paid_at
    },
    showInvoiceFormModal() {
      const status = typeof this.invoiceId === 'undefined' ? 'before_register' : this.invoice.status
      InvoiceModalService.openInvoiceFormModal(this.parentId, status)
    },
    closeInvoiceFormModal() {
      InvoiceModalService.closeInvoiceFormModal()
    },
    invoiceEditable() {
      if (!this.invoice) return true
      return this.invoice.status !== 'paid'
    },
    calculateInvoiceItemSubtotal: function (invoice_item) {
      if (typeof invoice_item.unit_price === 'number') {
        return InvoiceItemService.calculateSubtotal(invoice_item.count, invoice_item.unit_price)
      }
      return InvoiceItemInputService.calculateInputSubtotal(
        invoice_item.count,
        invoice_item.unit_price
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.no-background-hover {
  cursor: default;
  &::before {
    background-color: transparent !important;
  }
}
.cvs-store-fee-text {
  color: #232323;
}
</style>
