import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isBetween from 'dayjs/plugin/isBetween'
import { computed, ref, SetupContext } from 'vue'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isBetween)

export type YearMonthDropdownPropType = {
  from: dayjs.Dayjs
  to: dayjs.Dayjs
}

type SelectableYearMonths = {
  year: number
  months: number[]
}

const buildSelectableYearMonths: (
  yearMonthRange: YearMonthDropdownPropType
) => SelectableYearMonths[] = (yearMonthRange) => {
  const { to } = yearMonthRange
  const from = to.isAfter(yearMonthRange.from) ? yearMonthRange.from : to.clone()
  const requiredYearCount = to.year() - from.year() + 1
  return [...Array(requiredYearCount)]
    .map((_, yearIndex) => ({
      year: from.year() + yearIndex,
      months: [...Array(12)].flatMap((_, monthIndex) =>
        dayjs(new Date(from.year() + yearIndex, monthIndex, 1)).isBetween(from, to, null, '[]')
          ? monthIndex + 1
          : []
      ),
    }))
    .reverse()
}

export default function useYearMonthDropdown(
  props: Readonly<YearMonthDropdownPropType>,
  context: SetupContext
) {
  const isShowYearMonthOptions = ref(false)

  const selectableYearMonths = computed(() =>
    buildSelectableYearMonths({ from: props.from, to: props.to })
  )

  const selectYearMonth = (year: number, month: number) => {
    isShowYearMonthOptions.value = false
    context.emit('clickMonth', year, month)
  }

  return { isShowYearMonthOptions, selectableYearMonths, selectYearMonth }
}
