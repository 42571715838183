<template>
  <div class="d-flex justify-end">
    <template v-if="!isLoading">
      <div class="d-flex align-center mr-4">
        <div class="mr-8">
          <span class="font-weight-medium mr-2">合計金額</span>
          <span class="font-weight-bold"
            >{{ invoiceItemSummary.total.amount.toLocaleString() }}円</span
          >
        </div>
        <span class="font-weight-medium"
          >{{
            endIndex !== 0 ? startIndex.toLocaleString() + '-' + endIndex.toLocaleString() : 0
          }}件 / {{ invoiceItemSummary.total.count.toLocaleString() }}件</span
        >
      </div>
    </template>
    <div v-else class="d-flex align-self-center">
      <v-skeleton-loader
        type="text@2"
        class="mr-10"
        style="background-color: initial"
      ></v-skeleton-loader>
      <v-skeleton-loader
        type="text@2"
        class="mr-10"
        style="background-color: initial"
      ></v-skeleton-loader>
    </div>
    <ActionButtonArea
      :is-facility="isFacility"
      :total-count="invoiceItemSummary.total.count"
      :is-loading="isLoading"
      @on-click-search-filtered-download="onClickSearchFilteredDownload"
      @on-click-group-by-download="onClickGroupByDownload"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import ActionButtonArea from 'pages/export_invoice_items_page/areas/summary_area/components/ActionButtonArea.vue'
import {
  InvoiceItemSummary,
  InvoiceItem,
  GroupByDownloadParams,
  ExportInvoiceItemsGaEvent,
} from 'pages/export_invoice_items_page/model'

export default defineComponent({
  components: {
    ActionButtonArea,
  },
  props: {
    isFacility: {
      type: Boolean,
      required: true,
    },
    invoiceItemSummary: {
      type: Object as PropType<InvoiceItemSummary>,
      required: true,
    },
    invoiceItems: {
      type: Array as PropType<InvoiceItem[]>,
      required: true,
    },
    startIndex: {
      type: Number,
      required: true,
    },
    endIndex: {
      type: Number,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['onClickSearchFilteredDownload', 'onClickGroupByDownload'],
  setup(_, { emit }) {
    const onClickSearchFilteredDownload = (eventName: ExportInvoiceItemsGaEvent) => {
      emit('onClickSearchFilteredDownload', eventName)
    }
    const onClickGroupByDownload = (
      key: GroupByDownloadParams['groupBy'],
      eventName: ExportInvoiceItemsGaEvent
    ) => {
      emit('onClickGroupByDownload', key, eventName)
    }

    return {
      onClickSearchFilteredDownload,
      onClickGroupByDownload,
    }
  },
})
</script>
