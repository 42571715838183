<template>
  <div>
    <Button color="normal" variant="tonal" @click="openTransferStatementDownloadDialog()">
      <v-icon start> mdi-download </v-icon>
      振込明細一覧ダウンロード
    </Button>
    <TransferStatementDownloadDialog />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { TransferStatementDialogService } from '../services/transfer_statement_dialog_service'
import TransferStatementDownloadDialog from './TransferStatementDownloadDialog.vue'
import { EventTracker } from 'shared/utils/_event_tracking'
import Button from 'shared/components/Button.vue'

export default defineComponent({
  components: {
    TransferStatementDownloadDialog,
    Button,
  },
  setup() {
    // methods
    const openTransferStatementDownloadDialog = () => {
      EventTracker.trackEvent('click_transfer_statement_dl_btn_on_corp', {})
      TransferStatementDialogService.updateIsShow(true)
    }
    return { openTransferStatementDownloadDialog }
  },
})
</script>
