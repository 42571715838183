<template>
  <div>
    <Button
      class="text-body-2 bulk-exec-button"
      variant="outlined"
      @click="$emit('bulkLockInvoices')"
    >
      <v-icon size="small"> mdi-lock </v-icon>&nbsp;
      <span class="font-weight-bold">すべての施設の請求を締める</span>
    </Button>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import Button from 'shared/components/Button.vue'

export default defineComponent({
  components: {
    Button,
  },
  emits: ['bulkLockInvoices'],
})
</script>
<style lang="scss" scoped>
.bulk-exec-button {
  background-color: white;
}
</style>
