<template>
  <div class="d-flex justify-end align-end" :class="amountClassMapping">
    {{ commaSeparatedAmount }}<span :class="unitClassMapping">円</span>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue'

/**
 * 数値をカンマ区切りで表示し、単位「円」と共に表示します。
 * 単位「円」は数値と同じ大きさで表示されます。
 * amountがundefinedの場合には「--- 円」と表示します。
 * amountにはnumber型またはundefinedを渡してください。
 * size には https://vuetifyjs.com/en/styles/text-and-typography/#text-and-typography にある class を指定してください。
 * size に class を指定した場合は金額と単位の両方に指定した class が適用されます。
 */
const props = defineProps<{
  amount: undefined | number
  size: 'text-subtitle-2' | 'text-caption'
}>()

// data
const amountClassMapping = {
  [props.size]: true,
}
const unitClassMapping = {
  [props.size]: true,
}

// computed
const commaSeparatedAmount = computed(() =>
  props.amount === undefined ? '---' : props.amount.toLocaleString('ja-JP')
)
</script>
