import { InvoiceStatus } from 'shared/models'
import { PaymentMethodKey, PaymentMethodValue } from 'shared/consts/string/payment_method'

export type InvoiceItemsResponse = {
  id: number
  name: string
  count: number
  unit_price: number
  price: number
  invoice: {
    id: number
    target_year_and_month: string
    total_price: number
    seq: number
    payment_method: PaymentMethodKey
    sent_at: string
    paid_at: string
  }
  facility: {
    id: number
    name: string
  }
  school_parent: {
    id: number
    first_name: string
    last_name: string
  }
  school_parent_child: {
    id: number
    code: string
    first_name: string
  }
  school_class: {
    id: number
    name: string
  }
  school_master_invoice_item: {
    id: number
    code: string
  }
}[]

export type InvoiceItemSummaryResponse = {
  total: {
    amount: number
    count: number
    pages: {
      invoice_items: number
      school_master_invoice_items: number
      school_invoice_item_histories: number
    }
  }
}

export type InvoiceItem = {
  facilityId: number
  facilityName: string
  targetYearAndMonth: string
  lastName: string
  firstName: string
  className: string
  childId: number | '-'
  childCode: string
  childName: string
  seq: number
  sentAt: string
  paymentMethod: PaymentMethodValue
  paidAt: string
  invoiceItemName: string
  masterInvoiceItemCode: string
  count: string
  unitPrice: string
  price: string
}

export type InvoiceItemSummary = InvoiceItemSummaryResponse

export type SearchYearAndMonthType = 'from' | 'to'

export type TermType = 'target_year_and_month' | 'paid_at'

export type YearAndMonthRange = {
  years: number[]
  months: number[]
}

export type SelectableMonths = {
  selectableFromMonth: number[]
  selectableToMonth: number[]
}

export type InvoiceItemsApiCommonParams = {
  from?: string
  to?: string
  termType?: TermType
  includeKeyword: boolean
  itemNames?: string[]
  status?: InvoiceStatus[]
}

export type FacilityIdParams = {
  single: { facilityId: number }
  multiple: { facilityIds: number[] }
}

export type PagingParams = {
  index: { page?: number; perPage: 50 }
  summary: { perPage: 50 }
}

export type GroupByDownloadParams = {
  groupBy?:
    | ['facility_id', 'item_name']
    | ['class_name', 'item_name']
    | ['facility_id']
    | ['item_name']
}

export type YearAndMonth = {
  year: number | null
  month: number | null
}

export type TargetItem = 'chargedMonth' | 'paidMonth'

export type IncludeStatusType = 'include' | 'not_include'

export type InvoiceItemHistoriesResponse = {
  id: number
  name: string
  school_id: number
}

export type MasterInvoiceItemResponse = {
  id: number
  name: string
  school_id: number
  unit_price: number
}

export type QueryParams = {
  from: string
  to: string
  termType: TermType
  status: InvoiceStatus[]
  includeKeyword: boolean
  itemNames: string[]
  facilityIds: number[]
}

export type QueryParamsKey =
  | 'from'
  | 'to'
  | 'term_type'
  | 'status'
  | 'include_keyword'
  | 'item_names'
  | 'facility_ids'

export type ConvertToQueryParamsServiceReturn = QueryParams

export type ExportInvoiceItemsGaEvent =
  | 'export_raw_invoice_items_on_facility'
  | 'export_raw_invoice_items_on_corporation'
  | 'export_invoice_items_by_facility'
  | 'export_invoice_items_by_facility_x_item'
  | 'export_invoice_items_by_item'
  | 'export_invoice_items_by_item_x_class'

export type SwitchMode = 'isPagination' | 'isRefresh'

export const isTermType = (value: string): value is TermType =>
  value === 'target_year_and_month' || value === 'paid_at'

export const isInvoiceStatus = (value: string): value is InvoiceStatus =>
  value === 'unpaid' || value === 'paid' || value === 'before_charge'
