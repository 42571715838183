import { FacilityApi } from 'http/modules/facility'
import { InvoicePrintPageStore } from 'pages/invoice_print_page/invoice_print_page_store'
import { RouteService } from 'shared/services/route_service'

export class ConvenienceStoreFeeStatusService {
  static async loadParentConvenienceFeeApplyStatus() {
    const facilityId = Number(RouteService.getPathParamByIndex(1))
    const response = await FacilityApi.show(facilityId)
    InvoicePrintPageStore.updateParentConvenienceStoreFeeApplyStatus(
      response.data.parent_convenience_store_fee_apply_status
    )
  }
}
