import { HTTP } from '../common'

export type CsvFileMetadata = {
  start_rows: string[][]
  end_rows: string[][]
  total_count: number
}

export type PostResponse = {
  data: CsvFileMetadata
}

export class UploadCsvApi {
  static post(facilityId: number, file: File, targetYearAndMonth: string): Promise<PostResponse> {
    const params = new FormData()
    params.append('file', file)
    params.append('target_year_and_month', targetYearAndMonth)
    return HTTP.post(`/facilities/${facilityId}/upload_csv`, params)
  }
}
