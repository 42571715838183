import { TransferPageStore } from '../transfer_page_store'
import { CorporationsTransferApi, YearMonthRange } from 'http/modules/corporations_transfer'
import { CorporationTransferStatementApi } from 'http/modules/corporations_transfer_statement'
import { SnackbarService } from 'shared/services/snackbar_service'
import { ScreenLoadingService } from 'shared/services/screen_loading_service'

export class TransferStatementDialogService {
  static updateIsShow(isShow: boolean): void {
    // 表示切り替えの時にエラーフラグもクリアする
    TransferPageStore.updateTransferStatementDialogState({
      ...TransferPageStore.transferStatementDialogState,
      isShow,
    })
  }

  static updateTargetYearMonthRange(targetYearMonthRange: YearMonthRange): void {
    TransferPageStore.updateTransferStatementDialogState({
      ...TransferPageStore.transferStatementDialogState,
      targetYearMonthRange,
    })
  }

  static async downloadTransferStatementCsv() {
    try {
      const transfersLimitOne = await CorporationsTransferApi.index(
        TransferPageStore.transferStatementDialogState.targetYearMonthRange,
        undefined,
        undefined,
        1
      )
      if (transfersLimitOne.data.hits.length === 0) {
        SnackbarService.open('期間内に振込明細が存在しません', 'error')
      } else {
        CorporationTransferStatementApi.downloadTransferStatementCsv(
          TransferPageStore.transferStatementDialogState.targetYearMonthRange
        )
      }
    } catch (error) {
      SnackbarService.open('振込明細の取得に失敗しました', 'error')
    } finally {
      ScreenLoadingService.hideLoader()
    }
  }
}
