<template>
  <div>
    <p>{{ lastName }} {{ childFirstName }}さんのコドモン連携を解除します。よろしいですか？</p>
    <p class="mt-5">請求データの連携も解除されます。</p>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    lastName: {
      type: String,
      required: true,
    },
    childFirstName: {
      type: String,
      required: true,
    },
  },
})
</script>
