import { UploadCsvApi } from 'http/modules/upload_csv'
import { SnackbarService } from 'shared/services/snackbar_service'
import { ImportPanelStore } from '../import_panel_store'
import { columnMappingSaveKeyPrefix, ImportPanelService } from './import_panel_service'
import rfdc from 'rfdc'
import { ImportInvoicesPageStore } from 'pages/import_invoices_page/import_invoices_page_store'
import { SchoolService } from 'pages/top_page/services/school_service'
import { isAxiosError } from 'http/modules/invoice'
const clone = rfdc()

/**
 * 取り込み時に用いたカラムマッピングの値。1行目のどのような文字列にどのcolumn_idを紐付けたか、というデータ。
 * key: CSVの1行目の文字列
 * value: CandidateOption#column_id
 */
type ColumnMappingValues = {
  [key: string]: number
}

/**
 * CSVアップロードパネルに必要なロジックを持ちます
 */
export class CsvUploadService {
  /* eslint-disable require-atomic-updates */
  /* eslint-disable-next-line max-lines-per-function */
  static async uploadCsv(): Promise<void> {
    if (
      ImportPanelStore.importTargetFile === undefined ||
      !ImportPanelStore.importTargetFile.length
    )
      return
    ImportPanelStore.updateIsUploading(true)
    let metadata
    try {
      metadata = (
        await UploadCsvApi.post(
          SchoolService.getTargetFacilityId(),
          ImportPanelStore.importTargetFile[0],
          ImportInvoicesPageStore.targetYear + '-' + ImportInvoicesPageStore.targetMonth
        )
      ).data
    } catch (error) {
      if (isAxiosError(error) && error.response !== undefined) {
        const message =
          error.response.status === 403
            ? '請求が締められているため、請求内容の編集はできません'
            : 'アップロードに失敗しました'
        SnackbarService.open(message, 'error')
      }
      return
    } finally {
      ImportPanelStore.updateIsUploading(false)
    }
    if (metadata.total_count === 0) {
      SnackbarService.open('空ではないCSVファイルを選択してください', 'error')
      return
    }
    const colCount = metadata.start_rows[0].length
    ImportPanelStore.updateCsvFileMetadata({ ...metadata, colCount })
    ImportPanelStore.updateMappedColumnIds(Array(colCount))
    ImportPanelStore.updateAutoMappingStatuses(Array(colCount).fill(false))

    // カラムの自動マッピング処理
    const columnMappingValues = JSON.parse(
      localStorage.getItem(columnMappingSaveKeyPrefix + ImportPanelStore.columnMappingSaveKey) ??
        '{}'
    ) as ColumnMappingValues

    // CSVの1行目の各セルでforを回す
    metadata.start_rows[0].forEach((cellValue, index) => {
      const notMappedCandidates = ImportPanelStore.columnCandidates.filter((candidate) =>
        candidate.options.every(
          (option) => !ImportPanelStore.mappedColumnIds.includes(option.column_id)
        )
      )
      // 前回値で推測する
      if (
        columnMappingValues[cellValue] !== undefined &&
        notMappedCandidates.find((candidate) =>
          candidate.options.some((option) => option.column_id === columnMappingValues[cellValue])
        )
      ) {
        const mappedColumnIds = clone(ImportPanelStore.mappedColumnIds)
        mappedColumnIds[index] = columnMappingValues[cellValue]
        ImportPanelStore.updateMappedColumnIds(mappedColumnIds)

        const autoMappingStatuses = clone(ImportPanelStore.autoMappingStatuses)
        autoMappingStatuses[index] = true
        ImportPanelStore.updateAutoMappingStatuses(autoMappingStatuses)
        return
      }
      // CSVの1行目の文字列とcolumnCandidateのcolumn_nameの文字列を比較して推測する
      const presumedCandidate = notMappedCandidates
        .flatMap((candidate) => candidate.options)
        .find(
          (candidate) =>
            cellValue && candidate.column_name.toLowerCase() === cellValue.toLowerCase()
        )
      if (presumedCandidate !== undefined) {
        const mappedColumnIds = clone(ImportPanelStore.mappedColumnIds)
        mappedColumnIds[index] = presumedCandidate.column_id
        ImportPanelStore.updateMappedColumnIds(mappedColumnIds)
        const autoMappingStatuses = clone(ImportPanelStore.autoMappingStatuses)
        autoMappingStatuses[index] = true
        ImportPanelStore.updateAutoMappingStatuses(autoMappingStatuses)
      }
    })
    ImportPanelService.incrementStep()
  }
}
