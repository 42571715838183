<template>
  <div>
    <template v-if="selectedCodmon === '0'">
      <p>
        コドモン園児台帳でデータ連携の設定を行ったあと、コドモンに登録されている子どもを選択してください。
      </p>
      <div class="mt-5">
        <SelectInput
          v-model="selectedCodmon"
          variant="outlined"
          density="compact"
          :items="codmonChildren"
          item-text="label"
          item-value="value"
          hide-details
          :loading="isGettingCodomonChildren"
          @change="onChangeCodmon"
        />
      </div>
    </template>
    <template v-else>
      <WarningAlert text="保護者名、子ども名がコドモンの登録名で上書きされます。" />
      <p class="mt-5">「連携する」を押すと、入力済みのお名前が以下のように上書きされます。</p>
      <p>内容に誤りがないか、ご確認ください。</p>
      <p><strong>保護者名</strong></p>
      <p v-if="shouldBeChangedParent()">
        [変更前] {{ parentLastName }} {{ parentFirstName }} →
        <strong class="text-red font-weight-bold"
          >[変更後] {{ selectedCodmonInfo().parent_name }}</strong
        >
      </p>
      <p v-else>変更なし</p>
      <p class="mt-5"><strong>子ども名</strong></p>
      <p v-if="shouldBeChangedChild()">
        [変更前] {{ childFirstName }} →
        <strong class="text-red font-weight-bold"
          >[変更後] {{ selectedCodmonInfo().first_name }}</strong
        >
      </p>
      <p v-else>変更なし</p>
      <p></p>
    </template>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent } from 'vue'
import { ParentsService } from 'pages/parents_page/services/parents_service'
import { ParentsPageStore } from 'pages/parents_page/parents_page_store'
import SelectInput from 'shared/components/SelectInput.vue'
import { SelectOption } from 'pages/account_settings_page/models'
import WarningAlert from 'shared/components/alert/WarningAlert.vue'

export default defineComponent({
  components: { WarningAlert, SelectInput },
  props: {
    parentLastName: {
      type: String,
      required: true,
    },
    parentFirstName: {
      type: String,
      required: true,
    },
    childFirstName: {
      type: String,
      required: true,
    },
  },

  // eslint-disable-next-line max-lines-per-function
  setup({ parentLastName, parentFirstName, childFirstName }) {
    ParentsService.loadUnconnectedCodmonChildren()

    const selectedCodmon = computed(() => `${ParentsPageStore.selectedCodmonId || '0'}`)
    const isGettingCodomonChildren = computed(() => ParentsPageStore.isGettingCodomonChildren)

    const codmonChildren = computed(() => [
      {
        value: '0',
        label: '選択してください',
      },
      ...ParentsPageStore.unconnectedCodmonChildren.map<SelectOption>((codmonChild) => ({
        value: `${codmonChild.id}`,
        label: `${codmonChild.first_name} （保護者名：${
          codmonChild.parent_name
        }）\n${codmonChild.errors.join('\n')}`,
        props: {
          disabled: codmonChild.errors.length > 0,
        },
      })),
    ])

    const getCodmonInfo = (id: string) =>
      ParentsPageStore.unconnectedCodmonChildren.find((child) => `${child.id}` === id)

    const selectedCodmonInfo = () => getCodmonInfo(selectedCodmon.value)

    const onChangeCodmon = (value: string) => {
      const selected = getCodmonInfo(value)
      ParentsPageStore.updateSelectedCodmonId(selected?.id ?? null)
    }

    const shouldBeChangedParent = () => {
      const selected = selectedCodmonInfo()
      return selected?.parent_name !== `${parentLastName} ${parentFirstName}`
    }

    const shouldBeChangedChild = () => {
      const selected = selectedCodmonInfo()
      return selected?.first_name !== childFirstName
    }

    return {
      codmonChildren,
      selectedCodmon,
      isGettingCodomonChildren,
      shouldBeChangedParent,
      shouldBeChangedChild,
      onChangeCodmon,
      selectedCodmonInfo,
    }
  },
})
</script>
<style lang="scss" scoped>
.v-list-item__title {
  white-space: pre !important;
}
</style>
