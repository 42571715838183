<template>
  <SimpleLayout title="請求内容ダウンロード" is-expanded>
    <template #headerNav>
      <FacilityHeader v-if="isFacility" />
      <CorporationHeader v-else-if="!isFacility" />
    </template>

    <template #headerContent>
      <LinkText
        :link-url="helpPageUrl"
        is-open-new-tab
        :has-underline="false"
        class="d-flex align-center"
      >
        <S3Image path="/icons/newbie.svg" class="mr-2" />使い方</LinkText
      >
    </template>

    <template #main>
      <v-sheet color="transparent" width="100%" class="mx-auto">
        <v-row class="ma-0">
          <v-col class="pa-0">
            <InvoiceItemsSearchForm
              :is-facility="isFacility"
              :invoice-item-name-candidates="invoiceItemNameCandidates"
              :available-facilities="availableFacilities"
              @update-range="updateRange"
              @refresh-page="refreshPage"
              @on-click-clear-conditions="onClickClearConditions"
            />
          </v-col>
        </v-row>
        <v-row class="ma-0 justify-end">
          <v-col class="py-8 px-0">
            <SummaryArea
              :is-facility="isFacility"
              :invoice-item-summary="invoiceItemSummary"
              :invoice-items="invoiceItems"
              :start-index="startIndex"
              :end-index="endIndex"
              :is-loading="isLoading"
              @on-click-search-filtered-download="onClickSearchFilteredDownload"
              @on-click-group-by-download="onClickGroupByDownload"
            />
          </v-col>
        </v-row>
        <div class="d-flex align-center justify-space-between mb-11">
          <v-row v-if="isLoading">
            <v-col cols="12">
              <v-skeleton-loader type="table-tbody"></v-skeleton-loader>
            </v-col>
          </v-row>
          <template v-else-if="invoiceItems.length === 0">
            <v-col class="text-center bg-white py-10">
              <S3Image path="/export_invoice_items/empty_data.svg" />
              <p class="font-weight-bold fs-24 ma-6">検索結果は0件です。</p>
              <p class="font-weight-medium mt-6 mb-0">
                検索条件エリアから、対象期間や請求内容など<br />CSVダウンロードしたい条件を選択してください。
              </p>
            </v-col>
          </template>
          <template v-else>
            <v-col class="pa-0 mb-8">
              <InvoiceItemTable
                :invoice-items="invoiceItems"
                :invoice-item-summary="invoiceItemSummary"
                :start-index="startIndex"
                :end-index="endIndex"
                @on-page-change="onPageChange"
              />
            </v-col>
          </template>
        </div>
      </v-sheet>
    </template>
  </SimpleLayout>
</template>

<script lang="ts">
import S3Image from 'shared/components/s_3_image/S3Image.vue'
import LinkText from 'shared/components/LinkText.vue'
import FacilityHeader from 'shared/components/header/FacilityHeader.vue'
import SimpleLayout from 'shared/templates/SimpleLayout.vue'
import InvoiceItemTable from 'pages/export_invoice_items_page/areas/invoice_item_table/InvoiceItemTable.vue'
import InvoiceItemsSearchForm from 'pages/export_invoice_items_page/areas/invoice_items_search_form/InvoiceItemsSearchForm.vue'
import SummaryArea from 'pages/export_invoice_items_page/areas/summary_area/SummaryArea.vue'
import CorporationHeader from 'shared/components/header/CorporationHeader.vue'
import { defineComponent, computed, onUnmounted, ComputedRef } from 'vue'
import { RouteService } from 'shared/services/route_service'
import { FacilityExportInvoiceItemsService } from 'pages/export_invoice_items_page/services/facility_export_invoice_items_service'
import {
  InvoiceItem,
  InvoiceItemSummary,
  SearchYearAndMonthType,
  GroupByDownloadParams,
  ExportInvoiceItemsGaEvent,
} from 'pages/export_invoice_items_page/model'
import { ExportInvoiceItemsPageStore } from 'pages/export_invoice_items_page/export_invoice_items_page_store'
import { CorporationExportInvoiceItemsService } from 'pages/export_invoice_items_page/services/corporation_export_invoice_items_service'
import { FacilityExportInvoiceItemSummaryService } from 'pages/export_invoice_items_page/services/facility_export_invoice_item_summary_service'
import { CorporationExportInvoiceItemSummaryService } from 'pages/export_invoice_items_page/services/corporation_export_invoice_item_summary_service'
import ExternalLink from 'shared/consts/external_link'
import { PaginationStore } from 'shared/components/pagination/pagination_store'
import { YearAndMonthRangeService } from 'pages/export_invoice_items_page/services/year_and_month_range_service'
import { CalculateYearAndMonthRangeService } from 'pages/export_invoice_items_page/services/calculate_year_and_month_range_service'
import { InvoiceItemNameCandidate, AvailableFacility } from 'shared/models'
import { ClearSearchConditionsService } from 'pages/export_invoice_items_page/services/clear_search_conditions_service'
import { FacilityDownloadInvoiceItemsService } from 'pages/export_invoice_items_page/services/facility_download_invoice_items_service'
import { CorporationDownloadInvoiceItemsService } from 'pages/export_invoice_items_page/services/corporation_download_invoice_items_service'
import { ExportInvoiceItemsUrlService } from 'pages/export_invoice_items_page/services/export_invoice_items_url_service'
import { EventTracker } from 'shared/utils/_event_tracking'
import { MetaService } from 'shared/services/meta_service'

export default defineComponent({
  components: {
    S3Image,
    LinkText,
    FacilityHeader,
    SimpleLayout,
    InvoiceItemTable,
    InvoiceItemsSearchForm,
    SummaryArea,
    CorporationHeader,
  },
  /* eslint-disable max-lines-per-function */
  setup() {
    const perPage = 50

    const isFacility = RouteService.getPathParamByIndex(0) === 'facilities'
    MetaService.addTitle({
      mode: isFacility ? 'facility' : 'corporation',
      page: 'exportInvoiceItems',
    })

    const loadInitialData = async () => {
      // 初期表示月の設定はFacilityExportInvoiceItemsService.loadInitialDataで行うので、
      // awaitで待ってからサマリーAPIを呼ぶ必要がある
      if (isFacility) {
        await FacilityExportInvoiceItemsService.loadInitialData()
        FacilityExportInvoiceItemSummaryService.loadInitialData()
      } else {
        await CorporationExportInvoiceItemsService.loadInitialData()
        CorporationExportInvoiceItemSummaryService.loadInitialData()
      }
    }

    loadInitialData()

    const invoiceItems: ComputedRef<InvoiceItem[]> = computed(
      () => ExportInvoiceItemsPageStore.invoiceItems
    )
    const invoiceItemSummary: ComputedRef<InvoiceItemSummary> = computed(
      () => ExportInvoiceItemsPageStore.invoiceItemSummary
    )
    const invoiceItemNameCandidates: ComputedRef<InvoiceItemNameCandidate[]> = computed(
      () => ExportInvoiceItemsPageStore.invoiceItemNameCandidates
    )
    const availableFacilities: ComputedRef<AvailableFacility[]> = computed(
      () => ExportInvoiceItemsPageStore.availableFacilities
    )
    const isLoading: ComputedRef<boolean> = computed(() => ExportInvoiceItemsPageStore.isLoading)
    const currentPage: ComputedRef<number> = computed({
      get: () => PaginationStore.currentPage,
      set: (page: number) => {
        PaginationStore.updateCurrentPage(page)
      },
    })
    const startIndex = computed(() => (currentPage.value - 1) * perPage + 1)
    const endIndex = computed(() =>
      Math.min(startIndex.value + perPage - 1, invoiceItemSummary.value.total.count)
    )
    const helpPageUrl = ExternalLink.openpageExportInvoiceItems

    const onPageChange = (page: number) => {
      if (isFacility) {
        FacilityExportInvoiceItemsService.loadInvoiceItems(page)
        FacilityExportInvoiceItemSummaryService.loadInvoiceItemSummary('isPagination')
      } else {
        CorporationExportInvoiceItemsService.loadInvoiceItems(page)
        CorporationExportInvoiceItemSummaryService.loadInvoiceItemSummary('isPagination')
      }
    }

    const updateRange = (value: SearchYearAndMonthType) => {
      YearAndMonthRangeService.fixInvalidYearAndMonth(value)
      CalculateYearAndMonthRangeService.setSelectableYearAndMonthRange()
    }

    // 検索条件が変更された時、その条件でAPIを叩いて結果を表示する
    // 検索された条件はフロント側のURLにも反映する
    const refreshPage = () => {
      if (isFacility) {
        ExportInvoiceItemsUrlService.overwriteQueryParams({
          isFacility: isFacility,
        })
        FacilityExportInvoiceItemsService.loadInvoiceItems()
        FacilityExportInvoiceItemSummaryService.loadInvoiceItemSummary('isRefresh')
      } else {
        ExportInvoiceItemsUrlService.overwriteQueryParams({
          isFacility: isFacility,
        })
        CorporationExportInvoiceItemsService.loadInvoiceItems()
        CorporationExportInvoiceItemSummaryService.loadInvoiceItemSummary('isRefresh')
      }
    }

    const onClickClearConditions = () => {
      ClearSearchConditionsService.clearConditions(isFacility)
      ExportInvoiceItemsUrlService.overwriteQueryParams({
        isFacility: isFacility,
      })
    }

    const onClickSearchFilteredDownload = (eventName: ExportInvoiceItemsGaEvent) => {
      if (isFacility) {
        FacilityDownloadInvoiceItemsService.searchFilteredDownload()
      } else {
        CorporationDownloadInvoiceItemsService.searchFilteredDownload()
      }
      EventTracker.trackEvent(eventName, {})
    }

    const onClickGroupByDownload = (
      key: GroupByDownloadParams['groupBy'],
      eventName: ExportInvoiceItemsGaEvent
    ) => {
      if (isFacility) {
        FacilityDownloadInvoiceItemsService.groupByDownload(key)
      } else {
        CorporationDownloadInvoiceItemsService.groupByDownload(key)
      }
      EventTracker.trackEvent(eventName, {})
    }

    onUnmounted(() => {
      ExportInvoiceItemsPageStore.updateFromYearAndMonth({
        year: null,
        month: null,
      })
      ExportInvoiceItemsPageStore.updateToYearAndMonth({
        year: null,
        month: null,
      })
      ExportInvoiceItemsPageStore.updateTargetItem('chargedMonth')
      ExportInvoiceItemsPageStore.updateInvoiceStatuses(['paid', 'unpaid', 'before_charge'])
      ExportInvoiceItemsPageStore.updateIncludeStatusType('include')
      ExportInvoiceItemsPageStore.updateSelectedInvoiceItems([])
      ExportInvoiceItemsPageStore.updateSelectedFacilityId('all')
    })

    return {
      isFacility,
      invoiceItems,
      invoiceItemSummary,
      invoiceItemNameCandidates,
      availableFacilities,
      isLoading,
      currentPage,
      startIndex,
      endIndex,
      helpPageUrl,
      onPageChange,
      updateRange,
      refreshPage,
      onClickClearConditions,
      onClickSearchFilteredDownload,
      onClickGroupByDownload,
    }
  },
})
</script>
<style scoped lang="scss">
.fs-24 {
  font-size: 24px;
}
</style>
