<template>
  <transition name="modal">
    <div
      v-if="isShowModal"
      class="modal active"
      data-testid="invoice-list-modal"
      @click.self="closeInvoicesModal()"
    >
      <v-container class="modal_container">
        <div class="modal_inner">
          <div class="panel">
            <div class="panel_inner">
              <div class="header d-flex my-3 justify-space-between">
                <p class="text-black text-h6">{{ parent.full_name }}</p>
                <p class="text-primary d-flex align-end" data-testid="invoices-total-price">
                  <span class="text-black text-h6 mr-2">合計</span>
                  <AmountLabel :amount="calculateTotalPriceOfParentInvoices(parent)" :size="'L'" />
                </p>
              </div>
              <v-divider class="my-5" />
              <div v-if="showEditingRow" class="invoices">
                <LegacyInvoiceRow :parent-id="parent.id" />
                <v-divider class="my-5" />
              </div>
              <div v-for="(invoice, index) in parent.invoices" :key="index" class="invoices">
                <LegacyInvoiceRow :parent-id="parent.id" :invoice-id="invoice.id" />
                <v-divider class="my-5" />
              </div>
              <v-tooltip location="top" :disabled="canCreateNewInvoice()">
                <template #activator="{ props }">
                  <div class="d-inline-flex mx-auto" v-bind="props">
                    <Button
                      :disabled="!canCreateNewInvoice()"
                      size="x-large"
                      class="text-h6 font-weight-bold"
                      @click="openInvoiceFormModal()"
                    >
                      新しく請求をする
                    </Button>
                  </div>
                </template>
                <span>{{ tooltipText }}</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </v-container>
    </div>
  </transition>
</template>

<script>
import LegacyInvoiceRow from './LegacyInvoiceRow.vue'
import { SchoolParentService } from '../services/school_parent_service'
import { InvoiceItemService } from '../services/invoice_item_service'
import AmountLabel from 'shared/components/AmountLabel.vue'
import { InvoiceModalService } from '../services/invoice_modal_service'
import { TopPageStore } from '../top_page_store'
import Button from 'shared/components/Button.vue'

export default {
  components: {
    LegacyInvoiceRow,
    AmountLabel,
    Button,
  },
  props: {
    parentId: Number,
    isShowModal: Boolean,
  },
  computed: {
    parent() {
      return SchoolParentService.getParentById(this.parentId)
    },
    showEditingRow() {
      return (
        (this.parent.invoices.length === 0 || this.parent.invoices[0].status === 'paid') &&
        [undefined, ...this.parent.school_parent_children.map((child) => child.id)].some(
          (childId) => InvoiceItemService.isInputModified(this.parentId, childId)
        )
      )
    },
    isInvoiceLocked() {
      return TopPageStore.isLockedMonth
    },
    tooltipText() {
      const text = {
        lock: '請求が締められているため、新しい請求はできません。',
        before_charge: '保存中の請求があるため、新しい請求ができません。',
        unpaid: '未払いの請求があるため、新しい請求ができません。',
      }

      if (this.isInvoiceLocked) return text.lock

      const invoiceStatuses = this.parent.invoices.flatMap((invoice) =>
        invoice.status !== 'paid' ? invoice.status : []
      )
      return text[invoiceStatuses[0]]
    },
  },
  methods: {
    closeInvoicesModal: function () {
      InvoiceModalService.closeInvoicesModal()
    },
    openInvoiceFormModal: function () {
      InvoiceModalService.openInvoiceFormModal(this.parentId, 'before_register')
    },
    // ボタン活性化: 「すべての請求が支払い済の場合」 && 「請求が非ロック」
    canCreateNewInvoice: function () {
      if (this.isInvoiceLocked) return false

      const invoiceStatuses = this.parent.invoices.map((invoice) => invoice.status)
      const invoiceStatusesSet = new Set(invoiceStatuses)
      return invoiceStatusesSet.size === 1 && invoiceStatusesSet.has('paid')
    },
    calculateTotalPriceOfParentInvoices(parent) {
      return SchoolParentService.calculateTotalAmountOfParentInvoicesLegacy(parent.id)
    },
  },
}
</script>
