import { TransferStatementApi } from 'http/modules/transfer_statement'
import { TransferStatementPrintPageStore } from '../transfer_statement_print_page_store'
import { RouteService } from 'shared/services/route_service'
import { SnackbarService } from 'shared/services/snackbar_service'
import { ScreenLoadingService } from 'shared/services/screen_loading_service'
import { FacilityInvoiceItemsCsvApi } from 'http/modules/facility_invoice_items_csv'
import { getFormatDate } from 'shared/utils/date'

export class TransferStatementPrintService {
  static async loadTransferStatement(targetYearAndMonth: string): Promise<void> {
    // NOTE: （Cron で振込開始月以降の月の transferStatement は存在する前提で）将来月に直接遷移された場合のガードは未考慮（用紙は表示されるが、値がうまらない）
    try {
      TransferStatementPrintPageStore.updateIsLoading(true)
      const response = (
        await TransferStatementApi.show(
          TransferStatementPrintService.getTargetFacilityId(),
          targetYearAndMonth
        )
      ).data
      TransferStatementPrintPageStore.updateTransferStatement(response.transfer_statement)
      TransferStatementPrintPageStore.updateStartCalcPaidDate(response.start_calc_paid_date)
      TransferStatementPrintPageStore.updateEndCalcPaidDate(response.end_calc_paid_date)
    } finally {
      TransferStatementPrintPageStore.updateIsLoading(false)
    }
  }

  static getTargetFacilityId(): number {
    return Number(RouteService.getPathParamByIndex(1))
  }

  static downloadInvoiceItemsCsv(): void {
    try {
      FacilityInvoiceItemsCsvApi.index({
        facilityId: TransferStatementPrintService.getTargetFacilityId(),
        from: getFormatDate(TransferStatementPrintPageStore.startCalcPaidDate, 'YYYY-MM'),
        to: getFormatDate(TransferStatementPrintPageStore.endCalcPaidDate, 'YYYY-MM'),
        termType: 'paid_at',
        itemNames: [],
        includeKeyword: false,
        status: ['paid'],
      })
    } catch (error) {
      SnackbarService.open('集金合計の内訳のダウンロードに失敗しました', 'error')
    } finally {
      ScreenLoadingService.hideLoader()
    }
  }
}
