import { reactive } from 'vue'
import { SchoolClass } from 'http/modules/class'

type ClassManagementPageState = {
  schoolClasses: SchoolClass[]
  isShowRegistrationDialog: boolean
  updateTargetClass: SchoolClass | undefined
  isShowUpdateDialog: boolean
  isUpdating: boolean
}

const state = reactive<ClassManagementPageState>({
  schoolClasses: [],
  isShowRegistrationDialog: false,
  updateTargetClass: undefined,
  isShowUpdateDialog: false,
  isUpdating: false,
})

export class ClassManagementPageStore {
  static get schoolClasses(): SchoolClass[] {
    return state.schoolClasses
  }

  static updateSchoolClasses(schoolClasses: SchoolClass[]): void {
    state.schoolClasses = schoolClasses
  }

  static get isShowRegistrationDialog(): boolean {
    return state.isShowRegistrationDialog
  }

  static updateIsShowRegistrationDialog(isShowRegistrationDialog: boolean): void {
    state.isShowRegistrationDialog = isShowRegistrationDialog
  }

  static get updateTargetClass(): SchoolClass | undefined {
    return state.updateTargetClass
  }

  static updateUpdateTargetClass(updateTargetClass: SchoolClass) {
    state.updateTargetClass = updateTargetClass
  }

  static get isShowUpdateDialog(): boolean {
    return state.isShowUpdateDialog
  }

  static updateIsShowUpdateDialog(isShowUpdateDialog: boolean): void {
    state.isShowUpdateDialog = isShowUpdateDialog
  }

  static get isUpdating(): boolean {
    return state.isUpdating
  }

  static updateIsUpdating(isUpdating: boolean): void {
    state.isUpdating = isUpdating
  }
}
