<template>
  <span
    ><a class="text-caption text-primary text-decoration-underline" :href="linkTo" target="_blank">
      {{ mode === 'facility' ? '法人画面を表示' : '施設画面を表示' }}</a
    ><v-icon size="small" color="primary">mdi-open-in-new</v-icon>
  </span>
</template>

<script setup lang="ts">
import { OperationMode } from '../models'

type OperationModeSwitchButtonPropType = {
  mode: OperationMode
  linkTo: string
}
/**
 * AppStoreのenv,oemPartnerをロードしておく必要があります
 */

defineProps<OperationModeSwitchButtonPropType>()
</script>
