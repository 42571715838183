<template>
  <ParentSelectingAllCheckBox
    v-if="isSelectedAll && invisibleChildren.length === 0 && !parent.has_codmon_children"
    :is-selected-all="isSelectedAll"
    :parent="parent"
  />
  <template v-else>
    <template v-if="!parent.has_codmon_children">
      <v-checkbox
        :key="parent.id"
        v-model="selectedParentIds"
        :value="parent.id"
        :disabled="invisibleChildren.length > 0"
        hide-details
        class="shrink"
      >
        <template #label>
          <v-card-title class="text-h6 font-weight-bold" style="color: #000000">
            {{ parent.last_name }} {{ parent.first_name }}
          </v-card-title>
        </template>
      </v-checkbox>
    </template>
    <template v-else>
      <v-tooltip location="top">
        <template #activator="{ props: tooltipProps }">
          <div v-bind="tooltipProps">
            <v-checkbox
              :key="parent.id"
              v-model="selectedParentIds"
              :value="parent.id"
              :disabled="parent.has_codmon_children"
              hide-details
              class="shrink"
            >
              <template #label>
                <v-card-title class="text-h6 font-weight-bold">
                  {{ parent.last_name }} {{ parent.first_name }}
                </v-card-title>
              </template>
            </v-checkbox>
          </div>
        </template>
        <span>コドモン連携している子どもが存在するため、削除できません。</span>
      </v-tooltip>
    </template>
  </template>
</template>
<script setup lang="ts">
import { computed, defineProps } from 'vue'
import { Parent, Child } from 'pages/parents_page/model'
import { ParentsPageStore } from 'pages/parents_page/parents_page_store'
import ParentSelectingAllCheckBox from 'pages/parents_page/areas/parent_card/components/ParentSelectingAllCheckBox.vue'

const props = defineProps<{
  parent: Parent
  isSelectedAll: boolean
  invisibleChildren: Child[]
}>()
const emit = defineEmits(['onChangeParentSelected'])

const selectedParentIds = computed({
  get: () => ParentsPageStore.selectedParentIds,
  set: (value) => emit('onChangeParentSelected', value, props.parent),
})
</script>
<style scoped lang="scss">
.theme--light.v-card .v-card__subtitle {
  font-size: 16px;
  color: #727272;
}
.v-card__title {
  padding: 0 10px 0 0;
}
.v-input--selection-controls {
  margin: 0 8px 0 0 !important;
}
</style>
