<template>
  <div>
    <div v-if="inputMode === 'default'">
      <span class="text-grey font-weight-medium text-body-2 mb-2">クラス</span>
      <div class="class-container">
        <v-checkbox
          v-model="allClassesSelected"
          hide-details
          label="すべて選択"
          density="compact"
          data-testid="select-all-class"
        />
        <v-divider vertical />
        <div class="d-flex flex-wrap gap-x-5">
          <v-checkbox
            v-for="item in classes"
            :key="item.id"
            :model-value="selectedClassIds"
            hide-details
            :label="item.name"
            :value="item.id"
            class="flex-grow-0"
            density="compact"
            data-testid="select-class"
            @update:model-value="updateClassCondition"
          />
        </div>
      </div>
    </div>
    <div v-else-if="inputMode === 'compact'">
      <label for="class-filter" class="text-grey font-weight-medium text-body-2">クラス</label>
      <v-select
        id="class-filter"
        ref="selectRef"
        v-model="selectedClassIds"
        aria-label="クラス"
        density="compact"
        variant="outlined"
        :items="classes"
        item-title="name"
        item-value="id"
        multiple
        :placeholder="hasClasses ? '' : 'クラスが登録されていません'"
        :menu-props="{ maxHeight: classSelectMenuHeight, maxWidth: 900 }"
        class="scrollable-select mt-1"
      >
        <template #selection="{ item, index }">
          <div v-if="allClassesSelected && index === 0">すべて表示</div>
          <v-chip
            v-else-if="!allClassesSelected"
            color="#F6F6F6"
            label
            class="mr-2 font-weight-medium selected-class-chip"
            closable
            close-icon="mdi-close"
            variant="flat"
            @click:close="remove(item)"
          >
            {{ item.title }}
          </v-chip>
        </template>

        <template #no-data>
          <ClassEmptyState />
        </template>

        <!-- NOTE: プルダウンの挙動をオーバーライドするために、slotを乗っ取って非表示にしている -->
        <template #item="{ item, props }">
          <v-list-item v-show="false" v-bind="props">
            {{ item.name }}
          </v-list-item>
        </template>

        <template #append-item>
          <div style="overflow: hidden">
            <v-row v-show="hasClasses" class="pa-4">
              <v-col
                v-for="item in classes"
                :key="item.id"
                cols="12"
                sm="4"
                class="py-2"
                align-self="center"
              >
                <v-checkbox
                  :model-value="selectedClassIds"
                  hide-details
                  density="compact"
                  :label="item.name"
                  :value="item.id"
                  data-testid="select-class"
                  @update:model-value="updateClassCondition"
                />
              </v-col>
            </v-row>
            <div v-show="hasClasses" style="position: sticky; bottom: 0; background-color: #ffffff">
              <v-divider class="my-2" />
              <v-list-item density="compact">
                <div class="d-flex align-center justify-space-between" style="width: 100%">
                  <v-checkbox
                    v-model="allClassesSelected"
                    hide-details
                    density="compact"
                    label="すべて選択"
                    data-testid="select-all-class"
                  />
                  <Button
                    class="font-weight-medium text-grey d-flex align-center"
                    variant="text"
                    @click="closeClassSelect"
                  >
                    閉じる
                    <v-icon>mdi-chevron-up</v-icon>
                  </Button>
                </div>
              </v-list-item>
            </div>
          </div>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import type { VSelect } from 'vuetify/components'
import { TopPageStore } from 'pages/top_page/top_page_store'
import { ParentSearchConditionService } from 'pages/top_page/services/parent_search_condition_service'
import { SchoolClass } from 'pages/top_page/models'
import ClassEmptyState from 'pages/top_page/components/ParentFilters/components/ClassEmptyState.vue'
import Button from 'shared/components/Button.vue'

defineProps<{
  inputMode: 'default' | 'compact'
}>()

const selectRef = ref<VSelect | null>(null)
const closeClassSelect = () => {
  if (selectRef.value) {
    selectRef.value.menu = false
  }
}

const classSelectMenuHeight = window.innerHeight * 0.8

const classes = computed(() => TopPageStore.schoolClasses)
const hasClasses = computed(() => classes.value.length > 0)
const selectedClassIds = computed(() => TopPageStore.searchCondition.selectedClassIds)
const updateClassCondition = (classIds: SchoolClass['id'][]) => {
  ParentSearchConditionService.updateClassCondition(classIds)
}
const remove = (item: { id: number; name: string }) => {
  const index = selectedClassIds.value.indexOf(item.id)
  if (index >= 0) {
    selectedClassIds.value.splice(index, 1)
  }
}

const allClassesSelected = computed({
  get: () => ParentSearchConditionService.isAllClassesSelectChecked(),
  set: (value) => {
    if (!value) {
      ParentSearchConditionService.resetClassFilter()
    } else {
      ParentSearchConditionService.selectAllClassExceptNewAndGrad()
    }
  },
})

const classSelectMargin = computed(() =>
  selectedClassIds.value.length === 0 || allClassesSelected.value ? '0px' : '6px'
)
</script>

<style scoped lang="scss">
.class-container {
  display: grid;
  grid-template-columns: max-content max-content 1fr;
  column-gap: 16px;
  align-items: flex-start;
}

.gap-x-5 {
  column-gap: 20px;
}

.v-checkbox:deep(.v-input__details) {
  display: none;
}

.v-checkbox:deep(label) {
  color: #000;
  font-weight: 500;
}

.v-input--selection-controls {
  margin-top: 0;
}

.scrollable-select::v-deep .v-field__input {
  max-height: 88px;
  overflow-y: auto;
  margin-block: v-bind(classSelectMargin);
  row-gap: 8px;
  padding-left: 0px;
  margin-left: 14px;
}

.scrollable-select::v-deep .v-field__input::-webkit-scrollbar {
  width: 12px;
}
.scrollable-select::v-deep .v-field__input::-webkit-scrollbar-track {
  background-color: #f6f6f6;
  border-radius: 10px;
}
.scrollable-select::v-deep .v-field__input::-webkit-scrollbar-thumb {
  background-color: #a1a1a1;
  border-radius: 10px;
}

.scrollable-select::v-deep .v-text-field__prefix {
  color: #000;
}

.selected-class-chip:hover::v-deep {
  opacity: 0.9;
}

.selected-class-chip::v-deep .v-icon {
  color: #a1a1a1;
  font-size: 16px;
}
</style>
