import { ParentsPageStore } from 'pages/parents_page/parents_page_store'
import { ParentUrlService } from 'pages/parents_page/services/parent_url_service'

export class ClearSearchConditionsService {
  /**
   * 検索条件を初期状態にリセットする関数
   * 初期状態にリセットしたら、初期条件で検索する
   */

  static clearConditions(): void {
    ParentsPageStore.updateSearchQuery({
      class_id: '',
      parent_name: '',
      child_name: '',
      line_connected: 'all',
      page: '1',
      per_page: '50',
    })
    ParentUrlService.overwriteQueryParams()
  }
}
