<template>
  <div class="ml-3">
    <p>フランチャイズ展開を行っていますか</p>
    <v-radio-group v-model="formValue.developedFranchise">
      <v-radio color="primary" label="はい" :value="true"></v-radio>
      <v-radio color="primary" label="いいえ" :value="false"></v-radio>
    </v-radio-group>
  </div>
</template>

<script>
import { CorporationApplyPageStore } from '../corporation_apply_page_store'

export default {
  computed: {
    formValue() {
      return CorporationApplyPageStore.formValue
    },
  },
}
</script>
