<template>
  <div>
    <v-tooltip location="bottom">
      <template #activator="{ props }">
        <span v-bind="props">
          <template v-if="availableFacilities.length > 1">
            <v-select
              :items="availableFacilities"
              item-title="name"
              item-value="id"
              :model-value="currentFacilityId"
              variant="outlined"
              hide-details
              density="compact"
              @update:model-value="handleChange"
            ></v-select>
          </template>
          <template v-else>
            <span class="d-inline-flex mw-100 items-center ga-1"
              ><v-icon>mdi-domain</v-icon
              ><span class="text-body-1 text-truncate">{{ currentFacilityName }}</span></span
            >
          </template>
        </span>
      </template>
      <span>{{ currentFacilityName }}</span>
    </v-tooltip>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { AvailableFacility } from 'http/modules/facility'

type FacilitiesSelectPropType = {
  availableFacilities: AvailableFacility[]
  currentFacilityId: Number
}

const facilitySelectProps = defineProps<FacilitiesSelectPropType>()
const emit = defineEmits<{
  (event: 'change', facilityId: number): void
}>()

const currentFacilityName = computed(
  () =>
    facilitySelectProps.availableFacilities.find(
      (e) => e.id === facilitySelectProps.currentFacilityId
    )?.name || ''
)
const handleChange = (facilityId: any) => {
  if (typeof facilityId === 'number') {
    emit('change', facilityId)
  }
}
</script>

<style lang="scss" scoped>
.mw-100 {
  max-width: 100%;
}
</style>
