import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)

type Format =
  | 'YYYY/MM/DD HH:mm'
  | 'MM/DD HH:mm'
  | 'YYYY-MM'
  | 'YYYY-MM-DD'
  | 'YYYY年MM月'
  | 'YYYY年M月'
  | 'YYYY年MM月DD日'
  | 'YYYY年MM月01日'
  | 'YYYY'
  | 'MM'
  | 'MM月DD日'

/**
 * dayjsのインスタンスを取得する
 * @param date 日付
 * @returns dayjsのインスタンス
 */
export const convertToDayjs = (date: Date | string): dayjs.Dayjs => dayjs(date).tz('Asia/Tokyo')

/**
 * 現在時刻を返す
 * @param date 日付
 * @returns 現在時刻
 */
export const getDate = (date: Date | string): Date => dayjs(date).tz('Asia/Tokyo').toDate()

/**
 * X日後の日付を返す
 * @param date 日付
 * @param dayNumber X日後の date
 * @returns X日後の日付
 */
export const getAddDay = (date: Date | string, dayNumber: number): Date =>
  convertToDayjs(date).add(dayNumber, 'day').toDate()

/**
 * 現在時刻のXヶ月後を返す
 * もしpropsで渡された日付があれば、その日付のXヶ月後を取得する
 * @param date 日付
 * @param monthNumber Xヶ月後のX
 * @returns Xヶ月後の日付
 */
export const getAddMonth = (date: Date | string, monthNumber: number): Date =>
  convertToDayjs(date).add(monthNumber, 'month').toDate()

/**
 * 現在時刻のXヶ月前を返す
 * もしpropsで渡された日付があれば、その日付のXヶ月前を取得する
 * @param date 日付
 * @param monthNumber Xヶ月後のX
 * @returns Xヶ月前の日付
 */
export const getSubtractMonth = (date: Date | string, monthNumber: number): Date =>
  convertToDayjs(date).subtract(monthNumber, 'month').toDate()

/**
 * propsで渡された日付Aが、日付Bよりも未来の日付か判定する
 * @param dateA 比較元となる日付
 * @param dateB 比較先となる日付
 * @returns 日付Aが日付Bよりも未来の日付であればtrue
 */
export const isFutureDate = (dateA: Date | string, dateB: Date | string): boolean =>
  convertToDayjs(dateA).isAfter(convertToDayjs(dateB))

/**
 * propsで渡された日付Aが、日付Bよりも過去の日付か判定する
 * @param dateA 比較元となる日付
 * @param dateB 比較先となる日付
 * @returns 日付Aが日付Bよりも過去の日付であればtrue
 */
export const isPastDate = (dateA: Date | string, dateB: Date | string): boolean =>
  convertToDayjs(dateA).isBefore(convertToDayjs(dateB))

/**
 * propsで渡された形式に日付フォーマットを変更する
 * @param date 日付
 * @param format フォーマット ex) YYYY-MM-DD
 * @returns フォーマットされた日付
 */
export const getFormatDate = (date: Date | string, format: Format): string =>
  convertToDayjs(date).format(format)

/**
 * propsで渡された日付の年を取得する
 * @param date 日付
 * @returns 年
 */
export const getYear = (date: Date | string): number => convertToDayjs(date).year()

/**
 * propsで渡された日付の月を取得する
 * month()は0から始まるため、+1している
 * @param date 日付
 * @returns 月
 */
export const getMonth = (date: Date | string): number => convertToDayjs(date).month() + 1

/**
 * propsで受け取った日付に対して、月の始まりを取得する
 * @param date 日付
 * @returns 時間の始まり
 */
export const getStartOfMonth = (date: Date | string): Date =>
  convertToDayjs(date).startOf('month').toDate()

/**
 * propsで受け取った日付に対して、月の終わりを取得する
 * @param date 日付
 * @returns 時間の終わり
 */
export const getEndOfMonth = (date: Date | string): Date =>
  convertToDayjs(date).endOf('month').toDate()

/**
 * propsで受け取った年月を日付に変換して取得する
 * @param year 年の数値
 * @param month 月の数値
 * @returns 日付
 */
// NOTE: day.jsは月を0スタートでカウントするので-1する https://day.js.org/docs/en/get-set/month
export const setYearAndMonth = (year: string, month: string): Date =>
  dayjs()
    .set('year', Number(year))
    .set('month', Number(month) - 1)
    .toDate()
