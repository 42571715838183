/**
 * この関数は配列とコールバック関数を受け取ります。
 * この関数は配列を返します。
 * オブジェクトのキーはコールバック関数の返り値になり、値はそのキーを生成したアイテムの配列になります。
 */

export const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
  arr.reduce(
    (groups, item) => {
      ;(groups[key(item)] ||= []).push(item)
      return groups
    },
    {} as Record<K, T[]>
  )
