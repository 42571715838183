<template>
  <FacilityRegistrationLayout page-title="入力した詳細情報をご確認ください" :step="3">
    <div class="panel panel-normal">
      <div class="panel_inner">
        <div class="sign-Form">
          <div class="sign-Form_item">
            <div class="sign-Form_heading">郵便番号</div>
            <div class="sign-Form_input">
              <p>{{ facility.postal_code }}</p>
            </div>
          </div>
          <div class="sign-Form_item">
            <div class="sign-Form_heading">都道府県</div>
            <div class="sign-Form_input">
              <p>{{ prefecture }}</p>
            </div>
          </div>
          <div class="sign-Form_item">
            <div class="sign-Form_heading">市区町村・番地</div>
            <div class="sign-Form_input">
              <p>{{ facility.city }}</p>
            </div>
          </div>
          <div v-show="facility.building" class="sign-Form_item">
            <div class="sign-Form_heading">建物名</div>
            <div class="sign-Form_input">
              <p>{{ facility.building }}</p>
            </div>
          </div>
          <div class="sign-Form_item">
            <div class="sign-Form_heading">責任者名</div>
            <div class="sign-Form_input">
              <p>{{ facility.responsible_person_name }}</p>
            </div>
          </div>
          <div class="sign-Form_item">
            <div class="sign-Form_heading">銀行名</div>
            <div class="sign-Form_input">
              <p>{{ facility.bank_name }}</p>
            </div>
          </div>
          <div class="sign-Form_item">
            <div class="sign-Form_heading">銀行コード</div>
            <div class="sign-Form_input">
              <p>{{ facility.bank_code }}</p>
            </div>
          </div>
          <div class="sign-Form_item">
            <div class="sign-Form_heading">支店名</div>
            <div class="sign-Form_input">
              <p>{{ facility.bank_branch_name }}</p>
            </div>
          </div>
          <div class="sign-Form_item">
            <div class="sign-Form_heading">支店コード</div>
            <div class="sign-Form_input">
              <p>{{ facility.bank_branch_code }}</p>
            </div>
          </div>
          <div class="sign-Form_item">
            <div class="sign-Form_heading">預金種別</div>
            <div class="sign-Form_input">
              <p>{{ facility.bank_branch_type }}</p>
            </div>
          </div>
          <div class="sign-Form_item">
            <div class="sign-Form_heading">口座番号</div>
            <div class="sign-Form_input">
              <p>{{ facility.bank_account_number }}</p>
            </div>
          </div>
          <div class="sign-Form_item">
            <div class="sign-Form_heading">銀行口座の名義</div>
            <div class="sign-Form_input">
              <p>{{ facility.bank_account_holder_name }}</p>
            </div>
          </div>
        </div>
        <p class="sign-Form_buttonArea-sm">
          <a class="button-sm button-sub" :href="editUrl">修正する</a
          ><a class="button-sm button-normal" href="/school_accounts/finished">登録する</a>
        </p>
      </div>
    </div>
  </FacilityRegistrationLayout>
</template>

<script setup lang="ts">
import { RouteService } from 'shared/services/route_service'
import { computed } from 'vue'
import FacilityRegistrationLayout from 'shared/templates/FacilityRegistrationLayout.vue'

const targetFacilityId = Number(RouteService.getPathParamByIndex(1))
const editUrl = computed(() => `/facilities/${targetFacilityId}/registrations/edit`)

defineProps<{
  facility: {
    postal_code: string
    city: string
    building: string
    responsible_person_name: string
    bank_name: string
    bank_code: string
    bank_branch_name: string
    bank_branch_code: string
    bank_branch_type: string
    bank_account_type: string
    bank_account_number: string
    bank_account_holder_name: string
  }
  prefecture: string
}>()
</script>
