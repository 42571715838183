<template>
  <div>
    <v-app-bar
      color="white"
      elevation="0"
      scroll-behavior="elevate"
      :style="headerSpace ? { top: headerSpace.headerSpace.size } : {}"
      class="px-2"
    >
      <LogoImage mode="corporation" link-to="/transfer" />
      <v-toolbar-items class="ml-6">
        <NavMenu
          :available-menu="availableMenu"
          :selected-menu-id="selectedMenuId"
          mode="corporation"
        ></NavMenu>
      </v-toolbar-items>
      <v-spacer />
      <template v-if="availableFacilities.length > 0">
        <OperationModeSwitchButton
          mode="corporation"
          :link-to="`/facilities`"
        ></OperationModeSwitchButton>
      </template>
      <AccountMenuButton mode="corporation" class="ml-3"></AccountMenuButton>
      <HelpMenuButton
        mode="corporation"
        :helppage-url="helppageUrl"
        :info-for-parents-url="infoForParentsUrl"
        :manualpage-url="manualpageUrl"
        class="ml-3"
      ></HelpMenuButton>
      <BeamerButton mode="corporation" class="ml-3"></BeamerButton>
    </v-app-bar>
  </div>
</template>

<script setup lang="ts">
import LogoImage from './components/LogoImage.vue'
import AccountMenuButton from './components/AccountMenuButton.vue'
import BeamerButton from './components/BeamerButton.vue'
import { useCorporationHeader } from './use_header'
import NavMenu from './components/NavMenu.vue'
import OperationModeSwitchButton from './components/OperationModeSwitchButton.vue'
import HelpMenuButton from './components/HelpMenuButton.vue'
import ExternalLink from 'shared/consts/external_link'
import { makeHeaderSpace } from 'shared/components/header/components/UnsupportedBrowserNotification.vue'

const helppageUrl = ExternalLink.openpageFaq
const infoForParentsUrl = ExternalLink.openpageInfoForParents
const manualpageUrl = ExternalLink.openpageManual
const { availableFacilities, availableMenu, selectedMenuId } = useCorporationHeader()
const headerSpace = makeHeaderSpace()
</script>
