<template>
  <ChildSelectingAllCheckBox
    v-if="isSelectedAll && !Boolean(child.codmon_id)"
    :is-selected-all="isSelectedAll"
    :parent="parent"
    :child="child"
  />
  <template v-else>
    <template v-if="!Boolean(child.codmon_id)">
      <v-checkbox
        :key="child.id"
        v-model="selectedChildIds"
        :value="{ parentId: parent.id, childId: child.id }"
        class="shrink"
        hide-details
      >
        <template #label>
          <span class="font-weight-bold fs-16 d-flex align-self-center" style="color: #000000">
            {{ child.first_name }}
          </span>
        </template>
      </v-checkbox>
    </template>
    <template v-else>
      <v-tooltip location="top">
        <template #activator="{ props: tooltipProps }">
          <div v-bind="tooltipProps">
            <v-checkbox
              :key="child.id"
              class="shrink"
              :disabled="Boolean(child.codmon_id)"
              hide-details
            >
              <template #label>
                <span class="font-weight-bold fs-16 d-flex align-self-center">
                  {{ child.first_name }}
                </span>
              </template>
            </v-checkbox>
          </div>
        </template>
        <span>コドモン連携しているため、削除できません。</span>
      </v-tooltip>
    </template>
  </template>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { Parent, Child } from 'pages/parents_page/model'
import { ParentsPageStore } from 'pages/parents_page/parents_page_store'
import ChildSelectingAllCheckBox from 'pages/parents_page/areas/parent_card/components/ChildSelectingAllCheckBox.vue'

const props = defineProps<{
  parent: Parent
  child: Child
  isSelectedAll: boolean
  hasCodmonId: boolean
}>()

const emit = defineEmits([
  'onChangeChildSelected',
  'onClickDisconnectCodmon',
  'onClickConnectCodmon',
])

const selectedChildIds = computed({
  get: () => ParentsPageStore.selectedChildIds,
  set: (value) => emit('onChangeChildSelected', value, props.parent),
})
</script>
<style scoped lang="scss">
.fs-16 {
  font-size: 16px;
}
.v-input--selection-controls {
  margin: 0 8px 0 0 !important;
}
.v-btn:not(.v-btn--round).v-size--default {
  min-width: initial;
}
</style>
