import { CorporationApplyPageStore } from 'pages/corporation_apply_page/corporation_apply_page_store'
import { CorporationApi } from '../../../http/modules/corporation'
import { PanelStepService } from 'pages/corporation_apply_page/services/panel_step_service'
import type { AxiosError } from 'axios'

export class RegisterCorporationService {
  // eslint-disable-next-line max-lines-per-function
  static registerCorporation(oemPartnerId: string): void {
    CorporationApplyPageStore.updateIsSubmitting(true)
    CorporationApplyPageStore.updateErrorMessages([])

    if (CorporationApplyPageStore.formValue.businessType === '個人') {
      // 個人事業主の場合、法人番号は空にする
      CorporationApplyPageStore.formValue.corporationNumber = ''
    }

    // doSpecialBusiness（特商法・前払いに該当する販売方法）が該当なしの場合は関連するチェックを一律falseにする
    // 一度該当ありにして関連するチェックボックスをONにした後に該当なしへ変更した場合を考慮
    if (!CorporationApplyPageStore.formValue.doSpecialBusiness) {
      CorporationApplyPageStore.formValue.doDoorToDoorSales = false
      CorporationApplyPageStore.formValue.doSpecifiedContinuousServiceProvision = false
      CorporationApplyPageStore.formValue.doTelemarketingRelatedSales = false
      CorporationApplyPageStore.formValue.doChainReactionSales = false
      CorporationApplyPageStore.formValue.doFreelanceSales = false
      CorporationApplyPageStore.formValue.providedPrepaidTransactions = false
    }

    CorporationApi.create({
      do_door_to_door_sales: CorporationApplyPageStore.formValue.doDoorToDoorSales,
      do_specified_continuous_service_provision:
        CorporationApplyPageStore.formValue.doSpecifiedContinuousServiceProvision,
      do_telemarketing_related_sales:
        CorporationApplyPageStore.formValue.doTelemarketingRelatedSales,
      do_chain_reaction_sales: CorporationApplyPageStore.formValue.doChainReactionSales,
      do_freelance_sales: CorporationApplyPageStore.formValue.doFreelanceSales,
      provided_prepaid_transactions:
        CorporationApplyPageStore.formValue.providedPrepaidTransactions,
      is_applicable_special_commercial_code_measures:
        CorporationApplyPageStore.formValue.isApplicableSpecialCommercialCodeMeasures,
      developed_franchise: CorporationApplyPageStore.formValue.developedFranchise,
      email: CorporationApplyPageStore.formValue.email,
      business_type: CorporationApplyPageStore.formValue.businessType,
      type_of_industry: CorporationApplyPageStore.formValue.typeOfIndustry,
      business_description: CorporationApplyPageStore.formValue.businessDescription,
      number: CorporationApplyPageStore.formValue.corporationNumber,
      name: CorporationApplyPageStore.formValue.corporationName,
      name_kana: CorporationApplyPageStore.formValue.corporationNameKana,
      established_date: CorporationApplyPageStore.formValue.establishedDate.replace(/-/g, ''),
      capital_stock: CorporationApplyPageStore.formValue.capitalStock,
      employees_number: CorporationApplyPageStore.formValue.employeesNumber,
      annual_turnover: CorporationApplyPageStore.formValue.annualTurnover,
      home_page_url: CorporationApplyPageStore.formValue.homePageUrl,
      postal_code: CorporationApplyPageStore.formValue.postalCode,
      prefecture_name: CorporationApplyPageStore.formValue.prefectureName,
      city_name: CorporationApplyPageStore.formValue.cityName,
      town_name: CorporationApplyPageStore.formValue.townName,
      street_name: CorporationApplyPageStore.formValue.streetName,
      building_name: CorporationApplyPageStore.formValue.buildingName,
      prefecture_name_kana: CorporationApplyPageStore.formValue.prefectureNameKana,
      city_name_kana: CorporationApplyPageStore.formValue.cityNameKana,
      town_name_kana: CorporationApplyPageStore.formValue.townNameKana,
      street_name_kana: CorporationApplyPageStore.formValue.streetName,
      building_name_kana: CorporationApplyPageStore.formValue.buildingNameKana,
      phone_number: CorporationApplyPageStore.formValue.phoneNumber,
      gmo_inspection_status: '仮登録済み',
      gmo_payment_shop_id: '',
      gmo_payment_shop_pass: '',
      oem_partner_id: oemPartnerId,
      oem_partner_branch_name: CorporationApplyPageStore.formValue.oemPartnerBranchName,
      representative_first_name: CorporationApplyPageStore.formValue.representativeFirstName,
      representative_last_name: CorporationApplyPageStore.formValue.representativeLastName,
      representative_first_name_kana:
        CorporationApplyPageStore.formValue.representativeFirstNameKana,
      representative_last_name_kana: CorporationApplyPageStore.formValue.representativeLastNameKana,
      representative_birthdate: CorporationApplyPageStore.formValue.representativeBirthdate.replace(
        /-/g,
        ''
      ),
      representative_postal_code: CorporationApplyPageStore.formValue.representativePostalCode,
      representative_prefecture_name:
        CorporationApplyPageStore.formValue.representativePrefectureName,
      representative_city_name: CorporationApplyPageStore.formValue.representativeCityName,
      representative_town_name: CorporationApplyPageStore.formValue.representativeTownName,
      representative_street_name: CorporationApplyPageStore.formValue.representativeStreetName,
      representative_building_name: CorporationApplyPageStore.formValue.representativeBuildingName,
      representative_prefecture_name_kana:
        CorporationApplyPageStore.formValue.representativePrefectureNameKana,
      representative_city_name_kana: CorporationApplyPageStore.formValue.representativeCityNameKana,
      representative_town_name_kana: CorporationApplyPageStore.formValue.representativeTownNameKana,
      representative_street_name_kana: CorporationApplyPageStore.formValue.representativeStreetName,
      representative_building_name_kana:
        CorporationApplyPageStore.formValue.representativeBuildingNameKana,
      representative_phone_number: CorporationApplyPageStore.formValue.representativePhoneNumber,
    })
      .then(() => PanelStepService.incrementPanelStep())
      .catch((error: AxiosError<{ errors?: string[] }>) => {
        CorporationApplyPageStore.updateErrorMessages([
          ...(error.response?.data.errors ?? []),
          '会社情報の登録に失敗しました。繰り返し失敗する場合は管理者にお問い合わせください。',
        ])
      })
      .finally(() => {
        CorporationApplyPageStore.updateIsSubmitting(false)
      })
  }
}
