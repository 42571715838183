import { Parent } from 'pages/parents_page/model'
import { SchoolParentService } from 'pages/top_page/services/school_parent_service'
import { computed } from 'vue'

/**
 * 指定した保護者の最新の請求情報を扱う Composable
 */
export default function useLatestInvoice(parentId: Parent['id']) {
  const parent = computed(() => SchoolParentService.getParentById(parentId))

  const latestInvoice = computed(() => {
    if (!parent.value || !parent.value.invoices || parent.value.invoices.length === 0)
      return undefined

    return parent.value.invoices[0]
  })

  const isSentInvoice = computed(() =>
    Boolean(
      latestInvoice.value && latestInvoice.value.status === 'unpaid' && latestInvoice.value.sent_at
    )
  )

  const isPaidInvoice = computed(() =>
    Boolean(
      latestInvoice.value && latestInvoice.value.status === 'paid' && latestInvoice.value.paid_at
    )
  )

  /**
   * 通常の運用のケースでは paid_at が採択される。 display_paid_at は手動で paid_at を変更したいケースでに備えて作成されたカラム
   */
  const displayedPaidAt = computed(
    () =>
      !!latestInvoice.value &&
      (latestInvoice.value.displayed_paid_at || latestInvoice.value.paid_at)
  )

  const hasCvsPaymentFee = computed(() => {
    const invoice = latestInvoice.value
    if (!invoice || !invoice.invoice_items.length) return false

    return (
      !!isPaidInvoice.value && invoice.invoice_items.some((item) => item.is_convenience_store_fee)
    )
  })

  return {
    parent,
    latestInvoice,
    isSentInvoice,
    isPaidInvoice,
    displayedPaidAt,
    hasCvsPaymentFee,
  }
}
