<template>
  <transition name="modal">
    <div
      v-if="isShowModal"
      class="modal active"
      data-testid="invoice-list-modal"
      @click.self="closeInvoicesModal()"
    >
      <v-container class="modal_container">
        <div class="modal_inner">
          <div class="panel">
            <div class="panel_inner">
              <div class="header d-flex mb-2 justify-space-between align-start h-100">
                <p class="text-black text-h6 text-left" style="width: calc(100% - 256px - 4px)">
                  {{ parent.full_name }}
                </p>
                <div style="width: 256px">
                  <p
                    class="text-primary d-flex justify-space-between align-end bg-grey-lighten-3 px-3 py-3 mb-2"
                    data-testid="invoices-total-price"
                  >
                    <span
                      class="text-black text-h6 mr-2 text-subtitle-2"
                      style="line-height: 2; vertical-align: middle"
                      >小計</span
                    >
                    <span
                      ><AmountLabel
                        :amount="totalPriceOfParentInvoicesWithoutConvenienceStoreFee"
                        :size="'L'"
                    /></span>
                  </p>
                  <p
                    v-if="hasConvenienceStoreFee"
                    class="text-black d-flex justify-space-between align-end px-3"
                  >
                    <span class="mr-2 text-subtitle-2">コンビニ支払い手数料</span>
                    <span
                      ><FlatAmountLabel :amount="convenienceStoreFee" :size="'text-subtitle-2'"
                    /></span>
                  </p>
                  <p class="text-black d-flex justify-space-between px-3">
                    <span class="mr-2 text-subtitle-2">合計</span>
                    <span
                      ><FlatAmountLabel
                        :amount="totalPriceOfParentInvoicesWithConvenienceStoreFee"
                        :size="'text-subtitle-2'"
                    /></span>
                  </p>
                </div>
              </div>
              <v-divider />
              <div v-if="showEditingRow" class="invoices">
                <InvoiceRow :parent-id="parent.id" />
                <v-divider />
              </div>
              <div v-for="(invoice, index) in parent.invoices" :key="index" class="invoices">
                <InvoiceRow :parent-id="parent.id" :invoice-id="invoice.id" />
                <v-divider />
              </div>
              <v-tooltip location="top" :disabled="canCreateNewInvoice">
                <template #activator="{ props }">
                  <div class="d-inline-flex mx-auto mt-5" v-bind="props">
                    <Button
                      :disabled="!canCreateNewInvoice"
                      size="x-large"
                      class="text-h6 font-weight-bold"
                      @click="openInvoiceFormModal()"
                    >
                      新しく請求をする
                    </Button>
                  </div>
                </template>
                <span>{{ tooltipText }}</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </v-container>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import InvoiceRow from './InvoiceRow.vue'
import { SchoolParentService } from '../services/school_parent_service'
import AmountLabel from 'shared/components/AmountLabel.vue'
import FlatAmountLabel from 'shared/components/FlatAmountLabel.vue'
import { InvoiceModalService } from '../services/invoice_modal_service'
import { TopPageStore } from '../top_page_store'
import Button from 'shared/components/Button.vue'
import type { Parent } from 'pages/top_page/models'

const props = defineProps<{
  parentId: number
  isShowModal: boolean
}>()
const parent = computed(() => {
  const result = SchoolParentService.getParentById(props.parentId)
  if (!result) throw new Error('Parent not found')
  return result
})
const showEditingRow = computed(
  () =>
    (parent.value.invoices.length === 0 || parent.value.invoices[0].status === 'paid') &&
    SchoolParentService.hasEditingInvoice(parent.value)
)

const isInvoiceLocked = computed(() => TopPageStore.isLockedMonth)
const tooltipText = computed(() => {
  const text = {
    lock: '請求が締められているため、新しい請求はできません。',
    before_charge: '保存中の請求があるため、新しい請求ができません。',
    unpaid: '未払いの請求があるため、新しい請求ができません。',
  }

  if (isInvoiceLocked.value) return text.lock

  const invoiceStatuses = parent.value.invoices.flatMap((invoice) =>
    invoice.status !== 'paid' ? invoice.status : []
  )
  return text[invoiceStatuses[0]]
})
const closeInvoicesModal = () => InvoiceModalService.closeInvoicesModal()
const openInvoiceFormModal = () =>
  InvoiceModalService.openInvoiceFormModal(props.parentId, 'before_register')
// ボタン活性化: 「すべての請求が支払い済の場合」 && 「請求が非ロック」
const canCreateNewInvoice = computed(() => {
  if (isInvoiceLocked.value) return false

  const invoiceStatuses = parent.value.invoices.map((invoice) => invoice.status)
  const invoiceStatusesSet = new Set(invoiceStatuses)
  return invoiceStatusesSet.size === 1 && invoiceStatusesSet.has('paid')
})
const calculateTotalPriceOfParentInvoices = (parent: Parent, includeConvenienceStoreFee: boolean) =>
  SchoolParentService.calculateTotalAmountOfParentInvoices(parent.id, includeConvenienceStoreFee) ??
  0
const totalPriceOfParentInvoicesWithoutConvenienceStoreFee = computed(() =>
  calculateTotalPriceOfParentInvoices(parent.value, false)
)
const totalPriceOfParentInvoicesWithConvenienceStoreFee = computed(() =>
  calculateTotalPriceOfParentInvoices(parent.value, true)
)
const convenienceStoreFee = computed(
  () =>
    totalPriceOfParentInvoicesWithConvenienceStoreFee.value -
    totalPriceOfParentInvoicesWithoutConvenienceStoreFee.value
)
const hasConvenienceStoreFee = computed(() => convenienceStoreFee.value > 0)
</script>
