import { AxiosResponse, AxiosError } from 'axios'
import { HTTP } from '../common'

export type UpdateParams = {
  current_password: string
  password: string
  password_confirmation: string
}

type InputError = {
  input: { error: string }[]
}

type CurrentPasswordError = {
  current_password: { error: string }[]
}

type Errors = InputError | CurrentPasswordError

export type UpdateResponse = {
  school_account_id: number
  errors: {}
}

export type UpdateErrorResponse = {
  school_account_id: number
  errors: Errors
}

export const isAxiosError = <T extends UpdateErrorResponse>(
  error: unknown
): error is AxiosError<T> => !!(error as AxiosError<T>).isAxiosError

export class AccountSettingPasswordApi {
  static update(
    params: UpdateParams
  ): Promise<AxiosResponse<UpdateResponse | UpdateErrorResponse>> {
    return HTTP.patch(`/corporation_accounts/me/settings/password`, {
      current_password: params.current_password,
      password: params.password,
      password_confirmation: params.password_confirmation,
    })
  }
}
