/**
 * assertion functions の定義ファイルです
 * 必要なassertionがあれば、ここに追加してください
 * https://dev.classmethod.jp/articles/assertion-functions-of-typescript-3-7/
 * https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-7.html#assertion-functions
 */

/**
 * TypeScriptの仕様上、assertion functionsはarrow functionではコンパイルエラーが起きる
 * https://zenn.dev/akid/articles/b4ae3ace7722b1
 * https://github.com/microsoft/TypeScript/issues/34523
 */
/* eslint-disable func-style */
export function assertIsDefined<T>(val: T): asserts val is NonNullable<T> {
  if (val === undefined || val === null) {
    throw new Error(`Expected 'val' to be defined, but received ${val}`)
  }
}
