declare let gtag: Function

/**
 * Google Analytics のイベント名に設定できるのは40文字までです!!!
 * event tracking 関連の機能をまとめたモジュール。
 * importして使用するが、Vue.jsモジュールとして呼び出されないように _ 始まりとしている。
 */
export const EventTracker = {
  trackEvent: function (event_name: string, payload: any) {
    if (event_name.length > 40) {
      throw new Error(
        `Google Analytics のイベント名に設定できるのは40文字までです
        https://support.google.com/analytics/answer/9267744?hl=ja`
      )
    }
    try {
      gtag('event', event_name, payload)
    } catch (error) {
      // エラーを出力しつつ処理は継続
      console.error(error)
    }
  },
}
