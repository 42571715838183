<template>
  <div class="d-flex align-center justify-center range-label">
    <template v-if="!isEditMode">
      <div class="text-h4">
        {{ targetYearMonthRange ? dayjs(targetYearMonthRange.from).format('YYYY年M月') : ''
        }}<span class="mx-2">〜</span
        >{{ targetYearMonthRange ? dayjs(targetYearMonthRange.to).format('YYYY年M月') : '' }}
      </div>
      <Button icon variant="text" color="black" @click="onClickEditButton"
        ><v-icon>mdi-pencil</v-icon></Button
      >
    </template>
    <template v-if="isEditMode">
      <YearMonthRangeSelector
        :start-date="firstSelectableYearMonth"
        :default-range="targetYearMonthRange"
        :clearable="false"
        class="mr-3"
        @change="onChangeYearMonth"
      />
      <Button class="mr-2" @click="onConfirmRange">確定する</Button>
      <Button color="normal" variant="tonal" @click="onClearRange">キャンセル</Button>
    </template>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import YearMonthRangeSelector from 'shared/components/year_month_range_selector/YearMonthRangeSelector.vue'
import { YearMonthRange } from 'http/modules/corporations_transfer'
import dayjs from 'dayjs'
import { TransferPageStore } from '../transfer_page_store'
import { TransferService } from '../services/transfer_service'
import Button from 'shared/components/Button.vue'

export default defineComponent({
  components: { YearMonthRangeSelector, Button },
  setup() {
    // EditMode時に一時的に格納する期間の変数
    const selectedYearMonthRange = {
      from: '',
      to: '',
    }
    const firstSelectableYearMonth = computed(() => TransferPageStore.firstSelectableYearMonth)
    const targetYearMonthRange = computed(() => TransferPageStore.targetYearMonthRange)
    const isEditMode = ref<boolean>(false)
    const onChangeYearMonth = (newRange: YearMonthRange) => {
      selectedYearMonthRange.from = newRange.from
      selectedYearMonthRange.to = newRange.to
    }
    const onClickEditButton = () => {
      isEditMode.value = true
    }
    const onConfirmRange = () => {
      // 一時変数をそのまま渡してしまうと一時変数の書き換えでstoreの値が変わってしまう
      // それを防ぐためスプレッド構文(...)で展開している
      TransferPageStore.updateTargetYearMonthRange({ ...selectedYearMonthRange })
      TransferService.loadTransferSummariesFirstPage()
      TransferService.loadAllFacilitiesTransferSummary()
      isEditMode.value = false
    }
    const onClearRange = () => {
      isEditMode.value = false
    }
    return {
      isEditMode,
      selectedYearMonthRange,
      dayjs,
      firstSelectableYearMonth,
      targetYearMonthRange,
      onChangeYearMonth,
      onClickEditButton,
      onConfirmRange,
      onClearRange,
    }
  },
})
</script>
<style lang="scss" scoped>
.range-label {
  width: 36rem;
}
</style>
