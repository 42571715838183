<template>
  <v-app-bar
    v-if="!isChrome"
    class="unsupported-browser-notification"
    density="compact"
    flat
    style="top: 0"
  >
    <div class="message">
      <v-icon size="small" class="icon-alert-circle">mdi-alert-circle</v-icon
      ><span
        >現在ご利用のブラウザでは、正常に表示及び動作しないおそれがあります。Google
        Chromeをご利用ください。</span
      >
    </div>
    <div class="button">
      <Button variant="outlined" class="chrome-download-button" @click="downloadGoogleChrome">
        Google Chrome をダウンロードする
      </Button>
    </div>
  </v-app-bar>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import Button from 'shared/components/Button.vue'
import { isChrome } from 'shared/utils/browserDetection'

const downloadGoogleChrome = () => {
  window.open('https://www.google.com/intl/ja_jp/chrome/', '_target')
}

export const makeHeaderSpace = (): { headerSpace: { size: string } } | undefined => {
  if (isChrome()) {
    return undefined
  }
  return { headerSpace: { size: '50px' } }
}

export default defineComponent({
  components: {
    Button,
  },
  setup() {
    return {
      isChrome: isChrome(),
      makeHeaderSpace: makeHeaderSpace(),
      downloadGoogleChrome,
    }
  },
})
</script>
<style lang="scss" scoped>
.unsupported-browser-notification {
  background-color: #f4f6f9;
  display: flex;
  padding: 0 20px;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  // v-app-bar と合わせています
  z-index: 5;
  box-sizing: border-box;
  justify-content: space-between;
  border-bottom: 1px solid #d8d8d8;
  align-items: center;
  .message {
    color: #f14e54;
    font-size: 14px;
    display: flex;
    align-items: center;
  }
}
.chrome-download-button {
  background-color: #ffffff;
}
.icon-alert-circle {
  color: #f14e54;
  margin-right: 4px;
}
.v-app-bar ::v-deep .v-toolbar__content {
  justify-content: space-between;
}
</style>
