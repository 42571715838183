import { HTTP } from '../common'
import { ImportInvoiceService } from 'pages/import_invoices_page/services/import_invoice_service'

export type ConvertAlert = {
  message: string
  type: 'error' | 'report'
  row?: number
  col?: number
  child?: string
}

export type ConvertFormatResponse = {
  data: {
    csv: string
    file_name: string
    errors: ConvertAlert[]
  }
}
export class InvoiceRegisterTemplateApi {
  static convertFormat(
    facilityId: number,
    excelFile: File,
    targetYearAndMonth: string
  ): Promise<ConvertFormatResponse> {
    const params = new FormData()
    params.append('uploaded_file', excelFile)
    params.append('target_year_and_month', targetYearAndMonth)
    return HTTP.post(`/facilities/${facilityId}/invoice_register_template/convert_format`, params)
  }

  static download(fileType: 'csv' | 'excel') {
    const url =
      fileType === 'csv'
        ? ImportInvoiceService.generateTemplateDownloadUrl()
        : ImportInvoiceService.generateExcelTemplateDownloadUrl()
    return HTTP.get(url)
  }
}
