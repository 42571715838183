import { RouteService } from 'shared/services/route_service'
import { Env } from 'shared/models'

const buildS3ImageBaseURL = (env: Env) =>
  `https://s3.ap-northeast-1.amazonaws.com/jp.co.enpay-${env}.image`

const getS3ImageBaseUrl = (env: Env) => buildS3ImageBaseURL(env === 'localhost' ? 'develop' : env)

export default function useS3Image(path: string) {
  const env = RouteService.getENVFromHost()
  // TODO: 後で型(`/${string}`)で縛りたい
  if (env !== 'localhost' && !/^\//.test(path)) throw new Error('path の先頭は / にしてください')
  return {
    imageUrl: getS3ImageBaseUrl(env) + path,
  }
}
