/**
 * 保護者一覧の一括コドモン連携時に使う言語ファイル
 */
const ResultCodmonConnection = {
  no_parent: '保護者が登録されていないため、登録されませんでした。',
  no_space_in_name:
    '保護者または子どもの姓名の間にスペースが入っていないため、登録されませんでした。',
  graduated_child: '所属期間が過ぎているため、登録されませんでした。',
} as const

export default ResultCodmonConnection

export type ResultCodmonConnectionKey = keyof typeof ResultCodmonConnection
export type ResultCodmonConnectionValue = (typeof ResultCodmonConnection)[ResultCodmonConnectionKey]
