<template>
  <transition name="modal">
    <div v-if="isShowModal" class="modal active scrollable d-flex">
      <div ref="modalBackground" class="modal-background" @click.self="cancel()" />
      <div class="modal-inner">
        <div class="modal-dialog">
          <v-card class="d-flex flex-column">
            <v-card-title class="bg-primary-lighten-1 text-white text-left">
              {{ title }}
            </v-card-title>
            <v-card-text v-if="body.component" class="text-left pa-6">
              <component :is="body.component.name" v-bind="body.component.bind" />
            </v-card-text>
            <v-divider v-if="isOkButtonType === 'text'"></v-divider>
            <v-card-actions
              :class="
                isOkButtonType === 'text' ? 'justify-end pb-4' : 'justify-end pt-2 pb-10 pr-6'
              "
            >
              <Button
                v-show="isShowCancel"
                color="normal"
                variant="tonal"
                width="223px"
                size="large"
                class="mr-4"
                @click="cancel()"
              >
                {{ cancelButtonText }}
              </Button>
              <Button
                v-if="isOkButtonType === 'outlined'"
                variant="outlined"
                size="large"
                class="ml-4"
                @click="confirm()"
              >
                {{ okButtonText }}
              </Button>
              <Button
                v-else-if="isOkButtonType === 'text'"
                variant="text"
                size="large"
                class="ml-4"
                @click="confirm()"
              >
                {{ okButtonText }}
              </Button>
              <Button v-else width="223px" size="large" class="ml-4" @click="confirm()">
                {{ okButtonText }}
              </Button>
            </v-card-actions>
          </v-card>
        </div>
      </div>
      <div class="non-scroll" />
    </div>
  </transition>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { DialogService } from 'shared/services/dialog_service'
import Button from 'shared/components/Button.vue'

defineProps({
  isShowModal: Boolean,
})

const isShowCancel = computed(() => DialogService.showCancel)
const title = computed(() => DialogService.title)
const body = computed(() => DialogService.body)
const isOkButtonType = computed(() => DialogService.isOkButtonType)
const okButtonText = computed(() => DialogService.okButtonText)
const cancelButtonText = computed(() => DialogService.cancelButtonText)

const modalBackground = ref<HTMLElement | null>(null)

// methods
const adjustModalBackgroundHeight = () => {
  // modal-background要素の高さをモーダルの高さに合わせて計算
  const modalDialog = document.querySelector<HTMLElement>('.modal-dialog')
  if (modalDialog) {
    const modalCardHeight = modalDialog.querySelector<HTMLElement>('.v-card')?.offsetHeight
    if (!modalCardHeight) return
    const modalBackgroundHeight = Math.max(modalCardHeight) + 200 // 100はモーダルの上下のマージン
    if (!modalBackground.value) return
    modalBackground.value.style.minHeight = `${modalBackgroundHeight}px`
  }
}
const confirm = () => DialogService.confirm()
const cancel = () => DialogService.cancel()

watch(modalBackground, () => {
  if (modalBackground.value) {
    adjustModalBackgroundHeight()
  }
})
</script>

<style scoped>
.modal-background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000000;
  opacity: 0.5;
}
.modal-inner {
  min-height: 85%;
  max-height: 90%;
  overflow-y: initial;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.modal-dialog {
  opacity: 1;
  margin: 40px auto;
  width: 700px;
}
.modal.active {
  background-color: initial;
}
.modal .panel {
  overflow-y: initial;
  max-height: calc(100vh - 40px);
}
.scrollable {
  overscroll-behavior: contain;
  overflow-y: scroll;
}
.non-scroll {
  height: calc(100vh + 1px);
}
</style>
