<template>
  <AppFrame>
    <div class="text-h4 container mt-8">
      <span class="text-primary font-weight-bold"
        >{{ targetYear }}年{{ targetMonth.replace(/^0+/, '') }}月分</span
      >の請求内容を一括取り込み
    </div>
    <ImportPanel
      class="container"
      column-mapping-save-key="import_invoice"
      :template-download-url="generateTemplateDownloadUrl"
      :excel-template-download-url="generateExcelTemplateDownloadUrl"
      :column-candidates="columnCandidates"
      :import-csv="importCsv"
      :get-import-status="getImportStatus"
      :target-year-and-month="`${targetYear}-${targetMonth}`"
      :facility-id="facilityId"
      @download-csv-template="downloadTemplate('csv')"
      @download-excel-template="downloadTemplate('excel')"
    >
      <template #notation>
        <ImportInvoicesDescription />
      </template>
      <template #csvSampleEntry>
        <SampleEntry />
      </template>
      <template #excelSampleEntry>
        <SampleEntry />
      </template>
      <template #completedMessage>
        <CompletedMessage />
      </template>
    </ImportPanel>
  </AppFrame>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import ImportPanel from 'shared/components/import_panel/ImportPanel.vue'
import ImportInvoicesDescription from './ImportInvoicesDescription.vue'
import SampleEntry from './SampleEntry.vue'
import CompletedMessage from './CompletedMessage.vue'
import { CsvInvoicesApi } from 'http/modules/csv_invoices'
import { ImportInvoicesPageStore } from '../import_invoices_page_store'
import { ImportInvoiceService } from '../services/import_invoice_service'
import { CandidateColumnId } from 'shared/components/import_panel/models'
import { MetaService } from 'shared/services/meta_service'
import AppFrame from 'shared/components/AppFrame.vue'

ImportInvoiceService.loadColumnCandidates()
ImportInvoiceService.storeTargetYearAndMonth()
const generateTemplateDownloadUrl = ImportInvoiceService.generateTemplateDownloadUrl()
const generateExcelTemplateDownloadUrl = ImportInvoiceService.generateExcelTemplateDownloadUrl()

const columnCandidates = computed(() => ImportInvoicesPageStore.columnCandidates)
const targetYear = computed(() => ImportInvoicesPageStore.targetYear)
const targetMonth = computed(() => ImportInvoicesPageStore.targetMonth)
MetaService.addTitle({
  mode: 'facility',
  page: 'importInvoices',
  additional: `${targetYear.value}年${targetMonth.value}月分`,
})
const facilityId = ImportInvoiceService.getTargetFacilityId()

const downloadTemplate = (fileType: 'csv' | 'excel') => {
  ImportInvoiceService.downloadTemplate(fileType)
}
const importCsv = async (
  columnMapping: CandidateColumnId[],
  isFirstLineIgnored: boolean,
  file: File
) =>
  (
    await CsvInvoicesApi.post(
      facilityId,
      columnMapping,
      targetYear.value + '-' + targetMonth.value,
      isFirstLineIgnored,
      file
    )
  ).data.id
const getImportStatus = async (importId: number) =>
  (await CsvInvoicesApi.show(facilityId, importId)).data
</script>

<style scoped>
@media (1264px <= width) {
  .container {
    max-width: 1185px;
  }
}

@media (960px < width <= 1264px) {
  .container {
    max-width: 900px;
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding: 12px;
  width: 100%;
}
</style>
