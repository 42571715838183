<template>
  <div>
    <v-row dense class="my-4">
      <v-col cols="1" class="d-flex align-center" style="min-width: 120px; height: 48px">
        <span class="font-weight-medium fs-16 grey-font">保護者</span>
      </v-col>
      <v-col cols="3" class="px-0 mr-8">
        <TextInput
          :value="parentNameModel"
          :on-change="(value: string) => (parentNameModel = value)"
          placeholder="エンペイ 太郎"
          :on-keyup-enter="onKeyUpEnterParentName"
          :on-blur="onBlurParentName"
        />
      </v-col>
      <v-col cols="1" class="d-flex align-center" style="min-width: 120px; height: 48px">
        <span class="font-weight-medium fs-16 grey-font">LINE登録状況</span>
      </v-col>
      <v-col cols="3" class="px-0 mr-2">
        <SelectInput
          v-model="registeredLINEModel"
          variant="outlined"
          density="compact"
          :items="registeredLINE"
          item-text="label"
          item-value="value"
          hide-details
          @change="onChangeRegisterLINE"
        />
      </v-col>
    </v-row>
    <v-row dense class="my-4">
      <v-col cols="1" class="d-flex align-center" style="min-width: 120px; height: 48px">
        <span class="font-weight-medium fs-16 grey-font">子ども</span>
      </v-col>
      <v-col cols="3" class="px-0 mr-8">
        <TextInput
          :value="childNameModel"
          :on-change="(value: string) => (childNameModel = value)"
          placeholder="エンペイ 一郎"
          :on-keyup-enter="onKeyUpEnterChildName"
          :on-blur="onBlurChildName"
        />
      </v-col>
      <v-col cols="1" class="d-flex align-center" style="min-width: 120px; height: 48px">
        <span class="font-weight-medium fs-16 grey-font">クラス</span>
      </v-col>
      <v-col cols="3" class="px-0 mr-2">
        <SelectInput
          v-model="classesModel"
          variant="outlined"
          density="compact"
          :items="classes"
          item-text="label"
          item-value="value"
          hide-details
          @change="onChangeClassId"
        />
      </v-col>
      <v-col class="d-flex align-center justify-end">
        <Button
          variant="text"
          class="font-weight-medium fs-16"
          @click="
            $emit('onClickClearConditions'),
              $emit('refreshPage'),
              $emit('handleGaEvent', 'clear_search_button_in_parent_list')
          "
        >
          検索条件をクリア
        </Button>
      </v-col>
    </v-row>
  </div>
</template>
<script lang="ts">
import Button from 'shared/components/Button.vue'
import SelectInput from 'shared/components/SelectInput.vue'
import TextInput from 'shared/components/TextInput.vue'
import { ClassManagementService } from 'pages/parents_page/services/class_management_service'
import { computed, ComputedRef, defineComponent, ref } from 'vue'
import { SchoolClass } from 'http/modules/class'
import { SelectOption } from 'pages/account_settings_page/models'
import { ParentsPageStore, RegisteredLINEType } from 'pages/parents_page/parents_page_store'

export default defineComponent({
  components: { TextInput, SelectInput, Button },
  emits: ['onClickClearConditions', 'refreshPage', 'handleGaEvent'],
  // eslint-disable-next-line max-lines-per-function
  setup(_, { emit }) {
    ClassManagementService.loadClasses()
    const registeredLINEModel = computed<RegisteredLINEType>({
      get: () => ParentsPageStore.searchQuery.line_connected,
      set: (value) =>
        ParentsPageStore.updateSearchQuery({
          ...ParentsPageStore.searchQuery,
          line_connected: value,
        }),
    })

    const currentChildName = ref(ParentsPageStore.searchQuery.child_name)
    const childNameModel = computed({
      get: () => ParentsPageStore.searchQuery.child_name,
      set: (value) => {
        currentChildName.value = ParentsPageStore.searchQuery.child_name
        ParentsPageStore.updateSearchQuery({
          ...ParentsPageStore.searchQuery,
          child_name: value,
        })
      },
    })
    const onBlurChildName = () => {
      if (currentChildName.value !== childNameModel.value) {
        emit('refreshPage')
        emit('handleGaEvent', 'search_child_name_in_parent_list')
      }
      currentChildName.value = childNameModel.value
    }

    const onKeyUpEnterChildName = () => {
      emit('refreshPage')
      emit('handleGaEvent', 'search_child_name_in_parent_list')
    }
    const onKeyUpEnterParentName = () => {
      emit('refreshPage')
      emit('handleGaEvent', 'search_parent_name_in_parent_list')
    }

    const currentParentName = ref(ParentsPageStore.searchQuery.parent_name)
    const parentNameModel = computed({
      get: () => ParentsPageStore.searchQuery.parent_name,
      set: (value) => {
        currentParentName.value = ParentsPageStore.searchQuery.parent_name
        ParentsPageStore.updateSearchQuery({
          ...ParentsPageStore.searchQuery,
          parent_name: value,
        })
      },
    })
    const onBlurParentName = () => {
      if (currentParentName.value !== parentNameModel.value) {
        emit('refreshPage')
        emit('handleGaEvent', 'search_parent_name_in_parent_list')
      }
      currentParentName.value = parentNameModel.value
    }

    const classesModel = computed({
      get: () => ParentsPageStore.searchQuery.class_id,
      set: (value) =>
        ParentsPageStore.updateSearchQuery({
          ...ParentsPageStore.searchQuery,
          class_id: value,
        }),
    })

    const classes: ComputedRef<SelectOption[]> = computed(() => [
      {
        label: 'すべて表示',
        value: '',
      },
      ...ParentsPageStore.schoolClasses.map<SelectOption>((schoolClass: SchoolClass) => ({
        label: schoolClass.name,
        value: `${schoolClass.id}`,
      })),
    ])
    const registeredLINE = [
      {
        label: 'すべて表示',
        value: 'all',
      },
      {
        label: 'LINE登録済み',
        value: 'connected',
      },
      {
        label: 'LINE未登録',
        value: 'not_connected',
      },
    ]
    const onChangeRegisterLINE = (value: RegisteredLINEType) => {
      ParentsPageStore.updateSearchQuery({
        ...ParentsPageStore.searchQuery,
        line_connected: value,
      })
      emit('refreshPage')
      emit('handleGaEvent', 'search_line_registration_status')
    }

    const onChangeClassId = (value: string) => {
      ParentsPageStore.updateSearchQuery({
        ...ParentsPageStore.searchQuery,
        class_id: value,
      })
      emit('refreshPage')
      emit('handleGaEvent', 'search_class_in_parent_list')
    }

    return {
      classes,
      registeredLINE,
      registeredLINEModel,
      childNameModel,
      parentNameModel,
      onBlurParentName,
      onBlurChildName,
      onKeyUpEnterChildName,
      onKeyUpEnterParentName,
      classesModel,
      onChangeRegisterLINE,
      onChangeClassId,
    }
  },
})
</script>
<style scoped lang="scss">
.fs-16 {
  font-size: 16px;
}
.grey-font {
  color: #787878;
}
</style>
