import { reactive } from 'vue'
import {
  BulkOperationMode,
  ChildId,
  Parent,
  ParentTotal,
  SearchQuery,
} from 'pages/parents_page/model'
import { SchoolClass } from 'http/modules/class'
import { UnconnectedCodmonMembers } from 'http/modules/codmon'

export type RegisteredLINEType = 'all' | 'connected' | 'not_connected'

type ParentsPageState = {
  /** 保護者のサマリ */
  parentSummaries: Parent[]
  /** 保護者の総件数、ページ数 */
  parentTotal: ParentTotal
  /** 初期描画時など、画面全体がローディング状態かどうかを表す */
  isFullScreenLoading: boolean
  schoolClasses: SchoolClass[]
  selectedParentIds: Parent['id'][]
  selectedChildIds: ChildId[]
  bulkOperationMode: BulkOperationMode
  hasCodmonId: boolean
  /** 検索条件に一致する全てが選択されているかどうか */
  isSelectedAll: boolean
  isSearching: boolean
  /** コドモン連携結果を受け取っているかどうか */
  hasResultCodmonConnection: boolean

  isGettingCodmonChildren: boolean
  unconnectedCodmonChildren: UnconnectedCodmonMembers
  selectedCodmonId: number | null
  searchQuery: SearchQuery
}

const state = reactive<ParentsPageState>({
  parentSummaries: [],
  parentTotal: {
    total: {
      count: {
        parents: 0,
        children: 0,
      },
      pages: {
        parents: 0,
      },
    },
    deletable: {
      count: {
        parents: 0,
        children: 0,
      },
    },
  },
  isFullScreenLoading: false,
  schoolClasses: [],
  selectedParentIds: [],
  selectedChildIds: [],
  bulkOperationMode: undefined,
  hasCodmonId: false,
  isSelectedAll: false,
  isSearching: false,
  isGettingCodmonChildren: false,
  unconnectedCodmonChildren: {
    members: [],
  },
  hasResultCodmonConnection: false,
  selectedCodmonId: null,
  searchQuery: {
    class_id: '',
    parent_name: '',
    child_name: '',
    line_connected: 'all',
    page: '1',
    per_page: '50',
  },
})

export class ParentsPageStore {
  private static readonly isModifySuccessState = {
    storageKey: 'parents_page_modify_success_state',
    successValue: 'true',
  }

  static get parentSummaries(): Parent[] {
    return state.parentSummaries
  }

  static updateParentSummaries(parentSummaries: Parent[]): void {
    state.parentSummaries = parentSummaries
  }

  static get parentTotal(): ParentTotal {
    return state.parentTotal
  }

  static updateParentTotal(parentTotal: ParentTotal): void {
    state.parentTotal = parentTotal
  }

  static get isFullScreenLoading(): boolean {
    return state.isFullScreenLoading
  }

  static updateIsFullScreenLoading(isFullScreenLoading: boolean): void {
    state.isFullScreenLoading = isFullScreenLoading
  }

  static get schoolClasses(): SchoolClass[] {
    return state.schoolClasses
  }

  static updateSchoolClasses(schoolClasses: SchoolClass[]): void {
    state.schoolClasses = schoolClasses
  }

  static get selectedParentIds(): Parent['id'][] {
    return state.selectedParentIds
  }

  static updateSelectedParentIds(selectedParentIds: Parent['id'][]): void {
    state.selectedParentIds = selectedParentIds
  }

  static get selectedChildIds(): ChildId[] {
    return state.selectedChildIds
  }

  static updateSelectedChildIds(selectedChildIds: ChildId[]): void {
    state.selectedChildIds = selectedChildIds
  }

  static get bulkOperationMode(): BulkOperationMode {
    return state.bulkOperationMode
  }

  static updateBulkOperationMode(bulkOperationMode: BulkOperationMode): void {
    state.bulkOperationMode = bulkOperationMode
  }

  static get hasCodmonId(): boolean {
    return state.hasCodmonId
  }

  static updateHasCodmonId(hasCodmonId: boolean): void {
    state.hasCodmonId = hasCodmonId
  }

  static get isSelectedAll(): boolean {
    return state.isSelectedAll
  }

  static updateIsSelectedAll(isSelectedAll: boolean): void {
    state.isSelectedAll = isSelectedAll
  }

  static get isSearching(): boolean {
    return state.isSearching
  }

  static updateIsSearching(isSearching: boolean): void {
    state.isSearching = isSearching
  }

  static get hasResultCodmonConnection(): boolean {
    return state.hasResultCodmonConnection
  }

  static updateHasResultCodmonConnection(hasResultCodmonConnection: boolean): void {
    state.hasResultCodmonConnection = hasResultCodmonConnection
  }

  static get unconnectedCodmonChildren(): UnconnectedCodmonMembers['members'] {
    return state.unconnectedCodmonChildren['members']
  }

  static updateUnconnectedCodmonChildren(
    unconnectedCodmonChildren: UnconnectedCodmonMembers
  ): void {
    state.unconnectedCodmonChildren = unconnectedCodmonChildren
  }

  static get isGettingCodomonChildren(): boolean {
    return state.isGettingCodmonChildren
  }

  static updateIsGettingCodomonChildren(isGettingCodmonChildren: boolean): void {
    state.isGettingCodmonChildren = isGettingCodmonChildren
  }

  static get selectedCodmonId(): number | null {
    return state.selectedCodmonId
  }

  static updateSelectedCodmonId(selectedCodmonId: number | null): void {
    state.selectedCodmonId = selectedCodmonId
  }

  static get searchQuery(): SearchQuery {
    return state.searchQuery
  }

  static updateSearchQuery(searchQuery: SearchQuery): void {
    state.searchQuery = searchQuery
  }

  static get isModifySuccess(): boolean {
    const { storageKey, successValue } = this.isModifySuccessState
    return localStorage.getItem(storageKey) === successValue
  }

  static updateIsModifySuccess(isModifySuccess: boolean): void {
    const { storageKey, successValue } = this.isModifySuccessState
    const value = isModifySuccess ? successValue : 'false'
    localStorage.setItem(storageKey, value)
  }
}
