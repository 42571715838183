<template>
  <AppFrame :has-browser-alert="false">
    <div class="layout-wrapper">
      <div v-if="isLoading" class="d-flex justify-center">
        <v-progress-circular indeterminate :size="70" color="primary"></v-progress-circular>
      </div>
      <template v-else>
        <div class="wrapper">
          <div class="wrapper-inner mx-auto">
            <div class="contents contents-transfer-statement d-flex">
              <PrintArea
                class="print-area"
                :transfer-statement="transferStatement"
                :start-calc-paid-date="startCalcPaidDate"
                :end-calc-paid-date="endCalcPaidDate"
              ></PrintArea>
              <OperationArea
                :is-admin="isAdmin"
                :transfer-statement="transferStatement"
                :start-calc-paid-date="startCalcPaidDate"
                :end-calc-paid-date="endCalcPaidDate"
                :transfer-statement-histories="transferStatementHistories"
                :selected-transfer-statement-date="selectedTransferStatementDate"
                @download-invoice-items-csv="downloadInvoiceItemsCsv"
                @on-change-date-history="onChangeDateHistory"
              ></OperationArea>
            </div>
          </div>
        </div>
      </template>
    </div>
  </AppFrame>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { RouteService } from 'shared/services/route_service'
import { TransferStatementPrintService } from 'pages/transfer_statement_print_page/services/transfer_statement_print_service'
import { AdminTransferStatementPrintService } from 'pages/transfer_statement_print_page/services/admin_transfer_statement_print_service'
import { AdminTransferStatementHistoryPrintService } from 'pages/transfer_statement_print_page/services/admin_transfer_statement_history_print_service'
import { TransferStatementPrintPageStore } from 'pages/transfer_statement_print_page/transfer_statement_print_page_store'
import { OemPartnerService } from 'shared/services/oem_partner_service'
import PrintArea from 'pages/transfer_statement_print_page/areas/transfer_statement_area/TransferStatementArea.vue'
import OperationArea from 'pages/transfer_statement_print_page/areas/operation_area/OperationArea.vue'
import { getFormatDate } from 'shared/utils/date'
import { MetaService } from 'shared/services/meta_service'
import AppFrame from 'shared/components/AppFrame.vue'

// URLからadminを判定し、どちらのServiceを呼ぶかを判断する
const isAdmin = RouteService.getPathParamByIndex(0) === 'admin'
const monthlyTransferId = RouteService.getPathParamByIndex(2)

// NOTE: Adminと施設側でレスポンスの形が同じなので、Serviceは別だがStoreは共通で使っている
if (isAdmin) {
  // '/admin/monthly_transfers/1'というURLなので、2を指定。
  AdminTransferStatementPrintService.loadTransferStatement(Number(monthlyTransferId))
  MetaService.addTitle({ mode: 'admin', page: 'transferStatementPrint' })
  AdminTransferStatementHistoryPrintService.loadTransferStatementHistories(
    Number(monthlyTransferId)
  )
} else {
  // '/facilities/:id/transfer_statements/xxx'というURLなので、3を指定。
  const targetYearAndMonth = RouteService.getPathParamByIndex(3)
  MetaService.addTitle({
    mode: 'facility',
    page: 'transferStatementPrint',
    additional: `${targetYearAndMonth.replace(/-/, '年')}月度`,
  })
  TransferStatementPrintService.loadTransferStatement(targetYearAndMonth)
  OemPartnerService.loadForFacility()
}

const transferStatement = computed(() => TransferStatementPrintPageStore.transferStatement)
const startCalcPaidDate = computed(() => TransferStatementPrintPageStore.startCalcPaidDate)
const endCalcPaidDate = computed(() => TransferStatementPrintPageStore.endCalcPaidDate)
const isLoading = computed(() => TransferStatementPrintPageStore.isLoading)
const transferStatementHistories = computed(
  () => TransferStatementPrintPageStore.transferStatementHistories
)
const selectedTransferStatementDate = computed(
  () =>
    TransferStatementPrintPageStore.selectedTransferStatementDate &&
    getFormatDate(TransferStatementPrintPageStore.selectedTransferStatementDate, 'YYYY/MM/DD HH:mm')
)
const downloadInvoiceItemsCsv = () => {
  TransferStatementPrintService.downloadInvoiceItemsCsv()
}
const onChangeDateHistory = (date: string) => {
  const targetTransferStatement = transferStatementHistories.value.filter(
    (x) => date === getFormatDate(x.calculated_at, 'YYYY/MM/DD HH:mm')
  )[0]
  TransferStatementPrintPageStore.updateSelectedTransferStatementDate(
    targetTransferStatement.calculated_at
  )
  if (targetTransferStatement.id === null) {
    AdminTransferStatementPrintService.loadTransferStatement(Number(monthlyTransferId))
  } else {
    AdminTransferStatementHistoryPrintService.loadTransferStatementHistory(
      Number(monthlyTransferId),
      targetTransferStatement.id
    )
  }
}
</script>
<style lang="scss" scoped>
.contents.contents-transfer-statement {
  background-color: #fff;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.layout-wrapper {
  padding: 32px;
}

.print-area {
  margin-right: 24px;
}

@page {
  margin: 0mm;
  size: A4 portrait;
}

@media print {
  .layout-wrapper {
    padding: 0;
  }

  .wrapper {
    min-height: initial;
  }
  .wrapper-inner {
    min-height: initial;
  }

  .print-area {
    margin: 0mm;
  }
}

.contents.contents-transfer-statement {
  @import '../../../assets/stylesheets/design/variables';
  background-color: $color-bg;
}
</style>
