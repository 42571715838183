<template>
  <div>
    <div class="header">
      <h2>{{ PaymentMethod.credit_card }}</h2>
      <div class="header-contents">
        <div class="price-title">
          <div class="icon">
            <v-icon icon="mdi-credit-card-outline" />
          </div>
          <h4>支払い金額</h4>
        </div>
        <div class="price">{{ totalPrice }}<span>円</span></div>
      </div>
    </div>
    <section class="section">
      <div class="section_inner">
        <h3>前回のクレジットカード</h3>
      </div>
      <div class="section_inner">
        <h4>カード番号</h4>
        <div class="card_number">
          {{ cardNo }}
        </div>
        <div class="section_inner">
          <h4>有効期限</h4>
          <div>{{ expireDate }}</div>
        </div>
        <div class="section_inner" style="margin: 60px auto 0">
          <p style="margin: 0; padding: 0">
            <button class="button-normal" type="submit" @click="openLastCreditCardPaymentModal">
              前回と同じクレジットカードで支払う
            </button>
          </p>
          <form
            id="`edit_invoice_${invoiceNumber}`"
            class="edit_invoice"
            :action="newCreditCardActionUrl"
            accept-charset="UTF-8"
            method="get"
          >
            <input
              type="submit"
              name="commit"
              class="button-sub"
              value="新しいクレジットカードで支払う"
              data-disable-with="新しいクレジットカードで支払う"
            />
            <input id="new" type="hidden" name="new" value="true" />
          </form>
        </div>
      </div>
    </section>
    <ModalComponent
      panel-width-class="panel-normal"
      :is-show-modal="showModals.lastCreditCardPaymentModal"
    >
      <template #body>
        <div class="modal-active">
          <div class="panel">
            <h3>支払い内容確認</h3>
            <p>この内容で間違いありませんか？</p>
            <div class="panel_contents">
              <h4>支払い金額</h4>
              <p>{{ totalPrice }}</p>
              <h4>使用するカード番号</h4>
              <p>{{ cardNo }}</p>
              <h4>使用するカードの有効期限</h4>
              <p>{{ expireDate }}</p>
              <form
                id="`edit_invoice_${invoiceId}`"
                class="edit_invoice"
                :action="registerCreditCardActionUrl"
                accept-charset="UTF-8"
                method="post"
              >
                <input type="hidden" name="_method" value="patch" autocomplete="off" />
                <input
                  type="hidden"
                  name="authenticity_token"
                  :value="authenticityToken"
                  autocomplete="off"
                />
                <button
                  type="submit"
                  class="button-normal"
                  :disabled="isPaying"
                  @click="submitPayment"
                >
                  支払いをする
                </button>
                <button
                  type="button"
                  class="button-sub"
                  :disabled="isPaying"
                  @click="closeLastCreditCardPaymentModal"
                >
                  戻る
                </button>
              </form>
            </div>
          </div>
        </div>
      </template>
    </ModalComponent>
  </div>
</template>

<script setup lang="ts">
import PaymentMethod from 'shared/consts/string/payment_method'
import { ref } from 'vue'
import ModalComponent from '../../components/ModalComponent.vue'

defineProps<{
  invoiceId: number
  totalPrice: string
  cardNo: string
  expireDate: string
  newCreditCardActionUrl: string
  registerCreditCardActionUrl: string
  authenticityToken: string
}>()

const showModals = ref({
  lastCreditCardPaymentModal: false,
})
const isPaying = ref(false)

const openLastCreditCardPaymentModal = () => {
  showModals.value.lastCreditCardPaymentModal = true
}
const submitPayment = (event: Event) => {
  event.preventDefault()
  // 決済リクエスト中に戻るボタンを押してmodalを閉じて、再度開いて決済すると二重になる可能性があるので制御する
  isPaying.value = true
  ;(event.target as HTMLFormElement).form.submit()
}
const closeLastCreditCardPaymentModal = (_eventObj: any) => {
  showModals.value.lastCreditCardPaymentModal = false
}
</script>
