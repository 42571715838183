import { Component, reactive } from 'vue'

type Exclusive<T extends Record<PropertyKey, unknown>, U extends Record<PropertyKey, unknown>> =
  // eslint-disable-next-line no-unused-vars
  | (T & { [K in Exclude<keyof U, keyof T>]?: never })
  // eslint-disable-next-line no-unused-vars
  | (U & { [K in Exclude<keyof T, keyof U>]?: never })

type ComponentMessageOption = {
  component: {
    name: string | Component
    bind?: { [key: string]: any }
  }
}

type SimpleMessageOption = {
  message: string[]
}

type Body = Exclusive<ComponentMessageOption, SimpleMessageOption>

type DialogState = {
  isShow: boolean
  isShowCancel: boolean
  isShowOk: boolean
  title: string
  body: Body
  modalWidth: number
  isDisableOkButton: boolean
  isConsentRequired: boolean
  disabledBackdrop: boolean
  consentMessage: string
  okButtonText: string
  isOkButtonType: 'contained' | 'outlined' | 'text'
  okButtonColor: 'primary' | 'error'
  cancelButtonText: string
  isShowDivider: boolean
}

type BaseDialogOptions = {
  title: string
  body: Body
  onConfirm?: () => void
  onCancel?: () => void
  onBackdrop?: () => void
  disabledBackdrop?: boolean
  isShowCancel?: boolean
  isShowOk?: boolean
  modalWidth?: number
  isDisableOkButton?: boolean
  isConsentRequired?: boolean
  consentMessage?: string
  okButtonText?: string
  isOkButtonType?: 'contained' | 'outlined' | 'text'
  okButtonColor?: 'primary' | 'error'
  cancelButtonText?: string
  isShowDivider?: boolean
}

type SimpleMessageDialogOptions = Omit<BaseDialogOptions, 'body' | 'isShowCancel'> &
  SimpleMessageOption
type MessageDialogOptions = SimpleMessageDialogOptions
type ConfirmDialogOptions = SimpleMessageDialogOptions &
  Required<Pick<SimpleMessageDialogOptions, 'onConfirm'>>

const state: DialogState = reactive<DialogState>({
  isShow: false,
  isShowCancel: true,
  isShowOk: true,
  title: '',
  body: {
    message: [''],
  },
  modalWidth: 500,
  isDisableOkButton: false,
  isConsentRequired: false,
  disabledBackdrop: false,
  consentMessage: '',
  okButtonText: 'OK',
  isOkButtonType: 'contained',
  okButtonColor: 'primary',
  cancelButtonText: 'キャンセル',
  isShowDivider: true,
})

let onConfirm: (() => void) | undefined
let onCancel: (() => void) | undefined
let onBackdrop: (() => void) | undefined

export class DialogService {
  static get disabledBackdrop(): boolean {
    return state.disabledBackdrop
  }

  static get show(): boolean {
    return state.isShow
  }

  static set show(show) {
    state.isShow = show
  }

  static get showCancel(): boolean {
    return state.isShowCancel
  }

  static get showOk(): boolean {
    return state.isShowOk
  }

  static get title(): string | undefined {
    return state.title
  }

  static get body(): Body {
    return state.body
  }

  static get modalWidth(): number {
    return state.modalWidth
  }

  static get isDisableOkButton(): boolean {
    return state.isDisableOkButton
  }

  static get isConsentRequired(): boolean {
    return state.isConsentRequired
  }

  static get consentMessage(): string {
    return state.consentMessage
  }

  static get okButtonText(): string {
    return state.okButtonText
  }

  static get isOkButtonType(): 'contained' | 'outlined' | 'text' {
    return state.isOkButtonType
  }

  static get okButtonColor(): 'primary' | 'error' {
    return state.okButtonColor
  }

  static get cancelButtonText(): string {
    return state.cancelButtonText
  }

  static get isShowDivider(): boolean {
    return state.isShowDivider
  }

  /**
   * ダイアログを開くための基本的なインターフェースです。
   * 複雑なメッセージを描画する場合は options.body にメッセージ用のComponentを渡してください。
   * 単純なメッセージでのダイアログを使用したい場合は、 DialogService.openMessage または DialogService.openConfirm の使用を推奨します。
   * @param options
   */
  static open(options: BaseDialogOptions): void {
    state.title = options.title
    state.body = options.body
    state.modalWidth = options.modalWidth ?? 500
    onConfirm = options.onConfirm
    onCancel = options.onCancel
    onBackdrop = options.onBackdrop
    state.disabledBackdrop = options.disabledBackdrop ?? false
    state.isShowCancel = options.isShowCancel ?? true
    state.isShowOk = options.isShowOk ?? true
    state.isDisableOkButton = options.isDisableOkButton || false
    state.isConsentRequired = options.isConsentRequired || false
    state.consentMessage = options.consentMessage || ''
    state.isShow = true
    state.okButtonText = options.okButtonText || 'OK'
    state.isOkButtonType = options.isOkButtonType || 'contained'
    state.okButtonColor = options.okButtonColor || 'primary'
    state.cancelButtonText = options.cancelButtonText || 'キャンセル'
    state.isShowDivider = options.isShowDivider ?? true
  }

  /**
   * OKボタンだけを持つダイアログを開くための、 DialogService.open のラッパーメソッドです。
   * ユーザーへの単純なアナウンスを示すダイアログのための使用を想定しています。
   * 複雑なメッセージを表示する場合は DialogService.open でcomponentを指定してください。
   * @param options
   */
  static openMessage(options: MessageDialogOptions): void {
    DialogService.open({
      title: options.title,
      body: {
        message: options.message,
      },
      onBackdrop: options.onBackdrop,
      onConfirm: options.onConfirm,
      onCancel: options.onCancel,
      disabledBackdrop: false,
      isShowCancel: false,
      isShowOk: true,
      modalWidth: options.modalWidth,
      isDisableOkButton: options.isDisableOkButton,
      isConsentRequired: options.isConsentRequired,
      consentMessage: options.consentMessage,
      okButtonText: options.okButtonText || 'OK',
      isOkButtonType: options.isOkButtonType || 'contained',
      okButtonColor: options.okButtonColor || 'primary',
      cancelButtonText: options.cancelButtonText || 'キャンセル',
      isShowDivider: true,
    })
  }

  /**
   * OKボタンとキャンセルボタンを持つダイアログを開くための、 DialogService.open のラッパーメソッドです。
   * ユーザーへの単純な処理実行許可を促すダイアログのための使用を想定しています。
   * 複雑なメッセージを表示する場合は DialogService.open でcomponentを指定してください。
   * @param options
   *
   */
  static openConfirm(options: ConfirmDialogOptions): void {
    DialogService.open({
      title: options.title,
      body: {
        message: options.message,
      },
      onBackdrop: options.onBackdrop,
      onConfirm: options.onConfirm,
      onCancel: options.onCancel,
      disabledBackdrop: false,
      isShowCancel: true,
      isShowOk: true,
      modalWidth: options.modalWidth,
      okButtonText: options.okButtonText,
      isOkButtonType: options.isOkButtonType,
      okButtonColor: options.okButtonColor,
      cancelButtonText: options.cancelButtonText,
      isShowDivider: true,
    })
  }

  static close(): void {
    state.isShow = false
  }

  static confirm(): void {
    state.isShow = false
    if (onConfirm !== undefined) {
      onConfirm()
    }
  }

  static cancel(): void {
    state.isShow = false
    if (onCancel !== undefined) {
      onCancel()
    }
  }

  static backdrop(): void {
    if (state.disabledBackdrop) {
      return
    }
    state.isShow = false

    if (onBackdrop !== undefined) {
      onBackdrop()
    }
  }
}
