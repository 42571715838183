import { FacilityParentAccountsCsvApi } from 'http/modules/facility_parent_accounts_csv'
import { ParentsService } from 'pages/parents_page/services/parents_service'
import { Parent, QueryParams } from 'pages/parents_page/model'
import { ParentsPageStore } from 'pages/parents_page/parents_page_store'
import { SnackbarService } from 'shared/services/snackbar_service'
import { ScreenLoadingService } from 'shared/services/screen_loading_service'
/**
 * 保護者一覧のLINE連携に関するロジックを持ちます。
 */

export class LineService {
  /**
   * LINEの登録状況をダウンロード
   * 全保護者を対象にCSVダウンロードします。
   */
  static download() {
    try {
      FacilityParentAccountsCsvApi.index({
        facilityId: ParentsService.getTargetFacilityId(),
      })
    } catch (error) {
      SnackbarService.open('CSVのダウンロードに失敗しました', 'error')
    } finally {
      ScreenLoadingService.hideLoader()
    }
  }

  /**
   * LINEID登録用紙を出力する
   * 選択された保護者に対して、LINEID登録用紙を出力します。
   * @param facilityId 施設ID
   * @param selectedParentIds 選択された保護者のID
   * @param queryParams 検索条件
   */
  static printing(facilityId: number, selectedParentIds: Parent['id'][], queryParams: QueryParams) {
    let url

    if (!ParentsPageStore.isSelectedAll) {
      url = `/facilities/${facilityId}/parent_accounts/printings?parent_ids=${selectedParentIds.join(
        ','
      )}`
    } else {
      // NOTE ページネーション関連は不要なため分割代入で捨てている
      const { page: _page, per_page: _per_page, ...params } = queryParams
      url = `/facilities/${facilityId}/parent_accounts/printings?${new URLSearchParams(
        Object.entries(params)
      )}`
    }
    window.open(url, '_blank')
  }
}
