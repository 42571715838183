<template>
  <div>
    <div class="header">
      <h2>{{ `クレジットカード情報${httpMethod === 'post' ? '追加' : '変更'}` }}</h2>
    </div>

    <div class="credit-card">
      <section class="section">
        <div class="section_inner">
          <noscript>
            <div class="errorMessage">
              <ul class="errorMessage_list">
                <li>JavaScript を有効にしてください</li>
              </ul>
            </div>
          </noscript>
          <ErrorMessageList :error-messages="errorMessages.base" />
          <ErrorMessageList :error-messages="errorMessages.token" />
          <ErrorMessageList :error-messages="errorMessages.card_no" />

          <h4>クレジットカード番号</h4>
          <input
            id="card_no"
            v-model="cardNo"
            placeholder="クレジットカード番号を入力してください"
            class="form-Item_inputText"
            inputmode="numeric"
          />
        </div>
        <div class="section_inner">
          <ErrorMessageList :error-messages="errorMessages.holder_name" />
          <h4>クレジットカード名義（例: TARO ENPAY）</h4>
          <input
            id="holder_name"
            v-model="holderName"
            placeholder="クレジットカード名義を入力してください"
            class="form-Item_inputText"
          />
        </div>
        <div class="section_inner">
          <ErrorMessageList :error-messages="errorMessages.expire_date_month" />
          <ErrorMessageList :error-messages="errorMessages.expire_date_year" />

          <h4>有効期限</h4>
          <input
            id="expire_date_month"
            v-model="expireDateMonth"
            placeholder="MM"
            class="form-Item_inputText-sm"
            inputmode="numeric"
          />
          月
          <input
            id="expire_date_year"
            v-model="expireDateYear"
            placeholder="YY"
            class="form-Item_inputText-sm"
            inputmode="numeric"
          />
          年
        </div>
      </section>
      <div>
        <input id="shop_id" type="hidden" name="shop_id" :value="gmoShopId" autocomplete="off" />
      </div>

      <form
        :id="
          httpMethod === 'post'
            ? 'new_credit_card_registration_form'
            : 'edit_credit_card_registration_form'
        "
        ref="form"
        :action="actionUrl"
        accept-charset="UTF-8"
        method="post"
        :class="
          httpMethod === 'post'
            ? 'new_credit_card_registration_form'
            : 'edit_credit_card_registration_form'
        "
        @submit="fillInToken($event)"
      >
        <input
          type="hidden"
          name="authenticity_token"
          :value="authenticityToken"
          autocomplete="off"
        />
        <input
          v-if="httpMethod !== 'post'"
          type="hidden"
          name="_method"
          :value="httpMethod"
          autocomplete="off"
        />
        <section class="section">
          <div class="section_inner">
            <ErrorMessageList :error-messages="errorMessages.cvc" />
            <h4>セキュリティーコード</h4>
            <input
              id="cvc"
              v-model="cvc"
              type="password"
              name="form[cvc]"
              class="form-Item_inputText-sm"
              inputmode="numeric"
            />

            <span>
              <a
                target="_blank"
                href="https://www.gmo-pg.com/service/mulpay/security/securitycode/"
                class="help"
                >？</a
              >
            </span>
          </div>
          <div>
            <input id="token" v-model="token" type="hidden" name="form[token]" />
          </div>
          <div class="section_inner">
            <input
              type="submit"
              name="commit"
              value="保存する"
              :disabled="isSubmitting"
              class="payment credit-card button-normal"
            />
          </div>
        </section>
      </form>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount, nextTick } from 'vue'
import ErrorMessageList from './components/ErrorMessageList.vue'

const props = withDefaults(
  defineProps<{
    httpMethod: 'post' | 'put'
    gmoShopId: string
    actionUrl: string
    errorMessages: Record<
      | 'base'
      | 'token'
      | 'card_no'
      | 'holder_name'
      | 'expire_date_month'
      | 'expire_date_year'
      | 'cvc',
      string[]
    >
    token?: string
    cvc?: string
    authenticityToken: string
  }>(),
  {
    token: '',
    cvc: '',
  }
)

const form = ref<HTMLFormElement | null>(null)
const callbackFunctionName = `_callback_${Date.now()}` as const
// 利用規約及びプラポリの同意ボタンがある場合は非活性としておく
// TODO: isSubmitting という名前は適切ではないと思うが，これがどこで使われているかわからないので一旦これで。
const isSubmitting = ref(false)
const token = ref(props.token)
const cardNo = ref('')
const holderName = ref('')
const expireDateMonth = ref('')
const expireDateYear = ref('')
const cvc = ref(props.cvc)
const expireDate = computed(() => `${expireDateYear.value}${expireDateMonth.value}`)

onMounted(() => {
  // GMO のコールバックはJSONPで呼ばれるため、グローバルに参照可能できる必要がある
  window[callbackFunctionName] = (response: {
    resultCode: string
    tokenObject: { token: string }
  }) => {
    const { resultCode, tokenObject } = response

    if (resultCode !== '000') {
      const code = Number(resultCode)
      let message = 'カード番号トークン取得時にエラーが発生しました。'
      if (code >= 100 && code <= 102) {
        message = 'カード番号の形式が正しくありません。'
      }
      if (code >= 110 && code <= 113) {
        message = '有効期限の形式が正しくありません。'
      }
      if (code >= 131 && code <= 132) {
        message = 'カード名義の形式が正しくありません。'
      }
      // eslint-disable-next-line no-alert
      alert(`${message}:${resultCode}`)
      isSubmitting.value = false
    } else {
      token.value = tokenObject.token

      nextTick(() => {
        if (form.value) {
          form.value.submit()
        }
      })
    }
  }
})
onBeforeUnmount(() => {
  delete window[callbackFunctionName]
})
const fillInToken = (e: Event) => {
  e.preventDefault()
  isSubmitting.value = true
  const regex = new RegExp(/^[A-Z\s]+$/)

  if (!regex.test(holderName.value)) {
    // eslint-disable-next-line no-alert
    alert('カード名義は半角ローマ字の大文字で入力してください。')
    isSubmitting.value = false
  } else {
    Multipayment.init(props.gmoShopId)
    Multipayment.getToken(
      {
        cardno: cardNo.value,
        expire: expireDate.value,
        securitycode: cvc.value,
        holdername: holderName.value,
        tokennumber: '1',
      },
      callbackFunctionName
    )
  }
}
</script>
