import { AxiosResponse } from 'axios'
import { HTTP } from '../common'

type CreateResponse = {
  id: number
  status: string
}

type ShowResponse = {
  id: number
  status: 'done' | 'error' | 'processing'
  success_count: number
  errors: {
    row: number
    col: number
    message: string
    data: string[]
  }[]
}

export class ParentImportRequestApi {
  static show(facilityId: number, workerProcessId: number): Promise<AxiosResponse<ShowResponse>> {
    return HTTP.get(`/facilities/${facilityId}/parents/import_requests/${workerProcessId}`)
  }

  static create(facilityId: number, file: File): Promise<AxiosResponse<CreateResponse>> {
    const params = new FormData()
    params.append('file', file)
    return HTTP.post(`/facilities/${facilityId}/parents/import_requests`, params)
  }
}
