<template>
  <AppFrame>
    <template #header>
      <CorporationHeader></CorporationHeader>
    </template>
    <YearMonthSelectArea class="year-month-select-area" />
    <LowerArea class="lower-area" />
  </AppFrame>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import AppFrame from 'shared/components/AppFrame.vue'
import { TransferService } from '../services/transfer_service'
import LowerArea from './LowerArea.vue'
import YearMonthSelectArea from './YearMonthSelectArea.vue'
import CorporationHeader from 'shared/components/header/CorporationHeader.vue'
import { MetaService } from 'shared/services/meta_service'

export default defineComponent({
  components: { AppFrame, YearMonthSelectArea, LowerArea, CorporationHeader },
  setup() {
    MetaService.addTitle({ mode: 'corporation', page: 'transfer' })
    // 初期のデータロード
    TransferService.loadInitialData()
    return {}
  },
})
</script>
<style lang="scss" scoped>
$year-month-select-area-height: 60px;
$app-bar-height: 48px;

.year-month-select-area {
  height: $year-month-select-area-height;
  width: 100%;
}
.lower-area {
  width: 100%;
  height: calc(100vh - $year-month-select-area-height - $app-bar-height);
  overflow-y: auto;
}
</style>
