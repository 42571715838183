<template>
  <AppFrame>
    <template #header>
      <FacilityHeader />
    </template>

    <div id="bach_class_change" class="wrapper-inner">
      <div class="contents">
        <div class="contents_inner">
          <h2 class="contents_title">一括クラス更新</h2>
          <p class="contents_read">進級、卒園の際にクラスを一括で更新することができます。</p>

          <div class="panel panel-wide">
            <div class="panel panel_inner">
              <form
                :action="action"
                accept-charset="UTF-8"
                method="post"
                class="class-from-to-list"
              >
                <input type="hidden" name="_method" value="patch" autocomplete="off" />
                <input
                  type="hidden"
                  name="authenticity_token"
                  :value="authenticityToken"
                  autocomplete="off"
                />
                <div class="class_row">
                  <div class="form-Item_select class_row_select_item">現在のクラス</div>
                  <div class="class_row_separator"></div>
                  <div class="form-Item_select class_row_select_item">変更後のクラス</div>
                </div>
                <div
                  v-for="(classes, classIndex) in currentClasses"
                  :key="classIndex"
                  class="class_row"
                >
                  <div class="form-Item_select class_row_select_item">
                    <div class="class_row_before">
                      <select
                        :id="`current_classes[${classIndex}]`"
                        name="school_class[current_classes][]"
                        class="class_row_select"
                      >
                        <template v-for="(classObj, index) in currentClasses" :key="classObj.id">
                          <option :value="classObj.id" :selected="index === classIndex">
                            {{ classObj.name }}
                          </option>
                        </template>
                      </select>
                    </div>
                  </div>
                  <div class="class_row_separator">→</div>
                  <div class="form-Item_select class_row_select_item">
                    <div class="class_row_after">
                      <select
                        :id="`new_classes[${classIndex}]`"
                        name="school_class[new_classes][]"
                        class="class_row_select"
                      >
                        <template v-for="(classObj, index) in currentClasses" :key="classObj.id">
                          <option :value="classObj.id" :selected="index === classIndex">
                            {{ classObj.name }}
                          </option>
                        </template>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="operation">
                  <button
                    class="button-md button-sub"
                    type="button"
                    @click="openConfirmUpdateModal"
                  >
                    更新する
                  </button>
                </div>

                <ModalComponent
                  panel-width-class="panel-md"
                  :is-show-modal="showModals.confirmUpdate"
                >
                  <template #title> 一括クラス更新 </template>
                  <template #body>
                    <p class="text-center">クラスを一括更新しますか？</p>
                    <p class="text-center text-danger">
                      即時で反映されます。この操作は取り消すことができません。
                    </p>
                    <p class="button-Wrap">
                      <button class="button-md button-normal" type="submit">更新する</button>
                    </p>
                  </template>
                </ModalComponent>

                <ModalComponent
                  panel-width-class="panel-md"
                  :is-show-modal="showModals.updateAfter"
                >
                  <template #title> 一括クラス更新 </template>
                  <template #body>
                    <p class="text-center">クラスを一括更新しました。</p>
                    <p class="button-Wrap">
                      <button
                        class="button-md button-normal"
                        type="button"
                        @click="showModals.updateAfter = false"
                      >
                        閉じる
                      </button>
                    </p>
                  </template>
                </ModalComponent>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AppFrame>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { MetaService } from 'shared/services/meta_service'
import ModalComponent from 'pages/batch_class_update_page/components/ModalComponent.vue'
import FacilityHeader from 'shared/components/header/FacilityHeader.vue'
import { RouteService } from 'shared/services/route_service'
import AppFrame from 'shared/components/AppFrame.vue'

MetaService.addTitle({ mode: 'facility', page: 'batchClassChange' })

const props = defineProps<{
  currentClasses: { id: number; name: string }[]
  authenticityToken: string
  hasNotice?: boolean
}>()

const showModals = ref<{ confirmUpdate: boolean; updateAfter: boolean }>({
  confirmUpdate: false,
  updateAfter: false,
})

onMounted(() => {
  if (props.hasNotice) {
    showModals.value.updateAfter = true
  }
})

const openConfirmUpdateModal = () => {
  showModals.value.confirmUpdate = true
}

const action = computed(() => `/facilities/${RouteService.getPathParamByIndex(1)}/classes/batch`)
</script>

<style scoped>
.contents_inner {
  margin-top: 144px;
}
</style>
