import { ReceiptPrintPageStore } from '../receipt_print_page_store'
import { ReceiptApi } from 'http/modules/receipt'
import { RouteService } from '../../../shared/services/route_service'

/**
 * 領収書の印刷に必要なロジックを持ちます
 */
export class ReceiptPrintService {
  static async loadReceipt(invoiceId: number): Promise<void> {
    try {
      ReceiptPrintPageStore.updateIsLoading(true)
      const response = (await ReceiptApi.show(ReceiptPrintService.getTargetFacilityId(), invoiceId))
        .data
      ReceiptPrintPageStore.updateReceipt(response.receipt)
    } finally {
      ReceiptPrintPageStore.updateIsLoading(false)
    }
  }

  private static getTargetFacilityId(): number {
    return Number(RouteService.getPathParamByIndex(1))
  }
}
