import { TopPageStore } from '../top_page_store'
import { ClassApi } from '../../../http/modules/class'
import { ParentSearchConditionService } from './parent_search_condition_service'
import { SchoolService } from './school_service'

/**
 * SchoolClassに関するロジックを持ちます
 */
export class SchoolClassService {
  static async loadSchoolClasses(): Promise<void> {
    const responseSchoolClasses = (
      await ClassApi.index(
        SchoolService.getTargetFacilityId(),
        TopPageStore.targetYear + '-' + TopPageStore.targetMonth
      )
    ).data.school_classes
    TopPageStore.updateSchoolClasses(
      responseSchoolClasses.map((schoolClass) => ({
        id: schoolClass.id,
        name: schoolClass.name,
      }))
    )
    ParentSearchConditionService.selectAllClassExceptNewAndGrad()
  }
}
