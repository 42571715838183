<template>
  <div class="my-6">
    <v-form>
      <v-row dense justify="space-between" align="center">
        <v-col cols="auto">
          <v-select
            v-model="selectedFrom"
            class="term-selector"
            :items="selectableMonths"
            variant="outlined"
            density="compact"
            label="開始月"
            hide-details="true"
            :clearable="clearable"
          ></v-select>
        </v-col>
        <v-col cols="1" class="text-center"> 〜 </v-col>
        <v-col cols="auto">
          <v-select
            v-model="selectedTo"
            class="term-selector"
            :items="selectableMonths"
            variant="outlined"
            density="compact"
            label="終了月"
            hide-details="true"
            :clearable="clearable"
          ></v-select>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import useYearMonthRangeSelector, {
  YearMonthRangeSelectorPropType,
} from './use_year_month_range_selector'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

export default defineComponent<YearMonthRangeSelectorPropType>({
  props: {
    /**
     *  年月しか利用しないため、日は任意の値で良い
     *  例）startDateが2021年12月1日、endDateが2022年2月1日
     *  の場合、選択可能年月は[2021-12, 2022-01, 2022-02]
     *  ※ endDateが渡されなかった場合、現在の月までになる
     */
    startDate: {
      type: dayjs.Dayjs,
      required: true,
    },
    endDate: {
      type: dayjs.Dayjs,
      default: () => {
        dayjs.extend(utc)
        dayjs.extend(timezone)
        return dayjs().tz('Asia/Tokyo')
      },
    },
    /** YearMonthRange型（from, toがstring） */
    defaultRange: {
      type: Object,
      default: () => ({}),
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  setup(props, context) {
    return useYearMonthRangeSelector(props, context)
  },
})
</script>
