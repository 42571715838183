<template>
  <v-card class="mx-auto">
    <v-card-title class="facility-summary px-4 py-2 d-flex align-center">
      <span class="text-h6">{{ summary.facility_name }}</span>
      <v-spacer />
      <span class="text-body-2">代表：{{ summary.responsible_person_name }}</span>
    </v-card-title>

    <v-card-actions>
      <div class="flex-grow-1 d-flex justify-space-around">
        <div class="d-flex align-center">
          <span class="mr-2 text-body-2">集金合計</span>
          <span>
            <AmountLabel :amount="summary.collect_total" :size="'S'"></AmountLabel>
          </span>
        </div>
        <div class="d-flex align-center">
          <span class="mr-2 text-body-2">控除額合計</span>
          <span>
            <AmountLabel :amount="summary.deduction_total" :size="'S'"></AmountLabel>
          </span>
        </div>
        <div class="d-flex align-center">
          <span class="mr-2 text-body-1">振込合計</span>
          <span class="text-primary">
            <AmountLabel :amount="summary.transfer_amount" :size="'M'"></AmountLabel>
          </span>
        </div>
      </div>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import AmountLabel from 'shared/components/AmountLabel.vue'
import { FacilityAccountingSummary } from 'http/modules/corporations_transfer'

export default defineComponent({
  components: {
    AmountLabel,
  },
  props: {
    summary: {
      type: Object as () => FacilityAccountingSummary,
      required: true,
    },
  },
})
</script>
<style lang="scss" scoped>
.facility-summary {
  background-color: #ecf6fe;
}
</style>
