<template>
  <div class="content-wrapper">
    <div class="pa-2 content-header">
      <span class="font-weight-bold white-font">
        一括削除する保護者・子どもを選択中です。元の画面に戻す場合は、「一括操作をキャンセル」を押してください。
      </span>
    </div>
    <div class="d-flex justify-space-between align-center mx-6">
      <div class="d-flex align-center">
        <v-checkbox
          :model-value="isSelectedAll ? true : allParentsSelectedInPage"
          label="ページ内すべて選択"
          class="shrink mr-8 font-weight-bold fs-16 mt-4 pt-1"
          style="width: 120px"
          data-testid="select-all-parents-in-page"
          :disabled="
            isSelectedAll || (targetDeleteParentsCount === 0 && targetDeleteChildrenCount === 0)
          "
          @update:model-value="
            $emit('update:allParentsSelectedInPage', (isSelectedAllInPage = $event))
          "
        />
        <div
          v-if="selectedParentIds.length > 0 || selectedChildIds.length > 0 || isSelectedAll"
          class="grey-out"
        >
          <div v-show="allParentsSelectedInPage || isSelectedAll">
            <span class="black-font">
              {{ isSelectedAll ? '現在の検索条件に一致するすべて' : 'ページ内すべて' }} </span
            >を選択中
          </div>
          <span>{{ targetDeletingCount }}</span>
        </div>
        <Button
          v-if="!isSelectedAll"
          variant="text"
          class="font-weight-bold"
          style="text-decoration: underline"
          :disabled="targetDeleteParentsCount === 0 && targetDeleteChildrenCount === 0"
          @click="$emit('update:isSelectedAll', true)"
        >
          現在の検索条件に一致するすべてを選択
        </Button>
      </div>
      <div class="d-flex">
        <Button
          color="red"
          variant="outlined"
          class="mx-2 font-weight-bold fs-16 outlined-button"
          :disabled="enableDeleteButton"
          @click="onClickExecute"
        >
          選択した保護者・子どもを削除
        </Button>
        <v-divider vertical />
        <Button
          variant="outlined"
          class="mx-2 font-weight-bold grey-out grey-out-border outlined-button fs-16"
          @click="
            $emit('onClickBulkOperationMode', undefined), $emit('update:isSelectedAll', false)
          "
        >
          <v-icon>mdi-close</v-icon>
          <span class="grey-out">一括操作をキャンセル</span>
        </Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref } from 'vue'
import Button from 'shared/components/Button.vue'
import { Parent, ChildId, ParentTotal } from 'pages/parents_page/model'

export default defineComponent({
  components: { Button },
  props: {
    allParentsSelectedInPage: {
      type: Boolean,
      default: false,
    },
    isSelectedAll: {
      type: Boolean,
      default: false,
    },
    selectedParentIds: {
      type: Array as PropType<Parent['id'][]>,
      default: () => [],
    },
    selectedChildIds: {
      type: Array as PropType<ChildId[]>,
      default: () => [],
    },
    parentTotal: {
      type: Object as PropType<ParentTotal>,
      required: true,
    },
    parents: {
      type: Array as PropType<Parent[]>,
      required: true,
    },
  },
  emits: [
    'onClickBulkOperationMode',
    'update:allParentsSelectedInPage',
    'update:isSelectedAll',
    'onClickDeleteSelected',
    'handleGaEvent',
  ],
  // eslint-disable-next-line max-lines-per-function
  setup(props, { emit }) {
    const isSelectedAllInPage = ref(false)
    const enableDeleteButton = computed(
      () =>
        !props.isSelectedAll &&
        props.selectedParentIds.length === 0 &&
        props.selectedChildIds.length === 0
    )
    const targetDeleteParentsCount = computed(() => props.parentTotal.deletable.count.parents)
    const targetDeleteChildrenCount = computed(() => props.parentTotal.deletable.count.children)
    const targetDeletingCount = computed(() => {
      // NOTE: 現在の検索条件に一致するすべてを選択している場合
      if (
        props.isSelectedAll &&
        targetDeleteParentsCount.value > 0 &&
        targetDeleteChildrenCount.value > 0
      ) {
        return `${targetDeleteParentsCount.value}名の保護者、${targetDeleteChildrenCount.value}名の子ども`
      }
      // NOTE: 現在の検索条件に一致するすべてを選択している場合 && 保護者のみ選択されていた場合
      else if (props.isSelectedAll && targetDeleteChildrenCount.value === 0) {
        return `${targetDeleteChildrenCount.value}名の保護者`
      }
      // NOTE: 現在の検索条件に一致するすべてを選択している場合 && 子どものみ選択されていた場合
      else if (props.isSelectedAll && targetDeleteParentsCount.value === 0) {
        return `${targetDeleteChildrenCount.value}名の子ども`
      }
      // NOTE: ページ内すべて or 個別で選択した場合
      else if (
        !props.isSelectedAll &&
        props.selectedParentIds.length > 0 &&
        props.selectedChildIds.length > 0
      ) {
        return `${props.selectedParentIds.length}名の保護者、${props.selectedChildIds.length}名の子ども`
      }
      // NOTE: ページ内すべて or 個別で選択した場合 && 保護者のみ選択されていた場合
      else if (!props.isSelectedAll && props.selectedParentIds.length > 0) {
        return `${props.selectedParentIds.length}名の保護者`
      }
      // NOTE: ページ内すべて or 個別で選択した場合 && 子どものみ選択されていた場合
      return `${props.selectedChildIds.length}名の子ども`
    })

    const onClickExecute = () => {
      emit('onClickDeleteSelected', true)
      emit('handleGaEvent', 'delete_selected_parents_children')
    }

    return {
      isSelectedAllInPage,
      enableDeleteButton,
      targetDeletingCount,
      targetDeleteParentsCount,
      targetDeleteChildrenCount,
      onClickExecute,
    }
  },
})
</script>
<style scoped lang="scss">
.content-wrapper {
  border-radius: 4px;
  border: 1px solid #232323;
  width: 100%;
  background-color: #eaeef4;
}
.content-header {
  background-color: #232323;
}
.white-font {
  color: #ffffff;
}
.black-font {
  color: #232323;
}
.grey-out {
  color: #727272;
}
.grey-out-border {
  border-color: #727272;
}
.outlined-button {
  line-height: 24px;
  background-color: #fff;
}
.fs-16 {
  font-size: 16px;
}
.v-btn ::v-deep .v-btn__content .v-icon {
  color: #727272;
}
</style>
