<template>
  <div>
    <v-data-table
      :headers="headers"
      fixed-header
      no-filter
      density="comfortable"
      :items="invoiceItems"
      items-per-page="50"
    >
      <template #bottom></template>
    </v-data-table>
    <template v-if="totalPages >= 2">
      <div class="d-flex justify-end align-center my-9">
        <span class="font-weight-medium mr-6"
          >{{ startIndex.toLocaleString() + '-' + endIndex.toLocaleString() }}件 /
          {{ invoiceItemSummary.total.count.toLocaleString() }}件</span
        >
        <Pagination
          :total-pages="totalPages"
          :is-disabled-prev="currentPage === 1"
          :is-disabled-next="currentPage === totalPages"
          :handle-click-prev="handleClickPrevPage"
          :handle-click-next="handleClickNextPage"
          class="d-flex align-center"
          @handle-change-current-page="handleChangeCurrentPage"
        />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, watch } from 'vue'
import { InvoiceItem, InvoiceItemSummary } from 'pages/export_invoice_items_page/model'
import Pagination from 'shared/components/pagination/Pagination.vue'
import { PaginationStore } from 'shared/components/pagination/pagination_store'

const props = defineProps<{
  invoiceItemSummary: InvoiceItemSummary
  invoiceItems: InvoiceItem[]
  startIndex: number
  endIndex: number
}>()

const emit = defineEmits<{
  (event: 'onPageChange', page: number): void
}>()

const currentPage = computed({
  get: () => PaginationStore.currentPage,
  set: (page: number) => {
    PaginationStore.updateCurrentPage(page)
  },
})

const totalPages = computed(() => props.invoiceItemSummary.total.pages.invoice_items)

const handleChangeCurrentPage = (page: number) => {
  currentPage.value = page
  emit('onPageChange', currentPage.value)
}

const handleClickNextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value += 1
    emit('onPageChange', currentPage.value)
  }
}

const handleClickPrevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value -= 1
    emit('onPageChange', currentPage.value)
  }
}

const headers = [
  { title: '施設ID', value: 'facilityId' },
  { title: '施設名', value: 'facilityName' },
  { title: '対象年月', value: 'targetYearAndMonth' },
  { title: '保護者姓', value: 'lastName' },
  { title: '保護者名', value: 'firstName' },
  { title: 'クラス名', value: 'className' },
  { title: '子どもID', value: 'childId' },
  { title: '子どもコード', value: 'childCode' },
  { title: '子ども名', value: 'childName' },
  { title: '請求回数', value: 'seq', align: 'end' },
  { title: '請求日時', value: 'sentAt' },
  { title: '支払日時', value: 'paidAt' },
  { title: '支払方法', value: 'paymentMethod' },
  { title: '請求内容', value: 'invoiceItemName' },
  { title: '請求マスタコード', value: 'masterInvoiceItemCode' },
  { title: '数量', value: 'count', align: 'end' },
  { title: '単価', value: 'unitPrice', align: 'end' },
  { title: '金額', value: 'price', align: 'end' },
] as const

onMounted(() => {
  window.scrollTo(0, 0)
})

watch(currentPage, () => {
  window.scrollTo(0, 0)
})
</script>

<style scoped lang="scss">
.v-data-table::v-deep th {
  font-size: 16px !important;
  font-weight: 700 !important;
  background-color: #f6f6f5 !important;
  border-top: 1px #e0e0e0 solid !important;
  vertical-align: middle !important;
  white-space: nowrap !important;
  color: #0009 !important;
}
.v-data-table::v-deep td {
  font-size: 16px !important;
  font-weight: 500;
  vertical-align: middle;
  white-space: nowrap;
}
.v-data-table::v-deep tr:nth-child(even) td {
  background: #f6f6f6;
}
.v-data-table::v-deep tr:hover {
  background: transparent !important;
}
.v-data-table ::v-deep .v-table__wrapper > table {
  border: 1px solid #c0c0c0;
}
.v-data-table--fixed-header ::v-deep .v-data-table__wrapper > table > thead > tr > th {
  border: 0 !important;
}
</style>
