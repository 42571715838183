<template>
  <v-app>
    <v-snackbar
      v-model="show"
      :location="location"
      :color="color"
      :variant="variant"
      :style="snackbarPositionStyle"
      :class="snackbarClass"
    >
      <div class="d-flex">
        <div v-if="icon" class="mr-3">
          <v-icon :size="22">{{ icon }}</v-icon>
        </div>
        <span class="font-weight-bold">{{ message }}</span>
      </div>
      <template #actions>
        <Button icon :color="iconColor" variant="text" @click="show = false">
          <v-icon :size="22">mdi-close</v-icon>
        </Button>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { SnackbarService } from '../services/snackbar_service'
import Button from 'shared/components/Button.vue'

/**
 * 画面上にSnackbarを表示するためのコンポーネントです。
 * 直接このコンポーネントをtemplateに埋め込む利用方法は想定されていません。
 *
 * import { SnackbarService } from 'path/to/snackbar_service'
 * SnackbarService.open('成功しました', 'info')
 *
 * とすることで、画面上にSnackbarが表示されます。
 */
const show = ref(true)

const message = computed(() => SnackbarService.message)
const color = computed(() => SnackbarService.color)
const location = computed(() => SnackbarService.location)
const variant = computed(() => SnackbarService.variant)
const icon = computed(() => SnackbarService.icon)
const iconColor = computed(() => (variant.value === 'flat' ? 'white' : color.value))
const snackbarPositionStyle = computed(() => ({
  marginTop: location.value === 'top' ? '40px' : '',
  marginBottom: location.value === 'bottom right' ? '28px' : '',
  marginRight: location.value === 'bottom right' ? '100px' : '',
}))
const snackbarClass = computed(() => ({
  'snackbar-info': SnackbarService.type === 'info',
  'snackbar-error': SnackbarService.type === 'error',
  'snackbar-success': SnackbarService.type === 'success',
}))

watch(show, (show) => {
  if (!show) {
    SnackbarService.removeComponent()
  }
})
</script>

<!-- eslint-disable-next-line vue-scoped-css/enforce-style-type -->
<style>
.snackbar-info.v-snackbar--active .v-snackbar--variant-text {
  background: #e3f2fb !important;
}

.snackbar-error.v-snackbar--active .v-snackbar--variant-text {
  background: #fbe5e4 !important;
}

.snackbar-success.v-snackbar--active .v-snackbar--variant-text {
  background: #e6fbe3 !important;
}
</style>
