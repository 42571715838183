<template>
  <div class="d-flex justify-space-between py-4">
    <div style="width: 200px">
      <div class="batchActions">
        <Button
          :ripple="false"
          rounded
          variant="outlined"
          class="mb-3 no-background-hover"
          data-testid="invoice-seq"
        >
          {{ getSeq }}回目
        </Button>
      </div>
      <p class="text-primary mb-1 d-flex justify-center" data-testid="invoice-total-price">
        <AmountLabel :amount="calculateTotalPriceOfInvoice(invoice)" :size="'L'" />
      </p>
      <Button
        v-if="isSavedInvoice(invoice) && isPaidInvoice(invoice)"
        :href="'/facilities/' + facility_id + '/invoices/' + invoice.id + '/receipt'"
        class="mb-3"
        variant="outlined"
        size="small"
        target="_blank"
        @click="$eventTracker.trackEvent('click_btn_sending_receipt', {})"
      >
        領収書発行
      </Button>
      <template v-if="isSavedInvoice(invoice) && isPaidInvoice(invoice)">
        <p class="d-flex justify-space-between blue-grey-text text-caption">
          <span>支払日 :</span><span>{{ formatDateTime(displayedPaidAt(invoice)) }}</span>
        </p>
        <p v-if="paymentMethod" class="d-flex justify-space-between blue-grey-text text-caption">
          <span>支払い方法 :</span
          ><span
            >{{ paymentMethod
            }}<template v-if="convenienceStoreFeeOfInvoice > 0">(手数料あり)</template></span
          >
        </p>
      </template>
      <p
        v-else-if="isSavedInvoice(invoice) && isSentInvoice(invoice)"
        class="blue-grey-text text-caption"
      >
        請求日 : {{ formatDateTime(invoice.sent_at) }}
      </p>
      <Button v-if="invoiceEditable()" size="small" @click="showInvoiceFormModal()"
        >詳細を開く</Button
      >
    </div>
    <div style="width: calc(100% - 200px - 24px)">
      <div
        class="d-flex flex-wrap justify-space-between"
        :class="{ 'mb-6': convenienceStoreFeeOfInvoice > 0 }"
        :style="
          convenienceStoreFeeOfInvoice > 0
            ? { height: 'calc(100% - 20px - 24px)' }
            : { height: '100%' }
        "
      >
        <div v-if="InvoiceItemsWithoutChild.length > 0" data-testid="child-row" style="width: 49%">
          <div class="text-primary text-h5 d-flex justify-end mb-5">
            <p class="text-right" data-testid="total-price-of-child">
              <AmountLabel :amount="calculateTotalAmountWithChildId()" :size="'M'" />
            </p>
          </div>
          <v-divider />
          <div
            v-for="invoice_item in InvoiceItemsWithoutChild"
            :key="invoice_item.id"
            class="d-flex mt-3"
          >
            <p class="text-left" style="width: calc(100% - 140px)" data-testid="invoice-item-name">
              {{ invoice_item.name }}
            </p>
            <p class="text-right" style="width: 140px" data-testid="invoice-item-subtotal">
              <AmountLabel :amount="calculateInvoiceItemSubtotal(invoice_item)" :size="'S'" />
            </p>
          </div>
        </div>
        <div
          v-for="(child, index) in parent.school_parent_children"
          :key="child.id"
          :class="{ 'mt-3': index >= 2 }"
          style="width: 49%"
          data-testid="child-row"
        >
          <div class="text-primary text-h5 d-flex mb-5">
            <p class="text-left" style="width: calc(100% - 140px)" data-testid="child-name">
              {{ child.first_name }}
            </p>
            <p class="text-right" style="width: 140px" data-testid="total-price-of-child">
              <AmountLabel :amount="calculateTotalAmountWithChildId(child.id)" :size="'M'" />
            </p>
          </div>
          <v-divider />
          <div
            v-for="invoice_item in getInvoiceItems(child.id)"
            :key="invoice_item.id"
            class="d-flex mt-3"
          >
            <p class="text-left" style="width: calc(100% - 140px)" data-testid="invoice-item-name">
              {{ invoice_item.name }}
            </p>
            <p class="text-right" style="width: 140px" data-testid="invoice-item-subtotal">
              <AmountLabel :amount="calculateInvoiceItemSubtotal(invoice_item)" :size="'S'" />
            </p>
          </div>
        </div>
      </div>
      <div v-if="convenienceStoreFeeOfInvoice > 0" class="d-flex justify-end text-caption">
        ※コンビニ支払い手数料{{
          convenienceStoreFeeOfInvoice
        }}円が、別途保護者様にてお支払いされています。
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { SchoolParentService } from '../services/school_parent_service'
import { InvoiceService } from '../services/invoice_service'
import { InvoiceItemInputService } from 'pages/top_page/services/invoice_item_input_service'
import { InvoiceItemService } from 'pages/top_page/services/invoice_item_service'
import AmountLabel from 'shared/components/AmountLabel.vue'
import { InvoiceModalService } from '../services/invoice_modal_service'
import { FilterService } from 'shared/services/filter_service'
import { SchoolService } from '../services/school_service'
import Button from 'shared/components/Button.vue'
import type { Invoice, InvoiceItem, InvoiceItemInput } from 'pages/top_page/models'
import PaymentMethod from 'shared/consts/string/payment_method'

const props = defineProps<{
  parentId: number
  invoiceId?: number | undefined // 未保存の新規請求書の場合にはここがundefinedの場合がある
}>()
const facility_id = SchoolService.getTargetFacilityId()
const parent = computed(() => {
  const result = SchoolParentService.getParentById(props.parentId)
  if (!result) throw new Error('Parent not found')
  return result
})
const invoice = computed(() =>
  parent.value.invoices.find((invoice) => invoice.id === props.invoiceId)
)
const getSeq = computed(() => {
  if (!invoice.value) {
    return parent.value.invoices.length + 1
  }
  return invoice.value.seq
})
const convenienceStoreFeeOfInvoice = computed(() => {
  if (!invoice.value) {
    return 0
  }
  return InvoiceService.calculateConvenienceStoreFeeOfInvoice(invoice.value)
})
const formatDateTime = (date: string) => FilterService.formatDateTime(date)

/**
 * @param {number | undefined} childId
 * @returns {Array} invoice_itemの配列か、invoice_item_inputの配列を返します。
 */
const getInvoiceItems = (
  childId?: number | undefined
): InvoiceItem[] | (InvoiceItemInput & { id: undefined })[] => {
  if (!invoice.value) {
    return InvoiceItemInputService.getItemInputs(props.parentId, childId)
      .filter((item) => item.name)
      .map((item) => ({
        id: undefined,
        ...item,
      }))
  }

  if (invoice.value.status !== 'paid') {
    return InvoiceItemInputService.getItemInputs(props.parentId, childId)
      .filter((item) => item.name)
      .map((item) => ({
        id: undefined,
        ...item,
      }))
  }

  // コンビニ支払い手数料は子どもごとの請求内容としては表示しない
  return InvoiceService.getInvoiceItems(invoice.value, childId).filter(
    (item) => !item.is_convenience_store_fee && item.name
  )
}
const InvoiceItemsWithoutChild = computed(() => getInvoiceItems())

/**
 * @param {number | undefined} childId school_parent_child_idのこと
 * @returns {number}
 */
const calculateTotalAmountWithChildId = (childId?: number | undefined) => {
  if (!invoice.value) {
    return InvoiceItemInputService.calculateInputTotalAmountOfChild(props.parentId, childId)
  }
  return InvoiceItemService.calculateTotalAmountOfChild(invoice.value.id, childId)
}
const calculateTotalPriceOfInvoice = (invoice: Invoice | undefined) => {
  if (!invoice) {
    return InvoiceItemInputService.calculateInputTotalAmountByParentId(props.parentId)
  }
  return InvoiceService.calculateTotalAmountOfInvoice(invoice.id, false)
}
const isSavedInvoice = (invoice: Invoice | undefined): invoice is Invoice => !!invoice
const isPaidInvoice = (
  invoice: Invoice
): invoice is Invoice & { status: 'paid'; paid_at: string } =>
  invoice.status === 'paid' && !!invoice.paid_at
const isSentInvoice = (
  invoice: Invoice
): invoice is Invoice & { status: 'unpaid'; sent_at: string } =>
  invoice.status === 'unpaid' && !!invoice.sent_at
const displayedPaidAt = (invoice: Invoice & { status: 'paid'; paid_at: string }) =>
  invoice.displayed_paid_at || invoice.paid_at
const showInvoiceFormModal = () => {
  const status = !invoice.value ? 'before_register' : invoice.value.status
  InvoiceModalService.openInvoiceFormModal(props.parentId, status)
}
const invoiceEditable = () => {
  if (!invoice.value) return true
  return invoice.value.status !== 'paid'
}
const calculateInvoiceItemSubtotal = (invoice_item: InvoiceItem | InvoiceItemInput) => {
  const isInvoiceItem = (invoiceItem: InvoiceItem | InvoiceItemInput): invoiceItem is InvoiceItem =>
    typeof invoiceItem.unit_price === 'number'
  if (isInvoiceItem(invoice_item)) {
    return InvoiceItemService.calculateSubtotal(invoice_item.count, invoice_item.unit_price)
  }
  return InvoiceItemInputService.calculateInputSubtotal(invoice_item.count, invoice_item.unit_price)
}

const paymentMethod = computed(() => {
  if (!invoice.value) {
    throw new Error('invoice is not found')
  }
  if (!invoice.value.payment_method) {
    return ''
  }
  return PaymentMethod[invoice.value.payment_method]
})
</script>

<style lang="scss" scoped>
.no-background-hover {
  cursor: default;
  &::before {
    background-color: transparent !important;
  }
}
.cvs-store-fee-text {
  color: #232323;
}
</style>
