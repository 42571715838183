<template>
  <LinePaymentErrorResult v-bind="lineErrorResultPageProps" />
</template>

<script setup lang="ts">
import { LinePaymentErrorResult } from 'shared/components/line_payment'
import type { LinePaymentErrorResultProps } from 'shared/components/line_payment/LinePaymentErrorResult.vue'
import PaymentMethod from 'shared/consts/string/payment_method'

const props = defineProps<Omit<LinePaymentErrorResultProps, 'paymentMethod'>>()
const lineErrorResultPageProps = {
  ...props,
  paymentMethod: PaymentMethod.paypay,
}
</script>
