import Titles from 'shared/consts/string/title'

const modes = { corporation: '法人', facility: '施設', admin: 'admin' } as const

export class MetaService {
  private static suffix = ' | enpay' as const

  static addTitle({
    mode,
    page,
    additional = '',
  }: {
    mode?: keyof typeof modes
    page: keyof typeof Titles
    additional?: string
  }): void {
    document.title = this.prefix(mode) + Titles[page] + this.additional(additional) + this.suffix
  }

  private static prefix(mode: keyof typeof modes | undefined): string {
    if (mode === undefined) return ''

    return `${modes[mode]}-`
  }

  private static additional(additional: string): string {
    return additional !== '' ? ` ${additional}` : additional
  }
}
