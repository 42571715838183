import { HTTP } from '../common'

export type Invoice = {
  id: number
  target_year_and_month: string
  sent_at: string
  school_parent: {
    school: {
      school_name: string
    }
    last_name: string
    first_name: string
  }
  total_price: number
  childless_invoice_items: {
    subtotal: number
    invoice_items: {
      id: number
      name: string
      unit_price: number
      count: number
    }[]
  }
  children_with_invoice_items: {
    child: {
      id: number
      first_name: string
      class_name: string
    }
    subtotal: number
    invoice_items: {
      id: number
      name: string
      unit_price: number
      count: number
    }[]
  }[]
  payment_method_url: string
  cvs_payment_url: string
  credit_card_payment_url: string
}

export type IndexResponse = {
  data: Invoice[]
}

export class UnpaidInvoiceApi {
  static index(facilityId: number, invoiceIds: string[]): Promise<IndexResponse> {
    return HTTP.get(`/facilities/${facilityId}/unpaid_invoices?invoice_ids=${invoiceIds.join(',')}`)
  }
}
