import { ConvertToQueryParamsService } from 'pages/export_invoice_items_page/services/convert_to_query_params_service'
import { YearAndMonthRangeService } from 'pages/export_invoice_items_page/services/year_and_month_range_service'
import { QueryParamsKey, isTermType, isInvoiceStatus } from 'pages/export_invoice_items_page/model'
import { ExportInvoiceItemsPageStore } from 'pages/export_invoice_items_page/export_invoice_items_page_store'
import { getYear, getMonth } from 'shared/utils/date'
import { InvoiceStatus } from 'shared/models'
import { pushHistory } from 'shared/utils/history'

export class ExportInvoiceItemsUrlService {
  static copyQueryParamsToStore({ isFacility }: { isFacility: boolean }): void {
    const enteredQueryParams = new URLSearchParams(window.location.search)
    const queryParams: QueryParamsKey[] = [
      'from',
      'to',
      'term_type',
      'status',
      'include_keyword',
      'item_names',
    ]
    if (!isFacility) {
      queryParams.push('facility_ids')
    }

    queryParams.forEach((queryKey) => {
      if (enteredQueryParams.has(queryKey)) {
        ExportInvoiceItemsUrlService.updateStoreFromQueryParams(
          queryKey,
          enteredQueryParams.get(queryKey) as string
        )
      }
    })
  }

  /**
   * storeの値からクエリパラメータを上書きする関数
   * @param isFacility 施設画面からかどうか
   */
  static overwriteQueryParams({ isFacility }: { isFacility: boolean }): void {
    let queryParams: string

    if (isFacility) {
      const { from, to, termType, status, includeKeyword, itemNames } =
        ConvertToQueryParamsService.convertToQueryParams()
      queryParams = `?term_type=${termType}&from=${from}&to=${to}&include_keyword=${includeKeyword}&item_names=${itemNames}&status=${status}`
    } else {
      const { from, to, termType, status, includeKeyword, itemNames, facilityIds } =
        ConvertToQueryParamsService.convertToQueryParams()
      queryParams = `?term_type=${termType}&from=${from}&to=${to}&include_keyword=${includeKeyword}&item_names=${itemNames}&status=${status}&facility_ids=${facilityIds}`
    }

    // 検索条件を変更した履歴を残しておきたいので、pushHistoryを使う
    pushHistory(queryParams)
  }

  // eslint-disable-next-line max-lines-per-function
  private static updateStoreFromQueryParams(queryKey: QueryParamsKey, value: string): void {
    switch (queryKey) {
      case 'from':
        ExportInvoiceItemsPageStore.updateFromYearAndMonth({
          year: getYear(value),
          month: getMonth(value),
        })
        // URL手入力時に無効な期間なら修正する
        YearAndMonthRangeService.fixInvalidYearAndMonth('from')
        break
      case 'to':
        ExportInvoiceItemsPageStore.updateToYearAndMonth({
          year: getYear(value),
          month: getMonth(value),
        })
        // URL手入力時に無効な期間なら修正する
        YearAndMonthRangeService.fixInvalidYearAndMonth('to')
        break
      case 'term_type': {
        const targetItem = isTermType(value) ? value : 'target_year_and_month'
        ExportInvoiceItemsPageStore.updateTargetItem(
          targetItem === 'target_year_and_month' ? 'chargedMonth' : 'paidMonth'
        )
        break
      }
      case 'status':
        {
          const invoiceStatuses: InvoiceStatus[] = value
            .split(',')
            .every((value) => isInvoiceStatus(value))
            ? (value.split(',') as InvoiceStatus[])
            : ['paid', 'unpaid', 'before_charge']
          ExportInvoiceItemsPageStore.updateInvoiceStatuses(invoiceStatuses)
        }
        break
      case 'include_keyword': {
        const includeStatusType = value === 'true' || value === 'false' ? value : 'true'
        ExportInvoiceItemsPageStore.updateIncludeStatusType(
          JSON.parse(includeStatusType) ? 'include' : 'not_include'
        )
        break
      }
      case 'item_names':
        ExportInvoiceItemsPageStore.updateSelectedInvoiceItems(
          value.length === 0 ? [] : value.split(',')
        )
        break
      case 'facility_ids': {
        // @ts-ignore
        const facilityIds = value === '' || isNaN(value) ? 'all' : value
        ExportInvoiceItemsPageStore.updateSelectedFacilityId(facilityIds)
        break
      }
      default:
        throw new Error('invalid query key')
    }
  }
}
