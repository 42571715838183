<template>
  <div>
    <Chip v-if="isLockedInvoice" :text="'請求締め切り'" class="mr-2" />
    <PaidStatusChip v-if="displayHavingPaidInvoice" />
    <BeforeChargeStatusChip v-if="hasBeforeCharge" />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import Chip from 'shared/components/Chip.vue'
import PaidStatusChip from './PaidStatusChip.vue'
import BeforeChargeStatusChip from './BeforeChargeStatusChip.vue'

export default defineComponent({
  components: { Chip, PaidStatusChip, BeforeChargeStatusChip },
  props: {
    unpaidPayerCount: { type: Number, required: true },
    isLockedInvoice: { type: Boolean, required: true },
    hasBeforeCharge: { type: Boolean, required: true },
  },
  setup(props) {
    const displayHavingPaidInvoice = computed(
      () => props.isLockedInvoice && props.unpaidPayerCount === 0
    )

    return {
      displayHavingPaidInvoice,
    }
  },
})
</script>
