<template>
  <div>
    <div class="header">
      <h2>{{ PaymentMethod.cvs }}</h2>
      <div class="header-contents">
        <div class="price-title">
          <div class="icon">
            <v-icon>mdi-store</v-icon>
          </div>
          <h4>支払い金額</h4>
        </div>
        <div class="price">
          {{ totalPriceWithTax }}
          <span>円</span>
        </div>
      </div>
    </div>
    <div v-if="tax != '0'" class="break-down-area">
      <div class="break-down">内訳</div>
      <div class="body">
        <div>請求金額</div>
        <div class="value">{{ totalPrice }}円</div>
        <div class="space"></div>
      </div>
      <div class="body">
        <div>コンビニ支払い手数料</div>
        <div class="value">{{ tax }}円</div>
        <div class="space"></div>
      </div>
      <a :href="ExternalLink.cvsPaymentFeeHelpUrl" class="info">
        <div class="icon"></div>
        <div>コンビニ支払い手数料について</div>
        <div class="arrow"></div>
      </a>
    </div>
    <form
      id="new_cvs_payment_form"
      class="new_cvs_payment_form"
      :action="actionUrl"
      accept-charset="UTF-8"
      method="post"
    >
      <input
        type="hidden"
        name="authenticity_token"
        :value="authenticityToken"
        autocomplete="off"
      />
      <section class="section">
        <div class="section_inner">
          <ErrorMessageList :error-messages="errorMessages.cvs_code" />
          <h4>コンビニ選択</h4>
          <div class="form-Item_selectWrapper">
            <select
              id="cvs_payment_form_cvs_code"
              v-model="cvsCode"
              name="cvs_payment_form[cvs_code]"
              class="form-Item_select"
              required
            >
              <template v-for="cvs in cvsConvenienceCodes" :key="cvs.code">
                <option :value="cvs.code">{{ cvs.name }}</option>
              </template>
            </select>
          </div>
        </div>
        <div class="section_inner">
          <ErrorMessageList :error-messages="errorMessages.last_name" />
          <ErrorMessageList :error-messages="errorMessages.first_name" />
          <div class="section-md">
            <h4>姓</h4>
            <input
              id="cvs_payment_form_last_name"
              v-model="lastName"
              placeholder="例）えんぺい"
              class="form-Item_inputText"
              type="text"
              name="cvs_payment_form[last_name]"
            />
          </div>
          <div class="section-md">
            <h4>名</h4>
            <input
              id="cvs_payment_form_first_name"
              v-model="firstName"
              placeholder="例）太郎"
              class="form-Item_inputText"
              type="text"
              name="cvs_payment_form[first_name]"
            />
          </div>
        </div>
        <div class="section_inner">
          <ErrorMessageList :error-messages="errorMessages.last_name_kana" />
          <ErrorMessageList :error-messages="errorMessages.first_name_kana" />
          <div class="section-md">
            <h4>セイ</h4>
            <input
              id="cvs_payment_form_last_name_kana"
              v-model="lastNameKana"
              placeholder="例）エンペイ"
              class="form-Item_inputText"
              type="text"
              name="cvs_payment_form[last_name_kana]"
            />
          </div>
          <div class="section-md">
            <h4>メイ</h4>
            <input
              id="cvs_payment_form_first_name_kana"
              v-model="firstNameKana"
              placeholder="例）タロウ"
              class="form-Item_inputText"
              type="text"
              name="cvs_payment_form[first_name_kana]"
            />
          </div>
        </div>
        <div class="section_inner">
          <ErrorMessageList :error-messages="errorMessages.tel" />
          <h4>電話番号（ハイフンなし）</h4>
          <input
            id="cvs_payment_form_tel"
            v-model="tel"
            placeholder="例）09012345678"
            class="form-Item_inputText"
            type="text"
            name="cvs_payment_form[tel]"
          />
        </div>
        <template v-if="!readTermsAndPrivacyPolicyAt">
          <div class="cvs_payment_term_and_pp_area">
            <div>利用規約・プライバシーポリシーに同意した上でご利用ください。</div>
            <ul class="cvs_payment_term_links">
              <li>
                <a href="/school_parents/terms_of_service" target="_blank">利用規約</a>
              </li>
              <li>
                <a href="/schools/privacy_policy" target="_blank">プライバシーポリシー</a>
              </li>
              <li>
                <a target="_blank" :href="externalTransmissionsUrl">
                  利用者情報の外部送信について
                </a>
              </li>
            </ul>
          </div>
          <div class="cvs_payment_agree_box_area">
            <label>
              <input
                v-model="isCheckedReadTermsAndPrivacyPolicy"
                type="checkbox"
                class="form-Item_inputCheck cvs_payment_agree_button"
                autocomplete="off"
              />
              確認しました
            </label>
          </div>
        </template>
        <div class="section_inner">
          <p>
            <input
              type="submit"
              name="commit"
              :disabled="!isCheckedReadTermsAndPrivacyPolicy"
              value="次へ進む"
              class="payment cvs button-normal"
              data-disable-with="次へ進む"
            />
          </p>
        </div>
      </section>
    </form>
  </div>
</template>

<script setup lang="ts">
import PaymentMethod from 'shared/consts/string/payment_method'
import ErrorMessageList from 'pages/line_cvs_payment_page/components/ErrorMessageList.vue'
import { ref } from 'vue'
import ExternalLink from 'shared/consts/external_link'

const props = withDefaults(
  defineProps<{
    authenticityToken: string
    actionUrl: string
    totalPrice: string
    tax: string
    totalPriceWithTax: string
    cvsCode?: string
    firstName?: string
    lastName?: string
    firstNameKana?: string
    lastNameKana?: string
    tel?: string
    cvsConvenienceCodes: { name: string; code: string }[]
    errorMessages: Record<
      'cvs_code' | 'last_name' | 'first_name' | 'last_name_kana' | 'first_name_kana' | 'tel',
      string[]
    >
    readTermsAndPrivacyPolicyAt?: string
  }>(),
  {
    cvsCode: '',
    firstName: '',
    lastName: '',
    firstNameKana: '',
    lastNameKana: '',
    tel: '',
    readTermsAndPrivacyPolicyAt: '',
  }
)

const isCheckedReadTermsAndPrivacyPolicy = ref(!!props.readTermsAndPrivacyPolicyAt)
const cvsCode = ref(props.cvsCode)
const firstName = ref(props.firstName)
const lastName = ref(props.lastName)
const firstNameKana = ref(props.firstNameKana)
const lastNameKana = ref(props.lastNameKana)
const tel = ref(props.tel)
const externalTransmissionsUrl = ExternalLink.externalTransmissionsUrl
</script>

<style scoped lang="scss">
.button-normal[disabled],
.button-normal[disabled]:hover {
  background: #e2e2e2;
  color: #a1a1a1 !important;
  border: 1px solid #e2e2e2;
  cursor: default;
  opacity: initial;
}

.cvs_payment_term_and_pp_area {
  background-color: #f6f6f6;
  padding: 16px;
  font-size: 14px;
}

.cvs_payment_term_links {
  margin-top: 8px;
  list-style-type: disc;
}

.cvs_payment_term_links li {
  margin-left: 24px;
}

.cvs_payment_term_links li a {
  line-height: 21px;
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
}

.cvs_payment_agree_box_area {
  text-align: center;
  margin-top: 20px;
}
</style>
