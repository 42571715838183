<template>
  <v-sheet width="100%" class="mx-auto mt-6 pa-4">
    <v-container fluid>
      <v-row dense>
        <v-col cols="1" class="d-flex align-center" style="min-width: 120px; height: 48px">
          <span class="font-weight-bold fs-16">保護者</span>
        </v-col>
        <v-col>
          <fieldset>
            <v-container fluid>
              <v-row dense>
                <v-col cols="4" class="py-0">
                  <div class="pb-2 d-flex align-start ga-1">
                    <span class="font-weight-bold">姓</span>
                    <ContainedLabel class="bg-red text-white required" text="必須" />
                  </div>
                  <TextInput
                    :value="parent.lastName"
                    :on-change="(value: string) => (parent.lastName = value)"
                    placeholder="エンペイ"
                    class="font-weight-medium fs-14 mr-6"
                    :rules="[
                      parentValidatorsService.isRequired('保護者の姓'),
                      parentValidatorsService.isTooLong('保護者の姓', 255),
                    ]"
                  />
                </v-col>
                <v-col cols="4" class="py-0">
                  <div class="pb-2 d-flex align-start ga-1">
                    <span class="font-weight-bold">名</span>
                    <ContainedLabel class="bg-red text-white required" text="必須" />
                  </div>
                  <TextInput
                    :value="parent.firstName"
                    :on-change="(value: string) => (parent.firstName = value)"
                    placeholder="太郎"
                    class="font-weight-medium fs-14 mr-6"
                    :rules="[
                      parentValidatorsService.isRequired('保護者の名'),
                      parentValidatorsService.isTooLong('保護者の名', 255),
                    ]"
                  />
                </v-col>
                <v-col cols="3" class="py-0">
                  <div style="padding-bottom: 10px">
                    <span class="font-weight-bold">保護者コード</span>
                  </div>
                  <TextInput
                    :value="parent.code"
                    :on-change="(value: string) => (parent.code = value)"
                    placeholder="Code-01"
                    class="font-weight-medium fs-15 mr-6"
                    data-testid="parent-code"
                    :rules="[
                      parentValidatorsService.representativeInvalidCode('保護者コード'),
                      parentValidatorsService.representativeDuplicatedCodeErrorInParent(
                        '保護者コード',
                        parent,
                        parents
                      ),
                      parentValidatorsService.isTooLong('保護者コード', 255),
                    ]"
                    @input="resetErrors"
                  />
                </v-col>
                <v-col v-if="showDeletionButton" class="col-1 text-center">
                  <div class="remove-button" @click="$emit('removeParent')">
                    <v-icon class="pr-1">mdi-delete</v-icon>
                    <p class="font-weight-bold" style="color: #727272">削除</p>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </fieldset>
        </v-col>
      </v-row>
      <v-row
        v-for="(child, i) in parent.schoolParentChildren"
        :key="child.temporaryId"
        dense
        :style="i > 0 ? 'padding-top: 4px !important;' : ''"
      >
        <v-col cols="1" class="d-flex align-center" style="min-width: 120px; height: 48px">
          <span v-if="i === 0" class="font-weight-bold fs-16">子ども</span>
        </v-col>
        <v-col class="thin-gray">
          <fieldset>
            <v-container fluid>
              <v-row dense>
                <v-col cols="4">
                  <div class="pb-2">
                    <span class="font-weight-bold">子ども名</span>
                  </div>
                  <TextInput
                    :value="child.firstName"
                    :on-change="(value: string) => (child.firstName = value)"
                    placeholder="一郎"
                    background-color="#ffffff"
                    class="font-weight-medium fs-16 mr-6"
                    :rules="[
                      parentValidatorsService.representativeChildFirstName('子どもの名前', child),
                      parentValidatorsService.isTooLong('子どもの名前', 255),
                    ]"
                  />
                </v-col>
                <v-col cols="4">
                  <div class="pb-2">
                    <span class="font-weight-bold">クラス</span>
                  </div>
                  <SelectInput
                    v-model="child.schoolClassId"
                    variant="outlined"
                    density="compact"
                    hide-details="auto"
                    :items="classes"
                    item-text="label"
                    item-value="value"
                    background-color="#ffffff"
                    class="font-weight-medium fs-16"
                    data-testid="class-select"
                    :rules="[
                      parentValidatorsService.representativeSchoolClassErrorInChild(
                        'クラス',
                        child
                      ),
                    ]"
                    @change="(v) => onChangeSchoolClassId(child, v)"
                  />
                </v-col>
                <v-col cols="3">
                  <div class="pb-2">
                    <span class="font-weight-bold">子どもコード</span>
                  </div>
                  <TextInput
                    :value="child.code"
                    :on-change="(value: string) => (child.code = value)"
                    placeholder="Code-01"
                    background-color="#ffffff"
                    class="font-weight-medium fs-16 mr-6"
                    data-testid="child-code"
                    :rules="[
                      parentValidatorsService.representativeInvalidCode('子どもコード'),
                      parentValidatorsService.representativeDuplicatedCodeErrorInChild(
                        '子どもコード',
                        child,
                        parents
                      ),
                      parentValidatorsService.isTooLong('子どもコード', 255),
                    ]"
                    @input="resetErrors"
                  />
                </v-col>
                <v-col cols="1" class="text-center remove-button-container">
                  <div class="remove-button" @click="$emit('removeChild', i)">
                    <v-icon class="pr-1">mdi-delete</v-icon>
                    <div><span class="font-weight-bold text-grey">削除</span></div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </fieldset>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="1" class="d-flex align-center" style="min-width: 120px; height: 48px"> </v-col>
        <v-col>
          <Button
            variant="text"
            style="padding-left: 0; padding-right: 0"
            @click="$emit('addChild')"
          >
            <v-icon class="pr-1">mdi-plus-circle-outline</v-icon>
            <span class="font-weight-bold fs-16">子どもを追加する</span>
          </Button>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script lang="ts">
import { computed, ComputedRef, defineComponent, PropType } from 'vue'
import Button from 'shared/components/Button.vue'
import TextInput from 'shared/components/TextInput.vue'
import { EditableChild, EditableParent } from 'pages/parents_page/model'
import { SelectOption } from 'pages/account_settings_page/models'
import { SchoolClass } from 'http/modules/class'
import SelectInput from 'shared/components/SelectInput.vue'
import ContainedLabel from 'shared/components/label/ContainedLabel.vue'
import { ParentsAddPageStore } from 'pages/parents_page/parent_add/parent_adds_page_store'
import { ParentValidatorsService } from 'pages/parents_page/services/parent_validators_service'
import { ParentAddService } from 'pages/parents_page/parent_add/services/parent_add_service'

export default defineComponent({
  components: { ContainedLabel, SelectInput, TextInput, Button },
  props: {
    parent: {
      type: Object as PropType<EditableParent>,
      required: true,
    },
    parents: {
      type: Array as PropType<EditableParent[]>,
      required: true,
    },
    showDeletionButton: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['addChild', 'removeChild', 'removeParent'],
  setup({ parent: _parent, parents: _parents }) {
    const classes: ComputedRef<SelectOption[]> = computed(() =>
      ParentsAddPageStore.schoolClasses.map<SelectOption>((schoolClass: SchoolClass) => ({
        label: schoolClass.name,
        value: `${schoolClass.id}`,
      }))
    )
    const onChangeSchoolClassId = (child: EditableChild, v: string) => {
      child.schoolClassId = v
    }
    const resetErrors = () => {
      ParentAddService.setErrors([])
    }

    const parentValidatorsService = computed(() => ParentValidatorsService)

    return {
      classes,
      parentValidatorsService,
      onChangeSchoolClassId,
      resetErrors,
    }
  },
})
</script>
<style lang="scss" scoped>
.thin-gray {
  background-color: #f6f6f5;
}
.required {
  font-size: 12px;
  padding: 0 4px;
  display: inline-block;
}
.remove-button {
  cursor: pointer;
}
.fs-16 {
  font-size: 16px;
}
.remove-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
