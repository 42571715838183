<template>
  <div class="d-flex justify-space-between">
    <div class="d-flex align-center">
      <div class="mr-4">
        <YearMonthDropdown
          :from="from"
          :to="to"
          :outlined="true"
          button-background="white"
          @click-month="changeToSelectedYearMonth"
        >
          <div class="d-flex align-center text-h6 font-weight-bold pl-3 pr-2">
            {{ summaryTargetYearMonth.format('YYYY年MM月') }}
            <v-icon class="pr-1">mdi-menu-down</v-icon>
          </div>
        </YearMonthDropdown>
      </div>
      <span class="text-h5 font-weight-bold mr-6">請求状況</span>
    </div>
    <div class="d-flex">
      <template v-if="canBulkUnlock">
        <InvoiceBulkUnlockButton class="mr-4" @bulk-unlock-invoices="bulkUnlockInvoices()" />
      </template>
      <template v-else>
        <InvoiceBulkLockButton @bulk-lock-invoices="bulkLockInvoices()" />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import { InvoiceManagementPageStore } from '../../invoice_management_page_store'
import { InvoiceManagementService } from '../../services/invoice_management_service'
import { InvoiceLockService } from '../../services/invoice_lock_service'
import { AxiosResponse } from 'axios'
import { CorporationApi, Corporation } from 'http/modules/corporation'
import InvoiceBulkLockButton from './components/InvoiceBulkLockButton.vue'
import InvoiceBulkUnlockButton from './components/InvoiceBulkUnlockButton.vue'
import YearMonthDropdown from 'shared/components/year_month_dropdown/YearMonthDropdown.vue'

import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)

export default defineComponent({
  components: {
    InvoiceBulkLockButton,
    InvoiceBulkUnlockButton,
    YearMonthDropdown,
  },
  setup() {
    const from = ref<dayjs.Dayjs>(dayjs().tz('Asia/Tokyo'))
    const to = dayjs().tz('Asia/Tokyo').add(2, 'month').endOf('month') // 当月の翌々月まで請求が作成できるため`+2ヶ月`する

    CorporationApi.self().then((response: AxiosResponse<Corporation>) => {
      from.value = dayjs(response.data.oldest_facility_created_at)
        .tz('Asia/Tokyo')
        .startOf('month')
        .subtract(1, 'month') // 法人単位で一番最初に作成された施設の作成日時の前月から請求が作成できるため`-1ヶ月`する
    })

    const summaryTargetYearMonth = computed(() => InvoiceManagementPageStore.summaryTargetYearMonth)

    const changeToSelectedYearMonth = async (year: number, month: number) => {
      InvoiceManagementPageStore.updateSummaryTargetYearMonth(
        dayjs(new Date(year, month - 1, 1)).tz('Asia/Tokyo') // 月は0から始まるため`month`を`-1`する
      )
      await InvoiceManagementService.loadInvoiceSummariesFirstPage()
    }

    const canBulkUnlock = computed(() => InvoiceManagementPageStore.canBulkUnlock)

    const facilitySummary = computed(() => InvoiceManagementPageStore.facilitySummary)

    const bulkLockInvoices = () => {
      InvoiceLockService.bulkLockInvoices()
    }

    const bulkUnlockInvoices = () => InvoiceLockService.bulkUnlockInvoices()

    return {
      from,
      to,
      summaryTargetYearMonth,
      changeToSelectedYearMonth,
      canBulkUnlock,
      facilitySummary,
      bulkLockInvoices,
      bulkUnlockInvoices,
    }
  },
})
</script>
