<template>
  <v-app>
    <ScreenLoading />
    <slot name="header"></slot>
    <v-main>
      <UnsupportedBrowserNotification v-if="hasBrowserAlert" />
      <slot />
    </v-main>
  </v-app>
</template>
<script setup lang="ts">
import ScreenLoading from 'shared/components/ScreenLoading.vue'
import { ScreenLoadingService } from 'shared/services/screen_loading_service'
import { onMounted, onUnmounted } from 'vue'
import UnsupportedBrowserNotification from 'shared/components/header/components/UnsupportedBrowserNotification.vue'

withDefaults(
  defineProps<{
    hasBrowserAlert?: boolean
  }>(),
  {
    hasBrowserAlert: true,
  }
)

onMounted(() => {
  window.addEventListener('beforeunload', ScreenLoadingService.showLoader)
  window.addEventListener('load', ScreenLoadingService.hideLoader)
})

onUnmounted(() => {
  window.removeEventListener('beforeunload', ScreenLoadingService.showLoader)
  window.removeEventListener('load', ScreenLoadingService.hideLoader)
})
</script>
