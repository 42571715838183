<template>
  <v-card elevation="0" class="border mt-6" style="width: -webkit-fill-available">
    <v-container fluid>
      <v-row
        v-for="(child, i) in children"
        :key="child.id"
        :class="`d-flex align-center justify-space-between px-4 py-3 ${i > 0 ? 'pt-1' : ''}`"
      >
        <v-col cols="4" class="pa-0 d-flex">
          <ChildCheckbox
            v-if="bulkOperationMode === 'delete'"
            v-model:is-selected-all="isSelectedAll"
            :child="child"
            :parent="parent"
            :has-codmon-id="hasCodmonId"
            @on-change-child-selected="onChangeChildSelected"
            @on-click-disconnect-codmon="onClickDisconnectCodmon"
            @on-click-connect-codmon="onClickConnectCodmon"
          />
          <span v-else class="font-weight-bold fs-16 d-flex align-self-center">
            {{ child.first_name }}
          </span>
        </v-col>
        <v-col cols="3" class="pa-0">
          <OutlinedLabel label="クラス">
            <v-tooltip location="top">
              <template #activator="{ props }">
                <span class="font-weight-medium long-text" v-bind="props">{{
                  child.school_class.name
                }}</span>
              </template>
              <span class="font-weight-medium long-text">{{ child.school_class.name }}</span>
            </v-tooltip>
          </OutlinedLabel>
        </v-col>
        <v-col cols="3" class="pa-0">
          <OutlinedLabel label="子どもコード">
            <v-tooltip v-if="child.code" location="top">
              <template #activator="{ props }">
                <span class="font-weight-medium long-text text-left" v-bind="props">{{
                  child.code
                }}</span>
              </template>
              <span class="font-weight-medium long-text text-left">{{ child.code }}</span>
            </v-tooltip>
            <span v-else class="font-weight-medium long-text text-left">{{
              child.code || '-'
            }}</span>
          </OutlinedLabel>
        </v-col>
        <v-col v-if="hasCodmonId" cols="auto" class="pa-0" style="width: 150px">
          <div v-if="child.codmon_id" class="d-flex justify-end">
            <IconLabel
              image-path="/icons/codmon.svg"
              value="連携中"
              class="align-self-center"
              style="white-space: pre"
            />
            <v-tooltip location="top">
              <template #activator="{ props }">
                <div v-bind="props">
                  <Button
                    variant="text"
                    class="pa-0"
                    style="text-decoration: underline; font-size: 16px"
                    @click="$emit('onClickDisconnectCodmon', parent, child)"
                  >
                    解除
                  </Button>
                </div>
              </template>
              <span>コドモン連携を解除する</span>
            </v-tooltip>
          </div>
          <template v-else>
            <v-tooltip location="top">
              <template #activator="{ props }">
                <div v-bind="props" class="d-flex justify-end">
                  <Button
                    variant="text"
                    class="px-1 py-0 font-weight-medium"
                    style="text-decoration: underline"
                    @click="$emit('onClickConnectCodmon', parent, child)"
                  >
                    コドモン未連携
                  </Button>
                </div>
              </template>
              <span>コドモン連携をする</span>
            </v-tooltip>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script lang="ts">
import { defineComponent, PropType, computed, ComputedRef } from 'vue'
import OutlinedLabel from 'shared/components/label/OutlinedLabel.vue'
import IconLabel from 'shared/components/label/IconLabel.vue'
import { Parent, Child, ChildId, BulkOperationMode } from 'pages/parents_page/model'
import { ParentsPageStore } from 'pages/parents_page/parents_page_store'
import Button from 'shared/components/Button.vue'
import ChildCheckbox from 'pages/parents_page/areas/parent_card/components/ChildCheckbox.vue'

export default defineComponent({
  components: { OutlinedLabel, IconLabel, Button, ChildCheckbox },
  props: {
    parent: {
      type: Object as PropType<Parent>,
      required: true,
    },
    children: {
      type: Array as PropType<Child[]>,
      required: true,
    },
    bulkOperationMode: {
      type: String as PropType<BulkOperationMode>,
      default: undefined,
    },
    isSelectedAll: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onChangeChildSelected', 'onClickDisconnectCodmon', 'onClickConnectCodmon'],
  setup(props, { emit }) {
    const selectedChildIds: ComputedRef<ChildId[]> = computed({
      get: () => ParentsPageStore.selectedChildIds,
      set: (value) => emit('onChangeChildSelected', value, props.parent),
    })

    const hasCodmonId: ComputedRef<boolean> = computed(() => ParentsPageStore.hasCodmonId)

    const onChangeChildSelected = (selectedChildren: number[], parent: Parent) => {
      emit('onChangeChildSelected', selectedChildren, parent)
    }
    const onClickDisconnectCodmon = (parent: Parent, child: Child) => {
      emit('onClickDisconnectCodmon', parent, child)
    }
    const onClickConnectCodmon = (parent: Parent, child: Child) => {
      emit('onClickConnectCodmon', parent, child)
    }

    return {
      selectedChildIds,
      hasCodmonId,
      onChangeChildSelected,
      onClickDisconnectCodmon,
      onClickConnectCodmon,
    }
  },
})
</script>
<style scoped lang="scss">
.theme--light.v-card .v-card__subtitle,
.grey-text {
  font-size: 16px;
  color: #727272;
}
.v-card__title {
  padding: 0 10px 0 0;
}
.border {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}
.fs-16 {
  font-size: 16px;
}
.v-input--selection-controls {
  margin: 0 8px 0 0 !important;
}
.long-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 150px;
}
.v-btn:not(.v-btn--round).v-size--default {
  min-width: initial;
}
</style>
