<template>
  <div class="text-body-1 font-weight-bold">
    <span>{{ topMessage }}</span>
    <div class="mt-4 font-weight-bold">
      <span v-if="isSentInvoice" data-testid="sent-invoice-count">
        請求書発行: {{ sentInvoiceCount }} 件
      </span>
      <br />
      <template v-if="isSentLineInvoice">
        <span data-testid="success-line-count">LINE送信成功: {{ successLineCount }} 件</span>
        <br />
        <span v-if="failedLineCount > 0" class="text-red" data-testid="failed-line-count">
          LINE送信失敗: {{ failedLineCount }} 件
        </span>
        <br />
      </template>
    </div>
    <div v-if="isShowInfo" class="mt-4">
      <span v-if="failedLineCount > 0" class="mt-4" data-testid="failed-line-info">
        失敗した請求の詳細については、施設情報設定画面に登録してあるメールアドレス宛に送信いたしました。
      </span>
      <br />
      <span v-if="offsetInvoiceCount > 0" class="mt-4" data-testid="offset-invoice-info">
        {{ offsetInvoiceCount }}件は請求金額が０円のため、支払い済みとなりました。
      </span>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue'

export default defineComponent({
  props: {
    isRemind: {
      type: Boolean,
      required: true,
    },
    isSentLineInvoice: {
      type: Boolean,
      required: true,
    },
    isSentInvoice: {
      type: Boolean,
      required: true,
    },
    sentInvoiceCount: {
      type: Number,
      required: true,
    },
    offsetInvoiceCount: {
      type: Number,
      required: true,
    },
    successLineCount: {
      type: Number,
      required: true,
    },
    failedLineCount: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const topMessage = computed(() =>
      props.isRemind ? '一括再請求が完了しました。' : '一括請求が完了しました。'
    )
    const isShowInfo = computed(() => props.failedLineCount > 0 || props.offsetInvoiceCount > 0)
    return {
      topMessage,
      isShowInfo,
    }
  },
})
</script>
