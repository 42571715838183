import { computed, ComputedRef } from 'vue'
import { ImportPanelStore } from '../import_panel_store'
import { ErrorSummary, ImportError } from '../models'

/**
 * APサーバーから返されたエラーのうち、表形式で表示するエラー件数の上限値です。
 */
export const maxDisplayedInTableErrorCount = 20

/**
 * 取り込み結果表示に用いるロジックを持ちます
 */
export class ImportStatusService {
  /**
   * カラムマッピングにより設定された各カラムのカラム名を返します。
   * マッピングされていないカラムについては空文字を返します。
   */
  static mappedColumnNames(): ComputedRef<string[]> {
    return computed(() => {
      const flatMappedCandidates = ImportPanelStore.columnCandidates.flatMap(
        (candidate) => candidate.options
      )
      return ImportPanelStore.mappedColumnIds.map((columnId) => {
        if (columnId === undefined) {
          return ''
        }

        const candidate = flatMappedCandidates.find((candidate) => candidate.column_id === columnId)
        return candidate === undefined ? '' : candidate.column_name
      })
    })
  }

  /**
   * APサーバーから返されたエラー情報を件数で絞り込み、それを表形式で表示しやすいよう変形します。
   * 具体的には、同じ行に複数のエラーがあった場合、APサーバーから返された配列では別要素として
   * 扱われていますが、これを同じ要素に含めるよう変形します。
   */
  static errorSummaries(): ComputedRef<ErrorSummary[]> {
    return computed(() =>
      ImportPanelStore.importStatus.errors
        .slice(0, maxDisplayedInTableErrorCount)
        .reduce(
          (
            summaries: { row: number; data: string[]; errorMessageMap: Map<number, string> }[],
            current: ImportError
          ) => {
            if (summaries.length === 0) {
              return [
                {
                  row: current.csv_row,
                  data: current.data,
                  errorMessageMap: new Map<number, string>([[current.csv_col, current.message]]),
                },
              ]
            }
            const last = summaries[summaries.length - 1]
            if (last.row === current.csv_row) {
              last.errorMessageMap.set(current.csv_col, current.message)
              return summaries
            }
            summaries.push({
              row: current.csv_row,
              data: current.data,
              errorMessageMap: new Map<number, string>([[current.csv_col, current.message]]),
            })
            return summaries
          },
          []
        )
    )
  }

  /**
   *
   * エラー表示を行う表では、行が飛ぶ場合、それを示すための三点アイコンが表示されます。
   * それを表示するかどうかを判定するための関数です。
   *
   * @param index CSVの行数(0始まり)
   * @param errorSummary 該当行のエラー情報
   * @param errorSummaries 全てのエラーサマリ情報
   * @returns
   */
  static isShowDots(
    index: number,
    errorSummary: ErrorSummary,
    errorSummaries: ErrorSummary[]
  ): boolean {
    return (
      (index === 0 && errorSummary.row > 0) ||
      (index > 0 && errorSummaries[index - 1].row + 1 < errorSummary.row)
    )
  }
}
