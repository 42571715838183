import axios, { AxiosResponse, AxiosError } from 'axios'
import { InvoiceItemInput } from 'pages/top_page/models'
import { SchoolService } from 'pages/top_page/services/school_service'
import { TargetMonthService } from 'pages/top_page/services/target_month_service'
import { HTTP } from '../common'

export type SaveParamsParent = {
  id: number
  current_invoice_id: number | null
  invoice_items: InvoiceItemInput[]
}

export type SaveParams = {
  target_year_and_month: string // "yyyy-mm"
  parents: SaveParamsParent[]
}

export type ChargeParams = {
  target_year_and_month: string // "yyyy-mm"
  send_to_line: boolean
}

export type ImportExcelResponseRow = {
  id: number
  type: string
  message: string
  child: string
  invoice_item_name: string
  value: string
  cell: number
  row: number
}

export type ImportExcelResponse = {
  data: ImportExcelResponseRow[]
}

export type BatchUpdateStatusParams = {
  facilityId: number
  targetYearAndMonth: string
  schoolParentIds: number[]
  status: string
}
export type StatusUpdatedInvoice = {
  id: number
  school_parent_name: string
  total_price: number
  status: string
}
export type BatchUpdateStatusResponse = {
  results: StatusUpdatedInvoice[]
  errors: StatusUpdatedInvoice[]
}

export const isAxiosError = (error: unknown): error is AxiosError =>
  !!(error as AxiosError).isAxiosError

export default {
  /* DB上で締め状態なら403エラーでページが表示されないので、エラーが表示されているか確認するためのものです */
  checkReturnError() {
    const browserHTTP = axios.create({
      baseURL: '/',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN':
          document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') ?? '',
      },
    })

    // Invoiceのstatusを確認するというのが責務なので、引数からの本メソッドの再利用が無いようにメソッド内でURLを生成する
    const url = `/facilities/${SchoolService.getTargetFacilityId()}/invoices/import_requests?target_year_month=${TargetMonthService.getTargetMonthAsString()}`
    return browserHTTP.get(url)
  },
  save(facilityId: number, params: SaveParams): Promise<any> {
    return HTTP.put(`/facilities/${facilityId}/invoices/before_register`, params)
  },
  charge(facilityId: number, parent: any, params: ChargeParams): Promise<any> {
    return HTTP.post(
      `/facilities/${facilityId}/school_parents/${parent.id}/invoices/charge`,
      params
    )
  },
  batchCharge(facilityId: number, params: any): Promise<any> {
    return HTTP.post(`/facilities/${facilityId}/invoices/charges`, params)
  },
  batchUpdateStatus({
    facilityId,
    targetYearAndMonth,
    schoolParentIds,
    status,
  }: BatchUpdateStatusParams): Promise<AxiosResponse<BatchUpdateStatusResponse>> {
    return HTTP.patch(
      `/facilities/${facilityId}/invoices/status?target_year_and_month=${targetYearAndMonth}&school_parent_ids=${schoolParentIds}`,
      { status }
    )
  },
  importExcel(facilityId: number, params: any): Promise<ImportExcelResponse> {
    return HTTP.post(`/facilities/${facilityId}/invoices.xlsx`, params)
  },
  batchDelete(facilityId: number, target_year_and_month: string): Promise<any> {
    return HTTP.delete(
      `/facilities/${facilityId}/invoices/before_charged_invoices_by_year_and_month/${target_year_and_month}`
    )
  },
}
