import AddressApi, { AddressSearchResult } from '../../http/modules/address'

/**
 * 住所に関するロジックを集めたサービス。
 */
export class AddressService {
  /**
   * 渡された郵便番号で住所検索を行い、取得した住所（都道府県・市区町村・町名）を返却します。
   * 検索に失敗した場合エラーを投げます。
   * @param {string} postalCode ハイフンなしの半角数字（文字列）を渡す。
   * @returns 郵便番号による住所検索APIをコールしたときのレスポンスを返します。APIをコールしないとき・処理が失敗したときはundefinedを返します。
   */
  static async searchAddressByPostalCode(
    postalCode: string
  ): Promise<AddressSearchResult | undefined> {
    const POSTAL_CODE_LENGTH = 7
    if (postalCode.length !== POSTAL_CODE_LENGTH) return undefined

    try {
      return (await AddressApi.search(postalCode)).data
    } catch (error) {
      console.error(error)
      return undefined
    }
  }
}
