import { reactive } from 'vue'
import { AccountSettings } from '../../http/modules/account_setting'

type AccountSettingsPageState = AccountSettings

const state = reactive<AccountSettingsPageState>({
  settings: { invoice_page_initial_target_month: 'this_month' },
  account: { id: -1, email: '' },
})

export class AccountSettingsPageStore {
  static updateSettings(settings: AccountSettings['settings']): void {
    state.settings = settings
  }

  static updateAccount(account: AccountSettings['account']): void {
    state.account = account
  }

  static get invoicePageInitialTargetMonth(): AccountSettings['settings']['invoice_page_initial_target_month'] {
    return state.settings.invoice_page_initial_target_month
  }

  static updateInvoicePageInitialTargetMonth(
    value: AccountSettings['settings']['invoice_page_initial_target_month']
  ): void {
    state.settings.invoice_page_initial_target_month = value
  }

  static get email(): AccountSettings['account']['email'] {
    return state.account.email
  }
}
