import { reactive } from 'vue'
import { FacilityAccountingSummary, YearMonthRange } from 'http/modules/corporations_transfer'
import { StoreHelperService } from 'shared/services/store_helper_service'
import { TransferStatementDialogState } from './models'
import { CorporationsTransferSummary } from 'http/modules/corporations_transfer_summary'
import * as dayjs from 'dayjs'

type TransferPageState = {
  /** この法人で選択可能な最初の年月 */
  firstSelectableYearMonth: dayjs.Dayjs | undefined
  targetYearMonthRange: YearMonthRange | undefined
  searchKeyword: string
  transferStatementDialogState: TransferStatementDialogState
  isFacilityListLoading: boolean
  isTransferSummaryLoading: boolean
  facilityAccountingSummaries: FacilityAccountingSummary[]
  searchCursor: number | undefined
  infiniteLoadingIdentifier: number
  corporationsTransferSummary: CorporationsTransferSummary
}

const state = reactive<TransferPageState>({
  firstSelectableYearMonth: undefined,
  targetYearMonthRange: undefined,
  isFacilityListLoading: false,
  isTransferSummaryLoading: false,
  transferStatementDialogState: {
    isShow: false,
    targetYearMonthRange: { from: '', to: '' },
  },
  searchKeyword: '',
  facilityAccountingSummaries: [],
  searchCursor: undefined,
  infiniteLoadingIdentifier: +new Date(),
  corporationsTransferSummary: {
    collect_total_of_months: [],
    collect_total: undefined,
    transfer_amount: undefined,
    settlement_fee: undefined,
    enpay_service_charge: undefined,
    tax: undefined,
    cumulative_unpaid_amount: undefined,
    convenience_store_fee_applied: false,
    convenience_store_fee_total: undefined,
    transfer_fee_applied: false,
    transfer_fee_total: undefined,
  },
})

export class TransferPageStore {
  static get firstSelectableYearMonth(): dayjs.Dayjs | undefined {
    return state.firstSelectableYearMonth
  }

  static updateFirstSelectableYearMonth(firstSelectableYearMonth: dayjs.Dayjs) {
    state.firstSelectableYearMonth = firstSelectableYearMonth
  }

  static get targetYearMonthRange(): YearMonthRange | undefined {
    return state.targetYearMonthRange
  }

  static updateTargetYearMonthRange(targetYearMonthRange: YearMonthRange) {
    state.targetYearMonthRange = targetYearMonthRange
  }

  static get searchKeyword(): string {
    return state.searchKeyword
  }

  static updateSearchKeyword(searchKeyword: string): void {
    state.searchKeyword = searchKeyword
  }

  static get transferStatementDialogState(): TransferStatementDialogState {
    return state.transferStatementDialogState
  }

  static updateTransferStatementDialogState(
    transferStatementDialogState: TransferStatementDialogState
  ): void {
    state.transferStatementDialogState = transferStatementDialogState
  }

  static get isFacilityListLoading(): boolean {
    return state.isFacilityListLoading
  }

  static updateIsFacilityListLoading(isFacilityListLoading: boolean): void {
    state.isFacilityListLoading = isFacilityListLoading
  }

  static get isTransferSummaryLoading(): boolean {
    return state.isTransferSummaryLoading
  }

  static updateIsTransferSummaryLoading(isTransferSummaryLoading: boolean): void {
    state.isTransferSummaryLoading = isTransferSummaryLoading
  }

  static get facilityAccountingSummaries(): FacilityAccountingSummary[] {
    return state.facilityAccountingSummaries
  }

  static updateFacilityAccountingSummaries(
    facilityAccountingSummaries: FacilityAccountingSummary[]
  ): void {
    StoreHelperService.updateArrayState(
      state.facilityAccountingSummaries,
      facilityAccountingSummaries
    )
  }

  static get searchCursor(): number | undefined {
    return state.searchCursor
  }

  static updateSearchCursor(searchCursor: number | undefined): void {
    state.searchCursor = searchCursor
  }

  static get infiniteLoadingIdentifier(): number {
    return state.infiniteLoadingIdentifier
  }

  static updateInfiniteLoadingIdentifier(infiniteLoadingIdentifier: number): void {
    state.infiniteLoadingIdentifier = infiniteLoadingIdentifier
  }

  static get corporationsTransferSummary(): CorporationsTransferSummary {
    return state.corporationsTransferSummary
  }

  static updateCorporationsTransferSummary(
    corporationsTransferSummary: CorporationsTransferSummary
  ) {
    state.corporationsTransferSummary = corporationsTransferSummary
  }
}
