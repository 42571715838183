<template>
  <Button variant="outlined" class="font-weight-bold" @click="searchAddress"
    >郵便番号で住所検索</Button
  >
</template>

<script>
import { defineComponent } from 'vue'
import { AddressService } from '../services/address_service'
import Button from 'shared/components/Button.vue'

export default defineComponent({
  components: {
    Button,
  },
  props: {
    postalCode: String,
  },
  emits: ['handleSearchResult'],
  setup(props, context) {
    const searchAddress = async () => {
      const response = await AddressService.searchAddressByPostalCode(props.postalCode)
      // 親のコンポーネントでhandleSearchResultイベントを定義して、必要に応じて検索結果を整形してください
      // 住所が複数取得された場合は最初の1件だけ返します。
      if (response === undefined) return
      context.emit('handleSearchResult', response.addresses[0])
    }

    return {
      searchAddress,
    }
  },
})
</script>
