<template>
  <div>
    <AppFrame :has-browser-alert="false">
      <MailSendForm v-if="!isMailSendDone" :invoice-id="invoiceId" />
      <div v-if="isMailSendDone">
        <v-alert variant="text" type="success" class="mt-12 mx-4">
          領収書をご指定のメールアドレスに送信しました。
        </v-alert>
      </div>
    </AppFrame>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import MailSendForm from './MailSendForm.vue'
import { ReceiptSendPageStore } from '../receipt_send_page_store'
import { MetaService } from 'shared/services/meta_service'
import AppFrame from 'shared/components/AppFrame.vue'

defineProps<{
  invoiceId: string
}>()

MetaService.addTitle({ page: 'receiptSend' })
const isMailSendDone = computed(() => ReceiptSendPageStore.isMailSendDone)
</script>
