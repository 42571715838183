<template>
  <SearchForm>
    <v-row dense class="my-4">
      <v-col cols="1" class="d-flex align-center" style="min-width: 120px">
        <span class="font-weight-medium fs-14 grey-text">対象期間</span>
      </v-col>
      <SelectInput
        v-model="targetItem"
        variant="outlined"
        density="compact"
        :items="targetItems"
        item-text="label"
        item-value="value"
        hide-details
        style="max-width: 150px"
        class="mr-4"
        @change="onChangeTargetItem"
      />
      <SelectInput
        v-model="fromYear"
        variant="outlined"
        density="compact"
        :items="
          selectableFromYearAndMonthRange.years.map((item) => ({
            label: item.toString(),
            value: item.toString(),
          }))
        "
        item-text="label"
        item-value="value"
        hide-details
        style="max-width: 100px"
        @change="onChangeFromYear"
      />
      <span class="font-weight-medium fs-16 black-text align-self-center mx-2">年</span>
      <SelectInput
        v-model="fromMonth"
        variant="outlined"
        density="compact"
        :items="
          selectableFromYearAndMonthRange.months.map((item) => ({
            label: item.toString(),
            value: item.toString(),
          }))
        "
        item-text="label"
        item-value="value"
        hide-details
        style="max-width: 82px"
        @change="onChangeFromMonth"
      />
      <span class="font-weight-medium fs-16 black-text align-self-center mx-2">月</span>
      <span class="font-weight-medium fs-16 black-text align-self-center mr-2">〜</span>
      <SelectInput
        v-model="toYear"
        variant="outlined"
        density="compact"
        :items="
          selectableToYearAndMonthRange.years.map((item) => ({
            label: item.toString(),
            value: item.toString(),
          }))
        "
        item-text="label"
        item-value="value"
        hide-details
        style="max-width: 100px"
        @change="onChangeToYear"
      />
      <span class="font-weight-medium fs-16 black-text align-self-center mx-2">年</span>
      <SelectInput
        v-model="toMonth"
        variant="outlined"
        density="compact"
        :items="
          selectableToYearAndMonthRange.months.map((item) => ({
            label: item.toString(),
            value: item.toString(),
          }))
        "
        item-text="label"
        item-value="value"
        hide-details
        style="max-width: 82px"
        @change="onChangeToMonth"
      />
      <span class="font-weight-medium fs-16 black-text align-self-center mx-2">月</span>
    </v-row>
    <v-row dense>
      <v-col cols="1" class="d-flex align-center" style="min-width: 120px; height: 48px">
        <span class="font-weight-medium fs-14 grey-text">請求内容</span>
      </v-col>
      <v-col cols="3" class="px-0 mr-2">
        <ComboBox
          :invoice-item-name-candidates="invoiceItemNameCandidates"
          @on-change-invoice-items="onChangeInvoiceItems"
        />
      </v-col>
      <v-col cols="2" class="px-0">
        <SelectInput
          v-model="includeStatusType"
          variant="outlined"
          density="compact"
          :items="includeStatusTypes"
          item-text="label"
          item-value="value"
          hide-details
          style="max-width: 145px"
          class="mr-4"
          @change="onChangeIncludeStatusType"
        />
      </v-col>
      <template v-if="!isFacility">
        <v-col cols="1" class="d-flex align-center" style="height: 48px">
          <span class="font-weight-medium fs-14 grey-text">施設</span>
        </v-col>
        <v-col cols="4">
          <SelectInput
            v-model="selectedFacilityId"
            variant="outlined"
            density="compact"
            :items="facilities"
            item-text="label"
            item-value="value"
            hide-details
            @change="onChangeSelectedFacilityId"
          />
        </v-col>
      </template>
    </v-row>
    <v-row dense>
      <v-col cols="1" class="d-flex align-center" style="min-width: 120px">
        <span class="font-weight-medium fs-14 grey-text">ステータス</span>
      </v-col>
      <v-col cols="6">
        <v-radio-group v-model="invoiceStatuses" inline class="mt-4 pt-1">
          <template v-for="invoiceStatusType in invoiceStatusTypes" :key="invoiceStatusType.value">
            <v-checkbox
              v-model="invoiceStatuses"
              :label="invoiceStatusType.text"
              :value="invoiceStatusType.value"
              hide-details
              class="flex-grow-0 mr-8"
            />
          </template>
        </v-radio-group>
      </v-col>
      <v-col class="d-flex align-center justify-end">
        <Button
          variant="text"
          class="font-weight-medium fs-16"
          @click="$emit('onClickClearConditions')"
        >
          検索条件をクリア
        </Button>
      </v-col>
    </v-row>
  </SearchForm>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import ComboBox from 'pages/export_invoice_items_page/areas/invoice_items_search_form/components/ComboBox.vue'
import Button from 'shared/components/Button.vue'
import SelectInput from 'shared/components/SelectInput.vue'
import SearchForm from 'shared/components/search_form/SearchForm.vue'
import { ExportInvoiceItemsPageStore } from 'pages/export_invoice_items_page/export_invoice_items_page_store'
import { InvoiceItemNameCandidate, AvailableFacility } from 'shared/models'
import { IncludeStatusType, TargetItem } from 'pages/export_invoice_items_page/model'

export default defineComponent({
  components: { ComboBox, Button, SelectInput, SearchForm },
  props: {
    isFacility: {
      type: Boolean,
      required: true,
    },
    invoiceItemNameCandidates: {
      type: Array as PropType<InvoiceItemNameCandidate[]>,
      required: true,
    },
    availableFacilities: {
      type: Array as PropType<AvailableFacility[]>,
      required: true,
    },
  },
  /**
   * updateRangeは以下の2つの処理を行う(2つの処理が同時に呼ばれることが多いのでまとめている)
   * 1. フォームの値が無効な期間なら補正する(無効な期間は以下の3種類)
   *   - a. 施設作成日の前月(請求を作成できる最初の日付)よりも過去
   *   - b. 現在時刻の2ヶ月後(請求を作成できる最後の日付)よりも未来
   *   - c. 開始年月 > 終了年月(開始年月が終了年月よりも未来)
   *     - 例えば、2022/1 ~ 2022/12 と選択されている時、終了年月を2021/12に変更すると、開始年月 > 終了年月になるので、開始年月も2021/12へと変更(補正)される
   * 2. 対象期間のドロップダウンの中身を選択中の年・月に合わせて更新する
   *   - 開始月・終了月の選択候補はそれぞれ、選択中の開始年・開始月を参照して動的に変化する
   */
  emits: ['updateRange', 'refreshPage', 'onClickClearConditions'],
  /* eslint-disable max-lines-per-function, max-lines */
  setup(props, context) {
    const openPanelIndex = ref(0)
    const selectableFromYearAndMonthRange = computed(
      () => ExportInvoiceItemsPageStore.selectableFromYearAndMonthRange
    )
    const selectableToYearAndMonthRange = computed(
      () => ExportInvoiceItemsPageStore.selectableToYearAndMonthRange
    )

    const targetItems = [
      { label: '請求対象月', value: 'chargedMonth' },
      { label: '支払月', value: 'paidMonth' },
    ]
    const invoiceStatusTypes = [
      { text: '未払い', value: 'unpaid' },
      { text: '支払い済み', value: 'paid' },
      { text: '請求保存中', value: 'before_charge' },
    ]
    const includeStatusTypes = [
      { label: 'を含む', value: 'include' },
      { label: 'を含まない', value: 'not_include' },
    ]

    const targetItem = computed(() => ExportInvoiceItemsPageStore.targetItem)

    const onChangeTargetItem = (value: TargetItem) => {
      ExportInvoiceItemsPageStore.updateTargetItem(value)
      context.emit('refreshPage')
    }

    const fromYear = computed(() => ExportInvoiceItemsPageStore.fromYearAndMonth.year?.toString())
    const fromMonth = computed(() => ExportInvoiceItemsPageStore.fromYearAndMonth.month?.toString())
    const toYear = computed(() => ExportInvoiceItemsPageStore.toYearAndMonth.year?.toString())
    const toMonth = computed(() => ExportInvoiceItemsPageStore.toYearAndMonth.month?.toString())

    const onChangeFromYear = (value: string) => {
      ExportInvoiceItemsPageStore.updateFromYearAndMonth({
        year: Number(value),
      })
      context.emit('updateRange', 'from')
      context.emit('refreshPage')
    }

    const onChangeFromMonth = (value: string) => {
      ExportInvoiceItemsPageStore.updateFromYearAndMonth({
        month: Number(value),
      })
      context.emit('updateRange', 'from')
      context.emit('refreshPage')
    }

    const onChangeToYear = (value: string) => {
      ExportInvoiceItemsPageStore.updateToYearAndMonth({
        year: Number(value),
      })
      context.emit('updateRange', 'to')
      context.emit('refreshPage')
    }

    const onChangeToMonth = (value: string) => {
      ExportInvoiceItemsPageStore.updateToYearAndMonth({
        month: Number(value),
      })
      context.emit('updateRange', 'to')
      context.emit('refreshPage')
    }

    const onChangeInvoiceItems = (value: string[]) => {
      ExportInvoiceItemsPageStore.updateSelectedInvoiceItems(value)
      context.emit('refreshPage')
    }

    const includeStatusType = computed(() => ExportInvoiceItemsPageStore.includeStatusType)

    const onChangeIncludeStatusType = (value: IncludeStatusType) => {
      ExportInvoiceItemsPageStore.updateIncludeStatusType(value)
      context.emit('refreshPage')
    }

    const invoiceStatuses = computed({
      get: () => ExportInvoiceItemsPageStore.invoiceStatuses,
      set: (value) => {
        ExportInvoiceItemsPageStore.updateInvoiceStatuses(value)
        context.emit('refreshPage')
      },
    })

    const facilities = computed(() => {
      const allOption = {
        label: 'すべて',
        value: 'all',
      }

      const facilityOptions = props.availableFacilities.map((facility) => ({
        label: facility.name,
        value: facility.id.toString(),
      }))

      return [allOption, ...facilityOptions]
    })

    const selectedFacilityId = computed(() => ExportInvoiceItemsPageStore.selectedFacilityId)
    const onChangeSelectedFacilityId = (value: string) => {
      ExportInvoiceItemsPageStore.updateSelectedFacilityId(value)
      context.emit('refreshPage')
    }

    return {
      openPanelIndex,
      targetItems,
      invoiceStatusTypes,
      includeStatusTypes,
      targetItem,
      onChangeTargetItem,
      invoiceStatuses,
      fromYear,
      onChangeFromYear,
      fromMonth,
      onChangeFromMonth,
      toYear,
      onChangeToYear,
      toMonth,
      onChangeToMonth,
      onChangeInvoiceItems,
      includeStatusType,
      onChangeIncludeStatusType,
      selectableFromYearAndMonthRange,
      selectableToYearAndMonthRange,
      facilities,
      selectedFacilityId,
      onChangeSelectedFacilityId,
    }
  },
})
</script>

<style scoped lang="scss">
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.grey-text {
  color: #787878;
}
.black-text {
  color: #232323;
}
.align-self-center {
  align-self: center;
}
.v-checkbox::v-deep {
  .v-label {
    color: #232323;
  }
}
</style>
