<!-- 保護者一覧 新規登録、編集 重複している保護者がいる場合に表示するメッセージ -->
<template>
  <div>
    <span class="text-red font-weight-bold"
      >兄弟姉妹がいる場合は、登録済みの保護者の2人目以降に登録してください。</span
    >
    <br />
    <span>別世帯として登録する場合はそのまま取り込むことができます。</span>
    <div class="pt-4 mb-6 scrollable-horizontal">
      <template v-for="(error, rowNumber) in duplicatedErrors" :key="rowNumber + 'name'">
        <v-row class="row">
          <v-col cols="2" class="grey-out border pa-0">
            <p class="grid-content__text text-center ma-4">{{ rowNumber + 1 }}組目</p>
          </v-col>
          <v-col cols="2" class="border pa-0">
            <p class="grid-content__text text-center ma-4">保護者名</p>
            <v-divider></v-divider>
            <p class="grid-content__text text-center ma-4">子ども名</p>
          </v-col>
          <!-- parentsの中で一番重複が多いrowを取得して、そのlengthに応じてv-colを作成する -->
          <template v-for="columnNumber of maxColumnsCount" :key="columnNumber">
            <v-col cols="4" class="border pa-0 border-eight" style="margin-left: -1px">
              <p class="grid-content__text text-left ma-4">
                {{ getParentName(error[columnNumber - 1]) }}
              </p>
              <v-divider></v-divider>
              <p class="grid-content__text text-left ma-4">
                {{ getChildName(rowNumber, columnNumber) }}
              </p>
            </v-col>
          </template>
        </v-row>
      </template>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { InputConfirmationError, EditableParent } from 'pages/parents_page/model'
export default defineComponent({
  props: {
    duplicatedErrors: Array as PropType<InputConfirmationError['errors']['warn'][number][][]>,
    maxColumnsCount: Number,
    parents: Array as PropType<EditableParent[]>,
  },
  setup(props) {
    const getParentName = (warn: InputConfirmationError['errors']['warn'][number]) =>
      warn && warn.resource_name === 'school_parent'
        ? `${warn.error_detail.last_name} ${warn.error_detail.first_name}さん`
        : '-'
    const getChildName = (rowNumber: number, columnNumber: number) => {
      if (!props.parents) return '-'
      const parent = props.parents[rowNumber]

      if (!parent.schoolParentChildren.length || !parent.schoolParentChildren[columnNumber - 1])
        return '-'

      return parent ? `${parent.schoolParentChildren[columnNumber - 1].firstName}さん` : '-'
    }
    return {
      getParentName,
      getChildName,
    }
  },
})
</script>

<style scoped>
.scrollable-horizontal {
  overflow-x: auto;
  overflow-y: hidden;
}
.row {
  margin: -1px 0 0 0;
  flex-wrap: initial;
}
.grey-out {
  background: #f6f6f5;
  pointer-events: none;
}
.border {
  border: 1px solid #e0e0e0;
  border-right: 0px;
}
.border-right {
  border-right: 1px solid #e0e0e0;
}
.grid-content__text {
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  font-weight: 500;
}
</style>
