<template>
  <div>
    <p class="text-error text-h6 font-weight-bold mb-2">
      子どもを削除すると、同時に保護者も削除されます。
    </p>
  </div>
  <div class="text-body-1">
    <p class="mb-2">
      削除した子どもは、当月（{{
        thisMonth
      }}月）から請求画面に表示されなくなります。<br />当月以降お支払い済みの請求がある場合は、該当月に表示されます。
    </p>
  </div>
  <div class="message-area mb-6">
    <p class="font-weight-bold text-body-1">削除対象</p>
    <p class="text-body-1">保護者：{{ parent.lastName }} {{ parent.firstName }}さん</p>
    <p class="text-body-1">
      子ども：{{ parent.lastName }} {{ child.firstName }}さん({{ className }})
    </p>
  </div>
  <p class="text-body-1">この操作は取り消せません。ご注意ください。</p>
</template>
<script setup lang="ts">
import { EditableChild, EditableParent } from 'pages/parents_page/model'
import { ParentsEditPageStore } from 'pages/parents_page/parent_edit/parent_edit_page_store'
import { getMonth } from 'shared/utils/date'

const { parent, child } = defineProps<{
  parent: EditableParent
  child: EditableChild
}>()

const thisMonth = getMonth(new Date())
const className = child.schoolClassId
  ? (ParentsEditPageStore.schoolClasses.find(
      (schoolClass) => schoolClass.id === Number(child.schoolClassId)
    )?.name ?? '')
  : '未選択'
</script>
<style scoped lang="scss">
.message-area {
  margin-top: 20px;
  background: #f6f6f6;
  padding: 20px;
  border-radius: 4px;
}
</style>
