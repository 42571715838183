import { EditableParent, Parent } from 'pages/parents_page/model'
import { reactive } from 'vue'
import { SchoolClass } from 'http/modules/class'

type ParentAddsPageState = {
  parents: EditableParent[]
  schoolClasses: SchoolClass[]
  createdParents: Parent[]
  childId: number
  parentId: number
  isDisabled: boolean
  conflictedParentsOrChildrenNames: EditableParent[]
}

const state = reactive<ParentAddsPageState>({
  parents: [],
  schoolClasses: [],
  createdParents: [],
  childId: 1,
  parentId: 1,
  isDisabled: false,
  conflictedParentsOrChildrenNames: [],
})

export class ParentsAddPageStore {
  static get parents(): EditableParent[] {
    return state.parents
  }

  static updateParents(parents: EditableParent[]): void {
    state.parents = parents
  }

  static get schoolClasses(): SchoolClass[] {
    return state.schoolClasses
  }

  static updateSchoolClasses(schoolClasses: SchoolClass[]): void {
    state.schoolClasses = schoolClasses
  }

  static generateParentId(): number {
    const currentParentId = state.parentId
    state.parentId += 1
    return currentParentId
  }

  static generateChildId(): number {
    const currentChildId = state.childId
    state.childId += 1
    return currentChildId
  }

  static get createdParents(): Parent[] {
    return state.createdParents
  }

  static updateCreatedParents(parents: Parent[]): void {
    state.createdParents = parents
  }

  static get isDisabled(): boolean {
    return state.isDisabled
  }

  static updateIsDisabled(disable: boolean) {
    state.isDisabled = disable
  }

  static get conflictedParentsOrChildrenNames(): EditableParent[] {
    return state.conflictedParentsOrChildrenNames
  }

  static updateConflictedParentsOrChildrenNames(parents: EditableParent[]): void {
    state.conflictedParentsOrChildrenNames = parents
  }
}
