import dayjs from 'dayjs'

/**
 * .vueのtemplateで使うfilter的役割の関数を集めたサービス。
 * filterはVue3で廃止になるので、その流れに合わせてfilterではなく関数で定義する。
 */
export class FilterService {
  /**
   * 12345 -> '12,345'
   */
  static commaSeparate(num: number): string {
    return num.toLocaleString('ja-JP')
  }

  /**
   * @param dateTime ISO 8601形式(https://day.js.org/docs/en/parse/string)
   * @param format 返り値の形式。任意。
   * @returns formatで指定された形式、あるいはYYYY/MM/DD HH:mm 形式の時刻
   */
  static formatDateTime(dateTime: string, format?: string): string {
    return dayjs(dateTime).format(format || 'YYYY/MM/DD HH:mm')
  }
}
