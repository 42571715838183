import { reactive } from 'vue'
import {
  InvoiceFormModalState,
  InvoicesModalState,
  Parent,
  SchoolClass,
  SearchCondition,
} from './models'
import { StoreHelperService } from 'shared/services/store_helper_service'
import { School, InvoiceItemNameCandidate } from 'shared/models'

type TopPageState = {
  /** トップページの処理対象年月の内、年のみを文字列4桁で持つ。例:"2021" */
  targetYear: string
  /** トップページの処理対象年月の内、月のみを文字列2桁で持つ。"01"~"12"まで。*/
  targetMonth: string
  /** 請求対象となっている保護者の一覧 */
  schoolParents: Parent[]
  /**
   * schoolParentsと同じデータを、データ取得を高速化するためparentのidをキーにしたマップで保持。
   * schoolParentsMapに対するupdateメソッドは作らず、updateSchoolParentsの中でMapの更新も行う。
   */
  schoolParentsMap: Map<number, Parent>
  /**
   * schoolParentsと同じデータを、データ取得を高速化するためinvoiceのidをキーにしたマップで保持。
   * schoolParentsByInvoiceIdMapに対するupdateメソッドは作らず、updateSchoolParentsの中でMapの更新も行う。
   */
  schoolParentsByInvoiceIdMap: Map<number, Parent>
  /** 請求書を保存中のparent_idのリスト */
  invoiceSavingParentIds: number[]
  /** 請求中のparent_idのリスト */
  invoiceChargingParentIds: number[]
  /** 処理対象年月に存在するクラスの一覧 */
  schoolClasses: SchoolClass[]
  /** 親の検索条件を持つオブジェクト */
  searchCondition: SearchCondition
  /** 検索のダイアログの開閉状況 0:開 undefined:閉 */
  openPanelIndex: 0 | undefined
  /** 対象のschool情報 */
  school: School
  /** 一括再請求中かどうか */
  isBulkReminding: boolean
  /** Excel取込み用のダイアログの表示フラグ */
  isShowExcelImportDialog: boolean
  /** 処理対象年月がロック状態かチェック */
  isLockedMonth: boolean
  importedExcel: File | undefined
  invoicesModalState: InvoicesModalState
  invoiceFormModalState: InvoiceFormModalState
  invoiceItemNameCandidates: InvoiceItemNameCandidate[]
  isShowNewTopPageFormat: boolean
}

/**
 * トップページの状態。
 */
const state = reactive<TopPageState>({
  targetYear: '',
  targetMonth: '',
  schoolParents: [],
  schoolParentsMap: new Map(),
  schoolParentsByInvoiceIdMap: new Map(),
  invoiceSavingParentIds: [],
  invoiceChargingParentIds: [],
  schoolClasses: [],
  searchCondition: {
    selectedClassIds: [],
    paymentStatus: 'all',
    lineStatus: 'all',
    name: '',
  },
  openPanelIndex: 0,
  /**
   * created_atはDate型であればどんな値でも問題ないがテストのしやすさを考慮して固定値にしている。
   * また、未来の日付をデフォルト値とすることでcreated_atにDBからロードされた正しい値が入るまで、
   * 対象年月を遡る "<" ボタンがdisableとなるようにしています
   */
  school: {
    codmon_id: undefined,
    created_at: new Date('2100-1-1'),
    locked_target_year_and_months: [],
  },
  isBulkReminding: false,
  isShowExcelImportDialog: false,
  isLockedMonth: false,
  importedExcel: undefined,
  invoicesModalState: {
    isShow: false,
    parentId: undefined,
  },
  invoiceFormModalState: {
    isShow: false,
    parentId: undefined,
    status: 'before_register',
  },
  invoiceItemNameCandidates: [],
  isShowNewTopPageFormat: false,
})

/**
 * トップページの状態を操作するメソッドを持つStore。
 */
export class TopPageStore {
  static get targetYear(): string {
    return state.targetYear
  }

  /**
   * @param targetYear 4桁の文字列。例:"2021"
   */
  static updateTargetYear(targetYear: string): void {
    state.targetYear = targetYear
  }

  static get targetMonth(): string {
    return state.targetMonth
  }

  /**
   * @param targetMonth 2桁の文字列。"01"~"12"まで。
   */
  static updateTargetMonth(targetMonth: string): void {
    state.targetMonth = targetMonth
  }

  static get schoolParents(): Parent[] {
    return state.schoolParents
  }

  static updateSchoolParents(schoolParents: Parent[]): void {
    StoreHelperService.updateArrayState(state.schoolParents, schoolParents)
    const schoolParentsMap = new Map()
    const schoolParentsByInvoiceIdMap = new Map()
    schoolParents.forEach((parent) => {
      schoolParentsMap.set(parent.id, parent)
      parent.invoices.forEach((invoice) => schoolParentsByInvoiceIdMap.set(invoice.id, parent))
    })
    state.schoolParentsMap = schoolParentsMap
    state.schoolParentsByInvoiceIdMap = schoolParentsByInvoiceIdMap
  }

  static get schoolParentsMap(): Map<number, Parent> {
    return state.schoolParentsMap
  }

  static get schoolParentsByInvoiceIdMap(): Map<number, Parent> {
    return state.schoolParentsByInvoiceIdMap
  }

  static get invoiceSavingParentIds(): number[] {
    return state.invoiceSavingParentIds
  }

  static updateInvoiceSavingParentIds(invoiceSavingParentIds: number[]): void {
    StoreHelperService.updateArrayState(state.invoiceSavingParentIds, invoiceSavingParentIds)
  }

  static get invoiceChargingParentIds() {
    return state.invoiceChargingParentIds
  }

  static updateInvoiceChargingParentIds(invoiceChargingParentIds: number[]): void {
    StoreHelperService.updateArrayState(state.invoiceChargingParentIds, invoiceChargingParentIds)
  }

  static get schoolClasses(): SchoolClass[] {
    return state.schoolClasses
  }

  static updateSchoolClasses(schoolClasses: SchoolClass[]): void {
    StoreHelperService.updateArrayState(state.schoolClasses, schoolClasses)
  }

  static get searchCondition(): SearchCondition {
    return state.searchCondition
  }

  static updateSearchCondition(searchCondition: SearchCondition): void {
    state.searchCondition = searchCondition
  }

  static get school(): School {
    return state.school
  }

  static updateSchool(school: School): void {
    state.school = school
  }

  static get isBulkReminding(): boolean {
    return state.isBulkReminding
  }

  static updateIsBulkReminding(isBulkReminding: boolean): void {
    state.isBulkReminding = isBulkReminding
  }

  static get isShowExcelImportDialog(): boolean {
    return state.isShowExcelImportDialog
  }

  static updateIsShowExcelImportDialog(isShowExcelImportDialog: boolean): void {
    state.isShowExcelImportDialog = isShowExcelImportDialog
  }

  static get isLockedMonth(): boolean {
    return state.isLockedMonth
  }

  static updateIsLockedMonth(isLockedMonth: boolean): void {
    state.isLockedMonth = isLockedMonth
  }

  static get importedExcel(): File | undefined {
    return state.importedExcel
  }

  static updateImportedExcel(importedExcel: File | undefined): void {
    state.importedExcel = importedExcel
  }

  static get invoicesModalState(): InvoicesModalState {
    return state.invoicesModalState
  }

  static updateInvoicesModalState(invoicesModalState: InvoicesModalState): void {
    state.invoicesModalState = invoicesModalState
  }

  static get invoiceFormModalState(): InvoiceFormModalState {
    return state.invoiceFormModalState
  }

  static updateInvoiceFormModalState(invoiceFormModalState: InvoiceFormModalState): void {
    state.invoiceFormModalState = invoiceFormModalState
  }

  static get openPanelIndex(): 0 | undefined {
    return state.openPanelIndex
  }

  /** 0で開き、undefinedで閉じます */
  static updateOpenPanelIndex(openPanelIndex: 0 | undefined): void {
    state.openPanelIndex = openPanelIndex
  }

  static get invoiceItemNameCandidates(): InvoiceItemNameCandidate[] {
    return state.invoiceItemNameCandidates
  }

  static updateInvoiceItemNameCandidates(invoiceItemNameCandidates: InvoiceItemNameCandidate[]) {
    state.invoiceItemNameCandidates = invoiceItemNameCandidates
  }

  static get isShowNewTopPageFormat(): boolean {
    return state.isShowNewTopPageFormat
  }

  static updateIsShowNewTopPageFormat(isShowNewTopPageFormat: boolean): void {
    state.isShowNewTopPageFormat = isShowNewTopPageFormat
  }
}
