import { InvoicePrintPageStore } from '../invoice_print_page_store'
import { UnpaidInvoiceApi } from '../../../http/modules/unpaid_invoice'
import { RouteService } from 'shared/services/route_service'

/**
 * Invoiceに関するロジックを持ちます。
 */
export class InvoiceService {
  static async loadInvoice(invoiceIds: string[]): Promise<void> {
    if (invoiceIds.length === 0) {
      return
    }
    InvoicePrintPageStore.updateIsLoading(true)

    try {
      const invoices = (
        await UnpaidInvoiceApi.index(InvoiceService.getTargetFacilityId(), invoiceIds)
      ).data
      InvoicePrintPageStore.updateInvoices(invoices)
    } finally {
      InvoicePrintPageStore.updateIsLoading(false)
    }
  }

  static getTargetFacilityId(): number {
    return Number(RouteService.getPathParamByIndex(1))
  }
}
