<template>
  <SimpleLayout title="保護者一覧" is-expanded style="width: 90%; margin: auto">
    <template #headerNav>
      <FacilityHeader />
    </template>

    <template #headerContent>
      <div class="d-flex align-center">
        <div class="d-flex align-center">
          <LinkText :link-url="faqPageUrl" is-open-new-tab :has-underline="false">
            <v-icon color="primary">mdi-help-circle-outline</v-icon>
            よくある質問を確認する
          </LinkText>
        </div>
      </div>
    </template>

    <template #main>
      <v-sheet color="transparent" width="100%" class="mx-auto mt-6">
        <v-row class="ma-0 mb-6">
          <v-col class="pa-0 bg-white">
            <SearchForm event-name="close_search_box_button_in_parent_list">
              <SearchBox
                @on-click-clear-conditions="onClickClearConditions"
                @refresh-page="refreshPage"
                @handle-ga-event="handleGaEvent"
              />
            </SearchForm>
          </v-col>
        </v-row>
        <v-row class="mx-0 mb-6 justify-end">
          <BulkDeleteOperationArea
            v-if="bulkOperationMode === 'delete'"
            v-model:all-parents-selected-in-page="allParentsSelectedInPage"
            v-model:is-selected-all="isSelectedAll"
            :selected-parent-ids="selectedParentIds"
            :selected-child-ids="selectedChildIds"
            :parent-total="parentTotal"
            :parents="parents"
            @on-click-bulk-operation-mode="onClickBulkOperationMode"
            @on-click-delete-selected="onClickDeleteSelected"
            @on-click-l-i-n-e-selected="onClickLINESelected"
            @handle-ga-event="handleGaEvent"
          />
          <BulkLineOperationArea
            v-else-if="bulkOperationMode === 'line'"
            v-model:all-parents-selected-in-page="allParentsSelectedInPage"
            v-model:is-selected-all="isSelectedAll"
            :selected-parent-ids="selectedParentIds"
            :parent-total="parentTotal"
            :parents="parents"
            @on-click-bulk-operation-mode="onClickBulkOperationMode"
            @on-click-l-i-n-e-selected="onClickLINESelected"
            @handle-ga-event="handleGaEvent"
          />
          <ActionButtonArea
            v-else
            :parents="parents"
            :is-loading="isLoading"
            :facility-id="facilityId"
            :parent-total="parentTotal"
            :start-index="startIndex"
            :end-index="endIndex"
            :is-searching="isSearching"
            @on-click-bulk-operation-mode="onClickBulkOperationMode"
            @on-click-download-l-i-n-e-csv="onClickDownloadLINECsv"
            @on-click-codmon-connection="onClickCodmonConnection"
            @handle-ga-event="handleGaEvent"
          />
        </v-row>
        <div class="d-flex align-center justify-space-between pb-10">
          <v-row v-if="isSearching">
            <v-col cols="12">
              <v-skeleton-loader type="table-tbody"></v-skeleton-loader>
            </v-col>
          </v-row>
          <template v-else>
            <ParentSummaryList
              :parents="parents"
              :is-loading="isLoading"
              :parent-total="parentTotal"
              :start-index="startIndex"
              :end-index="endIndex"
              :bulk-operation-mode="bulkOperationMode"
              :facility-id="facilityId"
              :is-selected-all="isSelectedAll"
              @on-page-change="onPageChange"
              @on-change-parent-selected="onChangeParentSelected"
              @on-change-child-selected="onChangeChildSelected"
              @on-click-disconnect-l-i-n-e="onClickDisconnectLINE"
              @on-click-disconnect-codmon="onClickDisconnectCodmon"
              @on-click-connect-codmon="onClickConnectCodmon"
              @handle-ga-event="handleGaEvent"
            />
          </template>
        </div>
      </v-sheet>
      <ScrollableDialog :is-show-modal="hasResultCodmonConnection" />
      <Dialog v-if="!hasResultCodmonConnection" />
    </template>
  </SimpleLayout>
</template>

<script lang="ts">
import { ComputedRef, WritableComputedRef, computed, defineComponent, onMounted } from 'vue'
import LinkText from 'shared/components/LinkText.vue'
import SimpleLayout from 'shared/templates/SimpleLayout.vue'
import FacilityHeader from 'shared/components/header/FacilityHeader.vue'
import ActionButtonArea from 'pages/parents_page/areas/ActionButtonArea.vue'
import BulkDeleteOperationArea from 'pages/parents_page/areas/BulkDeleteOperationArea.vue'
import BulkLineOperationArea from 'pages/parents_page/areas/BulkLineOperationArea.vue'
import ParentSummaryList from 'pages/parents_page/areas/parent_summary_list/ParentSummaryList.vue'
import SearchForm from 'shared/components/search_form/SearchForm.vue'
import SearchBox from 'pages/parents_page/components/SearchBox.vue'
import { ParentsService } from 'pages/parents_page/services/parents_service'
import { Parent, Child, ParentTotal, ChildId, BulkOperationMode } from 'pages/parents_page/model'
import { ParentsPageStore } from 'pages/parents_page/parents_page_store'
import { PaginationStore } from 'shared/components/pagination/pagination_store'
import { BulkOperationService } from 'pages/parents_page/services/bulk_operation_service'
import Dialog from 'shared/components/Dialog.vue'
import { ModalService } from 'pages/parents_page/services/modal_service'
import { LineService } from 'pages/parents_page/services/line_service'
import { EventTracker } from 'shared/utils/_event_tracking'
import { CodmonService } from 'pages/parents_page/services/codmon_service'
import ScrollableDialog from 'shared/components/ScrollableDialog.vue'
import ExternalLink from 'shared/consts/external_link'
import { ParentUrlService } from 'pages/parents_page/services/parent_url_service'
import { ClearSearchConditionsService } from 'pages/parents_page/services/clear_search_conditions_service'
import { MetaService } from 'shared/services/meta_service'

export default defineComponent({
  components: {
    SearchBox,
    SimpleLayout,
    LinkText,
    FacilityHeader,
    ActionButtonArea,
    ParentSummaryList,
    SearchForm,
    BulkDeleteOperationArea,
    BulkLineOperationArea,
    Dialog,
    ScrollableDialog,
  },
  // eslint-disable-next-line max-lines-per-function
  setup() {
    MetaService.addTitle({ mode: 'facility', page: 'parents' })
    ParentsService.initialize()

    onMounted(ParentsService.openSuccessSnackbar)

    const perPage = 50
    const faqPageUrl = ExternalLink.openpageFaqForParentList
    const parents: ComputedRef<Parent[]> = computed(() => ParentsPageStore.parentSummaries)
    const isLoading: ComputedRef<boolean> = computed(() => ParentsPageStore.isFullScreenLoading)
    const facilityId: ComputedRef<number> = computed(() => ParentsService.getTargetFacilityId())
    const parentTotal: ComputedRef<ParentTotal> = computed(() => ParentsPageStore.parentTotal)
    const currentPage: WritableComputedRef<number> = computed({
      get: () => PaginationStore.currentPage,
      set: (page: number) => {
        PaginationStore.updateCurrentPage(page)
      },
    })
    const startIndex: ComputedRef<number> = computed(() => (currentPage.value - 1) * perPage + 1)
    const endIndex: ComputedRef<number> = computed(() =>
      Math.min(startIndex.value + perPage - 1, parentTotal.value.total.count.parents)
    )
    const bulkOperationMode: ComputedRef<BulkOperationMode> = computed(
      () => ParentsPageStore.bulkOperationMode
    )
    const allParentsSelectedInPage = computed({
      get: () =>
        BulkOperationService.isAllParentsSelectedInPage(parents.value, bulkOperationMode.value),
      set: (value) => {
        if (!value) {
          BulkOperationService.resetParentsSelected(bulkOperationMode.value === 'delete')
        } else {
          BulkOperationService.selectAllParentsInPage(parents.value, bulkOperationMode.value)
        }
      },
    })
    // NOTE: 検索条件に一致する全てが選択されているかどうか。選択可能な全てのチェックボックスにチェックが入る
    const isSelectedAll: WritableComputedRef<boolean> = computed({
      get: () => ParentsPageStore.isSelectedAll,
      set: (value) => ParentsPageStore.updateIsSelectedAll(value),
    })
    const selectedParentIds: ComputedRef<Parent['id'][]> = computed(
      () => ParentsPageStore.selectedParentIds
    )
    const selectedChildIds: ComputedRef<ChildId[]> = computed(
      () => ParentsPageStore.selectedChildIds
    )
    const isSearching: ComputedRef<boolean> = computed(() => ParentsPageStore.isSearching)
    const hasResultCodmonConnection: ComputedRef<boolean> = computed(
      () => ParentsPageStore.hasResultCodmonConnection
    )

    const onChangeParentSelected = (selectedParentIds: number[], parent: Parent) => {
      BulkOperationService.parent(selectedParentIds, parent)
    }

    const onChangeChildSelected = (selectedChildIds: ChildId[], parent: Parent) => {
      BulkOperationService.child(selectedChildIds, parent)
    }

    const onPageChange = (page: number) => {
      ParentsPageStore.updateSearchQuery({
        ...ParentsPageStore.searchQuery,
        page: String(page),
      })
      ParentUrlService.overwriteQueryParams()
      ParentsService.search({ ...ParentsPageStore.searchQuery })
      BulkOperationService.resetParentsSelected()
      ParentsPageStore.updateIsSelectedAll(false)
      ParentsPageStore.updateBulkOperationMode(undefined)
    }

    const onClickBulkOperationMode = (bulkOperationMode: BulkOperationMode) => {
      ParentsPageStore.updateBulkOperationMode(bulkOperationMode)
      ParentsPageStore.updateSelectedParentIds([])
      ParentsPageStore.updateSelectedChildIds([])
    }

    const onClickDeleteSelected = () => {
      ModalService.deleteConfirmation(
        allParentsSelectedInPage.value,
        isSelectedAll.value,
        ParentsPageStore.searchQuery
      )
    }

    const onClickLINESelected = () => {
      LineService.printing(facilityId.value, selectedParentIds.value, ParentsPageStore.searchQuery)
    }

    const onClickDownloadLINECsv = () => {
      LineService.download()
      EventTracker.trackEvent('click_btn_download_line_connected', {})
    }

    // NOTE: 一括コドモン連携
    const onClickCodmonConnection = () => {
      CodmonService.connect(facilityId.value)
    }

    // NOTE: 個別コドモン連携
    const onClickConnectCodmon = async (parent: Parent, child: Child) => {
      const { id: parentId, last_name: parentLastName, first_name: parentFirstName } = parent
      const { id: childId, first_name: childFirstName } = child
      await ParentsService.connectCodmon(
        parentId,
        childId,
        parentLastName,
        parentFirstName,
        childFirstName,
        () => {
          ParentsService.loadParent(parentId)
        }
      )
    }

    const onClickDisconnectCodmon = (parent: Parent, child: Child) => {
      const { id: parentId, last_name: lastName } = parent
      const { id: childId, first_name: childFirstName } = child
      ParentsService.disconnectCodmon(parentId, childId, lastName, childFirstName, () => {
        ParentsService.loadParent(parentId)
      })
    }

    const onClickDisconnectLINE = (parent: Parent) => {
      const { id, first_name, last_name } = parent
      ParentsService.disconnectLine(id, first_name, last_name, () => {
        ParentsService.loadParent(id)
      })
    }

    const onClickClearConditions = () => {
      ClearSearchConditionsService.clearConditions()
    }

    // 検索条件が変更された時、その条件でAPIを叩いて結果を表示する
    // 検索された条件はフロント側のURLにも反映する
    const refreshPage = () => {
      BulkOperationService.resetParentsSelected()
      ParentsPageStore.updateBulkOperationMode(undefined)
      ParentsPageStore.updateIsSelectedAll(false)
      ParentsPageStore.updateSearchQuery({
        ...ParentsPageStore.searchQuery,
        page: '1',
        per_page: '50',
      })
      PaginationStore.updateCurrentPage(1)
      ParentUrlService.overwriteQueryParams()
      ParentsService.search(ParentsPageStore.searchQuery)
    }

    const handleGaEvent = (eventName: string) => EventTracker.trackEvent(eventName, {})

    return {
      faqPageUrl,
      parents,
      isLoading,
      facilityId,
      parentTotal,
      startIndex,
      endIndex,
      bulkOperationMode,
      allParentsSelectedInPage,
      isSelectedAll,
      selectedParentIds,
      selectedChildIds,
      isSearching,
      hasResultCodmonConnection,
      onChangeParentSelected,
      onChangeChildSelected,
      onPageChange,
      onClickBulkOperationMode,
      onClickDeleteSelected,
      onClickLINESelected,
      onClickDownloadLINECsv,
      onClickCodmonConnection,
      onClickConnectCodmon,
      onClickDisconnectLINE,
      onClickDisconnectCodmon,
      onClickClearConditions,
      refreshPage,
      handleGaEvent,
    }
  },
})
</script>
<style scoped lang="scss">
.fs-16 {
  font-size: 16px;
}
.outlined-button {
  line-height: 24px;
  background-color: #fff;
}
.grey-font {
  color: #787878;
}
</style>
