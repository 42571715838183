import { HTTP } from '../common'
import { AxiosError, AxiosResponse } from 'axios'

export type SchoolAccount = {
  email: string
}

export type InvoiceLock = {
  created_at: string
  target_year_and_month: string
}

export type School = {
  codmon_id: number | null
  /**
   * 2021-03-26T15:09:39.000+09:00
   * のような形式(ISO 8601)
   */
  created_at: string

  company_name: string
  corporation_name: string
  school_name: string
  phone_number: string

  postal_code: string
  city: string
  building: string
  responsible_person_name: string
  bank_name: string
  bank_code: string
  bank_branch_name: string
  bank_branch_code: string
  bank_branch_type: string
  bank_account_number: string
  bank_account_holder_name: string

  allow_ips?: string
  prefecture_id: number | undefined
  prefecture_name: string

  locked_target_year_and_months: InvoiceLock[]

  can_use_enpay_wallet: boolean
  parent_convenience_store_fee_apply_status: {
    current_month: {
      applied_at: string
      amount: number
    } | null
    next_month: {
      applied_at: string
      amount: number
    } | null
  }
}

export type AvailableFacility = {
  id: number
  name: string
}

export type IndexResponse = {
  facilities: AvailableFacility[]
}

export type UpdateResponse = {
  messages: string[]
}

export class FacilityApi {
  static show(facilityId: number): Promise<AxiosResponse<School>> {
    return HTTP.get(`/facilities/${facilityId}`)
  }

  static update(facilityId: number, school: School): Promise<AxiosResponse<UpdateResponse>> {
    return HTTP.patch(`/facilities/${facilityId}`, school)
  }

  static index(): Promise<AxiosResponse<IndexResponse>> {
    return HTTP.get(`/facilities`)
  }
}

export const isAxiosError = <T extends UpdateResponse>(error: unknown): error is AxiosError<T> =>
  !!(error as AxiosError<T>).isAxiosError
