<template>
  <v-form ref="corpInfoPanel1" v-model="isValid.corpInfoPanel1">
    <div class="ml-3">
      <p>事業形態</p>
      <v-radio-group v-model="formValue.businessType">
        <v-radio color="primary" label="法人" value="法人"></v-radio>
        <v-radio color="primary" label="個人・その他団体" value="個人"></v-radio>
      </v-radio-group>
      <v-row align="center">
        <v-col cols="12" sm="6" class="d-flex">
          <v-select
            v-model="formValue.typeOfIndustry"
            :items="items"
            label="業種"
            variant="underlined"
          ></v-select>
        </v-col>
      </v-row>
      <v-text-field
        v-model="formValue.businessDescription"
        label="事業内容"
        :rules="businessDescriptionRules"
      ></v-text-field>
      <div v-show="false">{{ isInitial.corpInfoPanel1 }}</div>
    </div>
  </v-form>
</template>

<script>
import { CorporationApplyPageStore } from '../corporation_apply_page_store'
import { CorporationValidatorsService } from '../services/corporation_validators_service'

export default {
  data: () => ({
    items: [
      '農業，林業',
      '漁業',
      '鉱業，採石業，砂利採取業',
      '建設業',
      '製造業',
      '電気・ガス・熱供給・水道業',
      '情報通信業',
      '運輸業，郵便業',
      '卸売業，小売業',
      '金融業，保険業',
      '不動産業，物品賃貸業',
      '学術研究，専門・技術サービス業',
      '宿泊業，飲食サービス業',
      '生活関連サービス業，娯楽業',
      '教育，学習支援業',
      '医療，福祉',
      '複合サービス事業',
      'サービス業（他に分類されないもの）',
      '公務（他に分類されるものを除く）',
      '分類不能の産業',
    ],
  }),
  computed: {
    formValue() {
      return CorporationApplyPageStore.formValue
    },
    isValid() {
      return CorporationApplyPageStore.validState
    },
    isInitial() {
      if (!CorporationApplyPageStore.initializedState.corpInfoPanel1) {
        this.$refs.corpInfoPanel1.validate()
      }
      return CorporationApplyPageStore.initializedState
    },
    businessDescriptionRules() {
      return CorporationValidatorsService.businessDescriptionRules('事業内容')
    },
  },
}
</script>
