<template>
  <div class="ml-3">
    <p>入力した情報をご確認ください</p>
    <p class="mb-7 mt-13 text-h6">会社情報</p>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">メールアドレス</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">{{ formValue.email }}</p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">販売方法は、特商法・前払いに該当しますか</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">
        {{ formValue.doSpecialBusiness ? '該当する' : '該当しない' }}
      </p>
    </div>
    <div v-if="formValue.doSpecialBusiness" class="mb-5">
      <div class="mb-1 text-subtitle-2">訪問販売を行っている</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">
        {{ formValue.doDoorToDoorSales ? 'はい' : 'いいえ' }}
      </p>
      <div class="mb-1 text-subtitle-2">特定継続的役務提供を行っている</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">
        {{ formValue.doSpecifiedContinuousServiceProvision ? 'はい' : 'いいえ' }}
      </p>
      <div class="mb-1 text-subtitle-2">電話勧誘販売を行っている</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">
        {{ formValue.doTelemarketingRelatedSales ? 'はい' : 'いいえ' }}
      </p>
      <div class="mb-1 text-subtitle-2">連鎖販売を行っている</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">
        {{ formValue.doChainReactionSales ? 'はい' : 'いいえ' }}
      </p>
      <div class="mb-1 text-subtitle-2">業務提供誘引販売を行っている</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">
        {{ formValue.doFreelanceSales ? 'はい' : 'いいえ' }}
      </p>
      <div class="mb-1 text-subtitle-2">前払い式取引の提供を行っている</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">
        {{ formValue.providedPrepaidTransactions ? 'はい' : 'いいえ' }}
      </p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">
        <p>以下のいずれかに該当しますか</p>
        <p>
          特定商取引法に関する法律に定められた禁止行為に該当する行為を行っている、また直近５年間に同法による処分を受けたことがある
        </p>
        <p>
          消費者契約法において消費者に取消権が発生する原因となる行為を行っている、また直近５年間に同法違反あるいは同法の適用を理由とする敗訴判決を受けたことがある
        </p>
      </div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">
        {{ formValue.isApplicableSpecialCommercialCodeMeasures ? '該当する' : '該当しない' }}
      </p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">フランチャイズ展開を行っていますか</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">
        {{ formValue.developedFranchise ? 'はい' : 'いいえ' }}
      </p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">事業形態</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">{{ formValue.businessType }}</p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">業種</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">{{ formValue.typeOfIndustry }}</p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">事業内容</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">{{ formValue.businessDescription }}</p>
    </div>
    <div v-if="formValue.businessType === '法人'" class="mb-5">
      <div class="mb-1 text-subtitle-2">法人番号</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">{{ formValue.corporationNumber }}</p>
    </div>
    <div>
      <div class="mb-1 text-subtitle-2">会社名</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">{{ formValue.corporationName }}</p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">会社名（フリガナ）</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">{{ formValue.corporationNameKana }}</p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">設立年月日</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">{{ establishedDateForDisplay }}</p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">資本金</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">
        {{ commaSeparate(formValue.capitalStock) }}円
      </p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">従業員数</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">
        {{ commaSeparate(formValue.employeesNumber) }}人
      </p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">年商</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">
        {{ commaSeparate(formValue.annualTurnover) }}円
      </p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">ホームページURL</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">{{ formValue.homePageUrl }}</p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">郵便番号</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">{{ formValue.postalCode }}</p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">都道府県</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">{{ formValue.prefectureName }}</p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">都道府県（フリガナ）</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">{{ formValue.prefectureNameKana }}</p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">市区町村</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">{{ formValue.cityName }}</p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">市区町村（フリガナ）</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">{{ formValue.cityNameKana }}</p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">町名</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">{{ formValue.townName }}</p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">町名（フリガナ）</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">{{ formValue.townNameKana }}</p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">番地</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">{{ formValue.streetName }}</p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">建物名</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">{{ formValue.buildingName }}</p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">建物名（フリガナ）</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">{{ formValue.buildingNameKana }}</p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">電話番号</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">{{ formValue.phoneNumber }}</p>
    </div>
    <v-spacer></v-spacer>
    <p class="mb-7 mt-13 text-h6">代表者情報</p>
    <div class="mb-5">
      <div class="mb-5">
        <div class="mb-1 text-subtitle-2">姓</div>
        <p class="confirm-content mb-4 pd-1 text-subtitle-1">
          {{ formValue.representativeLastName }}
        </p>
      </div>
      <div class="mb-5">
        <div class="mb-1 text-subtitle-2">名</div>
        <p class="confirm-content mb-4 pd-1 text-subtitle-1">
          {{ formValue.representativeFirstName }}
        </p>
      </div>
      <div class="mb-5">
        <div class="mb-1 text-subtitle-2">姓（フリガナ）</div>
        <p class="confirm-content mb-4 pd-1 text-subtitle-1">
          {{ formValue.representativeLastNameKana }}
        </p>
      </div>
      <div class="mb-5">
        <div class="mb-1 text-subtitle-2">名（フリガナ）</div>
        <p class="confirm-content mb-4 pd-1 text-subtitle-1">
          {{ formValue.representativeFirstNameKana }}
        </p>
      </div>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">生年月日</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">
        {{ representativeBirthdateForDisplay }}
      </p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">郵便番号</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">
        {{ formValue.representativePostalCode }}
      </p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">都道府県</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">
        {{ formValue.representativePrefectureName }}
      </p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">都道府県（フリガナ）</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">
        {{ formValue.representativePrefectureNameKana }}
      </p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">市区町村</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">
        {{ formValue.representativeCityName }}
      </p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">市区町村（フリガナ）</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">
        {{ formValue.representativeCityNameKana }}
      </p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">町名</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">
        {{ formValue.representativeTownName }}
      </p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">町名（フリガナ）</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">
        {{ formValue.representativeTownNameKana }}
      </p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">番地</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">
        {{ formValue.representativeStreetName }}
      </p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">建物名</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">
        {{ formValue.representativeBuildingName }}
      </p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">建物名（フリガナ）</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">
        {{ formValue.representativeBuildingNameKana }}
      </p>
    </div>
    <div class="mb-5">
      <div class="mb-1 text-subtitle-2">電話番号</div>
      <p class="confirm-content mb-4 pd-1 text-subtitle-1">
        {{ formValue.representativePhoneNumber }}
      </p>
    </div>
  </div>
</template>

<script>
import { CorporationApplyPageStore } from '../corporation_apply_page_store'
import { CorporationDateService } from '../services/corporation_date_service'
import { FilterService } from 'shared/services/filter_service'

export default {
  computed: {
    formValue() {
      return CorporationApplyPageStore.formValue
    },
    establishedDateForDisplay() {
      return CorporationDateService.getEstablishedDateForDisplay()
    },
    representativeBirthdateForDisplay() {
      return CorporationDateService.getRepresentativeBirthdateForDisplay()
    },
  },
  methods: {
    commaSeparate(num) {
      return FilterService.commaSeparate(num)
    },
  },
}
</script>

<style scoped>
.confirm-content {
  border-bottom: 1px solid #d8d8d8;
  width: 100%;
}
</style>
