<template>
  <div class="bg-white rounded-lg py-3 my-6">
    <div class="px-4 mb-2 text-center text-body-2">
      <span class="font-weight-medium">請求月</span>
    </div>
    <div class="d-flex justify-space-between align-center px-1 mb-2">
      <Button
        :disabled="!canMoveToPreviousMonth"
        size="large"
        variant="text"
        icon
        data-testid="move-prev-month"
        @click="changeToPreviousMonth"
      >
        <v-icon size="large">mdi-chevron-left</v-icon>
      </Button>
      <div class="text-center">
        <YearMonthDropdown :from="from" :to="to" @click-month="changeToSelectedYearMonth">
          <div :class="yearMonthDropdownPadding" class="d-flex align-center">
            <div>
              <span class="text-h4 font-weight-bold text-primary">{{ month }}</span
              ><span class="text-h5 font-weight-bold text-primary">月</span>
            </div>
            <v-icon class="mt-2">mdi-menu-down</v-icon>
          </div>
        </YearMonthDropdown>
      </div>
      <Button
        :disabled="!canMoveToNextMonth"
        size="large"
        variant="text"
        icon
        data-testid="move-next-month"
        @click="changeToNextMonth"
      >
        <v-icon size="large">mdi-chevron-right</v-icon>
      </Button>
    </div>
    <div class="px-4 text-center text-body-3 text-grey-darken-1">{{ year }}年</div>
    <div class="text-center">
      <Chip v-if="isLockedMonth" :text="'請求締め切り'" class="mr-2" />
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent } from 'vue'
import { TopPageStore } from '../top_page_store'
import { TargetMonthService } from '../services/target_month_service'
import { SchoolService } from '../services/school_service'
import YearMonthDropdown from '../../../shared/components/year_month_dropdown/YearMonthDropdown.vue'
import Chip from 'shared/components/Chip.vue'
import dayjs from 'dayjs'
import Button from 'shared/components/Button.vue'
import { RouteService } from 'shared/services/route_service'

export default defineComponent({
  components: { YearMonthDropdown, Chip, Button },
  // eslint-disable-next-line max-lines-per-function
  setup() {
    const year = computed(() => TopPageStore.targetYear)
    const month = computed(() => Number(TopPageStore.targetMonth))
    const from = computed(() =>
      dayjs(TopPageStore.school.created_at).subtract(1, 'month').startOf('month')
    )
    // 現在時刻の翌々月の請求画面まで表示できる
    const to = dayjs().tz('Asia/Tokyo').add(2, 'month').startOf('month')
    const yearMonthDropdownPadding = computed(() => (month.value > 9 ? 'pl-3' : 'pl-6'))
    // 施設作成日の1ヶ月前の請求画面まで表示できる
    const canMoveToPreviousMonth = computed(() => TargetMonthService.canMoveToPreviousMonth())
    // 現在時刻の翌々月の請求画面まで表示できる
    const canMoveToNextMonth = computed(() => TargetMonthService.canMoveToNextMonth())
    const facilityId = computed(() => RouteService.getPathParamByIndex(1))
    const changeToPreviousMonth = () => {
      TargetMonthService.changeToPreviousMonth()
      SchoolService.initialize()
      const url = `/facilities/${facilityId.value}/invoices?target_year_and_month=${
        year.value
      }-${String(month.value).padStart(2, '0')}`
      window.history.pushState({}, '', url)
    }
    const changeToSelectedYearMonth = (year: number, month: number) => {
      TargetMonthService.changeYearMonth(year, month)
      SchoolService.initialize()
      const url = `/facilities/${facilityId.value}/invoices?target_year_and_month=${year}-${String(
        month
      ).padStart(2, '0')}`
      window.history.pushState({}, '', url)
    }
    const changeToNextMonth = () => {
      TargetMonthService.changeToNextMonth()
      SchoolService.initialize()
      const url = `/facilities/${facilityId.value}/invoices?target_year_and_month=${
        year.value
      }-${String(month.value).padStart(2, '0')}`
      window.history.pushState({}, '', url)
    }

    const isLockedMonth = computed(() => TopPageStore.isLockedMonth)

    return {
      year,
      month,
      from,
      to,
      yearMonthDropdownPadding,
      canMoveToNextMonth,
      canMoveToPreviousMonth,
      changeToNextMonth,
      changeToSelectedYearMonth,
      changeToPreviousMonth,
      YearMonthDropdown,
      isLockedMonth,
    }
  },
})
</script>
<style lang="scss" scoped>
.lock-alert {
  padding: 2px 2px;
}
</style>
