import { InvoiceManagementPageStore } from '../invoice_management_page_store'
import { DialogService } from 'shared/services/dialog_service'
import { SnackbarService } from 'shared/services/snackbar_service'
import { CorporationFacilityInvoicesLockApi } from 'http/modules/corporation_facility_invoices_lock'
import { CorporationInvoicesLockApi } from 'http/modules/corporation_invoices_lock'
import UnlockInvoicesMessage from '../areas/UnlockInvoicesMessage.vue'
import LockInvoicesMessage from '../areas/LockInvoicesMessage.vue'
import BulkUnlockInvoicesMessage from '../areas/title_area/components/BulkUnlockInvoicesMessage.vue'
import BulkLockInvoicesMessage from '../areas/title_area/components/BulkLockInvoicesMessage.vue'
import BulkLockInvoicesResult from '../areas/title_area/components/BulkLockInvoicesResult.vue'
import { CorporationsFacilitySummaryApi } from 'http/modules/corporations_facility_summary'

export class InvoiceLockService {
  static async loadCorporationsFacilitySummary(): Promise<void> {
    const response = await CorporationsFacilitySummaryApi.summary(
      InvoiceManagementPageStore.summaryTargetYearMonth.format('YYYY-MM')
    )
    InvoiceManagementPageStore.updateFacilitySummary(response.data)
    InvoiceManagementPageStore.updateCanBulkUnlock(
      InvoiceLockService.canBulkUnlock({
        facilityTotal: response.data.total,
        lockedTotal: response.data.locked_total,
      })
    )
  }

  private static canBulkUnlock({
    facilityTotal,
    lockedTotal,
  }: {
    facilityTotal: number
    lockedTotal: number
  }): boolean {
    return facilityTotal === lockedTotal
  }

  static lockInvoicesByFacility(facilityId: number, facilityName: string) {
    DialogService.open({
      title: '施設の請求を締める',
      body: {
        component: {
          name: LockInvoicesMessage,
          bind: { facilityName },
        },
      },
      okButtonText: '請求を締める',
      onConfirm: () => {
        InvoiceLockService.handleConfirmLock(facilityId, facilityName)
      },
    })
  }

  private static async handleConfirmLock(facilityId: number, facilityName: string) {
    try {
      const response = await CorporationFacilityInvoicesLockApi.post(
        facilityId,
        InvoiceManagementPageStore.summaryTargetYearMonth.format('YYYY-MM')
      )
      const failureFacilityTotal = response.data.failure_facility_ids.length

      if (failureFacilityTotal !== 0) {
        const message = [
          '請求保存中の請求があったため、請求を締められませんでした。',
          `請求作業が完了しているか施設へご確認ください。`,
        ]
        InvoiceManagementPageStore.updateInvoiceSummaryByFacilityId({
          facility_id: facilityId,
          has_before_charge: true,
        })
        DialogService.openMessage({ title: '請求を締められませんでした', message })
      } else {
        SnackbarService.open(`${facilityName}の請求を締めました`, 'info')
        InvoiceManagementPageStore.updateInvoiceSummaryByFacilityId({
          facility_id: facilityId,
          is_locked: true,
          has_before_charge: false,
        })
      }
    } catch {
      SnackbarService.open(`${facilityName}の請求締めに失敗しました`, 'error')
    } finally {
      InvoiceLockService.loadCorporationsFacilitySummary()
    }
  }

  static unlockInvoicesByFacility(facilityId: number, facilityName: string) {
    DialogService.open({
      title: '請求締めを取り消す',
      body: {
        component: {
          name: UnlockInvoicesMessage,
          bind: { facilityName },
        },
      },
      okButtonText: '請求締めを取り消す',
      okButtonColor: 'error',
      onConfirm: () => {
        InvoiceLockService.handleConfirmUnlock(facilityId, facilityName)
      },
    })
  }

  private static async handleConfirmUnlock(facilityId: number, facilityName: string) {
    try {
      await CorporationFacilityInvoicesLockApi.delete(
        facilityId,
        InvoiceManagementPageStore.summaryTargetYearMonth.format('YYYY-MM')
      )
      SnackbarService.open(`${facilityName}の請求締めを取り消しました`, 'info')
      InvoiceManagementPageStore.updateInvoiceSummaryByFacilityId({
        facility_id: facilityId,
        is_locked: false,
      })
    } catch {
      SnackbarService.open(`${facilityName}の請求締めの取り消しに失敗しました`, 'error')
    } finally {
      InvoiceLockService.loadCorporationsFacilitySummary()
    }
  }

  static bulkLockInvoices() {
    const facilitySummary = InvoiceManagementPageStore.facilitySummary
    const notLockedTotal = facilitySummary.total - facilitySummary.locked_total
    const beforeChargeTotal = facilitySummary.not_completed.before_charge

    DialogService.open({
      title: 'すべての施設の請求を締める',
      body: {
        component: {
          name: BulkLockInvoicesMessage,
          bind: { notLockedTotal, beforeChargeTotal },
        },
      },
      modalWidth: 640,
      isDisableOkButton: notLockedTotal === beforeChargeTotal,
      okButtonText: '請求を締める',
      onConfirm: () => {
        InvoiceLockService.handleConfirmBulkLock(notLockedTotal)
      },
    })
  }

  private static async handleConfirmBulkLock(notLockedTotal: number) {
    try {
      const response = await CorporationInvoicesLockApi.post(
        InvoiceManagementPageStore.summaryTargetYearMonth.format('YYYY-MM')
      )
      const { success_facility_ids: successFacilityIds, failure_facility_ids: failureFacilityIds } =
        response.data
      const [successFacilityTotal, failureFacilityTotal] = [
        successFacilityIds.length,
        failureFacilityIds.length,
      ]
      if (failureFacilityTotal > 0) {
        DialogService.open({
          title: '一部の請求を締めました',
          body: {
            component: {
              name: BulkLockInvoicesResult,
              bind: { successFacilityTotal, failureFacilityTotal },
            },
          },
          modalWidth: 640,
          isShowCancel: false,
        })
        InvoiceManagementPageStore.updateInvoiceSummariesByFacilityIds(successFacilityIds, {
          is_locked: true,
          has_before_charge: false,
        })
        InvoiceManagementPageStore.updateInvoiceSummariesByFacilityIds(failureFacilityIds, {
          has_before_charge: true,
        })
      } else {
        SnackbarService.open(`${notLockedTotal}施設の請求を締めました`, 'info')
        // "全施設の請求締めに成功したときのみ"、storeのinvoiceSummariesを一括更新
        InvoiceManagementPageStore.updateAllInvoiceSummaries({
          is_locked: true,
          has_before_charge: false,
        })
      }
    } catch {
      SnackbarService.open(`${notLockedTotal}施設の請求締めに失敗しました`, 'error')
    } finally {
      InvoiceLockService.loadCorporationsFacilitySummary()
    }
  }

  static bulkUnlockInvoices() {
    const lockedTotal = InvoiceManagementPageStore.facilitySummary.locked_total
    DialogService.open({
      title: 'すべての施設の請求締めを取り消す',
      body: {
        component: {
          name: BulkUnlockInvoicesMessage,
          bind: { lockedTotal },
        },
      },
      modalWidth: 640,
      okButtonText: '請求締めを取り消す',
      okButtonColor: 'error',
      onConfirm: () => {
        InvoiceLockService.handleConfirmBulkUnlock(lockedTotal)
      },
    })
  }

  private static async handleConfirmBulkUnlock(lockedTotal: number) {
    try {
      await CorporationInvoicesLockApi.delete(
        InvoiceManagementPageStore.summaryTargetYearMonth.format('YYYY-MM')
      )
      SnackbarService.open(`${lockedTotal}施設の請求締めを取り消しました`, 'info')
      InvoiceManagementPageStore.updateAllInvoiceSummaries({
        is_locked: false,
      })
    } catch {
      SnackbarService.open(`${lockedTotal}施設の請求締めの取り消しに失敗しました`, 'error')
    } finally {
      InvoiceLockService.loadCorporationsFacilitySummary()
    }
  }
}
