import { computed, ref, SetupContext } from 'vue'

type ValidatableHTMLInputElement = HTMLInputElement & { valid: boolean }

export type CommaSeparatedFieldPropType = {
  modelValue: number | string
  rules?: Function[]
}

export default function useCommaSeparatedField(
  props: Readonly<CommaSeparatedFieldPropType>,
  emit: SetupContext['emit']
) {
  const isEdit = ref(false)
  const displayValue = ref('0')
  const actualValueField = ref<ValidatableHTMLInputElement | null>(null)

  const actualValue = computed({
    get: () => props.modelValue,
    set: (newValue) => {
      emit('update:modelValue', newValue)
    },
  })

  const validate = (): boolean => {
    const inputElement = actualValueField.value
    if (inputElement === null) return false
    return inputElement.valid
  }

  const displayCommaSeparatedString = (): void => {
    if (!validate()) return
    displayValue.value = actualValue.value.toLocaleString('ja-Jp')
    isEdit.value = false
  }

  return {
    isEdit,
    displayValue,
    actualValue,
    actualValueField,
    displayCommaSeparatedString,
  }
}
