<template>
  <Button :size="size" :width="width" @click="print()">印刷する</Button>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import Button from 'shared/components/Button.vue'

export default defineComponent({
  components: {
    Button,
  },
  props: {
    size: String,
    width: String,
  },
  setup() {
    const print = () => window.print()
    return {
      print,
    }
  },
})
</script>
