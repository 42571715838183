import { AxiosResponse } from 'axios'
import { HTTP } from 'http/common'

export type CorporationsFacilitySummary = {
  total: number
  locked_total: number
  not_completed: {
    before_charge: number
    unpaid: number
  }
}

/**
 * 任意の月を対象に、各請求作業状態ごとでの施設数を返します
 */
export class CorporationsFacilitySummaryApi {
  static summary(targetYearAndMonth: string): Promise<AxiosResponse<CorporationsFacilitySummary>> {
    return HTTP.get('/corporations/me/facility_summary', {
      params: {
        target_year_and_month: targetYearAndMonth,
      },
    })
  }
}
