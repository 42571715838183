<template>
  <div>
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-item color="primary" :complete="step > 0" value="1">
          CSVファイルをアップロード
        </v-stepper-item>
        <v-divider></v-divider>
        <v-stepper-item color="primary" :complete="step > 1" value="2">
          取り込み内容の設定
        </v-stepper-item>
        <v-divider></v-divider>
        <v-stepper-item color="primary" :complete="step === 2 && importStatus === 'done'" value="3">
          取り込み完了
        </v-stepper-item>
      </v-stepper-header>

      <v-stepper-window>
        <v-stepper-window-item value="1">
          <NotationPanel>
            <template #notation><slot name="notation"></slot></template>
          </NotationPanel>
          <!-- excelテンプレートのurlが渡されている場合は、excel->csv変換機能があるものとしてタブで表示する（暫定対応） -->
          <template v-if="excelTemplateDownloadUrl">
            <div class="border-thin rounded pa-2">
              <v-tabs v-model="tab">
                <v-tab>CSVを記入して取り込み</v-tab>
                <v-tab>ExcelをCSVに変換して取り込み</v-tab>
              </v-tabs>
              <v-window v-model="tab">
                <v-window-item>
                  <v-container>
                    <CsvUploadPanel @download-csv="onClickDownloadCsv">
                      <template #sampleEntryDialog>
                        <SampleEntryDialog>
                          <template #sampleEntry><slot name="csvSampleEntry" /></template>
                        </SampleEntryDialog>
                      </template>
                    </CsvUploadPanel>
                  </v-container>
                </v-window-item>
                <v-window-item>
                  <v-container>
                    <ExcelConvertPanel
                      :facility-id="facilityId"
                      @download-excel="onClickDownloadExcel"
                    >
                      <template #sampleEntryDialog>
                        <SampleEntryDialog>
                          <template #sampleEntry><slot name="excelSampleEntry" /></template>
                        </SampleEntryDialog>
                      </template>
                    </ExcelConvertPanel>
                  </v-container>
                </v-window-item>
              </v-window>
            </div>
          </template>
          <template v-else>
            <CsvUploadPanel> </CsvUploadPanel>
          </template>
        </v-stepper-window-item>

        <v-stepper-window-item value="2">
          <ColumnMappingPanel :import-csv="importCsv" :get-import-status="getImportStatus" />
        </v-stepper-window-item>

        <v-stepper-window-item value="3">
          <ImportStatusPanel>
            <template #completedMessage>
              <slot name="completedMessage"></slot>
            </template>
          </ImportStatusPanel>
        </v-stepper-window-item>
      </v-stepper-window>
    </v-stepper>
  </div>
</template>

<script setup lang="ts">
import { computed, watch, ref } from 'vue'
import ColumnMappingPanel from './components/ColumnMappingPanel.vue'
import NotationPanel from './components/NotationPanel.vue'
import CsvUploadPanel from './components/CsvUploadPanel.vue'
import SampleEntryDialog from './components/SampleEntryDialog.vue'
import ImportStatusPanel from './components/ImportStatusPanel.vue'
import { ImportPanelStore } from './import_panel_store'
import { CandidateColumnId, CsvImportColumnCandidate, CsvImportStatus } from './models'
import ExcelConvertPanel from './components/ExcelConvertPanel.vue'

/**
 * CSVインポートを行うための共通コンポーネントです。
 * CSVアップロード、CSVのカラムのマッピング、その後取り込みと取り込み結果表示のUIを作ることができます。
 * propsの詳細は `ImportPanelPropType` のdocを見てください
 *
 * slotについて
 * #notation
 * - ファイルの記入方法など、注釈があれば渡してください(optional)
 *
 * #csvSampleEntry
 * - CSVファイルの記入例があれば渡してください(optional)
 *
 * #excelSampleEntry
 * - Excelファイルの記入例があれば渡してください(optional)
 *
 * * #completedMessage
 * - 取り込み完了（成功）後のパネルに表示されます
 */

const props = withDefaults(
  defineProps<{
    excelTemplateDownloadUrl: string
    columnMappingSaveKey: string
    columnCandidates: CsvImportColumnCandidate[]
    importCsv: (
      columnMapping: CandidateColumnId[],
      isFirstLineIgnored: boolean,
      file: File
    ) => Promise<number>
    getImportStatus: (importId: number) => Promise<CsvImportStatus>
    targetYearAndMonth: string
    facilityId: number
  }>(),
  {
    excelTemplateDownloadUrl: '',
    targetYearAndMonth: '',
  }
)

const emit = defineEmits<{
  (event: 'downloadCsvTemplate'): void
  (event: 'downloadExcelTemplate'): void
}>()

watch(props, (props) => {
  ImportPanelStore.updateColumnCandidates(props.columnCandidates)
  ImportPanelStore.updateColumnMappingSaveKey(props.columnMappingSaveKey)
  ImportPanelStore.updateExcelTemplateDownloadUrl(props.excelTemplateDownloadUrl)
  ImportPanelStore.updateTargetYearAndMonth(props.targetYearAndMonth)
})

// 取り込みのためのCSVアップロード中、
// または3枚目のパネルにいてかつ取り込み中の表示時に
// タブを閉じようとした場合に本当に閉じて良いかのalertを出す
window.addEventListener('beforeunload', (event) => {
  if (
    ImportPanelStore.isImporting ||
    (ImportPanelStore.step === 2 && ImportPanelStore.importStatus.status === 'processing')
  ) {
    // 何かしらの文字列を入れればalertが表示される。
    // 入れた文字列はIEでしか画面に反映されないためここでは空文字でOK。
    event.returnValue = ''
  }
})

const tab = ref(null)
const step = computed(() => ImportPanelStore.step)
const importStatus = computed(() => ImportPanelStore.importStatus.status)
const onClickDownloadCsv = () => {
  emit('downloadCsvTemplate')
}
const onClickDownloadExcel = () => {
  emit('downloadExcelTemplate')
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/stylesheets/design/variables';

.row-number-cell {
  background-color: $color-bg-grey;
}

.border-thin {
  border: 1px solid $color-bg-grey;
}

.v-tabs::v-deep .v-slide-group-item--active {
  color: #0068b7 !important;
}

.v-tabs::v-deep button:not(.v-slide-group-item--active) {
  color: #0000008a !important;
}
</style>
