<template>
  <v-dialog v-model="isShow" width="700px" scrollable>
    <v-card>
      <v-card-text class="pt-4">
        <div class="text-error bg-red-lighten-5 px-4 py-2">
          <div v-for="(error, index) in errors" :key="index">
            <v-row no-gutters class="error-row py-2">
              <v-col cols="3">{{ error.csv_row }}行、{{ error.csv_col + 1 }}列目</v-col>
              <v-col cols="9">{{ error.message }}</v-col>
            </v-row>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <Button variant="text" @click="isShow = false"> OK </Button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import { computed, defineComponent } from 'vue'
import { ImportPanelStore } from '../import_panel_store'
import Button from 'shared/components/Button.vue'

/**
 * 全てのエラーを文字列で表示するダイアログです。
 */
export default defineComponent({
  components: {
    Button,
  },
  setup() {
    // computed
    const isShow = computed({
      get: () => ImportPanelStore.isShowAllErrorDialog,
      set: (value) => ImportPanelStore.updateIsShowAllErrorDialog(value),
    })
    const errors = computed(() => ImportPanelStore.importStatus.errors)

    return { isShow, errors }
  },
})
</script>
<style lang="scss" scoped>
.error-row {
  border-bottom: 1px dashed #ff5252;
}
</style>
