import { reactive } from 'vue'
import { ParentAccount } from 'http/modules/parent_account'

type AllParentIdPrintPageState = {
  isLoading: boolean
  allParentAccount: ParentAccount[] | undefined
  qrCodeImageSource: string
  canUseEnpayWallet: boolean
}

/**
 * 全員分のLINE登録用ID印刷ページページの状態。
 */
const state = reactive<AllParentIdPrintPageState>({
  isLoading: false,
  allParentAccount: undefined,
  qrCodeImageSource: '',
  canUseEnpayWallet: false,
})

export class AllParentIdPrintPageStore {
  static get isLoading(): boolean {
    return state.isLoading
  }

  static updateIsLoading(isLoading: boolean): void {
    state.isLoading = isLoading
  }

  static get allParentAccount(): ParentAccount[] | undefined {
    return state.allParentAccount
  }

  static updateAllParentAccount(allParentAccount: ParentAccount[]): void {
    state.allParentAccount = allParentAccount
  }

  static get qrCodeImageSource(): string {
    return state.qrCodeImageSource
  }

  static updateQrCodeImageSource(qrCodeImageSource: string): void {
    state.qrCodeImageSource = qrCodeImageSource
  }

  static get canUseEnpayWallet(): boolean {
    return state.canUseEnpayWallet
  }

  static updateCanUseEnpayWallet(canUseEnpayWallet: boolean): void {
    state.canUseEnpayWallet = canUseEnpayWallet
  }
}
