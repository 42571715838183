import { HTTP } from '../common'

export class ParentApi {
  /**
   * @param target_year_and_month yyyy-MM 形式
   * 指定した場合、保護者の削除時期を考慮した保護者・子供を返します(請求情報を含む)
   * 指定しない場合、施設に紐付く全ての保護者・子供を返します(請求情報を含まない)
   * @param parentIds 数値をカンマ区切りで渡す。123,456など。
   * target_year_and_monthが指定されていない時は無視されるパラメーターです。
   * idを指定した場合、指定したparentsのみ返します。されていなければ全件取得します。
   */
  static index(facilityId: number, target_year_and_month: string, parentIds: string): Promise<any> {
    return HTTP.get(
      `/facilities/${facilityId}/school_parents?target_year_and_month=${target_year_and_month}&school_parent_ids=${parentIds}`
    )
  }
}
