<template>
  <div class="modal_inner">
    <div v-if="parent" class="pa-6 pt-4 panel">
      <div class="mb-4 text-primary">
        <span class="text-h5 font-weight-bold">{{ targetYear }}年{{ targetMonth }}月</span
        ><span class="text-h6">請求分</span>
      </div>
      <v-alert
        v-if="isInvoiceLocked"
        icon="mdi-alert"
        type="error"
        variant="outlined"
        class="text-left mb-4"
      >
        <div class="font-weight-bold">{{ targetMonth }}月の請求は締められています。</div>
        <div class="mt-2 text-black">
          再請求のみ可能です。新規請求・請求の編集はできません。
          <br />
          新規請求・請求の編集をしたい場合は本部に連絡をしてください。
        </div>
      </v-alert>
      <div class="forms-wrapper">
        <v-window v-model="tabIndex">
          <v-window-item>
            <div
              v-for="(child, index) in parent.school_parent_children"
              :key="child.id"
              class="invoiceList-Content"
              :class="{ 'mt-4': index !== 0 }"
            >
              <p class="invoiceList-Content_head mb-0" data-testid="form-child-name">
                {{ child.first_name }}（{{ getChildSchoolClassName(parent, child) }}）
              </p>
              <InvoiceItemsForm
                :parent-id="parentId"
                :child-id="child.id"
                :is-invoice-locked="isInvoiceLocked"
              ></InvoiceItemsForm>
            </div>
          </v-window-item>
          <v-window-item>
            <div class="invoiceList-Content">
              <InvoiceItemsForm
                :parent-id="parentId"
                :is-invoice-locked="isInvoiceLocked"
                class="invoiceList-Content"
              ></InvoiceItemsForm>
            </div>
          </v-window-item>
        </v-window>
      </div>
      <div class="invoiceList-Content_foot">
        <v-row v-if="isTotalAmountZero()" no-gutters>
          <v-col cols="9" class="d-flex justify-center text-orange">
            <div>
              請求金額が0円の請求書は、請求した時点で支払い済みとなり、編集できなくなります。
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="9" class="d-flex justify-space-around">
            <v-tooltip location="top" :disabled="!isInvalidLine">
              <template #activator="{ props }">
                <div v-bind="props">
                  <Button
                    width="270px"
                    size="large"
                    :loading="isCharging || isSaving"
                    :disabled="isInvalidLine || isSaving"
                    @click="
                      $eventTracker.trackEvent('click_btn_charge_line', {}),
                        chargeInvoice(parent, true)
                    "
                  >
                    LINE請求
                  </Button>
                </div>
              </template>
              <div>
                <template v-for="(v, i) in tooltipMessageForLine.split('\n')">
                  <template v-if="i > 0">
                    <br :key="i" />
                  </template>
                  {{ v }}
                </template>
              </div>
            </v-tooltip>
            <v-tooltip location="top" :disabled="!isInvalidInvoice">
              <template #activator="{ props }">
                <div v-bind="props">
                  <Button
                    width="270px"
                    size="large"
                    :loading="isCharging || isSaving"
                    :disabled="isInvalidInvoice || isSaving"
                    @click="$eventTracker.trackEvent('click_btn_charge', {}), chargeInvoice(parent)"
                  >
                    請求書発行
                  </Button>
                </div>
              </template>
              <div>
                <template v-for="(v, i) in tooltipMessageForInvoice.split('\n')">
                  <template v-if="i > 0">
                    <br :key="i" />
                  </template>
                  {{ v }}
                </template>
              </div>
            </v-tooltip>
          </v-col>
          <v-col cols="3" class="d-flex justify-center">
            <v-tooltip location="top" :disabled="!isInvalidSave">
              <template #activator="{ props }">
                <div v-bind="props">
                  <Button
                    data-testid="save-button"
                    class="save-button"
                    variant="outlined"
                    size="large"
                    :loading="isSaving"
                    :disabled="isInvalidSave"
                    @click="
                      $eventTracker.trackEvent('click_btn_save_invoice', {}), saveInvoiceItems()
                    "
                  >
                    保存する
                  </Button>
                </div>
              </template>
              <div>
                <template v-for="(v, i) in tooltipMessageForSave.split('\n')">
                  <template v-if="i > 0">
                    <br :key="i" />
                  </template>
                  {{ v }}
                </template>
              </div>
            </v-tooltip>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
/* eslint-disable max-lines */
import { ref, computed } from 'vue'
import InvoiceItemsForm from '../../../pages/top_page/components/InvoiceItemsForm.vue'
import { InvoiceItemService } from 'pages/top_page/services/invoice_item_service'
import { InvoiceService } from 'pages/top_page/services/invoice_service'
import { Child, InvoiceStatusWithBeforeRegister, Parent } from 'pages/top_page/models'
import { SchoolParentService } from 'pages/top_page/services/school_parent_service'
import { SchoolParentChildService } from 'pages/top_page/services/school_parent_child_service'
import { TopPageStore } from 'pages/top_page/top_page_store'
import { InvoiceItemInputService } from 'pages/top_page/services/invoice_item_input_service'
import { InvoiceModalService } from '../services/invoice_modal_service'
import { SnackbarService } from 'shared/services/snackbar_service'
import Button from 'shared/components/Button.vue'

type ValidationResult = { isValid: boolean; message?: string }

const invoiceItemDetailBodyProps = withDefaults(
  defineProps<{
    parentId: number
    status: InvoiceStatusWithBeforeRegister
    isInvoiceLocked: boolean
  }>(),
  {
    status: 'before_register',
    isInvoiceLocked: false,
  }
)

const tabIndex = ref(0)

const parent = computed<Parent | undefined>(() =>
  SchoolParentService.getParentById(invoiceItemDetailBodyProps.parentId)
)
const targetYear = computed<string>(() => TopPageStore.targetYear)
const targetMonth = computed<number>(() => Number(TopPageStore.targetMonth))
const isCharging = computed<boolean>(() =>
  TopPageStore.invoiceChargingParentIds.includes(invoiceItemDetailBodyProps.parentId)
)
const isSaving = computed<boolean>(() =>
  TopPageStore.invoiceSavingParentIds.includes(invoiceItemDetailBodyProps.parentId)
)
// 請求締めされていても、未払い状態なら"再請求"として請求可能
const isChargeable = computed<boolean>(
  () =>
    !invoiceItemDetailBodyProps.isInvoiceLocked || invoiceItemDetailBodyProps.status === 'unpaid'
)
const isSavable = computed<boolean>(() => {
  if (invoiceItemDetailBodyProps.isInvoiceLocked) return false
  const parent = SchoolParentService.getParentById(invoiceItemDetailBodyProps.parentId)!

  return (
    InvoiceItemService.hasNoEmptyNameOrUnitPriceInputsAll(
      invoiceItemDetailBodyProps.parentId,
      parent.school_parent_children
    ) &&
    [undefined, ...parent.school_parent_children.map((child) => child.id)].some((childId) =>
      InvoiceItemService.hasSavableItemInputs(invoiceItemDetailBodyProps.parentId, childId)
    )
  )
})

const validateInvoiceItem = (): ValidationResult => {
  const parent = SchoolParentService.getParentById(invoiceItemDetailBodyProps.parentId)
  if (!parent) return { isValid: true }

  const isValidCount = InvoiceItemService.countRuleForParent(parent)
  const isValidUnitPrice = InvoiceItemService.unitPriceRuleForParent(parent)
  const isValidTotalPrice = InvoiceItemService.totalPriceRuleForParent(
    invoiceItemDetailBodyProps.parentId
  )

  return {
    isValid: isValidCount && isValidUnitPrice && isValidTotalPrice,
    message: (() => {
      if (!isValidCount || !isValidUnitPrice) {
        return [
          [isValidCount ? '' : '数量', isValidUnitPrice ? '' : '単価']
            .filter((v) => v !== '')
            .join('と'),
          'の入力値に誤りがあります',
        ].join('')
      }
      if (!isValidTotalPrice) return '合計金額が上限値を超えています'
      return ''
    })(),
  }
}

// "未払い" 且つ 編集中のInputが無ければ再請求と判断して、請求保存のAPIへのリクエストをしない
const isRemindWhenLocked = (parent: Parent, status: InvoiceStatusWithBeforeRegister) => {
  const isExistsEditingInputs = [
    undefined,
    ...parent.school_parent_children.map((child) => child.id),
  ].some((childId) =>
    InvoiceItemService.hasSavableItemInputs(invoiceItemDetailBodyProps.parentId, childId)
  )

  return !isExistsEditingInputs && status === 'unpaid'
}

const chargeInvoice = async (parent: Parent, sendToLine = false): Promise<void> => {
  const { isValid, message } = validateInvoiceItem()
  if (!isValid) {
    SnackbarService.open(`${message}、保存できませんでした。`, 'error')
    return
  }
  const isRemind = isRemindWhenLocked(parent, invoiceItemDetailBodyProps.status)

  await InvoiceService.charge(parent.id, sendToLine, isRemind)
  InvoiceModalService.closeInvoiceFormModal()
}

const getChildSchoolClassName = (parent: Parent, child: Child): string =>
  SchoolParentChildService.getChildSchoolClass(parent, child).name

const hasChargeableInvoice = (parent: Parent): boolean =>
  InvoiceItemService.hasChargableInputItems(parent.id)

const isTotalAmountZero = (): boolean => {
  if (!parent.value) return false
  return (
    InvoiceItemInputService.calculateInputTotalAmountByParentId(
      invoiceItemDetailBodyProps.parentId
    ) === 0
  )
}

const saveInvoiceItems = (): void => {
  const { isValid, message } = validateInvoiceItem()
  if (!isValid) {
    SnackbarService.open(`${message}、保存できませんでした。`, 'error')
    return
  }
  /**
   * 保存前にタブのindexを子どもの方にしておく処理。
   * これがないと、親子どちらにも請求内容を保存している状況で、親の請求内容のみを全て削除し保存した場合に、
   * v-tabsが消え、v-tabs-itemsが保護者の物のままとなってしまい、子どものフォームを見ることができなくなる（ダイアログを開き直したら見れる）。
   */
  if (parent.value && parent.value.school_parent_children.length > 0) tabIndex.value = 0

  InvoiceItemService.saveInvoiceItems([invoiceItemDetailBodyProps.parentId])
    .then(() => SnackbarService.open('請求書を保存しました。', 'info'))
    .catch((error) => {
      const message =
        error.response.status === 403
          ? '請求が締められているため、請求書の保存に失敗しました。'
          : '請求書の保存に失敗しました。'
      SnackbarService.open(message, 'error')
    })
}

const isValidInput = computed<boolean>(() => {
  if (invoiceItemDetailBodyProps.isInvoiceLocked) return false
  const parent = SchoolParentService.getParentById(invoiceItemDetailBodyProps.parentId)!

  return (
    InvoiceItemService.hasValidValueCountOrUnitPriceInputsAll(
      invoiceItemDetailBodyProps.parentId,
      parent.school_parent_children
    ) && validateInvoiceItem().isValid
  )
})

const isInvalidInvoice = computed<boolean>(() => {
  const parent = SchoolParentService.getParentById(invoiceItemDetailBodyProps.parentId)!
  if (!hasChargeableInvoice(parent)) {
    return true
  }
  const isUnpaid = parent.invoices[0]?.status === 'unpaid'
  if (invoiceItemDetailBodyProps.isInvoiceLocked && isUnpaid) {
    return false
  }

  return !isValidInput.value || isCharging.value || !isChargeable.value
})

const isInvalidLine = computed<boolean>(() => {
  if (!parent.value?.line_connected) {
    return true
  }
  const isNotUnpaid = parent.value?.invoices[0]?.status !== 'unpaid'

  if (isNotUnpaid) {
    return isInvalidInvoice.value
  }

  if (!hasChargeableInvoice(parent.value)) {
    return true
  }

  return !isValidInput.value || !isSavable.value || isCharging.value || !isChargeable.value
})

const isInvalidSave = computed<boolean>(
  () => !isValidInput.value || isSaving.value || !isSavable.value
)

const tooltipMessageForLine = computed<string>(() => {
  if (!parent.value?.line_connected) {
    return 'LINE未登録のため、LINE請求できません。'
  }
  const isUnpaid = parent.value.invoices[0]?.status === 'unpaid'
  if (invoiceItemDetailBodyProps.isInvoiceLocked && isUnpaid) {
    return '再請求の場合は、未払いタブから選択した請求を送れます。'
  }
  if (isUnpaid && !isSavable.value) {
    return '請求内容を変更すると、LINE請求を送れます。\n再請求の場合は、未払いタブから選択した請求を送れます。'
  }
  return '未入力または入力値が正しくないフォームがあるため、LINE請求できません。'
})
const tooltipMessageForInvoice = computed<string>(
  () => '未入力または入力値が正しくないフォームがあるため、請求書を発行できません。'
)
const tooltipMessageForSave = computed<string>(
  () => '未入力または入力値が正しくないフォームがあるため、保存できません。'
)

if (parent.value && parent.value.school_parent_children.length === 0) tabIndex.value = 1
</script>

<style lang="scss" scoped>
@import '../../../../assets/stylesheets/design/variables';

.save-button-area {
  background-color: $color-bg;
}
.forms-wrapper {
  max-height: 546px;
  overflow-y: auto;
  overflow-x: hidden;
}
.save-button {
  background-color: white;
}
.panel {
  max-height: calc(100vh - 120px) !important;
}

.v-tabs::v-deep .v-slide-group-item--active {
  color: #0068b7 !important;
}

.v-tabs::v-deep button:not(.v-slide-group-item--active) {
  color: #0000008a !important;
}
</style>
