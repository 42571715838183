/**
 * 決済手段の言語ファイル
 */
const PaymentMethod = {
  credit_card: 'クレジットカード',
  cvs: 'コンビニ',
  linepay: 'LINE Pay',
  paypay: 'PayPay',
  aupay: 'au PAY',
  enpay_wallet: 'enpayウォレット',
  merpay: 'メルペイ',
} as const

export default PaymentMethod

export type PaymentMethodKey = keyof typeof PaymentMethod
export type PaymentMethodValue = (typeof PaymentMethod)[PaymentMethodKey]
