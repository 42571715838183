import { FacilityInvoiceItemSummaryApi } from 'http/modules/facility_invoice_item_summary'
import { RouteService } from 'shared/services/route_service'
import { ExportInvoiceItemsPageStore } from 'pages/export_invoice_items_page/export_invoice_items_page_store'
import { ConvertToQueryParamsService } from 'pages/export_invoice_items_page/services/convert_to_query_params_service'
import { PaginationStore } from 'shared/components/pagination/pagination_store'
import { SwitchMode } from 'pages/export_invoice_items_page/model'

export class FacilityExportInvoiceItemSummaryService {
  /**
   * 初期値が欲しい時に呼ばれる関数
   */
  static async loadInitialData() {
    await FacilityExportInvoiceItemSummaryService.loadInvoiceItemSummary()
  }

  /**
   * 施設の請求内容一覧サマリを取得する関数
   */
  static async loadInvoiceItemSummary(mode?: SwitchMode) {
    const facilityId = FacilityExportInvoiceItemSummaryService.getTargetFacilityId()

    const { from, to, termType, status, includeKeyword, itemNames } =
      ConvertToQueryParamsService.convertToQueryParams()

    const apiResponse = await FacilityInvoiceItemSummaryApi.show({
      facilityId: facilityId,
      from,
      to,
      termType,
      itemNames,
      status,
      includeKeyword,
      perPage: 50,
    })
    ExportInvoiceItemsPageStore.updateInvoiceItemSummary(apiResponse.data)

    if (mode === 'isRefresh') {
      PaginationStore.updateCurrentPage(1)
    }
  }

  private static getTargetFacilityId(): number {
    return Number(RouteService.getPathParamByIndex(1))
  }
}
