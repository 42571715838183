<template>
  <AppFrame :has-browser-alert="false">
    <PrintButtonBar class="mb-6"></PrintButtonBar>
    <div>
      <div v-if="isLoading" class="d-flex justify-center">
        <v-progress-circular indeterminate :size="70" color="primary"></v-progress-circular>
      </div>
      <template v-else>
        <ParentIdDocument
          :parent-account="parentAccount"
          :qr-code-image-source="qrCodeImageSource"
          :can-use-enpay-wallet="canUseEnpayWallet"
        ></ParentIdDocument>
      </template>
    </div>
  </AppFrame>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import ParentIdDocument from 'shared/components/ParentIdDocument.vue'
import PrintButtonBar from 'shared/components/PrintButtonBar.vue'
import { RouteService } from 'shared/services/route_service'
import { ParentIdPrintPageStore } from '../parent_id_print_page_store'
import { ParentIdPrintService } from '../services/parent_id_print_service'
import { MetaService } from 'shared/services/meta_service'
import AppFrame from 'shared/components/AppFrame.vue'

MetaService.addTitle({ mode: 'facility', page: 'parentIdPrint' })
// '/facilities/:facility_id/parent_accounts/printings/xxx'というURLなので、4を指定。
const parentId = RouteService.getPathParamByIndex(4)!
ParentIdPrintService.loadParentIdPrintInfo(Number(parentId))

const parentAccount = computed(() => ParentIdPrintPageStore.parentAccount)
const qrCodeImageSource = computed(() => ParentIdPrintPageStore.qrCodeImageSource)
const isLoading = computed(() => ParentIdPrintPageStore.isLoading)
const canUseEnpayWallet = computed(() => ParentIdPrintPageStore.canUseEnpayWallet)
</script>
