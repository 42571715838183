/**
 * 保護者一覧のコドモン連携に関するロジックを持ちます。
 */

import CodmonApi from 'http/modules/codmon'
import { DialogService } from 'shared/services/dialog_service'
import LoadingMessage from 'pages/parents_page/areas/modal_message/LoadingMessage.vue'
import ResultCodmonConnectionMessage from 'pages/parents_page/areas/modal_message/ResultCodmonConnectionMessage.vue'
import { FacilityApi } from 'http/modules/facility'
import { ParentsService } from 'pages/parents_page/services/parents_service'
import { ParentsPageStore } from 'pages/parents_page/parents_page_store'

export class CodmonService {
  static async checkCodmonId(): Promise<void> {
    const school = (await FacilityApi.show(ParentsService.getTargetFacilityId())).data
    ParentsPageStore.updateHasCodmonId(Boolean(school.codmon_id))
  }

  static connect(facilityId: number): void {
    DialogService.openConfirm({
      title: 'コドモンから保護者情報を連携',
      message: ['コドモンに接続して保護者情報を取得します。'],
      onConfirm: () => CodmonService.connecting(facilityId),
    })
  }

  static async connecting(facilityId: number): Promise<void> {
    DialogService.open({
      title: 'コドモンデータ連携中',
      body: {
        component: {
          name: LoadingMessage,
          bind: { text: 'データ連携中です。完了までしばらくお待ちください。' },
        },
      },
      isShowCancel: false,
      isShowOk: false,
      isShowDivider: false,
    })

    try {
      const resultCodmonConnection = (await CodmonApi.loadCodmonChildren(facilityId)).data
      ParentsPageStore.updateHasResultCodmonConnection(true)
      DialogService.open({
        title: 'コドモンデータ連携結果',
        body: {
          component: {
            name: ResultCodmonConnectionMessage,
            bind: { resultCodmonConnection },
          },
        },
        okButtonText: '閉じる',
        isOkButtonType: 'text',
        isShowCancel: false,
        isShowOk: true,
        modalWidth: 900,
        // NOTE: 成功しているものがあれば結果を反映するためにリロードする。なければモーダルを閉じるだけ。
        onConfirm: () =>
          resultCodmonConnection.success_number.all !== 0
            ? location.reload()
            : ParentsPageStore.updateHasResultCodmonConnection(false),
      })
    } catch (e) {
      DialogService.openConfirm({
        title: 'コドモンデータ連携結果',
        message: [
          'コドモン園児データ連携に失敗しました。',
          '何度も表示される場合は運営にお問い合わせください。',
        ],
        // eslint-disable-next-line no-empty-function
        onConfirm: () => {},
      })
    }
  }
}
