import { ConvertToQueryParamsService } from 'pages/export_invoice_items_page/services/convert_to_query_params_service'
import { CorporationInvoiceItemsCsvApi } from 'http/modules/corporation_invoice_items_csv'
import { GroupByDownloadParams } from 'pages/export_invoice_items_page/model'
import { SnackbarService } from 'shared/services/snackbar_service'
import { ScreenLoadingService } from 'shared/services/screen_loading_service'

export class CorporationDownloadInvoiceItemsService {
  /**
   * 法人 そのままダウンロード
   */
  static searchFilteredDownload() {
    const { from, to, termType, status, includeKeyword, itemNames, facilityIds } =
      ConvertToQueryParamsService.convertToQueryParams()

    try {
      CorporationInvoiceItemsCsvApi.index({
        from,
        to,
        termType,
        itemNames,
        status,
        includeKeyword,
        facilityIds,
      })
    } catch (error) {
      SnackbarService.open('CSVのダウンロードに失敗しました', 'error')
    } finally {
      ScreenLoadingService.hideLoader()
    }
  }

  /**
   * 法人 集計してダウンロード
   * @param key どの項目で集計するか
   */
  static groupByDownload(key: GroupByDownloadParams['groupBy']) {
    const { from, to, termType, status, includeKeyword, itemNames, facilityIds } =
      ConvertToQueryParamsService.convertToQueryParams()

    try {
      CorporationInvoiceItemsCsvApi.index({
        from,
        to,
        termType,
        itemNames,
        status,
        includeKeyword,
        facilityIds,
        groupBy: key,
      })
    } catch (error) {
      SnackbarService.open('CSVのダウンロードに失敗しました', 'error')
    } finally {
      ScreenLoadingService.hideLoader()
    }
  }
}
