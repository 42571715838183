<template>
  <v-dialog
    v-model="isShow"
    :persistent="disabledBackdrop"
    :width="modalWidth"
    @click:outside="clickOutside"
  >
    <v-card data-testid="dialog">
      <v-card-title
        v-if="title"
        class="px-6 pt-4 pb-3"
        style="background-color: #3b81d3; color: #fff"
      >
        {{ title }}
      </v-card-title>
      <v-card-text class="pt-6">
        <template v-if="body.component">
          <component :is="body.component.name" v-bind="body.component.bind" />
        </template>
        <template v-else>
          <div
            v-for="(message, index) in body.message"
            :key="index"
            data-testid="dialog-message"
            class="text-body-1"
          >
            {{ message }}
          </div>
        </template>
      </v-card-text>
      <template v-if="isConsentRequired">
        <v-checkbox
          v-model="isConsented"
          :label="consentMessage"
          class="mr-auto ml-6 w-fit"
        ></v-checkbox>
      </template>
      <v-divider v-show="isShowDivider"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <Button v-show="isShowCancel" color="normal" variant="text" @click="cancel()"
          >キャンセル</Button
        >
        <v-tooltip location="top" :disabled="!isConsentRequired || isConsented">
          <template #activator="{ props }">
            <span v-bind="props">
              <Button
                v-show="isShowOk"
                :color="okButtonColor"
                variant="text"
                :disabled="(isConsentRequired && !isConsented) || isDisableOkButton"
                @click="confirm()"
              >
                {{ okButtonText }}
              </Button>
            </span>
          </template>
          <span>チェックボックスを選択してください</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
/**
 * 画面上にダイアログを表示するためのコンポーネントです。
 * 必ずdialog_service.tsを介して利用してください。
 * 利用方法は上記tsファイルを参照。
 */
import { computed, defineComponent, ref } from 'vue'
import { DialogService } from '../services/dialog_service'
import Button from 'shared/components/Button.vue'

export default defineComponent({
  components: {
    Button,
  },
  // eslint-disable-next-line max-lines-per-function
  setup() {
    // computed
    const isShow = computed({
      get: () => DialogService.show,
      set: (value) => (DialogService.show = value),
    })
    const isShowCancel = computed(() => DialogService.showCancel)
    const isShowOk = computed(() => DialogService.showOk)
    const title = computed(() => DialogService.title)
    const body = computed(() => DialogService.body)
    const modalWidth = computed(() => DialogService.modalWidth)
    const isDisableOkButton = computed(() => DialogService.isDisableOkButton)
    const isConsentRequired = computed(() => DialogService.isConsentRequired)
    const consentMessage = computed(() => DialogService.consentMessage)
    const okButtonText = computed(() => DialogService.okButtonText)
    const okButtonColor = computed(() => DialogService.okButtonColor)
    const isShowDivider = computed(() => DialogService.isShowDivider)
    const disabledBackdrop = computed(() => DialogService.disabledBackdrop)

    const isConsented = ref(false)

    // methods
    const confirm = () => {
      isConsented.value = false
      DialogService.confirm()
    }
    const cancel = () => {
      isConsented.value = false
      DialogService.cancel()
    }
    const clickOutside = () => {
      if (disabledBackdrop.value) {
        return
      }
      isConsented.value = false
      DialogService.backdrop()
    }

    return {
      disabledBackdrop,
      isShow,
      isShowCancel,
      isShowOk,
      title,
      body,
      modalWidth,
      isDisableOkButton,
      isShowDivider,
      isConsentRequired,
      consentMessage,
      isConsented,
      okButtonText,
      okButtonColor,
      confirm,
      cancel,
      clickOutside,
    }
  },
})
</script>
<style lang="scss" scoped>
.w-fit {
  width: fit-content;
}
</style>
