import { AxiosError, AxiosResponse } from 'axios'
import { HTTP } from '../common'
import { isAxiosError } from 'http/modules/invoice'

type ErrorType =
  | 'duplicate_parent_code_on_file'
  | 'duplicate_child_code_on_file'
  | 'different_names_between_same_id_on_file'
  | 'different_parent_codes_between_same_id_on_file'
  | 'different_child_codes_between_same_id_on_file'
  | 'non_existent_school_class_on_db'
  | 'non_existent_parent_id_on_db'
  | 'non_existent_child_id_on_db'
  | 'invalid_parent_and_child_relation_on_db'
  | 'changed_codmon_parent_first_name'
  | 'changed_codmon_parent_last_name'
  | 'changed_codmon_child_first_name'
  | 'invalid_parent_first_name'
  | 'invalid_parent_last_name'
  | 'invalid_parent_code'
  | 'empty_child_first_name'
  | 'invalid_child_code'
  | 'empty_class_name'
  | 'invalid_file'
  | 'invalid_worksheet'
  | 'invalid_encoding'

type WarningType =
  | 'duplicate_names_on_file'
  | 'duplicate_parent_names_between_new_record_and_saved_record_on_file'
  | 'duplicate_parent_and_child_name_on_db'
  | 'duplicate_parent_names_between_new_record_and_saved_record_on_db'

type ErrorDetail = {
  parent_id: '' | number
  parent_code: string
  parent_last_name: string
  parent_first_name: string
  child_id: '' | number
  child_code: string
  child_name: string
  class_name: string
}

type CreateResponse = {
  errors: {
    error: {
      row_num: number
      col_num: number
      attribute_name: string
      error_type: ErrorType
      error_detail: ErrorDetail
    }[]
    warn: {
      row_num: number
      col_num: number
      attribute_name: string
      error_type: WarningType
      error_detail: ErrorDetail
    }[]
  }
}
export type CreateErrorResponse = CreateResponse

export class ParentImportConfirmationApi {
  static create(facilityId: number, file: File): Promise<AxiosResponse<CreateResponse>> {
    const params = new FormData()
    params.append('file', file)
    return HTTP.post(`/facilities/${facilityId}/parents/import_confirmation`, params)
  }
}

export const isConfirmationError = (error: unknown): error is AxiosError<CreateErrorResponse> => {
  const errorResponse = error as AxiosError<CreateErrorResponse>

  return (
    isAxiosError(errorResponse) &&
    !!errorResponse.response &&
    errorResponse.response.status === 422 &&
    // @ts-ignore
    !!errorResponse.response.data.errors &&
    // @ts-ignore
    errorResponse.response.data.errors.error.length > 0
  )
}

export const isConfirmationBadRequest = (
  error: unknown
): error is AxiosError<CreateErrorResponse> => {
  const errorResponse = error as AxiosError<CreateErrorResponse>

  return (
    isAxiosError(errorResponse) &&
    !!errorResponse.response &&
    errorResponse.response.status === 400 &&
    // @ts-ignore
    !!errorResponse.response.data.errors &&
    // @ts-ignore
    errorResponse.response.data.errors.error.length > 0
  )
}

export const isConfirmationWarning = (error: unknown): error is AxiosError<CreateErrorResponse> => {
  const errorResponse = error as AxiosError<CreateErrorResponse>

  return (
    isAxiosError(errorResponse) &&
    !!errorResponse.response &&
    errorResponse.response.status === 409 &&
    // @ts-ignore
    !!errorResponse.response.data.errors &&
    // @ts-ignore
    errorResponse.response.data.errors.warn.length > 0
  )
}
