import { AxiosResponse } from 'axios'
import { HTTP } from '../common'
import { InvoiceItemNameCandidate } from 'shared/models'

export type IndexResponse = {
  invoice_items: InvoiceItemNameCandidate[]
}

export default {
  index(facilityId: number): Promise<AxiosResponse<IndexResponse>> {
    return HTTP.get(`/facilities/${facilityId}/invoice_item_name_candidates`)
  },
  create(facilityId: number, params: any): Promise<any> {
    return HTTP.post(`/facilities/${facilityId}/invoice_item_name_candidates`, params)
  },
  update(facilityId: number, id: any, params: any): Promise<any> {
    return HTTP.patch(`/facilities/${facilityId}/invoice_item_name_candidates/${id}`, params)
  },
  destroy(facilityId: number, itemId: any): Promise<any> {
    return HTTP.delete(`/facilities/${facilityId}/invoice_item_name_candidates/${itemId}`)
  },
}
