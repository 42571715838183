<template>
  <div>
    <div
      v-if="parentSearchResult.length === 0"
      class="d-flex justify-center align-center flex-column bg-white invoice-header-list py-12"
    >
      <S3Image path="/invoices/empty_state_illust.svg" alt="検索結果は0件です" />
      <div class="text-h5 text-grey-darken-2 mt-8">検索結果は0件です</div>
    </div>
    <template v-else-if="parentSearchResult.length > 0">
      <div v-if="isUnpaidSelected" class="select-all-checkbox-area">
        <v-checkbox
          v-model="isAllSelectChecked"
          data-testid="select-all-checkbox"
          class="select-all-checkbox d-flex align-center"
          label="全選択"
        ></v-checkbox>
      </div>
      <div class="invoice-header-list">
        <template v-if="isShowNewTopPageFormat">
          <InvoiceHeader
            v-for="parent in parentSearchResult"
            :key="parent.id"
            :ref="'invoiceHeader'"
            :parent-id="parent.id"
          >
          </InvoiceHeader>
        </template>
        <template v-else>
          <LegacyInvoiceHeader
            v-for="parent in parentSearchResult"
            :key="parent.id"
            :ref="'invoiceHeader'"
            :parent-id="parent.id"
          >
          </LegacyInvoiceHeader>
        </template>
      </div>
      <div style="height: 100px" />
    </template>
  </div>
</template>

<script lang="ts">
import InvoiceHeader from 'pages/top_page/components/InvoiceHeader/InvoiceHeader.vue'
import LegacyInvoiceHeader from 'pages/top_page/components/LegacyInvoiceHeader/LegacyInvoiceHeader.vue'
import { SchoolParentService } from '../services/school_parent_service'
import { defineComponent, computed } from 'vue'
import { TopPageStore } from '../top_page_store'
import rfdc from 'rfdc'
import S3Image from 'shared/components/s_3_image/S3Image.vue'
const clone = rfdc()

export default defineComponent({
  components: {
    S3Image,
    InvoiceHeader,
    LegacyInvoiceHeader,
  },
  setup() {
    // computed
    const parentSearchResult = computed(() => SchoolParentService.getSearchedSchoolParents())
    const isUnpaidSelected = computed(() => TopPageStore.searchCondition.paymentStatus === 'unpaid')
    const isShowNewTopPageFormat = computed(() => TopPageStore.isShowNewTopPageFormat)

    const isAllSelectChecked = computed({
      get: (): boolean => {
        const searchedParents = SchoolParentService.getSearchedSchoolParents()
        // 配列のサイズが0のときeveryはtrueを返すため明示的にfalseを返す
        if (searchedParents.length === 0) return false
        return searchedParents.every((parent) => parent.isChecked)
      },
      set: (newValue) => {
        const clonedParents = clone(TopPageStore.schoolParents)
        const searchedParentIds = SchoolParentService.getSearchedSchoolParents().map(
          (parent) => parent.id
        )
        clonedParents.forEach((parent) => {
          if (searchedParentIds.includes(parent.id)) {
            parent.isChecked = newValue
          }
        })
        TopPageStore.updateSchoolParents(clonedParents)
      },
    })

    return {
      isAllSelectChecked,
      parentSearchResult,
      isUnpaidSelected,
      isShowNewTopPageFormat,
    }
  },
})
</script>

<style scoped>
.select-all-checkbox-area {
  background-color: white;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}
.no-data-icon {
  zoom: 6;
  color: #bbb !important;
}
.select-all-checkbox {
  margin-left: 10px;
}
.invoice-header-list > :not(:last-child) {
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}
</style>
