import InvoicePrintPage from 'pages/invoice_print_page/components/InvoicePrintPage.vue'
import { InvoiceService } from 'pages/invoice_print_page/services/invoice_service'
import { InvoicePrintPageStore } from 'pages/invoice_print_page/invoice_print_page_store'
import { LaunchDarklyService } from 'shared/services/launch_darkly_service'

export default {
  components: {
    InvoicePrintPage,
  },
  created: function () {
    LaunchDarklyService.getFlag('class_name_on_invoice', InvoiceService.getTargetFacilityId()).then(
      (flag) => InvoicePrintPageStore.updateIsShowClassName(flag)
    )
  },
}
