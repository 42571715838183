import { EditableParent, Parent } from 'pages/parents_page/model'
import { reactive } from 'vue'
import { SchoolClass } from 'http/modules/class'

type ParentsEditPageState = {
  parent: EditableParent | null
  modifiedParent: Parent | null
  schoolClasses: SchoolClass[]
  childId: number
  parentId: number
  isDisabled: boolean
  conflictedParentsOrChildrenNames: EditableParent[]
}

const state = reactive<ParentsEditPageState>({
  parent: null,
  modifiedParent: null,
  schoolClasses: [],
  childId: 1,
  parentId: 1,
  isDisabled: false,
  conflictedParentsOrChildrenNames: [],
})

export class ParentsEditPageStore {
  static get parent(): EditableParent | null {
    return state.parent
  }

  static updateParent(parent: EditableParent | null): void {
    state.parent = parent
  }

  static get modifiedParent(): Parent | null {
    return state.modifiedParent
  }

  static updateModifiedParent(parent: Parent | null): void {
    state.modifiedParent = parent
  }

  static get schoolClasses(): SchoolClass[] {
    return state.schoolClasses
  }

  static updateSchoolClasses(schoolClasses: SchoolClass[]): void {
    state.schoolClasses = schoolClasses
  }

  static generateParentId(): number {
    const currentParentId = state.parentId
    state.parentId += 1
    return currentParentId
  }

  static generateChildId(): number {
    const currentChildId = state.childId
    state.childId += 1
    return currentChildId
  }

  static get isDisabled(): boolean {
    return state.isDisabled
  }

  static updateIsDisabled(disable: boolean) {
    state.isDisabled = disable
  }

  static get conflictedParentsOrChildrenNames(): EditableParent[] {
    return state.conflictedParentsOrChildrenNames
  }

  static updateConflictedParentsOrChildrenNames(parents: EditableParent[]): void {
    state.conflictedParentsOrChildrenNames = parents
  }
}
