<template>
  <div>
    <span class="text-body-1">
      <span class="font-weight-bold"> {{ facilityName }}</span>
      の請求締めを取り消して、請求内容を変更できるようにします。
    </span>
  </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    facilityName: String,
  },
})
</script>
