<template>
  <div class="text-body-1 mb-4">
    <span class="font-weight-bold">CSVを記入する</span>方法、または<span class="font-weight-bold"
      >Excelを記入してCSVに変換する</span
    >方法のいずれかを選択して請求内容を取り込めます。<br />
    CSVとExcelでは、記入方法が異なります。
    <v-dialog v-model="isShowDialog" width="770">
      <template #activator="{ props }">
        <span class="text-body-1 text-decoration-underline text-primary my-4">
          <span v-bind="props" role="button"> 詳しくはこちら </span>
        </span>
      </template>

      <v-card>
        <v-card-title class="text-h5 font-weight-regular text-white bg-primary-lighten-1">
          CSV・Excelの記入方法について
        </v-card-title>
        <v-card-text class="text-h6 font-weight-bold text-black mt-6"> CSVの記入方法 </v-card-text>
        <v-card-text class="text-body-1 text-black">
          <span class="font-weight-bold">請求内容ごとに新しい行を下に追加します。</span><br />
          子ども1人に対する請求内容が複数行にまたがる点にご注意ください。<br />
          他システムとの連携時などに便利です。
        </v-card-text>
        <div>
          <S3Image path="/invoice_import/format_csv.png" class="px-6 mb-2" />
        </div>
        <v-card-text class="text-h6 font-weight-bold text-black mt-8">
          Excelの記入方法
        </v-card-text>
        <v-card-text class="text-body-1 text-black">
          <span class="font-weight-bold"
            >請求内容マスタ設定に登録されている項目の列が自動で挿入されます。</span
          ><br />
          単価・数量を埋めるだけで、記入が完了します。<br />
          マスタ登録していない請求内容の列を追加することもできます。
        </v-card-text>
        <div>
          <S3Image path="/invoice_import/format_excel.png" class="px-6 mb-2" />
        </div>

        <v-card-actions class="justify-center">
          <Button
            class="my-6"
            color="grey-darken-2"
            size="x-large"
            variant="outlined"
            width="180"
            @click="closeDialog()"
          >
            閉じる
          </Button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import S3Image from 'shared/components/s_3_image/S3Image.vue'
import Button from 'shared/components/Button.vue'

export default defineComponent({
  components: {
    S3Image,
    Button,
  },
  setup() {
    const isShowDialog = ref(false)
    const closeDialog = () => (isShowDialog.value = false)
    return {
      isShowDialog,
      closeDialog,
    }
  },
})
</script>
