<template>
  <div class="d-flex justify-end align-end" :class="amountClassMapping">
    {{ commaSeparatedAmount
    }}<span :class="unitClassMapping" style="line-height: 2 !important; vertical-align: middle"
      >円</span
    >
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue'

/**
 * 数値をカンマ区切りで表示し、単位「円」と共に表示します。
 * 単位「円」は数値よりも小さく表示されます。
 * amountがundefinedの場合には「--- 円」と表示します。
 * amountにはnumber型またはundefinedを渡してください。
 * size には'S', 'M', 'L' もしくは https://vuetifyjs.com/en/styles/text-and-typography/#text-and-typography にある class を指定してください。デフォルトは'M'です。
 * size に class を指定した場合は金額と単位の両方に指定した class が適用されます。
 */
const props = withDefaults(
  defineProps<{
    amount: undefined | number
    size?: 'S' | 'M' | 'L'
  }>(),
  {
    size: 'M',
  }
)

// data
const amountClassMapping = {
  'text-h4': props.size === 'L',
  'text-h5': props.size === 'M',
  'text-body-1': props.size === 'S',
}
const unitClassMapping = {
  'text-body-1': props.size === 'L',
  'text-body-2': props.size === 'M',
  'text-caption': props.size === 'S',
  'ml-2': true,
}

// computed
const commaSeparatedAmount = computed(() =>
  props.amount === undefined ? '---' : props.amount.toLocaleString('ja-JP')
)
</script>
