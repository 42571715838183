<template>
  <div>
    <div v-if="importStatus.status === 'processing'">
      <div class="d-flex justify-center my-10">
        <v-progress-circular indeterminate :size="170" color="primary">
          取り込み中...
        </v-progress-circular>
      </div>
    </div>
    <div v-if="importStatus.status === 'done'">
      <v-alert color="info" variant="outlined" class="mb-4">
        <div class="d-flex text-h6">
          <v-icon color="info" class="mr-2">mdi-check-circle</v-icon>
          <div v-if="importStatus.success_count > 0">
            {{ importStatus.success_count }}件の取り込みに成功しました。
          </div>
          <div v-if="importStatus.success_count === 0">
            取り込みに成功しました。データの変更はありませんでした。
          </div>
        </div>
      </v-alert>

      <slot name="completedMessage"></slot>
    </div>
    <div v-if="importStatus.status === 'error'">
      <v-alert icon="mdi-alert" type="error" variant="outlined" density="compact" class="mb-4">
        正しく入力されていない項目があります。エラー内容を確認し、修正してください。
      </v-alert>

      <div class="mb-2 d-flex align-center">
        <span class="text-h6 mr-1">{{ importStatus.errors.length }}</span>
        <span
          >件のエラーがあります（下表で赤色の欄にマウスを乗せて、エラーの詳細を確認してください）</span
        >
        <div
          v-if="importStatus.errors.length > maxDisplayedInTableErrorCount"
          class="d-flex align-center flex-grow-1 justify-space-between"
        >
          <span>（内{{ maxDisplayedInTableErrorCount }}件を以下で表示しています）</span>
          <Button @click="openAllErrorDialog()">
            {{ importStatus.errors.length }}件のエラーを全て表示する
          </Button>
        </div>
      </div>

      <SimpleTable>
        <tr>
          <th>行数</th>
          <th v-for="(name, index) in mappedColumnNames" :key="index">
            {{ name || '−−' }}
          </th>
        </tr>
        <template v-for="(errorSummary, index) in errorSummaries" :key="errorSummary.row">
          <tr
            v-if="isShowDots(index, errorSummary, errorSummaries)"
            :key="errorSummary.row + '-pre'"
            class="omission"
          >
            <td :colspan="errorSummary.data.length + 1">
              <div class="d-flex justify-space-around">
                <v-icon v-for="dotsIndex in 3" :key="'dots-' + dotsIndex">mdi-dots-vertical</v-icon>
              </div>
            </td>
          </tr>
          <tr>
            <td class="text-center row-number-cell">
              {{ errorSummary.row + 1 }}
            </td>
            <template v-for="(cellValue, cellIndex) in errorSummary.data">
              <template v-if="errorSummary.errorMessageMap.has(cellIndex)">
                <td :key="cellIndex" class="error-cell">
                  <v-tooltip location="bottom">
                    <template #activator="{ props }">
                      <div v-bind="props" :key="cellIndex">
                        {{ cellValue === '' ? '&nbsp;' : cellValue }}
                      </div>
                    </template>
                    <span>{{ errorSummary.errorMessageMap.get(cellIndex) }}</span>
                  </v-tooltip>
                </td>
              </template>
              <td v-else :key="cellIndex" :title="cellValue">{{ cellValue }}</td>
            </template>
          </tr>
        </template>
      </SimpleTable>
      <div class="my-8">
        CSVファイルの修正ができたら<a @click="reload()">再アップロード</a>しましょう
      </div>
    </div>
    <AllErrorDialog />
  </div>
</template>
<script lang="ts">
import { computed, defineComponent } from 'vue'
import { ImportPanelStore } from '../import_panel_store'
import AllErrorDialog from './AllErrorDialog.vue'
import SimpleTable from './SimpleTable.vue'
import {
  ImportStatusService,
  maxDisplayedInTableErrorCount,
} from '../services/import_status_service'
import { ErrorSummary } from '../models'
import Button from 'shared/components/Button.vue'

/**
 * 取り込み状況を表示するためのパネルです。
 */
export default defineComponent({
  components: {
    SimpleTable,
    AllErrorDialog,
    Button,
  },
  setup() {
    // computed
    const importStatus = computed(() => ImportPanelStore.importStatus)
    const mappedColumnNames = ImportStatusService.mappedColumnNames()
    const errorSummaries = ImportStatusService.errorSummaries()
    const openAllErrorDialog = () => ImportPanelStore.updateIsShowAllErrorDialog(true)
    // methods
    const isShowDots = (
      index: number,
      errorSummary: ErrorSummary,
      errorSummaries: ErrorSummary[]
    ) => ImportStatusService.isShowDots(index, errorSummary, errorSummaries)
    const reload = () => location.reload()

    return {
      maxDisplayedInTableErrorCount,
      importStatus,
      mappedColumnNames,
      errorSummaries,
      openAllErrorDialog,
      isShowDots,
      reload,
    }
  },
})
</script>
<style lang="scss" scoped>
.omission {
  height: 56px;
}
</style>
