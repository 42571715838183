import { getObjectKeys } from 'shared/utils/getObjectKeys'
import { computed, readonly, ref } from 'vue'

/**
 * 請求一覧の検索パネルの表示モードを切り替えるためのcomposable
 * 通常表示とスッキリ表示の2つのモードがあり、以下の情報を提供します。
 * @return searchPanelMode - 現在の表示モード
 * @return searchPanelModeToggleLabel - 表示モードを切り替えるためのラベル。通常表示なら「スッキリ表示に切り替え」、スッキリ表示なら「通常表示に切り替え」
 * @return toggleSearchPanelMode - 表示モードを切り替えるための関数
 */
export default function useSearchPanelMode() {
  const searchPanelModeLabelMap = {
    default: '通常表示',
    compact: 'スッキリ表示',
  } as const
  const initialSearchPanelMode = localStorage.getItem('searchPanelMode') as
    | keyof typeof searchPanelModeLabelMap
    | null
  const searchPanelModeOrigin = ref<keyof typeof searchPanelModeLabelMap>(
    initialSearchPanelMode ?? 'default'
  )
  const searchPanelMode = readonly(searchPanelModeOrigin)

  const switchableSearchPanelMode = computed(() => {
    const currentMode = searchPanelModeOrigin.value
    return getObjectKeys(searchPanelModeLabelMap).filter((key) => key !== currentMode)[0]
  })

  const searchPanelModeToggleLabel = computed(
    () => `${searchPanelModeLabelMap[switchableSearchPanelMode.value]}に切り替え`
  )
  const toggleSearchPanelMode = () => {
    searchPanelModeOrigin.value = switchableSearchPanelMode.value
    localStorage.setItem('searchPanelMode', searchPanelModeOrigin.value)
  }

  return {
    searchPanelMode,
    searchPanelModeToggleLabel,
    toggleSearchPanelMode,
  }
}
