<template>
  <div>
    <!-- vue-infinite-loadingを1ページ目のロードに使おうとした。 -->
    <!-- しかし、年月の初期値が決まっていないと1ページ目をロードしてはいけないという制約をクリアできなかったので -->
    <!-- 1ページ目のローディングは独自で実装している(infinite-loadingのspinner slotを使っていない) -->
    <div v-if="isLoading">
      <v-card v-for="i in 10" :key="i" class="mb-4">
        <v-skeleton-loader type="card, list-item"></v-skeleton-loader>
      </v-card>
    </div>
    <template v-else>
      <div
        v-if="facilityAccountingSummaries.length === 0"
        class="d-flex justify-center align-center flex-column"
      >
        <div class="text-h5 text-grey-darken-2 mt-8">検索結果は0件です</div>
        <v-icon color="lighten-4" class="no-data-icon">mdi-magnify</v-icon>
      </div>
      <template v-else>
        <FacilityAccountingSummaryCard
          v-for="summary in facilityAccountingSummaries"
          :key="summary.facility_id"
          :summary="summary"
          class="mb-4"
        />
        <InfiniteLoading :identifier="identifier" @infinite="infiniteHandler">
          <template #spinner>
            <div>
              <div class="d-flex justify-center align-center infinite-loading-circlar-wrapper">
                <v-progress-circular indeterminate :size="70" color="primary"></v-progress-circular>
              </div>
            </div>
          </template>
          <template #no-more>
            <!-- デフォルトメッセージを表示させないため -->
            <div></div>
          </template>
          <template #no-results>
            <!-- デフォルトメッセージを表示させないため -->
            <div></div>
          </template>
        </InfiniteLoading>
      </template>
    </template>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent } from 'vue'
import FacilityAccountingSummaryCard from './FacilityAccountingSummaryCard.vue'
import { TransferPageStore } from '../transfer_page_store'
import InfiniteLoading from 'vue-infinite-loading'
import { TransferService } from '../services/transfer_service'

export default defineComponent({
  components: {
    FacilityAccountingSummaryCard,
    InfiniteLoading,
  },
  setup() {
    const facilityAccountingSummaries = computed(
      () => TransferPageStore.facilityAccountingSummaries
    )
    const isLoading = computed(() => TransferPageStore.isFacilityListLoading)
    // リストを入れ替えることがあるのでidentifierを使っている。実装はドキュメントを参考にした
    // https://peachscript.github.io/vue-infinite-loading/guide/use-with-filter-or-tabs.html
    const identifier = computed(() => TransferPageStore.infiniteLoadingIdentifier)

    const infiniteHandler = ($state: any) => TransferService.handleInfiniteLoad($state)
    return { facilityAccountingSummaries, isLoading, identifier, infiniteHandler }
  },
})
</script>
<style lang="scss" scoped>
.loading-circular-wrapper {
  height: 300px;
}
.infinite-loading-circlar-wrapper {
  height: 120px;
}
.no-data-icon {
  zoom: 6;
  color: #bbb !important;
}
</style>
