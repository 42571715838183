import { QueryParams } from 'pages/parents_page/model'
import { HTTP } from '../common'
import { AxiosResponse } from 'axios'

export type SchoolClass = {
  name: string
}

export type Child = {
  name: string
  school_class: SchoolClass
}
export type ParentAccount = {
  parent_id: number
  first_name: string
  last_name: string
  onetime_id: string
  onetime_password: string
  children: Child[]
}

export type IndexResponse = ParentAccount[]

export type ShowResponse = {
  data: ParentAccount
}

type IndexParams = {
  facilityId: number
  queryParams?: Omit<QueryParams, 'page' | 'per_page'>
}

export class ParentAccountApi {
  /**
   * LINE ID登録用紙をダウンロード
   * parent_idsが含まれていたら、選択して印刷する場合に対応
   * query情報が含まれていたら、検索条件に一致するすべてを選択して印刷する場合に対応
   * @param facilityId 施設ID
   * @param queryParams 検索条件
   */
  static index({ facilityId, queryParams }: IndexParams): Promise<AxiosResponse<IndexResponse>> {
    const query = new URLSearchParams(queryParams)

    return HTTP.get(
      `/facilities/${facilityId}/parent_accounts${
        query.toString().length > 0 ? `?${query.toString()}` : ''
      }`
    )
  }

  static show(facilityId: number, parentId: number): Promise<ShowResponse> {
    return HTTP.get(`/facilities/${facilityId}/parent_accounts/${parentId}`)
  }
}
