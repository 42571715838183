<template>
  <div>
    <p>ファイル形式が対応していない可能性があります</p>
    <LinkText :link-url="helpPageUrl" is-open-new-tab>詳しくはこちら</LinkText>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import LinkText from 'shared/components/LinkText.vue'

export default defineComponent({
  components: { LinkText },
  props: {
    helpPageUrl: { type: String, required: true },
  },
})
</script>
