<template>
  <div>
    <v-alert v-if="reportMessages.length !== 0" type="info" variant="outlined">
      <div class="mb-6">処理をスキップされた行があります。</div>
      <div v-for="message in reportMessages" :key="message" class="mb-2 text-black">
        <span class="font-weight-bold"> {{ message.index }}</span>
        <br />
        <span class="pl-4">{{ message.message }}</span>
      </div>
    </v-alert>
    <v-alert
      v-if="errorMessages.length !== 0"
      type="error"
      icon="mdi-alert-circle"
      variant="outlined"
    >
      <div class="mb-6">
        エラーが発生しました。以下の内容を参考にExcelファイルを修正してください。
      </div>
      <div class="d-flex align-center mb-6">
        <div class="flex-shrink-1 mr-2 max-width-200">
          <S3Image path="/invoice_import/explanation_of_row_and_column.png"></S3Image>
        </div>
        <div class="flex-grow-1 text-black">
          行・列番号については、左図をあわせてご参照ください。
        </div>
      </div>
      <div v-for="message in errorMessages" :key="message" class="mb-2 text-black">
        <span class="font-weight-bold"> {{ message.index }}</span>
        <br />
        <span class="pl-4">{{ message.message }}</span>
      </div>
    </v-alert>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed, ComputedRef } from 'vue'
import S3Image from 'shared/components/s_3_image/S3Image.vue'
import { ConvertAlertMessage, ExcelConvertService } from '../services/excel_convert_service'
import { ImportPanelStore } from '../import_panel_store'

export default defineComponent({
  components: { S3Image },
  setup() {
    const errorMessages: ComputedRef<ConvertAlertMessage[]> = computed(() =>
      ImportPanelStore.convertToCsvErrors
        .filter((e) => e.type === 'error')
        .map((e) => ExcelConvertService.buildErrorMessage(e))
    )

    const reportMessages: ComputedRef<ConvertAlertMessage[]> = computed(() =>
      ImportPanelStore.convertToCsvErrors
        .filter((e) => e.type === 'report')
        .map((e) => ExcelConvertService.buildErrorMessage(e))
    )
    return { errorMessages, reportMessages }
  },
})
</script>

<style lang="scss" scoped>
@import '../../../../../assets/stylesheets/design/variables';
.border-red {
  border: $color-error solid;
}
// tailwind化に向けてこういう変数を貯めていきたい
.max-width-200 {
  max-width: 200px;
}
</style>
