<template>
  <div class="ml-3">
    <p>以下のいずれかに該当しますか</p>
    <p>
      特定商取引法に関する法律に定められた禁止行為に該当する行為を行っている、また直近５年間に同法による処分を受けたことがある
    </p>
    <p>
      消費者契約法において消費者に取消権が発生する原因となる行為を行っている、また直近５年間に同法違反あるいは同法の適用を理由とする敗訴判決を受けたことがある
    </p>
    <v-radio-group v-model="formValue.isApplicableSpecialCommercialCodeMeasures">
      <v-radio color="primary" label="該当する" :value="true"></v-radio>
      <v-radio color="primary" label="該当しない" :value="false"></v-radio>
    </v-radio-group>
  </div>
</template>

<script>
import { CorporationApplyPageStore } from '../corporation_apply_page_store'

export default {
  computed: {
    formValue() {
      return CorporationApplyPageStore.formValue
    },
  },
}
</script>
