<template>
  <v-combobox
    v-model="selectedInvoiceItems"
    :items="invoiceItemNameCandidates"
    item-title="name"
    menu-icon=""
    placeholder="入力してください"
    return-object
    hide-selected
    multiple
    variant="outlined"
    hide-details
    density="compact"
    color="primary"
    chips
    closable-chips
    clearable
    clear-icon="mdi-close"
    :menu-props="{ minWidth: '600', maxHeight: '200' }"
    @update:model-value="onChangeValue($event)"
  >
    <template #item="{ props, item }">
      <v-list-item v-bind="props">
        {{ props.name }}
        <template #append>
          <div class="text-subtitle-2 text-grey-darken-1">
            {{ item.raw.master_flg ? '請求内容マスタ' : '履歴' }}
          </div>
        </template>
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { ExportInvoiceItemsPageStore } from 'pages/export_invoice_items_page/export_invoice_items_page_store'
import { InvoiceItemNameCandidate } from 'shared/models'

defineProps<{
  invoiceItemNameCandidates: InvoiceItemNameCandidate[]
}>()

const emit = defineEmits<{
  (event: 'onChangeInvoiceItems', invoiceItems: string[]): void
}>()

const selectedInvoiceItems = computed({
  get: () => ExportInvoiceItemsPageStore.selectedInvoiceItems,
  set: () => ExportInvoiceItemsPageStore.selectedInvoiceItems,
})

const onChangeValue = (value: InvoiceItemNameCandidate[] | string[]): void => {
  const trimmedValue = value
    .map((item) => (typeof item === 'object' ? item.name : item).trim())
    .filter((item) => item !== '')

  // 重複を取り除いて、親コンポーネントに渡す
  emit('onChangeInvoiceItems', Array.from(new Set(trimmedValue)))
}
</script>

<style scoped lang="scss">
.v-combobox ::v-deep .v-chip {
  border-radius: 2px;
  background: #f6f6f6;
  margin: 2px;
  align-items: center;
  display: inline-flex;
  color: #404040;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  pointer-events: none;
}
.v-autocomplete:not(.v-input--is-focused).v-select--chips ::v-deep input {
  max-height: 25px !important;
}
.v-combobox ::v-deep .v-chip .v-chip__close {
  pointer-events: auto;
}
.v-combobox ::v-deep .v-chip .v-chip__close:hover {
  opacity: 0.8;
}
</style>
