import { HTTP } from '../common'
import { AxiosResponse } from 'axios'

type CreateParams = {
  do_door_to_door_sales: boolean
  do_specified_continuous_service_provision: boolean
  do_telemarketing_related_sales: boolean
  do_chain_reaction_sales: boolean
  do_freelance_sales: boolean
  provided_prepaid_transactions: boolean
  is_applicable_special_commercial_code_measures: boolean
  developed_franchise: boolean
  email: string
  business_type: string
  type_of_industry: string
  business_description: string
  number: string
  name: string
  name_kana: string
  established_date: string
  capital_stock: number
  employees_number: number
  annual_turnover: number
  home_page_url: string
  postal_code: string
  prefecture_name: string
  city_name: string
  town_name: string
  street_name: string
  building_name: string
  prefecture_name_kana: string
  city_name_kana: string
  town_name_kana: string
  street_name_kana: string
  building_name_kana: string
  phone_number: string
  gmo_inspection_status: string
  gmo_payment_shop_id: string
  gmo_payment_shop_pass: string
  oem_partner_id: string
  oem_partner_branch_name: string
  representative_first_name: string
  representative_last_name: string
  representative_first_name_kana: string
  representative_last_name_kana: string
  representative_birthdate: string
  representative_postal_code: string
  representative_prefecture_name: string
  representative_city_name: string
  representative_town_name: string
  representative_street_name: string
  representative_building_name: string
  representative_prefecture_name_kana: string
  representative_city_name_kana: string
  representative_town_name_kana: string
  representative_street_name_kana: string
  representative_building_name_kana: string
  representative_phone_number: string
}

export type Corporation = {
  id: number
  name: string
  oldest_facility_transferred_at: string
  oldest_facility_created_at: string
}

export class CorporationApi {
  static create(params: CreateParams): Promise<void> {
    return HTTP.post('/corporations', params)
  }

  static self(): Promise<AxiosResponse<Corporation>> {
    return HTTP.get<Corporation>('/corporations/me')
  }
}
