<template>
  <div class="w-100">
    <div v-if="!isInitialSearchQuery && parents.length === 0" class="text-center bg-white py-6">
      <div class="text-center ma-10"><S3Image path="/parents/no_found.png" /></div>
      <p class="ma-6 font-weight-bold">検索結果は0件です。</p>
      <p class="ma-6 font-weight-medium">別のキーワードや検索条件を試してください。</p>
    </div>
    <div v-else-if="parents.length === 0" class="text-center bg-white py-6">
      <div class="text-center ma-10"><S3Image path="/parents/no_data.png" /></div>
      <p class="ma-6 font-weight-bold">保護者はまだ登録されていません。</p>
      <p class="ma-6 font-weight-medium">
        「保護者・子どもを登録・削除」から保護者情報を登録してください。
      </p>
    </div>
    <template v-else>
      <template v-for="(parent, i) in parents" :key="parent.id">
        <ParentCard
          v-model:is-selected-all="isSelectedAll"
          :parent="parent"
          :bulk-operation-mode="bulkOperationMode"
          :facility-id="facilityId"
          :class="i > 0 ? 'mt-1' : ''"
          @on-change-parent-selected="onChangeParentSelected"
          @on-change-child-selected="onChangeChildSelected"
          @on-click-disconnect-l-i-n-e="onClickDisconnectLINE"
          @on-click-disconnect-codmon="onClickDisconnectCodmon"
          @on-click-connect-codmon="onClickConnectCodmon"
        />
      </template>

      <template v-if="totalPages >= 2">
        <div class="d-flex justify-end align-center my-9">
          <span class="font-weight-medium mr-6"
            >保護者
            {{
              `${startIndex.toLocaleString()}-${endIndex.toLocaleString()}名 / ${parentTotal.total.count.parents.toLocaleString()}名`
            }}</span
          >
          <Pagination
            :total-pages="totalPages"
            :is-disabled-prev="currentPage === 1"
            :is-disabled-next="currentPage === totalPages"
            :handle-click-prev="handleClickPrevPage"
            :handle-click-next="handleClickNextPage"
            class="d-flex align-center"
            @handle-change-current-page="handleChangeCurrentPage"
          />
        </div>
      </template>
    </template>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, computed, onMounted, watch } from 'vue'
import ParentCard from 'pages/parents_page/areas/parent_card/ParentCard.vue'
import { Parent, ParentTotal, BulkOperationMode, Child } from 'pages/parents_page/model'
import S3Image from 'shared/components/s_3_image/S3Image.vue'
import Pagination from 'shared/components/pagination/Pagination.vue'
import { PaginationStore } from 'shared/components/pagination/pagination_store'
import { ParentsPageStore } from 'pages/parents_page/parents_page_store'

export default defineComponent({
  components: { ParentCard, S3Image, Pagination },
  props: {
    parents: {
      type: Array as PropType<Parent[]>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    parentTotal: {
      type: Object as PropType<ParentTotal>,
      required: true,
    },
    startIndex: {
      type: Number,
      required: true,
    },
    endIndex: {
      type: Number,
      required: true,
    },
    bulkOperationMode: {
      type: String as PropType<BulkOperationMode>,
      default: undefined,
    },
    facilityId: {
      type: Number,
      required: true,
    },
    isSelectedAll: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'onPageChange',
    'onChangeParentSelected',
    'onChangeChildSelected',
    'onClickDisconnectLINE',
    'onClickDisconnectCodmon',
    'onClickConnectCodmon',
    'handleGaEvent',
  ],
  // eslint-disable-next-line max-lines-per-function
  setup(props, { emit }) {
    const totalPages = computed(() => props.parentTotal.total.pages.parents)
    const currentPage = computed({
      get: () => PaginationStore.currentPage,
      set: (page: number) => {
        PaginationStore.updateCurrentPage(page)
      },
    })
    const handleChangeCurrentPage = (page: number) => {
      currentPage.value = page
      emit('onPageChange', currentPage.value)
      emit('handleGaEvent', 'paging_in_parent_list')
    }
    const handleClickNextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value += 1
        emit('onPageChange', currentPage.value)
        emit('handleGaEvent', 'paging_in_parent_list')
      }
    }
    const handleClickPrevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value -= 1
        emit('onPageChange', currentPage.value)
        emit('handleGaEvent', 'paging_in_parent_list')
      }
    }
    const onChangeParentSelected = (selectedParents: number[], parent: Parent) => {
      emit('onChangeParentSelected', selectedParents, parent)
    }
    const onChangeChildSelected = (selectedChildren: number[], parent: Parent) => {
      emit('onChangeChildSelected', selectedChildren, parent)
    }
    const onClickDisconnectLINE = (parent: Parent) => {
      emit('onClickDisconnectLINE', parent)
      emit('handleGaEvent', 'unsubscribe_line_of_parent')
    }
    const onClickDisconnectCodmon = (parent: Parent, child: Child) => {
      emit('onClickDisconnectCodmon', parent, child)
      emit('handleGaEvent', 'unlink_codmon')
    }
    const onClickConnectCodmon = (parent: Parent, child: Child) => {
      emit('onClickConnectCodmon', parent, child)
      emit('handleGaEvent', 'individual_link_with_codmon')
    }
    // NOTE: ParentsPageStoreの初期値とParentsPageStore.searchQueryの値を比較して、初期値と異なる場合はfalseを返す
    const isInitialSearchQuery =
      ParentsPageStore.searchQuery.class_id === '' &&
      ParentsPageStore.searchQuery.parent_name === '' &&
      ParentsPageStore.searchQuery.child_name === '' &&
      ParentsPageStore.searchQuery.line_connected === 'all'

    onMounted(() => {
      window.scrollTo(0, 0)
    })

    watch(currentPage, () => {
      window.scrollTo(0, 0)
    })

    return {
      currentPage,
      totalPages,
      handleChangeCurrentPage,
      handleClickNextPage,
      handleClickPrevPage,
      onChangeParentSelected,
      onChangeChildSelected,
      onClickDisconnectLINE,
      onClickDisconnectCodmon,
      onClickConnectCodmon,
      isInitialSearchQuery,
    }
  },
})
</script>
<style scoped lang="scss">
.w-100 {
  width: 100%;
}
</style>
