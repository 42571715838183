import { ExportInvoiceItemsPageStore } from 'pages/export_invoice_items_page/export_invoice_items_page_store'
import { CorporationExportInvoiceItemsService } from 'pages/export_invoice_items_page/services/corporation_export_invoice_items_service'
import { FacilityExportInvoiceItemsService } from 'pages/export_invoice_items_page/services/facility_export_invoice_items_service'
import { FacilityExportInvoiceItemSummaryService } from 'pages/export_invoice_items_page/services/facility_export_invoice_item_summary_service'
import { CorporationExportInvoiceItemSummaryService } from 'pages/export_invoice_items_page/services/corporation_export_invoice_item_summary_service'

export class ClearSearchConditionsService {
  /**
   * 検索条件を初期状態にリセットする関数
   * 初期状態にリセットしたら、初期条件で検索する
   */
  static clearConditions(isFacility: boolean): void {
    ExportInvoiceItemsPageStore.updateTargetItem('chargedMonth')
    ExportInvoiceItemsPageStore.updateSelectedInvoiceItems([])
    ExportInvoiceItemsPageStore.updateIncludeStatusType('include')
    ExportInvoiceItemsPageStore.updateSelectedFacilityId('all')
    ExportInvoiceItemsPageStore.updateInvoiceStatuses(['unpaid', 'paid', 'before_charge'])
    if (isFacility) {
      FacilityExportInvoiceItemsService.setInitialSearchDate()
      FacilityExportInvoiceItemsService.loadInitialData()
      FacilityExportInvoiceItemSummaryService.loadInitialData()
    } else {
      CorporationExportInvoiceItemsService.setInitialSearchDate()
      CorporationExportInvoiceItemsService.loadInitialData()
      CorporationExportInvoiceItemSummaryService.loadInitialData()
    }
  }
}
