<template>
  <div>
    <Button
      color="red"
      class="text-body-2 bulk-cancel-button"
      variant="outlined"
      @click="$emit('bulkUnlockInvoices')"
    >
      <v-icon size="small"> mdi-lock-open-outline </v-icon>&nbsp;
      <span class="font-weight-bold">すべての施設の請求締めを取り消す</span>
    </Button>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import Button from 'shared/components/Button.vue'

export default defineComponent({
  components: {
    Button,
  },
  emits: ['bulkUnlockInvoices'],
})
</script>
<style lang="scss" scoped>
.bulk-cancel-button {
  background-color: white;
}
</style>
