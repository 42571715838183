<template>
  <div class="d-flex">
    <div class="side-area pa-3">
      <AllFacilitiesSummaryCard />
    </div>
    <div class="flex-grow-1 main-area">
      <div class="d-flex action-area align-center px-3 pt-4">
        <FacilitySearchForm class="search-form" />
        <v-spacer />
        <TransferStatementDownloadButton class="ml-2" />
      </div>
      <div class="summary-list-area px-3 pt-2">
        <FacilityAccountingSummaryList />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import FacilityAccountingSummaryList from './FacilityAccountingSummaryList.vue'
import TransferStatementDownloadButton from './TransferStatementDownloadButton.vue'
import AllFacilitiesSummaryCard from './AllFacilitiesSummaryCard.vue'
import FacilitySearchForm from './FacilitySearchForm.vue'

export default defineComponent({
  components: {
    FacilitySearchForm,
    TransferStatementDownloadButton,
    FacilityAccountingSummaryList,
    AllFacilitiesSummaryCard,
  },
  setup() {
    return {}
  },
})
</script>
<style lang="scss" scoped>
$action-area-height: 50px;
$action-area-margin-bottom: 8px;
@import '../../../../assets/stylesheets/design/variables';
.search-form {
  width: 380px;
}
.main-area {
  background-color: $color-bg-white;
}
.action-area {
  height: $action-area-height;
  margin-bottom: $action-area-margin-bottom;
}
.summary-list-area {
  height: calc(100% - $action-area-height - $action-area-margin-bottom);
  overflow-y: auto;
}
.side-area {
  width: 300px;
  background-color: $color-bg-grey;
}
</style>
