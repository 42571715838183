import { reactive } from 'vue'
import { TransferStatement } from 'shared/models'
import { TransferStatementHistory } from 'pages/transfer_statement_print_page/model'

type TransferStatementPrintPageState = {
  isLoading: boolean
  transferStatement: TransferStatement
  transferStatementHistories: TransferStatementHistory[]
  selectedTransferStatementDate: string
  /** yyyy-MM-DD */
  startCalcPaidDate: string
  /** yyyy-MM-DD */
  endCalcPaidDate: string
}

const state = reactive<TransferStatementPrintPageState>({
  isLoading: false,
  transferStatement: {
    destination: {
      id: 0,
      school_name: '',
      responsible_person_name: '',
      formatted_postal_code: '',
      company_name: '',
      prefecture: {
        id: 0,
        name: '',
      },
      city: '',
      building: '',
      bank_name: '',
      bank_branch_name: '',
      bank_account_number: '',
      bank_account_holder_name: '',
    },
    registration_number: '',
    transferred_date: '',
    payment_date: '',
    settlement_fee: {
      standard: {
        collect_total: 0,
        settlement_fee_rate: '0',
        settlement_fee: 0,
      },
      enpay_wallet: {
        collect_total: 0,
        settlement_fee_rate: '0',
        settlement_fee: 0,
      },
    },
    enpay_service_charge: 0,
    transfer_amount: 0,
    sub_total_of_months: [],
    collect_total: 0,
    deduction_total: 0,
    deduction_total_without_tax: 0,
    sales_tax: 0,
    cumulative_unpaid_amount: 0,
    calculated_at: null,
    reason: '',
    memo: '',
    fee_gap_assist: 0,
    can_use_enpay_wallet: false,
    convenience_store_fee_applied: false,
    convenience_store_fee: 0,
    convenience_store_payment_count: 0,
    convenience_store_fee_total: 0,
    convenience_store_fee_total_with_tax: 0,
    convenience_store_fee_collect_total: 0,
    collect_total_without_cvs: 0,
    transfer_fee: 0,
    transfer_fee_setting_amount: 0,
    not_deducted_transfer_fee_amount_with_tax: 0,
  },
  transferStatementHistories: [],
  selectedTransferStatementDate: '',
  startCalcPaidDate: '',
  endCalcPaidDate: '',
})

export class TransferStatementPrintPageStore {
  static get isLoading(): boolean {
    return state.isLoading
  }

  static updateIsLoading(isLoading: boolean): void {
    state.isLoading = isLoading
  }

  static get transferStatement(): TransferStatement {
    return state.transferStatement
  }

  static updateTransferStatement(transferStatement: TransferStatement): void {
    state.transferStatement = transferStatement
  }

  static get transferStatementHistories(): TransferStatementHistory[] {
    return state.transferStatementHistories
  }

  static updateTransferStatementHistories(
    transferStatementHistories: TransferStatementHistory[]
  ): void {
    state.transferStatementHistories = transferStatementHistories
  }

  static get selectedTransferStatementDate(): string {
    return state.selectedTransferStatementDate
  }

  static updateSelectedTransferStatementDate(selectedTransferStatementDate: string): void {
    state.selectedTransferStatementDate = selectedTransferStatementDate
  }

  static get startCalcPaidDate(): string {
    return state.startCalcPaidDate
  }

  static updateStartCalcPaidDate(startCalcPaidDate: string): void {
    state.startCalcPaidDate = startCalcPaidDate
  }

  static get endCalcPaidDate(): string {
    return state.endCalcPaidDate
  }

  static updateEndCalcPaidDate(endCalcPaidDate: string): void {
    state.endCalcPaidDate = endCalcPaidDate
  }
}
