<template>
  <Button variant="outlined" :height="height" :width="width" :href="href" :target="target">
    <div class="d-flex flex-column align-center">
      <v-icon class="mt-1" size="large" :color="iconColor">{{ icon }}</v-icon>
      <div class="text-caption text-primary font-weight-bold">{{ text }}</div>
    </div>
  </Button>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import Button from 'shared/components/Button.vue'
import { WindowTarget } from 'shared/models'
export default defineComponent({
  components: {
    Button,
  },
  props: {
    height: {
      type: String,
      default: '52',
    },
    width: {
      type: String,
      default: '52',
    },
    icon: {
      type: String,
      required: true,
    },
    iconColor: {
      type: String,
      default: 'primary',
    },
    text: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: false,
    },
    target: {
      type: String as PropType<WindowTarget>,
      required: false,
    },
  },
})
</script>
