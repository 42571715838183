import { DialogService } from 'shared/services/dialog_service'
import CodmonApi, { LoadCodmonInvoiceResponseRow } from '../../../http/modules/codmon'
import { TopPageStore } from '../top_page_store'
import { SchoolParentService } from './school_parent_service'
import { SchoolService } from 'pages/top_page/services/school_service'
import CodmonResultMessage from 'pages/top_page/components/CodmonResultMessage.vue'
import { ScreenLoadingService } from 'shared/services/screen_loading_service'
import Axios from 'axios'

const warningMessageList = [
  'paid_invoice',
  'invalid_tax_flag',
  'invalid_minus_total_price',
] as const
type WarningMessageTypes = (typeof warningMessageList)[number]

const errorMessageList = ['invalid_codmon_empty_payment', 'empty_statements'] as const
type ErrorMessageTypes = (typeof errorMessageList)[number]

/**
 * コドモン連携に関するロジックを持ちます。
 */
export class CodmonService {
  static openCodmonInvoiceConfirmDialog(): void {
    DialogService.openConfirm({
      title: 'コドモンから請求データを連携',
      message: ['入力中のデータがある場合は、削除されます。', 'よろしいですか？'],
      onConfirm: () => CodmonService.loadCodmonInvoice(),
    })
  }

  /**
   * コドモン連携するようサーバーにリクエストを送ります。
   * リクエスト完了のレスポンスを受け取ったら、schoolParentsを同期し、結果ダイアログを表示します。
   */
  static async loadCodmonInvoice(): Promise<void> {
    ScreenLoadingService.showLoader()
    try {
      const response = (
        await CodmonApi.loadCodmonInvoices(
          SchoolService.getTargetFacilityId(),
          TopPageStore.targetYear + '-' + TopPageStore.targetMonth
        )
      ).data

      const messageHeader = CodmonService.generateCodmonExecSummary(response)
      const messageBody = response.map((row) => CodmonService.generateCodmonResultMessage(row))
      const messageFooter = CodmonService.generateCodmonResultSummary(response)

      await SchoolParentService.loadSchoolParents()
      DialogService.open({
        title: 'コドモン連携完了',
        body: {
          component: {
            name: CodmonResultMessage,
            bind: { messageHeader, messageBody, messageFooter },
          },
        },
        isShowCancel: false,
        modalWidth: 700,
      })
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        const message =
          error.response?.status === 403
            ? ['請求が締められているため、コドモン連携機能は利用できません。']
            : [
                'コドモン請求データ連携に失敗しました。',
                '何度も表示される場合は運営にお問い合わせください。',
              ]
        DialogService.openMessage({ title: 'コドモン連携失敗', message })
      }
    } finally {
      ScreenLoadingService.hideLoader()
    }
  }

  static generateCodmonExecSummary(response: LoadCodmonInvoiceResponseRow[]): string {
    const countInfo = response.find(
      (row) => row.type === 'info' && row.message === 'import_summary'
    )
    if (countInfo) return `確定済 ${countInfo.target_count} 件に対する取り込みを実施しました。`
    return ''
  }

  static generateCodmonResultMessage(row: LoadCodmonInvoiceResponseRow): string {
    switch (row.type) {
      case 'error':
        return CodmonService.buildErrorMessage(row)
      case 'warning':
        return CodmonService.buildWarningMessage(row)
      case 'report':
        return CodmonService.buildSuccessMessage(row)
      case 'info':
        return ''
      default:
        return '更新に失敗しました。'
    }
  }

  static generateCodmonResultSummary(response: LoadCodmonInvoiceResponseRow[]): string {
    if (response.some((row) => row.type === 'error')) {
      return 'コドモン請求データ連携に失敗しました。何度も表示される場合は運営にお問い合わせください。'
    }
    if (!response.some((row) => ['created', 'updated', 'deleted'].includes(row.message))) {
      return '前回の更新から変更はありませんでした。'
    }
    return 'コドモン請求データ連携に成功しました。'
  }

  private static buildWarningMessage(row: LoadCodmonInvoiceResponseRow): string {
    const message = row.message

    if (!CodmonService.isWarningMessageTypes(message)) {
      return row.child
        ? `${row.child} さんに対する請求の更新に失敗しました。`
        : '請求の更新に失敗しました。'
    }

    const hasDetailInfo = Boolean(row.child && row.invoice_item_name)
    const WarningMessageMap = new Map<WarningMessageTypes, string>([
      [
        'paid_invoice',
        `${row.child} さんへの請求は選択月の１回目の請求が支払い済みのため更新できません。`,
      ],
      [
        'invalid_tax_flag',
        hasDetailInfo
          ? `${row.child} さんに対する請求「 ${row.invoice_item_name} 」はコドモン上で税抜の金額になっているため連携できません。`
          : `コドモン上で税抜の金額になっているため連携できません。`,
      ],
      [
        'invalid_minus_total_price',
        `${row.child} さんに対する請求の総額がコドモン上でマイナスになっているため更新できません。`,
      ],
    ])

    const prefix = '【警告】'
    return `${prefix} ${WarningMessageMap.get(message)}`
  }

  private static buildErrorMessage(row: LoadCodmonInvoiceResponseRow): string {
    const message = row.message

    if (!CodmonService.isErrorMessageTypes(message)) {
      return '連携可能なデータがありません。'
    }

    const ErrorMessageMap = new Map<ErrorMessageTypes, string>([
      ['invalid_codmon_empty_payment', 'コドモンに確定済の請求済データがありません。'],
      ['empty_statements', 'コドモンに連携可能な請求データがありません。'],
    ])

    const prefix = '【エラー】❌'
    return `${prefix} ${ErrorMessageMap.get(message)}`
  }

  private static buildSuccessMessage(row: LoadCodmonInvoiceResponseRow): string {
    const hasDetailInfo = Boolean(row.child && row.invoice_item_name)

    return hasDetailInfo
      ? `${row.child} さんに対する請求「 ${row.invoice_item_name} 」を${
          row.message === 'deleted' ? '削除' : '更新'
        }しました。`
      : '請求を更新しました。'
  }

  private static isWarningMessageTypes(message: unknown): message is WarningMessageTypes {
    if (message === undefined || message === null) return false
    return warningMessageList.includes(message as WarningMessageTypes)
  }

  private static isErrorMessageTypes(message: unknown): message is ErrorMessageTypes {
    if (message === undefined || message === null) return false
    return errorMessageList.includes(message as ErrorMessageTypes)
  }
}
