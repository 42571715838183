import { HTTP } from '../common'

export type ShowResponse = {
  data: {
    qr_code_url: string
  }
}

export class LineQrCodeApi {
  /**
   * エンペイのLINEアカウントのお友だち登録用のURLを表すQRコードです。
   * OEMの施設の場合はそれに対応したLINEアカウントのQRコードが返ります。
   */
  static show(facilityId: number): Promise<ShowResponse> {
    return HTTP.get(`/facilities/${facilityId}/line_account_qr_code_url`)
  }
}
