<template>
  <div class="ml-3">
    <p class="mb-10">ご登録ありがとうございました。</p>
    <div class="text-h6">【重要】</div>
    <p>ご登録いただいたメールアドレスに後ほどメールをお送りいたします。</p>
    <p>この後の手続きに関する内容のため必ずご確認ください。</p>
    <p>
      メールが届かない場合は下記メールアドレスまでお問い合わせください。
      <br />
      cs@enpay.co.jp
    </p>
  </div>
</template>
