<template>
  <div>
    <div class="font-weight-bold fs-16" style="color: #0009; line-height: initial">
      <span>データ連携が完了しました。</span>
      <br />
      <span v-if="createdGroup.length !== 0">
        {{ createdGroup.length }}件の子どもを新規登録しました。
      </span>
      <span v-if="updatedGroup.length !== 0">
        {{ updatedGroup.length }}件の子どもを更新しました。
      </span>
      <span v-if="resultCodmonConnection.success_number.all === 0">
        前回の更新から変更はありませんでした。
      </span>
    </div>
    <template v-if="resultCodmonConnection.warning_number.all > 0">
      <v-divider class="my-6" />
      <div class="pb-2">
        <v-alert icon="mdi-alert" type="error" variant="outlined" class="mt-4">
          <span class="font-weight-bold"> エラーのため、連携されなかったデータがあります。 </span>
          <div style="color: #333">
            <div class="fs-16 mt-2 mb-6">
              <span v-if="resultCodmonConnection.warning_number.no_parent !== 0"
                >・{{ resultCodmonConnection.warning_number.no_parent }}件の子どもが、{{
                  getMessageByType('no_parent')
                }}</span
              >
              <br />
              <span v-if="resultCodmonConnection.warning_number.no_space_in_name !== 0">
                ・{{ resultCodmonConnection.warning_number.no_space_in_name }}件の子どもが、{{
                  getMessageByType('no_space_in_name')
                }}
              </span>
              <br />
              <span v-if="resultCodmonConnection.warning_number.graduated_child !== 0"
                >・{{ resultCodmonConnection.warning_number.graduated_child }}件の子どもが、{{
                  getMessageByType('graduated_child')
                }}</span
              >
              <br />
            </div>
            <template v-for="(child, index) in warningGroup" :key="index">
              <v-row :style="`margin: ${index === warningGroup.length - 1 ? '1px 0' : '-1px 0'}`">
                <v-col cols="3" :class="`border px-8 py-2 ${index === 0 ? 'grey-out' : ''}`">{{
                  index === 0 ? '子ども名' : `${child.name} さん`
                }}</v-col>
                <v-col
                  cols="9"
                  :class="`border px-4 py-2 ${index === 0 ? 'grey-out' : ''}`"
                  style="border-left: none !important"
                  >{{ index === 0 ? 'エラー原因' : getMessageByType(child.message) }}</v-col
                >
              </v-row>
            </template>
          </div>
        </v-alert>
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { LoadCodmonChildren } from 'pages/parents_page/model'
import ResultCodmonConnection, {
  ResultCodmonConnectionKey,
} from 'shared/consts/string/result_codmon_connection'

export default defineComponent({
  props: {
    resultCodmonConnection: Object as PropType<LoadCodmonChildren>,
  },
  setup(props) {
    const createdGroup = props.resultCodmonConnection.children.filter(
      (child: LoadCodmonChildren['children']) => child.message === 'created'
    )
    const updatedGroup = props.resultCodmonConnection.children.filter(
      (child: LoadCodmonChildren['children']) => child.message === 'updated'
    )
    const warningGroup = props.resultCodmonConnection.children.filter(
      (child: LoadCodmonChildren['children']) => child.type === 'warning'
    )

    const getMessageByType = (type: ResultCodmonConnectionKey) =>
      ResultCodmonConnection[type] ?? '予期せぬエラーで登録されませんでした。'

    return { createdGroup, updatedGroup, warningGroup, getMessageByType }
  },
})
</script>

<style scoped>
.grey-out {
  background: #f6f6f5;
  pointer-events: none;
}
.fs-16 {
  font-size: 16px;
}
.border {
  border: 1px solid #e0e0e0;
  border-right: 0px;
}
</style>
