import { AxiosResponse } from 'axios'
import { HTTP } from 'http/common'

export type UpdateParams = {
  email: string
}

type InputError = {
  input: { error: string }[]
}

type EmailError = {
  email: { error: string }[]
}

type Errors = InputError | EmailError

export type UpdateResponse = {
  school_account_id: number
  errors: {}
}

export type UpdateErrorResponse = {
  school_account_id: number
  errors: Errors
}

export class AccountSettingEmailApi {
  static update(
    params: UpdateParams
  ): Promise<AxiosResponse<UpdateResponse | UpdateErrorResponse>> {
    return HTTP.patch(`/corporation_accounts/me/settings/email`, {
      email: params.email,
    })
  }
}
