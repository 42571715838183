import { HTTP } from '../common'
import { AxiosResponse } from 'axios'

export type Parent = {
  id: number
  first_name: string
  last_name: string
}

export type Child = {
  first_name: string
}

export type Prefecture = {
  name: string
}

export type School = {
  id: number
  school_name: string
  responsible_person_name: string
  postal_code: string
  company_name: string
  prefecture: Prefecture
  city: string
  building: string
}
export type InvoiceItem = {
  name: string
  count: number
  unit_price: number
  is_convenience_store_fee: boolean
}

export type InvoiceListItem = {
  child: Child
  sub_total: number
  invoice_items: InvoiceItem[]
}

export type ChildlessInvoiceItem = {
  subtotal: number
  invoice_items: InvoiceItem[]
}

export type Receipt = {
  invoice_id: number
  convenience_store_fee_invoice?: InvoiceItem
  childless_invoice_items: ChildlessInvoiceItem
  children_with_invoice_items: InvoiceListItem[]
  paid_at: Date
  school_parent: Parent
  target_year: string
  target_month: string
  total_price: number
  school: School
}

export type ShowResponse = {
  data: {
    receipt: Receipt
  }
}

export class ReceiptApi {
  static show(facilityId: number, invoiceId: number): Promise<ShowResponse> {
    return HTTP.get(`/facilities/${facilityId}/invoices/${invoiceId}/receipt`)
  }

  static create(invoiceId: number, email: string): Promise<AxiosResponse> {
    HTTP.defaults.baseURL = '/line/api/v1/'
    return HTTP.post(`/invoices/${invoiceId}/receipt.pdf?email=${email}`)
  }
}
