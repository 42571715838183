<template>
  <div class="d-flex">
    <div class="d-flex justify-center align-center mx-2">
      <a :href="linkTo" class="d-flex justify-between align-center">
        <S3Image class="logo-image" :path="logoImagePath" />
        <template v-if="mode === 'corporation'">
          <div class="text-subtitle-2 text-white bg-black px-2 ml-3 rounded">法人</div>
        </template>
      </a>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import { AppStore } from 'shared/app_store'
import { OperationMode } from '../models'
import S3Image from 'shared/components/s_3_image/S3Image.vue'
import { OemPartnerService } from 'shared/services/oem_partner_service'
import { Mode } from 'shared/models'

type LogoImagePropType = {
  linkTo: string
  mode: OperationMode
}

/**
 * AppStoreのenv,oemPartnerをロードしておく必要があります
 */
export default defineComponent<LogoImagePropType>({
  components: { S3Image },
  props: {
    linkTo: String,
    mode: {
      type: String as PropType<Mode>,
      required: true,
    },
  },
  setup(props) {
    if (props.mode === 'corporation') {
      OemPartnerService.loadForCorporation()
    } else {
      OemPartnerService.loadForFacility()
    }
    const logoImagePath = computed(() => AppStore.oemPartner.logo_image_path)
    return {
      logoImagePath,
    }
  },
})
</script>
<style lang="scss" scoped>
.logo-image {
  height: 28px;
}
</style>
