<script setup lang="ts">
import Liff from '@line/liff'
import { visit } from 'shared/utils/history'
import { isAxiosError, LineSessionApi } from 'http/modules/line_session'

const props = defineProps<{
  liffId: string
}>()
// NOTE: TypeScriptアップデート時にtsconfig.jsonを変更してtop-level awaitを有効にした時にload()を消す処理に書き換える
const liffInit = async () => {
  await Liff.init({
    liffId: props.liffId,
  })

  const lineIdToken = (await Liff.getIDToken()) ?? ''
  const {
    data: { return_to },
  } = await (async () => {
    try {
      return await LineSessionApi.create(lineIdToken)
    } catch (e) {
      if (isAxiosError(e) && e.response?.status === 422) {
        return e.response
      }
      throw Error('line session could not be created.')
    }
  })()

  visit(return_to)
}

liffInit()
</script>
