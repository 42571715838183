<template>
  <div class="d-flex justify-center year-month-select-area pt-8 pb-7">
    <EditableYearMonthRangeLabel />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import EditableYearMonthRangeLabel from './EditableYearMonthRangeLabel.vue'

export default defineComponent({
  components: { EditableYearMonthRangeLabel },
})
</script>
<style lang="scss" scoped>
@import '../../../../assets/stylesheets/design/variables';
.year-month-select-area {
  background-color: $color-bg;
}
</style>
