<template>
  <div v-show="show" id="overlay-loading" class="overlay is-active is-full-page">
    <div class="background"></div>
    <div class="icon">
      <S3Image :path="'/loading/loading.png'" class="pc" />
      <S3Image :path="'/loading/loading_3x.png'" class="sp" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { ScreenLoadingService } from 'shared/services/screen_loading_service'
import S3Image from 'shared/components/s_3_image/S3Image.vue'

const show = computed(() => ScreenLoadingService.show)
</script>

<style lang="scss" scoped>
.overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  align-items: center;
  display: none;
  justify-content: center;
  overflow: hidden;
  z-index: 9999;
}
.overlay.is-active {
  display: flex;
}
.overlay.is-full-page {
  z-index: 9999;
  position: fixed;
}
.overlay .background {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background: rgb(255, 255, 255);
  opacity: 0.5;
}
.overlay .icon {
  position: relative;
  .pc {
    display: block;
    width: 80px;
    height: 80px;
  }
  .sp {
    display: none;
  }
  @media only screen and (max-width: 480px) {
    .pc {
      display: none;
    }
    .sp {
      display: block;
      width: 80px;
      height: 80px;
    }
  }
}
</style>
