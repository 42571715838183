<template>
  <div class="transfer-statement px-5 py-6" style="position: relative">
    <div class="d-flex justify-space-between px-4 mb-5">
      <h1 class="page-title">振込明細書</h1>
      <S3Image class="logo" :path="logoImagePath" alt="enpayのロゴ" />
    </div>
    <div class="address-area px-4">
      <div id="school-address" class="school-address">
        <div class="postal-code">
          〒&nbsp;{{ transferStatement.destination.formatted_postal_code }}
        </div>
        <div class="address">
          {{ transferStatement.destination.prefecture.name }}{{ transferStatement.destination.city
          }}{{ transferStatement.destination.building }}
        </div>
        <div class="company-name">
          {{ transferStatement.destination.company_name }}
        </div>
        <div class="responsible-person-name">
          {{ transferStatement.destination.responsible_person_name }}&nbsp;様
        </div>
      </div>
      <div class="enpay-address">
        <template v-if="transferStatement.calculated_at !== null">
          <span data-testId="calculated_at_text">
            発行日：
            {{ getFormatDate(transferStatement.calculated_at, 'YYYY年MM月DD日') }}
          </span>
          <span data-testId="transferred_date_text">
            取引年月日：
            {{ getFormatDate(transferStatement.transferred_date, 'YYYY年MM月01日') }}
          </span>
        </template>
        <template v-else>
          <span data-testId="transferred_date_text">
            発行日：
            {{ getFormatDate(transferStatement.transferred_date, 'YYYY年MM月01日') }}
          </span>
        </template>
        <span>登録番号：{{ transferStatement.registration_number }}</span>
        <span>株式会社エンペイ</span>
        <span>〒 108-6022</span>
        <span>東京都港区港南二丁目15番1号</span>
        <span>品川インターシティA棟 22階 SPROUND</span>
        <S3Image class="enpay-address_stamp" path="/logos/enpay_stamp.png" />
      </div>
    </div>

    <div class="content px-4">
      <div class="message">
        <span class="name mr-4">{{ transferStatement.destination.company_name }}</span>
        <span class="name">{{ transferStatement.destination.school_name }}</span>
        <p class="mt-3">
          平素は格別のお引き立てを賜り誠にありがとうございます。お振込につきまして以下のとおりご案内申し上げます。
        </p>
      </div>
      <div class="detail mt-4">
        <div class="transfer-detail">
          <div class="total_date">
            <span class="label">振込予定日：</span>
            <span class="date" data-testId="payment_date_text">
              {{
                transferStatement.transfer_amount > 0
                  ? getFormatDate(transferStatement.payment_date, 'YYYY年MM月DD日')
                  : 'ー'
              }}
            </span>
          </div>
          <p class="bank-information memo">
            振込先：<span class="bank-name"
              >{{ transferStatement.destination.bank_name }}&nbsp;</span
            ><span class="bank-branch-name"
              >{{ transferStatement.destination.bank_branch_name }}&nbsp;</span
            ><span>普通&nbsp;</span
            ><span class="bank-account-number"
              >{{ transferStatement.destination.bank_account_number }}&nbsp;</span
            ><span class="bank-account-holder-name">{{
              transferStatement.destination.bank_account_holder_name
            }}</span>
          </p>
        </div>
        <div class="collection-detail">
          <div class="total_amount">
            <span class="label">振込金額：</span>
            <span class="amount" data-testId="transfer_amount_text">
              &yen;&nbsp;{{ commaSeparate(Math.max(transferStatement.transfer_amount, 0)) }}
              <span class="is-c">…C</span>
            </span>
          </div>
          <p v-if="transferStatement.transfer_amount < 0" class="memo" data-testId="deficit_text">
            不足分の{{
              commaSeparate(transferStatement.transfer_amount)
            }}円は翌月以降自動精算されます。
          </p>
        </div>
      </div>
      <div class="detail mb-6">
        <div data-testid="collection-detail" class="collection-detail collection-detail-table-area">
          <div>
            <h2 class="header">
              ① 集金明細
              <p class="collection-period pl-1">
                ({{ getFormatDate(startCalcPaidDate, 'YYYY年MM月DD日') }} -
                {{ getFormatDate(endCalcPaidDate, 'YYYY年MM月DD日') }} 集金合計額)
              </p>
            </h2>
            <h3>集金の内訳</h3>
            <table class="body">
              <tr
                v-for="(subTotalOfMonth, index) in transferStatement.sub_total_of_months"
                :key="index"
              >
                <th>{{ getFormatDate(subTotalOfMonth.paid_year_month + '-01', 'YYYY年M月') }}分</th>
                <td>¥ {{ commaSeparate(subTotalOfMonth.amount_of_month) }}</td>
              </tr>
              <tr v-if="hasCvsPaymentFee">
                <th>コンビニ支払い手数料</th>
                <td>
                  ¥ {{ commaSeparate(transferStatement.convenience_store_fee_total_with_tax) }}
                </td>
              </tr>
            </table>
          </div>
          <div class="d-flex align-end">
            <div>
              <table class="w-100">
                <tr class="total-row solid-border">
                  <th>集金合計 …A</th>
                  <td>&yen;&nbsp;{{ commaSeparate(transferStatement.collect_total) }}</td>
                </tr>
              </table>
              <p class="memo">
                ※ 上記金額は、{{ getFormatDate(startCalcPaidDate, 'YYYY年MM月DD日') }}から
                {{
                  getFormatDate(endCalcPaidDate, 'YYYY年MM月DD日')
                }}までに、利用者様がお支払いされた合計額です。<br />{{
                  nextCalcStartDate
                }}以降にお支払いされた金額はお支払日の翌月の振込に含まれます。
              </p>
            </div>
          </div>
        </div>
        <div class="transfer-detail" style="min-height: 540px">
          <div data-testid="payment-method-detail" class="mb-4">
            <h2 class="header">② 決済手段別の内訳</h2>
            <table class="body">
              <tr>
                <th>クレジットカード・電子決済<span class="appendix">…A1</span></th>
                <td>&yen;&nbsp;{{ commaSeparate(transferStatement.collect_total_without_cvs) }}</td>
              </tr>
              <tr class="no-border">
                <th colspan="2">コンビニ払い</th>
              </tr>
              <tr
                :class="
                  hasCvsPaymentFee || !transferStatement.can_use_enpay_wallet
                    ? { 'no-border': true }
                    : {}
                "
              >
                <th class="indent">請求分<span class="appendix">…A2</span></th>
                <td>
                  &yen;&nbsp;{{
                    commaSeparate(transferStatement.convenience_store_fee_collect_total)
                  }}
                </td>
              </tr>
              <template v-if="hasCvsPaymentFee">
                <tr :class="transferStatement.can_use_enpay_wallet ? {} : { 'no-border': true }">
                  <th class="indent">手数料</th>
                  <td>
                    &yen;&nbsp;{{
                      commaSeparate(transferStatement.convenience_store_fee_total_with_tax)
                    }}
                  </td>
                </tr>
              </template>
              <tr v-if="transferStatement.can_use_enpay_wallet" class="no-border">
                <th>enpayウォレット<span class="appendix">…A3</span></th>
                <td>
                  &yen;&nbsp;{{
                    commaSeparate(transferStatement.settlement_fee.enpay_wallet.collect_total)
                  }}
                </td>
              </tr>
            </table>
          </div>
          <div data-testid="deduction-detail" class="transfer-detail">
            <h2 class="header">③ 控除額</h2>
            <table class="body">
              <tr class="no-border">
                <th>enpayサービス利用料</th>
                <td>&yen;&nbsp;{{ commaSeparate(transferStatement.enpay_service_charge) }}</td>
              </tr>
              <tr
                :class="
                  transferStatement.can_use_enpay_wallet || hasCvsPaymentFee || isEnableTransferFee
                    ? { 'no-border': true }
                    : { 'solid-border': true }
                "
                data-testid="settlement-fee-row"
              >
                <th data-testid="settlement-fee">
                  決済手数料<span class="appendix"
                    >…(A1+A2) ✕
                    {{ transferStatement.settlement_fee.standard.settlement_fee_rate }}%</span
                  >
                </th>
                <td>
                  &yen;&nbsp;{{
                    commaSeparate(transferStatement.settlement_fee.standard.settlement_fee)
                  }}
                </td>
              </tr>
              <tr
                v-if="transferStatement.can_use_enpay_wallet"
                :class="
                  hasCvsPaymentFee || isEnableTransferFee
                    ? { 'no-border': true }
                    : { 'solid-border': true }
                "
                data-testid="enpay-wallet-settlement-fee-row"
              >
                <th>
                  enpayウォレット 決済手数料<span class="appendix"
                    >…A3 ✕
                    {{ transferStatement.settlement_fee.enpay_wallet.settlement_fee_rate }}%<br
                  /></span>
                </th>
                <td>
                  &yen;&nbsp;{{
                    commaSeparate(transferStatement.settlement_fee.enpay_wallet.settlement_fee)
                  }}
                </td>
              </tr>
              <tr
                v-if="hasCvsPaymentFee"
                :class="
                  transferStatement.transfer_fee_setting_amount > 0
                    ? { 'no-border': true }
                    : { 'solid-border': true }
                "
                data-testid="convenience-store-fee-row"
              >
                <th>
                  コンビニ支払い手数料<span class="appendix ml-1"
                    >{{ commaSeparate(transferStatement.convenience_store_payment_count) }}件</span
                  ><span class="appendix">
                    ({{ commaSeparate(transferStatement.convenience_store_fee) }}円/件)
                  </span>
                </th>
                <td>
                  &yen;&nbsp;{{ commaSeparate(transferStatement.convenience_store_fee_total) }}
                </td>
              </tr>
              <tr v-if="isEnableTransferFee" class="solid-border" data-testid="transfer-fee-row">
                <th>振込手数料</th>
                <td>&yen;&nbsp;{{ commaSeparate(transferStatement.transfer_fee) }}</td>
              </tr>
              <tr>
                <th>小計（10%対象）</th>
                <td data-testId="deduction_total_without_tax_text">
                  &yen;&nbsp;{{ commaSeparate(transferStatement.deduction_total_without_tax) }}
                </td>
              </tr>
              <tr>
                <th>消費税</th>
                <td>&yen;&nbsp;{{ commaSeparate(transferStatement.sales_tax) }}</td>
              </tr>
              <template v-if="transferStatement.cumulative_unpaid_amount > 0">
                <tr>
                  <th>累積未精算額</th>
                  <td>
                    &yen;&nbsp;{{ commaSeparate(transferStatement.cumulative_unpaid_amount) }}
                  </td>
                </tr>
              </template>
            </table>
            <p
              v-if="transferStatement.cumulative_unpaid_amount > 0"
              class="memo solid-border py-1 mt-0 mb-2"
            >
              ※累積未精算額は、前月以前に控除額が集金額より多くなり未精算になっていた金額を表します。
            </p>
            <table class="w-100">
              <tr
                class="total-row is-b"
                :class="{
                  'no-border': transferStatement.not_deducted_transfer_fee_amount_with_tax > 0,
                  'solid-border': transferStatement.not_deducted_transfer_fee_amount_with_tax <= 0,
                }"
              >
                <th>控除額合計（税込）…B</th>
                <td>
                  &yen;&nbsp;{{
                    commaSeparate(
                      transferStatement.deduction_total + transferStatement.cumulative_unpaid_amount
                    )
                  }}
                </td>
              </tr>
            </table>
            <table
              v-if="transferStatement.not_deducted_transfer_fee_amount_with_tax > 0"
              class="body"
            >
              <tr class="solid-border">
                <th>B - 振込手数料（税込）…B'</th>
                <td>
                  &yen;&nbsp;{{
                    commaSeparate(
                      transferStatement.deduction_total +
                        transferStatement.cumulative_unpaid_amount -
                        transferStatement.not_deducted_transfer_fee_amount_with_tax
                    )
                  }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div
        v-if="
          transferStatement.transfer_amount > 0 &&
          transferStatement.not_deducted_transfer_fee_amount_with_tax <= 0
        "
        class="detail w-50 ml-auto px-4 pt-5 pb-6 justify-end"
        style="background-color: #eef0f3 !important; -webkit-print-color-adjust: exact"
      >
        <table class="transfer-detail">
          <tr class="total-row no-border">
            <th>控除後金額 …C（A-B）</th>
            <td>&yen;&nbsp;{{ commaSeparate(transferStatement.transfer_amount) }}</td>
          </tr>
        </table>
      </div>
      <div
        v-else
        class="detail px-4 py-6 align-center"
        style="background-color: #eef0f3 !important; -webkit-print-color-adjust: exact"
      >
        <p v-if="transferStatement.transfer_amount <= 0" class="collection-detail memo mt-0">
          ※控除後金額がマイナスになる場合、振込金額は0円になり、振込は行われません。控除額は翌月以降の振込明細書に「累積未精算額」として表示され、集金合計から差し引かれます。
        </p>
        <p
          v-else-if="transferStatement.not_deducted_transfer_fee_amount_with_tax > 0"
          class="collection-detail memo mt-0"
        >
          ※控除後金額が当社所定の金額に満たないため、<span class="alert"
            >今月の振込手数料は、翌月にお支払いいただきます。</span
          >翌月の振込明細書に累積未精算額として組み入れられ、集金合計から差し引かれます。
        </p>
        <table class="transfer-detail">
          <tr class="total-row no-border">
            <th v-if="transferStatement.not_deducted_transfer_fee_amount_with_tax <= 0">
              控除後金額 …C（A-B）
            </th>
            <th v-else>控除後金額 …C（A-B'）</th>
            <td>&yen;&nbsp;{{ commaSeparate(transferStatement.transfer_amount) }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { TransferStatement } from 'shared/models'
import { FilterService } from 'shared/services/filter_service'
import { AppStore } from 'shared/app_store'
import S3Image from 'shared/components/s_3_image/S3Image.vue'
import { getFormatDate, getAddDay } from 'shared/utils/date'

type TransferStatementAreaProps = {
  transferStatement: Omit<TransferStatement, 'reason' | 'memo'>
  startCalcPaidDate: string
  endCalcPaidDate: string
}

const props = defineProps<TransferStatementAreaProps>()
const logoImagePath = computed(() => AppStore.oemPartner.logo_image_path)
const nextCalcStartDate = computed(() =>
  getFormatDate(getAddDay(props.endCalcPaidDate, 1), 'YYYY年MM月DD日')
)

const commaSeparate = (money: number) => {
  if (money === undefined) {
    return ''
  }
  return FilterService.commaSeparate(money)
}

// 振込明細の対象の支払い日時点でコンビニ支払い手数料が発生していなくても、前月以前に送信され、コンビニ支払い手数料が存在するケースを考慮
const hasCvsPaymentFee = computed(
  () =>
    props.transferStatement.convenience_store_fee_applied ||
    props.transferStatement.convenience_store_payment_count > 0
)

// transfer_fee_setting_amountは振込手数料が有効化ならば0以上の値を持つ(請求書対応であっても対象なら有効化はされる)
const isEnableTransferFee = computed(() => props.transferStatement.transfer_fee_setting_amount > 0)
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/design/_variables.scss';

// 297×420mm
* {
  font-weight: 500;
}

.transfer-statement {
  background-color: #fff;
  color: #000;
  font-size: 16px;
  width: 230mm;
  min-height: 310mm;
  padding: 20mm 10mm;
  margin: 0 auto;
}

.page-title {
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
}

.logo {
  width: 50mm;
}

.address-area {
  line-height: 16px;
  position: relative;
  z-index: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0;
}
.transfer-statement .school-address {
  border: 1px solid #c0c0c0;
  font-size: 0.9em;
  padding: 5mm;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100mm;
  -ms-flex: 0 0 100mm;
  flex: 0 0 100mm;
  width: 100mm;
}
.transfer-statement .school-address .address {
  margin-top: 3mm;
}
.transfer-statement .school-address .company-name {
  margin-top: 3mm;
}
.transfer-statement .school-address .responsible-person-name {
  font-size: 1.2em;
  margin-top: 3mm;
}

.enpay-address > span {
  display: grid;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 4px;
}
.enpay-address_stamp {
  max-width: 15mm;
  position: absolute;
  top: 80px;
  right: 0px;
  z-index: -1;
}

.content .message .name {
  font-size: 24px;
}
.transfer-statement .content .message p {
  font-size: 0.9em;
}

.transfer-statement .content .detail {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin: 8mm 0 0;
}
.transfer-statement .content .detail .transfer-detail,
.transfer-statement .content .detail .collection-detail {
  width: 101mm;
}

.total_date,
.total_amount {
  flex: 0 1 50%;
  border-bottom: 1px solid #000;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 2mm;
}
.label {
  font-weight: bold;
  vertical-align: middle;
}
.date,
.amount {
  color: $color-main;
  font-size: 2em;
  .is-c {
    color: $color-main;
    font-size: 45%;
    padding: 0 0 0 4mm;
    vertical-align: baseline;
  }
}
.transfer-statement .content .bank-information {
  margin: 6mm 0 0;
}

.collection-detail-table-area {
  display: grid;
  row-gap: 24px;
  align-content: space-between;
  min-height: 540px;
}
.content .detail h2.header {
  justify-content: start;
  font-size: 16px;
  padding: 8px 6px;
  margin-bottom: 8px;
  color: #fff;
  text-align: left;
  font-weight: bold;
  border: 1px solid $color-main;
  // 設定にかかわらず背景色を印刷するための設定です。
  // 非標準ですがChrome、Edgeなどで有効です。
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  background-color: $color-main;
}
.content .detail h2.header .collection-period {
  font-size: 12px;
  margin: 0;
}
.content .detail h3 {
  font-size: 14px;
  color: #636363;
  line-height: 20px;
  padding: 4px 0;
}
.appendix {
  padding-left: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #636363;
}

.transfer-statement .content .detail table.body {
  border: none;
  width: 100%;
}
.content .detail tr {
  border-bottom: 1px dashed #aaa;
}
.content .detail table.body tr {
  color: black;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.content .detail .no-border {
  border-bottom: none;
}
.content .detail .no-border th {
  padding-bottom: 0;
}
.content .detail .no-border td {
  padding-bottom: 0;
}
.content .detail .solid-border {
  border-bottom: 1px solid #aaa;
}

.transfer-statement .content table th {
  text-align: left;
  padding: 4px 0;
}
.content .detail table.body th {
  color: black;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.content .detail table th {
  border-bottom: none;
}
.content .detail table.body th.indent {
  padding-left: 5mm;
}
.content .detail tr.total-row th {
  font-size: 16px;
  color: $color-main;
}

.transfer-statement .content table td {
  text-align: right;
  max-width: 10em;
  padding: 4px 0;
}
.content .detail table.body td {
  color: black;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.content .detail tr.total-row td {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  color: $color-main;
}

.content .detail table td {
  border-bottom: none;
}

.content .detail p.memo {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  margin: 3mm 0 0;
}

.content .detail p.memo span.alert {
  font-weight: bold;
  color: red;
  text-decoration: underline;
}
</style>
