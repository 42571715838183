import { CsvImportColumnCandidate } from 'shared/components/import_panel/models'
import { HTTP } from '../common'

export type ShowResponse = {
  data: CsvImportColumnCandidate[]
}

export class InvoiceRegisterColumnCandidatesApi {
  static show(facilityId: number): Promise<ShowResponse> {
    return HTTP.get(`/facilities/${facilityId}/invoice_register_column_candidates`)
  }
}
