import { ref } from 'vue'

export default {
  setup() {
    const password = ref('')
    const password_type = ref('password')
    const password_confirmation = ref('')
    const password_confirmation_type = ref('password')

    const showPassword = () => {
      password_type.value = 'text'
    }
    const hidePassword = () => {
      password_type.value = 'password'
    }
    const showPasswordConfirmation = () => {
      password_confirmation_type.value = 'text'
    }
    const hidePasswordConfirmation = () => {
      password_confirmation_type.value = 'password'
    }

    return {
      password,
      password_type,
      password_confirmation,
      password_confirmation_type,
      showPassword,
      hidePassword,
      showPasswordConfirmation,
      hidePasswordConfirmation,
    }
  },
}
