<template>
  <Button
    class="import-button"
    :disabled="isDisabled"
    :width="width"
    variant="outlined"
    @click="jumpToImportPage()"
  >
    <v-icon start> mdi-upload </v-icon>
    請求データ一括取り込み
  </Button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { InvoiceService } from '../services/invoice_service'
import Button from 'shared/components/Button.vue'

export default defineComponent({
  components: {
    Button,
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      required: false,
    },
  },
  setup() {
    const jumpToImportPage = () => {
      InvoiceService.jumpToImportPage()
    }
    return {
      jumpToImportPage,
    }
  },
})
</script>
<style lang="scss" scoped>
.import-button {
  background-color: white;
}
</style>
