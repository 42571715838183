<template>
  <div class="bg-white rounded-lg px-2 py-4">
    <div>
      <PaymentRateChart class="d-flex justify-center" />
    </div>
    <v-divider class="mb-3"></v-divider>
    <AmountByStatusSummary v-if="isShowNewTopPageFormat" />
    <LegacyAmountByStatusSummary v-else />
  </div>
</template>
<script lang="ts">
import { computed, defineComponent } from 'vue'
import AmountByStatusSummary from './AmountByStatusSummary.vue'
import LegacyAmountByStatusSummary from './LegacyAmountByStatusSummary.vue'
import PaymentRateChart from './PaymentRateChart.vue'
import { TopPageStore } from 'pages/top_page/top_page_store'

export default defineComponent({
  components: {
    AmountByStatusSummary,
    LegacyAmountByStatusSummary,
    PaymentRateChart,
  },
  setup() {
    const isShowNewTopPageFormat = computed(() => TopPageStore.isShowNewTopPageFormat)

    return { isShowNewTopPageFormat }
  },
})
</script>
