import { Ref, ref } from 'vue'
import { allCorporationMenu, allFacilityMenu } from 'shared/consts/menu_const'
import { MenuDirectory, Menu } from 'shared/models'
import rfdc from 'rfdc'
import { AvailableFacility } from 'http/modules/facility'
import { AuthService } from 'shared/services/auth_service'

const isDirectory = (arg: any): arg is MenuDirectory => arg.menus !== undefined

/**
 * 施設メニュー用。メニューurlの前に文字列を付加する。
 * メニューは最大2階層であることが前提
 * @param menu
 * @param prefix /facilities/{facilityId} など、親のresource
 */
const prependStringToMenuUrls = (
  menu: Array<Menu | MenuDirectory>,
  prefix: string = ''
): Array<Menu | MenuDirectory> =>
  menu.map((directoryOrMenu) => {
    if (isDirectory(directoryOrMenu)) {
      return {
        ...directoryOrMenu,
        menus: directoryOrMenu.menus.map((childMenu) => ({
          ...childMenu,
          url: prefix + childMenu.url,
        })),
      }
    }
    return { ...directoryOrMenu, url: prefix + directoryOrMenu.url }
  })

/**
 * 現在選択（表示）されているメニュー（画面）のIDをurlから割り出す
 * @param availableMenu
 * @param prefix facilities/{facilityId} など、画面のpathの前につくもの
 */
const findSelectedMenuId = (
  availableMenu: Array<Menu | MenuDirectory>,
  prefix: string = ''
): string => {
  const selectedMenuElement = availableMenu
    .flatMap((directoryOrMenu) => {
      if (isDirectory(directoryOrMenu)) return directoryOrMenu.menus
      return directoryOrMenu
    })
    .find((directoryOrMenu) => {
      if (isDirectory(directoryOrMenu)) return false
      return prefix + directoryOrMenu.url === location.pathname
    })
  return selectedMenuElement?.id || ''
}

/**
 * @param facilityId facilityIdは必須。rootPathを生成するのに必要。
 */
export const useFacilityHeader = (facilityId: number) => {
  const rootPath = `/facilities/${facilityId}`
  // 現状、権限によって施設のメニューは絞っていないため、全てのメニューを返す
  // turbolinks対策（jsのstateが残る）のため、cloneします。
  const clone = rfdc()
  const availableMenu: Ref<Array<MenuDirectory | Menu>> = ref(clone(allFacilityMenu))
  const selectedMenuId: Ref<string | undefined> = ref()
  const availableFacilities: Ref<AvailableFacility[]> = ref([])
  const hasCorporationPermission: Ref<boolean> = ref(false)
  const loadMenuData = async () => {
    availableFacilities.value = await AuthService.getAvailableFacilities('facility')
    // 法人画面の権限がない場合、404が返ってきてthrowされてしまうので、helppageUrlのloadよりも後ろに置く
    hasCorporationPermission.value = await AuthService.hasCorporationPermission()
  }
  selectedMenuId.value = findSelectedMenuId(availableMenu.value, rootPath)
  availableMenu.value = prependStringToMenuUrls(availableMenu.value, rootPath)
  loadMenuData()

  return {
    selectedMenuId,
    availableMenu,
    currentFacilityId: facilityId,
    availableFacilities,
    hasCorporationPermission,
  }
}

export const useCorporationHeader = () => {
  //  全てのメニューから、権限を加味して利用可能なメニューに絞ったもの
  //  今のところ権限による差はないため、APIアクセスはオミット
  //  turbolinks対策（jsのstateが残る）のため、cloneします。

  const clone = rfdc()
  const availableMenu: Ref<Array<MenuDirectory | Menu>> = ref(clone(allCorporationMenu))
  const selectedMenuId: Ref<string> = ref('')
  selectedMenuId.value = findSelectedMenuId(availableMenu.value)
  const availableFacilities: Ref<AvailableFacility[]> = ref([])
  const loadMenuData = async () => {
    availableFacilities.value = await AuthService.getAvailableFacilities('corporation')
  }
  loadMenuData()

  return { selectedMenuId, availableMenu, availableFacilities }
}
