<template>
  <VForm ref="emailPanel">
    <div class="ml-3">
      <v-checkbox v-model="formValue.signUpCheckbox" :rules="checkboxRules">
        <template #label>
          <div>
            <LinkText :is-open-new-tab="true" :link-url="termsOfServiceUrl">利用規約</LinkText>
            及び
            <LinkText :is-open-new-tab="true" :link-url="privacyPolicyUrl">
              プライバシーポリシー
            </LinkText>
            に同意します。
          </div>
        </template>
      </v-checkbox>
      <v-checkbox v-model="formValue.signUpPolicyAgainstAntiSocialForces" :rules="checkboxRules">
        <template #label>
          利用規約 に定める「反社会的勢力」に該当せず、「反社会的勢力」との関係も有しません。
        </template>
      </v-checkbox>
      <LinkText is-open-new-tab :link-url="externalTransmissionsUrl" class="mb-6">
        利用者情報の外部送信について
      </LinkText>
      <v-text-field
        v-model="formValue.email"
        label="メールアドレス"
        :rules="emailRules"
        class="mb-3 mt-4"
      ></v-text-field>
      <v-text-field
        v-model="formValue.confirmationEmail"
        label="確認用メールアドレス"
        :rules="confirmationEmailRules"
      ></v-text-field>
      <div v-show="false">{{ isInitial.emailPanel }}</div>
    </div>
  </VForm>
</template>

<script setup lang="ts">
type FormInstance = {
  validate: () => boolean
  reset: () => void
}
import { ref, computed, watch } from 'vue'
import LinkText from 'shared/components/LinkText.vue'
import { CorporationValidatorsService } from 'pages/corporation_apply_page/services/corporation_validators_service'
import { CorporationApplyPageStore } from 'pages/corporation_apply_page/corporation_apply_page_store'
import { VForm } from 'vuetify/components'
import ExternalLink from 'shared/consts/external_link'

defineProps<{
  termsOfServiceUrl: string
  privacyPolicyUrl: string
}>()

const emailPanel = ref<(typeof VForm & FormInstance) | null>(null)

const formValue = computed(() => CorporationApplyPageStore.formValue)
const isInitial = computed(() => {
  if (!CorporationApplyPageStore.initializedState.emailPanel) {
    emailPanel.value?.validate()
  }
  return CorporationApplyPageStore.initializedState
})
const emailRules = computed(() =>
  CorporationValidatorsService.validateEmailMatch(
    'email',
    formValue.value.email,
    formValue.value.confirmationEmail
  )
)
const confirmationEmailRules = computed(() =>
  CorporationValidatorsService.validateEmailMatch(
    'confirmEmail',
    formValue.value.email,
    formValue.value.confirmationEmail
  )
)

const checkboxRules = CorporationValidatorsService.checkboxRules()

const externalTransmissionsUrl = ExternalLink.externalTransmissionsUrl

watch(
  () => [
    formValue.value.email,
    formValue.value.confirmationEmail,
    formValue.value.signUpCheckbox,
    formValue.value.signUpPolicyAgainstAntiSocialForces,
  ],
  ([email, confirmationEmail, signUpCheckbox, signUpPolicyAgainstAntiSocialForces]) => {
    if (email === confirmationEmail) {
      emailPanel.value?.resetValidation()
    }

    CorporationValidatorsService.checkEmailPanel(
      String(email),
      String(confirmationEmail),
      Boolean(signUpCheckbox),
      Boolean(signUpPolicyAgainstAntiSocialForces)
    )
  }
)
</script>
<style scoped>
::v-deep .v-label {
  opacity: initial !important;
}
::v-deep .v-selection-control {
  min-height: 0 !important;
  padding-top: 0 !important;
}
::v-deep .v-input__details {
  padding: 6px 0;
}
::v-deep div .v-selection-control__wrapper,
::v-deep div .v-selection-control__input {
  width: initial !important;
  padding-right: 4px;
}
</style>
