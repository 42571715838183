<template>
  <div>
    <div v-if="messageHeader" class="mb-8 text-body-1">{{ messageHeader }}</div>
    <div v-for="(message, index) in messageBody" :key="index" class="text-body-1">
      {{ message }}
    </div>
    <div v-if="messageFooter" class="mt-8 text-body-1">{{ messageFooter }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CodmonResultMessage',
  props: {
    messageHeader: {
      type: String,
      required: false,
    },
    messageBody: {
      type: Array,
      required: true,
    },
    messageFooter: {
      type: String,
      required: false,
    },
  },
})
</script>
