import { HTTP } from '../common'

export type Prefecture = {
  id: number
  name: string
}

export type Address = {
  prefecture: Prefecture
  city: string
  town: string
}

export type AddressSearchResult = {
  addresses: Address[]
}

export type SearchResponse = {
  data: AddressSearchResult
}

export default {
  search(zipcode: string): Promise<SearchResponse> {
    return HTTP.get(`/addresses?postal_code=${zipcode}`)
  },
}
