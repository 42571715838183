<template>
  <AppFrame>
    <div class="wrapper">
      <div class="wrapper-inner">
        <div class="contents">
          <div class="sign-TitleArea">
            <h1 class="sign-ServiceLogo">
              <S3Image :path="logoImage" />
            </h1>
            <h2 class="sign-Title">
              <span class="sign-Title_item">{{ pageTitle }}</span>
            </h2>
          </div>
          <div class="sign-Progress_wrapper">
            <ul class="sign-Progress">
              <template v-for="(stepName, index) in stepNames" :key="stepName">
                <li :class="signProgressItemClass(index + 1)">
                  <span class="sign-Progress_num">
                    <template v-if="step > index + 1">
                      <i class="icon-done"></i>
                    </template>
                    <template v-else>
                      {{ index + 1 }}
                    </template>
                  </span>
                  <div>{{ stepName }}</div>
                </li>
              </template>
            </ul>
          </div>
          <ErrorAlert v-if="errorMessages.length > 0" :messages="errorMessages" />
          <slot />
        </div>
      </div>
    </div>
  </AppFrame>
</template>

<script setup lang="ts">
/**
 * 施設の初期登録（本パスワード、施設情報）で使用するレイアウト。
 * タイトルの下、エラーメッセージの上にプログレス表示が入る。
 */
import { AppStore } from 'shared/app_store'
import AppFrame from 'shared/components/AppFrame.vue'
import S3Image from 'shared/components/s_3_image/S3Image.vue'
import { OemPartnerService } from 'shared/services/oem_partner_service'
import { computed, ref } from 'vue'
import ErrorAlert from 'shared/components/alert/ErrorAlert.vue'

const props = defineProps<{
  pageTitle: string
  step: number
  logoImagePath?: string
  errorMessage?: string | string[]
}>()
const errorMessages = ref<string[]>([])

if (props.errorMessage) {
  if (typeof props.errorMessage === 'string') {
    errorMessages.value = [props.errorMessage]
  } else {
    errorMessages.value = props.errorMessage
  }
}

if (!props.logoImagePath) {
  OemPartnerService.loadForFacility()
}

const stepNames = ['パスワードの変更', '詳細情報の登録', '詳細情報の確認']

const signProgressItemClass = (step: number) => {
  const baseClass = 'sign-Progress_item'
  if (props.step === step) {
    return `${baseClass} active`
  } else if (props.step > step) {
    return `${baseClass} checked`
  }
  return baseClass
}

const logoImage = computed(() => props.logoImagePath ?? AppStore.oemPartner.logo_image_path)
</script>
