<!-- 保護者一覧 新規登録、編集 重複している保護者・子どもがいる場合に表示するメッセージ -->
<template>
  <div>
    <span class="text-red font-weight-bold">
      以下の保護者・子どもが重複しています。このまま保存しますか？
    </span>
    <div class="pt-4 pb-5">
      <template v-for="(error, index) in duplicatedErrors" :key="index + 'name'">
        <v-row style="margin: -1px 0">
          <v-col cols="2" class="grey-out border pa-0">
            <p class="grid-content__text text-center ma-4">{{ index + 1 }}組目</p>
          </v-col>
          <v-col cols="2" class="border pa-0">
            <p class="grid-content__text text-center ma-4">保護者名</p>
            <v-divider></v-divider>
            <p class="grid-content__text text-center ma-4">子ども名</p>
          </v-col>
          <v-col cols="8" class="border pa-0 border-eight">
            <p class="grid-content__text text-left ma-4">
              {{ getParentName(error[0]) }}
            </p>
            <v-divider></v-divider>
            <div class="d-flex px-4">
              <span
                :key="`${error[0].error_detail.id}_${error[0].error_detail.first_name}`"
                class="grid-content__text text-left my-4"
              >
                {{ getChildName(error[0]) }}
              </span>
            </div>
          </v-col>
        </v-row>
      </template>
    </div>
    <div class="text-grey text-body-2 mb-2 font-weight-medium">
      1. 入力間違いの場合：修正してください。
      <br />
      2. 同姓同名の場合：このまま保存してください。
      <br />
      3. 保護者が同一の場合：登録済みの保護者に2人目以降の子どもとして登録してください。
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { InputConfirmationError, EditableParent } from 'pages/parents_page/model'
export default defineComponent({
  props: {
    duplicatedErrors: Array as PropType<InputConfirmationError['errors']['warn'][number][][]>,
    parents: Array as PropType<EditableParent[]>,
  },
  setup(props) {
    // NOTE: parentsのindex番目の保護者名を取得する
    const getParentName = (warn: InputConfirmationError['errors']['warn'][number]) => {
      const parentIndex = warn.parent_index
      if (!props.parents) return '-'
      const parent = props.parents[parentIndex]
      return parent ? `${parent.lastName} ${parent.firstName}さん` : '-'
    }
    const getChildName = (warn?: InputConfirmationError['errors']['warn'][number]) =>
      warn && warn.resource_name === 'school_parent_child'
        ? `${warn.error_detail.first_name}さん`
        : '-'
    return {
      getParentName,
      getChildName,
    }
  },
})
</script>

<style scoped>
.grey-out {
  background: #f6f6f5;
  pointer-events: none;
}
.border {
  border: 1px solid #e0e0e0;
  border-right: 0px;
}
.border-right {
  border-right: 1px solid #e0e0e0;
}
.grid-content__text {
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  font-weight: 500;
}
</style>
