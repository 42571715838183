import { computed } from 'vue'
import ParentsPage from 'pages/parents_page/ParentsPage.vue'
import { ParentsPageStore } from 'pages/parents_page/parents_page_store'
import ParentAdd from 'pages/parents_page/parent_add/ParentAdd.vue'
import ParentEdit from 'pages/parents_page/parent_edit/ParentEdit.vue'

export default {
  components: {
    ParentAdd,
    ParentsPage,
    ParentEdit,
  },
  setup() {
    const isLoading = computed(() => ParentsPageStore.isFullScreenLoading)
    return { isLoading }
  },
}
