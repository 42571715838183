<template>
  <SimpleLayout>
    <template #main>
      <div class="contents">
        <div class="corporation-apply-page">
          <div class="sign-TitleArea">
            <SignInServiceLogo :image-url="logoImageUrl" />
            <h2 class="sign-Title">
              <span class="sign-Title_item">会社情報の新規登録</span>
            </h2>
          </div>
          <v-stepper v-model="panelStep" alt-labels class="corporation-v-stepper">
            <CorporationProgress :steps="panels" />
            <v-stepper-window>
              <template v-for="(panel, index) in panels" :key="panel.refName + 'stepper-content'">
                <v-stepper-window-item :value="index + 1">
                  <component
                    :is="panel.componentName"
                    v-bind="panel.props"
                    :key="panel.refName + 'component'"
                  ></component>
                </v-stepper-window-item>
              </template>
            </v-stepper-window>
            <CorporationApplyNavigationButton :oem-partner-id="oemPartnerId" />
          </v-stepper>
        </div>
      </div>
    </template>
  </SimpleLayout>
</template>

<script lang="ts">
import SignInServiceLogo from 'shared/components/SignInServiceLogo.vue'
import CorporationProgress from 'pages/corporation_apply_page/components/CorporationProgress.vue'
import EmailPanel from 'pages/corporation_apply_page/components/EmailPanel.vue'
import ScreeningPanel1 from 'pages/corporation_apply_page/components/ScreeningPanel1.vue'
import ScreeningPanel2 from 'pages/corporation_apply_page/components/ScreeningPanel2.vue'
import ScreeningPanel3 from 'pages/corporation_apply_page/components/ScreeningPanel3.vue'
import CorpInfoPanel1 from 'pages/corporation_apply_page/components/CorpInfoPanel1.vue'
import CorpInfoPanel2 from 'pages/corporation_apply_page/components/CorpInfoPanel2.vue'
import CorpInfoPanel3 from 'pages/corporation_apply_page/components/CorpInfoPanel3.vue'
import RepresentativeInfoPanel from 'pages/corporation_apply_page/components/RepresentativeInfoPanel.vue'
import ConfirmPanel from 'pages/corporation_apply_page/components/ConfirmPanel.vue'
import ApplicationFinishPanel from 'pages/corporation_apply_page/components/ApplicationFinishPanel.vue'
import CorporationApplyNavigationButton from 'pages/corporation_apply_page/components/CorporationApplyNavigationButton.vue'
import { CorporationApplyPageStore } from 'pages/corporation_apply_page/corporation_apply_page_store'
import BranchNamePanel from 'pages/corporation_apply_page/components/BranchNamePanel.vue'
import rfdc from 'rfdc'
import SimpleLayout from 'shared/templates/SimpleLayout.vue'
import { MetaService } from 'shared/services/meta_service'

const clone = rfdc()

export default {
  components: {
    SignInServiceLogo,
    CorporationProgress,
    EmailPanel,
    ScreeningPanel1,
    ScreeningPanel2,
    ScreeningPanel3,
    CorpInfoPanel1,
    CorpInfoPanel2,
    CorpInfoPanel3,
    RepresentativeInfoPanel,
    ConfirmPanel,
    ApplicationFinishPanel,
    CorporationApplyNavigationButton,
    BranchNamePanel,
    SimpleLayout,
  },
  props: {
    oemPartnerId: {
      type: String,
      default: '',
    },
    // TODO serviceでapi叩いてとってくる
    logoImageUrl: {
      type: String,
      default:
        'https://s3.ap-northeast-1.amazonaws.com/jp.co.enpay-production.image/enpay_logo_1.svg',
    },
    termsOfServiceUrl: {
      type: String,
      default: '/terms_of_services/school_account/v2',
    },
    privacyPolicyUrl: {
      type: String,
      default: '/privacy_policy',
    },
    oemPartnerName: {
      type: String,
      default: null,
    },
  },
  computed: {
    panelStep() {
      return CorporationApplyPageStore.panelStep
    },
    panels() {
      CorporationApplyPageStore.updatePanel(
        {
          title: 'メール',
          refName: 'emailPanel',
          componentName: 'EmailPanel',
          props: {
            termsOfServiceUrl: this.termsOfServiceUrl,
            privacyPolicyUrl: this.privacyPolicyUrl,
          },
        },
        0
      )
      if (this.oemPartnerId) {
        CorporationApplyPageStore.insertPanel(
          {
            title: '支店情報',
            refName: 'branchNamePanel',
            componentName: 'BranchNamePanel',
            props: { oemPartnerName: this.oemPartnerName },
          },
          1
        )
      }
      return clone(CorporationApplyPageStore.panels)
    },
  },
  created() {
    MetaService.addTitle({ page: 'corporationApply' })
  },
  mounted() {
    window.addEventListener('beforeunload', this.handleBeforeUnload)
  },
  methods: {
    /**
     * ブラウザの戻るボタン・更新ボタンなどで入力内容がクリアされることの対応。
     */
    handleBeforeUnload(e: BeforeUnloadEvent) {
      // 送信完了ページでは警告を出さない
      if (CorporationApplyPageStore.getCurrentPanelInfo().refName !== 'applicationFinishPanel') {
        // chromeではこのメッセージは反映されないが、IE等の為まともなメッセージにしておく。
        e.returnValue = '入力内容がクリアされますがよろしいですか？'
      }
    },
  },
}
</script>

<style scoped>
.corporation-v-stepper {
  width: 914px;
}
/* TODO sign-TitleAreaなどのロゴとタイトル部分のstyleについてはscssに定義されているのでこちらに移動する */
</style>
