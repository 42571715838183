<template>
  <div>
    <v-menu>
      <template #activator="{ props }">
        <Button v-bind="props" color="normal" :class="`${mode}-button`" height="48" width="72">
          <div class="d-flex flex-column justify-center">
            <div><v-icon size="small" class="mt-1" :color="colorName">mdi-account</v-icon></div>
            <div class="text-caption" :class="`text-${colorName}`">アカウント</div>
          </div>
        </Button>
      </template>
      <v-list density="compact">
        <template v-if="mode === 'facility'">
          <v-list-item
            href="/corporation_accounts/me/settings"
            data-turbolinks="false"
            data-testid="account_settings"
          >
            <span class="context-menu-link" data-testid="account_settings_text"
              >アカウント設定</span
            >
          </v-list-item>
          <v-divider></v-divider>
        </template>
        <v-list-item
          href="/school_accounts/sign_out"
          rel="nofollow"
          data-method="delete"
          data-testid="logout"
        >
          <!-- Railsのdevise(gem)により生成されるaタグをベタで書いている -->
          <span class="context-menu-link" data-testid="logout_text"> ログアウト</span>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script setup lang="ts">
import { OperationMode } from '../models'
import Button from 'shared/components/Button.vue'

type SignoutButtonPropType = {
  mode: OperationMode
}

const { mode } = defineProps<SignoutButtonPropType>()
const colorName = mode === 'facility' ? 'primary' : 'grey--darken-2'
</script>

<style lang="scss" scoped>
@import '../../../../../assets/stylesheets/design/variables';
.context-menu-link {
  color: inherit;
}
.corporation-button {
  background-color: $color-button-grey;
  border: 1px $color-button-border-grey solid;
}
.facility-button {
  background-color: $color-button-blue;
  border: 1px $color-button-border-grey solid;
}
.v-btn:not(.v-btn--round).v-size--default {
  min-width: 48px;
}
</style>
