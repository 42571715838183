<template>
  <div class="d-flex align-center pa-2" style="background-color: #f6f6f5; border-radius: 4px">
    <v-icon class="mr-2" color="#727272">mdi-information</v-icon>
    <span class="font-weight-medium" style="font-size: 14px">{{ text }}</span>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    text: {
      type: String,
      default: '',
    },
  },
})
</script>
