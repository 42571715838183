import { TopPageStore } from '../top_page_store'
import rfdc from 'rfdc'
const clone = rfdc()

/**
 * 請求中かどうかのステータス管理に関するロジックを持ちます。
 */
export class InvoiceChargingStatusService {
  /**
   * 指定したparentIdsの請求書を請求中状態にします。
   * @returns 新たに請求中状態にできたparentIdsを配列で返します
   */
  static updateToCharging(parentIds: number[]): number[] {
    const chargingParentIds = clone(TopPageStore.invoiceChargingParentIds)
    const notChargingParentIds = parentIds.filter(
      (parentId) => !chargingParentIds.includes(parentId)
    )
    if (notChargingParentIds.length === 0) {
      return []
    }
    chargingParentIds.push(...notChargingParentIds)
    TopPageStore.updateInvoiceChargingParentIds(chargingParentIds)
    return notChargingParentIds
  }

  /**
   * 指定したparentIdsの請求書を非請求中状態にします。
   */
  static updateToNotCharging(parentIds: number[]): void {
    const chargingParentIds = clone(TopPageStore.invoiceChargingParentIds)
    TopPageStore.updateInvoiceChargingParentIds(
      chargingParentIds.filter((id) => !parentIds.includes(id))
    )
  }
}
