import { Parent, Child, SchoolClass } from '../models'

/**
 * Childに関するロジックを持ちます。
 */
export class SchoolParentChildService {
  /**
   * Childに紐づくSchoolClassを取得します
   */
  static getChildSchoolClass(parent: Parent, child: Child): SchoolClass {
    const invoice_items = parent?.invoices?.[0]?.invoice_items?.filter(
      (item) => item.school_parent_child_id === child.id
    )
    if (!invoice_items || !invoice_items.length) {
      return child.school_class
    }
    const latestInvoiceItemClass = invoice_items.sort(function (a, b) {
      return b.id - a.id
    })
    return latestInvoiceItemClass[0].school_class
  }
}
