<template>
  <v-form ref="representativeInfoPanel" v-model="isValid.representativeInfoPanel">
    <div class="ml-3">
      <p>代表者情報</p>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="formValue.representativeLastName"
        label="姓"
        hint="例）えんぺい"
        :rules="representativeLastNameRules"
        class="mb-3"
      ></v-text-field>
      <v-text-field
        v-model="formValue.representativeFirstName"
        label="名"
        hint="例）太郎"
        :rules="representativeFirstNameRules"
        class="mb-3"
      ></v-text-field>
      <v-text-field
        v-model="formValue.representativeLastNameKana"
        label="姓（フリガナ）"
        hint="例）エンペイ"
        :rules="representativeLastNameKanaRules"
        class="mb-3"
      ></v-text-field>
      <v-text-field
        v-model="formValue.representativeFirstNameKana"
        label="名（フリガナ）"
        hint="例）タロウ"
        :rules="representativeFirstNameKanaRules"
        class="mb-3"
      ></v-text-field>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-menu
            ref="menu"
            v-model="menu"
            v-model:return-value="formValue.representativeBirthdate"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
          >
            <template #activator="{ props }">
              <v-text-field
                v-bind="props"
                v-model="formValue.representativeBirthdate"
                label="生年月日"
                prepend-icon="mdi-calendar"
                :rules="representativeBirthdateRules"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="representativeBirthdate"
              hide-header
              color="primary"
              :max="dayjs().format('YYYY-MM-DD')"
            >
              <template #actions>
                <Button variant="text" @click="menu = false">キャンセル</Button>
                <Button variant="text" @click="menu = false">OK</Button>
              </template>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
      <p class="mt-8">代表者住所</p>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="formValue.representativePostalCode"
            label="郵便番号（ハイフン無し）"
            :rules="postalCodeRules"
          ></v-text-field>
        </v-col>
        <v-col>
          <addressSearchButton
            class="mt-3"
            :postal-code="formValue.representativePostalCode"
            @handle-search-result="handleSearchResult"
          />
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="6" md="4">
          <v-select
            v-model="formValue.representativePrefectureName"
            :items="items"
            label="都道府県"
            :rules="addressNameRules"
          ></v-select>
        </v-col>
      </v-row>
      <v-text-field
        v-model="formValue.representativePrefectureNameKana"
        label="都道府県（フリガナ）"
        hint="例）トウキョウト"
        :rules="addressKanaWithoutBuildingNameRules"
        class="mb-3"
      ></v-text-field>
      <v-text-field
        v-model="formValue.representativeCityName"
        label="市区町村"
        hint="例）港区"
        :rules="addressNameRules"
        class="mb-3"
      ></v-text-field>
      <v-text-field
        v-model="formValue.representativeCityNameKana"
        label="市区町村（フリガナ）"
        hint="例）ミナトク"
        :rules="addressKanaWithoutBuildingNameRules"
        class="mb-3"
      ></v-text-field>
      <v-text-field
        v-model="formValue.representativeTownName"
        label="町名"
        hint="例）港南"
        :rules="addressNameRules"
        class="mb-3"
      ></v-text-field>
      <v-text-field
        v-model="formValue.representativeTownNameKana"
        label="町名（フリガナ）"
        hint="例）コウナン"
        :rules="addressKanaWithoutBuildingNameRules"
        class="mb-3"
      ></v-text-field>
      <v-text-field
        v-model="formValue.representativeStreetName"
        label="番地"
        hint="例）２ー１５ー１"
        :rules="streetNameRules"
        class="mb-3"
      ></v-text-field>
      <v-text-field
        v-model="formValue.representativeBuildingName"
        label="建物名（任意）"
        hint="例）品川インターシティＡ棟２２階ＳＰＲＯＵＮＤ"
        :rules="buildingNameRules"
        class="mb-3"
      ></v-text-field>
      <v-text-field
        v-model="formValue.representativeBuildingNameKana"
        label="建物名（フリガナ）（任意）"
        hint="例）シナガワインターシティＡトウ２２カイＳＰＲＯＵＮＤ"
        :rules="buildingNameKanaRules"
        class="mb-3"
      ></v-text-field>
      <v-text-field
        v-model="formValue.representativePhoneNumber"
        label="電話番号（ハイフンあり）"
        hint="例）03-1234-5678"
        :rules="representativePhoneNumberRules"
        class="mb-3"
      ></v-text-field>
      <div v-show="false">{{ isInitial.representativeInfoPanel }}</div>
    </div>
  </v-form>
</template>

<script>
import { CorporationApplyPageStore } from '../corporation_apply_page_store'
import { prefectureService } from '../../../shared/services/prefecture_service'
import { CorporationValidatorsService } from '../services/corporation_validators_service'
import addressSearchButton from '../../../shared/components/AddressSearchButton'
import Button from 'shared/components/Button.vue'
import dayjs from 'dayjs'
import { getFormatDate } from 'shared/utils/date'

export default {
  components: { addressSearchButton, Button },
  data: () => ({
    items: prefectureService.getAllPrefectures(),
    menu: false,
    postalCodeRules: CorporationValidatorsService.postalCodeRules('郵便番号'),
  }),
  computed: {
    dayjs() {
      return dayjs
    },
    formValue() {
      return CorporationApplyPageStore.formValue
    },
    representativeBirthdate: {
      get() {
        return this.formValue.representativeBirthdate
      },
      set(value) {
        this.formValue.representativeBirthdate = getFormatDate(value, 'YYYY-MM-DD')
      },
    },
    isValid() {
      return CorporationApplyPageStore.validState
    },
    isInitial() {
      if (!CorporationApplyPageStore.initializedState.representativeInfoPanel) {
        this.$refs.representativeInfoPanel.validate()
      }
      return CorporationApplyPageStore.initializedState
    },
    representativeLastNameRules() {
      return CorporationValidatorsService.representativeNameRules('代表者姓', this.formValue)
    },
    representativeFirstNameRules() {
      return CorporationValidatorsService.representativeNameRules('代表者名', this.formValue)
    },
    representativeLastNameKanaRules() {
      return CorporationValidatorsService.representativeNameKanaRules(
        '代表者姓（フリガナ）',
        this.formValue
      )
    },
    representativeFirstNameKanaRules() {
      return CorporationValidatorsService.representativeNameKanaRules(
        '代表者名（フリガナ）',
        this.formValue
      )
    },
    representativeBirthdateRules() {
      return CorporationValidatorsService.representativeBirthdateRules('生年月日')
    },
    addressNameRules() {
      return CorporationValidatorsService.representativeAddressNameRules(
        '都道府県・市区町村・町名・番地',
        this.formValue
      )
    },
    streetNameRules() {
      return CorporationValidatorsService.representativeStreetNameRules(
        '都道府県・市区町村・町名・番地',
        this.formValue
      )
    },
    buildingNameRules() {
      if (!this.formValue.representativeBuildingName) return []
      return CorporationValidatorsService.representativeBuildingNameRules(this.formValue)
    },
    addressKanaWithoutBuildingNameRules() {
      return CorporationValidatorsService.representativeAddressNameKanaWithoutBuildingNameRules(
        '都道府県（フリガナ）・市区町村（フリガナ）・町名（フリガナ）・番地（フリガナ）',
        this.formValue
      )
    },
    buildingNameKanaRules() {
      if (!this.formValue.representativeBuildingNameKana) return []
      return CorporationValidatorsService.representativeBuildingNameKanaRules(this.formValue)
    },
    representativePhoneNumberRules() {
      return CorporationValidatorsService.phoneNumberRules('電話番号')
    },
  },
  methods: {
    handleSearchResult(address) {
      const updatedForm = {
        ...this.formValue,
        representativePrefectureName: address.prefecture.name,
        representativeCityName: address.city,
        representativeTownName: address.town,
      }
      CorporationApplyPageStore.updateFormValue(updatedForm)
    },
  },
}
</script>
