import { AccountSettingApi } from 'http/modules/account_setting'
import { AccountSettingsPageStore } from '../account_settings_page_store'

export class AccountSettingsService {
  static async load(): Promise<void> {
    const accountSettingResponse = await AccountSettingApi.index()
    AccountSettingsPageStore.updateSettings(accountSettingResponse.settings)
    AccountSettingsPageStore.updateAccount(accountSettingResponse.account)
  }
}
