<template>
  <input
    id="redirect_url"
    type="hidden"
    name="redirect_url"
    :value="redirectUrl"
    data-external-for-android="0"
  />
</template>

<script setup lang="ts">
import Liff from '@line/liff'
import { onMounted, ref } from 'vue'

defineProps<{
  redirectUrl: string
}>()

const isLoading = ref(true)
isLoading.value = false

onMounted(() => {
  // iOSの場合のみ外部ブラウザ経由でenpay walletの決済画面へ遷移
  const redirectUrlElement = document.getElementById('redirect_url') as HTMLInputElement
  Liff.openWindow({
    url: redirectUrlElement.value,
    external:
      Liff.getOS() === 'ios'
        ? true
        : (redirectUrlElement.getAttribute('data-external-for-android') || '1') === '1',
  })
  Liff.closeWindow()
})
</script>
