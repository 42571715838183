<template>
  <SearchForm>
    <template #title>
      <div class="d-flex align-center">
        <div class="font-weight-medium fs-16 grey-text">検索条件</div>
      </div>
    </template>

    <v-row dense>
      <v-col cols="6" class="d-flex align-center">
        <TextInput
          :value="searchFacilityName"
          :on-change="(value: string) => (searchFacilityName = value)"
          label="施設名"
          placeholder="エンペイ保育園"
          class="font-weight-medium fs-14 grey-text mr-6"
        />
      </v-col>
      <v-col cols="6" class="d-flex align-center">
        <span class="font-weight-medium fs-14 grey-text mr-3">締め状況</span>
        <v-radio-group v-model="lockedStatusCondition" inline class="mt-4 pt-1">
          <template v-for="lockedStatus in lockedStatusTypes" :key="lockedStatus.value">
            <v-radio
              color="primary"
              :label="lockedStatus.text"
              :value="lockedStatus.value"
            ></v-radio>
          </template>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6" class="d-flex align-center">
        <span class="font-weight-medium fs-14 grey-text mr-3">集金状況</span>
        <v-radio-group v-model="collectionProgress" inline class="mt-4 pt-1">
          <template
            v-for="collectionProgressType in collectionProgressTypes"
            :key="collectionProgressType.value"
          >
            <v-radio
              color="primary"
              :label="collectionProgressType.text"
              :value="collectionProgressType.value"
            ></v-radio>
          </template>
        </v-radio-group>
      </v-col>
      <v-col cols="3" class="d-flex align-center">
        <span class="font-weight-medium fs-14 grey-text mr-3">請求状況</span>
        <v-checkbox
          v-model="hasBeforeChargeCondition"
          label="請求保存中のみ"
          class="mt-4 pt-1"
        ></v-checkbox>
      </v-col>
      <v-col class="d-flex align-center justify-end">
        <Button
          variant="text"
          :disabled="isEmpty"
          class="font-weight-medium fs-16"
          @click="onClearConditions"
        >
          検索条件をクリア
        </Button>
      </v-col>
    </v-row>
  </SearchForm>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue'
import { InvoiceManagementPageStore } from '../../invoice_management_page_store'
import { InvoiceManagementService } from '../../services/invoice_management_service'
import TextInput from 'shared/components/TextInput.vue'
import Button from 'shared/components/Button.vue'
import SearchForm from 'shared/components/search_form/SearchForm.vue'

export default defineComponent({
  components: { TextInput, Button, SearchForm },
  /* eslint-disable max-lines-per-function */
  setup() {
    const openPanelIndex = ref(0)
    const collectionProgressTypes = [
      { text: 'すべて', value: 'all' },
      { text: '集金中', value: 'not_completed' },
      { text: '集金完了', value: 'completed' },
    ]

    const lockedStatusTypes = [
      { text: 'すべて', value: 'all' },
      { text: '請求作業中', value: 'not_locked' },
      { text: '請求締め切り', value: 'locked' },
    ]

    const hasBeforeChargeCondition = computed({
      get: () => InvoiceManagementPageStore.hasBeforeChargeCondition,
      set: async (value) => {
        InvoiceManagementPageStore.updateHasBeforeChargeCondition(value)
        await InvoiceManagementService.loadInvoiceSummariesFirstPage()
      },
    })

    const searchFacilityName = computed({
      get: () => InvoiceManagementPageStore.searchFacilityName,
      set: async (value) => {
        InvoiceManagementPageStore.updateSearchFacilityName(value)
        await InvoiceManagementService.loadInvoiceSummariesFirstPage()
      },
    })

    const collectionProgress = computed({
      get: () => InvoiceManagementPageStore.collectionProgress,
      set: async (value) => {
        InvoiceManagementPageStore.updateCollectionProgress(value)
        await InvoiceManagementService.loadInvoiceSummariesFirstPage()
      },
    })

    const lockedStatusCondition = computed({
      get: () => InvoiceManagementPageStore.lockedStatusCondition,
      set: async (value) => {
        InvoiceManagementPageStore.updateLockedStatusCondition(value)
        await InvoiceManagementService.loadInvoiceSummariesFirstPage()
      },
    })

    const onClearConditions = function () {
      InvoiceManagementService.clearSearchConditions()
    }

    const isEmpty = computed(
      () =>
        InvoiceManagementPageStore.searchFacilityName === '' &&
        InvoiceManagementPageStore.collectionProgress === 'all' &&
        InvoiceManagementPageStore.lockedStatusCondition === 'all' &&
        !InvoiceManagementPageStore.hasBeforeChargeCondition
    )

    return {
      collectionProgressTypes,
      lockedStatusTypes,
      searchFacilityName,
      collectionProgress,
      lockedStatusCondition,
      hasBeforeChargeCondition,
      onClearConditions,
      isEmpty,
      openPanelIndex,
    }
  },
})
</script>

<style scoped lang="scss">
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.grey-text {
  color: #787878;
}
.black-text {
  color: #232323;
}
.v-radio::v-deep {
  .v-label {
    color: #232323;
  }
}
.v-input--checkbox::v-deep {
  .v-label {
    color: #232323;
  }
}
</style>
