<template>
  <div>
    <span class="text-body-1">
      <span class="font-weight-bold">{{ lockedTotal }}施設</span>
      の請求締めを取り消して、 請求内容を変更できるようにします。
    </span>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    lockedTotal: Number,
  },
})
</script>
