<template>
  <div>
    <span class="text-body-1">
      <span class="font-weight-bold"> {{ facilityName }}</span>
      の請求を締めます。<br />
      請求を締めた後は、施設側で請求内容の変更が行えなくなります。
    </span>
  </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    facilityName: String,
  },
})
</script>
