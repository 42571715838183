<template>
  <div>
    <v-app-bar
      color="white"
      elevation="0"
      scroll-behavior="elevate"
      :style="headerSpace ? { top: headerSpace.headerSpace.size } : {}"
      style="z-index: 99"
      class="px-2"
    >
      <LogoImage
        mode="facility"
        :link-to="`/facilities/${currentFacilityId}/invoices`"
        data-turbolinks="false"
      />
      <v-toolbar-items class="ml-6">
        <NavMenu
          :available-menu="availableMenu"
          :selected-menu-id="selectedMenuId"
          mode="facility"
        ></NavMenu>
      </v-toolbar-items>
      <v-spacer />
      <div class="facilities-select-area">
        <FacilitiesSelect
          :available-facilities="availableFacilities"
          :current-facility-id="currentFacilityId"
          @change="switchFacility"
        ></FacilitiesSelect>
      </div>
      <template v-if="hasCorporationPermission">
        <OperationModeSwitchButton
          mode="facility"
          class="ml-3"
          link-to="/transfer"
        ></OperationModeSwitchButton>
      </template>
      <AccountMenuButton mode="facility" class="ml-3"></AccountMenuButton>
      <HelpMenuButton
        mode="facility"
        :helppage-url="helppageUrl"
        :info-for-parents-url="infoForParentsUrl"
        :manualpage-url="manualpageUrl"
        class="ml-3"
      ></HelpMenuButton>
      <BeamerButton mode="facility" class="ml-3"></BeamerButton>
    </v-app-bar>
  </div>
</template>

<script setup lang="ts">
import LogoImage from './components/LogoImage.vue'
import AccountMenuButton from './components/AccountMenuButton.vue'
import BeamerButton from './components/BeamerButton.vue'
import { useFacilityHeader } from './use_header'
import NavMenu from './components/NavMenu.vue'
import { RouteService } from 'shared/services/route_service'
import OperationModeSwitchButton from './components/OperationModeSwitchButton.vue'
import FacilitiesSelect from './components/FacilitiesSelect.vue'
import HelpMenuButton from './components/HelpMenuButton.vue'
import ExternalLink from 'shared/consts/external_link'
import { makeHeaderSpace } from 'shared/components/header/components/UnsupportedBrowserNotification.vue'

const props = defineProps<{
  facilityId?: number
}>()

const facilityId = props.facilityId || Number(RouteService.getPathParamByIndex(1))
const switchFacility = (facilityId: number) => {
  location.href = `/facilities/${facilityId}/invoices`
}
const helppageUrl = ExternalLink.openpageFaq
const infoForParentsUrl = ExternalLink.openpageInfoForParents
const manualpageUrl = ExternalLink.openpageManual
const {
  selectedMenuId,
  availableMenu,
  availableFacilities,
  hasCorporationPermission,
  currentFacilityId,
} = useFacilityHeader(facilityId)
const headerSpace = makeHeaderSpace()
</script>

<style lang="scss" scoped>
.facilities-select-area {
  width: 22rem;
  text-align: end;
}
</style>
