<template>
  <div data-testid="invoice-header">
    <div
      class="invoice-header-row d-flex justify-space-between pr-6"
      data-testid="invoice-header-row"
      @click="showModal()"
    >
      <div class="d-flex">
        <div class="icon-column">
          <v-checkbox
            v-if="isUnpaidSelected"
            v-model="isChecked"
            data-testid="invoice-header-remind"
            class="d-flex justify-end align-center"
            @click.stop=""
          ></v-checkbox>
        </div>
        <div class="parent-column d-flex flex-column justify-center">
          <div class="text-caption class-label">
            <span v-for="class_name in classNamesOfParent" :key="class_name" class="mr-2">
              <span>{{ class_name }}</span>
            </span>
          </div>
          <div data-testid="student_parent">
            <div class="d-flex align-center">
              <span class="mr-2 text-h6">{{ parent!.last_name }} {{ parent!.first_name }}</span>
              <div v-if="parent!.line_connected" class="line-icon mr-2"></div>
              <span
                v-if="parent!.school_parent_children.length > 0"
                class="text-caption"
                data-testid="student_name"
              >
                子ども：
                <template v-for="(child, index) in parent!.school_parent_children" :key="child.id">
                  <span v-if="index > 0"> / </span>
                  <span style="word-break: break-all">{{ child.first_name }}</span>
                </template>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <div class="status-column d-flex flex-column justify-center">
          <span data-testid="invoice-status" class="d-flex justify-center">{{
            invoiceStatusLabel
          }}</span>
          <div
            v-if="isPaidInvoice"
            class="text-body-2 d-flex justify-center text-grey-darken-1"
            data-testid="paid-at"
          >
            支払日：{{ getFormatDate(displayedPaidAt, 'YYYY/MM/DD HH:mm') }}
          </div>
          <div
            v-else-if="isSentInvoice"
            class="text-body-2 d-flex justify-center text-grey-darken-1"
            data-testid="sent-at"
          >
            請求日：{{ getFormatDate(latestInvoice!.sent_at, 'YYYY/MM/DD HH:mm') }}
            <v-tooltip location="right">
              <template #activator="{ props }">
                <v-icon v-bind="props" size="large" class="text-grey-darken-1 ml-5"
                  >mdi-history</v-icon
                >
              </template>
              <div>{{ `請求回数：${latestInvoice!.sent_histories.length}回` }}</div>
              <div v-for="history in latestInvoice!.sent_histories" :key="history.sent_at">
                {{ getFormatDate(history.sent_at, 'YYYY/MM/DD HH:mm') }}
              </div>
            </v-tooltip>
          </div>
        </div>
        <div class="amount-column d-flex justify-center align-end flex-column">
          <div
            v-if="hasMultipleCurrentInvoices"
            class="d-flex align-center justify-center text-caption"
            data-testid="invoice-count"
          >
            {{ getSeq }}回目
          </div>
          <AmountLabel
            class="amount"
            :amount="totalPriceOfInvoiceWithoutConvenienceStoreFee"
            :size="'L'"
            data-testid="invoice-price"
          />
          <div
            v-if="hasCvsPaymentFee && latestInvoice!.status === 'paid' && !hasEditingInvoice"
            class="cvs-payment-fee-label d-flex justify-end"
          >
            <span class="text-caption">コンビニ支払い手数料込み：</span
            ><span
              ><FlatAmountLabel
                :amount="totalPriceOfInvoiceWithConvenienceStoreFee"
                :size="'text-caption'"
            /></span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="hasMultipleCurrentInvoices" class="invoiceList-Content_head">
      <div class="total_price_container d-flex justify-end align-end">
        <span class="label">請求合計</span>
        <span class="price">
          <AmountLabel
            :amount="totalPriceOfParentInvoicesWithoutConvenienceStoreFee"
            :size="'M'"
            data-testid="total-price-of-multiple-invoices"
          />
        </span>
      </div>
      <div
        v-if="hasAnyConvenienceStoreFee(parent!)"
        class="cvs-payment-fee-label d-flex justify-end"
      >
        <span class="text-caption">コンビニ支払い手数料込み：</span
        ><span
          ><FlatAmountLabel
            :amount="totalPriceOfParentInvoicesWithConvenienceStoreFee"
            :size="'text-caption'"
        /></span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import rfdc from 'rfdc'
import { SchoolParentService } from 'pages/top_page/services/school_parent_service'
import { SchoolParentChildService } from 'pages/top_page/services/school_parent_child_service'
import { InvoiceService } from 'pages/top_page/services/invoice_service'
import { InvoiceItemInputService } from 'pages/top_page/services/invoice_item_input_service'
import { TopPageStore } from 'pages/top_page/top_page_store'
import AmountLabel from 'shared/components/AmountLabel.vue'
import FlatAmountLabel from 'shared/components/FlatAmountLabel.vue'
import { InvoiceModalService } from 'pages/top_page/services/invoice_modal_service'
import { Invoice, Parent } from 'pages/top_page/models'
import { computed } from 'vue'
import { getFormatDate } from 'shared/utils/date'
import useInvoiceHeader from './use_latest_invoice'
const clone = rfdc()

const { parentId } = defineProps<{
  parentId: number
}>()

const { parent, latestInvoice, displayedPaidAt, isPaidInvoice, isSentInvoice, hasCvsPaymentFee } =
  useInvoiceHeader(parentId)

const isChecked = computed({
  get: (): boolean => (parent.value ? parent.value.isChecked : false),
  set: (selected: boolean) => {
    const clonedParents = clone(TopPageStore.schoolParents)
    const targetParent = clonedParents.find((parent) => parent.id === parentId)
    if (!targetParent) return
    targetParent.isChecked = selected
    TopPageStore.updateSchoolParents(clonedParents)
  },
})

const hasEditingInvoice = computed(() => SchoolParentService.hasEditingInvoice(parent.value))

const statusWithBeforeRegister = computed(() => {
  let status
  if (latestInvoice.value) {
    if (parent.value && latestInvoice.value.status === 'paid' && hasEditingInvoice.value) {
      status = 'before_register' as const
    } else {
      status = latestInvoice.value.status
    }
  } else {
    status = 'before_register' as const
  }

  return status
})

/**
 * InvoiceHeaderに表示する請求のステータスに対応する日本語を返します
 * 返す日本語はconfig/locales/ja.ymlのinvoice_base/statusに合わせてください
 * @returns {string}
 */
const invoiceStatusLabel = computed(() => {
  const invoiceStatusLabelMap = {
    before_register: '請求未作成',
    before_charge: '請求保存中',
    unpaid: '未払い',
    paid: '支払い済み',
  }
  const status = statusWithBeforeRegister.value
  return invoiceStatusLabelMap[status]
})

const isUnpaidSelected = computed(() => TopPageStore.searchCondition.paymentStatus === 'unpaid')

const getSeq = computed(() => {
  if (!parent.value || !parent.value.invoices || parent.value.invoices.length === 0 || !parentId) {
    return 1
  }

  if (InvoiceService.existNeverSavedInvoice(parentId)) {
    return parent.value.invoices.length + 1
  }
  return parent.value.invoices[0].seq
})

const showModal = () => {
  if (!parent.value || !parentId) return

  if (parent.value.invoices.map((invoice) => invoice.status).includes('paid')) {
    InvoiceModalService.openInvoicesModal(parentId)
    return
  }
  InvoiceModalService.openInvoiceFormModal(parentId, statusWithBeforeRegister.value)
}

const classNamesOfParent = computed(() => {
  const parentValue = parent.value
  if (!parentValue) return []

  const class_names: string[] = []
  if (parentValue.school_parent_children) {
    parentValue.school_parent_children.forEach((child) => {
      class_names.push(SchoolParentChildService.getChildSchoolClass(parentValue, child).name)
    })
  }
  return Array.from(new Set(class_names))
})

const hasMultipleCurrentInvoices = computed(() => {
  const parentValue = parent.value
  return (
    !!parentValue &&
    (parentValue.invoices.length > 1 ||
      (parentValue.invoices.length === 1 && InvoiceService.existNeverSavedInvoice(parentValue.id)))
  )
})

const calculateTotalPriceOfInvoice = (
  invoice: Invoice | undefined,
  includeConvenienceStoreFee: boolean
) => {
  if (parentId && InvoiceService.existNeverSavedInvoice(parentId)) {
    return InvoiceItemInputService.calculateInputTotalAmountByParentId(parentId)
  } else if (!invoice) {
    return undefined
  }
  return InvoiceService.calculateTotalAmountOfInvoice(invoice.id, includeConvenienceStoreFee)
}

const totalPriceOfInvoiceWithoutConvenienceStoreFee = computed(() =>
  calculateTotalPriceOfInvoice(latestInvoice.value, false)
)
const totalPriceOfInvoiceWithConvenienceStoreFee = computed(() =>
  calculateTotalPriceOfInvoice(latestInvoice.value, true)
)

const calculateTotalPriceOfParentInvoices = (
  parent: Parent | undefined,
  includeConvenienceStoreFee: boolean
) => {
  if (!parent) return undefined
  return SchoolParentService.calculateTotalAmountOfParentInvoices(
    parent.id,
    includeConvenienceStoreFee
  )
}

const totalPriceOfParentInvoicesWithoutConvenienceStoreFee = computed(() =>
  calculateTotalPriceOfParentInvoices(parent.value, false)
)
const totalPriceOfParentInvoicesWithConvenienceStoreFee = computed(() =>
  calculateTotalPriceOfParentInvoices(parent.value, true)
)

const hasAnyConvenienceStoreFee = (parent: Parent) =>
  parent.invoices.some((invoice) => {
    const isPaid = invoice.status === 'paid' && invoice.paid_at
    const hasCvsPaymentFee =
      invoice.invoice_items.length > 0 &&
      invoice.invoice_items.some((item) => item.is_convenience_store_fee)
    return isPaid && hasCvsPaymentFee
  })
</script>

<style lang="scss" scoped>
@import '../../../../../assets/stylesheets/design/variables';

.line-icon {
  height: 20px;
  width: 20px;
}
.invoice-header-row {
  background-color: white;
  min-height: 64px;
  padding-block: 8px;
  &:hover {
    cursor: pointer;
    background-color: #f5f5f5;
  }
}
.icon-column {
  width: 43px;
}
.parent-column {
  width: calc(100% - 43px);
}
.class-label {
  color: $color-main;
}
.status-column {
  width: 230px;
}
.amount-column {
  width: 240px;
  .amount {
    color: $color-main;
  }
}

.cvs-payment-fee-label {
  color: #636363;
}

.invoiceList-Content_head {
  background: #eaf6ff;
  color: $color-main;
  padding: 2px 24px 4px 0;
  .total_price_container {
    .label {
      color: #333;
      font-size: 13px;
      margin-right: 5px;
      line-height: 2;
      vertical-align: middle;
    }
  }
}
</style>
