<template>
  <AppFrame :has-browser-alert="false">
    <PrintButtonBar class="mb-6"></PrintButtonBar>
    <div>
      <div v-if="isLoading || !receipt" class="d-flex justify-center">
        <v-progress-circular indeterminate :size="70" color="primary"></v-progress-circular>
      </div>
      <template v-else>
        <div class="wrapper">
          <div class="wrapper-inner">
            <div class="contents receipt">
              <div class="receipt_inner">
                <div class="receipt_header">
                  <h1 class="receipt_title">領収書</h1>
                  <p class="receipt_date">
                    {{ formatDate(receipt.paid_at, 'YYYY 年 M 月 D 日') }}
                  </p>
                </div>
                <div class="receipt_provision">
                  <div class="receipt_provision_left">
                    <h2 class="receipt_provision_title">但し</h2>
                    <p class="receipt_provision_text">上記正に領収いたしました</p>
                  </div>
                  <div class="receipt_provision_right">
                    <p class="receipt_provision_name">
                      {{ receipt.school_parent.last_name + ' ' + receipt.school_parent.first_name
                      }}<span>様</span>
                    </p>
                    <p class="receipt_provision_price">
                      <span>¥</span> {{ commaSeparate(receipt.total_price) }}
                    </p>
                  </div>
                </div>
                <div class="receipt_footer">
                  <div class="receipt_address">
                    〒{{ receipt.school.postal_code }}<br />
                    {{ receipt.school.prefecture.name }}{{ receipt.school.city }}&nbsp;{{
                      receipt.school.building
                    }}<br />
                    {{ receipt.school.company_name }}&nbsp;{{ receipt.school.school_name }}
                  </div>
                  <div class="receipt_stamp">
                    <S3Image path="/receipt_stamp.png"></S3Image>
                  </div>
                </div>
                <div v-if="receipt" class="section_inner section_invoice">
                  <h2>{{ receipt.target_year }} 年&nbsp; {{ receipt.target_month }}月 明細</h2>
                  <template v-if="!!receipt.convenience_store_fee_invoice">
                    <div class="invoice_list">
                      <div class="cvs_fee_invoice_title">
                        <span>{{ receipt.convenience_store_fee_invoice.name }} </span
                        ><span
                          >{{
                            receipt.convenience_store_fee_invoice.count *
                            receipt.convenience_store_fee_invoice.unit_price
                          }}
                          円</span
                        >
                      </div>
                    </div>
                  </template>
                  <template v-if="receipt.childless_invoice_items.invoice_items.length > 0">
                    <div class="invoice_list">
                      <div class="invoice_title childless-sub-total">
                        <span
                          >{{ commaSeparate(receipt.childless_invoice_items.subtotal) }} 円</span
                        >
                      </div>
                      <ul>
                        <li>
                          <span class="content">内容 </span><span class="count">数量</span
                          ><span class="unit_price">単価</span><span class="subtotal">金額</span>
                        </li>
                        <li
                          v-for="(invoice_item, indexOfInvoiceItem) in receipt
                            .childless_invoice_items.invoice_items"
                          :key="index + ' ' + indexOfInvoiceItem"
                        >
                          <span class="content">{{ invoice_item.name }}</span
                          ><span class="count">{{ commaSeparate(invoice_item.count) }}</span
                          ><span class="unit_price">
                            {{ commaSeparate(invoice_item.unit_price) }}</span
                          ><span class="subtotal"
                            >{{
                              commaSeparate(invoice_item.unit_price * invoice_item.count)
                            }}
                            円</span
                          >
                        </li>
                      </ul>
                    </div>
                  </template>

                  <template
                    v-for="(list_item, index) in receipt.children_with_invoice_items"
                    :key="index"
                  >
                    <div class="invoice_list">
                      <div class="invoice_title">
                        <span>{{ list_item.child.first_name }} さん</span
                        ><span>{{ commaSeparate(list_item.sub_total) }} 円</span>
                      </div>
                      <ul>
                        <li>
                          <span class="content">内容 </span><span class="count">数量</span
                          ><span class="unit_price">単価</span><span class="subtotal">金額</span>
                        </li>
                        <li
                          v-for="(invoice_item, indexOfInvoiceItem) in list_item.invoice_items"
                          :key="index + ' ' + indexOfInvoiceItem"
                        >
                          <span class="content">{{ invoice_item.name }}</span
                          ><span class="count">{{ commaSeparate(invoice_item.count) }}</span
                          ><span class="unit_price">
                            {{ commaSeparate(invoice_item.unit_price) }}</span
                          ><span class="subtotal"
                            >{{
                              commaSeparate(invoice_item.unit_price * invoice_item.count)
                            }}
                            円</span
                          >
                        </li>
                      </ul>
                    </div>
                  </template>
                </div>

                <div class="published_by_enpay">
                  領収書は、「集金業務支援サービス enpay」が発行しております。
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </AppFrame>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import S3Image from 'shared/components/s_3_image/S3Image.vue'
import { FilterService } from 'shared/services/filter_service'
import { RouteService } from 'shared/services/route_service'
import { ReceiptPrintPageStore } from '../receipt_print_page_store'
import { ReceiptPrintService } from '../services/receipt_print_service'
import PrintButtonBar from 'shared/components/PrintButtonBar.vue'
import { MetaService } from 'shared/services/meta_service'
import AppFrame from 'shared/components/AppFrame.vue'

MetaService.addTitle({ mode: 'facility', page: 'receiptPrint' })
const invoiceId = RouteService.getPathParamByIndex(3)!
ReceiptPrintService.loadReceipt(Number(invoiceId))

const receipt = computed(() => ReceiptPrintPageStore.receipt)
const isLoading = computed(() => ReceiptPrintPageStore.isLoading)
const formatDate = (date: string, format?: string) =>
  FilterService.formatDateTime(date, format || 'YYYY年MM月DD日')
const commaSeparate = (money: number) => {
  if (money === undefined) {
    return ''
  }
  return FilterService.commaSeparate(money)
}
</script>
<style lang="scss" scoped>
.published_by_enpay {
  margin-top: 10px;
  text-align: right;
}
.childless-sub-total {
  justify-content: end !important;
}
</style>
