import { reactive } from 'vue'
import { DownloadTarget, InvoiceDownloadDialogState } from './models'
import {
  CollectionProgressCondition,
  InvoiceSummary,
  InvoiceSummaryToUpdate,
  LockedStatusCondition,
} from 'http/modules/corporations_invoices'

import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { CorporationsFacilitySummary } from 'http/modules/corporations_facility_summary'
dayjs.extend(utc)
dayjs.extend(timezone)

type InvoiceManagementPageState = {
  invoiceDownloadDialogState: InvoiceDownloadDialogState
  firstSelectableYearMonth: dayjs.Dayjs | undefined

  isInvoiceSummariesLoading: boolean
  canBulkUnlock: boolean
  summaryTargetYearMonth: dayjs.Dayjs
  searchCursor: number | undefined
  searchFacilityName: string
  collectionProgress: CollectionProgressCondition
  invoiceSummaries: InvoiceSummary[]
  infiniteLoadingIdentifier: number
  downloadTarget: DownloadTarget
  lockedStatusCondition: LockedStatusCondition
  hasBeforeChargeCondition: boolean
  facilitySummary: CorporationsFacilitySummary
}

const state = reactive<InvoiceManagementPageState>({
  invoiceDownloadDialogState: {
    isShow: false,
    targetYearMonthRange: { from: '', to: '' },
  },
  firstSelectableYearMonth: undefined,
  canBulkUnlock: false,
  isInvoiceSummariesLoading: false,
  summaryTargetYearMonth: dayjs().tz('Asia/Tokyo'),
  searchCursor: 0,
  searchFacilityName: '',
  collectionProgress: 'all',
  invoiceSummaries: [],
  infiniteLoadingIdentifier: new Date().getTime(),
  downloadTarget: 'chargedMonth',
  lockedStatusCondition: 'all',
  hasBeforeChargeCondition: false,
  facilitySummary: {
    total: 0,
    locked_total: 0,
    not_completed: {
      before_charge: 0,
      unpaid: 0,
    },
  },
})

export class InvoiceManagementPageStore {
  static get invoiceDownloadDialogState(): InvoiceDownloadDialogState {
    return state.invoiceDownloadDialogState
  }

  static updateInvoiceDownloadDialogState(
    invoiceDownloadDialogState: InvoiceDownloadDialogState
  ): void {
    state.invoiceDownloadDialogState = invoiceDownloadDialogState
  }

  static get firstSelectableYearMonth(): dayjs.Dayjs | undefined {
    return state.firstSelectableYearMonth
  }

  static updateFirstSelectableYearMonth(firstSelectableYearMonth: dayjs.Dayjs) {
    state.firstSelectableYearMonth = firstSelectableYearMonth
  }

  static get isInvoiceSummariesLoading(): boolean {
    return state.isInvoiceSummariesLoading
  }

  static updateIsInvoiceSummariesLoading(isInvoiceSummariesLoading: boolean): void {
    state.isInvoiceSummariesLoading = isInvoiceSummariesLoading
  }

  static get canBulkUnlock(): boolean {
    return state.canBulkUnlock
  }

  static updateCanBulkUnlock(canBulkUnlock: boolean): void {
    state.canBulkUnlock = canBulkUnlock
  }

  static get summaryTargetYearMonth(): dayjs.Dayjs {
    return state.summaryTargetYearMonth
  }

  static updateSummaryTargetYearMonth(summaryTargetYearMonth: dayjs.Dayjs) {
    state.summaryTargetYearMonth = summaryTargetYearMonth
  }

  static get searchCursor(): number | undefined {
    return state.searchCursor
  }

  static updateSearchCursor(searchCursor: number | undefined): void {
    state.searchCursor = searchCursor
  }

  static get searchFacilityName(): string {
    return state.searchFacilityName
  }

  static updateSearchFacilityName(searchFacilityName: string): void {
    state.searchFacilityName = searchFacilityName
  }

  static get collectionProgress(): CollectionProgressCondition {
    return state.collectionProgress
  }

  static updateCollectionProgress(collectionProgress: CollectionProgressCondition): void {
    state.collectionProgress = collectionProgress
  }

  static get invoiceSummaries(): InvoiceSummary[] {
    return state.invoiceSummaries
  }

  static updateInvoiceSummaries(invoiceSummaries: InvoiceSummary[]): void {
    state.invoiceSummaries = invoiceSummaries
  }

  static updateInvoiceSummaryByFacilityId(invoiceSummary: InvoiceSummaryToUpdate): void {
    state.invoiceSummaries = state.invoiceSummaries.map((summary) => {
      if (summary.facility_id === invoiceSummary.facility_id) {
        return { ...summary, ...invoiceSummary }
      }
      return summary
    })
  }

  /**
   * 全てのinvoiceSummariesをupdatedInvoiceSummaryValueの値に更新する
   * @param updatedInvoiceSummaryValue 更新したいInvoiceSummaryのproperty
   */
  static updateAllInvoiceSummaries(
    updatedInvoiceSummaryValue: Partial<Omit<InvoiceSummary, 'facility_id'>>
  ): void {
    state.invoiceSummaries = state.invoiceSummaries.map((summary) => ({
      ...summary,
      ...updatedInvoiceSummaryValue,
    }))
  }

  /**
   * facilityIdsに渡されたidと一致するinvoiceSummaryをupdatedInvoiceSummaryValueの値に更新する
   * @param facilityIds updatedInvoiceSummaryValueに更新する施設のid
   * @param updatedInvoiceSummaryValue 更新したいInvoiceSummaryのproperty
   */
  static updateInvoiceSummariesByFacilityIds(
    facilityIds: number[],
    invoiceSummary: Partial<Omit<InvoiceSummary, 'facility_id'>>
  ): void {
    state.invoiceSummaries = state.invoiceSummaries.map((summary) => {
      if (facilityIds.includes(summary.facility_id)) {
        const updatedSummary: InvoiceSummaryToUpdate = {
          ...invoiceSummary,
          facility_id: summary.facility_id,
        }
        return { ...summary, ...updatedSummary }
      }
      return summary
    })
  }

  static get infiniteLoadingIdentifier(): number {
    return state.infiniteLoadingIdentifier
  }

  static updateInfiniteLoadingIdentifier(infiniteLoadingIdentifier: number): void {
    state.infiniteLoadingIdentifier = infiniteLoadingIdentifier
  }

  static get downloadTarget(): DownloadTarget {
    return state.downloadTarget
  }

  static updateDownloadTarget(downloadTarget: DownloadTarget): void {
    state.downloadTarget = downloadTarget
  }

  static get facilitySummary(): CorporationsFacilitySummary {
    return state.facilitySummary
  }

  static updateFacilitySummary(facilitySummary: CorporationsFacilitySummary): void {
    state.facilitySummary = facilitySummary
  }

  static get lockedStatusCondition(): LockedStatusCondition {
    return state.lockedStatusCondition
  }

  static updateLockedStatusCondition(lockedStatusCondition: LockedStatusCondition) {
    state.lockedStatusCondition = lockedStatusCondition
  }

  static get hasBeforeChargeCondition(): boolean {
    return state.hasBeforeChargeCondition
  }

  static updateHasBeforeChargeCondition(hasBeforeChargeCondition: boolean) {
    state.hasBeforeChargeCondition = hasBeforeChargeCondition
  }
}
