<template>
  <div class="header-control-container mt-4">
    <Button variant="text" class="font-weight-bold header-control-container-text" @click="goto">
      <v-icon>mdi-chevron-left</v-icon>
      <span>{{ title }}</span>
    </Button>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import Button from 'shared/components/Button.vue'

export default defineComponent({
  components: { Button },
  props: {
    title: String,
    path: {
      type: String,
      required: true,
    },
  },
  setup({ path }) {
    const goto = () => {
      location.href = path
    }
    return { goto }
  },
})
</script>
<style lang="scss" scoped>
.header-control-container-text {
  i,
  span {
    vertical-align: middle;
  }
  span {
    position: relative;
    // 微調整
    top: -2px;
  }
}
</style>
