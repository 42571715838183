import { HTTP } from '../common'
import { AxiosResponse } from 'axios'
import { InvoiceLockResponse } from './corporation_invoices_lock'

export class CorporationFacilityInvoicesLockApi {
  static basePath = (facilityId: number) => `/corporations/me/facilities/${facilityId}/invoice_lock`

  static post(
    facilityId: number,
    targetYearAndMonth: string
  ): Promise<AxiosResponse<InvoiceLockResponse>> {
    return HTTP.post(CorporationFacilityInvoicesLockApi.basePath(facilityId), {
      target_year_and_month: targetYearAndMonth,
    })
  }

  static delete(
    facilityId: number,
    targetYearAndMonth: string
  ): Promise<AxiosResponse<Record<string, unknown>>> {
    return HTTP.delete(CorporationFacilityInvoicesLockApi.basePath(facilityId), {
      data: {
        target_year_and_month: targetYearAndMonth,
      },
    })
  }
}
