<template>
  <SimpleLayout title="クラス一覧">
    <template #headerNav>
      <FacilityHeader />
    </template>

    <template #headerContent>
      <div class="d-flex">
        <Button
          variant="outlined"
          size="large"
          class="font-weight-bold outlined-button mr-3"
          @click="openRegistrationDialog()"
        >
          <v-avatar start color="primary" size="20px" icon="mdi-plus" class="mr-2" /><span
            class="fs-16"
            >クラスを登録する</span
          >
        </Button>
        <Button
          size="large"
          class="font-weight-bold flat-button"
          :href="`/facilities/${facilityId}/classes/batch/edit`"
          ><v-icon icon="mdi-restart" class="mr-2" /><span class="fs-16"
            >一括でクラスを更新する</span
          ></Button
        >
      </div>
    </template>

    <template #main>
      <div v-if="schoolClasses.length > 0" class="mb-6" style="width: 832px">
        <draggable v-model="schoolClasses" handle=".handle" item-key="id" class="bg-white">
          <template #item="{ element: school_class }">
            <div>
              <div class="d-flex class-row align-center py-2">
                <div class="handle px-4">
                  <v-icon> mdi-drag-horizontal-variant </v-icon>
                </div>
                <div class="name-column">
                  {{ school_class.name }}
                </div>
                <v-spacer />
                <div v-if="!staticClasses.includes(school_class.name)">
                  <Button
                    color="normal"
                    variant="tonal"
                    class="mr-4"
                    @click="openUpdateDialog(school_class)"
                  >
                    編集する
                  </Button>
                  <Button color="error" class="mr-8" @click="deleteClass(school_class)">
                    削除する
                  </Button>
                </div>
              </div>
              <v-divider />
            </div>
          </template>
        </draggable>
      </div>
      <div v-else>クラスが登録されていません。</div>
      <v-dialog v-model="isShowRegistrationDialog" width="500">
        <v-card>
          <v-card-title class="dialog-bg-color text-white">
            <span>クラスの新規登録</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="registrationForm">
              <v-text-field
                v-model="registrationFormValue"
                class="mt-8"
                label="クラス名"
                :rules="nameRules"
                :disabled="isUpdating"
                autofocus
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <Button color="normal" variant="text" @click="closeRegistrationDialog()">
              キャンセル
            </Button>
            <Button variant="text" :loading="isUpdating" @click="registerClass()">
              登録する
            </Button>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="isShowUpdateDialog" width="500">
        <v-card>
          <v-card-title class="dialog-bg-color text-white">
            <span>クラス名の変更</span>
          </v-card-title>
          <v-card-text>
            <v-row class="mt-8">
              <v-col cols="4" class="text-body-1">現在のクラス名</v-col>
              <v-col v-if="updateTargetClass" cols="8" class="text-body-1">{{
                updateTargetClass.name
              }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="d-flex align-center text-body-1">変更後のクラス名</v-col>
              <v-col cols="8" class="text-body-1">
                <v-form ref="updateForm">
                  <v-text-field
                    v-model="updateFormValue"
                    label="クラス名"
                    :rules="nameRules"
                    :disabled="isUpdating"
                    autofocus
                  ></v-text-field
                ></v-form>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <Button color="normal" variant="text" @click="closeUpdateDialog()"> キャンセル </Button>
            <Button variant="text" :loading="isUpdating" @click="updateClassName()">
              変更する
            </Button>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <Dialog />
    </template>
  </SimpleLayout>
</template>
<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { ClassManagementService } from '../services/class_management_service'
import { ClassManagementPageStore } from '../class_management_page_store'
import Dialog from 'shared/components/Dialog.vue'
import { SchoolClass } from 'http/modules/class'
import draggable from 'vuedraggable'
import SimpleLayout from 'shared/templates/SimpleLayout.vue'
import FacilityHeader from 'shared/components/header/FacilityHeader.vue'
import Button from 'shared/components/Button.vue'
import type { VForm } from 'vuetify/components'
import { MetaService } from 'shared/services/meta_service'
import { RouteService } from 'shared/services/route_service'

export default defineComponent({
  components: {
    Dialog,
    draggable,
    SimpleLayout,
    FacilityHeader,
    Button,
  },
  // eslint-disable-next-line max-lines-per-function
  setup() {
    MetaService.addTitle({ mode: 'facility', page: 'classManagement' })
    ClassManagementService.loadClasses()

    // const
    const nameRules = [(value: string) => !!value || 'クラス名を入力してください']
    const staticClasses = ['新園児', '卒園児', 'コドモン連携']
    // data
    const registrationFormValue = ref('')
    const updateFormValue = ref('')

    // computed
    const isShowRegistrationDialog = computed({
      get: () => ClassManagementPageStore.isShowRegistrationDialog,
      set: (value) => ClassManagementPageStore.updateIsShowRegistrationDialog(value),
    })
    const isShowUpdateDialog = computed({
      get: () => ClassManagementPageStore.isShowUpdateDialog,
      set: (value) => ClassManagementPageStore.updateIsShowUpdateDialog(value),
    })
    const schoolClasses = computed({
      get: () => ClassManagementPageStore.schoolClasses,
      set: (value) => {
        // changeOrderの中でloadが走るので、ここでupdateSchoolClassesを実行しなくても正しく使えるが、
        // ここで実行しておかないと、並び順変更時に画面がちらついてしまう。
        // （D&D直後に一瞬、変更前の並び順で表示され、すぐに更新されることによりちらつきが発生する）
        ClassManagementPageStore.updateSchoolClasses(value)
        ClassManagementService.changeOrder(value.map((schoolClass) => schoolClass.id))
      },
    })
    const updateTargetClass = computed(() => ClassManagementPageStore.updateTargetClass)

    const updateForm = ref<VForm | null>(null)

    const registrationForm = ref<VForm | null>(null)
    // methods
    const updateClassName = () => {
      if (!updateForm.value) return
      if (!updateForm.value.validate()) return
      ClassManagementService.updateClassName(updateFormValue.value).then(() => {
        if (!updateForm.value) return
        updateForm.value.reset()
      })
    }
    const isUpdating = computed(() => ClassManagementPageStore.isUpdating)
    const openRegistrationDialog = () =>
      ClassManagementPageStore.updateIsShowRegistrationDialog(true)
    const closeRegistrationDialog = () =>
      ClassManagementPageStore.updateIsShowRegistrationDialog(false)
    const closeUpdateDialog = () => ClassManagementPageStore.updateIsShowUpdateDialog(false)
    const openUpdateDialog = (schoolClass: SchoolClass) => {
      ClassManagementPageStore.updateUpdateTargetClass(schoolClass)
      ClassManagementPageStore.updateIsShowUpdateDialog(true)
    }
    const deleteClass = (schoolClass: SchoolClass) =>
      ClassManagementService.deleteClass(schoolClass)
    const registerClass = () => {
      if (!registrationForm.value || !registrationForm.value.isValid) return
      ClassManagementService.registerClass(registrationFormValue.value).then(() => {
        if (!registrationForm.value) return
        registrationForm.value.reset()
      })
    }
    const facilityId = RouteService.getPathParamByIndex(1)

    return {
      nameRules,
      staticClasses,
      registrationForm,
      registrationFormValue,
      updateForm,
      updateFormValue,
      isShowRegistrationDialog,
      isShowUpdateDialog,
      schoolClasses,
      updateTargetClass,
      isUpdating,
      facilityId,
      updateClassName,
      openRegistrationDialog,
      closeRegistrationDialog,
      closeUpdateDialog,
      openUpdateDialog,
      deleteClass,
      registerClass,
    }
  },
})
</script>
<style lang="scss" scoped>
.outlined-button {
  line-height: 24px;
  background-color: #fff;
}
.flat-button {
  line-height: 24px;
}
.fs-16 {
  font-size: 16px;
}
.handle {
  cursor: move;
}
.class-row {
  width: 100%;

  .name-column {
    // クラス名がとても長いときにデザイン崩れさせないため
    width: 400px;
  }
}
.sortable-ghost {
  background-color: #ccc !important;
}

.button-bottom {
  position: fixed;
  right: 24px;
  bottom: 180px;
}

// 本来はcreateVuetifyで動的に生成している bg-primary-lighten-1 で正しいはずだが、turbolinksか何かの影響で一度登録した後にこれらのスタイルが消えてしまうため、ここだけ静的に指定している
.dialog-bg-color {
  background-color: rgba(59, 129, 211, 1) !important;
}
</style>
