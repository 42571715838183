<template>
  <div class="label font-weight-bold">{{ text }}</div>
</template>
<script lang="ts">
/**
 * 色付きの背景を持つラベル
 */
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    text: { type: String, required: true },
  },
})
</script>

<style lang="scss" scoped>
.label {
  display: flex;
  padding: 2px 4px;
  align-items: flex-start;
  gap: 10px;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  border-radius: 2px;
}
</style>
