<template>
  <AppFrame>
    <template #header>
      <slot name="headerNav"></slot>
    </template>
    <slot name="headerControl"></slot>

    <template v-if="hasAlert">
      <slot name="alert"></slot>
    </template>

    <v-container fluid class="d-flex align-center flex-column px-10">
      <div
        :class="`d-flex justify-space-between align-center ${hasAlert ? 'mt-10' : ''}`"
        :style="{ width: isExpanded ? '100%' : '832px' }"
      >
        <template v-if="title">
          <h1 class="text-h6 font-weight-bold py-7">{{ title }}<slot name="subTitle"></slot></h1>
        </template>
        <slot name="headerContent"></slot>
      </div>

      <slot name="notification"></slot>
      <slot name="main"></slot>
    </v-container>
  </AppFrame>
</template>
<script>
/**
 * SimpleLayout（上にタイトル・概要、下にコンテントのパターン）
 * headerNav: ヘッダーのナビゲーション
 * title: ページタイトル
 * notification: お知らせ
 * main: メインコンテンツ
 */
import { defineComponent } from 'vue'
import AppFrame from 'shared/components/AppFrame.vue'

export default defineComponent({
  components: {
    AppFrame,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    hasNotification: {
      type: Boolean,
      default: false,
    },
    // isExpandedは、現状保護者一括登録画面のみで使用している
    isExpanded: {
      type: Boolean,
      default: false,
    },
    hasAlert: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
