<template>
  <div class="text-center">
    <v-menu v-model="isShowYearMonthOptions">
      <template #activator="{ props }">
        <Button
          v-bind="props"
          :color="buttonBackground == 'transparent' ? 'transparent' : 'normal'"
          size="large"
          :class="buttonBackground == 'white' ? 'px-0 white-background' : 'px-0'"
          :outlined="outlined"
          :variant="outlined ? 'outlined' : 'flat'"
        >
          <slot>年月を修正</slot>
        </Button>
      </template>

      <v-list density="compact" class="py-0">
        <div v-for="(yearMonth, yearIndex) in selectableYearMonths" :key="yearIndex">
          <v-menu
            max-width="66"
            offset="-40, -135"
            open-on-hover
            open-delay="150"
            close-delay="150"
          >
            <template #activator="{ props }">
              <v-list-item v-bind="props" :key="yearIndex" class="d-flex pr-0">
                <v-list-item-title>
                  <div class="text-body-1 d-flex justify-center">{{ yearMonth.year }}年</div>
                </v-list-item-title>
                <template #append>
                  <v-icon> mdi-menu-right </v-icon>
                </template>
              </v-list-item>
            </template>

            <v-list density="compact" class="py-0">
              <v-list-item
                v-for="(month, monthIndex) in yearMonth.months"
                :key="monthIndex"
                @click="selectYearMonth(yearMonth.year, month)"
              >
                <v-list-item-title>
                  <div class="text-body-1">{{ month }}月</div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import useYearMonthDropdown, {
  YearMonthDropdownPropType,
} from 'shared/components/year_month_dropdown/use_year_month_dropdown'
import Button from 'shared/components/Button.vue'

import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)

export default defineComponent<YearMonthDropdownPropType>({
  components: {
    Button,
  },
  props: {
    from: {
      type: dayjs.Dayjs,
      default: () => dayjs().tz('Asia/Tokyo'),
    },
    to: {
      type: dayjs.Dayjs,
      default: () => dayjs().tz('Asia/Tokyo'),
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    buttonBackground: {
      type: String,
      default: 'transparent',
    },
  },
  emits: ['clickMonth'],
  setup(props, context) {
    return useYearMonthDropdown(props, context)
  },
})
</script>

<style scoped lang="scss">
/* v-btnでoutlinedの場合に背景のみを白色にする標準機能がないため */
.white-background {
  background-color: white;
}
</style>
