import { AxiosResponse } from 'axios'
import { HTTP } from 'http/common'
import { Child } from 'pages/parents_page/model'

export type DeleteResponse = Child & {
  errors: string[]
}

export class FacilityParentChildApi {
  static delete(facilityId: number, id: number): Promise<AxiosResponse<DeleteResponse>> {
    return HTTP.delete(`/facilities/${facilityId}/parents/${id}/children/${id}`)
  }
}
