import { HTTP } from 'http/common'
import { AxiosResponse } from 'axios'

// API側でレスポンス生成に一括締めと単体施設締めで同じメソッドを使っているので、型も合わせる
export type InvoiceLockResponse = {
  success_facility_ids: number[]
  failure_facility_ids: number[]
}

export class CorporationInvoicesLockApi {
  static basePath = '/corporations/me/invoice_lock'

  static post(targetYearAndMonth: string): Promise<AxiosResponse<InvoiceLockResponse>> {
    return HTTP.post(CorporationInvoicesLockApi.basePath, {
      target_year_and_month: targetYearAndMonth,
    })
  }

  static delete(targetYearAndMonth: string): Promise<AxiosResponse<Record<string, unknown>>> {
    return HTTP.delete(CorporationInvoicesLockApi.basePath, {
      data: {
        target_year_and_month: targetYearAndMonth,
      },
    })
  }
}
