import { ExportInvoiceItemsPageStore } from 'pages/export_invoice_items_page/export_invoice_items_page_store'
import { SearchYearAndMonthType } from 'pages/export_invoice_items_page/model'
import {
  getAddMonth,
  getDate,
  getMonth,
  getSubtractMonth,
  getYear,
  isFutureDate,
  isPastDate,
} from 'shared/utils/date'

/**
 * フォームの値の更新・補正を行う
 * 選択可能な年月の計算はCalculateYearAndMonthRangeServiceに任せる
 */
export class YearAndMonthRangeService {
  /**
   * 以下の確認項目を満たさない場合は、フォームの値を補正する
   * 確認する項目は2つ
   * 1. 施設作成日の1ヶ月前(法人なら法人は以下の施設で最も古い施設)より後ろの年月になっているかの確認
   * 2. 現在時刻の2ヶ月後よりも前の年月になっているかの確認
   * 3. fromがtoよりも前の年月になっているかの確認
   */
  static fixInvalidYearAndMonth(changedFormName: SearchYearAndMonthType): void {
    // 1. 施設作成日の1ヶ月より前の年月かの確認(請求が作成できる最初の月)
    const creatableInvoiceStartDate = getSubtractMonth(
      ExportInvoiceItemsPageStore.oldestFacilityCreatedAt,
      1
    )
    const updatedYearAndMonth = getDate(
      YearAndMonthRangeService.concatSearchYearAndMonth(changedFormName)
    )

    if (isPastDate(updatedYearAndMonth, creatableInvoiceStartDate)) {
      YearAndMonthRangeService.fixBeforeCreatableInvoiceStartDate(
        creatableInvoiceStartDate,
        changedFormName
      )
    }

    // 2. 現在時刻の2ヶ月後よりも未来の年月かの確認
    const creatableInvoiceEndDate = getAddMonth(new Date(), 2)
    if (isFutureDate(updatedYearAndMonth, creatableInvoiceEndDate)) {
      YearAndMonthRangeService.fixAfterCreatableInvoiceEndDate(
        creatableInvoiceEndDate,
        changedFormName
      )
    }

    // 3. fromがtoよりも後になっているかの確認
    const isInvalidRange = isFutureDate(
      YearAndMonthRangeService.concatSearchYearAndMonth('from'),
      YearAndMonthRangeService.concatSearchYearAndMonth('to')
    )

    if (isInvalidRange) {
      YearAndMonthRangeService.fixToBeforeFrom(changedFormName)
    }
  }

  private static fixBeforeCreatableInvoiceStartDate(
    oldestFacilityCreatedAt: Date,
    changedFormName: SearchYearAndMonthType
  ): void {
    ExportInvoiceItemsPageStore.updateFromYearAndMonth({
      year: getYear(oldestFacilityCreatedAt),
      month: getMonth(oldestFacilityCreatedAt),
    })
    if (changedFormName === 'to') {
      ExportInvoiceItemsPageStore.updateToYearAndMonth({
        year: getYear(oldestFacilityCreatedAt),
        month: getMonth(oldestFacilityCreatedAt),
      })
    }
  }

  private static fixAfterCreatableInvoiceEndDate(
    upperLimitDate: Date,
    changedFormName: SearchYearAndMonthType
  ): void {
    ExportInvoiceItemsPageStore.updateToYearAndMonth({
      year: getYear(upperLimitDate),
      month: getMonth(upperLimitDate),
    })
    if (changedFormName === 'from') {
      ExportInvoiceItemsPageStore.updateFromYearAndMonth({
        year: getYear(upperLimitDate),
        month: getMonth(upperLimitDate),
      })
    }
  }

  /**
   * fixInvalidYearAndMonthを通過した上で、fromがtoよりも後になっている場合は、入力された方と同じ値にする
   */
  private static fixToBeforeFrom(changedFormName: SearchYearAndMonthType): void {
    if (changedFormName === 'from') {
      ExportInvoiceItemsPageStore.updateToYearAndMonth({
        year: ExportInvoiceItemsPageStore.fromYearAndMonth.year,
        month: ExportInvoiceItemsPageStore.fromYearAndMonth.month,
      })
    } else if (changedFormName === 'to') {
      ExportInvoiceItemsPageStore.updateFromYearAndMonth({
        year: ExportInvoiceItemsPageStore.toYearAndMonth.year,
        month: ExportInvoiceItemsPageStore.toYearAndMonth.month,
      })
    }
  }

  private static concatSearchYearAndMonth(target: SearchYearAndMonthType): string {
    switch (target) {
      case 'to':
        return `${ExportInvoiceItemsPageStore.toYearAndMonth.year}-${ExportInvoiceItemsPageStore.toYearAndMonth.month}`
      case 'from':
        return `${ExportInvoiceItemsPageStore.fromYearAndMonth.year}-${ExportInvoiceItemsPageStore.fromYearAndMonth.month}`
      default:
        throw new Error('invalid target')
    }
  }
}
