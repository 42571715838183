<template>
  <div class="px-2">
    <div class="d-flex align-end justify-space-between mb-1">
      <div class="d-flex align-center">
        <v-icon class="mr-1" size="small" color="primary">mdi-circle</v-icon>
        <span class="text-subtitle-2">支払い済み</span>
      </div>
      <AmountLabel data-testid="paid-total-amount" :amount="totalAmountByStatus.paid" :size="'S'" />
    </div>
    <div class="d-flex align-end justify-space-between mb-1">
      <div class="d-flex align-center">
        <v-icon class="mr-1" size="small" color="orange-lighten-3">mdi-circle</v-icon>
        <span class="text-subtitle-2">未払い</span>
      </div>
      <AmountLabel
        data-testid="unpaid-total-amount"
        :amount="totalAmountByStatus.unpaid"
        :size="'S'"
      />
    </div>
    <div class="d-flex align-end justify-space-between mb-1">
      <div class="d-flex align-center">
        <v-icon class="mr-1" size="small" color="grey-lighten-1">mdi-circle</v-icon>
        <span class="text-subtitle-2">請求保存中</span>
      </div>
      <AmountLabel
        data-testid="before-charge-total-amount"
        :amount="totalAmountByStatus.before_charge"
        :size="'S'"
      />
    </div>
    <div class="d-flex justify-space-between align-end">
      <span class="text-subtitle-2 mr-2 pl-5">合計</span>
      <AmountLabel
        data-testid="all-parents-invoices-total-amount"
        :amount="totalAmountByStatus.all"
        :size="'S'"
        class="font-weight-medium"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { InvoiceService } from '../services/invoice_service'
import AmountLabel from '../../../shared/components/AmountLabel.vue'

export default defineComponent({
  components: {
    AmountLabel,
  },
  setup() {
    // computed
    const totalAmountByStatus = computed(() =>
      InvoiceService.calculateAmountOfInvoiceByStatusLegacy()
    )
    return { totalAmountByStatus }
  },
})
</script>
