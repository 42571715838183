import { CorporationInvoiceItemsApi } from 'http/modules/corporation_invoice_items'
import { ConvertToInvoiceItemsService } from 'pages/export_invoice_items_page/services/convert_to_invoice_items_service'
import { ExportInvoiceItemsPageStore } from 'pages/export_invoice_items_page/export_invoice_items_page_store'
import { CalculateYearAndMonthRangeService } from 'pages/export_invoice_items_page/services/calculate_year_and_month_range_service'
import { ConvertToQueryParamsService } from 'pages/export_invoice_items_page/services/convert_to_query_params_service'
import { CorporationFacilityApi } from 'http/modules/corporation_facility'
import { CorporationApi } from 'http/modules/corporation'
import { ExportInvoiceItemsUrlService } from 'pages/export_invoice_items_page/services/export_invoice_items_url_service'
import { getDate, getMonth, getSubtractMonth, getYear } from 'shared/utils/date'

export class CorporationExportInvoiceItemsService {
  /**
   * 初期値が欲しい時に呼ばれる関数
   * @param page ページ番号
   */
  static async loadInitialData(page?: number): Promise<void> {
    await Promise.all([CorporationExportInvoiceItemsService.loadOldestFacilityCreatedAt()])
    CorporationExportInvoiceItemsService.setInitialSearchDate()

    // クエリパラメータ入力済みならクエリパラメータの値をstoreに入れる
    // 未入力ならstoreの値の初期値をクエリパラメータに入れる(storeの値のデフォルト値自体が初期検索値)
    if (location.search.toString() !== '') {
      ExportInvoiceItemsUrlService.copyQueryParamsToStore({ isFacility: false })
    }

    // storeの値に応じて選択候補の年月を入れたいので、クエリパラメータを読み取った後に呼ぶ
    CalculateYearAndMonthRangeService.setSelectableYearAndMonthRange()
    await CorporationExportInvoiceItemsService.loadInvoiceItems(page)
    await CorporationExportInvoiceItemsService.loadCorporationFacility()
  }

  /**
   * 対象期間の初期値をセットする関数
   * 法人の場合は、現在時刻の前月を初期値とする
   * 例、現在が8月の場合は、初期値は7月
   */
  static setInitialSearchDate(): void {
    const initialSearchDate = getSubtractMonth(new Date(), 1)

    // TODO: クエリパラメータの値でAPI接続するときに、store内の検索条件の変数は削除する
    ExportInvoiceItemsPageStore.updateFromYearAndMonth({
      year: getYear(initialSearchDate),
      month: getMonth(initialSearchDate),
    })
    ExportInvoiceItemsPageStore.updateToYearAndMonth({
      year: getYear(initialSearchDate),
      month: getMonth(initialSearchDate),
    })
  }

  /**
   * 法人の請求内容一覧を取得する関数
   */
  static async loadInvoiceItems(page?: number): Promise<void> {
    ExportInvoiceItemsPageStore.updateIsLoading(true)
    const { from, to, termType, status, includeKeyword, itemNames, facilityIds } =
      ConvertToQueryParamsService.convertToQueryParams()

    const apiResponse = await CorporationInvoiceItemsApi.index({
      from,
      to,
      termType,
      itemNames,
      status,
      includeKeyword,
      facilityIds,
      page: page ?? 1,
      perPage: 50,
    })
    const invoiceItems = ConvertToInvoiceItemsService.convertToInvoiceItems(apiResponse.data)
    ExportInvoiceItemsPageStore.updateInvoiceItems(invoiceItems)
    ExportInvoiceItemsPageStore.updateIsLoading(false)
  }

  /**
   * 法人配下に存在する利用可能な施設を取得する関数
   */
  private static async loadCorporationFacility(): Promise<void> {
    const apiResponse = await CorporationFacilityApi.index('all')
    ExportInvoiceItemsPageStore.updateAvailableFacilities(apiResponse.data.facilities)
  }

  /**
   * 法人配下に存在する施設の一番古い作成日を取得する関数
   */
  private static async loadOldestFacilityCreatedAt(): Promise<void> {
    const response = await CorporationApi.self()
    ExportInvoiceItemsPageStore.updateOldestFacilityCreatedAt(
      getDate(response.data.oldest_facility_created_at)
    )
  }
}
