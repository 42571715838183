import { assertIsDefined } from 'shared/utils/assertFunctions'
import { Env } from 'shared/models'

/**
 * 現在のURLに関わるロジック
 */
export class RouteService {
  static getQueryParams(): { [key: string]: string } {
    const urlSearchParams = new URLSearchParams(window.location.search)
    return Object.fromEntries(urlSearchParams.entries())
  }

  static getQueryParamByKey(key: string): string | undefined {
    return new URLSearchParams(window.location.search).get(key) ?? undefined
  }

  /**
   * pathパラメータをindex指定で取得できます。
   * 例)
   * https://app.enpay.co.jp/path0/path1/path2?query=hoge
   * のようなとき、以下のような実行結果となります。
   * getPathParamByIndex(0) -> 'path0'
   * getPathParamByIndex(1) -> 'path1'
   * getPathParamByIndex(2) -> 'path2'
   * getPathParamByIndex(3) -> undefined
   */
  static getPathParamByIndex(index: number): string {
    // location.pathnameは最初がスラッシュなので1文字削っておく
    const pathParams = location.pathname.slice(1).split('/')[index]
    assertIsDefined(pathParams)
    return pathParams
  }

  static hasQueryParams(key: string): boolean {
    return new URLSearchParams(window.location.search).has(key)
  }

  static getENVFromHost(): Env {
    const pattern = /^(.+)\.enpay\.co\.jp$/
    const result = this.getHost().match(pattern)

    const isLocalhost = result === null
    if (isLocalhost) return 'localhost'

    switch (result[1]) {
      case 'app':
        return 'production'
      case 'copy':
        return 'copy'
      case 'app-staging':
        return 'staging'
      case 'app-develop':
        return 'develop'
      case 'hotfix':
        return 'hotfix'
      default: {
        const isPullRequestID = /^\d+\.app-qa$/.test(result[1])
        if (isPullRequestID) return 'qa'

        throw new Error('unknown host')
      }
    }
  }

  static getHost() {
    return window.location.host
  }
}
