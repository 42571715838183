import {
  FacilityIdParams,
  GroupByDownloadParams,
  InvoiceItemsApiCommonParams,
} from 'pages/export_invoice_items_page/model'

type Params = InvoiceItemsApiCommonParams & FacilityIdParams['multiple'] & GroupByDownloadParams

export class CorporationInvoiceItemsCsvApi {
  /**
   * 法人画面の請求内容DL 合計金額・合計件数・総ページ数を取得する
   * @param from 対象期間 任意
   * 開始年月 yyyy-mm形式
   * @param to 対象期間 任意
   * 終了年月 yyyy-mm形式
   * @param termType 対象期間 任意
   * target_year_and_month, paid_at
   * @param includeKeyword 検索キーワードを含むかどうか 任意
   * デフォルトは true
   * true の場合は OR検索、false の場合は NOT検索 の AND検索
   * @param itemNames 請求内容名 任意
   * カンマ区切りで複数キーワードを指定できる。
   * 例: 延長保育代,おやつ代
   * @param status 請求ステータス 任意
   * デフォルトは 'before_charge', 'unpaid', 'paid' の全てのステータス
   * カンマ区切りで複数指定できる。
   * 複数指定した場合は OR検索 になる。
   * @param facilityIds 施設ID 任意
   * カンマ区切りで複数指定できる。
   * 複数指定した場合は OR検索 になる。
   * @param groupBy 集計対象
   * カンマ区切りで複数指定できる。
   * 指定順がそのままソートのキーになる
   */
  static index({
    from,
    to,
    termType,
    itemNames,
    status,
    includeKeyword,
    facilityIds,
    groupBy,
  }: Params): void {
    window.location.href = `${
      location.origin
    }/api/v1/corporations/me/invoice_items.csv?from=${from}&to=${to}&term_type=${termType}&include_keyword=${includeKeyword}&item_names=${itemNames}&status=${status}&facility_ids=${facilityIds}${
      groupBy ? `&group_by=${groupBy}` : ''
    }`
  }
}
