import { FacilityPageStore } from 'pages/facility_page/facility_page_store'
import { FacilityApi, School, UpdateResponse, isAxiosError } from 'http/modules/facility'
import { PrefectureApi } from 'http/modules/prefecture'
import { Prefecture } from 'shared/models'
import { RouteService } from 'shared/services/route_service'

/**
 * 施設情報設定に必要なロジックを持ちます
 */
export class FacilityService {
  static async loadPrefectures(): Promise<Prefecture[]> {
    return (await PrefectureApi.index()).data.prefectures
  }

  static async loadFacility(): Promise<void> {
    try {
      FacilityPageStore.updateIsLoading(true)
      FacilityPageStore.updateFacility((await FacilityApi.show(this.getTargetFacilityId())).data)
    } finally {
      FacilityPageStore.updateIsLoading(false)
    }
  }

  static async updateFacility(school: School): Promise<boolean> {
    try {
      FacilityPageStore.updateIsLoading(true)
      FacilityPageStore.updateMessages(
        (await FacilityApi.update(this.getTargetFacilityId(), school)).data.messages
      )
      FacilityService.loadFacility()
    } catch (error) {
      FacilityPageStore.updateMessages(
        isAxiosError<UpdateResponse>(error)
          ? error.response!.data.messages
          : ['想定外のエラーが発生しました。']
      )
      return false
    } finally {
      FacilityPageStore.updateIsLoading(false)
    }
    return true
  }

  static getTargetFacilityId(): number {
    return Number(RouteService.getPathParamByIndex(1))
  }
}
