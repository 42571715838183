<template>
  <div class="no-print">
    <v-card width="300px" elevation="0" class="pa-2">
      <v-card-title class="pb-2">振込明細書</v-card-title>
      <v-card-text>
        <span class="text-black">
          振込予定日：{{ transferStatement.transfer_amount > 0 ? formattedPaymentDate : 'ー' }}
        </span>
      </v-card-text>
      <v-card-actions class="px-4 pb-4">
        <PrintButton width="100%" />
      </v-card-actions>
    </v-card>
    <template v-if="isAdmin">
      <v-card width="300px" elevation="0" class="pa-2 mt-6">
        <v-card-title class="pb-2">発行日時</v-card-title>
        <v-card-actions class="px-4 pb-4">
          <SelectInput
            variant="outlined"
            density="compact"
            :items="formattedPaymentDateHistories"
            item-text="label"
            item-value="value"
            hide-details
            :value="selectedTransferStatementDate"
            @change="onChangeTransferStatementDate"
          />
        </v-card-actions>
      </v-card>
      <v-card width="300px" elevation="0" class="pa-2 mt-6">
        <v-card-title class="pb-2">修正理由</v-card-title>
        <v-card-text>
          <span class="text-black" style="white-space: pre-line">
            {{ transferStatement.reason || 'なし' }}</span
          >
        </v-card-text>
        <v-card-title class="pb-2">メモ</v-card-title>
        <v-card-text>
          <span class="text-black" style="white-space: pre-line">
            {{ transferStatement.memo || 'なし' }}</span
          >
        </v-card-text>
      </v-card>
    </template>
    <template v-else>
      <v-card width="300px" elevation="0" class="pa-2 mt-6">
        <v-card-title class="pb-2">集金合計の内訳</v-card-title>
        <v-card-text>
          <span class="text-black">
            {{ formattedStartCalcPaidDate }} - {{ formattedEndCalcPaidDate }}</span
          >
        </v-card-text>
        <v-card-actions class="px-4 pb-4">
          <Button variant="outlined" width="100%" @click="downloadInvoiceItems">
            ダウンロードする
          </Button>
        </v-card-actions>
      </v-card>
    </template>
    <div
      v-if="
        transferStatement.can_use_enpay_wallet &&
        !isInvoicePayment &&
        !(hasEnpayWalletCollectionAmount && !hasFeeGapAssist)
      "
      data-testId="enpay-wallet-pr-banner"
    >
      <template v-if="hasFeeGapAssist">
        <v-card width="300px" elevation="0" class="pa-2 mt-6">
          <v-card-title class="pb-2">決済手数料の軽減金額</v-card-title>
          <div class="enpay-wallet-pr">
            <div class="enpay-wallet-pr-amount">
              <span class="fee-gap-assist-amount">{{
                commaSeparate(transferStatement.fee_gap_assist)
              }}</span
              >円
            </div>
            <v-card-text class="d-flex">
              <div class="enpay-wallet-pr-description">
                enpayウォレットでお支払いいただくと決済手数料が0.5%軽減されます。
              </div>
              <S3Image
                alt="保護者のイラスト"
                path="/transfer_statement/parent-blue-background.svg"
                style="margin-left: 7px"
              />
            </v-card-text>
          </div>
        </v-card>
      </template>
      <template v-else>
        <div class="mt-6">
          <S3Image
            alt="enpayウォレットでお支払いいただくと、決済手数料率が0.5%軽減されます。ぜひ保護者の方へおすすめください。"
            path="/transfer_statement/fee-rate-reduction-pr.svg"
            style="right: 4px"
          />
        </div>
      </template>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { FilterService } from 'shared/services/filter_service'
import PrintButton from 'shared/components/PrintButton.vue'
import { EventTracker } from 'shared/utils/_event_tracking'
import Button from 'shared/components/Button.vue'
import SelectInput from 'shared/components/SelectInput.vue'
import S3Image from 'shared/components/s_3_image/S3Image.vue'
import { getFormatDate } from 'shared/utils/date'
import { TransferStatementHistory } from 'pages/transfer_statement_print_page/model'
import { TransferStatement } from 'shared/models'

const props = withDefaults(
  defineProps<{
    isAdmin: boolean
    transferStatement: TransferStatement
    startCalcPaidDate: string
    endCalcPaidDate: string
    transferStatementHistories: TransferStatementHistory[]
    selectedTransferStatementDate: string
  }>(),
  {
    isAdmin: false,
  }
)

const emit = defineEmits(['downloadInvoiceItemsCsv', 'onChangeDateHistory'])
const formattedPaymentDateHistories = computed(() =>
  props.transferStatementHistories.map((x: TransferStatementHistory) =>
    getFormatDate(x.calculated_at, 'YYYY/MM/DD HH:mm')
  )
)

const formattedPaymentDate = getFormatDate(props.transferStatement.payment_date, 'YYYY年MM月DD日')
const formattedStartCalcPaidDate = getFormatDate(props.startCalcPaidDate, 'YYYY年MM月DD日')
const formattedEndCalcPaidDate = getFormatDate(props.endCalcPaidDate, 'YYYY年MM月DD日')

const downloadInvoiceItems = () => {
  EventTracker.trackEvent('click_invoice_item_download_in_transfer', {})
  emit('downloadInvoiceItemsCsv')
}

const onChangeTransferStatementDate = (date: string) => {
  emit('onChangeDateHistory', date)
}

const commaSeparate = (money: number) => FilterService.commaSeparate(money)
const hasFeeGapAssist = computed(() => props.transferStatement.fee_gap_assist > 0)
const isInvoicePayment = computed(
  () =>
    props.transferStatement.settlement_fee.enpay_wallet.settlement_fee_rate === '0.0' &&
    props.transferStatement.settlement_fee.standard.settlement_fee_rate === '0.0'
)

const hasEnpayWalletCollectionAmount = computed(
  () => props.transferStatement.settlement_fee.enpay_wallet.collect_total > 0
)
</script>

<style lang="scss" scoped>
@media print {
  .no-print {
    display: none;
  }
}
.enpay-wallet-pr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.enpay-wallet-pr-amount {
  background-color: #e3f2fb;
  width: 272px;
  height: 62px;
  text-align: right;
  padding-right: 10px;
  font-weight: bold;
}
.fee-gap-assist-amount {
  font-size: 40px;
  margin-right: 4px;
}
.enpay-wallet-pr-description {
  font-size: 12px;
  width: 165px;
  height: 63px;
  margin-right: 7px;
}
</style>
