/* eslint-disable max-lines */
import { TopPageStore } from '../top_page_store'
import InvoiceApi, { SaveParamsParent, SaveParams } from '../../../http/modules/invoice'
import InvoiceItemNameCandidateApi from '../../../http/modules/invoice_item_name_candidate'
import { InvoiceSavingStatusService } from './invoice_saving_status_service'
import { SchoolParentService } from './school_parent_service'
import { Parent, InvoiceItemInput, InvoiceItem, Child } from '../models'
import { InvoiceItemInputService } from './invoice_item_input_service'
import { SchoolService } from './school_service'
import rfdc from 'rfdc'

const clone = rfdc()

/**
 * InvoiceItemに関するロジックを持ちます。
 */
export class InvoiceItemService {
  /**
   * 請求書項目の小計を計算します。
   * InvoiceItemInputの小計を計算したい場合(countとunitPriceがstringのとき)は、
   * InvoiceItemInputService#calculateInputSubtotalを使ってください。
   * count * unitPriceした値を返します。0や負の数が返ることもあります。
   */
  static calculateSubtotal(count: number, unitPrice: number): number {
    return count * unitPrice
  }

  /**
   * 指定したinvoiceIdの、childIdだけの合計金額を返します。負の数が返ることがあります。
   * 値が不正な請求書項目は0円として合計されます。
   * 請求書項目が全て未入力の場合や、対応する親や請求書が無い場合にはundefinedを返します。
   * 指定した請求書の合計額を計算する際、請求書入力フォームに最新の値がある場合はそちらを優先して使い、計算します。
   */
  static calculateTotalAmountOfChild(invoiceId: number, childId?: number): number | undefined {
    // 指定されたinvoiceIdがDBに保存されている最新のinvoiceで、
    // かつstatusがpaid(フォームが最新の状況と一致する)であれば、
    // フォームを元に計算し結果を返す。
    const parent = clone(SchoolParentService.getParentByInvoiceId(invoiceId))
    if (!parent) return undefined
    if (
      parent.invoices.length > 0 &&
      parent.invoices[0].id === invoiceId &&
      parent.invoices[0].status !== 'paid'
    ) {
      return InvoiceItemInputService.calculateInputTotalAmountOfChild(parent.id, childId)
    }

    // そうでなければinvoice_itemsを元に計算し結果を返す
    const invoice = parent.invoices.find((invoice) => invoice.id === invoiceId)
    if (!invoice) return undefined

    return invoice.invoice_items
      .filter((item) => item.school_parent_child_id === childId)
      .reduce((amount: number | undefined, item) => {
        // コンビニ手数料は子どもごとの合計金額に含めない
        if (item.is_convenience_store_fee) {
          return amount
        }

        const subtotal = InvoiceItemService.calculateSubtotal(item.count, item.unit_price)
        if (amount === undefined) {
          return subtotal
        }
        return amount + (subtotal ?? 0)
      }, undefined)
  }

  static calculateConvenienceStoreFeeOfChild(invoiceId: number, childId?: number): number {
    const parent = clone(SchoolParentService.getParentByInvoiceId(invoiceId))
    if (!parent) return 0
    if (
      parent.invoices.length > 0 &&
      parent.invoices[0].id === invoiceId &&
      parent.invoices[0].status !== 'paid'
    ) {
      return 0
    }

    // そうでなければinvoice_itemsを元に計算し結果を返す
    const invoice = parent.invoices.find((invoice) => invoice.id === invoiceId)
    if (!invoice) return 0

    const item = invoice.invoice_items
      .filter((item) => item.school_parent_child_id === childId)
      .find((item) => item.is_convenience_store_fee)
    return item?.unit_price || 0
  }

  /* eslint-disable max-lines-per-function */
  /**
   * 渡されたparentIdsの親に紐づく請求内容を保存します。保存に失敗した場合エラーを投げます。
   * @returns 保存APIをコールしたときのレスポンスを返します。APIをコールしないときはundefinedを返します。
   */
  static async saveInvoiceItems(parentIds: number[]): Promise<any> {
    InvoiceSavingStatusService.updateToSaving(parentIds)

    // APIコールのためのparams
    const params: SaveParams = {
      target_year_and_month: TopPageStore.targetYear + '-' + TopPageStore.targetMonth,
      parents: [],
    }
    // parents配列を作る
    params.parents = parentIds
      .map((parentId) => SchoolParentService.getParentById(parentId)!)
      .reduce((saveParamParents: SaveParamsParent[], parent) => {
        // paramsに使うinvoiceIdを作成
        let invoiceId: number | null = null
        if (parent.invoices.length > 0 && parent.invoices[0].status !== 'paid') {
          invoiceId = parent.invoices[0].id
        }

        const saveParamInvoiceItems: InvoiceItemInput[] = []

        ;[undefined, ...parent.school_parent_children.map((child) => child.id)].forEach(
          (childId) => {
            if (InvoiceItemService.hasSavableItemInputs(parent.id, childId)) {
              saveParamInvoiceItems.push(
                ...parent.invoice_item_inputs.filter(
                  (input) =>
                    input.school_parent_child_id === childId && InvoiceItemService.isValid(input)
                )
              )
              return
            }
            // DBに保存済みの値を使ってinputを生成
            // invoiceIdがnullなら新規請求書なので何も送信しない。invoiceIdに値が入っていればDBの値を使う
            if (invoiceId !== null) {
              saveParamInvoiceItems.push(
                ...parent.invoices[0].invoice_items
                  .filter((item) => item.school_parent_child_id === childId)
                  .map((item) => InvoiceItemInputService.constructInputByInvoiceItem(item, childId))
              )
            }
          }
        )

        saveParamParents.push({
          id: parent.id,
          current_invoice_id: invoiceId,
          invoice_items: saveParamInvoiceItems,
        })
        return saveParamParents
      }, [])

    let saveResponse: any
    try {
      saveResponse = await InvoiceApi.save(SchoolService.getTargetFacilityId(), params)
      await SchoolParentService.loadSchoolParents(params.parents.map((parent) => parent.id))
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      InvoiceSavingStatusService.updateToNotSaving(parentIds)
    }
    return saveResponse
  }

  static hasValidValueCountOrUnitPriceInputsAll(parentId: number, children: Child[]): boolean {
    const itemInputs = children.reduce<InvoiceItemInput[]>(
      (acc, child) => [
        ...acc,
        // 空の場合は検査対象としない
        ...(InvoiceItemService.shouldEmptyInputs(parentId, child.id)
          ? []
          : InvoiceItemInputService.getItemInputs(parentId, child.id)),
      ],
      []
    )
    return itemInputs.every((invoiceItemInput) => {
      if (invoiceItemInput.unit_price === '') {
        return true
      }
      return (
        invoiceItemInput.unit_price !== '' &&
        invoiceItemInput.unit_price.toString().match(/^[+-]?[0-9]+$/) &&
        invoiceItemInput.count !== '' &&
        invoiceItemInput.count.toString().match(/^[0-9]+$/)
      )
    })
  }

  /**
   * nameが入力されていて、count * unitPriceの計算結果が0以外の整数であれば有効であると判断する
   */
  static isValid(invoiceItemInput: InvoiceItemInput): boolean {
    const subtotal = InvoiceItemInputService.calculateInputSubtotal(
      invoiceItemInput.count,
      invoiceItemInput.unit_price
    )
    return !!invoiceItemInput.name.trim() && subtotal !== undefined && subtotal !== 0
  }

  static countRule(name: string, count: string): boolean {
    return !name || !count || (/^\d+$/.test(count) && this.mysqlIntTypeRule(count))
  }

  static unitPriceRule(name: string, unitPrice: string): boolean {
    return !name || !unitPrice || (/^[-]?\d+$/.test(unitPrice) && this.mysqlIntTypeRule(unitPrice))
  }

  private static mysqlIntTypeRule(number: string | number): boolean {
    return Number(number) >= -2147483648 && Number(number) <= 2147483647
  }

  static countRuleForParent(parent: Parent): boolean {
    return parent.invoice_item_inputs.every((input) => this.countRule(input.name, input.count))
  }

  static unitPriceRuleForParent(parent: Parent): boolean {
    return parent.invoice_item_inputs.every((input) =>
      this.unitPriceRule(input.name, input.unit_price)
    )
  }

  static totalPriceRuleForParent(parentId: number): boolean {
    const totalPrice = InvoiceItemInputService.calculateInputTotalAmountByParentId(parentId)
    return totalPrice === undefined || this.mysqlIntTypeRule(totalPrice)
  }

  /**
   * - 各子供と子供に紐付かない請求のそれぞれがすべて有効な入力値になっている
   *
   * かつ
   * - 少なくとも1つのフォームにおいて有効な名前が入力されており、小計が正の数になる
   *
   * 場合に請求可能であると判断する
   */
  static hasChargableInputItems(parentId: number): boolean {
    const parent = SchoolParentService.getParentById(parentId)
    if (!parent) {
      return false
    }
    const hasChildrenAllValidInputs = [
      undefined,
      ...parent.school_parent_children.map((child) => child.id),
    ].every((childId) => InvoiceItemService.hasValidInputs(parentId, childId))
    const existValidInput = parent.invoice_item_inputs.some((input) =>
      InvoiceItemService.isValid(input)
    )
    return hasChildrenAllValidInputs && existValidInput
  }

  static hasNoEmptyNameOrUnitPriceInputsAll(parentId: number, children: Child[]): boolean {
    const itemInputs = children.reduce<InvoiceItemInput[]>(
      (acc, child) => [
        ...acc,
        // 空の場合は検査対象としない
        ...(InvoiceItemService.shouldEmptyInputs(parentId, child.id)
          ? []
          : InvoiceItemInputService.getItemInputs(parentId, child.id)),
      ],
      []
    )
    const hasEmptyNameOrUnitPrice = itemInputs.find(
      (invoiceItemInput) =>
        // 請求書の単価は入力済みだが名前が未入力
        (invoiceItemInput.unit_price !== '' && invoiceItemInput.name === '') ||
        // 名前は入力済みだが，単価は未入力
        (invoiceItemInput.unit_price === '' && invoiceItemInput.name !== '')
    )

    return !hasEmptyNameOrUnitPrice
  }

  /**
   * 入力欄が全て未入力であることを判定する。
   */
  static shouldEmptyInputs(parentId: number, childId?: number): boolean {
    const parent = SchoolParentService.getParentById(parentId)
    if (!parent) {
      return false
    }

    const invoiceItemInputs = InvoiceItemInputService.getItemInputs(parent.id, childId)

    // 全てが empty である場合（何も入力されていない）
    return invoiceItemInputs.every(
      (invoiceItemInput) => invoiceItemInput.unit_price === '' && invoiceItemInput.name === ''
    )
  }

  /**
   * 指定したchildIdの請求書項目が有効か（保存に成功するか）を判定します。
   * - 請求書項目の合計金額が正の数の場合
   * - 請求書項目が未入力の場合
   * - 請求書の単価は入力済みだが名前が未入力の場合または，名前は入力済みだが単価が未入力の場合
   * - 請求書項目の合計金額が0円でかつ複数の有効なアイテムがある場合（相殺のケース
   * は有効な入力値になっていると判断します。
   */
  static hasValidInputs(parentId: number, childId?: number): boolean {
    const parent = SchoolParentService.getParentById(parentId)
    if (!parent) {
      return false
    }
    const itemInputs = InvoiceItemInputService.getItemInputs(parentId, childId)
    const totalAmount = InvoiceItemInputService.calculateInputTotalAmountOfChild(parentId, childId)

    if (totalAmount === undefined || totalAmount > 0) {
      return true
    }

    if (totalAmount === 0) {
      let validItemCount = 0
      itemInputs.forEach((input) => {
        const subtotal = InvoiceItemInputService.calculateInputSubtotal(
          input.count,
          input.unit_price
        )
        if (subtotal !== undefined) {
          validItemCount += 1
        }
      })
      // 複数の有効なアイテムがある場合
      return validItemCount >= 2
    }

    return false
  }

  /**
   * 該当のchildIdのフォームの値が変更されているかを判定します。
   */
  static isInputModified(parentId: number, childId?: number): boolean {
    const parent = SchoolParentService.getParentById(parentId)
    if (!parent) {
      return false
    }

    // 一枚もDBに保存されている請求書が無いか、DBに保存されている最新の請求書が「支払い済」の場合
    if (parent.invoices.length === 0 || parent.invoices[0].status === 'paid') {
      // 各入力欄が初期値かをチェック
      const isChanged = InvoiceItemInputService.getItemInputs(parent.id, childId).some(
        (input) => !InvoiceItemInputService.isInputDefault(input)
      )
      return isChanged
    }

    // 既にDBに保存されている請求書を変更している場合は、入力値とDBの値とを比較
    const invoiceItems = this.getLatestInvoiceItems(parent, childId)
    const invoiceItemInputs = InvoiceItemInputService.getItemInputs(parent.id, childId)

    return invoiceItemInputs.some((input, index) => {
      // DBに保存済みのitemの値を取り出す
      const item = invoiceItems[index]

      // 指定したindexのitemがある場合には、itemとinputの値が違えば変更されているとみなす
      if (item) {
        return (
          item.name !== input.name ||
          String(item.count) !== input.count ||
          String(item.unit_price) !== input.unit_price
        )
      }
      // 指定したindexのitemが無い場合には、inputの値が初期値ではなければ変更されているとみなす
      return !InvoiceItemInputService.isInputDefault(input)
    })
  }

  /**
   * 保存可能な入力値かを判定します。
   * 値が変更されており、有効な入力値の場合にはtrueを返します。
   */
  static hasSavableItemInputs(parentId: number, childId?: number): boolean {
    return (
      InvoiceItemService.isInputModified(parentId, childId) &&
      InvoiceItemService.hasValidInputs(parentId, childId)
    )
  }

  /**
   * parentが持つ、DBに保存されている請求書のうち、最新の請求書の中で指定したchildIdの請求書項目を返します。
   * DBに請求書を持っていない場合には空の配列を返します
   */
  static getLatestInvoiceItems(parent: Parent, childId?: number): InvoiceItem[] {
    if (parent.invoices.length === 0) return []

    return parent.invoices[0].invoice_items.filter(
      (item) => item.school_parent_child_id === childId
    )
  }

  /**
   * - 少なくとも一人、保存可能な請求を持つ子どもがいる
   *
   * または
   * - 子どもに紐付かない請求が保存可能である
   *
   * 場合にtrueを返します
   */
  static getSavableParents(): Parent[] {
    return TopPageStore.schoolParents.filter((parent) =>
      [undefined, ...parent.school_parent_children.map((child) => child.id)].some((childId) =>
        InvoiceItemService.hasSavableItemInputs(parent.id, childId)
      )
    )
  }

  /**
   * invoiceItemのマスタ・履歴をサーバから読み込む
   */
  static async loadInvoiceItemNameCandidates(): Promise<void> {
    const invoiceItemNameCandidate = await InvoiceItemNameCandidateApi.index(
      SchoolService.getTargetFacilityId()
    )
    TopPageStore.updateInvoiceItemNameCandidates(invoiceItemNameCandidate.data.invoice_items)
  }
}
