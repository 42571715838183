import dayjs from 'dayjs'
import { TopPageStore } from '../top_page_store'
import { getFormatDate } from 'shared/utils/date'

/**
 * 対象年月の状態に関するロジックを持ちます。
 */
export class TargetMonthService {
  /**
   * `TopPageStore`に定義されている`targetYear`と`targetMonth`を使った日付を返します。
   * 日は1日で固定です。
   */
  private static getTargetMonthAsDate(): dayjs.Dayjs {
    return dayjs('2000-01-01')
      .set('year', Number(TopPageStore.targetYear))
      .set('month', Number(TopPageStore.targetMonth) - 1)
  }

  /**
   * 対象年月を 'yyyy-MM' 形式で返します
   */
  static getTargetMonthAsString(): string {
    return TopPageStore.targetYear + '-' + TopPageStore.targetMonth
  }

  /**
   * `TopPageStore`に定義されている`targetYear`と`targetMonth`の状態を書き換えます。
   */
  static updateTargetYearAndMonthByDate(date: Date): void {
    TopPageStore.updateTargetYear(getFormatDate(date, 'YYYY'))
    TopPageStore.updateTargetMonth(getFormatDate(date, 'MM'))
  }

  /**
   * `TopPageStore`に定義されている`targetYear`と`targetMonth`を1月前へ変更します。
   */
  static changeToPreviousMonth(): void {
    const previousMonth = dayjs(
      TopPageStore.targetYear + '-' + TopPageStore.targetMonth + '-01'
    ).add(-1, 'month')
    TopPageStore.updateTargetYear(previousMonth.year().toString())
    TopPageStore.updateTargetMonth((previousMonth.month() + 1).toString().padStart(2, '0'))
  }

  static changeYearMonth(year: number, month: number): void {
    TopPageStore.updateTargetYear(year.toString())
    TopPageStore.updateTargetMonth(month.toString().padStart(2, '0'))
  }

  /**
   * `TopPageStore`に定義されている`targetYear`と`targetMonth`を1月後へ変更します。
   */
  static changeToNextMonth(): void {
    const nextMonth = dayjs(TopPageStore.targetYear + '-' + TopPageStore.targetMonth + '-01').add(
      1,
      'month'
    )
    TopPageStore.updateTargetYear(nextMonth.year().toString())
    TopPageStore.updateTargetMonth((nextMonth.month() + 1).toString().padStart(2, '0'))
  }

  static canMoveToPreviousMonth(): boolean {
    // 施設作成日の1ヶ月前を取得 年月のみを基準に計算するため1日に合わせる
    const createdAtOneMonthAgo = new Date(
      TopPageStore.school.created_at.getFullYear(),
      TopPageStore.school.created_at.getMonth() - 1,
      1
    )
    return TargetMonthService.getTargetMonthAsDate().isAfter(createdAtOneMonthAgo)
  }

  static canMoveToNextMonth(): boolean {
    const currentDate = new Date()
    // 現在時刻の翌々月と比較するため、Monthに+2をする
    const limitNextDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 2)
    return TargetMonthService.getTargetMonthAsDate().isBefore(limitNextDate)
  }

  static isLockedMonth(targetYearAndMonth = TargetMonthService.getTargetMonthAsString()): boolean {
    return !!TopPageStore.school.locked_target_year_and_months.find(
      (invoiceLock) => invoiceLock.target_year_and_month === targetYearAndMonth
    )
  }
}
