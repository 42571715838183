<template>
  <div class="invoice-logo_header">
    <div
      v-if="
        !parentConvenienceStoreFeeApplyStatus.current_month &&
        parentConvenienceStoreFeeApplyStatus.next_month
      "
      class="cvs_payment_fee_banner"
    >
      <v-icon>mdi-alert-circle</v-icon>
      <p>
        {{
          getFormatDate(
            parentConvenienceStoreFeeApplyStatus.next_month.applied_at,
            'YYYY年MM月DD日'
          )
        }}以降の請求でコンビニ支払いを選択された場合、別途手数料として{{
          commaSeparate(parentConvenienceStoreFeeApplyStatus.next_month.amount)
        }}円が発生します
      </p>
    </div>
    <S3Image class="invoice-logo enpay-address_logo" :path="logoImagePath"></S3Image>
    <div class="invoice-statement">
      <div class="invoice-statement_header">
        <h1>
          {{ Number(invoice.target_year_and_month.split('-')[0]) }}年{{
            Number(invoice.target_year_and_month.split('-')[1])
          }}月&nbsp;&nbsp;ご請求
        </h1>
        <div class="print-date">
          <span class="label">請求日：</span
          ><span class="date">{{ getFormatDate(invoice.sent_at, 'YYYY年MM月DD日') }}</span>
        </div>
      </div>
      <div class="content">
        <div class="detail">
          <div class="invoice-name">
            <div class="message">
              <span class="invoice-name_school-name">{{
                invoice.school_parent.school.school_name
              }}</span>
            </div>
            <div class="message mt-1">
              <span class="invoice-name_parent-name mr-2">{{
                invoice.school_parent.last_name
              }}</span>
              <span class="invoice-name_parent-name mr-3">
                {{ invoice.school_parent.first_name }}
              </span>
              <span>様</span>
            </div>
          </div>
          <div class="invoice-total">
            <div class="invoice-total_amount">
              <div class="invoice-total_amount_body">
                <div class="label">ご請求金額：</div>
                <div class="amount">&yen;{{ commaSeparate(invoice.total_price) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="qr-capture-and-intro-area">
          <div class="intro">
            <dl class="intro-list">
              <dt class="intro-list_numbered">
                <span>(1)</span>
                <p class="mb-4">
                  お手元のスマートフォン及び携帯電話でQRコードを読み取ってください。
                </p>
              </dt>
              <dt class="intro-list_numbered">
                <span>(2)</span>
                <div class="mb-4">
                  <p>クレジットカードかコンビニか、支払い方法を選んでください。</p>
                  <p
                    v-if="parentConvenienceStoreFeeApplyStatus.current_month"
                    class="font-weight-regular"
                  >
                    ※コンビニ支払いの場合、手数料{{
                      commaSeparate(parentConvenienceStoreFeeApplyStatus.current_month.amount)
                    }}円がかかります。
                  </p>
                </div>
              </dt>
              <dt class="intro-list_numbered">
                <span>(3)</span>
                <div>
                  <p class="mb-4">必要な情報を入力し、お支払いください。</p>
                  <div class="intro-list_cannot-read-qr">
                    <p>QRコードが読み取れない方は、こちらから</p>
                    <p>{{ selectPaymentMethodUrl }}</p>
                  </div>
                </div>
              </dt>
            </dl>
          </div>
          <div class="qr">
            <div class="qr-code-image">
              <canvas ref="qrCodeRef"></canvas>
            </div>
            <p class="qr-read-explanation mb-4">QRコードを読み取って<br />支払い方法を選択</p>
          </div>
        </div>
        <div class="detail spacing">
          <div class="collection-detail">
            <div class="text-h6">
              <h2 class="invoice-title">支払い明細</h2>
            </div>
            <template v-if="invoice.childless_invoice_items.invoice_items.length > 0">
              <div class="invoice-body">
                <div class="invoice-body_name">&nbsp;</div>
                <div class="invoice-body_details">
                  <table class="body">
                    <thead>
                      <tr>
                        <th class="filed pa-2">請求内容</th>
                        <th class="filed pa-2">単価</th>
                        <th class="filed pa-2">数量</th>
                        <th class="filed pa-2">金額</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in invoice.childless_invoice_items.invoice_items"
                        :key="item.id"
                      >
                        <td class="pa-2">{{ item.name }}</td>
                        <td class="pa-2 text-right">{{ commaSeparate(item.unit_price) }}</td>
                        <td class="pa-2 text-right">
                          {{ item.count }}
                        </td>
                        <td class="pa-2 text-right">
                          {{ commaSeparate(item.count * item.unit_price) }}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3" class="total pa-2 text-right">合計金額</td>
                        <td class="total pa-2 text-right">
                          {{ commaSeparate(invoice.childless_invoice_items.subtotal) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </template>
            <template v-for="child in invoice.children_with_invoice_items" :key="child.id">
              <div class="invoice-body">
                <div class="invoice-body_name">
                  <p class="invoice-body_name_name">{{ child.child.first_name }}さん</p>
                  <p v-if="isShowClassName" class="invoice-body_name_class-name">
                    {{ child.child.class_name }}
                  </p>
                </div>
                <div class="invoice-body_details">
                  <table :key="child.child.id" class="body">
                    <thead>
                      <tr :key="child.child.id">
                        <th class="filed pa-2">請求内容</th>
                        <th class="filed pa-2">単価</th>
                        <th class="filed pa-2">数量</th>
                        <th class="filed pa-2">金額</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in child.invoice_items" :key="item.id" class="indent">
                        <td class="name pa-2">{{ item.name }}</td>
                        <td class="pa-2 text-right">{{ commaSeparate(item.unit_price) }}</td>
                        <td class="pa-2 text-right">
                          {{ item.count }}
                        </td>
                        <td class="pa-2 text-right">
                          {{ commaSeparate(item.count * item.unit_price) }}
                        </td>
                      </tr>
                      <tr :key="child.child.id">
                        <td colspan="3" class="total pa-2 text-right">合計金額</td>
                        <td class="total pa-2 text-right">{{ commaSeparate(child.subtotal) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, ComputedRef } from 'vue'
import { FilterService } from '../../../shared/services/filter_service'
import { InvoicePrintPageStore } from '../invoice_print_page_store'
import { AppStore } from 'shared/app_store'
import S3Image from 'shared/components/s_3_image/S3Image.vue'
import { Invoice } from 'http/modules/unpaid_invoice'
import QRCode from 'qrcode'
import { getFormatDate } from 'shared/utils/date'
import { School } from 'http/modules/facility'

/**
 * 請求書1枚を表すコンポーネントです。
 * 印刷用のため、紙の請求書のようなデザインになっています。
 */
const props = defineProps<{
  invoiceProp: Invoice
  parentConvenienceStoreFeeApplyStatus: School['parent_convenience_store_fee_apply_status']
}>()

const qrCodeRef = ref(null)
const invoice: ComputedRef<Invoice> = computed(() => props.invoiceProp as Invoice)
const commaSeparate = (num: number) => FilterService.commaSeparate(num)
const isShowClassName = computed(() => InvoicePrintPageStore.isShowClassName)
const logoImagePath = computed(() => AppStore.oemPartner.logo_image_path)
const selectPaymentMethodUrl = computed(() => props.invoiceProp.payment_method_url)

onMounted(() => {
  QRCode.toCanvas(qrCodeRef.value, props.invoiceProp.payment_method_url, {
    width: 120,
    margin: 0,
  })
})
</script>

<style scoped>
.cvs_payment_fee_banner {
  width: 210mm;
  height: 40px;
  color: #d70000;
  background-color: #fbebed;
  font-weight: 700;
  line-height: 24px;
  font-size: 14px;
  margin: -20px -20px 0px -20px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
}

@media print {
  .cvs_payment_fee_banner {
    margin: 0px;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
</style>
