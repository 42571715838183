import { TransferStatement } from 'shared/models'
import { HTTP } from 'http/common'
import { AxiosResponse } from 'axios'

export type ShowResponse = {
  transfer_statement: TransferStatement
  start_calc_paid_date: string
  end_calc_paid_date: string
}

export class AdminTransferStatementApi {
  /**
   * @param monthlyTransferId
   */
  static show(monthlyTransferId: number): Promise<AxiosResponse<ShowResponse>> {
    return HTTP.get(`/admin/transfer_statements/${monthlyTransferId}`)
  }
}
