<template>
  <SimpleLayout title="新しく保護者・子どもを登録" is-expanded class="container">
    <template #headerNav>
      <FacilityHeader />
    </template>
    <template #headerControl>
      <HeaderControl title="保護者一覧に戻る" :path="backToUrl" />
    </template>
    <template #headerContent>
      <div class="d-flex">
        <div class="d-flex align-center">
          <LinkText :link-url="faqPageUrl" is-open-new-tab :has-underline="false">
            <v-icon color="primary">mdi-help-circle-outline</v-icon>
            よくある質問を確認する
          </LinkText>
        </div>
      </div>
    </template>

    <template #main>
      <Dialog />
      <v-form ref="parentAddForm" style="width: 100%">
        <template v-for="(parent, i) in parents" :key="parent.temporaryId">
          <ParentEditableField
            data-testid="parent-editable-field"
            :parent="parent"
            :parents="parents"
            :style="i > 0 ? 'margin-top: 4px !important' : ''"
            :show-deletion-button="i > 0"
            @add-child="() => addChild(i)"
            @remove-parent="() => removeParent(i)"
            @remove-child="(childrenIndex) => removeChild(i, childrenIndex)"
          />
        </template>
        <v-sheet width="100%" class="mx-auto pa-4" style="margin-top: 4px !important">
          <div class="text-right">
            <Button size="large" variant="outlined" @click="addParent">
              <v-icon class="pr-1">mdi-plus-circle-outline</v-icon>
              <span class="font-weight-bold fs-16">保護者を追加する</span>
            </Button>
          </div>
        </v-sheet>
        <div class="text-center" style="margin-top: 32px">
          <Button
            size="large"
            style="min-width: 30%"
            :disabled="isDisabled || !hasChanged()"
            @click="() => createParents()"
          >
            <span class="font-weight-bold fs-16">登録する</span>
          </Button>
        </div>
      </v-form>
    </template>
  </SimpleLayout>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue'
import SimpleLayout from 'shared/templates/SimpleLayout.vue'
import FacilityHeader from 'shared/components/header/FacilityHeader.vue'
import HeaderControl from 'pages/parents_page/components/HeaderControl.vue'
import Button from 'shared/components/Button.vue'
import { EditableChild, EditableParent } from 'pages/parents_page/model'
import ParentEditableField from 'pages/parents_page/parent_add/components/ParentEditableField.vue'
import { ClassManagementService } from 'pages/parents_page/parent_add/services/class_management_service'
import LinkText from 'shared/components/LinkText.vue'
import { ParentsAddPageStore } from 'pages/parents_page/parent_add/parent_adds_page_store'
import { ParentsService } from 'pages/parents_page/services/parents_service'
import { ParentAddService } from 'pages/parents_page/parent_add/services/parent_add_service'
import Dialog from 'shared/components/Dialog.vue'
import { DialogService } from 'shared/services/dialog_service'
import ExternalLink from 'shared/consts/external_link'
import { ParentsPageStore } from 'pages/parents_page/parents_page_store'
import { MetaService } from 'shared/services/meta_service'

export default defineComponent({
  components: {
    Dialog,
    LinkText,
    ParentEditableField,
    Button,
    HeaderControl,
    FacilityHeader,
    SimpleLayout,
  },
  // eslint-disable-next-line max-lines-per-function
  setup() {
    MetaService.addTitle({ mode: 'facility', page: 'parentAdd' })
    ClassManagementService.loadClasses()

    const handler = (event: BeforeUnloadEvent) => {
      event.returnValue = '行った変更が保存されない可能性があります'
    }

    onUnmounted(() => {
      DialogService.close()
      ParentsAddPageStore.updateParents([])
      window.removeEventListener('beforeunload', handler)
    })

    onMounted(() => {
      window.addEventListener('beforeunload', handler)
    })

    const faqPageUrl = ExternalLink.openpageFaqForParentList
    const facilityId = ParentsService.getTargetFacilityId()
    const { class_id, parent_name, child_name, line_connected, page } = ParentsPageStore.searchQuery
    const backToUrl = `/facilities/${facilityId}/parents?class_id=${class_id || ''}&parent_name=${
      parent_name || ''
    }&child_name=${child_name || ''}&line_connected=${line_connected}&page=${page}`
    const parentAddForm = ref<HTMLFormElement | null>(null)
    const defaultChild: EditableChild = {
      temporaryId: -1,
      firstName: '',
      code: '',
    }
    const defaultParent: EditableParent = {
      temporaryId: -1,
      firstName: '',
      lastName: '',
      code: '',
      schoolParentChildren: [{ ...defaultChild }],
    }
    const isDisabled = computed(() => ParentsAddPageStore.isDisabled)

    const parents = computed({
      get: () => ParentsAddPageStore.parents,
      set: (value) => ParentsAddPageStore.updateParents(value),
    })

    const handleCreateSuccess = () => {
      window.removeEventListener('beforeunload', handler)
      ParentsPageStore.updateIsModifySuccess(true)
      // NOTE: 作成成功。一覧へ遷移させる
      location.href = backToUrl
    }

    const createParents = async () => {
      await ParentAddService.startCreateConfirmation({
        onSuccess: () => {
          handleCreateSuccess()
        },
        onValidate: () => parentAddForm.value?.validate(),
      })
      parentAddForm.value?.validate()
    }

    const addParent = () => {
      const parent: EditableParent = {
        // Shallow copy
        ...defaultParent,
        temporaryId: ParentsAddPageStore.generateParentId(),
        schoolParentChildren: [
          { ...defaultChild, temporaryId: ParentsAddPageStore.generateChildId() },
        ],
      }

      parents.value.push({ ...parent })
    }

    const addChild = (index: number) => {
      parents.value[index].schoolParentChildren.push({
        ...defaultChild,
        temporaryId: ParentsAddPageStore.generateChildId(),
      })
    }

    const removeParent = (index: number) => {
      parents.value = parents.value.filter((_, arrayIndex) => arrayIndex !== index)
    }

    const removeChild = (parentIndex: number, index: number) => {
      parents.value[parentIndex].schoolParentChildren = parents.value[
        parentIndex
      ].schoolParentChildren.filter((_, arrayIndex) => arrayIndex !== index)
    }

    // 初期データ
    addParent()

    const hasChanged = () =>
      parents.value.some((parent) => parent.lastName !== '' && parent.firstName !== '')

    return {
      parents,
      faqPageUrl,
      backToUrl,
      addParent,
      addChild,
      removeParent,
      removeChild,
      createParents,
      isDisabled,
      parentAddForm,
      hasChanged,
    }
  },
})
</script>

<style scoped>
@media (1264px <= width) {
  .container {
    max-width: 1185px;
  }
}

@media (960px < width <= 1264px) {
  .container {
    max-width: 900px;
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding: 12px;
  width: 100%;
}
</style>
