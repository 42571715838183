<template>
  <FormContainer>
    <template #title>表示設定</template>
    <template #form-content>
      <v-row>
        <v-col cols="5">
          <div class="text-subtitle-1 font-weight-medium mb-2">請求一覧の初期表示月</div>
          <div class="text-subtitle-1 font-weight-medium item-description">
            請求一覧を開いたときに最初に表示する月を選択できます。
          </div>
        </v-col>
        <v-spacer />
        <v-col cols="5">
          <SelectInput
            variant="outlined"
            :value="invoicePageInitialTargetMonth"
            :items="[
              { value: 'last_month', label: '先月' },
              { value: 'this_month', label: '今月' },
              { value: 'next_month', label: '来月' },
            ]"
            item-text="label"
            item-value="value"
            hide-details
            @change="handleChange"
          >
            <template #append>
              <v-icon color="primary">mdi-menu-down</v-icon>
            </template>
          </SelectInput>
          <span class="text-caption font-weight-medium item-description pt-2">
            例）現在「4月」で「先月」を設定→初期表示月は「3月」
          </span>
        </v-col>
      </v-row>
    </template>
    <template #actions>
      <Button
        size="large"
        variant="outlined"
        data-testid="initial-month-button"
        @click="handleSubmit"
      >
        保存する
      </Button>
    </template>
  </FormContainer>
</template>
<script setup lang="ts">
import FormContainer from 'pages/account_settings_page/components/FormContainer.vue'
import Button from 'shared/components/Button.vue'
import SelectInput from 'shared/components/SelectInput.vue'
import { InvoicePageInitialTargetMonth } from 'shared/models'

defineProps<{
  invoicePageInitialTargetMonth: InvoicePageInitialTargetMonth
}>()
const emit = defineEmits<{
  (e: 'change', value: InvoicePageInitialTargetMonth): void
  (e: 'submit'): void
}>()
const handleChange = (value: InvoicePageInitialTargetMonth) => emit('change', value)
const handleSubmit = () => emit('submit')
</script>
<style scoped>
.item-description {
  color: #727272;
}
</style>
