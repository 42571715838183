<template>
  <v-card
    elevation="3"
    class="invoice-list-bottom-card"
    :class="isBulkSavable || isBulkChargable || isBulkRemindable ? 'active' : ''"
  >
    <div class="contents-wrapper">
      <CountButton
        data-testid="batch-save-button"
        text="一括保存"
        :disabled="!isBulkSavable"
        :count="savableParentCount"
        @click="bulkSave"
      >
      </CountButton>
      <v-tooltip location="top" :disabled="isBulkChargable">
        <template #activator="{ props }">
          <div v-bind="props">
            <CountButton
              data-testid="batch-charge-button"
              text="一括請求"
              :disabled="!isBulkChargable"
              :count="chargableInvoiceCount"
              @click="bulkCharge"
            ></CountButton>
          </div>
        </template>
        <span>
          {{ isLockedMonth ? lockedMonthTooltipText : '「未払い」タブ以外で利用できます' }}
        </span>
      </v-tooltip>
      <v-tooltip location="top" :disabled="isBulkRemindable">
        <template #activator="{ props }">
          <div v-bind="props">
            <CountButton
              text="選択中の保護者に一括再請求"
              :disabled="!isBulkRemindable"
              :loading="isBulkReminding"
              @click="bulkRemind"
            ></CountButton>
          </div>
        </template>
        <span>「未払い」タブを選択し、保護者を選択してください</span>
      </v-tooltip>
      <v-tooltip location="top" :disabled="isBulkCancelable">
        <template #activator="{ props }">
          <div v-bind="props">
            <CountButton
              text="選択中の請求を一括取り消し"
              button-style="error"
              :disabled="!isBulkCancelable"
              :loading="isBulkReminding"
              @click="bulkCancel"
            ></CountButton>
          </div>
        </template>
        <span>
          {{
            isLockedMonth
              ? lockedMonthTooltipText
              : '「未払い」タブを選択し、保護者を選択してください'
          }}
        </span>
      </v-tooltip>
    </div>
  </v-card>
</template>
<script lang="ts">
import { computed, defineComponent } from 'vue'
import { TopPageStore } from '../top_page_store'
import { InvoiceItemService } from '../services/invoice_item_service'
import { SchoolParentService } from '../services/school_parent_service'
import CountButton from './CountButton.vue'
import { InvoiceService } from '../services/invoice_service'

export default defineComponent({
  components: {
    CountButton,
  },
  /* eslint max-lines-per-function: ["error", {"max": 50, "skipBlankLines": true}] */
  setup() {
    const savableParentCount = computed(() => InvoiceItemService.getSavableParents().length)
    const chargableInvoiceCount = computed(
      () =>
        TopPageStore.schoolParents.filter(
          (parent) => parent.invoices.length > 0 && parent.invoices[0].status === 'before_charge'
        ).length
    )
    const isBulkSavable = computed(() => {
      if (TopPageStore.isLockedMonth) return false
      return savableParentCount.value > 0
    })
    const isBulkChargable = computed(() => {
      if (TopPageStore.isLockedMonth) return false

      return (
        chargableInvoiceCount.value > 0 && TopPageStore.searchCondition.paymentStatus !== 'unpaid'
      )
    })
    const isInUnpaidTab = () => TopPageStore.searchCondition.paymentStatus === 'unpaid'
    const isParentChecked = () =>
      SchoolParentService.getSearchedSchoolParents().some((parent) => parent.isChecked)
    const isBulkRemindable = computed(() => isInUnpaidTab() && isParentChecked())
    const isBulkCancelable = computed(() => {
      if (TopPageStore.isLockedMonth) return false
      return isInUnpaidTab() && isParentChecked()
    })

    const isBulkReminding = computed(() => TopPageStore.isBulkReminding)

    const isLockedMonth = computed(() => TopPageStore.isLockedMonth)
    const lockedMonthTooltipText = '請求が締められているため、利用できません'

    const bulkSave = () => InvoiceService.bulkSave()
    const bulkCharge = () => InvoiceService.bulkChargeToBeforeChargeParents()
    const bulkRemind = () => InvoiceService.bulkRemind()
    const bulkCancel = () => InvoiceService.bulkCancel()

    return {
      isBulkChargable,
      isBulkSavable,
      isBulkRemindable,
      isBulkCancelable,
      savableParentCount,
      chargableInvoiceCount,
      isBulkReminding,
      isLockedMonth,
      lockedMonthTooltipText,
      bulkSave,
      bulkCharge,
      bulkRemind,
      bulkCancel,
    }
  },
})
</script>

<style scoped lang="scss">
// 一括ボタン
.invoice-list-bottom-card {
  display: none;
  margin: 0 auto;
  padding: 12px;
  text-align: center;
  position: fixed;
  bottom: 24px;
  width: calc(100% - 364px);

  &.active {
    display: block;

    .contents-wrapper {
      display: flex;
      gap: 1rem;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}
</style>
