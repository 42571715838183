<template>
  <div>
    <p>{{ lastName }} {{ firstName }}さんのLINE連携を解除します。よろしいですか？</p>
    <p class="mt-5">
      LINE連携を解除すると、保護者のLINEへ「解除されました」というメッセージが届きます。
    </p>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
  },
})
</script>
