import { reactive } from 'vue'

type ScreenLoadingState = {
  show: boolean
}

const state = reactive<ScreenLoadingState>({
  show: false,
})

/**
 * 全画面に表示される、黒い背景のローダーです。
 *
 * 使い方：
 * 1. ページのどこかに1つだけ、<ScreenLoading /> タグを書く。(XxxPage.vue推奨)
 * 2. ScreenLoadingService#showLoader を呼び出す。閉じるときはhideLoader。
 *
 * 注意：
 * Vuetify以外で独自のCSSでz-index等を指定している場合に、
 * オーバーレイの上にその要素が来てしまわないかは確認しておいてください。
 */
export class ScreenLoadingService {
  static get show(): boolean {
    return state.show
  }

  static showLoader(): void {
    state.show = true
  }

  static hideLoader(): void {
    state.show = false
  }
}
