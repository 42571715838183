<template>
  <div class="d-flex flex-column">
    <label for="name-filter" class="font-weight-medium text-body-2 text-grey mb-2"
      >保護者・子どもの名前</label
    >
    <TextInput
      id="name-filter"
      :value="value"
      :on-change="(v: string) => (value = v)"
      placeholder="例：エンペイ 太郎"
      hide-details
      append-inner-icon="mdi-magnify"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { TopPageStore } from 'pages/top_page/top_page_store'
import { ParentSearchConditionService } from 'pages/top_page/services/parent_search_condition_service'
import TextInput from 'shared/components/TextInput.vue'

const value = computed({
  get: () => TopPageStore.searchCondition.name,
  set: (value) => ParentSearchConditionService.updateNameCondition(value),
})
</script>

<style scoped lang="scss"></style>
