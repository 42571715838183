<template>
  <v-form ref="corpInfoPanel3" v-model="isValid.corpInfoPanel3">
    <div class="ml-3">
      <p>会社住所</p>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="formValue.postalCode"
            label="郵便番号（ハイフン無し）"
            :rules="postalCodeRules"
          ></v-text-field>
        </v-col>
        <v-col>
          <addressSearchButton
            class="mt-3"
            :postal-code="formValue.postalCode"
            @handle-search-result="handleSearchResult"
          />
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="6" md="4">
          <v-select
            v-model="formValue.prefectureName"
            :items="items"
            label="都道府県"
            :rules="addressNameRules"
          ></v-select>
        </v-col>
      </v-row>
      <v-text-field
        v-model="formValue.prefectureNameKana"
        label="都道府県（フリガナ）"
        hint="例）トウキョウト"
        :rules="addressNameKanaWithoutBuildingNameRules"
        class="mb-3"
      ></v-text-field>
      <v-text-field
        v-model="formValue.cityName"
        label="市区町村"
        hint="例）港区"
        :rules="addressNameRules"
        class="mb-3"
      ></v-text-field>
      <v-text-field
        v-model="formValue.cityNameKana"
        label="市区町村（フリガナ）"
        hint="例）ミナトク"
        :rules="addressNameKanaWithoutBuildingNameRules"
        class="mb-3"
      ></v-text-field>
      <v-text-field
        v-model="formValue.townName"
        label="町名"
        hint="例）港南"
        :rules="addressNameRules"
        class="mb-3"
      ></v-text-field>
      <v-text-field
        v-model="formValue.townNameKana"
        label="町名（フリガナ）"
        hint="例）コウナン"
        :rules="addressNameKanaWithoutBuildingNameRules"
        class="mb-3"
      ></v-text-field>
      <v-text-field
        v-model="formValue.streetName"
        label="番地"
        hint="例）２ー１５ー１"
        :rules="streetNameRules"
        class="mb-3"
      ></v-text-field>
      <v-text-field
        v-model="formValue.buildingName"
        label="建物名（任意）"
        hint="例）品川インターシティＡ棟２２階ＳＰＲＯＵＮＤ"
        :rules="buildingNameRules"
        class="mb-3"
      ></v-text-field>
      <v-text-field
        v-model="formValue.buildingNameKana"
        label="建物名（フリガナ）（任意）"
        hint="例）シナガワインターシティＡトウ２２カイＳＰＲＯＵＮＤ"
        :rules="buildingNameKanaRules"
        class="mb-3"
      ></v-text-field>
      <v-text-field
        v-model="formValue.phoneNumber"
        label="電話番号（ハイフンあり）"
        hint="例）03-1234-5678"
        :rules="phoneNumberRules"
      ></v-text-field>
      <div v-show="false">{{ isInitial.corpInfoPanel3 }}</div>
    </div>
  </v-form>
</template>

<script>
import { CorporationApplyPageStore } from '../corporation_apply_page_store'
import { prefectureService } from '../../../shared/services/prefecture_service'
import { CorporationValidatorsService } from '../services/corporation_validators_service'
import addressSearchButton from '../../../shared/components/AddressSearchButton'

export default {
  components: { addressSearchButton },
  data: () => ({
    items: prefectureService.getAllPrefectures(),
    postalCodeRules: CorporationValidatorsService.postalCodeRules('郵便番号'),
  }),
  computed: {
    formValue() {
      return CorporationApplyPageStore.formValue
    },
    isValid() {
      return CorporationApplyPageStore.validState
    },
    isInitial() {
      if (!CorporationApplyPageStore.initializedState.corpInfoPanel3) {
        this.$refs.corpInfoPanel3.validate()
      }
      return CorporationApplyPageStore.initializedState
    },
    addressNameRules() {
      return CorporationValidatorsService.addressNameRules(
        '都道府県・市区町村・町名・番地',
        this.formValue
      )
    },
    streetNameRules() {
      return CorporationValidatorsService.streetNameRules(
        '都道府県・市区町村・町名・番地',
        this.formValue
      )
    },
    buildingNameRules() {
      if (!this.formValue.buildingName) return []
      return CorporationValidatorsService.buildingNameRules(this.formValue)
    },
    addressNameKanaWithoutBuildingNameRules() {
      return CorporationValidatorsService.addressNameKanaWithoutBuildingNameRules(
        '都道府県（フリガナ）・市区町村（フリガナ）・町名（フリガナ）・番地（フリガナ）',
        this.formValue
      )
    },
    buildingNameKanaRules() {
      if (!this.formValue.buildingNameKana) return []
      return CorporationValidatorsService.buildingNameKanaRules(this.formValue)
    },
    phoneNumberRules() {
      return CorporationValidatorsService.phoneNumberRules('電話番号')
    },
  },
  methods: {
    handleSearchResult(address) {
      const updatedForm = {
        ...this.formValue,
        prefectureName: address.prefecture.name,
        cityName: address.city,
        townName: address.town,
      }
      CorporationApplyPageStore.updateFormValue(updatedForm)
    },
  },
}
</script>
