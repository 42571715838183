import { computed, ComputedRef } from 'vue'
import { SnackbarService } from 'shared/services/snackbar_service'
import { ImportPanelStore } from '../import_panel_store'
import {
  ColumnMappingValues,
  CsvImportColumnCandidate,
  GetImportStatusFunction,
  ImportCsvFunction,
  ImportError,
} from '../models'
import {
  columnMappingSaveKeyPrefix,
  ImportPanelService,
  maxPreviewRowCount,
} from './import_panel_service'
import rfdc from 'rfdc'
import { EventTracker } from 'shared/utils/_event_tracking'
import { isAxiosError } from 'http/modules/invoice'
const clone = rfdc()

/**
 * カラムマッピングパネルに必要なロジックを持ちます
 */
export class ColumnMappingService {
  /**
   * カラムマッピングする際に表示するサンプルデータは合計10行表示される。
   * その際、APサーバーからは先端と末尾の5行ずつが返されるが、合計行数が例えば8行だったとしても、
   * 5行ずつ返される。そのため、重複する行は表示しないよう制御しなければならない。
   * このメソッドは、返されたend_rowsのうち、重複排除した行を返します。
   */
  static dedupedEndRows(): ComputedRef<string[][]> {
    return computed(() => {
      if (ImportPanelStore.csvFileMetadata.total_count <= maxPreviewRowCount / 2) {
        // 全体行数が5行以下であればend_rowsは使わない
        return []
      } else if (ImportPanelStore.csvFileMetadata.total_count < maxPreviewRowCount) {
        // 全体行数が6~9行であればend_rowsの一部を使う
        return ImportPanelStore.csvFileMetadata.end_rows.slice(
          maxPreviewRowCount / 2 -
            (ImportPanelStore.csvFileMetadata.total_count - maxPreviewRowCount / 2)
        )
      }
      // 全体行数が10行以上であれば全て使う
      return ImportPanelStore.csvFileMetadata.end_rows
    })
  }

  /**
   * まだマッピングされていないカラム候補が返ります
   */
  static notMappedCandidates(): CsvImportColumnCandidate[] {
    const mappedColumnIds = ImportPanelStore.mappedColumnIds // ここで変数に入れないとstate変更時にcomputedの再計算が走りません
    return ImportPanelStore.columnCandidates.filter(
      (candidate) => !candidate.options.some((option) => mappedColumnIds.includes(option.column_id))
    )
  }

  /**
   * カラムマッピングのドロップダウンの値が変更されたときに、store値を変える処理。
   * @param columnId v-selectのclearアイコンが押下されたときはnullが渡ってくるためnullを受け取れるようにしています
   */
  static changeMappedColumnIds(columnIndex: number, columnId: number | null): void {
    // マッピング情報を変更
    const mappedColumnIds = clone(ImportPanelStore.mappedColumnIds)
    mappedColumnIds[columnIndex] = columnId === null ? undefined : columnId
    ImportPanelStore.updateMappedColumnIds(mappedColumnIds)

    // 当該カラムの自動マッピングの状態をリセット
    const autoMappingStatuses = clone(ImportPanelStore.autoMappingStatuses)
    autoMappingStatuses[columnIndex] = false
    ImportPanelStore.updateAutoMappingStatuses(autoMappingStatuses)
  }

  // eslint-disable-next-line max-lines-per-function
  static async executeImportCsv(
    importCsv: ImportCsvFunction,
    getImportStatus: GetImportStatusFunction
  ): Promise<void> {
    if (
      ImportPanelStore.importTargetFile === undefined ||
      !ImportPanelStore.importTargetFile.length
    )
      return
    ImportPanelStore.importStatus.status = 'processing'

    ImportPanelStore.updateIsImporting(true)

    let importId: number
    try {
      ColumnMappingService.saveColumnMapping()

      importId = await importCsv(
        ImportPanelStore.mappedColumnIds,
        ImportPanelStore.isFirstLineIgnored,
        ImportPanelStore.importTargetFile[0]
      )
    } catch (error) {
      if (isAxiosError(error) && error.response !== undefined) {
        const message =
          error.response.status === 403
            ? '請求が締められているため、請求の内容は編集できません。'
            : '取り込みに失敗しました。'
        SnackbarService.open(message, 'error')
        return
      }
    } finally {
      ImportPanelStore.updateIsImporting(false)
    }
    ImportPanelService.incrementStep()
    // import処理開始後、2秒ごとにimport状況を取得し画面に反映する
    const intervalId = setInterval(async () => {
      const importStatus = await getImportStatus(importId)
      ImportPanelStore.updateImportStatus(importStatus)
      switch (importStatus.status) {
        case 'done':
          clearInterval(intervalId)
          EventTracker.trackEvent('click_btn_invoice_exe_csv_import', {})
          break
        case 'error':
          clearInterval(intervalId)
          if (ColumnMappingService.includesUnpaidInvoiceError(importStatus.errors)) {
            EventTracker.trackEvent('unpaid_error_when_import_invoice_csv', {})
          }
          break
        default:
      }
    }, 2000)
  }

  private static includesUnpaidInvoiceError(errors: ImportError[]): boolean {
    return errors.some((error) => error.message === '未払いのため請求できません。')
  }

  /**
   * 取り込み時のカラムマッピングデータをlocalStorageへ保存しておく。
   * 次回、この値を使って入力補完するため。
   */
  private static saveColumnMapping(): void {
    localStorage.setItem(
      columnMappingSaveKeyPrefix + ImportPanelStore.columnMappingSaveKey,
      JSON.stringify(
        ImportPanelStore.mappedColumnIds.reduce(
          (mappedValues: ColumnMappingValues, current, index) => {
            if (current === undefined) return mappedValues

            mappedValues[ImportPanelStore.csvFileMetadata.start_rows[0][index]] =
              ImportPanelStore.columnCandidates
                .flatMap((candidate) => candidate.options)
                .find((candidate) => candidate.column_id === current)!.column_id
            return mappedValues
          },
          {}
        )
      )
    )
  }
}
