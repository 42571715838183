<!-- eslint-disable vuetify/no-deprecated-classes -->
<template>
  <v-card class="card pa-6 mb-5">
    <div class="d-flex mb-6">
      <div :class="numberTagClassName">1</div>
      <div class="flex-grow-1">
        <div>テンプレートをダウンロードしてください。</div>
        <Button
          class="mt-3 mr-3"
          variant="outlined"
          data-turbolinks="false"
          :disabled="isLoading"
          @click="$emit('downloadTemplate', 'csv')"
        >
          CSVテンプレートをダウンロード
        </Button>
        <Button
          class="mt-3"
          variant="outlined"
          data-turbolinks="false"
          :disabled="isLoading"
          @click="$emit('downloadTemplate', 'excel')"
        >
          Excelテンプレートをダウンロード
        </Button>
        <div class="text-grey-darken-1 mt-3">
          ※ CSVテンプレートの方が一部機能が充実しています。
          <LinkText :link-url="csvImportHelppageUrl" is-open-new-tab> 使い方はこちら </LinkText>
        </div>
      </div>
    </div>

    <div class="d-flex my-6 py-2">
      <div :class="numberTagClassName">2</div>
      <div class="flex-grow-1">
        <div>テンプレートに記入してください。</div>
        <div class="mb-1 mt-2">
          ・
          <LinkText :link-url="csvImportHelppageUrl" is-open-new-tab> CSV記入例 </LinkText>
        </div>
        <div class="mt-1">
          ・
          <LinkText :link-url="excelImportHelppageUrl" is-open-new-tab> Excel記入例 </LinkText>
        </div>
      </div>
    </div>

    <div class="d-flex mt-6 mb-3">
      <div :class="numberTagClassName">3</div>
      <div class="flex-grow-1">
        記入済みのファイルを選択後、「取り込む」を押すと完了します。<br />
        <span class="text-grey-darken-1 mt-1">
          ※ ダウンロードしたテンプレートをどちらも選択することができます。
          <br />
          &#12288;
          取り込み件数が多いと処理に時間がかかる場合があります。処理が完了するまでお待ちください。
        </span>
        <v-alert v-if="importFileErrorsCount > 0" type="error" variant="outlined" class="mt-4">
          <span class="font-weight-bold">
            {{
              importFileErrorsCount
            }}件のエラーが発生しました。以下の内容を確認のうえ、ファイルを修正してください。
          </span>
          <br />
          <span class="alert-text__normal">修正したら、再度取り込んでください。</span>
          <template v-for="(errorsGroupedByRowNum, index) in importFileErrors" :key="index">
            <v-row style="margin: -1px 0">
              <v-col cols="2" class="grey-out border pa-0">
                <p class="grid-content__text text-center ma-4">
                  {{ errorsGroupedByRowNum[0].row_num }}行目
                </p>
              </v-col>
              <v-col cols="3" class="border pa-0">
                <template
                  v-for="(error, columnNumIndex) in errorsGroupedByRowNum"
                  :key="columnNumIndex"
                >
                  <div>
                    <template v-if="columnNumIndex > 0">
                      <v-divider />
                    </template>
                    <p class="grid-content__text text-center ma-4">
                      {{ error.col_num }}列目: {{ error.attribute_name }}
                    </p>
                  </div>
                </template>
              </v-col>
              <v-col cols="7" class="border pa-0 border-right">
                <template
                  v-for="(error, errorDetailIndex) in errorsGroupedByRowNum"
                  :key="errorDetailIndex"
                >
                  <div>
                    <template v-if="errorDetailIndex > 0">
                      <v-divider />
                    </template>
                    <p class="grid-content__text text-left ma-4">
                      {{ getErrorTypeDetail(error.error_type) }}
                    </p>
                  </div>
                </template>
              </v-col>
            </v-row>
          </template>
        </v-alert>
        <div class="mt-4 w-60">
          <v-file-input
            v-model="importTargetFile"
            color="primary"
            variant="outlined"
            truncate-length="50"
            chips
            label="ファイルを選択"
            accept=".csv, .xlsx"
            clear-icon="mdi-close"
            prepend-icon=""
            :disabled="isLoading"
            :rules="fileRules"
          />
        </div>
      </div>
    </div>

    <div class="d-flex">
      <div class="rounded-number mr-2"></div>
      <Button
        size="large"
        width="223px"
        :disabled="isLoading || !isValidFile"
        :loading="isLoading"
        @click="$emit('handleClickUploadFile')"
      >
        取り込む
      </Button>
      <Button
        size="large"
        variant="text"
        color="normal"
        class="ml-1"
        :disabled="isLoading"
        :href="backPath"
      >
        戻る
      </Button>
    </div>
  </v-card>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import Button from 'shared/components/Button.vue'
import LinkText from 'shared/components/LinkText.vue'
import { ImportParentsPageStore } from 'pages/import_parents_page/import_parents_page_store'
import { ImportParentsService } from 'pages/import_parents_page/services/import_parents_service'
import { FileService } from 'shared/services/file_service'

defineProps<{
  backPath: string
  isDeprecatedBrowser: boolean
  csvImportHelppageUrl: string
  excelImportHelppageUrl: string
  isLoading: boolean
}>()

defineEmits<{
  (event: 'handleClickUploadFile'): void
  (event: 'downloadTemplate', fileType: 'csv' | 'excel'): void
}>()

const numberTagClassName =
  'text-highlighted rounded-circle rounded-number px-1 mr-2 d-flex justify-center align-center'

const importTargetFile = computed({
  get: () => ImportParentsPageStore.importTargetFile,
  set: (value) => {
    ImportParentsPageStore.updateImportTargetFile(value)
  },
})
const isValidFile = computed(() => {
  if (!importTargetFile.value.length) return false
  return (
    FileService.isCsvFile(importTargetFile.value[0]) ||
    FileService.isExcelFile(importTargetFile.value[0])
  )
})
const importFileErrors = computed(() => ImportParentsPageStore.importFileErrors)
const importFileErrorsCount = computed(() => ImportParentsPageStore.importFileErrorsCount)
const getErrorTypeDetail = ImportParentsService.getErrorTypeDetail

const fileRules = [
  (v: File[]) => {
    if (!v.length) return true
    return FileService.isCsvFile(v[0]) || FileService.isExcelFile(v[0])
      ? true
      : 'CSVまたはExcelファイルを選択してください'
  },
]
</script>

<style scoped lang="scss">
@import '../../../../assets/stylesheets/design/variables';

.title {
  font-size: 20px;
  line-height: 30px;
  color: #333333;
}
.card {
  border-radius: 10px;
}
.row-number-cell {
  background-color: $color-bg-grey;
}
.border-thin {
  border: 1px solid $color-bg-grey;
}
.rounded-number {
  width: 26px;
  height: 24px;
}
.grey-out {
  background: #f6f6f5;
  pointer-events: none;
}
.border {
  border: 1px solid #e0e0e0 !important;
  border-bottom: 2px solid #e0e0e0 !important;
  border-right: 0px !important;
}
.border-right {
  border-right: 1px solid #e0e0e0 !important;
}
.grid-content__text {
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  font-weight: 500;
}
.alert-text__normal {
  font-size: 16px;
  font-weight: 500;
  line-height: 36px;
  color: #333333;
}
.w-60 {
  width: 60%;
}
</style>
