/* eslint-disable max-lines */
import rfdc from 'rfdc'
import { ParentApi } from 'http/modules/parent'
import {
  InvoiceItemInput,
  Parent,
  SearchCondition,
  ParentCountByLatestInvoiceStatus,
  PaymentStatusFilterValue,
} from '../models'
import { TopPageStore } from '../top_page_store'
import { InvoiceItemInputService } from './invoice_item_input_service'
import { InvoiceService } from './invoice_service'
import { SchoolParentChildService } from './school_parent_child_service'
import { SchoolService } from './school_service'
import { InvoiceItemService } from 'pages/top_page/services/invoice_item_service'

const clone = rfdc()

/**
 * Parentに関するロジックを持ちます。
 */
export class SchoolParentService {
  /**
   * schoolParentsの状態から指定したidに一致するリアクティブなparentオブジェクトを返します。
   */
  static getParentById(id: number): Parent | undefined {
    return TopPageStore.schoolParentsMap.get(id)
  }

  /**
   * schoolParentsの状態から、指定したinvoiceIdの請求書を持つ、リアクティブなparentオブジェクトを返します。
   * @returns 存在しない場合はundefinedを返します。
   */
  static getParentByInvoiceId(invoiceId: number): Parent | undefined {
    return TopPageStore.schoolParentsByInvoiceIdMap.get(invoiceId)
  }

  /**
   * JSのメモリ上(Store)にもっているschoolParentsのデータをDBにあるデータと同期させるためのメソッドです。
   * DBからデータを取得し、そのデータでJSのメモリ上のデータを上書きします。
   *
   * @param parentIds 何も渡さないか、空の配列を渡した場合には全てのschoolParentsをDBと同期します。
   */
  static async loadSchoolParents(parentIds: number[] = []): Promise<void> {
    const responseSchoolParents: Parent[] = (
      await ParentApi.index(
        SchoolService.getTargetFacilityId(),
        TopPageStore.targetYear + '-' + TopPageStore.targetMonth,
        parentIds.join(',')
      )
    ).data.school_parents
    // APサーバーから取得したschoolParentsをMapに変換
    const responseSchoolParentsMap = new Map()
    responseSchoolParents.forEach((parent) => {
      responseSchoolParentsMap.set(parent.id, parent)
    })
    // top_page_storeのschoolParentsへの書き込み
    if (parentIds.length === 0) {
      TopPageStore.updateSchoolParents(
        responseSchoolParents.map((parent) => SchoolParentService.convertSchoolParents(parent))
      )
    } else {
      const parentsInStore = clone(TopPageStore.schoolParents)
      const updatedParents = parentsInStore.map((parent) => {
        if (parentIds.includes(parent.id)) {
          return SchoolParentService.convertSchoolParents(responseSchoolParentsMap.get(parent.id))
        }
        return parent
      })
      TopPageStore.updateSchoolParents(updatedParents)
    }
  }

  /* eslint-disable max-lines-per-function */
  /**
   * APサーバーから取得したparentオブジェクトを、フロントエンドが使いやすい形に変換します。
   * 具体的にはparent > invoice_item_inputsを生成をします
   * @param parent APサーバーから取得した時点でのparentオブジェクト
   */
  static convertSchoolParents(parent: any): Parent {
    // バックエンドからは school_parent_child_id がnullで来る可能性がある
    // フロントエンドではnullではなくundefinedで扱いたいのでここでundefinedに一括変換
    parent.invoices = parent.invoices.map((invoice: any) => {
      invoice.invoice_items.forEach(
        (item: any) =>
          (item.school_parent_child_id =
            item.school_parent_child_id === null ? undefined : item.school_parent_child_id)
      )
      return invoice
    })

    // parent > invoices[0] > invoice_itemsを見て、請求書のフォーム情報を抽出する
    const invoiceItemInputs: InvoiceItemInput[] = []
    if (parent.invoices.length > 0) {
      const latestInvoice = parent.invoices[0]

      ;[undefined, ...parent.school_parent_children.map((child: any) => child.id)].forEach(
        (childId: number | undefined) => {
          if (latestInvoice.status === 'paid') {
            invoiceItemInputs.push(...InvoiceItemInputService.construct3ItemInputs(childId))
          } else {
            latestInvoice.invoice_items
              .filter((item: any) => item.school_parent_child_id === childId)
              .forEach((item: any) => {
                invoiceItemInputs.push(
                  InvoiceItemInputService.constructInputByInvoiceItem(item, childId)
                )
              })
            // 少なくとも3つはフォームが表示されるようにする
            while (
              invoiceItemInputs.filter((input) => input.school_parent_child_id === childId).length <
              3
            ) {
              invoiceItemInputs.push(InvoiceItemInputService.constructItemInput(childId))
            }
          }
        }
      )
    } else {
      ;[undefined, ...parent.school_parent_children.map((child: any) => child.id)].forEach(
        (childId: number | undefined) => {
          invoiceItemInputs.push(...InvoiceItemInputService.construct3ItemInputs(childId))
        }
      )
    }
    parent.invoice_item_inputs = invoiceItemInputs
    parent.isChecked = false
    return parent
  }

  /**
   * 親がもつ請求全ての合計値を返すメソッド
   */
  static calculateTotalAmountOfParentInvoices(
    parentId: number,
    includeConvenienceStoreFee: boolean
  ): number | undefined {
    const parent = SchoolParentService.getParentById(parentId)
    if (!parent) return undefined

    let totalAmount
    if (InvoiceService.existNeverSavedInvoice(parentId)) {
      totalAmount = InvoiceItemInputService.calculateInputTotalAmountByParentId(parentId)
    }

    return parent.invoices.reduce((amount: number | undefined, invoice) => {
      const amountOfInvoice = InvoiceService.calculateTotalAmountOfInvoice(
        invoice.id,
        includeConvenienceStoreFee
      )
      if (amountOfInvoice === undefined) return amount
      return amountOfInvoice + (amount ?? 0)
    }, totalAmount)
  }

  /**
   * 親がもつ請求全ての合計値を返すメソッド
   */
  static calculateTotalAmountOfParentInvoicesLegacy(parentId: number): number | undefined {
    const parent = SchoolParentService.getParentById(parentId)
    if (!parent) return undefined

    let totalAmount
    if (InvoiceService.existNeverSavedInvoice(parentId)) {
      totalAmount = InvoiceItemInputService.calculateInputTotalAmountByParentId(parentId)
    }

    return parent.invoices.reduce((amount: number | undefined, invoice) => {
      const amountOfInvoice = InvoiceService.calculateTotalAmountOfInvoiceLegacy(invoice.id)
      if (amountOfInvoice === undefined) return amount
      return amountOfInvoice + (amount ?? 0)
    }, totalAmount)
  }

  /**
   * 検索にヒットした親の配列を返します。
   * 引数に何も渡さなかった場合は、Storeに状態として持っている検索条件を使って検索し、その結果を返します。
   */
  static getSearchedSchoolParents(
    searchCondition: SearchCondition = TopPageStore.searchCondition
  ): Parent[] {
    return TopPageStore.schoolParents.filter((parent) => {
      // クラスによる絞り込み
      if (!SchoolParentService.meetClassCondition(parent, searchCondition)) return false
      // 支払い状況による絞り込み
      if (!SchoolParentService.meetPaymentStatusCondition(parent, searchCondition)) return false
      // LINE登録状況による絞り込み
      if (!SchoolParentService.meetLineCondition(parent, searchCondition)) return false
      // 名前による絞り込み
      if (!SchoolParentService.meetNameCondition(parent, searchCondition)) return false
      return true
    })
  }

  /**
   * 対象の親がクラスの検索条件を満たすか判定します。
   * 満たす場合はtrue, 満たさない場合はfalseを返します。
   */
  private static meetClassCondition(parent: Parent, searchCondition: SearchCondition): boolean {
    // 子供がいない親の場合は、クラスによる絞り込みは常にtrueを返す
    if (parent.school_parent_children.length === 0) return true

    return parent.school_parent_children.some((child) =>
      searchCondition.selectedClassIds.includes(
        SchoolParentChildService.getChildSchoolClass(parent, child).id
      )
    )
  }

  /**
   * 対象の親が支払い状況の検索条件を満たすか判定します。
   * 満たす場合はtrue, 満たさない場合はfalseを返します。
   */
  private static meetPaymentStatusCondition(
    parent: Parent,
    searchCondition: SearchCondition
  ): boolean {
    // 全てタブの時
    if (searchCondition.paymentStatus === 'all') {
      return true
    }
    // 支払い済タブの時
    if (
      searchCondition.paymentStatus === 'paid' &&
      parent.invoices[0]?.status === 'paid' &&
      !InvoiceService.existNeverSavedInvoice(parent.id)
    ) {
      // 請求が支払い済みかつ保存されていない請求がない場合、支払い済タブに分類する
      return true
    }
    // 未払いタブの時
    if (searchCondition.paymentStatus === 'unpaid' && parent.invoices[0]?.status === 'unpaid') {
      // 未払いの請求もしくは未払いの請求が変更されまだ保存ボタンが押されていない場合は、未払いタブに分類する
      return true
    }
    // 未請求タブの時
    if (
      searchCondition.paymentStatus === 'saved' &&
      parent.invoices[0]?.status === 'before_charge'
    ) {
      // 保存中の請求もしくは保存中の請求が変更されまだ保存ボタンが押されていない場合は、保存中タブに分類する
      return true
    }
    // 未作成タブの時
    if (
      searchCondition.paymentStatus === 'not_created' &&
      (parent.invoices.length === 0 ||
        (parent.invoices[0].status === 'paid' && InvoiceService.existNeverSavedInvoice(parent.id)))
    ) {
      // 請求がない場合もしくは「新しく請求をする」を押下し保存されていない請求がある場合、未作成タブに分類する
      return true
    }
    return false
  }

  /**
   * 対象の親がLINE登録状況の検索条件を満たすか判定します。
   * 満たす場合はtrue, 満たさない場合はfalseを返します。
   */
  private static meetLineCondition(parent: Parent, searchCondition: SearchCondition): boolean {
    if (
      (searchCondition.lineStatus === 'connected' && !parent.line_connected) ||
      (searchCondition.lineStatus === 'not_connected' && parent.line_connected)
    ) {
      return false
    }
    return true
  }

  /**
   * 対象の親が名前の検索条件を満たすか判定します。
   * 満たす場合はtrue, 満たさない場合はfalseを返します。
   */
  private static meetNameCondition(parent: Parent, searchCondition: SearchCondition): boolean {
    const parentName = parent.last_name + parent.first_name
    const childrenName = parent.school_parent_children.map((child) => child.first_name).join('')
    return parentName.includes(searchCondition.name) || childrenName.includes(searchCondition.name)
  }

  /**
   * 検索条件で絞り込んだ後の請求ステータスごとの保護者の人数
   */
  static parentCountByPaymentStatus(): Map<PaymentStatusFilterValue, number> {
    return new Map([
      [
        'all',
        SchoolParentService.getSearchedSchoolParents({
          ...TopPageStore.searchCondition,
          paymentStatus: 'all',
        }).length,
      ],
      [
        'paid',
        SchoolParentService.getSearchedSchoolParents({
          ...TopPageStore.searchCondition,
          paymentStatus: 'paid',
        }).length,
      ],
      [
        'unpaid',
        SchoolParentService.getSearchedSchoolParents({
          ...TopPageStore.searchCondition,
          paymentStatus: 'unpaid',
        }).length,
      ],
      [
        'saved',
        SchoolParentService.getSearchedSchoolParents({
          ...TopPageStore.searchCondition,
          paymentStatus: 'saved',
        }).length,
      ],
      [
        'not_created',
        SchoolParentService.getSearchedSchoolParents({
          ...TopPageStore.searchCondition,
          paymentStatus: 'not_created',
        }).length,
      ],
    ])
  }

  /**
   * 最新の請求のステータスごとの保護者の人数をカウントするメソッド
   */
  static countParentByLatestInvoiceStatus(): ParentCountByLatestInvoiceStatus {
    const parentsByStatus: ParentCountByLatestInvoiceStatus = {
      paid: 0,
      unpaid: 0,
      before_charge: 0,
    }
    TopPageStore.schoolParents.forEach((parent) => {
      if (parent.invoices[0]) {
        parentsByStatus[`${parent.invoices[0].status}`] += 1
      }
    })
    return parentsByStatus
  }

  /**
   * お支払い率を計算するメソッド
   * 請求を持つ保護者の人数が0人の時undefinedを返します
   * お支払い率 = 最新の請求が支払い済の保護者の人数 / 請求を持つ保護者の人数 * 100
   */
  static calculatePaymentRate(): number | undefined {
    const haveInvoiceParentsCount = TopPageStore.schoolParents.filter(
      (parent: Parent) => parent.invoices.length !== 0
    ).length
    if (haveInvoiceParentsCount === 0) {
      return undefined
    }
    const paidParentsCount = SchoolParentService.countParentByLatestInvoiceStatus().paid
    return Math.floor((paidParentsCount / haveInvoiceParentsCount) * 100)
  }

  /**
   * 保護者が編集中の請求を持っているかどうかを判定する
   * @param parent 保護者
   * @returns 編集中の請求を持っている場合はtrue、持っていない場合はfalse
   */
  static hasEditingInvoice(parent: Parent | undefined): boolean {
    if (!parent) return false

    return [undefined, ...parent.school_parent_children.map((child) => child.id)].some((childId) =>
      InvoiceItemService.isInputModified(parent.id, childId)
    )
  }
}
