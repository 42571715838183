<template>
  <v-tabs v-model="activeTabIndex" height="44px">
    <template v-for="tab of tabs" :key="tab.value">
      <v-tab rounded="t" width="19%" variant="flat" hide-slider>
        <span class="status-name pr-2">{{ tab.text }}</span
        ><span class="search-count">{{ searchCount.get(tab.value) }}</span>
      </v-tab>
      <div v-if="tab.value !== 'not_created'" class="spacer" />
    </template>
  </v-tabs>
  <div class="tabs-item">
    <InvoiceHeaderList />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { PaymentStatusFilterValue } from '../models'
import { TopPageStore } from '../top_page_store'
import InvoiceHeaderList from './InvoiceHeaderList.vue'
import { ParentSearchConditionService } from '../services/parent_search_condition_service'
import { SchoolParentService } from '../services/school_parent_service'

const tabs: {
  text: string
  value: PaymentStatusFilterValue
}[] = [
  { text: 'すべて', value: 'all' },
  { text: '支払い済み', value: 'paid' },
  { text: '未払い', value: 'unpaid' },
  { text: '請求保存中', value: 'saved' },
  { text: '請求未作成', value: 'not_created' },
]
const searchCount = computed(() => SchoolParentService.parentCountByPaymentStatus())
const activeTabIndex = computed({
  // TopPageStore.searchCondition.paymentStatusで設定した値を初期描画時のデフォルトのタブとします
  get: () => tabs.findIndex((tab) => tab.value === TopPageStore.searchCondition.paymentStatus),
  set: (index) => ParentSearchConditionService.updatePaymentStatusCondition(tabs[index].value),
})
</script>

<style lang="scss" scoped>
.tabs-item {
  overflow-y: auto;
  background-color: #f3f6f9;
}

.v-tab:not(.v-tab--selected) {
  border-bottom: 2px solid rgb(var(--v-theme-primary));
}

.v-tab.v-tab--selected {
  border-top: 2px solid rgb(var(--v-theme-primary));
  border-left: 2px solid rgb(var(--v-theme-primary));
  border-right: 2px solid rgb(var(--v-theme-primary));
}

.spacer {
  width: 1.25%; // タブの幅の合計(== 19 * 5) / タブとタブの間隔の総数(== 4)
  background: rgb(var(--v-theme-background));
  border-bottom: 2px solid rgb(var(--v-theme-primary));
}

.v-tab.v-tab--selected .status-name {
  color: rgb(var(--v-theme-primary));
  font-size: 14px;
  font-weight: 700;
}

.v-tab:not(.v-tab--selected) .status-name {
  color: #636363;
  font-size: 14px;
  font-weight: 700;
}

.search-count {
  color: #999999;
  font-size: 12px;
  font-weight: 700;
}
</style>
