<template>
  <div>
    <div class="font-weight-bold">
      <span class="text-primary text-h5 font-weight-bold"
        >{{ targetYear }}年{{ Number(targetMonth) }}月</span
      ><span class="text-h6 font-weight-bold text-black">の請求内容です</span>
    </div>
    <div class="mt-8">
      <div class="text-body-1 font-weight-bold mb-1">LINE {{ lineInvoicesCount }}件</div>
      <div class="text-body-1 font-weight-bold">請求書 {{ invoicesCount }}件</div>
      <template v-if="offsetParents && offsetParents.length > 0">
        <div class="mt-4">
          <div class="text-body-1 mb-1">
            内{{
              offsetParents.length
            }}件は総額が0円の請求であり、請求後は支払い済みになり、編集できなくなります。
          </div>
          <div class="text-body-1">相殺対象の保護者：{{ offsetParentsNames }}</div>
        </div>
      </template>
    </div>
    <div class="mt-8">
      <div class="text-body-1 font-weight-medium">以上の内容で請求します。よろしいですか？</div>
      <div class="text-body-1 font-weight-medium">
        （LINE登録をしている保護者には請求時に通知が行われます）
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    targetYear: {
      type: String,
      required: true,
    },
    targetMonth: {
      type: String,
      required: true,
    },
    lineInvoicesCount: {
      type: Number,
      required: true,
    },
    invoicesCount: {
      type: Number,
      required: true,
    },
    offsetParents: {
      type: Array,
      required: false,
    },
    offsetParentsNames: {
      type: String,
      required: false,
    },
  },
})
</script>
