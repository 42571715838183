import { reactive } from 'vue'
import {
  InvoiceItem,
  InvoiceItemSummary,
  YearAndMonthRange,
  YearAndMonth,
  TargetItem,
  IncludeStatusType,
} from 'pages/export_invoice_items_page/model'
import {
  InvoiceStatus,
  InvoiceItemNameCandidate,
  AvailableFacility,
  InvoicePageInitialTargetMonth,
} from 'shared/models'
import { getDate, getStartOfMonth } from 'shared/utils/date'

type ExportInvoiceItemsPageState = {
  isLoading: boolean
  /**
   * 法人画面ならCorporationApiから返ってくるoldest_facility_created_at, 施設画面ならfacility.created_at
   */
  oldestFacilityCreatedAt: Date
  fromYearAndMonth: YearAndMonth
  toYearAndMonth: YearAndMonth
  invoicePageInitialTargetMonth: InvoicePageInitialTargetMonth
  invoiceItemNameCandidates: InvoiceItemNameCandidate[]
  selectedInvoiceItems: string[]
  invoiceStatuses: InvoiceStatus[]
  targetItem: TargetItem
  includeStatusType: IncludeStatusType
  invoiceItems: InvoiceItem[]
  invoiceItemSummary: InvoiceItemSummary
  // NOTE: 開始月・終了月はそれぞれ、開始年・終了年を参照して、別々の年月のリストを作成したいので、fromとtoそれぞれで用意
  selectableFromYearAndMonthRange: YearAndMonthRange
  selectableToYearAndMonthRange: YearAndMonthRange
  availableFacilities: AvailableFacility[]
  selectedFacilityId: string
}

const state = reactive<ExportInvoiceItemsPageState>({
  isLoading: true,
  oldestFacilityCreatedAt: getDate(new Date()),
  fromYearAndMonth: {
    year: null,
    month: null,
  },
  toYearAndMonth: {
    year: null,
    month: null,
  },
  invoicePageInitialTargetMonth: 'last_month',
  invoiceItemNameCandidates: [],
  selectedInvoiceItems: [],
  invoiceStatuses: ['unpaid', 'paid', 'before_charge'],
  targetItem: 'chargedMonth',
  includeStatusType: 'include',
  invoiceItems: [],
  invoiceItemSummary: {
    total: {
      amount: 0,
      count: 0,
      pages: {
        invoice_items: 0,
        school_master_invoice_items: 0,
        school_invoice_item_histories: 0,
      },
    },
  },
  selectableFromYearAndMonthRange: {
    years: [],
    months: [],
  },
  selectableToYearAndMonthRange: {
    years: [],
    months: [],
  },
  availableFacilities: [],
  selectedFacilityId: 'all',
})

export class ExportInvoiceItemsPageStore {
  static get isLoading(): boolean {
    return state.isLoading
  }

  static updateIsLoading(isLoading: boolean): void {
    state.isLoading = isLoading
  }

  static get oldestFacilityCreatedAt(): Date {
    return state.oldestFacilityCreatedAt
  }

  static updateOldestFacilityCreatedAt(oldestFacilityCreatedAt: Date): void {
    state.oldestFacilityCreatedAt = getStartOfMonth(oldestFacilityCreatedAt)
  }

  static get invoiceItemNameCandidates(): InvoiceItemNameCandidate[] {
    return state.invoiceItemNameCandidates
  }

  static updateInvoiceItemNameCandidates(
    invoiceItemNameCandidates: InvoiceItemNameCandidate[]
  ): void {
    state.invoiceItemNameCandidates = invoiceItemNameCandidates
  }

  static get selectedInvoiceItems(): string[] {
    return state.selectedInvoiceItems
  }

  static updateSelectedInvoiceItems(selectedInvoiceItems: string[]): void {
    state.selectedInvoiceItems = selectedInvoiceItems
  }

  static get targetItem(): TargetItem {
    return state.targetItem
  }

  static updateTargetItem(targetItem: TargetItem): void {
    state.targetItem = targetItem
  }

  static get invoiceStatuses(): InvoiceStatus[] {
    return state.invoiceStatuses
  }

  static updateInvoiceStatuses(invoiceStatuses: InvoiceStatus[]): void {
    state.invoiceStatuses = invoiceStatuses
  }

  static get includeStatusType(): IncludeStatusType {
    return state.includeStatusType
  }

  static updateIncludeStatusType(includeStatusType: IncludeStatusType): void {
    state.includeStatusType = includeStatusType
  }

  static get fromYearAndMonth(): YearAndMonth {
    return state.fromYearAndMonth
  }

  static updateFromYearAndMonth(yearOrMonth: Partial<YearAndMonth>): void {
    state.fromYearAndMonth = { ...state.fromYearAndMonth, ...yearOrMonth }
  }

  static get toYearAndMonth(): YearAndMonth {
    return state.toYearAndMonth
  }

  static updateToYearAndMonth(yearOrMonth: Partial<YearAndMonth>): void {
    state.toYearAndMonth = { ...state.toYearAndMonth, ...yearOrMonth }
  }

  static get invoicePageInitialTargetMonth(): InvoicePageInitialTargetMonth {
    return state.invoicePageInitialTargetMonth
  }

  static updateInvoicePageInitialTargetMonth(
    invoicePageInitialTargetMonth: InvoicePageInitialTargetMonth
  ): void {
    state.invoicePageInitialTargetMonth = invoicePageInitialTargetMonth
  }

  static get invoiceItems(): InvoiceItem[] {
    return state.invoiceItems
  }

  static updateInvoiceItems(invoiceItems: InvoiceItem[]): void {
    state.invoiceItems = invoiceItems
  }

  static get invoiceItemSummary(): InvoiceItemSummary {
    return state.invoiceItemSummary
  }

  static updateInvoiceItemSummary(invoiceItemSummary: InvoiceItemSummary): void {
    state.invoiceItemSummary = invoiceItemSummary
  }

  static get selectableFromYearAndMonthRange(): YearAndMonthRange {
    return state.selectableFromYearAndMonthRange
  }

  static updateSelectableFromYearAndMonthRange(
    selectableFromYearAndMonthRange: YearAndMonthRange
  ): void {
    state.selectableFromYearAndMonthRange = selectableFromYearAndMonthRange
  }

  static get selectableToYearAndMonthRange(): YearAndMonthRange {
    return state.selectableToYearAndMonthRange
  }

  static updateSelectableToYearAndMonthRange(
    selectableToYearAndMonthRange: YearAndMonthRange
  ): void {
    state.selectableToYearAndMonthRange = selectableToYearAndMonthRange
  }

  static get availableFacilities(): AvailableFacility[] {
    return state.availableFacilities
  }

  static updateAvailableFacilities(availableFacilities: AvailableFacility[]): void {
    state.availableFacilities = availableFacilities
  }

  static get selectedFacilityId(): string {
    return state.selectedFacilityId
  }

  static updateSelectedFacilityId(selectedFacilityId: string): void {
    state.selectedFacilityId = selectedFacilityId
  }
}
