import { reactive } from 'vue'
import { StoreHelperService } from 'shared/services/store_helper_service'
import {
  CandidateColumnId,
  CsvFileMetadata,
  CsvImportColumnCandidate,
  CsvImportStatus,
} from './models'
import { ConvertAlert } from 'http/modules/invoice_register_template'

type ImportPanelState = {
  importTargetFile: File[]
  columnCandidates: CsvImportColumnCandidate[]
  isUploading: boolean
  isImporting: boolean
  csvFileMetadata: CsvFileMetadata
  /** CSVの各カラムにマッピングされたColumnCandidateのcolumn_id。マッピングされていない場合はundefined。 */
  mappedColumnIds: (CandidateColumnId | undefined)[]
  /** CSVの各カラムに対する自動マッピング結果。自動マッピングできている場合はtrue、そうでなければfalse。 */
  autoMappingStatuses: boolean[]
  importStatus: CsvImportStatus
  step: number
  isFirstLineIgnored: boolean
  isShowAllErrorDialog: boolean
  columnMappingSaveKey: string
  excelTemplateDownloadUrl: string
  uploadedExcel: File[]
  isConvertingToCsv: boolean
  targetYearAndMonth: string
  convertToCsvErrors: ConvertAlert[]
}

const state = reactive<ImportPanelState>({
  importTargetFile: [],
  columnCandidates: [],
  isUploading: false,
  isImporting: false,
  csvFileMetadata: {
    start_rows: [],
    end_rows: [],
    total_count: 0,
    colCount: 0,
  },
  mappedColumnIds: [],
  autoMappingStatuses: [],
  importStatus: {
    errors: [],
    id: 0,
    status: 'processing',
    success_count: 0,
  },
  step: 0,
  isFirstLineIgnored: true,
  isShowAllErrorDialog: false,
  columnMappingSaveKey: '',
  excelTemplateDownloadUrl: '',
  uploadedExcel: [],
  /** エクセルファイルをCSVに変換中の時trueになります */
  isConvertingToCsv: false,
  targetYearAndMonth: '',
  convertToCsvErrors: [],
})

export class ImportPanelStore {
  static get importTargetFile(): File[] {
    return state.importTargetFile
  }

  static updateImportTargetFile(importTargetFile: File[]): void {
    state.importTargetFile = importTargetFile
  }

  static get columnCandidates(): CsvImportColumnCandidate[] {
    return state.columnCandidates
  }

  static updateColumnCandidates(columnCandidates: CsvImportColumnCandidate[]): void {
    StoreHelperService.updateArrayState(state.columnCandidates, columnCandidates)
  }

  static get isUploading(): boolean {
    return state.isUploading
  }

  static updateIsUploading(isUploading: boolean): void {
    state.isUploading = isUploading
  }

  static get isImporting(): boolean {
    return state.isImporting
  }

  static updateIsImporting(isImporting: boolean): void {
    state.isImporting = isImporting
  }

  static get csvFileMetadata(): CsvFileMetadata {
    return state.csvFileMetadata
  }

  static updateCsvFileMetadata(csvFileMetadata: CsvFileMetadata): void {
    state.csvFileMetadata = csvFileMetadata
  }

  static get mappedColumnIds(): (CandidateColumnId | undefined)[] {
    return state.mappedColumnIds
  }

  static updateMappedColumnIds(mappedColumnIds: (CandidateColumnId | undefined)[]): void {
    StoreHelperService.updateArrayState(state.mappedColumnIds, mappedColumnIds)
  }

  static get autoMappingStatuses(): boolean[] {
    return state.autoMappingStatuses
  }

  static updateAutoMappingStatuses(autoMappingStatuses: boolean[]): void {
    StoreHelperService.updateArrayState(state.autoMappingStatuses, autoMappingStatuses)
  }

  static get importStatus(): CsvImportStatus {
    return state.importStatus
  }

  static updateImportStatus(importStatus: CsvImportStatus): void {
    state.importStatus = importStatus
  }

  static get step(): number {
    return state.step
  }

  static updateStep(step: number): void {
    state.step = step
  }

  static get isFirstLineIgnored(): boolean {
    return state.isFirstLineIgnored
  }

  static updateIsFirstLineIgnored(isFirstLineIgnored: boolean): void {
    state.isFirstLineIgnored = isFirstLineIgnored
  }

  static get isShowAllErrorDialog(): boolean {
    return state.isShowAllErrorDialog
  }

  static updateIsShowAllErrorDialog(isShowAllErrorDialog: boolean): void {
    state.isShowAllErrorDialog = isShowAllErrorDialog
  }

  static get columnMappingSaveKey(): string {
    return state.columnMappingSaveKey
  }

  static updateColumnMappingSaveKey(columnMappingSaveKey: string): void {
    state.columnMappingSaveKey = columnMappingSaveKey
  }

  static get excelTemplateDownloadUrl(): string {
    return state.excelTemplateDownloadUrl
  }

  static updateExcelTemplateDownloadUrl(excelTemplateDownloadUrl: string): void {
    state.excelTemplateDownloadUrl = excelTemplateDownloadUrl
  }

  static get uploadedExcel(): File[] {
    return state.uploadedExcel
  }

  static updateUploadedExcel(uploadedExcel: File[]): void {
    state.uploadedExcel = uploadedExcel
  }

  static get isConvertingToCsv(): boolean {
    return state.isConvertingToCsv
  }

  static updateIsConvertingToCsv(isConvertingToCsv: boolean): void {
    state.isConvertingToCsv = isConvertingToCsv
  }

  static get targetYearAndMonth(): string {
    return state.targetYearAndMonth
  }

  static updateTargetYearAndMonth(targetYearAndMonth: string): void {
    state.targetYearAndMonth = targetYearAndMonth
  }

  static get convertToCsvErrors(): ConvertAlert[] {
    return state.convertToCsvErrors
  }

  static updateConvertToCsvErrors(convertToCsvErrors: ConvertAlert[]): void {
    StoreHelperService.updateArrayState(state.convertToCsvErrors, convertToCsvErrors)
  }
}
