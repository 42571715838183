import { reactive } from 'vue'

type ReceiptSendPageState = {
  /** メール送信中か */
  isSending: boolean
  /** メール送信完了したか */
  isMailSendDone: boolean
}

const state = reactive<ReceiptSendPageState>({
  isSending: false,
  isMailSendDone: false,
})

export class ReceiptSendPageStore {
  static get isSending(): boolean {
    return state.isSending
  }

  static updateIsSending(isSending: boolean): void {
    state.isSending = isSending
  }

  static get isMailSendDone(): boolean {
    return state.isMailSendDone
  }

  static updateIsMailSendDone(isMailSendDone: boolean): void {
    state.isMailSendDone = isMailSendDone
  }
}
