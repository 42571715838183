<template>
  <AppFrame>
    <template #header>
      <FacilityHeader />
    </template>
    <div class="d-flex column-wrapper">
      <div class="left-column">
        <OutstandingMessages
          class="mb-4"
          :outstanding-invoice-months="outstandingInvoiceMonths"
          :selected-outstanding-invoice-month="selectedOutstandingInvoiceMonth"
          @change="changeMonth"
        />
        <div class="px-6">
          <TargetMonthSelector class="mb-2" />
          <PaymentStatisticsPanel class="mb-4" />
          <ActionButtonsArea />
        </div>
      </div>
      <div class="right-column flex-grow-1 pa-6">
        <span v-cloak class="column-inner-wrapper">
          <ParentFilters class="mb-4" />
          <InvoiceHeaderListTabView />
          <BaseDialog />
          <template v-if="isShowNewTopPageFormat">
            <SchoolParentInvoicesModal
              :is-show-modal="invoicesModalState.isShow"
              :parent-id="invoicesModalState.parentId"
            />
          </template>
          <template v-else>
            <LegacySchoolParentInvoicesModal
              :is-show-modal="invoicesModalState.isShow"
              :parent-id="invoicesModalState.parentId"
            />
          </template>
          <SchoolParentInvoiceFormModal
            :is-show-modal="invoiceFormModalState.isShow"
            :parent-id="invoiceFormModalState.parentId"
            :status="invoiceFormModalState.status"
          />
        </span>
        <BottomArea />
      </div>
    </div>
  </AppFrame>
</template>

<script setup lang="ts">
import { onMounted, computed } from 'vue'
import SchoolParentInvoicesModal from 'pages/top_page/components/SchoolParentInvoicesModal.vue'
import LegacySchoolParentInvoicesModal from 'pages/top_page/components/LegacySchoolParentInvoicesModal.vue'
import SchoolParentInvoiceFormModal from 'pages/top_page/components/SchoolParentInvoiceFormModal.vue'
import BaseDialog from 'shared/components/Dialog.vue'
import PaymentStatisticsPanel from 'pages/top_page/components/PaymentStatisticsPanel.vue'
import TargetMonthSelector from 'pages/top_page/components/TargetMonthSelector.vue'
import { ParentFilters } from 'pages/top_page/components/ParentFilters'
import ActionButtonsArea from 'pages/top_page/components/ActionButtonsArea.vue'
import BottomArea from 'pages/top_page/components/BottomArea.vue'
import InvoiceHeaderListTabView from 'pages/top_page/components/InvoiceHeaderListTabView.vue'
import { TargetMonthService } from 'pages/top_page/services/target_month_service'
import { ElementHeightService } from 'pages/top_page/services/element_height_service'
import { TopPageStore } from 'pages/top_page/top_page_store'
import { SchoolService } from 'pages/top_page/services/school_service'
import FacilityHeader from 'shared/components/header/FacilityHeader.vue'
import { RouteService } from 'shared/services/route_service'
import { MetaService } from 'shared/services/meta_service'
import AppFrame from 'shared/components/AppFrame.vue'
import OutstandingMessages from 'pages/top_page/components/OutstandingMessages.vue'
import { EventTracker } from 'shared/utils/_event_tracking'
import { setYearAndMonth } from 'shared/utils/date'

const props = defineProps<{
  targetYear: string
  targetMonth: string
  outstandingInvoiceMonths: string
  selectedOutstandingInvoiceMonth?: string
}>()

const invoicesModalState = computed(() => TopPageStore.invoicesModalState)
const invoiceFormModalState = computed(() => TopPageStore.invoiceFormModalState)
const outstandingInvoiceMonths = computed(() => JSON.parse(props.outstandingInvoiceMonths))
const isShowNewTopPageFormat = computed(() => TopPageStore.isShowNewTopPageFormat)

MetaService.addTitle({ mode: 'facility', page: 'invoices' })
if (props.targetYear && props.targetMonth) {
  TargetMonthService.updateTargetYearAndMonthByDate(
    setYearAndMonth(props.targetYear, props.targetMonth)
  )
  TargetMonthService.updateTargetYearAndMonthByDate(
    setYearAndMonth(props.targetYear, props.targetMonth)
  )
}
SchoolService.initialize()
window.addEventListener('resize', () => {
  ElementHeightService.setColumnWrapperPosition()
  ElementHeightService.setInvoiceHeaderListHeight()
})

onMounted(() => {
  ElementHeightService.setColumnWrapperPosition()
  ElementHeightService.setInvoiceHeaderListHeight()
  const resizeObserver = new ResizeObserver((_entries) => {
    ElementHeightService.setInvoiceHeaderListHeight()
  })
  const expansionPanel = document.querySelector('.v-expansion-panel')
  if (expansionPanel) {
    resizeObserver.observe(expansionPanel)
  }
})

const changeMonth = (event: Event) => {
  const facilityId = RouteService.getPathParamByIndex(1)
  if (event.target instanceof HTMLSelectElement) {
    location.href = `/facilities/${facilityId}/invoices?target_year_and_month=${event.target.value}`
  }
  EventTracker.trackEvent('change_select_form_outstanding_month', {})
}
</script>

<style scoped lang="scss">
.v-application--wrap {
  min-height: 1px !important;
}
</style>
