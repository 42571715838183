import { HTTP } from '../common'

/**
 * ログイン中のアカウント情報を表す型です
 */
export type SignInAccount = {
  id: number | undefined
}

export type SelfResponse = {
  data: SignInAccount
}

export class CorporationAccountApi {
  static self(): Promise<SelfResponse> {
    return HTTP.get('/corporation_accounts/me')
  }
}
