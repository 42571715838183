const ExternalLink = {
  // 外部リンクのurl
  // 例: google: 'https://www.google.com'
  openpageManual: '/helppages/manual_page',
  openpageFaq: '/helppages/faq_page',
  openpageCsvImportInvoices: '/helppages/csv_import_invoices_page',
  openpageCsvImportParents: '/helppages/csv_import_parents_page',
  openpageExcelImportParents: '/helppages/excel_import_parents_page',
  openpageTroubleshootingExcelAndCsv: '/helppages/troubleshooting_excel_and_csv_page',
  openpageInfoForParents: '/helppages/info_for_parents_page',
  openpageExportInvoiceItems: '/helppages/export_invoice_items_page',
  exportInvoiceItemsFacilityReleaseNote:
    'https://www.notion.so/enpayinc/e6b4f034c78a4165ac11b8320e3d9a3f?pvs=4#9240da9954f4471890a8fc0786d8a3eb',
  exportInvoiceItemsCorporationReleaseNote:
    'https://www.notion.so/enpayinc/e6b4f034c78a4165ac11b8320e3d9a3f?pvs=4#b5114b1f203a43469b402dca7ef31676',
  openpageFaqForParentList: '/helppages/parents_page',
  externalTransmissionsUrl: '/external_transmissions',
  cvsPaymentFeeHelpUrl:
    'https://faq.enpay.co.jp/%E4%BF%9D%E8%AD%B7%E8%80%85%E5%90%91%E3%81%91%E3%83%98%E3%83%AB%E3%83%97-%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E8%B3%AA%E5%95%8Fdb/about-convenience-store-payment-fees',
} as const

export default ExternalLink
