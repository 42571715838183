<template>
  <div class="text-center bg-white py-6">
    <div class="text-center ma-10">
      <S3Image path="/parents/no_data.png" />
    </div>
    <p class="ma-6 font-weight-bold">クラスが登録されていません</p>
    <div class="ma-6 font-weight-medium">
      <p class="mb-0">
        人数が多い場合はクラスを登録すると保護者や子どもの管理がかんたんになります。
      </p>
      <p>「設定」>「クラス一覧」から登録できます。</p>
    </div>
    <Button :href="classPageUrl">クラス一覧画面はこちら</Button>
  </div>
</template>

<script setup lang="ts">
import S3Image from 'shared/components/s_3_image/S3Image.vue'
import Button from 'shared/components/Button.vue'
import { SchoolService } from 'pages/top_page/services/school_service'

const classPageUrl = `/facilities/${SchoolService.getTargetFacilityId()}/classes`
</script>
