<template>
  <ApplicationLayout :oem-partner-logo-path="oemPartnerLogoPath" :error-message="errorMessage">
    <div class="panel panel-normal">
      <div class="panel_inner">
        <form
          id="new_school_account"
          class="new_school_account"
          action="/school_accounts/password"
          accept-charset="UTF-8"
          method="post"
        >
          <input type="hidden" name="_method" value="put" autocomplete="off" />
          <input
            type="hidden"
            name="authenticity_token"
            :value="authenticityToken"
            autocomplete="off"
          />
          <input
            id="school_account_reset_password_token"
            autocomplete="off"
            type="hidden"
            :value="resetPasswordToken"
            name="school_account[reset_password_token]"
          />
          <div class="sign-Form">
            <div class="sign-Form">
              <div class="sign-Form_item">
                <div class="sign-Form_heading">
                  <label for="school_account_password"
                    >新しいパスワード（半角英数字、6文字以上）</label
                  >
                </div>
                <div class="sign-Form_input">
                  <template v-if="passwordType === 'password'">
                    <input
                      id="school_account_password"
                      v-model="password"
                      autofocus
                      required
                      placeholder="例）qazwsxedc"
                      class="form-Item_inputText"
                      type="password"
                      name="school_account[password]"
                    /><span @click="showPassword"
                      ><i class="icon-visibility form-Item_showPassword"></i
                    ></span>
                  </template>
                  <template v-if="passwordType === 'text'">
                    <input
                      id="school_account_password"
                      v-model="password"
                      class="form-Item_inputText"
                      type="text"
                      name="school_account[password]"
                    /><span @click="hidePassword"
                      ><i class="icon-visibility_off form-Item_showPassword"></i
                    ></span>
                  </template>
                </div>
              </div>
              <div class="sign-Form_item">
                <div class="sign-Form_heading">
                  <label for="school_account_password_confirmation">確認用パスワード</label>
                </div>
                <div class="sign-Form_input">
                  <template v-if="passwordConfirmationType === 'password'">
                    <input
                      id="school_account_password_confirmation"
                      v-model="passwordConfirmation"
                      required
                      placeholder="再度パスワードを入力してください"
                      class="form-Item_inputText"
                      type="password"
                      name="school_account[password_confirmation]"
                    /><span @click="showPasswordConfirmation"
                      ><i class="icon-visibility form-Item_showPassword"></i
                    ></span>
                  </template>
                  <template v-if="passwordConfirmationType === 'text'">
                    <input
                      id="school_account_password_confirmation"
                      v-model="passwordConfirmation"
                      class="form-Item_inputText"
                      type="text"
                      name="school_account[password_confirmation]"
                    /><span @click="hidePasswordConfirmation"
                      ><i class="icon-visibility_off form-Item_showPassword"></i
                    ></span>
                  </template>
                </div>
              </div>
            </div>
          </div>

          <p class="sign-Form_buttonArea">
            <input
              type="submit"
              name="commit"
              value="パスワードを変更する"
              class="button-md button-normal"
              data-disable-with="パスワードを変更する"
            />
          </p>
        </form>
      </div>
    </div>
  </ApplicationLayout>
</template>

<script setup lang="ts">
import { MetaService } from 'shared/services/meta_service'
import { ref } from 'vue'
import ApplicationLayout from 'shared/templates/ApplicationLayout.vue'

MetaService.addTitle({ page: 'passwordReset' })

defineProps<{
  authenticityToken: string
  resetPasswordToken: string
  oemPartnerLogoPath?: string
  errorMessage?: string | string[]
}>()

type PasswordInputType = 'password' | 'text'

const password = ref('')
const passwordType = ref<PasswordInputType>('password')
const passwordConfirmation = ref('')
const passwordConfirmationType = ref<PasswordInputType>('password')

const showPassword = () => {
  passwordType.value = 'text'
}
const hidePassword = () => {
  passwordType.value = 'password'
}
const showPasswordConfirmation = () => {
  passwordConfirmationType.value = 'text'
}
const hidePasswordConfirmation = () => {
  passwordConfirmationType.value = 'password'
}
</script>
