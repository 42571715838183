import { CorporationApplyPageStore } from '../corporation_apply_page_store'

const DATE_REGEXP = /(\d{4})-(\d{2})-(\d{2})/

/**
 * 対象年月の状態に関するロジックを持ちます。
 */
export class CorporationDateService {
  /**
   * `CorporationApplyPageStore`に定義されている`establishedDate`を使った日付の文字列を返します。例:"2021年6月28日"
   */
  static getEstablishedDateForDisplay(): string {
    return CorporationDateService.replaceDateForDisplay(
      CorporationApplyPageStore.formValue.establishedDate
    )
  }

  /**
   * `CorporationApplyPageStore`に定義されている`representativeBirthdate`を使った日付の文字列を返します。例:"2021年6月28日"
   */
  static getRepresentativeBirthdateForDisplay(): string {
    return CorporationDateService.replaceDateForDisplay(
      CorporationApplyPageStore.formValue.representativeBirthdate
    )
  }

  /**
   * 2021-06-08形式の日付を2021年6月8日に変換して、日付の文字列として返します。
   * 2021-06-08形式以外の場合は変換せず引数で受け取った文字列をそのまま返します。
   */
  static replaceDateForDisplay(date: string): string {
    const replacedDate = date.match(DATE_REGEXP)
    if (replacedDate === null) return date
    return replacedDate[1] + '年' + Number(replacedDate[2]) + '月' + Number(replacedDate[3]) + '日'
  }
}
