import { FacilityInvoiceItemsApi } from 'http/modules/facility_invoice_items'
import InvoiceItemNameCandidateApi from 'http/modules/invoice_item_name_candidate'
import { AccountSettingApi } from 'http/modules/account_setting'
import { RouteService } from 'shared/services/route_service'
import { FacilityApi } from 'http/modules/facility'
import { ConvertToInvoiceItemsService } from 'pages/export_invoice_items_page/services/convert_to_invoice_items_service'
import { CalculateYearAndMonthRangeService } from 'pages/export_invoice_items_page/services/calculate_year_and_month_range_service'
import { ConvertToQueryParamsService } from 'pages/export_invoice_items_page/services/convert_to_query_params_service'
import { ExportInvoiceItemsPageStore } from 'pages/export_invoice_items_page/export_invoice_items_page_store'
import { getAddMonth, getDate, getMonth, getSubtractMonth, getYear } from 'shared/utils/date'
import { ExportInvoiceItemsUrlService } from 'pages/export_invoice_items_page/services/export_invoice_items_url_service'

export class FacilityExportInvoiceItemsService {
  /**
   * 初期値が欲しい時に呼ばれる関数
   * @param page ページ番号
   */
  static async loadInitialData(page?: number): Promise<void> {
    await Promise.all([
      FacilityExportInvoiceItemsService.loadInvoiceItemNameCandidates(),
      FacilityExportInvoiceItemsService.loadOldestFacilityCreatedAt(),
      FacilityExportInvoiceItemsService.loadInvoicePageInitialTargetMonth(),
    ])
    FacilityExportInvoiceItemsService.setInitialSearchDate()

    // クエリパラメータ入力済みならクエリパラメータの値をstoreに入れる
    // 未入力ならstoreの値の初期値をクエリパラメータに入れる(storeの値のデフォルト値自体が初期検索値)
    if (location.search.toString() !== '') {
      ExportInvoiceItemsUrlService.copyQueryParamsToStore({ isFacility: true })
    }

    // storeの値に応じて選択候補の年月を入れたいので、クエリパラメータを読み取った後に呼ぶ
    CalculateYearAndMonthRangeService.setSelectableYearAndMonthRange()
    // アカウント設定の初期値を参照してからじゃないと、対象期間の初期値を入れれないのでPromise.allの後に呼ぶ
    await FacilityExportInvoiceItemsService.loadInvoiceItems(page)
  }

  static setInitialSearchDate(): void {
    const invoicePageInitialTargetMonth = ExportInvoiceItemsPageStore.invoicePageInitialTargetMonth
    let initialSearchDate

    switch (invoicePageInitialTargetMonth) {
      case 'last_month':
        initialSearchDate = getSubtractMonth(new Date(), 1)
        break
      case 'this_month':
        initialSearchDate = getDate(new Date())
        break
      case 'next_month':
        initialSearchDate = getAddMonth(new Date(), 1)
        break
      default:
        initialSearchDate = getDate(new Date())
    }

    ExportInvoiceItemsPageStore.updateFromYearAndMonth({
      year: getYear(initialSearchDate),
      month: getMonth(initialSearchDate),
    })
    ExportInvoiceItemsPageStore.updateToYearAndMonth({
      year: getYear(initialSearchDate),
      month: getMonth(initialSearchDate),
    })
  }

  /**
   * 施設の請求内容一覧を取得する関数
   */
  static async loadInvoiceItems(page?: number): Promise<void> {
    ExportInvoiceItemsPageStore.updateIsLoading(true)

    const facilityId = FacilityExportInvoiceItemsService.getTargetFacilityId()
    const { from, to, termType, status, includeKeyword, itemNames } =
      ConvertToQueryParamsService.convertToQueryParams()

    const apiResponse = await FacilityInvoiceItemsApi.index({
      facilityId: facilityId,
      from,
      to,
      termType,
      itemNames,
      status,
      includeKeyword,
      page: page ?? 1,
      perPage: 50,
    })
    const invoiceItems = ConvertToInvoiceItemsService.convertToInvoiceItems(apiResponse.data)
    ExportInvoiceItemsPageStore.updateInvoiceItems(invoiceItems)
    ExportInvoiceItemsPageStore.updateIsLoading(false)
  }

  private static async loadInvoiceItemNameCandidates(): Promise<void> {
    const response = await InvoiceItemNameCandidateApi.index(
      FacilityExportInvoiceItemsService.getTargetFacilityId()
    )
    ExportInvoiceItemsPageStore.updateInvoiceItemNameCandidates(response.data.invoice_items)
  }

  private static async loadOldestFacilityCreatedAt(): Promise<void> {
    const response = await FacilityApi.show(FacilityExportInvoiceItemsService.getTargetFacilityId())
    ExportInvoiceItemsPageStore.updateOldestFacilityCreatedAt(getDate(response.data.created_at))
  }

  private static async loadInvoicePageInitialTargetMonth(): Promise<void> {
    const response = await AccountSettingApi.index()
    const invoicePageInitialTargetMonth = response.settings.invoice_page_initial_target_month
    ExportInvoiceItemsPageStore.updateInvoicePageInitialTargetMonth(invoicePageInitialTargetMonth)
  }

  private static getTargetFacilityId(): number {
    return Number(RouteService.getPathParamByIndex(1))
  }
}
