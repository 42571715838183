<template>
  <div>
    <Button
      variant="outlined"
      :disabled="isDisabledPrev"
      width="96px"
      :class="`font-weight-normal outlined-button fs-16 mr-3 ${
        isDisabledPrev ? 'disabled-button' : ''
      }`"
      @click="handleClickPrev"
      >前へ</Button
    >
    <v-pagination
      v-model="currentPage"
      :length="totalPages"
      :total-visible="7"
      density="comfortable"
      variant="flat"
      active-color="primary"
      @update:model-value="handleChangeCurrentPage"
    />
    <Button
      variant="outlined"
      :disabled="isDisabledNext"
      width="96px"
      :class="`font-weight-normal outlined-button fs-16 ml-3 ${
        isDisabledNext ? 'disabled-button' : ''
      }`"
      @click="handleClickNext"
      >次へ</Button
    >
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import Button from 'shared/components/Button.vue'
import { PaginationStore } from 'shared/components/pagination/pagination_store'

defineProps<{
  totalPages: number
  isDisabledPrev: boolean
  isDisabledNext: boolean
  handleClickPrev: () => void
  handleClickNext: () => void
}>()

const emit = defineEmits<{
  (event: 'onPageChange', page: number): void
  (event: 'handleChangeCurrentPage', page: number): void
}>()

const currentPage = computed({
  get: () => PaginationStore.currentPage,
  set: (page: number) => {
    PaginationStore.updateCurrentPage(page)
  },
})

const handleChangeCurrentPage = (page: number) => {
  emit('handleChangeCurrentPage', page)
  emit('onPageChange', page)
}
</script>

<style scoped lang="scss">
[role='navigation'] ::v-deep .v-pagination__list > li {
  margin: 4px;
  border: 1px solid #0068b7;
  border-radius: 6px;
}
[role='navigation'] ::v-deep .v-pagination__item--is-active {
  box-shadow: initial;
  pointer-events: none;
}
[role='navigation'] ::v-deep .v-pagination__item:not(.v-pagination__item--is-active) button {
  box-shadow: initial;
  color: #0068b7 !important;
}
[role='navigation'] ::v-deep .v-pagination > li:has(.v-pagination__more) {
  margin: initial !important;
  border: initial;
  border-radius: none;
}
[role='navigation'] ::v-deep .v-pagination__more {
  margin: initial !important;
}
[role='navigation'] ::v-deep .v-pagination__item {
  margin: initial;
}
[role='navigation'] ::v-deep .v-pagination__item:has(.v-btn--disabled) {
  border: initial !important;
}
[role='navigation'] ::v-deep .v-pagination__item:has(.v-btn--disabled) button {
  box-shadow: initial;
  color: #f4f6f9 !important;
  background-color: #f4f6f9 !important;
  border: initial !important;
}
[role='navigation'] ::v-deep .v-pagination__item:has(.v-btn--disabled) .v-btn__content {
  color: #000 !important;
}
// カスタムで前後ボタンを作成するため、デフォルトのボタンは強制的に非表示にする
[role='navigation'] ::v-deep .v-pagination__list > li:first-child,
[role='navigation'] ::v-deep .v-pagination__list > li:last-child {
  display: none;
}
.outlined-button {
  line-height: 24px;
  background-color: #fff;
}
.fs-16 {
  font-size: 16px;
}
.disabled-button {
  border: 1px solid #e0e0e0;
  background: #e0e0e0;
}
</style>
