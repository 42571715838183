/**
 * コンポーネントの高さ調整に関するロジックを持ちます。
 * 本来ならCSSのみでできるはずなので、VuetifyのApplicationコンポーネントを使えるようになったら
 * さっさとCSSのみに移行してこんなファイル消してしまいたい。
 *
 * cf.
 * https://vuetifyjs.com/ja/components/application/#application-30b330f330dd30fc30cd30f330c8
 */
export class ElementHeightService {
  static setInvoiceHeaderListHeight(): void {
    const headerHeight = (document.querySelector('.v-app-bar') as HTMLElement).offsetHeight
    const paddingHeight = 32
    const expansionPanelHeight = (document.querySelector('.v-expansion-panel') as HTMLElement)
      .offsetHeight
    const tabHeight = 52
    ;(document.querySelector('.tabs-item') as HTMLElement).style.height =
      window.innerHeight - headerHeight - paddingHeight - expansionPanelHeight - tabHeight + 'px'
  }

  /**
   * 請求画面のcolumnWrapperのheightとmargin-topを調整します。
   */
  static setColumnWrapperPosition(): void {
    const unsupportedBrowserHeight = (
      document.querySelector('.unsupported-browser-notification') as HTMLElement
    )?.offsetHeight
    const headerHeight = (document.querySelector('.v-app-bar') as HTMLElement).offsetHeight

    ;(document.querySelector('.column-wrapper') as HTMLElement).style.height =
      window.innerHeight - headerHeight - (unsupportedBrowserHeight || 0) + 'px'
  }
}
