<template>
  <FormContainer>
    <template #title>パスワード</template>
    <template #form-content>
      <v-row>
        <v-col cols="5">
          <label class="text-subtitle-1 font-weight-medium" for="current-password-input"
            >現在のパスワード</label
          >
        </v-col>
        <v-spacer />
        <v-col cols="5">
          <TextInput
            id="current-password-input"
            data-testid="current-password-input"
            :value="currentPassword"
            :on-change="(value: string) => (currentPassword = value)"
            density="default"
            :rules="currentPasswordRules"
            :type="isVisibleCurrentPassword ? 'text' : 'password'"
            :append-inner-icon="isVisibleCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :click-icon="handleCurrentPasswordVisibility"
            :error-messages="currentPasswordError"
            class="font-weight-medium"
            @blur="resetCurrentPasswordError"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5">
          <label class="text-subtitle-1 font-weight-medium mb-2" for="new-password-input"
            >新しいパスワード</label
          >
          <div class="text-subtitle-1 font-weight-medium item-description">
            半角英数字、6文字以上
          </div>
        </v-col>
        <v-spacer />
        <v-col cols="5">
          <TextInput
            id="new-password-input"
            data-testid="new-password-input"
            :value="newPassword"
            :on-change="(value: string) => (newPassword = value)"
            density="default"
            :rules="newPasswordRules"
            :type="isVisibleNewPassword ? 'text' : 'password'"
            :append-inner-icon="isVisibleNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :click-icon="handleNewPasswordVisibility"
            class="font-weight-medium"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5">
          <label class="text-subtitle-1 font-weight-medium" for="new-confirm-password-input"
            >新しいパスワード（確認用）</label
          >
        </v-col>
        <v-spacer />
        <v-col cols="5">
          <TextInput
            id="new-confirm-password-input"
            data-testid="new-confirm-password-input"
            :value="newConfirmPassword"
            :on-change="(value: string) => (newConfirmPassword = value)"
            :rules="newConfirmPasswordRules"
            density="default"
            :type="isVisibleNewConfirmPassword ? 'text' : 'password'"
            :append-inner-icon="isVisibleNewConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :click-icon="handleNewConfirmPasswordVisibility"
            class="font-weight-medium"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="text-subtitle-1 font-weight-medium text-right">
            パスワードを変更すると、自動的にログアウトされます。<br />
            新しいパスワードで再度ログインしてください。
          </div>
        </v-col>
      </v-row>
    </template>
    <template #actions>
      <Button
        size="large"
        variant="outlined"
        data-testid="password-button"
        :disabled="isButtonDisabled"
        @click="updatePassword"
        >保存する</Button
      >
    </template>
  </FormContainer>
</template>
<script setup lang="ts">
import FormContainer from 'pages/account_settings_page/components/FormContainer.vue'
import TextInput from 'shared/components/TextInput.vue'
import Button from 'shared/components/Button.vue'
import { SnackbarService } from 'shared/services/snackbar_service'
import { AccountSettingPasswordApi, isAxiosError } from 'http/modules/account_setting_password'
import { ref, computed } from 'vue'

const isVisibleCurrentPassword = ref(false)
const isVisibleNewPassword = ref(false)
const isVisibleNewConfirmPassword = ref(false)
const currentPassword = ref('')
const currentPasswordError = ref('') // 現在のパスワードのみAPIからのエラーメッセージを表示させる必要があるため
const newPassword = ref('')
const newConfirmPassword = ref('')

const currentPasswordRules = [(value: string) => !!value || '現在のパスワードを入力してください']
const newPasswordRules = [
  (value: string) => {
    const isValid =
      value && value.length >= 6 && /^(?=[^\s]*\d)(?=[^\s]*[a-zA-Z])[^\s]{6,}$/.test(value)
    return isValid || '半角英数字6文字以上で入力してください'
  },
]
const newConfirmPasswordRules = [
  (value: string) => newPassword.value === value || 'パスワードが一致しません',
]

const resetCurrentPasswordError = () => {
  currentPasswordError.value = ''
}

const handleCurrentPasswordVisibility = () => {
  isVisibleCurrentPassword.value = !isVisibleCurrentPassword.value
}
const handleNewPasswordVisibility = () => {
  isVisibleNewPassword.value = !isVisibleNewPassword.value
}
const handleNewConfirmPasswordVisibility = () => {
  isVisibleNewConfirmPassword.value = !isVisibleNewConfirmPassword.value
}

const updatePassword = async () => {
  try {
    // パスワード更新処理
    const params = {
      current_password: currentPassword.value,
      password: newPassword.value,
      password_confirmation: newConfirmPassword.value,
    }
    await AccountSettingPasswordApi.update(params)

    location.href = '/school_accounts/sign_in'
  } catch (error) {
    if (
      isAxiosError(error) &&
      error.response?.status === 422 &&
      'current_password' in error.response.data.errors
    ) {
      // 422エラーの場合はパスワードが間違っていることが主である
      currentPasswordError.value = 'パスワードが間違っています'
    } else {
      const message = 'パスワードの更新に失敗しました。'
      SnackbarService.open(message, 'error', {
        variant: 'flat',
        showIcon: true,
        location: 'bottom right',
      })
    }
  }
}

const isButtonDisabled = computed(() => {
  // validation結果取得
  const hasCurrentPasswordError = currentPasswordRules.some(
    (rule) => typeof rule(currentPassword.value) === 'string'
  )
  const hasNewPasswordError = newPasswordRules.some(
    (rule) => typeof rule(newPassword.value) === 'string'
  )
  const hasNewConfirmPasswordError = newConfirmPasswordRules.some(
    (rule) => typeof rule(newConfirmPassword.value) === 'string'
  )

  return (
    hasCurrentPasswordError ||
    hasNewPasswordError ||
    hasNewConfirmPasswordError ||
    currentPasswordError.value !== ''
  )
})
</script>
<style scoped>
.item-description {
  color: #727272;
}
</style>
