<template>
  <v-row data-testid="item-form-row">
    <v-col cols="5">
      <v-combobox
        ref="nameField"
        v-model="computedName"
        class="input"
        hide-details="true"
        :disabled="isDisable"
        label="請求内容"
        density="compact"
        variant="outlined"
        :items="autoCompleteItems"
        item-title="name"
        item-value="name"
        :hide-no-data="true"
        color="primary"
        data-testid="item-name"
        @update:model-value="selectItem"
        @blur="blurCombobox(nameField)"
      >
        <template #item="{ props, item }">
          <template v-if="item.raw.master_flg">
            <v-list-item v-bind="props" title="">
              <v-row class="autocomplete-row">
                <v-col cols="8">{{ item.raw.name }}</v-col>
                <v-col cols="2" class="d-flex justify-end">{{ item.raw.unit_price }}円</v-col>
                <v-col cols="2" class="d-flex justify-end"
                  ><v-tooltip location="right">
                    <template #activator="{ props: tooltipActivatorProps }"
                      ><v-icon v-bind="tooltipActivatorProps" color="grey"
                        >mdi-cog</v-icon
                      ></template
                    >
                    <span>マスタ</span>
                  </v-tooltip></v-col
                >
              </v-row>
            </v-list-item>
          </template>
          <template v-else>
            <v-list-item v-bind="props" title="">
              <v-row
                class="autocomplete-row"
                @click="$eventTracker.trackEvent('click_dropdown_invoice_history_item', {})"
              >
                <v-col cols="10">{{ item.raw.name }}</v-col>
                <v-col cols="2" class="d-flex justify-end">
                  <v-tooltip location="right">
                    <template #activator="{ props: tooltipActivatorProps }">
                      <v-icon v-bind="tooltipActivatorProps" color="grey">mdi-history</v-icon>
                    </template>
                    <span>履歴</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-list-item>
          </template>
        </template>
      </v-combobox>
      <div v-if="isCodmonInvoiceItem" class="d-flex">
        <span class="icon icon-codmon"></span>
        <span class="text-body-2">コドモン連携中</span>
      </div>
    </v-col>
    <v-col cols="2">
      <v-text-field
        ref="countField"
        v-model="computedCount"
        class="input"
        hide-details="true"
        :rules="[countRules]"
        :disabled="isDisable"
        min="1"
        label="数量"
        variant="outlined"
        density="compact"
        data-testid="item-count"
      ></v-text-field>
    </v-col>
    <v-col cols="2">
      <v-text-field
        ref="unitPriceField"
        v-model="computedUnitPrice"
        class="input"
        hide-details="true"
        :rules="[unitPriceRules]"
        :disabled="isDisable"
        label="単価"
        variant="outlined"
        density="compact"
        data-testid="item-price"
      ></v-text-field>
    </v-col>
    <v-col cols="3">
      <v-row>
        <v-col cols="8"
          ><AmountLabel :amount="price" :size="'M'" data-testid="item-form-row-total"
        /></v-col>
        <v-col cols="4" data-testid="item-form-reset-button">
          <Button color="grey" variant="text" icon :disabled="isInvoiceLocked" @click="clearInput">
            <v-icon>mdi-close-circle</v-icon>
          </Button>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { computed, defineComponent, ref, nextTick } from 'vue'
import AmountLabel from '../../../shared/components/AmountLabel.vue'
import { InvoiceItemInputService } from '../services/invoice_item_input_service'
import { InvoiceItemService } from '../services/invoice_item_service'
import Button from 'shared/components/Button.vue'

/**
 * inputをemitした際、第一引数にはindex、第二引数には変更後の値を表すオブジェクト{[key: 'name' | 'count' | 'unitPrice']: string}が渡されます
 */
export default defineComponent({
  components: {
    AmountLabel,
    Button,
  },
  props: {
    /**
     * 請求項目の識別子。inputをemitする際に第一引数として渡されます。
     */
    index: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: false,
    },
    count: {
      type: String,
      required: false,
    },
    unitPrice: {
      type: String,
      required: false,
    },
    isCodmonItem: {
      type: Boolean,
      required: true,
    },
    autoCompleteItems: {
      type: Array,
      required: true,
    },
    // 請求ロック時はすべてのinputが非活性となります。
    isInvoiceLocked: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  // eslint-disable-next-line max-lines-per-function
  setup(props, context) {
    const nameField = ref(null)
    const countField = ref(null)
    const unitPriceField = ref(null)

    // computed
    const computedName = computed({
      get: () => (props.name ? [props.name] : []),
      set: (value) => {
        if (typeof value === 'object') {
          // autocompleteによる値の変更が起きたときは何もしない
          return
        }
        context.emit('input', props.index, { name: value })
      },
    })
    const computedCount = computed({
      get: () => props.count,
      set: (value) => context.emit('input', props.index, { count: value }),
    })
    const computedUnitPrice = computed({
      get: () => props.unitPrice,
      set: (value) => context.emit('input', props.index, { unitPrice: value }),
    })
    const price = computed(() =>
      InvoiceItemInputService.calculateInputSubtotal(computedCount.value, computedUnitPrice.value)
    )
    const isCodmonInvoiceItem = computed(() => props.isCodmonItem)
    const isDisable = computed(() => props.isCodmonItem || props.isInvoiceLocked)

    // methods
    const clearInput = () => {
      computedName.value = ''
      computedCount.value = '1'
      computedUnitPrice.value = ''
    }
    const changeItem = () => {
      nextTick(() => {
        countField.value.validate()
        unitPriceField.value.validate()
      })
    }
    const selectItem = (selectedItem) => {
      changeItem()
      if (typeof selectedItem === 'string') {
        // autocompleteによらない値の変更（キーボードによる文字入力）が起きたときは何もしない
        return
      }
      // 値が選択されていない場合、Vuetifyはnullを返す
      if (selectedItem === null) {
        computedName.value = ''
        return
      }
      computedName.value = selectedItem.name
      if (selectedItem.master_flg) {
        computedUnitPrice.value = selectedItem.unit_price
          ? String(selectedItem.unit_price)
          : computedUnitPrice.value
      }
    }

    /**
     * v-comboboxの編集中に保存するボタンをクリックするとcomputedNameが更新前のため、編集前の値が取得される
     * 1. v-comboboxのblurイベント
     * 2. 保存するボタンのclickイベント（編集前の値）
     * 3. computedNameの値の更新
     * そのため、保存するボタンのclickイベント中にblurメソッドを実行する
     * 1. v-comboboxのblurイベント
     * 2. v-comboboxのblurメソッドを実行
     * 3. computedNameの値の更新
     * 4. 保存するボタンのclickイベント（編集後の値）
     * こうすることで、保存するボタンのclickイベント中に編集後の値を取得できるようになる
     */
    const blurCombobox = (current) => current.blur()

    const countRules = (count) => {
      const name = computedName.value
      return InvoiceItemService.countRule(name, count) || false
    }
    const unitPriceRules = (unitPrice) => {
      const name = computedName.value
      return InvoiceItemService.unitPriceRule(name, unitPrice) || false
    }

    return {
      nameField,
      countField,
      unitPriceField,
      computedName,
      computedCount,
      computedUnitPrice,
      price,
      isCodmonInvoiceItem,
      isDisable,
      clearInput,
      selectItem,
      changeItem,
      blurCombobox,
      countRules,
      unitPriceRules,
    }
  },
})
</script>
<style scoped>
.autocomplete-row {
  min-width: 600px;
}
.input {
  background-color: white;
}
</style>
