import { YearMonthRange } from 'http/modules/corporations_transfer'
import { HTTP } from '../common'

export type CollectTotalOfMonth = {
  amount_of_month: number
  paid_year_month: string
}

export type CorporationsTransferSummary = {
  collect_total_of_months: CollectTotalOfMonth[]
  collect_total: number | undefined
  transfer_amount: number | undefined
  settlement_fee: number | undefined
  enpay_service_charge: number | undefined
  tax: number | undefined
  cumulative_unpaid_amount: number | undefined
  convenience_store_fee_applied: boolean
  convenience_store_fee_total: number | undefined
  transfer_fee_applied: boolean
  transfer_fee_total: number | undefined
}

export type SummaryResponse = {
  data: CorporationsTransferSummary
}

export class CorporationsTransferSummaryApi {
  /**
   * 指定期間の、全施設の振込の合計額を取得します
   */
  static summary(targetYearMonthRange: YearMonthRange): Promise<SummaryResponse> {
    return HTTP.get('/corporations/me/transfer_summary', {
      params: {
        from: targetYearMonthRange.from,
        to: targetYearMonthRange.to,
      },
    })
  }
}
