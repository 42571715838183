<template>
  <transition name="modal">
    <div
      v-if="isShowModal"
      class="modal active"
      style="display: flex"
      data-testid="invoice-form-modal"
      @click.self="closeInvoiceFormModal()"
    >
      <v-container class="modal_container">
        <InvoiceItemDetailBody
          :parent-id="parentId"
          :status="status"
          :is-invoice-locked="isInvoiceLocked"
        />
      </v-container>
    </div>
  </transition>
</template>

<script>
import { SchoolParentService } from 'pages/top_page/services/school_parent_service'
import { InvoiceModalService } from 'pages/top_page/services/invoice_modal_service'
import InvoiceItemDetailBody from './InvoiceItemDetailBody.vue'
import { TopPageStore } from '../top_page_store'
export default {
  components: {
    InvoiceItemDetailBody,
  },
  props: {
    isShowModal: Boolean,
    parentId: Number,
    status: String,
  },
  computed: {
    parent() {
      return SchoolParentService.getParentById(this.parentId)
    },
    isInvoiceLocked() {
      return TopPageStore.isLockedMonth
    },
  },
  methods: {
    closeInvoiceFormModal: function () {
      InvoiceModalService.closeInvoiceFormModal()
    },
  },
}
</script>
