<template>
  <v-form ref="branchNamePanel" v-model="isValid.branchNamePanel">
    <div class="corporation-apply-panel-1 ml-3">
      <v-text-field
        v-model="formValue.oemPartnerBranchName"
        :label="
          oemPartnerName +
          'とお取引がある場合は支店名を入力ください。（お取引がない、支店名が不明な場合は空白）'
        "
      ></v-text-field>
      <div v-show="false">{{ isInitial.branchNamePanel }}</div>
    </div>
  </v-form>
</template>

<script>
import { CorporationApplyPageStore } from '../corporation_apply_page_store'

export default {
  props: {
    oemPartnerName: {
      type: String,
      default: '',
    },
  },
  computed: {
    formValue() {
      return CorporationApplyPageStore.formValue
    },
    isValid() {
      return CorporationApplyPageStore.validState
    },
    isInitial() {
      if (!CorporationApplyPageStore.initializedState.emailPanel) {
        this.$refs.emailPanel.validate()
      }
      return CorporationApplyPageStore.initializedState
    },
  },
}
</script>
