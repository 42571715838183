<template>
  <div>
    <span class="text-body-1">
      <span class="font-weight-bold">{{ notLockedTotal }}施設</span>
      の請求を締めます。<br />
      請求を締めたあとは、施設側で請求内容の変更が行えなくなります。
    </span>
    <v-alert v-if="isAllBeforeCharge" type="error" variant="outlined" class="mt-4">
      <p class="font-weight-bold">請求が締められません。</p>
      <p class="mt-4 text-black">
        対象となるすべての施設に保存中の請求があるため、締められません。
        <br />
        請求を送信するか、保存中の請求を削除してください。
      </p>
    </v-alert>
    <v-alert
      v-else-if="beforeChargeTotal > 0"
      type="warning"
      icon="mdi-alert-circle"
      variant="outlined"
      class="mt-4"
    >
      <p class="font-weight-bold">保存中の請求がある施設は{{ beforeChargeTotal }}件です。</p>
      <p class="mt-4 text-black">請求内容が確定している施設のみ、請求を締めます。</p>
    </v-alert>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  notLockedTotal: number
  beforeChargeTotal: number
}>()

const isAllBeforeCharge = computed(() => props.notLockedTotal === props.beforeChargeTotal)
</script>
