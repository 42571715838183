<template>
  <v-card elevation="0" class="px-6 py-5">
    <v-container fluid>
      <v-row class="d-flex align-center justify-space-between">
        <v-col cols="6" class="pa-0 d-flex">
          <ParentDeletingCheckBox
            v-if="bulkOperationMode === 'delete'"
            :is-selected-all="isSelectedAll"
            :parent="parent"
            :invisible-children="invisibleChildren"
            @on-change-parent-selected="onChangeParentSelected"
          />
          <ParentLineCheckBox
            v-else-if="bulkOperationMode === 'line'"
            :is-selected-all="isSelectedAll"
            :parent="parent"
            :invisible-children="invisibleChildren"
            @on-change-parent-selected="onChangeParentSelected"
          />
          <div v-else class="text-h6 font-weight-bold" style="word-break: break-all">
            {{ parent.last_name }} {{ parent.first_name }}
          </div>
        </v-col>
        <v-col cols="3" class="pa-0">
          <OutlinedLabel label="保護者コード">
            <v-tooltip v-if="parent.code" location="top">
              <template #activator="{ props }">
                <span class="font-weight-medium long-text text-left" v-bind="props">{{
                  parent.code
                }}</span>
              </template>
              <span class="font-weight-medium long-text text-left">{{ parent.code }}</span>
            </v-tooltip>
            <span v-else class="font-weight-medium long-text text-left">{{
              parent.code || '-'
            }}</span>
          </OutlinedLabel>
        </v-col>
        <v-col cols="auto" class="pa-0" style="width: 150px">
          <div v-if="parent.line_connected" class="d-flex">
            <IconLabel
              image-path="/icons/line.svg"
              value="連携中"
              class="align-self-center"
              style="white-space: pre"
            />
            <v-tooltip location="top">
              <template #activator="{ props }">
                <div v-bind="props">
                  <Button
                    variant="text"
                    class="pa-0"
                    style="text-decoration: underline; font-size: 16px"
                    @click="$emit('onClickDisconnectLINE', parent)"
                  >
                    解除
                  </Button>
                </div>
              </template>
              <span>LINE連携を解除する</span>
            </v-tooltip>
          </div>
          <div v-else></div>
        </v-col>
        <v-col cols="auto" class="pa-0">
          <SquareButton
            icon="mdi-pencil"
            text="編集"
            class="square-button mr-2"
            :href="`/facilities/${facilityId}/parents/${parent.id}/edit`"
          />
          <SquareButton
            icon="mdi-printer"
            text="印刷"
            class="square-button"
            :href="`/facilities/${facilityId}/parent_accounts/printings/${parent.id}`"
            target="_blank"
          />
        </v-col>
      </v-row>
      <v-row>
        <ChildrenList
          v-if="visibleChildren.length > 0"
          v-model:is-selected-all="isSelectedAll"
          :parent="parent"
          :children="visibleChildren"
          :bulk-operation-mode="bulkOperationMode"
          @on-change-child-selected="onChangeChildSelected"
          @on-click-disconnect-codmon="onClickDisconnectCodmon"
          @on-click-connect-codmon="onClickConnectCodmon"
        />
      </v-row>
      <v-row>
        <InfoAlert
          v-if="invisibleChildren.length > 0"
          :text="alertOnHavingInvisibleChildrenText"
          class="mt-4"
          style="width: 100%"
        />
      </v-row>
    </v-container>
  </v-card>
</template>
<script lang="ts">
import { defineComponent, PropType, computed, ref, watch } from 'vue'
import OutlinedLabel from 'shared/components/label/OutlinedLabel.vue'
import IconLabel from 'shared/components/label/IconLabel.vue'
import SquareButton from 'shared/components/button/SquareButton.vue'
import InfoAlert from 'shared/components/alert/InfoAlert.vue'
import ChildrenList from 'pages/parents_page/areas/parent_card/components/ChildrenList.vue'
import { Parent, BulkOperationMode, Child } from 'pages/parents_page/model'
import Button from 'shared/components/Button.vue'
import { groupBy } from 'shared/utils/groupBy'
import ParentDeletingCheckBox from 'pages/parents_page/areas/parent_card/components/ParentDeletingCheckBox.vue'
import ParentLineCheckBox from 'pages/parents_page/areas/parent_card/components/ParentLineCheckBox.vue'

export default defineComponent({
  components: {
    OutlinedLabel,
    IconLabel,
    SquareButton,
    ChildrenList,
    Button,
    InfoAlert,
    ParentDeletingCheckBox,
    ParentLineCheckBox,
  },
  props: {
    parent: {
      type: Object as PropType<Parent>,
      required: true,
    },
    bulkOperationMode: {
      type: String as PropType<BulkOperationMode>,
      default: undefined,
    },
    facilityId: {
      type: Number,
      required: true,
    },
    isSelectedAll: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'onChangeParentSelected',
    'onChangeChildSelected',
    'onClickDisconnectLINE',
    'onClickDisconnectCodmon',
    'onClickConnectCodmon',
  ],
  setup(props, { emit }) {
    const schoolParentChildren = ref(props.parent.school_parent_children)
    const groupedChildren = computed(() =>
      groupBy(schoolParentChildren.value, (child) => (child.matched ? 'visible' : 'invisible'))
    )
    const visibleChildren = computed(() => groupedChildren.value.visible || [])
    const invisibleChildren = computed(() => groupedChildren.value.invisible || [])
    const onChangeParentSelected = (selectedParents: number[], parent: Parent) => {
      emit('onChangeParentSelected', selectedParents, parent)
    }
    const onChangeChildSelected = (selectedChildren: number[], parent: Parent) => {
      emit('onChangeChildSelected', selectedChildren, parent)
    }
    const onClickDisconnectCodmon = (parent: Parent, child: Child) => {
      emit('onClickDisconnectCodmon', parent, child)
    }
    const onClickConnectCodmon = (parent: Parent, child: Child) => {
      emit('onClickConnectCodmon', parent, child)
    }
    const invisibleChildNames = invisibleChildren.value.map((child) => child.first_name + 'さん')
    const alertOnHavingInvisibleChildrenText = computed(() =>
      props.bulkOperationMode === 'delete'
        ? `他に${invisibleChildNames.length}名の子ども(${invisibleChildNames.join(
            '、'
          )})がいるため、保護者は削除できません。`
        : `${invisibleChildNames.length}名の子ども(${invisibleChildNames.join(
            '、'
          )})は現在の検索条件に一致しないため表示されていません。`
    )

    watch(
      () => props.parent.school_parent_children,
      (newValue) => {
        schoolParentChildren.value = newValue
      }
    )

    return {
      onChangeParentSelected,
      onChangeChildSelected,
      onClickDisconnectCodmon,
      onClickConnectCodmon,
      visibleChildren,
      invisibleChildren,
      alertOnHavingInvisibleChildrenText,
    }
  },
})
</script>
<style scoped lang="scss">
.square-button {
  background: #eaeef4;
  padding: 4px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
}
.long-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 150px;
}
.v-btn:not(.v-btn--round).v-size--default {
  min-width: initial;
}
</style>
