import { ParentIdPrintPageStore } from '../parent_id_print_page_store'
import { ParentAccountApi } from 'http/modules/parent_account'
import { LineQrCodeApi } from 'http/modules/line_qr_code'
import { RouteService } from 'shared/services/route_service'
import { FacilityApi } from 'http/modules/facility'

/**
 * 保護者のID印刷に必要なロジックを持ちます
 */
export class ParentIdPrintService {
  static async loadParentIdPrintInfo(parentId: number): Promise<void> {
    try {
      ParentIdPrintPageStore.updateIsLoading(true)
      const response = (
        await ParentAccountApi.show(ParentIdPrintService.getTargetFacilityId(), parentId)
      ).data
      ParentIdPrintPageStore.updateParentAccount(response)
      const qrCodeImageSource = (
        await LineQrCodeApi.show(ParentIdPrintService.getTargetFacilityId())
      ).data.qr_code_url
      ParentIdPrintPageStore.updateQrCodeImageSource(qrCodeImageSource)

      const canUseEnpayWallet = (await FacilityApi.show(ParentIdPrintService.getTargetFacilityId()))
        .data.can_use_enpay_wallet
      ParentIdPrintPageStore.updateCanUseEnpayWallet(canUseEnpayWallet)
    } finally {
      ParentIdPrintPageStore.updateIsLoading(false)
    }
  }

  static getTargetFacilityId(): number {
    return Number(RouteService.getPathParamByIndex(1))
  }
}
