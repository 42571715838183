<template>
  <div class="d-flex align-center">
    <v-chip class="font-weight-medium px-1 mr-2 chip" label variant="outlined">{{ label }}</v-chip>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
  },
})
</script>
<style scoped lang="scss">
.chip {
  height: initial;
  color: #727272;
  pointer-events: none;
  cursor: default;
}
.v-chip.v-chip--outlined {
  border-color: #727272;
}
.v-chip--label {
  border-radius: 2px !important;
}
</style>
