<template>
  <div>
    <div class="font-weight-bold">
      <span class="text-primary text-h5 font-weight-bold"
        >{{ targetYear }}年{{ Number(targetMonth) }}月</span
      ><span class="text-h6 font-weight-bold text-black">の請求内容です</span>
    </div>
    <div class="mt-6">
      <div class="text-body-1 font-weight-medium mb-1">LINE {{ lineInvoicesCount }}件</div>
      <div class="text-body-1 font-weight-medium">請求書 {{ invoicesCount }}件</div>
    </div>
    <div class="mt-6">
      <div class="text-body-1 font-weight-medium">
        以上の請求を請求保存中へ変更します。よろしいですか？
      </div>
      <v-sheet class="bg-lightblue pa-4 mt-6">
        <div class="text-body-1 font-weight-bold mb-2">注意事項</div>
        <div class="text-red text-body-1 font-weight-medium">・保護者は支払えなくなります。</div>
        <div class="text-red text-body-1 font-weight-medium">
          ・LINE登録をしている保護者には、請求取り消しの通知が送信されます。
        </div>
        <div class="text-red text-body-1 font-weight-medium">
          ・請求書を利用している保護者には、請求取り消しの連絡をお願いします。
        </div>
      </v-sheet>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    targetYear: {
      type: String,
      required: true,
    },
    targetMonth: {
      type: String,
      required: true,
    },
    lineInvoicesCount: {
      type: Number,
      required: true,
    },
    invoicesCount: {
      type: Number,
      required: true,
    },
  },
})
</script>
<style lang="scss" scoped>
@import '../../../../assets/stylesheets/design/variables';
.bg-lightblue {
  background-color: $color-bg;
}
</style>
