import { reactive } from 'vue'
import { Invoice } from '../../http/modules/unpaid_invoice'
import { School } from 'http/modules/facility'

type InvoicePrintPageState = {
  invoices: Invoice[]
  isLoading: boolean
  isShowClassName: boolean
} & Pick<School, 'parent_convenience_store_fee_apply_status'>

/**
 * 請求書印刷ページページの状態。
 */
const state = reactive<InvoicePrintPageState>({
  invoices: [],
  isLoading: false,
  isShowClassName: false,
  parent_convenience_store_fee_apply_status: {
    current_month: null,
    next_month: null,
  },
})

export class InvoicePrintPageStore {
  static get invoices(): Invoice[] {
    return state.invoices
  }

  static updateInvoices(invoices: Invoice[]): void {
    state.invoices = invoices
  }

  static get isLoading(): boolean {
    return state.isLoading
  }

  static updateIsLoading(isLoading: boolean): void {
    state.isLoading = isLoading
  }

  static get isShowClassName(): boolean {
    return state.isShowClassName
  }

  static updateIsShowClassName(isShowClassName: boolean) {
    state.isShowClassName = isShowClassName
  }

  static get parentConvenienceStoreFeeApplyStatus() {
    return state.parent_convenience_store_fee_apply_status
  }

  static updateParentConvenienceStoreFeeApplyStatus(
    parent_convenience_store_fee_apply_status: InvoicePrintPageState['parent_convenience_store_fee_apply_status']
  ) {
    state.parent_convenience_store_fee_apply_status = parent_convenience_store_fee_apply_status
  }
}
