<template>
  <div class="section_inner_body">
    <div :class="`color-${invoiceStatus}`" class="content">
      <div @click="toggleInvoiceItems">
        <slot name="school_icon" />
        <slot name="school_name" />
        <span class="float-right button" />
      </div>
      <p><slot name="invoice_total_price" /></p>
    </div>
    <slot name="invoice_status" />
    <div v-show="isShowInvoiceItems" class="invoice_items">
      <slot name="invoice_items" />
      <div v-if="payable">
        <div v-if="useEnpayWallet">
          <a :href="enpayWalletPaymentUrl" class="button-sub">enpayウォレットで支払う</a>
        </div>
        <a :href="payPayPaymentUrl" class="button-sub">PayPayで支払う</a>
        <a :href="linePayPaymentUrl" class="button-sub">LINE Payで支払う</a>
        <a :href="auPayPaymentUrl" class="button-sub">au PAYで支払う</a>
        <div v-if="useMerPay">
          <a :href="merPayPaymentUrl" class="button-sub">メルペイで支払う</a>
        </div>
        <a :href="creditCardPaymentUrl" class="button-sub">クレジットカードで支払う</a>
        <a :href="cvsPaymentUrl" class="button-sub">コンビニで支払う</a>
        <CvsPaymentFeeAlert
          :recent-parent-cvs-payment-fee-status="recent_parent_cvs_fee_apply_status"
          class="cvs-payment-fee-alert"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import CvsPaymentFeeAlert from 'shared/components/cvs_payment_fee_alert/CvsPaymentFeeAlert.vue'
import { CvsPaymentFeeStatus } from 'pages/line_invoice_history_page/model'

const props = defineProps<{
  invoiceStatus: string
  creditCardPaymentUrl: string
  cvsPaymentUrl: string
  linePayPaymentUrl: string
  payPayPaymentUrl: string
  auPayPaymentUrl: string
  enpayWalletPaymentUrl: string
  useEnpayWallet: boolean
  merPayPaymentUrl: string
  useMerPay: boolean
  // eslint-disable-next-line vue/prop-name-casing
  recent_parent_cvs_fee_apply_status: {
    current_month: CvsPaymentFeeStatus | null
    next_month: CvsPaymentFeeStatus | null
  }
}>()

const isShowInvoiceItems = ref(false)
const toggleInvoiceItems = () => {
  isShowInvoiceItems.value = !isShowInvoiceItems.value
}

const payable = computed(() => props.invoiceStatus === 'unpaid')
</script>

<style scoped>
.cvs-payment-fee-alert {
  margin: 0 0 4px;
}
</style>
