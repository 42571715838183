<template>
  <v-sheet width="100%" class="mx-auto mt-6 pa-4">
    <v-container fluid>
      <v-row dense>
        <v-col cols="1" class="d-flex align-center" style="min-width: 120px; height: 48px">
          <span class="font-weight-medium fs-14 grey-text">保護者</span>
        </v-col>
        <v-col>
          <fieldset>
            <v-container fluid>
              <v-row dense>
                <v-col cols="4">
                  <div class="pb-2 d-flex align-start ga-1">
                    <span class="font-weight-bold">姓</span>
                    <ContainedLabel class="bg-red text-white required" text="必須" />
                  </div>
                  <TextInput
                    :value="parent.lastName"
                    placeholder="エンペイ"
                    class="font-weight-medium fs-14 mr-6"
                    :disabled="!isUpdatableParentLastNameAndFirstName"
                    :rules="[
                      parentValidatorsService.isRequired('保護者の姓'),
                      parentValidatorsService.isTooLong('保護者の姓', 255),
                    ]"
                    :on-change="(value: string) => (parent.lastName = value)"
                  />
                </v-col>
                <v-col cols="4">
                  <div class="pb-2 d-flex align-start ga-1">
                    <span class="font-weight-bold">名</span>
                    <ContainedLabel class="bg-red text-white required" text="必須" />
                  </div>
                  <TextInput
                    :value="parent.firstName"
                    placeholder="太郎"
                    class="font-weight-medium fs-14 mr-6"
                    :disabled="!isUpdatableParentLastNameAndFirstName"
                    :rules="[
                      parentValidatorsService.isRequired('保護者の名'),
                      parentValidatorsService.isTooLong('保護者の名', 255),
                    ]"
                    :on-change="(value: string) => (parent.firstName = value)"
                  />
                </v-col>
                <v-col cols="3">
                  <div style="padding-bottom: 10px">
                    <span class="font-weight-bold">保護者コード</span>
                  </div>
                  <TextInput
                    :value="parent.code"
                    placeholder="Code-01"
                    class="font-weight-medium fs-14 mr-6"
                    data-testid="parent-code"
                    :rules="[
                      parentValidatorsService.representativeInvalidCode('保護者コード'),
                      parentValidatorsService.representativeDuplicatedCodeErrorInParent(
                        '保護者コード',
                        parent,
                        [parent]
                      ),
                      parentValidatorsService.isTooLong('保護者コード', 255),
                    ]"
                    :on-change="
                      (value: string) => {
                        parent.code = value
                        resetErrors()
                      }
                    "
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <template v-if="parent.lineConnected">
                    <div class="icon">
                      <S3Image path="/icons/line.svg" class="codmon-icon mr-2" />
                    </div>
                    LINE 連携中 [<a @click="$emit('disconnectLine', parent)">連携解除する</a>]
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </fieldset>
        </v-col>
      </v-row>
      <v-row
        v-for="(child, i) in parent.schoolParentChildren"
        :key="child.temporaryId"
        dense
        :style="i > 0 ? 'padding-top: 4px !important;' : ''"
      >
        <v-col cols="1" class="d-flex align-center" style="min-width: 120px; height: 48px">
          <span v-if="i === 0" class="font-weight-medium fs-14 grey-text">子ども</span>
        </v-col>
        <v-col class="thin-gray">
          <fieldset>
            <v-container fluid>
              <v-row dense>
                <v-col cols="4">
                  <div class="pb-2">
                    <span class="font-weight-bold">子ども名</span>
                  </div>
                  <TextInput
                    :value="child.firstName"
                    placeholder="一郎"
                    background-color="#ffffff"
                    class="font-weight-medium fs-14 mr-6"
                    :disabled="!isUpdatableChildLastNameAndFirstName(child)"
                    :rules="[
                      parentValidatorsService.representativeChildFirstName('子どもの名前', child),
                      parentValidatorsService.isTooLong('子どもの名前', 255),
                    ]"
                    :on-change="(value: string) => (child.firstName = value)"
                  />
                </v-col>
                <v-col cols="4">
                  <div class="pb-2">
                    <span class="font-weight-bold">クラス</span>
                  </div>
                  <SelectInput
                    v-model="child.schoolClassId"
                    variant="outlined"
                    density="compact"
                    hide-details="auto"
                    :items="classes"
                    item-text="label"
                    item-value="value"
                    background-color="#ffffff"
                    class="font-weight-medium"
                    data-testid="class-select"
                    :rules="[
                      parentValidatorsService.representativeSchoolClassErrorInChild(
                        'クラス',
                        child
                      ),
                    ]"
                    @change="(v) => onChangeSchoolClassId(child, v)"
                  />
                </v-col>
                <v-col cols="3">
                  <div class="pb-2">
                    <span class="font-weight-bold">子どもコード</span>
                  </div>
                  <TextInput
                    :value="child.code"
                    :on-change="
                      (value: string) => {
                        child.code = value
                        resetErrors()
                      }
                    "
                    placeholder="Code-01"
                    background-color="#ffffff"
                    class="font-weight-medium fs-14 mr-6"
                    data-testid="child-code"
                    :rules="[
                      parentValidatorsService.representativeInvalidCode('子どもコード'),
                      parentValidatorsService.representativeDuplicatedCodeErrorInChild(
                        '子どもコード',
                        child,
                        [parent]
                      ),
                      parentValidatorsService.isTooLong('子どもコード', 255),
                    ]"
                  />
                </v-col>
                <v-col cols="1" class="text-center remove-button-container">
                  <v-tooltip v-if="!child.canBeDeletable && child.id" location="top">
                    <template #activator="{ props }">
                      <button
                        class="remove-button disabled"
                        type="button"
                        v-bind="props"
                        data-testid="child-delete-button"
                      >
                        <v-icon class="pr-1">mdi-delete</v-icon>
                        <span class="font-weight-bold text-grey">削除</span>
                      </button>
                    </template>

                    <span v-if="useCodmon"
                      >保護者のいずれかの子どもに未払い・保存中の請求がある、またはコドモンに連携している子どもがいるため、削除できません。</span
                    >
                    <span v-else
                      >保護者のいずれかの子どもに未払い・保存中の請求があるため、削除ができません。</span
                    >
                  </v-tooltip>
                  <div v-else>
                    <button
                      v-if="childrenInDb.length === 1 && child.id"
                      class="remove-button"
                      type="button"
                      data-testid="child-delete-button"
                      @click="$emit('deleteParentAndChild')"
                    >
                      <v-icon class="pr-1">mdi-delete</v-icon>
                      <span class="font-weight-bold text-grey">削除</span>
                    </button>
                    <button
                      v-else
                      class="remove-button"
                      type="button"
                      data-testid="child-delete-button"
                      @click="$emit('removeChild', i)"
                    >
                      <v-icon class="pr-1">mdi-delete</v-icon>
                      <span class="font-weight-bold text-grey">削除</span>
                    </button>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="child.id" dense>
                <v-col>
                  <template v-if="useCodmon">
                    <template v-if="child.codmonId">
                      <div class="icon">
                        <S3Image path="/logos/codmon-logo.png" class="codmon-icon mr-2" />
                      </div>
                      コドモン連携中 [<a @click="$emit('disconnectCodmon', parent, child)"
                        >連携解除する</a
                      >]</template
                    >
                    <template v-else>
                      コドモン未連携 [<a @click="$emit('connectCodmon', parent, child)">連携する</a
                      >]</template
                    >
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </fieldset>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="1" class="d-flex align-center" style="min-width: 120px; height: 48px"> </v-col>
        <v-col>
          <template v-if="parent.canAddChildren">
            <Button
              variant="text"
              style="padding-left: 0; padding-right: 0"
              @click="$emit('addChild')"
            >
              <v-icon class="pr-1">mdi-plus-circle-outline</v-icon>
              <span class="font-weight-bold fs-16">子どもを追加する</span>
            </Button>
          </template>
          <template v-else>
            <v-tooltip location="top">
              <template #activator="{ props }">
                <div class="d-inline-block" v-bind="props">
                  <Button variant="text" style="padding-left: 0; padding-right: 0" :disabled="true">
                    <v-icon class="pr-1">mdi-plus-circle-outline</v-icon>
                    <span class="font-weight-bold fs-16">子どもを追加する</span>
                  </Button>
                </div>
              </template>
              <span>保護者に対する請求が作られているため、子どもを作成できません。</span>
            </v-tooltip>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script lang="ts">
import { computed, ComputedRef, defineComponent } from 'vue'
import Button from 'shared/components/Button.vue'
import TextInput from 'shared/components/TextInput.vue'
import { EditableChild, EditableParent } from 'pages/parents_page/model'
import { SelectOption } from 'pages/account_settings_page/models'
import { SchoolClass } from 'http/modules/class'
import SelectInput from 'shared/components/SelectInput.vue'
import ContainedLabel from 'shared/components/label/ContainedLabel.vue'
import { ParentValidatorsService } from 'pages/parents_page/services/parent_validators_service'
import { ParentsEditPageStore } from 'pages/parents_page/parent_edit/parent_edit_page_store'
import { ParentEditService } from 'pages/parents_page/parent_edit/services/parent_edit_service'
import S3Image from 'shared/components/s_3_image/S3Image.vue'

export default defineComponent({
  components: { S3Image, ContainedLabel, SelectInput, TextInput, Button },
  props: {
    useCodmon: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'addChild',
    'removeChild',
    'deleteParentAndChild',
    'disconnectCodmon',
    'connectCodmon',
    'disconnectLine',
  ],
  setup() {
    const parent = computed<EditableParent>(
      () =>
        ParentsEditPageStore.parent ?? {
          temporaryId: -1,
          firstName: '',
          lastName: '',
          code: '',
          schoolParentChildren: [],
        }
    )
    const classes: ComputedRef<SelectOption[]> = computed(() =>
      ParentsEditPageStore.schoolClasses.map<SelectOption>((schoolClass: SchoolClass) => ({
        label: schoolClass.name,
        value: `${schoolClass.id}`,
      }))
    )
    // [子どもを追加(DBに無い) -> (DB上にある)最後の1人を削除 -> 保存せずページ更新 -> 保護者だけ残る]を防ぐためにDB上の子どもの数をカウントする
    const childrenInDb = computed(() =>
      parent.value.schoolParentChildren.filter((child) => child.id)
    )
    const onChangeSchoolClassId = (child: EditableChild, v: string) => {
      child.schoolClassId = v
    }
    const resetErrors = () => {
      ParentEditService.setErrors([])
    }

    const isUpdatableParentLastNameAndFirstName = computed(
      () => !parent.value?.schoolParentChildren.some((child) => child.codmonId)
    )
    const isUpdatableChildLastNameAndFirstName = computed(
      () =>
        (child: EditableChild): boolean =>
          !child.codmonId
    )

    const parentValidatorsService = computed(() => ParentValidatorsService)

    return {
      parent,
      classes,
      childrenInDb,
      parentValidatorsService,
      onChangeSchoolClassId,
      resetErrors,
      isUpdatableParentLastNameAndFirstName,
      isUpdatableChildLastNameAndFirstName,
    }
  },
})
</script>
<style lang="scss" scoped>
.icon {
  width: 23px;
  display: inline-block;
  vertical-align: middle;
}
.thin-gray {
  background-color: #f6f6f5;
}
.required {
  font-size: 12px;
  padding: 0 4px;
  display: inline-block;
}
.remove-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
    .grey--text,
    .v-icon {
      color: #b8b8b8 !important;
    }
  }
}

.remove-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
