<template>
  <FacilityRegistrationLayout
    page-title="詳細情報を入力してください"
    :step="2"
    :error-message="errorMessage"
  >
    <div class="panel panel-normal">
      <div class="panel_inner">
        <form id="edit_school" :action="action" accept-charset="UTF-8" method="post">
          <input type="hidden" name="_method" value="patch" autocomplete="off" />
          <input
            type="hidden"
            name="authenticity_token"
            :value="authenticityToken"
            autocomplete="off"
          />
          <div id="addresses" class="sign-Form">
            <div class="sign-Form_item">
              <div class="sign-Form_heading">
                <label for="school_postal_code">郵便番号（ハイフン無し）</label>
              </div>
              <div class="sign-Form_input">
                <input
                  id="school_postal_code"
                  v-model="postalCode"
                  required
                  pattern="[0-9]{7}"
                  placeholder="例）1234567"
                  type="text"
                  name="school[postal_code]"
                  class="form-Item_inputTextSm"
                /><button
                  id="show-modal"
                  type="button"
                  class="button-zipcode"
                  @click="searchAddress"
                >
                  郵便番号で住所検索
                </button>
              </div>
            </div>
            <div class="sign-Form_item">
              <div class="sign-Form_heading"><label for="school_prefecture">都道府県</label></div>
              <div class="sign-Form_input">
                <div class="form-Item_selectWrapper">
                  <select
                    id="school_prefecture_id"
                    v-model="prefectureId"
                    name="school[prefecture_id]"
                    class="form-Item_select"
                    required
                  >
                    <template v-for="prefecture in props.prefectures" :key="prefecture.id">
                      <option :value="prefecture.id">{{ prefecture.name }}</option>
                    </template>
                  </select>
                </div>
              </div>
            </div>
            <div class="sign-Form_item">
              <div class="sign-Form_heading"><label for="school_city">市区町村・番地</label></div>
              <div class="sign-Form_input">
                <input
                  id="school_city"
                  v-model="city"
                  required
                  placeholder="市区町村・番地名を入力してください"
                  type="text"
                  name="school[city]"
                  class="form-Item_inputText"
                />
              </div>
            </div>
            <div class="sign-Form_item">
              <div class="sign-Form_heading">
                <label for="school_building">建物名【任意】</label>
              </div>
              <div class="sign-Form_input">
                <input
                  id="school_building"
                  v-model="building"
                  placeholder="建物名を入力してください"
                  type="text"
                  name="school[building]"
                  class="form-Item_inputText"
                />
              </div>
            </div>
            <div class="sign-Form_item">
              <div class="sign-Form_heading">
                <label for="school_responsible_person_name">責任者名</label>
              </div>
              <div class="sign-Form_input">
                <input
                  id="school_responsible_person_name"
                  v-model="responsiblePersonName"
                  required
                  placeholder="施設の責任者の名前を入力してください"
                  type="text"
                  name="school[responsible_person_name]"
                  class="form-Item_inputText"
                />
              </div>
            </div>
            <hr class="sign-Form_hr" />
            <div class="sign-Form_item">
              <div class="sign-Form_heading"><label for="school_bank_name">銀行名</label></div>
              <div class="sign-Form_input">
                <input
                  id="school_bank_name"
                  v-model="bankName"
                  required
                  placeholder="例）みずほ銀行"
                  type="text"
                  name="school[bank_name]"
                  class="form-Item_inputText"
                />
              </div>
            </div>
            <div class="sign-Form_item">
              <div class="sign-Form_heading"><label for="school_bank_code">銀行コード</label></div>
              <div class="sign-Form_input">
                <input
                  id="school_bank_code"
                  v-model="bankCode"
                  required
                  pattern="[0-9]{4}"
                  placeholder="例）1234"
                  type="text"
                  name="school[bank_code]"
                  class="form-Item_inputText"
                />
              </div>
            </div>
            <div class="sign-Form_item">
              <div class="sign-Form_heading">
                <label for="school_bank_branch_name">支店名</label>
              </div>
              <div class="sign-Form_input">
                <input
                  id="school_bank_branch_name"
                  v-model="bankBranchName"
                  required
                  placeholder="例）新宿支店"
                  type="text"
                  name="school[bank_branch_name]"
                  class="form-Item_inputText"
                />
              </div>
            </div>
            <div class="sign-Form_item">
              <div class="sign-Form_heading">
                <label for="school_bank_branch_code">支店コード</label>
              </div>
              <div class="sign-Form_input">
                <input
                  id="school_bank_branch_code"
                  v-model="bankBranchCode"
                  required
                  pattern="[0-9]{3}"
                  placeholder="例）123"
                  type="text"
                  name="school[bank_branch_code]"
                  class="form-Item_inputText"
                />
              </div>
            </div>
            <div class="sign-Form_item">
              <div class="sign-Form_heading">
                <label for="school_bank_branch_type">預金種別</label>
              </div>
              <div class="form-Item_selectWrapper">
                <select
                  id="school_bank_branch_type"
                  v-model="bankBranchType"
                  name="school[bank_branch_type]"
                  class="form-Item_select"
                >
                  <option value="普通預金">普通預金</option>
                  <option value="当座預金">当座預金</option>
                </select>
              </div>
            </div>
            <div class="sign-Form_item">
              <div class="sign-Form_heading">
                <label for="school_bank_account_number">口座番号</label>
              </div>
              <div class="sign-Form_input">
                <input
                  id="school_bank_account_number"
                  v-model="bankAccountNumber"
                  required
                  pattern="[0-9]{7}"
                  placeholder="例）1234567"
                  type="text"
                  name="school[bank_account_number]"
                  class="form-Item_inputText"
                />
              </div>
            </div>
            <div class="sign-Form_item">
              <div class="sign-Form_heading">
                <label for="school_bank_account_holder_name">銀行口座の名義</label>
              </div>
              <div class="sign-Form_input">
                <input
                  id="school_bank_account_holder_name"
                  v-model="bankAccountHolderName"
                  required
                  placeholder="例）エンペイ タロウ"
                  type="text"
                  name="school[bank_account_holder_name]"
                  class="form-Item_inputText"
                />
              </div>
            </div>
          </div>
          <p class="sign-Form_buttonArea">
            <button type="submit" class="button-md button-normal">確認する</button>
          </p>
        </form>
      </div>
    </div>
  </FacilityRegistrationLayout>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import AddressApi, { Address } from '../../http/modules/address'
import { RouteService } from 'shared/services/route_service'
import { AxiosError } from 'axios'
import FacilityRegistrationLayout from 'shared/templates/FacilityRegistrationLayout.vue'

const props = defineProps<{
  authenticityToken: string
  prefectures: { id: number; name: string }[]
  facility: {
    postal_code: string
    prefecture_id: string
    city: string
    building: string
    responsible_person_name: string
    bank_name: string
    bank_code: string
    bank_branch_name: string
    bank_branch_code: string
    bank_branch_type: string
    bank_account_number: string
    bank_account_holder_name: string
  }
  errorMessage?: string | string[]
}>()

const address = ref<{
  city: string
  town?: string
}>({
  city: '',
  town: undefined,
})
const addresses = ref<Address[]>([])
const postalCode = ref<string>(props.facility.postal_code)
const prefectureId = ref<string | undefined>(props.facility.prefecture_id)
const city = ref<string>(props.facility.city)
const building = ref<string>(props.facility.building)
const responsiblePersonName = ref<string>(props.facility.responsible_person_name)
const bankName = ref<string>(props.facility.bank_name)
const bankCode = ref<string>(props.facility.bank_code)
const bankBranchName = ref<string>(props.facility.bank_branch_name)
const bankBranchCode = ref<string>(props.facility.bank_branch_code)
const bankBranchType = ref<string>(props.facility.bank_branch_type)
const bankAccountNumber = ref<string>(props.facility.bank_account_number)
const bankAccountHolderName = ref<string>(props.facility.bank_account_holder_name)

const targetFacilityId = Number(RouteService.getPathParamByIndex(1))
const action = computed(() => `/facilities/${targetFacilityId}/registrations`)

const searchAddress = async () => {
  if (!postalCode.value) {
    return
  }
  try {
    const res = await AddressApi.search(postalCode.value)
    addresses.value = []
    city.value = ''
    if (res.data.addresses.length > 0) {
      const addresses = res.data.addresses
      // 検索結果が複数の場合は先頭の情報を使用する
      address.value = addresses[0]
      city.value = `${address.value.city}${address.value.town ? address.value.town : ''}`
      prefectureId.value = String(addresses[0].prefecture.id)
    }
  } catch (error) {
    const axiosError = error as AxiosError
    if (axiosError.response) {
      if (axiosError.response.status === 401) {
        window.location.href = '/'
      } else {
        console.error(axiosError.response.data || axiosError.response)
      }
    } else {
      console.error(error)
    }
  }
}
</script>
