<template>
  <div class="form-wrapper pt-6 pl-10 pb-6">
    <v-row>
      <v-col cols="9">
        <div v-for="(input, index) in itemInputs" :key="index">
          <InvoiceItemInput
            :index="index"
            :name="input.name"
            :count="input.count"
            :unit-price="input.unit_price"
            :is-codmon-item="input.codmon_invoice"
            :is-invoice-locked="isInvoiceLocked"
            :auto-complete-items="autoCompleteItems"
            @input="updateInputs"
          ></InvoiceItemInput>
        </div>
        <div class="d-flex justify-start mt-2">
          <Button
            variant="plain"
            data-testid="item-form-row-add-button"
            :disabled="isInvoiceLocked"
            @click="addItemInputField"
          >
            <v-icon>mdi-plus-circle-outline</v-icon>
            項目を追加する
          </Button>
        </div>
      </v-col>
      <v-col cols="3" class="total-amount-area d-flex flex-column">
        <div class="my-6">合計</div>
        <div class="d-flex justify-center mb-6">
          <AmountLabel data-testid="item-form-child-total" :amount="totalAmount" :size="'L'" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { computed, defineComponent } from 'vue'
import AmountLabel from '../../../shared/components/AmountLabel.vue'
import InvoiceItemInput from './InvoiceItemInput.vue'
import { TopPageStore } from '../top_page_store'
import { InvoiceItemInputService } from '../services/invoice_item_input_service'
import Button from 'shared/components/Button.vue'

export default defineComponent({
  components: {
    InvoiceItemInput,
    AmountLabel,
    Button,
  },
  props: {
    parentId: {
      type: Number,
    },
    childId: {
      type: Number,
      required: false,
    },
    isInvoiceLocked: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // computed
    const itemInputs = computed(() =>
      InvoiceItemInputService.getItemInputs(props.parentId, props.childId)
    )
    const totalAmount = computed(() =>
      InvoiceItemInputService.calculateInputTotalAmountOfChild(props.parentId, props.childId)
    )
    const autoCompleteItems = computed(() => TopPageStore.invoiceItemNameCandidates)

    // methods
    const updateInputs = (index, input) =>
      InvoiceItemInputService.updateItemInputs(props.parentId, props.childId, input, index)
    const addItemInputField = () =>
      InvoiceItemInputService.addItemInputField(props.parentId, props.childId)

    return {
      itemInputs,
      totalAmount,
      autoCompleteItems,
      updateInputs,
      addItemInputField,
    }
  },
})
</script>

<style scoped lang="scss">
@import '../../../../assets/stylesheets/design/variables';

.total-amount-area {
  border-left: 1px solid #707070;
}
.form-wrapper {
  background-color: $color-bg;
}
</style>
