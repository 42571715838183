<template>
  <transition name="modal">
    <div v-if="isShowModal" class="modal active" style="display: flex" @click.self="closeModal()">
      <div class="modal_inner">
        <p class="modal_title">
          <slot name="title" />
        </p>
        <div :class="panelWidthClass" class="panel">
          <div class="panel_inner">
            <slot name="body" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
defineProps<{
  panelWidthClass: string
  isShowModal: boolean
}>()

const emit = defineEmits<{
  (event: 'close'): void
}>()

const closeModal = () => {
  emit('close')
}
</script>
