// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'vuetify/dist/vuetify.min.css'
import { createApp, App } from 'vue'
import { createVuetify } from 'vuetify'
import '@mdi/font/css/materialdesignicons.css'
import { EventTracker } from 'shared/utils/_event_tracking'
import { VTooltip } from 'v-tooltip'
import { vuetifyOptions } from '../shared/consts/vuetify_const'
import { datadogRum } from '@datadog/browser-rum'
import { ConfigApi } from 'http/modules/config'
import { RouteService } from 'shared/services/route_service'

const isAdmin = RouteService.getPathParamByIndex(0) === 'admin'

if (!isAdmin) {
  ConfigApi.env().then((response) => {
    if (!['test', 'localhost'].includes(response.data.ENV)) {
      datadogRum.init({
        applicationId: response.data.DATADOG_APP_ID,
        clientToken: response.data.DATADOG_CLIENT_TOKEN,
        allowedTracingUrls: [
          /^https?:\/\/[^/]*(app(-staging|-qa|-develop)?|copy|hotfix)\.enpay\.co\.jp/,
        ],
        site: 'datadoghq.com',
        service: 'en_payment',
        sessionReplaySampleRate: 100,
        env: response.data.ENV,
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: false, // 収集されるアクションに個人情報が含まれるためfalseにする
      })
      datadogRum.startSessionReplayRecording()
    }
  })
}

const vuetify = createVuetify(vuetifyOptions)

let currentApps: App[] = []

const pages: Record<string, any> = {}
const requireContext = require.context('../pages', true, /.*index\.ts$/)
requireContext.keys().forEach((key) => {
  const name = key.replace(/^pages\/(.+)\/index.ts$/, (_, packageName) => packageName)
  pages[name] = requireContext(key).default
})

document.addEventListener('turbolinks:load', () => {
  const templates = document.querySelectorAll<HTMLElement>('[data-vue]')

  for (const el of templates) {
    if (el.dataset.vue && el.parentNode) {
      const page = pages[el.dataset.vue]
      const mixins = page.mixins ? page.mixins.slice() : []
      if (el.dataset.vueData) {
        const data = JSON.parse(el.dataset.vueData)
        mixins.push({ data })
      }
      if (!page.methods) {
        page.methods = {}
      }

      const app = createApp(page)
      app.directive('tooltip', VTooltip)
      app.config.globalProperties.$eventTracker = EventTracker
      app.mixin(mixins)
      app.use(vuetify)

      const appRoot = document.createElement('div')
      appRoot.setAttribute('id', 'app')
      el.parentNode.insertBefore(appRoot, el)
      appRoot.appendChild(el)
      app.mount(appRoot)

      for (const currentApp of currentApps) {
        currentApp.unmount()
      }
      currentApps = []
      currentApps.push(app)
    }
  }
})
