import { isAxiosError } from 'http/modules/invoice'
import { InvoiceRegisterColumnCandidatesApi } from 'http/modules/invoice_register_column_candidates'
import { InvoiceRegisterTemplateApi } from 'http/modules/invoice_register_template'
import { RouteService } from 'shared/services/route_service'
import { SnackbarService } from 'shared/services/snackbar_service'
import { ImportInvoicesPageStore } from '../import_invoices_page_store'
import { ScreenLoadingService } from 'shared/services/screen_loading_service'
export class ImportInvoiceService {
  static async loadColumnCandidates(): Promise<void> {
    ImportInvoicesPageStore.updateColumnCandidates(
      (await InvoiceRegisterColumnCandidatesApi.show(ImportInvoiceService.getTargetFacilityId()))
        .data
    )
  }

  static getTargetFacilityId(): number {
    return Number(RouteService.getPathParamByIndex(1))
  }

  static generateTemplateDownloadUrl(): string {
    const targetYearMonth = RouteService.getQueryParamByKey('target_year_month') ?? ''
    return `/facilities/${ImportInvoiceService.getTargetFacilityId()}/invoice_register_template.csv?target_year_and_month=${targetYearMonth}`
  }

  static generateExcelTemplateDownloadUrl(): string {
    const targetYearMonth = RouteService.getQueryParamByKey('target_year_month') ?? ''
    return `/facilities/${ImportInvoiceService.getTargetFacilityId()}/invoice_register_template.xlsx?target_year_and_month=${targetYearMonth}`
  }

  static storeTargetYearAndMonth(): void {
    const targetYearMonth = RouteService.getQueryParamByKey('target_year_month') ?? ''
    if (targetYearMonth === '') return

    // yyyy-MMを'-'でsplitする
    const targetYearMonthArray = targetYearMonth.split('-')
    ImportInvoicesPageStore.updateTargetYear(targetYearMonthArray[0])
    ImportInvoicesPageStore.updateTargetMonth(targetYearMonthArray[1])
  }

  static async downloadTemplate(fileType: 'csv' | 'excel') {
    try {
      await InvoiceRegisterTemplateApi.download(fileType)
      if (fileType === 'csv') {
        location.href = '/api/v1' + ImportInvoiceService.generateTemplateDownloadUrl()
      } else {
        location.href = '/api/v1' + ImportInvoiceService.generateExcelTemplateDownloadUrl()
      }
    } catch (error) {
      if (isAxiosError(error) && error.response !== undefined) {
        const message =
          error.response.status === 403
            ? '請求が締められているため、請求内容の編集はできません。'
            : 'テンプレートファイルのダウンロードに失敗しました。'
        SnackbarService.open(message, 'error')
      }
    } finally {
      ScreenLoadingService.hideLoader()
    }
  }
}
