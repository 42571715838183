<template>
  <div class="d-flex align-center pa-2" style="border: 1px solid #db722f; border-radius: 4px">
    <v-icon class="mr-2" color="#DB722F">mdi-information</v-icon>
    <span class="font-weight-medium" style="color: #fb8c00; font-size: 14px">{{ text }}</span>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    text: {
      type: String,
      default: '',
    },
  },
})
</script>
