<template>
  <div>
    <div class="font-weight-bold black-font fs-16 mb-4">
      <template v-if="targetParentsCount > 0">
        保護者 <span class="fs-18">{{ targetParentsCount }}</span
        >名
      </template>
      <template v-if="targetParentsCount > 0 && targetChildrenCount > 0">、</template>
      <template v-if="targetChildrenCount > 0">
        子ども <span class="fs-18">{{ targetChildrenCount }}</span
        >名
      </template>
      を削除します
    </div>
    <p class="text-body-2 mb-4">
      削除した保護者・子どもは、当月（{{
        thisMonth
      }}月）から請求画面に表示されなくなります。<br />この操作は取り消せません。ご注意ください。
    </p>
    <InfoAlert text="当月以降お支払い済みの請求がある場合は、該当月に表示されます。" />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { getMonth } from 'shared/utils/date'
import InfoAlert from 'shared/components/alert/InfoAlert.vue'

export default defineComponent({
  components: { InfoAlert },
  props: {
    targetParentsCount: {
      type: Number,
      required: true,
    },
    targetChildrenCount: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const thisMonth = getMonth(new Date())

    return { thisMonth }
  },
})
</script>
<style scoped lang="scss">
.black-font {
  color: #333;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
</style>
