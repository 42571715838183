import TopPage from 'pages/top_page/TopPage.vue'
import { LaunchDarklyService } from 'shared/services/launch_darkly_service'
import { TopPageStore } from 'pages/top_page/top_page_store'
import { SchoolService } from 'pages/top_page/services/school_service'

export default {
  components: {
    TopPage,
  },
  created: function () {
    LaunchDarklyService.getFlag('new_top_page_format', SchoolService.getTargetFacilityId()).then(
      (flag) => TopPageStore.updateIsShowNewTopPageFormat(flag)
    )
  },
}
