import { AxiosError, AxiosResponse } from 'axios'
import { HTTP } from 'http/common'
import {
  DeleteChildResponse,
  DeleteParentResponse,
  Child,
  Parent,
  QueryParams,
  BulkDeleteResponse,
  InputConfirmationError,
  RequestParams,
} from 'pages/parents_page/model'

export type IndexResponse = Parent[]
type IndexParams = {
  facilityId: number
  queryParams: QueryParams
}
type DeleteParentParams = {
  facilityId: number
  parentId: Parent['id']
}
type DeleteChildParams = DeleteParentParams & {
  childId: number
}
type BulkDeleteParams = {
  facilityId: number
  queryParams: Omit<QueryParams, 'page' | 'per_page'>
}

export type CreateChildParams = {
  first_name: string
  code: string
  school_class_id?: number
}

export type CreateParams = {
  first_name: string
  code: string
  last_name: string
  school_parent_children: CreateChildParams[]
}

export type ShowParams = {
  facilityId: number
  parentId: number
}

export type CreateResponse = Parent & {
  status: number
  errors: string[]
  school_parent_children: (Child & {
    errors: string[]
  })[]
}

export type ShowResponse = Parent & {
  status: number
  errors: string[]
  school_parent_children: (Child & {
    errors: string[]
  })[]
}

export type UpdateResponse = Parent & {
  status: number
  errors: string[]
  school_parent_children: (Child & {
    errors: string[]
  })[]
}

export type DeleteResponse = Parent & {
  status: number
  errors: string[]
  school_parent_children: (Child & {
    errors: string[]
  })[]
}

type InputConfirmationResponse = InputConfirmationError

export class FacilityParentApi {
  static index({
    facilityId,
    queryParams = {},
  }: IndexParams): Promise<AxiosResponse<IndexResponse>> {
    const query = new URLSearchParams(queryParams)
    return HTTP.get(
      `/facilities/${facilityId}/parents${
        query.toString().length > 0 ? `?${query.toString()}` : ''
      }`
    )
  }

  static show({ facilityId, parentId }: ShowParams): Promise<AxiosResponse<ShowResponse>> {
    return HTTP.get(`/facilities/${facilityId}/parents/${parentId}`)
  }

  static create(facilityId: number, body: CreateParams): Promise<AxiosResponse<CreateResponse>> {
    return HTTP.post(`/facilities/${facilityId}/parents`, body)
  }

  static update(
    facilityId: number,
    id: number,
    body: CreateParams
  ): Promise<AxiosResponse<UpdateResponse>> {
    return HTTP.patch(`/facilities/${facilityId}/parents/${id}`, body)
  }

  static deleteParent({
    facilityId,
    parentId,
  }: DeleteParentParams): Promise<AxiosResponse<DeleteParentResponse>> {
    return HTTP.delete(`facilities/${facilityId}/parents/${parentId}`)
  }

  static deleteChild({
    facilityId,
    parentId,
    childId,
  }: DeleteChildParams): Promise<AxiosResponse<DeleteChildResponse>> {
    return HTTP.delete(`facilities/${facilityId}/parents/${parentId}/children/${childId}`)
  }

  static bulkDelete({
    facilityId,
    queryParams = {},
  }: BulkDeleteParams): Promise<AxiosResponse<BulkDeleteResponse>> {
    const query = new URLSearchParams(queryParams)
    return HTTP.delete(
      `/facilities/${facilityId}/parents${
        query.toString().length > 0 ? `?${query.toString()}` : ''
      }`
    )
  }

  static inputConfirmation(
    facilityId: number,
    body: RequestParams[]
  ): Promise<AxiosResponse<InputConfirmationResponse>> {
    return HTTP.post(`/facilities/${facilityId}/parents/input_confirmation`, { parents: body })
  }
}

export const isAxiosError = (error: unknown): error is AxiosError =>
  !!(error as AxiosError).isAxiosError

export const isConfirmationError = (
  error: unknown
): error is AxiosError<InputConfirmationResponse> => {
  const errorResponse = error as AxiosError<InputConfirmationResponse>

  return (
    isAxiosError(errorResponse) &&
    !!errorResponse.response &&
    errorResponse.response.status === 422 &&
    !!(errorResponse.response.data as InputConfirmationError)?.errors &&
    (errorResponse.response.data as InputConfirmationError)?.errors.error.length > 0
  )
}

export const isConfirmationWarning = (
  error: unknown
): error is AxiosError<InputConfirmationResponse> => {
  const errorResponse = error as AxiosError<InputConfirmationResponse>

  return (
    isAxiosError(errorResponse) &&
    !!errorResponse.response &&
    errorResponse.response.status === 409 &&
    !!(errorResponse.response.data as InputConfirmationError)?.errors &&
    (errorResponse.response.data as InputConfirmationError)?.errors.warn.length > 0
  )
}
