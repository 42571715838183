<template>
  <div class="d-flex flex-column">
    <!-- コドモンボタン -->
    <v-tooltip location="right" :disabled="!isLockedMonth">
      <template #activator="{ props }">
        <div v-bind="props">
          <Button
            v-if="connectedWithCodmon"
            class="mb-2 action-button"
            width="100%"
            variant="outlined"
            :disabled="isLockedMonth"
            @click="openCodmonInvoiceConfirmDialog()"
          >
            <S3Image :path="codmonLogoPath" class="codmon-icon mr-2" />
            コドモン請求データ連携
          </Button>
        </div>
      </template>
      <span>請求が締められているため、コドモン連携機能は利用できません</span>
    </v-tooltip>
    <!-- 未請求データ一括削除ボタン -->
    <Button
      class="mb-2 action-button"
      variant="outlined"
      :disabled="!existBeforeChargeInvoice"
      data-testid="batch-delete-button"
      @click="openBeforeChargeDeleteConfirmDialog()"
    >
      <v-icon start> mdi-delete </v-icon>
      未請求データ一括削除
    </Button>
    <v-tooltip location="right" :disabled="!isLockedMonth">
      <template #activator="{ props }">
        <div v-bind="props">
          <InvoiceImportButton :is-disabled="isLockedMonth" class="action-button" width="100%" />
        </div>
      </template>
      <span>請求が締められているため、請求内容の編集はできません</span>
    </v-tooltip>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent } from 'vue'
import { CodmonService } from '../services/codmon_service'
import { TopPageStore } from '../top_page_store'
import { InvoiceService } from '../services/invoice_service'
import S3Image from 'shared/components/s_3_image/S3Image.vue'
import InvoiceImportButton from './InvoiceImportButton.vue'
import Button from 'shared/components/Button.vue'

export default defineComponent({
  components: {
    S3Image,
    InvoiceImportButton,
    Button,
  },
  setup() {
    // computed
    const isLockedMonth = computed(() => TopPageStore.isLockedMonth)
    const connectedWithCodmon = computed(() => TopPageStore.school.codmon_id !== undefined)
    const codmonLogoPath = computed(() => {
      if (TopPageStore.isLockedMonth) {
        return '/logos/codmon-logo--grayout.png'
      }
      return '/logos/codmon-logo.png'
    })
    const existBeforeChargeInvoice = computed(() =>
      TopPageStore.schoolParents.some(
        (parent) =>
          InvoiceService.existNeverSavedInvoice(parent.id) ||
          parent.invoices[0]?.status === 'before_charge'
      )
    )

    // methods
    const openCodmonInvoiceConfirmDialog = () => CodmonService.openCodmonInvoiceConfirmDialog()
    const openBeforeChargeDeleteConfirmDialog = () =>
      InvoiceService.openBeforeChargeDeleteConfirmDialog()

    return {
      isLockedMonth,
      connectedWithCodmon,
      codmonLogoPath,
      existBeforeChargeInvoice,
      openCodmonInvoiceConfirmDialog,
      openBeforeChargeDeleteConfirmDialog,
    }
  },
})
</script>

<style scoped lang="scss">
.action-button {
  background-color: white;
}
.codmon-icon {
  width: 1.6rem;
}
</style>
