<template>
  <span>
    <v-dialog v-model="isShowDialog" width="770">
      <template #activator="{ props }">
        <span
          v-bind="props"
          role="button"
          class="text-body-1 text-decoration-underline text-primary my-4"
        >
          記入例
        </span>
      </template>
      <v-card>
        <slot name="sampleEntry" />
        <v-card-actions class="justify-center">
          <Button
            class="my-6"
            color="grey-darken-2"
            size="x-large"
            variant="outlined"
            width="180"
            @click="closeDialog()"
          >
            閉じる
          </Button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import Button from 'shared/components/Button.vue'

const isShowDialog = ref(false)
const closeDialog = () => (isShowDialog.value = false)
</script>
