<template>
  <SimpleLayout title="保護者・子どもを編集" is-expanded class="container">
    <template #headerNav>
      <FacilityHeader />
    </template>
    <template #headerControl>
      <HeaderControl title="保護者一覧に戻る" :path="backToUrl" />
    </template>
    <template #headerContent>
      <div class="d-flex">
        <div>
          <v-icon color="primary">mdi-help-circle-outline</v-icon>
          <LinkText :link-url="faqPageUrl" is-open-new-tab :has-underline="false">
            よくある質問を確認する
          </LinkText>
        </div>
      </div>
    </template>

    <template #main>
      <Dialog />
      <v-form ref="parentEditForm" style="width: 100%">
        <ParentEditableField
          data-testid="parent-editable-field"
          :use-codmon="useCodmon"
          @add-child="addChild"
          @connect-codmon="(parent, child) => connectCodmon(parent, child)"
          @remove-child="(childrenIndex) => removeChild(childrenIndex)"
          @delete-parent-and-child="deleteParentAndChild"
          @disconnect-line="(parent) => disconnectLine(parent)"
          @disconnect-codmon="(parent, child) => disconnectCodmon(parent, child)"
        />
        <div class="d-flex justify-space-between" style="margin-top: 32px">
          <template v-if="!parent.canBeDeletable">
            <v-tooltip location="top">
              <template #activator="{ props }">
                <div v-bind="props">
                  <Button
                    size="large"
                    variant="outlined"
                    border
                    style="min-width: 15%"
                    :disabled="true"
                  >
                    <v-icon class="pr-1">mdi-delete</v-icon>
                    <span class="font-weight-bold fs-16">保護者を削除する</span>
                  </Button>
                </div>
              </template>
              <span v-if="useCodmon"
                >未払い・保存中の請求がある、またはコドモンに連携している子どもがいるため、削除できません。</span
              >
              <span v-else>未払い・保存中の請求があるため、削除できません。</span>
            </v-tooltip>
          </template>
          <template v-else>
            <Button
              size="large"
              variant="outlined"
              color="#F14E54"
              style="min-width: 15%; border: 1px solid #f14e54 !important; background-color: white"
              @click="() => deleteParent()"
            >
              <v-icon class="pr-1">mdi-delete</v-icon>
              <span class="font-weight-bold fs-16">保護者を削除する</span>
            </Button>
          </template>

          <Button size="large" style="min-width: 30%" @click="() => modifyParent()">
            <span class="font-weight-bold fs-16">保存する</span>
          </Button>
        </div>
      </v-form>
    </template>
  </SimpleLayout>
</template>
<script lang="ts">
/* eslint-disable max-lines */
import SimpleLayout from 'shared/templates/SimpleLayout.vue'
import FacilityHeader from 'shared/components/header/FacilityHeader.vue'
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue'
import Button from 'shared/components/Button.vue'
import { ParentsService } from 'pages/parents_page/services/parents_service'
import { EditableChild, EditableParent } from 'pages/parents_page/model'
import { DialogService } from 'shared/services/dialog_service'
import { ParentEditService } from 'pages/parents_page/parent_edit/services/parent_edit_service'
import { ParentsEditPageStore } from 'pages/parents_page/parent_edit/parent_edit_page_store'
import ParentEditableField from 'pages/parents_page/parent_edit/components/ParentEditableField.vue'
import Dialog from 'shared/components/Dialog.vue'
import { ClassManagementService } from 'pages/parents_page/parent_edit/services/class_management_service'
import HeaderControl from 'pages/parents_page/components/HeaderControl.vue'
import LinkText from 'shared/components/LinkText.vue'
import ConfirmationDeletingParent from 'pages/parents_page/parent_edit/components/ConfirmationDeletingParent.vue'
import ConfirmationDeletingChild from 'pages/parents_page/parent_edit/components/ConfirmationDeletingChild.vue'
import ConfirmationDeletingParentAndLastChild from 'pages/parents_page/parent_edit/components/ConfirmationDeletingParentAndLastChild.vue'
import ExternalLink from 'shared/consts/external_link'
import { ParentsPageStore } from 'pages/parents_page/parents_page_store'
import { SnackbarService } from 'shared/services/snackbar_service'
import { MetaService } from 'shared/services/meta_service'

export default defineComponent({
  components: {
    LinkText,
    HeaderControl,
    Dialog,
    ParentEditableField,
    Button,
    FacilityHeader,
    SimpleLayout,
  },
  props: {
    useCodmon: {
      type: Boolean,
      default: false,
    },
  },
  // eslint-disable-next-line max-lines-per-function
  setup() {
    MetaService.addTitle({ mode: 'facility', page: 'parentEdit' })
    ClassManagementService.loadClasses()
    ParentEditService.loadParent()

    const handler = (event: BeforeUnloadEvent) => {
      event.returnValue = '行った変更が保存されない可能性があります'
    }

    onUnmounted(() => {
      DialogService.close()
      ParentsEditPageStore.updateParent(null)
      window.removeEventListener('beforeunload', handler)
    })

    onMounted(() => {
      window.addEventListener('beforeunload', handler)
    })

    const facilityId = ParentsService.getTargetFacilityId()
    const parentId = ParentsService.getTargetParentId()

    const faqPageUrl = ExternalLink.openpageFaqForParentList

    const { class_id, parent_name, child_name, line_connected, page } = ParentsPageStore.searchQuery
    const backToUrl = `/facilities/${facilityId}/parents?class_id=${class_id || ''}&parent_name=${
      parent_name || ''
    }&child_name=${child_name || ''}&line_connected=${line_connected}&page=${page}`

    const parentEditForm = ref<HTMLFormElement | null>(null)
    const defaultChild: EditableChild = {
      temporaryId: -1,
      firstName: '',
      code: '',
      codmonId: null,
    }
    const isDisabled = computed(() => ParentsEditPageStore.isDisabled)

    const parent = computed<EditableParent>({
      get: () =>
        ParentsEditPageStore.parent ?? {
          temporaryId: -1,
          firstName: '',
          lastName: '',
          code: '',
          schoolParentChildren: [{ ...defaultChild }],
        },
      set: (value) => ParentsEditPageStore.updateParent(value),
    })

    const handleModifySuccess = () => {
      window.removeEventListener('beforeunload', handler)
      ParentsPageStore.updateIsModifySuccess(true)
      location.href = backToUrl
    }

    const modifyParent = async () => {
      await ParentEditService.startUpdateConfirmation({
        id: parentId,
        onSuccess: handleModifySuccess,
        onValidate: () => parentEditForm.value?.validate(),
      })
      parentEditForm.value?.validate()
    }

    const deleteParent = () => {
      DialogService.open({
        title: '削除の確認',
        body: {
          component: {
            name: ConfirmationDeletingParent,
            bind: { parent },
          },
        },
        modalWidth: 640,
        okButtonColor: 'error',
        onConfirm: async () => {
          await ParentEditService.deleteParent(facilityId, parentId)
          window.removeEventListener('beforeunload', handler)
          ParentsPageStore.updateIsModifySuccess(true)
          location.href = `${backToUrl}&deleted_parents_id=${parentId}`
        },
        isShowCancel: true,
        okButtonText: '削除する',
      })
    }

    const addChild = (_index: number) => {
      if (!parent.value) {
        return
      }
      parent.value.schoolParentChildren.push({
        ...defaultChild,
        temporaryId: ParentsEditPageStore.generateChildId(),
      })
    }

    const removeChild = (index: number) => {
      if (!parent.value) {
        return
      }

      const child = parent.value.schoolParentChildren[index]

      // ID がある場合はリクエストして削除
      if (child.id) {
        DialogService.open({
          title: '削除の確認',
          body: {
            component: {
              name: ConfirmationDeletingChild,
              bind: { parent, child },
            },
          },
          modalWidth: 640,
          okButtonColor: 'error',
          onConfirm: async () => {
            if (!child.id) {
              return
            }

            await ParentEditService.deleteChild(facilityId, child.id)
            parent.value.schoolParentChildren = parent.value.schoolParentChildren.filter(
              (_, arrayIndex) => arrayIndex !== index
            )
            ParentsEditPageStore.updateParent(parent.value)
            SnackbarService.open('削除しました。', 'success', {
              variant: 'flat',
              showIcon: true,
              location: 'bottom right',
            })
          },
          isShowCancel: true,
          okButtonText: '削除する',
        })
      } else {
        // ない場合はそのまま削除
        parent.value.schoolParentChildren = parent.value.schoolParentChildren.filter(
          (_, arrayIndex) => arrayIndex !== index
        )
        ParentsEditPageStore.updateParent(parent.value)
      }
    }

    const deleteParentAndChild = () => {
      if (!parent.value) {
        return
      }

      const child = parent.value.schoolParentChildren[0]

      DialogService.open({
        title: '削除の確認',
        body: {
          component: {
            name: ConfirmationDeletingParentAndLastChild,
            bind: { parent, child },
          },
        },
        modalWidth: 640,
        okButtonColor: 'error',
        isShowCancel: true,
        okButtonText: '削除する',
        isConsentRequired: true,
        consentMessage: '子どもと保護者の同時削除について確認した',
        onConfirm: async () => {
          await ParentEditService.deleteParent(facilityId, parentId)
          window.removeEventListener('beforeunload', handler)
          ParentsPageStore.updateIsModifySuccess(true)
          location.href = `${backToUrl}&deleted_parents_id=${parentId}`
        },
      })
    }

    const disconnectLine = (parent: EditableParent) => {
      const { id, firstName, lastName } = parent
      ParentsService.disconnectLine(id, firstName, lastName, () => {
        ParentEditService.loadParent()
      })
    }

    const disconnectCodmon = (parent: EditableParent, child: EditableChild) => {
      const { id: parentId, lastName } = parent
      const { id: childId, firstName: childFirstName } = child
      ParentsService.disconnectCodmon(parentId, childId, lastName, childFirstName, () => {
        ParentEditService.loadParent()
      })
    }

    const connectCodmon = async (parent: EditableParent, child: EditableChild) => {
      const { id: parentId, lastName: parentLastName, firstName: parentFirstName } = parent
      const { id: childId, firstName: childFirstName } = child
      await ParentsService.connectCodmon(
        parentId,
        childId,
        parentLastName,
        parentFirstName,
        childFirstName,
        () => {
          ParentEditService.loadParent()
        }
      )
    }

    return {
      parent,
      backToUrl,
      faqPageUrl,
      addChild,
      removeChild,
      deleteParentAndChild,
      modifyParent,
      deleteParent,
      disconnectLine,
      disconnectCodmon,
      connectCodmon,
      isDisabled,
      parentEditForm,
    }
  },
})
</script>

<style scoped>
@media (1264px <= width) {
  .container {
    max-width: 1185px;
  }
}

@media (960px < width <= 1264px) {
  .container {
    max-width: 900px;
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding: 12px;
  width: 100%;
}
</style>
