import { ImportPanelStore } from '../import_panel_store'

export const maxPreviewRowCount = 10

export const columnMappingSaveKeyPrefix = 'csv_import_column_mapping_value_'

/**
 * ImportPanel全体に関わるロジックを持ちます
 */
export class ImportPanelService {
  static incrementStep(): void {
    ImportPanelStore.updateStep(ImportPanelStore.step + 1)
  }

  static decrementStep(): void {
    ImportPanelStore.updateStep(ImportPanelStore.step - 1)
  }
}
