<template>
  <div class="table-wrapper">
    <table>
      <slot></slot>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

/**
 * tableタグの中身をslotに渡してください。
 * 特に機能はありません。スタイルをあてるためのコンポーネントです。
 * - trにgrey-outクラスを付与するとグレーアウトします
 * - tdにerror-cellクラスを付与すると背景を赤くします
 */
export default defineComponent({
  setup() {
    return {}
  },
})
</script>

<style lang="scss" scoped>
@import '../../../../../assets/stylesheets/design/variables';

.table-wrapper {
  overflow-x: auto;
}
table {
  border-collapse: collapse;
  width: 100%;
}

:slotted(tr.grey-out) {
  background-color: #f6f6f5;
  color: #727272;
}
:slotted(table td) {
  vertical-align: middle;
  padding: 0.3rem;
  border-left: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}
:slotted(.error-cell) {
  background-color: #ffa2a5;
}
:slotted(table th) {
  padding: 0.6rem;
  vertical-align: middle;
  border-top: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  background-color: $color-secondary;
}
:slotted(table td:last-child) {
  border-right: 1px solid #e0e0e0;
}
:slotted(table th:last-child) {
  border-right: 1px solid #e0e0e0;
}
</style>
