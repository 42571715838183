import { HTTP } from '../common'

export type CollectTotalOfMonth = {
  collect_total: number
  deduction_total: number
  transfer_amount: number
  paid_year_month: string
}

export type FacilityAccountingSummary = {
  facility_id: number
  facility_name: string
  responsible_person_name: string
  collect_total: number
  deduction_total: number
  transfer_amount: number
  collect_total_of_months: CollectTotalOfMonth[]
}

export type IndexResponseData = {
  hits: FacilityAccountingSummary[]
  next_cursor: number
}

export type IndexResponse = {
  data: IndexResponseData
}

/** from, toともにyyyy-MMの形式 */
export type YearMonthRange = {
  from: string
  to: string
}
export class CorporationsTransferApi {
  /**
   * 指定期間の施設ごとの振込情報を取得します
   */
  static index(
    targetYearMonthRange: YearMonthRange,
    cursor?: number,
    keyword?: string,
    perPage?: number
  ): Promise<IndexResponse> {
    return HTTP.get('/corporations/me/transfer', {
      params: {
        from: targetYearMonthRange.from,
        to: targetYearMonthRange.to,
        cursor: cursor || '',
        keyword: keyword || '',
        per_page: perPage || '',
      },
    })
  }
}
