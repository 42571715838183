import { Receipt } from 'http/modules/receipt'
import { reactive } from 'vue'

type ReceiptPrintPageState = {
  isLoading: boolean
  receipt: Receipt | undefined
}

const state = reactive<ReceiptPrintPageState>({
  isLoading: false,
  receipt: undefined,
})

export class ReceiptPrintPageStore {
  static get isLoading(): boolean {
    return state.isLoading
  }

  static updateIsLoading(isLoading: boolean): void {
    state.isLoading = isLoading
  }

  static get receipt(): Receipt | undefined {
    return state.receipt
  }

  static updateReceipt(receipt: Receipt): void {
    state.receipt = receipt
  }
}
