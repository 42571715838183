<template>
  <div class="text-center">
    <v-progress-circular :size="70" color="primary" indeterminate />
    <p class="mt-4">{{ text }}</p>
  </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
    },
  },
})
</script>
