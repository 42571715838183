<template>
  <SimpleLayout>
    <template #headerNav>
      <FacilityHeader :facility-id="Number(facilityId)" />
    </template>
    <template #main>
      <div class="mt-12 mb-16 mx-auto">
        <div class="text-h6 text-center">アカウント設定</div>
      </div>
      <div class="card-section">
        <EmailFormCard :current-email="email" />
        <PasswordFormCard />
        <InitialMonthFormCard
          :invoice-page-initial-target-month="invoicePageInitialTargetMonth"
          @change="handleChangeInvoicePageInitialTargetMonth"
          @submit="handleClickSave"
        />
      </div>
    </template>
  </SimpleLayout>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { AccountSettingsPageStore } from 'pages/account_settings_page/account_settings_page_store'
import { AccountSettingsService } from 'pages/account_settings_page/services/account_settings_service'
import { AccountSettingApi } from 'http/modules/account_setting'
import { SnackbarService } from 'shared/services/snackbar_service'
import FacilityHeader from 'shared/components/header/FacilityHeader.vue'
import { EventTracker } from 'shared/utils/_event_tracking'
import { MetaService } from 'shared/services/meta_service'
import SimpleLayout from 'shared/templates/SimpleLayout.vue'
import EmailFormCard from 'pages/account_settings_page/components/EmailFormCard.vue'
import PasswordFormCard from 'pages/account_settings_page/components/PasswordFormCard.vue'
import InitialMonthFormCard from 'pages/account_settings_page/components/InitialMonthFormCard.vue'
import { InvoicePageInitialTargetMonth } from 'shared/models'

defineProps<{
  facilityId: string
}>()

MetaService.addTitle({ mode: 'facility', page: 'accountSettings' })

// computed
AccountSettingsService.load()
const email = computed(() => AccountSettingsPageStore.email)

const invoicePageInitialTargetMonth = computed(
  () => AccountSettingsPageStore.invoicePageInitialTargetMonth
)
const handleChangeInvoicePageInitialTargetMonth = (value: InvoicePageInitialTargetMonth) => {
  AccountSettingsPageStore.updateInvoicePageInitialTargetMonth(value)
}

const saveSetting = () => {
  AccountSettingApi.save({ invoice_page_initial_target_month: invoicePageInitialTargetMonth.value })
    .then(() =>
      SnackbarService.open('設定を保存しました。', 'success', {
        variant: 'flat',
        showIcon: true,
        location: 'bottom right',
      })
    )
    .catch(() =>
      SnackbarService.open('設定の保存に失敗しました。', 'error', {
        variant: 'flat',
        showIcon: true,
        location: 'bottom right',
      })
    )
}
const handleClickSave = () => {
  EventTracker.trackEvent('click_btn_save_account_setting', {})
  saveSetting()
}
</script>
<style scoped>
.card-section {
  display: grid;
  row-gap: 40px;
  margin-bottom: 160px;
}
</style>
