import { AllParentIdPrintPageStore } from '../all_parent_id_print_page_store'
import { ParentAccountApi } from 'http/modules/parent_account'
import { LineQrCodeApi } from 'http/modules/line_qr_code'
import { RouteService } from 'shared/services/route_service'
import { FacilityApi } from 'http/modules/facility'

/**
 * 保護者全員分のID印刷に必要なロジックを持ちます
 */
export class AllParentIdPrintService {
  static async loadAllParentIdPrintInfo(): Promise<void> {
    try {
      AllParentIdPrintPageStore.updateIsLoading(true)
      const queryParams = RouteService.getQueryParams()
      const response = (
        await ParentAccountApi.index({
          facilityId: AllParentIdPrintService.getTargetFacilityId(),
          queryParams: queryParams,
        })
      ).data
      AllParentIdPrintPageStore.updateAllParentAccount(response)
      const qrCodeImageSource = (
        await LineQrCodeApi.show(AllParentIdPrintService.getTargetFacilityId())
      ).data.qr_code_url
      AllParentIdPrintPageStore.updateQrCodeImageSource(qrCodeImageSource)

      const canUseEnpayWallet = (
        await FacilityApi.show(AllParentIdPrintService.getTargetFacilityId())
      ).data.can_use_enpay_wallet
      AllParentIdPrintPageStore.updateCanUseEnpayWallet(canUseEnpayWallet)
    } finally {
      AllParentIdPrintPageStore.updateIsLoading(false)
    }
  }

  static getTargetFacilityId(): number {
    return Number(RouteService.getPathParamByIndex(1))
  }
}
