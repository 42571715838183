import { TopPageStore } from '../top_page_store'
import { SchoolParentService } from '../services/school_parent_service'
import { SchoolClassService } from '../services/school_class_service'
import { InvoiceItemService } from './invoice_item_service'
import { FacilityApi } from 'http/modules/facility'
import { RouteService } from '../../../shared/services/route_service'
import { TargetMonthService } from './target_month_service'
import { ScreenLoadingService } from 'shared/services/screen_loading_service'

export class SchoolService {
  /**
   * 初期描画時、<>ボタンで月移動したときに呼ばれるのを想定しています
   * school,schoolParents,schoolClass,invoiceItemNameCandidatesを取得しtop_page_storeにデータを登録します
   */
  static async initialize(): Promise<void> {
    ScreenLoadingService.showLoader()
    try {
      await Promise.all([
        SchoolService.loadSchool(),
        SchoolParentService.loadSchoolParents(),
        SchoolClassService.loadSchoolClasses(),
        InvoiceItemService.loadInvoiceItemNameCandidates(),
      ])
      TopPageStore.updateIsLockedMonth(TargetMonthService.isLockedMonth())
    } finally {
      ScreenLoadingService.hideLoader()
    }
  }

  static async loadSchool(): Promise<void> {
    const school = (await FacilityApi.show(SchoolService.getTargetFacilityId())).data
    TopPageStore.updateSchool({
      codmon_id: school.codmon_id ?? undefined,
      created_at: new Date(school.created_at),
      locked_target_year_and_months: school.locked_target_year_and_months,
    })
  }

  static getTargetFacilityId(): number {
    return Number(RouteService.getPathParamByIndex(1))
  }
}
