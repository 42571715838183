<template>
  <v-sheet class="contents pa-8">
    <div class="text-h6 mb-8">
      <slot name="title"></slot>
    </div>
    <div class="mb-2">
      <VForm ref="formRef">
        <slot name="form-content"></slot>
      </VForm>
    </div>
    <div class="d-flex justify-end mt-6">
      <slot name="actions" :form-ref="formRef"></slot>
    </div>
  </v-sheet>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import { VForm } from 'vuetify/components'
const formRef = ref<VForm | null>(null)
</script>
