<template>
  <div>
    <div
      v-if="deletedParentIds.length !== 0 || deletedChildIds.length !== 0"
      class="font-weight-bold fs-16"
    >
      <template v-if="deletedParentIds.length > 0">
        保護者{{ deletedParentIds.length }}名
      </template>
      <template v-if="deletedParentIds.length > 0 && deletedChildIds.length > 0">、</template>
      <template v-if="deletedChildIds.length > 0"> 子ども{{ deletedChildIds.length }}名 </template>
      を削除しました。
    </div>
    <v-divider
      v-if="
        (deletedParentIds.length !== 0 || deletedChildIds.length !== 0) &&
        (failedDeletedParentIds.length > 0 || failedDeletedChildIds.length > 0)
      "
      class="my-6"
    />
    <template v-if="failedDeletedParentIds.length > 0 || failedDeletedChildIds.length > 0">
      <div class="pb-5">
        <v-alert type="error" variant="outlined" class="mt-4">
          <template v-if="failedDeletedParentIds.length > 0">
            保護者{{ failedDeletedParentIds.length }}名
          </template>
          <template v-if="failedDeletedParentIds.length > 0 && failedDeletedChildIds.length > 0"
            >と</template
          >
          <template v-if="failedDeletedChildIds.length > 0">
            子ども{{ failedDeletedChildIds.length }}名
          </template>
          <span>は未払いのため、削除できませんでした。</span>
          <div style="color: #333" class="fs-16 my-2">
            <span>未払いの保護者や子どもは削除することができません。</span>
            <br />
            <span>支払い完了後、削除できるようになります。</span>
          </div>
        </v-alert>
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { DeleteResult } from 'pages/parents_page/model'

export default defineComponent({
  props: {
    result: {
      type: Object as PropType<DeleteResult>,
      required: true,
    },
  },
  setup(props) {
    const {
      deleted_ids: deletedParentIds,
      failed_deleted_ids: failedDeletedParentIds,
      children,
    } = props.result.parents
    const { deleted_ids: deletedChildIds, failed_deleted_ids: failedDeletedChildIds } = children

    return { deletedParentIds, failedDeletedParentIds, deletedChildIds, failedDeletedChildIds }
  },
})
</script>

<style scoped>
.grey-out {
  background: #f6f6f5;
  pointer-events: none;
}
.fs-16 {
  font-size: 16px;
}
.border {
  border: 1px solid #e0e0e0;
  border-right: 0px;
}
.border-right {
  border-right: 1px solid #e0e0e0;
}
</style>
