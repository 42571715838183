import { AdminTransferStatementHistoryApi } from 'http/modules/admin_transfer_statement_history'
import { TransferStatementPrintPageStore } from 'pages/transfer_statement_print_page/transfer_statement_print_page_store'
import { TransferStatementHistory } from 'pages/transfer_statement_print_page/model'

export class AdminTransferStatementHistoryPrintService {
  /**
   * @param monthlyTransferId 月々の施設への振り込みID
   */
  static async loadTransferStatementHistories(monthlyTransferId: number): Promise<void> {
    const response = (await AdminTransferStatementHistoryApi.index(monthlyTransferId)).data
    TransferStatementPrintPageStore.updateTransferStatementHistories(response)
    AdminTransferStatementHistoryPrintService.setInitialTransferStatementDate(response)
  }

  /**
   * @param monthlyTransferId 月々の施設への振り込みID
   * @param historyId 修正された月々の施設への振り込みID
   */
  static async loadTransferStatementHistory(
    monthlyTransferId: number,
    historyId: number
  ): Promise<void> {
    try {
      TransferStatementPrintPageStore.updateIsLoading(true)
      const response = (await AdminTransferStatementHistoryApi.show(monthlyTransferId, historyId))
        .data
      TransferStatementPrintPageStore.updateTransferStatement(response.transfer_statement)
      TransferStatementPrintPageStore.updateStartCalcPaidDate(response.start_calc_paid_date)
      TransferStatementPrintPageStore.updateEndCalcPaidDate(response.end_calc_paid_date)
    } finally {
      TransferStatementPrintPageStore.updateIsLoading(false)
    }
  }

  /**
   * @param transferStatementHistories 修正された月々の施設への振り込みの一覧
   */
  private static setInitialTransferStatementDate(
    transferStatementHistories: TransferStatementHistory[]
  ): void {
    TransferStatementPrintPageStore.updateSelectedTransferStatementDate(
      transferStatementHistories.filter((x) => x.id === null)[0].calculated_at
    )
  }
}
