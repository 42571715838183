<template>
  <a
    :target="tabTarget"
    :href="linkUrl"
    :class="textDecoration"
    @click.stop="$emit('click', $event)"
    ><slot>リンクテキストを入力</slot></a
  >
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    linkUrl: {
      type: String,
      required: true,
    },
    isOpenNewTab: {
      type: Boolean,
      default: false,
    },
    hasUnderline: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['click'],
  setup(props) {
    const tabTarget = props.isOpenNewTab ? '_blank' : '_self'
    // hasUnderlineがtrueの場合、text-decoration: noneはここでは設定しない。body全体のaタグに適用されているので。
    const textDecoration = !props.hasUnderline
      ? 'font-weight-bold'
      : 'text-decoration-underline text-primary'

    return {
      tabTarget,
      textDecoration,
    }
  },
})
</script>
