<template>
  <h1 class="sign-ServiceLogo">
    <img :src="imageUrl" alt="enpay" />
  </h1>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SignInServiceLogo',
  props: {
    imageUrl: {
      type: String,
      default: () =>
        'https://s3.ap-northeast-1.amazonaws.com/jp.co.enpay-production.image/enpay_logo_2.svg',
    },
  },
})
</script>
