<template>
  <div class="print-panel page_break">
    <div class="print-panel_inner">
      <section class="print-panel_header">
        <div style="flex: 1" />
        <div class="d-flex justify-center">
          <S3Image class="logo" path="/enpay_logo_1.svg" />
        </div>
        <div>
          <div class="header-title">【重要】LINE登録のお願い</div>
          <div class="header-message">
            <div class="inline-flex align-center">
              <v-icon color="#d70000">mdi-alert-circle-outline</v-icon>
            </div>
            <div class="alert">この紙は登録完了までなくさないようにご注意ください。</div>
          </div>
        </div>
        <div style="flex: 1" />
        <section class="body-title-section">
          <h3 class="body-title">
            <span class="mr-1">{{ parentAccount.last_name }}</span>
            <span class="mr-2">{{ parentAccount.first_name }}</span>
            <span class="body-title-adornment">様</span>
          </h3>
          <div class="body-sub-title">{{ formatChildren(parentAccount.children) }}</div>
        </section>
      </section>
      <section class="print-panel_body">
        <section class="line-registration-guide-section">
          <div class="title-area">
            <S3Image path="/logos/icon_line.png" />
            <div class="text-body-1 font-weight-bold">
              QRコードを読み取って<br />お友だち登録してください
            </div>
          </div>
          <div class="qr-code-area">
            <img
              class="qr-code"
              :src="qrCodeImageSource"
              alt="enpay（エンペイ）のLINEアカウントのQRコード"
            />
          </div>
          <div class="registration-step-area">
            <ol>
              <li>カメラアプリを起動して、QRコードを読み取る</li>
              <li>エンペイ(園・施設のお支払いサービス)の<br />LINEアカウントを友だち登録</li>
              <li>
                <div>
                  IDとパスワードを入力して送信
                  <div class="id-table">
                    <div class="id-table-row">
                      <div class="id-table-header">ID</div>
                      <div class="id-table-data">{{ parentAccount.onetime_id }}</div>
                    </div>
                    <div class="id-table-row">
                      <div class="id-table-header">パスワード</div>
                      <div class="id-table-data">{{ parentAccount.onetime_password }}</div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                「アカウント連携が完了しました」という<br />メッセージが届いたら、登録完了です。
              </li>
            </ol>
          </div>
        </section>
        <ul class="body-supplement">
          <li>・登録が終わったら、家族を招待できます。「メニューをひらく」→「施設・家族を追加」</li>
          <li>
            ・LINEを活用した決済サービスですが、皆様のLINEアカウント（IDやアイコン等）は施設側で一切把握できません。
          </li>
        </ul>
      </section>
      <div class="banner">
        <template v-if="canUseEnpayWallet">
          <S3Image
            alt="enpayウォレットの紹介"
            path="/parent_id_document/enpay_wallet_pr_banner.png"
          />
          <div class="enpay-wallet-pr-message">
            LINE友だち登録が完了し、施設から請求が届くと支払い方法を選択できる画面が表示されます。その際ご活用ください。
          </div>
        </template>
        <template v-else>
          <S3Image
            style="margin-bottom: 24px"
            alt="LINE登録後の流れ"
            path="/parent_id_document/line_usage_banner.png"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Child, ParentAccount } from 'http/modules/parent_account'
import S3Image from 'shared/components/s_3_image/S3Image.vue'

const props = defineProps<{
  parentAccount: ParentAccount
  qrCodeImageSource: string
  canUseEnpayWallet: boolean
}>()

const formatChildren = (children: Child[]) => {
  const PARENT_NAME_AREA_WIDTH_PIXEL = 765
  const CHILD_NAME_FONT_SIZE_PIXEL = 20
  // 20pxで1行（765px）に収まる文字数 * 3行分
  const maxContainableChildNameLength =
    Math.floor(PARENT_NAME_AREA_WIDTH_PIXEL / CHILD_NAME_FONT_SIZE_PIXEL) * 3
  let displayName: string = ''

  for (let i = 0; i < children.length; i += 1) {
    const child = children[i]
    // 現在処理対象の子どもが領域が足りず表示できない場合、他N名に含まれるので、インデックス考慮で-1する必要はない。
    const remainder = children.length - i
    const remainderText = ` 他${remainder}名`
    const childName = `${
      i === 0
        ? `${child.name}さん（${child.school_class.name}）`
        : `/ ${child.name}さん（${child.school_class.name}）`
    }`
    const containableLength = maxContainableChildNameLength - displayName.length
    const isContainable =
      remainder === 0
        ? childName.length <= containableLength
        : childName.length + remainderText.length <= containableLength

    if (isContainable) {
      displayName += childName
    } else {
      displayName += remainderText
      break
    }
  }
  return displayName
}

const parentNameLength = (props.parentAccount.last_name + props.parentAccount.first_name).length
</script>

<style lang="scss" scoped>
@page {
  margin: 0mm;
  size: A4 portrait;
}

@media print {
  .print-panel {
    margin-bottom: 0mm;
  }

  .page_break {
    break-after: always;
  }

  .page_break:last-child {
    page-break-after: auto;
  }
}

.print-panel {
  width: 850px;
  border-radius: 4px;
  background: #fff;
  margin: 0 auto 8px;
}

.print-panel_inner {
  padding: 30px;
}

.print-panel_header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: stretch;
  height: 358px;
}

.print-panel_body {
  width: 100%;
  display: grid;
  place-content: center;
}

.logo {
  width: 154px;
}

.header-title {
  font-size: 44px;
  font-weight: bold;
  text-align: center;
  margin-top: 18px;
}

.header-message {
  display: flex;
  align-content: center;
  justify-content: center;
  column-gap: 4px;
  margin-top: 11px;
  font-size: 16px;
  font-weight: bold;
  color: #d70000;
}

.header-message .alert {
  margin-top: 2px;
}

.body-title-section {
  display: grid;
  place-content: center;
  place-items: center;
  row-gap: 12px;
  margin: 0 auto;
  width: 735px;
  min-height: 146px;
  max-height: 185px;
  border-radius: 8px;
  border: 2px solid #c0c0c0;
  padding: 12px 0px;
  text-align: center;
}

.body-title {
  text-align: center;
  // 印刷用紙の幅 - 全体の padding - 両横のマージン（自動） - 調整用の100px
  font-size: clamp(
    24px,
    calc((850px - 30px - 2 * 27.5px - 100px) / v-bind('parentNameLength')),
    36.8px
  );
  font-weight: 700;
  word-break: break-all;
}

.body-title-adornment {
  font-size: 24px;
  font-weight: 500;
}

.body-sub-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

.line-registration-guide-section {
  display: grid;
  grid-template-columns: 292px auto;
  grid-template-rows: 88px 1fr;
  width: 734px;
  height: 376px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 3px solid #c0c0c0;
  background: #f6f6f6;
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
  margin: 32px auto 0;
}

.line-registration-guide-section .title-area {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  height: 88px;
  width: 292px;
  display: inline-flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px 0px 40px 0px;
  border-right: 3px solid #c0c0c0;
  border-bottom: 3px solid #c0c0c0;
  background: #fff;
}

.line-registration-guide-section .title-area img {
  width: 40px;
}

.line-registration-guide-section .qr-code-area {
  display: grid;
  place-content: center;
  grid-row: 2 / 3;
  grid-column: 1 / 2;
  width: 292px;
}

.line-registration-guide-section .registration-step-area {
  grid-row: 1 / 3;
  grid-column: 2 / 3;
  padding: 8px 20px 20px 20px;
}

.line-registration-guide-section .registration-step-area ol {
  counter-reset: number;
  list-style-type: none !important;
  padding-left: 0;
}

.line-registration-guide-section .registration-step-area ol > li {
  position: relative;
  padding: 0.5em 0.5em 0.5em 37px;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: flex-start;
}

.line-registration-guide-section .registration-step-area ol > li:before {
  position: absolute;
  counter-increment: number;
  content: counter(number);
  display: inline-block;
  background-color: #fff;
  border: 1px solid #c0c0c0;
  font-weight: bold;
  font-size: 14px;
  border-radius: 50%;
  left: 0;
  width: 29px;
  height: 29px;
  line-height: 25px;
  text-align: center;
  align-self: flex-start;
}

.id-table {
  margin-top: 13px;
}

.id-table-row {
  display: flex;
}

.id-table-row:first-child > .id-table-header {
  border-radius: 6px 0 0 0;
  border-bottom: none;
}

.id-table-row:first-child > .id-table-data {
  border-radius: 0 6px 0 0;
  border-bottom: none;
}

.id-table-row:nth-child(2) > .id-table-header {
  border-radius: 0 0 0 6px;
}

.id-table-row:nth-child(2) > .id-table-data {
  border-radius: 0 0 6px 0;
}

.id-table-header {
  width: 120px;
  background: #f0f0f0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  padding: 15px 8px;
  border: 2px solid #c0c0c0;
  border-right: none;
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
}

.id-table-data {
  width: 220px;
  background: #fff;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  padding: 8px;
  border: 2px solid #c0c0c0;
}

.qr-code {
  width: 200px;
}

.body-supplement {
  font-size: 14px;
  font-weight: 500;
  padding-left: 0;
  margin-top: 20px;
  width: 742px;
  text-align: left;
}

.body-supplement li:not(:first-child) {
  margin-top: 8px;
}

.banner {
  margin: 16px auto 0;
  display: grid;
  justify-content: center;
}

.banner .enpay-wallet-pr-message {
  margin-top: 8px;
  text-align: right;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}
</style>
