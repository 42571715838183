import { HTTP } from '../common'
import { AxiosError, AxiosResponse } from 'axios'

type CreateResponse = { return_to: string }

export class LineSessionApi {
  static create(line_user_token: string): Promise<AxiosResponse<CreateResponse>> {
    HTTP.defaults.baseURL = '/line/api/v1/'
    return HTTP.post(`/sessions`, { line_user_token })
  }
}

export const isAxiosError = <T extends CreateResponse>(error: unknown): error is AxiosError<T> =>
  !!(error as AxiosError<T>).isAxiosError
