<template>
  <div>
    <div class="mb-4">
      この画面を閉じて<span class="font-weight-bold">前の画面（請求画面）を再読み込み</span
      >すると、取り込んだ請求内容が画面に反映されます。
    </div>
    <Button size="large" class="mb-4" @click="closeTab()"> 閉じる </Button>
    <div>タブが閉じない場合は、ブラウザのタブにある×ボタンで閉じてください。</div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import Button from 'shared/components/Button.vue'

export default defineComponent({
  components: {
    Button,
  },
  setup() {
    const closeTab = () => window.close()
    return {
      closeTab,
    }
  },
})
</script>
