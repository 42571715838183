import { reactive } from 'vue'
import { CorporationApplyForm, InitializedState, PanelInfo, ValidState } from './models'

type CorporationApplyPageState = {
  formValue: CorporationApplyForm
  panelStep: number
  validState: ValidState
  initializedState: InitializedState
  errorMessages: string[]
  panels: PanelInfo[]
  isSubmitting: boolean
}

/**
 * 法人情報登録ページの状態。
 */
const state = reactive<CorporationApplyPageState>({
  /**
   * 法人情報登録ページの入力項目を持つ。
   */
  formValue: {
    signUpCheckbox: false,
    signUpPolicyAgainstAntiSocialForces: false,
    email: '',
    confirmationEmail: '',
    oemPartnerBranchName: '',
    doSpecialBusiness: false,
    doDoorToDoorSales: false,
    doSpecifiedContinuousServiceProvision: false,
    doTelemarketingRelatedSales: false,
    doChainReactionSales: false,
    doFreelanceSales: false,
    providedPrepaidTransactions: false,
    isApplicableSpecialCommercialCodeMeasures: false,
    developedFranchise: false,
    businessType: '法人',
    typeOfIndustry: '教育，学習支援業',
    businessDescription: '',
    corporationNumber: '',
    corporationName: '',
    corporationNameKana: '',
    establishedDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    capitalStock: 0,
    employeesNumber: 0,
    annualTurnover: 0,
    homePageUrl: '',
    postalCode: '',
    prefectureName: '東京都',
    prefectureNameKana: '',
    cityName: '',
    cityNameKana: '',
    townName: '',
    townNameKana: '',
    streetName: '', // streetNameKanaはstreetNameと同じ値が入るため不要
    buildingName: '',
    buildingNameKana: '',
    phoneNumber: '',
    representativeFirstName: '',
    representativeLastName: '',
    representativeFirstNameKana: '',
    representativeLastNameKana: '',
    representativeBirthdate: new Date(
      new Date(1980, 0, 1).getTime() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    representativePostalCode: '',
    representativePrefectureName: '東京都',
    representativeCityName: '',
    representativeTownName: '',
    representativeStreetName: '', // representativeStreetNameKanaはrepresentativeStreetNameと同じ値が入るため不要
    representativeBuildingName: '',
    representativePrefectureNameKana: '',
    representativeCityNameKana: '',
    representativeTownNameKana: '',
    representativeBuildingNameKana: '',
    representativePhoneNumber: '',
  },

  /**
   * 法人情報登録ページで開いているページ数を数字で持つ。1~10まで。
   */
  panelStep: 1,

  validState: {
    emailPanel: false,
    branchNamePanel: false,
    screeningPanel1: false,
    screeningPanel2: true,
    screeningPanel3: true,
    corpInfoPanel1: false,
    corpInfoPanel2: false,
    corpInfoPanel3: false,
    representativeInfoPanel: false,
  },

  /**
   * 法人情報登録画面で開いているページの入力項目が編集されたか否かを持つ。falseなら編集済み、trueなら未編集。
   */
  initializedState: {
    emailPanel: true,
    branchNamePanel: true,
    screeningPanel1: true,
    screeningPanel2: true,
    screeningPanel3: true,
    corpInfoPanel1: true,
    corpInfoPanel2: true,
    corpInfoPanel3: true,
    representativeInfoPanel: true,
  },

  /**
   * 法人情報登録時のエラーメッセージを配列で持つ。
   */
  errorMessages: [],

  /**
   * パネルの情報を配列で持つ。
   */
  panels: [
    {
      title: 'メール',
      refName: 'emailPanel',
      componentName: 'EmailPanel',
      props: {
        termsOfServiceUrl: '/terms_of_services/school_account',
        privacyPolicyUrl: '/privacy_policy',
      },
    },
    { title: '審査情報1', refName: 'screeningPanel1', componentName: 'ScreeningPanel1', props: {} },
    { title: '審査情報2', refName: 'screeningPanel2', componentName: 'ScreeningPanel2', props: {} },
    { title: '審査情報3', refName: 'screeningPanel3', componentName: 'ScreeningPanel3', props: {} },
    { title: '会社情報1', refName: 'corpInfoPanel1', componentName: 'CorpInfoPanel1', props: {} },
    { title: '会社情報2', refName: 'corpInfoPanel2', componentName: 'CorpInfoPanel2', props: {} },
    { title: '会社情報3', refName: 'corpInfoPanel3', componentName: 'CorpInfoPanel3', props: {} },
    {
      title: '代表者情報',
      refName: 'representativeInfoPanel',
      componentName: 'RepresentativeInfoPanel',
      props: {},
    },
    { title: '確認', refName: 'confirmPanel', componentName: 'ConfirmPanel', props: {} },
    {
      title: '完了',
      refName: 'applicationFinishPanel',
      componentName: 'ApplicationFinishPanel',
      props: {},
    },
  ],

  /**
   * 法人情報登録ページの登録するボタンが押されたかどうかを持つ。falseは押されてない。trueは押された。
   */
  isSubmitting: false,
})

/**
 * 法人情報登録ページの状態を操作するメソッドを持つStore。
 */
export class CorporationApplyPageStore {
  static get formValue(): CorporationApplyForm {
    return state.formValue
  }

  static updateFormValue(formValue: any): void {
    state.formValue = formValue
  }

  static get panelStep(): number {
    return state.panelStep
  }

  /**
   * 現在のpanel情報を返す。
   */
  static getCurrentPanelInfo(): PanelInfo {
    return state.panels[state.panelStep - 1]
  }

  /**
   * @param panelStep 2桁の数字。1~10まで。
   */
  static updatePanelStep(panelStep: number): void {
    state.panelStep = panelStep
  }

  static get validState(): ValidState {
    return state.validState
  }

  /**
   * @param validState 法人情報登録ページの入力項目がバリデーションを通ったか。通ったらtrue、通らなければfalse。
   */
  static updateValidState(validState: ValidState): void {
    state.validState = validState
  }

  static get initializedState(): InitializedState {
    return state.initializedState
  }

  /**
   * @param initializedState 法人情報登録画面で開いているページの入力項目が編集されたか否かを持つ。falseなら編集済み、trueなら未編集。
   * バリデーションをかけるかどうかの判定に用いる。
   */
  static updateInitializedState(initializedState: InitializedState): void {
    state.initializedState = initializedState
  }

  static get errorMessages(): Array<string> {
    return state.errorMessages
  }

  /**
   * @param errorMessages 法人情報登録画面に表示するエラーメッセージ。
   */
  static updateErrorMessages(errorMessages: Array<string>): void {
    state.errorMessages = errorMessages
  }

  static get panels(): Array<PanelInfo> {
    return state.panels
  }

  /**
   * パネルを上書きする。
   * @param info パネルの情報
   * @param index 上書きする場所（0始まり）
   */
  static updatePanel(info: PanelInfo, index: number): void {
    state.panels.splice(index, 1, info)
  }

  /**
   * パネルを挿入する。
   * 初期処理の範疇。
   * @param info パネルの情報
   * @param index 挿入する場所（0始まり）
   */
  static insertPanel(info: PanelInfo, index: number): void {
    state.panels.splice(index, 0, info)
  }

  static get isSubmitting(): boolean {
    return state.isSubmitting
  }

  /**
   * 法人情報登録ページの登録するボタンが押されたかどうかの値を上書きする。
   * @isSubmitting boolean falseはボタンが押されていない。trueは押された。
   */
  static updateIsSubmitting(isSubmitting: boolean): void {
    state.isSubmitting = isSubmitting
  }
}
