import equal from 'fast-deep-equal'

/**
 * xxx_store で使う便利メソッドをまとめたサービス。
 */
export class StoreHelperService {
  /**
   * storeにある配列型の状態を更新するときに使うものです。
   * 単なる上書きに比べて、状態前後の配列の長さが同じ場合、変更のある要素のみ上書くので、変更の無い要素をwatchしている箇所で
   * 更新が走らないため画面描画等のコストが低くなります。
   */
  static updateArrayState(stateArray: any[], newArray: any[]): void {
    if (stateArray.length !== newArray.length) {
      stateArray.splice(0)
      stateArray.push(...newArray)
      return
    }
    // 配列の一部オブジェクトの値を変えているだけの場合、
    // 全配列の値を更新するとコストが高いので変更対象のelementだけを更新する。
    for (let i = 0; i < newArray.length; i += 1) {
      if (!equal(stateArray[i], newArray[i])) {
        stateArray[i] = newArray[i]
      }
    }
  }
}
