<template>
  <div class="d-flex align-end">
    <div class="mr-6">
      <span class="mr-4 all-counts">全{{ facilitySummary.total }}施設</span>
    </div>
    <v-divider class="mr-6" vertical inset />
    <AmountFacilitiesByStatus
      v-if="beforeLockedInvoiceTotal > 0"
      :status-name="'請求締め'"
      :total="beforeLockedInvoiceTotal"
    />
    <AmountFacilitiesByStatus
      v-if="beforeChargeInvoiceTotal > 0"
      :status-name="'請求保存中'"
      :total="beforeChargeInvoiceTotal"
    />
    <AmountFacilitiesByStatus
      v-if="unpaidInvoiceTotal > 0"
      :status-name="'未回収あり'"
      :total="unpaidInvoiceTotal"
    />
    <div
      v-if="beforeLockedInvoiceTotal === 0"
      class="my-auto text-primary font-weight-bold text-body-2"
    >
      <v-icon size="small" color="primary">mdi-check</v-icon>
      <span>請求締め完了</span>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, ComputedRef, defineComponent } from 'vue'
import { InvoiceManagementPageStore } from 'pages/invoice_management_page/invoice_management_page_store'
import { CorporationsFacilitySummary } from 'http/modules/corporations_facility_summary'
import AmountFacilitiesByStatus from './components/AmountFacilitiesByStatus.vue'

export default defineComponent({
  components: {
    AmountFacilitiesByStatus,
  },
  setup() {
    const facilitySummary: ComputedRef<CorporationsFacilitySummary> = computed(
      () => InvoiceManagementPageStore.facilitySummary
    )
    const beforeLockedInvoiceTotal = computed(
      () => facilitySummary.value.total - facilitySummary.value.locked_total
    )
    const beforeChargeInvoiceTotal = computed(
      () => facilitySummary.value.not_completed.before_charge
    )
    const unpaidInvoiceTotal = computed(() => facilitySummary.value.not_completed.unpaid)

    return {
      facilitySummary,
      beforeLockedInvoiceTotal,
      beforeChargeInvoiceTotal,
      unpaidInvoiceTotal,
    }
  },
})
</script>
<style scoped>
.all-counts {
  color: #727272;
}
</style>
