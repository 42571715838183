<template>
  <div v-if="isEdit">
    <!-- 編集用コンポーネント -->
    <v-text-field
      v-bind="attrs"
      ref="actualValueField"
      v-model="actualValue"
      :rules="rules"
      @blur="displayCommaSeparatedString"
    >
    </v-text-field>
  </div>
  <div v-else>
    <!-- 表示用コンポーネント -->
    <v-text-field
      v-model="displayValue"
      type="text"
      :label="attrs.label"
      :hint="attrs.hint"
      @focus="isEdit = true"
    >
    </v-text-field>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import useCommaSeparatedField, { CommaSeparatedFieldPropType } from './use_comma_separated_field'

export default defineComponent<CommaSeparatedFieldPropType>({
  model: { prop: 'modelValue', event: 'update:modelValue' },
  props: {
    modelValue: {
      type: [Number, String],
      required: true,
    },
    rules: {
      type: Array,
      required: false,
    },
  },
  emit: ['update:modelValue'],
  setup(props, { emit, attrs }) {
    const { isEdit, displayValue, actualValue, actualValueField, displayCommaSeparatedString } =
      useCommaSeparatedField(props, emit)

    return {
      attrs,
      isEdit,
      displayValue,
      actualValue,
      actualValueField,
      displayCommaSeparatedString,
    }
  },
})
</script>
