<template>
  <ParentSelectingAllCheckBox
    v-if="isSelectedAll"
    :is-selected-all="isSelectedAll"
    :parent="parent"
  />
  <v-checkbox
    v-else
    :key="parent.id"
    v-model="selectedParentIds"
    :value="parent.id"
    hide-details
    class="shrink"
  >
    <template #label>
      <v-card-title class="text-h6 font-weight-bold" style="color: #000000">
        {{ parent.last_name }} {{ parent.first_name }}
      </v-card-title>
    </template>
  </v-checkbox>
</template>
<script setup lang="ts">
import { computed, defineProps } from 'vue'
import { Parent } from 'pages/parents_page/model'
import { ParentsPageStore } from 'pages/parents_page/parents_page_store'
import ParentSelectingAllCheckBox from 'pages/parents_page/areas/parent_card/components/ParentSelectingAllCheckBox.vue'

const props = defineProps<{
  parent: Parent
  isSelectedAll: boolean
}>()
const emit = defineEmits(['onChangeParentSelected'])

const selectedParentIds = computed({
  get: () => ParentsPageStore.selectedParentIds,
  set: (value) => emit('onChangeParentSelected', value, props.parent),
})
</script>
<style scoped lang="scss">
.theme--light.v-card .v-card__subtitle {
  font-size: 16px;
  color: #727272;
}
.v-card__title {
  padding: 0 10px 0 0;
}
.v-input--selection-controls {
  margin: 0 8px 0 0 !important;
}
</style>
