import { CorporationApplyPageStore } from '../corporation_apply_page_store'
import rfdc from 'rfdc'
const clone = rfdc()

const minPanelSize = 1

/**
 * 法人情報登録画面のページのナビゲーションボタンに関するロジックを持つ。
 */
export class PanelStepService {
  /**
   * ページ数を+1する。ページ数を+1できない場合はfalse、+1できたらtrueを返す。
   */
  static incrementPanelStep(): boolean {
    // 確認の1個前（最後から3つめ）以前のみバリデーション
    if (CorporationApplyPageStore.panelStep < CorporationApplyPageStore.panels.length - 1) {
      PanelStepService.updateCurrentStepsInitializedState(false)
    }
    if (CorporationApplyPageStore.panelStep >= CorporationApplyPageStore.panels.length) return false
    if (
      CorporationApplyPageStore.panelStep < CorporationApplyPageStore.panels.length - 1 &&
      !CorporationApplyPageStore.validState[CorporationApplyPageStore.getCurrentPanelInfo().refName]
    )
      return false
    if (CorporationApplyPageStore.panelStep < CorporationApplyPageStore.panels.length - 1) {
      PanelStepService.updateCurrentStepsInitializedState(true)
    }
    CorporationApplyPageStore.updatePanelStep(CorporationApplyPageStore.panelStep + 1)
    return true
  }

  /**
   * ページ数を-1する。ページ数を-1できない場合はfalse、-1できたらtrueを返す。
   */
  static decrementPanelStep(): boolean {
    if (CorporationApplyPageStore.panelStep <= minPanelSize) return false
    CorporationApplyPageStore.updatePanelStep(CorporationApplyPageStore.panelStep - 1)
    PanelStepService.updateCurrentStepsInitializedState(true)
    return true
  }

  /**
   * 戻るボタンを表示する場合はtrue、表示しない場合はfalseを返す。
   */
  static isShowBackButton(): boolean {
    return (
      CorporationApplyPageStore.panelStep > minPanelSize &&
      CorporationApplyPageStore.panelStep < CorporationApplyPageStore.panels.length
    )
  }

  /**
   * 進むボタンを表示する場合はtrue、表示しない場合はfalseを返す。
   */
  static isShowNextButton(): boolean {
    return (
      CorporationApplyPageStore.panelStep > minPanelSize - 1 &&
      CorporationApplyPageStore.panelStep < CorporationApplyPageStore.panels.length - 1
    )
  }

  /**
   * 登録するボタンを表示する場合はtrue、表示しない場合はfalseを返す。
   */
  static isShowRegisterButton(): boolean {
    return CorporationApplyPageStore.panelStep === CorporationApplyPageStore.panels.length - 1
  }

  /**
   * 表示中のページが初期表示か否かのステータスを更新する
   */
  static updateCurrentStepsInitializedState(isInitial: boolean): void {
    const initializedState = clone(CorporationApplyPageStore.initializedState)
    initializedState[CorporationApplyPageStore.getCurrentPanelInfo().refName] = isInitial
    CorporationApplyPageStore.updateInitializedState(initializedState)
  }
}
