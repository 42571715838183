import { createApp, h } from 'vue'
import { createVuetify } from 'vuetify'
import { vuetifyOptions } from 'shared/consts/vuetify_const'
import Snackbar from '../components/Snackbar.vue'

const snackbarTypeColorAndIconMap = {
  info: {
    color: 'primary',
    icon: 'mdi-information',
  },
  error: {
    color: 'red',
    icon: 'mdi-alert-circle',
  },
  success: {
    color: 'success',
    icon: 'mdi-check-circle',
  },
} as const

type SnackbarTypeColorAndIconMap = typeof snackbarTypeColorAndIconMap
type SnackbarType = keyof SnackbarTypeColorAndIconMap
type ColorAndIcon = SnackbarTypeColorAndIconMap[SnackbarType]

const block = ['top', 'bottom'] as const
const inline = ['start', 'end', 'left', 'right'] as const
type Tblock = (typeof block)[number]
type Tinline = (typeof inline)[number]
export type Anchor =
  | Tblock
  | Tinline
  | 'center'
  | 'center center'
  | `${Tblock} ${Tinline | 'center'}`
  | `${Tinline} ${Tblock | 'center'}`

type SnackbarState = {
  show: boolean
  type: SnackbarType
  color: ColorAndIcon['color']
  message: string
  variant: 'text' | 'outlined' | 'tonal' | 'flat'
  location: Anchor
  icon?: ColorAndIcon['icon']
}

/**
 * Snackbarのスタイルに関するオプション
 *
 * [variantについて]
 * refs: https://v2.vuetifyjs.com/en/components/snackbars/#vertical
 *
 * [placementについて]
 * ここのtopやらrightやらをUNIONで指定できるようにしています。現状必要最小限のものだけを指定できるようにしてます。
 * refs: https://v2.vuetifyjs.com/en/api/v-snackbar/#links
 */
type SnackbarStyleOptions = {
  variant?: SnackbarState['variant']
  showIcon?: boolean
  location?: SnackbarState['location']
}

/**
 * snackbarに関する状態
 */
const state: SnackbarState = {
  show: false,
  type: 'info',
  color: 'primary',
  message: '',
  variant: 'tonal',
  location: 'top',
}

/**
 * snackbarコンポーネントをwrapするdivタグ
 */
let divTag: HTMLDivElement
let appInstance: ReturnType<typeof createApp> | null = null

/**
 * 共通snackbarに関するロジックを持ちます。
 *
 * 使い方：
 * enpayアプリケーション内にてsnackbarを表示したい場合、このserviceが持つ`open`メソッドを呼び出してください。
 * snackbarを表示するために、テンプレートにsnackbarのタグを書く必要はありません。
 * `open`を呼び出すことで、javascript/shared/components/Snackbar.vueコンポーネントが表示されます。
 */
export class SnackbarService {
  static get show(): boolean {
    return state.show
  }

  static set show(value) {
    state.show = value
  }

  static get type(): SnackbarType {
    return state.type
  }

  static get color(): string {
    return state.color
  }

  static get message(): string {
    return state.message
  }

  static get icon() {
    return state.icon
  }

  static get variant() {
    return state.variant
  }

  static get location() {
    return state.location
  }

  static open(
    message: string,
    type: SnackbarType,
    { variant = 'text', showIcon = false, location = 'top' }: SnackbarStyleOptions = {}
  ): void {
    const { color, icon } = snackbarTypeColorAndIconMap[type]
    state.type = type
    state.message = message
    state.color = color
    state.variant = variant
    state.icon = showIcon ? icon : undefined
    state.location = location
    const vuetify = createVuetify(vuetifyOptions)
    if (!divTag) {
      divTag = document.createElement('div')
      document.body.appendChild(divTag)
    }
    if (appInstance) {
      appInstance.unmount()
      appInstance = null
    }
    appInstance = createApp({
      vuetify,
      state,
      render: () => h(Snackbar, { ...state }),
    })
    appInstance.use(vuetify)
    appInstance.mount(divTag)
  }

  /**
   * DOMからSnackbarの要素を全て削除します。
   * 内部的にはdivタグ以下のNodeを全て削除します
   */
  static removeComponent(): void {
    if (!divTag) return

    if (appInstance) {
      appInstance.unmount()
      appInstance = null
    }

    while (divTag.firstChild) {
      divTag.removeChild(divTag.firstChild)
    }
  }
}
