import { EditableChild, EditableParent } from 'pages/parents_page/model'
import { countLengthStrictly } from 'shared/utils/string'

const CODE_REGEXP = /^[a-zA-Z0-9\-_]*$/
const CODE_INCLUDING_INVALID_SIGN_REGEXP = /[^a-zA-Z0-9\-_]/

export class ParentValidatorsService {
  static isRequired(name: string) {
    return (value: string) => Boolean(value.trim()) || `${name}を入力してください`
  }

  static representativeInvalidCode(name: string) {
    return (value: string) => {
      if (value.match(CODE_INCLUDING_INVALID_SIGN_REGEXP)) {
        return '使えない記号が含まれています'
      }
      return !!value.match(CODE_REGEXP) || `${name}が正しくありません`
    }
  }

  static isTooLong(name: string, maxLength: number) {
    return (value: string) =>
      countLengthStrictly(value) <= maxLength || `${name}は${maxLength}文字以内で入力してください`
  }

  static representativeDuplicatedCodeErrorInParent(
    name: string,
    parent: EditableParent,
    parents: EditableParent[]
  ) {
    return (value: string) => {
      // 他の parents と code が被っていない
      const havingDuplicatedCode = parents.some(
        // 同一コードかつ同じ ID ではない
        (part) => part.code === parent.code && parent.temporaryId !== part.temporaryId
      )
      if (value !== '' && havingDuplicatedCode) {
        return `${name}が重複しています`
      }
      return !parent.errors?.attributeName.includes('code') || `${name}が重複しています`
    }
  }

  static representativeDuplicatedCodeErrorInChild(
    name: string,
    child: EditableChild,
    parents: EditableParent[]
  ) {
    return (value: string) => {
      // 他の children と code が被っていない
      const havingDuplicatedCode = parents
        .flatMap((parent) => parent.schoolParentChildren)
        .some(
          // 同一コードかつ同じ ID ではない
          (partOfChild) =>
            partOfChild.code === value && child.temporaryId !== partOfChild.temporaryId
        )

      if (value !== '' && havingDuplicatedCode) {
        return `${name}が重複しています`
      }

      return !child.errors?.attributeName.includes('code') || `${name}が重複しています`
    }
  }

  static representativeSchoolClassErrorInChild(name: string, child: EditableChild) {
    return (value: string | undefined) => {
      const errorMessage = `${name}を選択してください`
      const isEmptyValue = !value
      // 名前が入力済みかつ，school ID が未入力
      if (child.firstName !== '' && isEmptyValue) {
        return errorMessage
      }
      if (child.code.trim() !== '' && isEmptyValue) {
        return errorMessage
      }

      // すべてが未入力の場合
      if (isEmptyValue && child.code.trim() === '' && (child.schoolClassId ?? 0) === 0) {
        return true
      }

      // 入力済みの場合
      if (!isEmptyValue) {
        return true
      }

      return !child.errors?.includes('school_class') || errorMessage
    }
  }

  static representativeChildFirstName(name: string, child: EditableChild) {
    return (value: string) => {
      const errorMessage = `${name}を入力してください`
      const isEmptyValue = value.trim() === ''
      // コードが入力済みかつ名前が未入力である場合
      if (child.code.trim() !== '' && isEmptyValue) {
        return errorMessage
      }

      // クラスが入力済みかつ名前が未入力である場合
      if ((child.schoolClassId ?? '').length > 0 && isEmptyValue) {
        return errorMessage
      }

      // すべてが未入力の場合
      if (isEmptyValue && child.code.trim() === '' && (child.schoolClassId ?? 0) === 0) {
        return true
      }

      // 入力済みの場合
      if (!isEmptyValue) {
        return true
      }

      return errorMessage
    }
  }
}
