<template>
  <div class="d-flex align-center">
    <v-expansion-panels v-model="openPanelIndex">
      <v-expansion-panel>
        <div class="pa-4 d-flex align-center justify-space-between" @click.stop="">
          <div class="font-weight-medium text-body-1 text-grey flex-shrink-0">検索条件</div>
          <div class="flex-grow-1">
            <slot v-if="isOpen" name="header-open" />
            <slot v-else name="header-close" />
          </div>
          <Button
            class="font-weight-medium text-body-1 text-grey d-flex align-center"
            variant="text"
            @click="toggle"
          >
            {{ isOpen ? '閉じる' : '' }}
            <v-icon>{{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </Button>
        </div>
        <v-expansion-panel-text>
          <slot />
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import Button from '../Button.vue'

const { eventName } = defineProps<{
  eventName?: string
}>()

const emit = defineEmits<{
  (event: 'handleGaEvent', eventName: string): void
}>()

const openPanelIndex = ref<0 | -1>(0)
const isOpen = computed(() => openPanelIndex.value === 0)
const toggle = () => {
  openPanelIndex.value = openPanelIndex.value === 0 ? -1 : 0
  if (!eventName) return
  emit('handleGaEvent', eventName)
}
</script>

<style scoped lang="scss">
.v-expansion-panel-text::v-deep .v-expansion-panel-text__wrapper {
  padding: 0 16px 0 16px !important;
}

.v-expansion-panel::v-deep .v-expansion-panel__shadow {
  box-shadow: none !important;
}

.v-expansion-panels,
.v-expansion-panels .v-expansion-panel,
.v-expansion-panels .v-expansion-panel-text {
  transition: none !important;
}
</style>
