<template>
  <div class="payment-rate-chart d-flex align-center justify-center mb-4">
    <div class="chart-inner d-flex flex-column align-center">
      <p class="text-primary text-subtitle-1 mb-0 mt-2">
        お支払い率<span class="text-caption">(人)</span>
      </p>
      <div class="d-flex align-end text-h2 text-primary" data-testid="payment-rate">
        {{ paymentRate }}
        <span class="text-h4">%</span>
      </div>
    </div>
    <div class="chart">
      <Chart :chart-data="chartdata" :options="options" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import type { ChartData, ChartOptions } from 'chart.js'
import Chart from '../../../shared/components/Chart.vue'
import { SchoolParentService } from '../services/school_parent_service'

export default defineComponent({
  components: {
    Chart,
  },
  setup() {
    // computed
    const paymentRate = computed(() => {
      const rate = SchoolParentService.calculatePaymentRate()
      return rate === undefined ? '---' : rate
    })

    const chartdata = computed<ChartData<'doughnut'>>(() => ({
      datasets: [
        {
          backgroundColor: ['#0068b7', '#ffcc80', '#c7cfd6'],
          data: [
            SchoolParentService.countParentByLatestInvoiceStatus().paid,
            SchoolParentService.countParentByLatestInvoiceStatus().unpaid,
            SchoolParentService.countParentByLatestInvoiceStatus().before_charge,
          ],
        },
      ],
      labels: ['支払い済み', '未払い', '請求保存中'],
    }))

    // data
    const options = ref<ChartOptions<'doughnut'>>({
      cutout: '85%',
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return context.label + ': ' + context.parsed + '人'
            },
          },
        },
      },
    })

    return { chartdata, options, paymentRate }
  },
})
</script>

<style scoped lang="scss">
@import '../../../../assets/stylesheets/design/variables';

.payment-rate-chart {
  position: relative;
  height: 180px;
}

.chart {
  position: relative;
  width: 180px;
  height: 180px;
  z-index: $z-index-7;
}

.chart-inner {
  position: absolute;
  top: 36px;
  width: 180px;
  height: 90px;
}
</style>
