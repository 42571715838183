<template>
  <AppFrame :has-browser-alert="false">
    <PrintButtonBar class="mb-6"></PrintButtonBar>
    <div>
      <div v-if="isLoading" class="d-flex justify-center">
        <v-progress-circular indeterminate :size="70" color="primary"></v-progress-circular>
      </div>
      <template v-else>
        <div v-for="parentAccount in allParentAccount" :key="parentAccount.parent_id">
          <ParentIdDocument
            :parent-account="parentAccount"
            :qr-code-image-source="qrCodeImageSource"
            :can-use-enpay-wallet="canUseEnpayWallet"
          ></ParentIdDocument>
        </div>
      </template>
    </div>
  </AppFrame>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import ParentIdDocument from 'shared/components/ParentIdDocument.vue'
import PrintButtonBar from 'shared/components/PrintButtonBar.vue'
import { AllParentIdPrintPageStore } from '../all_parent_id_print_page_store'
import { AllParentIdPrintService } from '../services/all_parent_id_print_service'
import { MetaService } from 'shared/services/meta_service'
import AppFrame from 'shared/components/AppFrame.vue'

MetaService.addTitle({ mode: 'facility', page: 'parentIdPrint' })
AllParentIdPrintService.loadAllParentIdPrintInfo()

const allParentAccount = computed(() => AllParentIdPrintPageStore.allParentAccount)
const qrCodeImageSource = computed(() => AllParentIdPrintPageStore.qrCodeImageSource)
const isLoading = computed(() => AllParentIdPrintPageStore.isLoading)
const canUseEnpayWallet = computed(() => AllParentIdPrintPageStore.canUseEnpayWallet)
</script>
