<template>
  <div class="d-flex justify-end align-center">
    <template v-if="!isSearching">
      <div class="mr-4">
        <span class="font-weight-medium"
          >保護者
          {{
            `
          ${
            endIndex !== 0 ? `${startIndex.toLocaleString()}-${endIndex.toLocaleString()}` : '0'
          }名 / ${parentTotal.total.count.parents.toLocaleString()}名`
          }}
        </span>
      </div>
    </template>
    <template v-else>
      <v-skeleton-loader
        type="chip"
        class="mr-2 bg-background d-inline-flex justify-center"
        width="160"
      />
    </template>
    <div class="mr-4">
      <v-menu>
        <template #activator="{ props }">
          <Button v-bind="props" size="large">
            <span class="font-weight-bold fs-16">保護者・子どもを登録・削除</span>
            <v-icon class="pr-1">mdi-menu-down</v-icon>
          </Button>
        </template>
        <v-card class="mx-auto" max-width="300">
          <v-list>
            <v-list-subheader class="font-weight-bold">登録</v-list-subheader>
            <v-list-item
              v-if="hasCodmonId"
              class="context-menu-link d-flex"
              @click="
                $emit('onClickCodmonConnection'), $emit('handleGaEvent', 'batch_link_with_codmon')
              "
            >
              <S3Image path="/logos/codmon-logo.png" class="codmon-icon mr-2" />
              <span class="font-weight-medium">コドモンから保護者情報を連携</span>
            </v-list-item>
            <v-list-item link :href="`/facilities/${facilityId}/parents/new`">
              <a class="context-menu-link d-flex">
                <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>
                <span class="font-weight-medium">1人ずつ登録</span>
              </a>
            </v-list-item>
            <v-list-item
              class="context-menu-link py-3"
              style="display: inherit"
              link
              :href="`/facilities/${facilityId}/parents/import_requests`"
            >
              <a class="context-menu-link d-flex pb-3">
                <v-icon class="mr-2">mdi-upload</v-icon>
                <v-list-item-title class="font-weight-medium">一括登録</v-list-item-title>
              </a>
              <v-list-item-subtitle class="font-weight-medium no-line-clamp"
                >複数人を一度に登録したいときに、<br />おすすめです。</v-list-item-subtitle
              >
            </v-list-item>
            <v-divider />
            <v-list-subheader class="font-weight-bold">削除</v-list-subheader>
            <v-list-item
              class="context-menu-link py-3"
              style="display: inherit"
              @click="$emit('onClickBulkOperationMode', 'delete')"
            >
              <div class="context-menu-link d-flex pb-3">
                <v-icon class="mr-2">mdi-delete</v-icon>
                <v-list-item-title class="font-weight-medium">選択して削除</v-list-item-title>
              </div>
              <v-list-item-subtitle class="font-weight-medium no-line-clamp"
                >複数人を一度に削除したいときに、<br />おすすめです。</v-list-item-subtitle
              >
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>
    <div class="mr-4">
      <v-menu>
        <template #activator="{ props }">
          <Button v-bind="props" size="large" variant="outlined" class="outlined-button">
            <span class="font-weight-bold fs-16">LINE登録IDの管理</span>
            <v-icon class="pr-1">mdi-menu-down</v-icon>
          </Button>
        </template>
        <v-card class="mx-auto" max-width="300">
          <v-list>
            <v-list-item
              class="context-menu-link py-3"
              style="display: inherit"
              @click="$emit('onClickBulkOperationMode', 'line')"
            >
              <div class="d-flex pb-3">
                <v-icon class="mr-2">mdi-printer</v-icon>
                <v-list-item-title class="font-weight-medium"
                  >LINE登録ID用紙を印刷</v-list-item-title
                >
              </div>
              <v-list-item-subtitle class="no-line-clamp"
                >保護者・子どもを選択して印刷できます。</v-list-item-subtitle
              >
            </v-list-item>
            <v-list-item
              class="context-menu-link py-3"
              style="display: inherit"
              @click="$emit('onClickDownloadLINECsv')"
            >
              <div class="d-flex pb-3">
                <v-icon class="mr-2">mdi-download</v-icon>
                <v-list-item-title class="font-weight-medium"
                  >CSVで一覧をダウンロード</v-list-item-title
                >
              </div>
              <v-list-item-subtitle class="no-line-clamp"
                >登録状況を一覧化した<br />CSVをダウンロードできます。</v-list-item-subtitle
              >
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>
    <Button
      size="large"
      variant="outlined"
      class="font-weight-bold fs-16 outlined-button"
      :href="`/facilities/${facilityId}/classes/batch/edit`"
    >
      一括クラス更新
    </Button>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ComputedRef, computed } from 'vue'
import Button from 'shared/components/Button.vue'
import S3Image from 'shared/components/s_3_image/S3Image.vue'
import { Parent, ParentTotal } from 'pages/parents_page/model'
import { ParentsPageStore } from 'pages/parents_page/parents_page_store'

export default defineComponent({
  components: { Button, S3Image },
  props: {
    parents: {
      type: Array as PropType<Parent[]>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    facilityId: {
      type: Number,
      required: true,
    },
    parentTotal: {
      type: Object as PropType<ParentTotal>,
      required: true,
    },
    startIndex: {
      type: Number,
      required: true,
    },
    endIndex: {
      type: Number,
      required: true,
    },
    isSearching: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'onClickBulkOperationMode',
    'onClickDownloadLINECsv',
    'onClickCodmonConnection',
    'handleGaEvent',
  ],
  setup() {
    const hasCodmonId: ComputedRef<boolean> = computed(() => ParentsPageStore.hasCodmonId)

    return { hasCodmonId }
  },
})
</script>
<style scoped lang="scss">
.codmon-icon {
  width: 1.6rem;
}
.context-menu-link {
  color: inherit;
}
.fs-16 {
  font-size: 16px;
}
.outlined-button {
  line-height: 24px;
  background-color: #fff;
}

.no-line-clamp {
  -webkit-line-clamp: revert;
  white-space: nowrap;
}
</style>
