<template>
  <SimpleLayout>
    <template #headerNav>
      <FacilityHeader> </FacilityHeader>
    </template>

    <template #main>
      <div v-if="isLoading || !school" class="d-flex justify-center">
        <v-progress-circular indeterminate :size="70" color="primary"></v-progress-circular>
      </div>
      <div v-else>
        <v-form ref="facilityForm" v-model="valid">
          <div class="my-16 mx-auto">
            <div class="text-h6 mb-12 text-center">施設情報設定</div>
            <div class="text-subtitle-1 mb-4 text-center">
              {{ description }}
            </div>
            <v-alert v-if="hasMessage" :type="alertType" closable class="multi-line">{{
              messages.join('\n')
            }}</v-alert>
            <v-card class="bg-white mx-auto" width="510">
              <div class="py-13 px-14">
                <div class="pb-6">
                  <div class="pt-4">
                    <v-text-field
                      v-model="school.company_name"
                      :color="textFieldColor"
                      label="法人名"
                      placeholder="法人名を入力してください"
                      :rules="[required('法人名')]"
                      hide-details="auto"
                      :validate-on="true && 'blur'"
                      :readonly="!editing"
                      :disabled="!editing && !school.company_name"
                      :variant="editing ? 'outlined' : undefined"
                    ></v-text-field>
                  </div>
                  <div class="pt-4">
                    <v-text-field
                      v-model="school.school_name"
                      :color="textFieldColor"
                      label="施設名"
                      placeholder="施設名を入力してください"
                      :rules="[required('施設名')]"
                      hide-details="auto"
                      :validate-on="true && 'blur'"
                      :readonly="!editing"
                      :disabled="!editing && !school.school_name"
                      :variant="editing ? 'outlined' : undefined"
                    ></v-text-field>
                  </div>
                  <div class="pt-4">
                    <v-text-field
                      v-model="school.phone_number"
                      :color="textFieldColor"
                      :label="phoneNumberLabel"
                      placeholder="例）09012345678"
                      :rules="[required('電話番号'), rules.phoneNumber]"
                      hide-details="auto"
                      :validate-on="true && 'blur'"
                      :readonly="!editing"
                      :disabled="!editing && !school.phone_number"
                      :variant="editing ? 'outlined' : undefined"
                    ></v-text-field>
                  </div>
                  <div class="pt-4">
                    <v-row justify="center" align-content="center">
                      <v-col>
                        <v-text-field
                          v-model="school.postal_code"
                          :color="textFieldColor"
                          :label="postalCodeLabel"
                          placeholder="例）1234567"
                          :rules="[required('郵便番号'), rules.postalCode]"
                          hide-details="auto"
                          :validate-on="true && 'blur'"
                          :readonly="!editing"
                          :disabled="!editing && !school.postal_code"
                          :variant="editing ? 'outlined' : undefined"
                        ></v-text-field>
                      </v-col>
                      <v-col v-if="editing" class="d-flex align-center">
                        <AddressSearchButton
                          hide-details="true"
                          :postal-code="school.postal_code"
                          @handle-search-result="searchAddress"
                        ></AddressSearchButton>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="pt-4">
                    <div v-if="!editing">
                      <v-text-field
                        v-model="school.prefecture_name"
                        color="black"
                        label="都道府県"
                        hide-details
                        readonly
                        :disabled="!school.prefecture_name"
                      ></v-text-field>
                    </div>
                    <div v-else>
                      <v-select
                        v-model="school.prefecture_id"
                        label="都道府県"
                        hide-details
                        :items="prefectures"
                        item-title="name"
                        item-value="id"
                        variant="outlined"
                      ></v-select>
                    </div>
                  </div>
                  <div class="pt-4">
                    <v-text-field
                      v-model="school.city"
                      :color="textFieldColor"
                      label="市区町村・番地"
                      placeholder="市区町村・番地を入力してください"
                      :rules="[required('市区町村・番地')]"
                      hide-details="auto"
                      :validate-on="true && 'blur'"
                      :readonly="!editing"
                      :disabled="!editing && !school.city"
                      :variant="editing ? 'outlined' : undefined"
                    ></v-text-field>
                  </div>
                  <div v-if="editing || school.building" class="pt-4">
                    <v-text-field
                      v-model="school.building"
                      :color="textFieldColor"
                      label="建物名【任意】"
                      placeholder="建物名を入力してください"
                      hide-details
                      :readonly="!editing"
                      :disabled="!editing && !school.building"
                      :variant="editing ? 'outlined' : undefined"
                    ></v-text-field>
                  </div>
                  <div class="pt-4">
                    <v-text-field
                      v-model="school.responsible_person_name"
                      :color="textFieldColor"
                      label="責任者名"
                      placeholder="施設の責任者の名前を入力してください"
                      :rules="[required('責任者名')]"
                      hide-details="auto"
                      :validate-on="true && 'blur'"
                      :readonly="!editing"
                      :disabled="!editing && !school.responsible_person_name"
                      :variant="editing ? 'outlined' : undefined"
                    ></v-text-field>
                  </div>
                  <div class="pt-4">
                    <v-text-field
                      v-model="school.bank_name"
                      :color="textFieldColor"
                      label="銀行名"
                      placeholder="例）みずほ銀行"
                      :rules="[required('銀行名')]"
                      hide-details="auto"
                      :validate-on="true && 'blur'"
                      :readonly="!editing"
                      :disabled="!editing && !school.bank_name"
                      :variant="editing ? 'outlined' : undefined"
                    ></v-text-field>
                  </div>
                  <div class="pt-4">
                    <v-text-field
                      v-model="school.bank_code"
                      :color="textFieldColor"
                      label="銀行コード"
                      placeholder="例）0001"
                      :rules="[required('銀行コード'), rules.bankCode]"
                      hide-details="auto"
                      :validate-on="true && 'blur'"
                      :readonly="!editing"
                      :disabled="!editing && !school.bank_code"
                      :variant="editing ? 'outlined' : undefined"
                    ></v-text-field>
                  </div>
                  <div class="pt-4">
                    <v-text-field
                      v-model="school.bank_branch_name"
                      :color="textFieldColor"
                      label="支店名"
                      placeholder="例）新宿支店"
                      :rules="[required('支店名')]"
                      hide-details="auto"
                      :validate-on="true && 'blur'"
                      :readonly="!editing"
                      :disabled="!editing && !school.bank_branch_name"
                      :variant="editing ? 'outlined' : undefined"
                    ></v-text-field>
                  </div>
                  <div class="pt-4">
                    <v-text-field
                      v-model="school.bank_branch_code"
                      :color="textFieldColor"
                      label="支店コード"
                      placeholder="例）240"
                      :rules="[required('支店コード'), rules.bankBranchCode]"
                      hide-details="auto"
                      :validate-on="true && 'blur'"
                      :readonly="!editing"
                      :disabled="!editing && !school.bank_branch_code"
                      :variant="editing ? 'outlined' : undefined"
                    ></v-text-field>
                  </div>
                  <div class="pt-4">
                    <div v-if="!editing">
                      <v-text-field
                        v-model="school.bank_branch_type"
                        color="black"
                        label="預金種別"
                        hide-details
                        readonly
                        :disabled="!school.bank_branch_type"
                      ></v-text-field>
                    </div>
                    <div v-else>
                      <v-select
                        v-model="school.bank_branch_type"
                        hide-details
                        label="預金種別"
                        :items="bankBranchTypes"
                        variant="outlined"
                      ></v-select>
                    </div>
                  </div>
                  <div class="pt-4">
                    <v-text-field
                      v-model="school.bank_account_number"
                      :color="textFieldColor"
                      label="口座番号"
                      placeholder="例）1234567"
                      :rules="[required('口座番号'), rules.bankAccountNumber]"
                      hide-details="auto"
                      :validate-on="true && 'blur'"
                      :readonly="!editing"
                      :disabled="!editing && !school.bank_account_number"
                      :variant="editing ? 'outlined' : undefined"
                    ></v-text-field>
                  </div>
                  <div class="pt-4">
                    <v-text-field
                      v-model="school.bank_account_holder_name"
                      :color="textFieldColor"
                      label="銀行口座の名義"
                      placeholder="例）エンペイ タロウ"
                      :rules="[required('銀行口座の名義'), rules.bankAccountHolderName]"
                      hide-details="auto"
                      :validate-on="true && 'blur'"
                      :readonly="!editing"
                      :disabled="!editing && !school.bank_account_holder_name"
                      :variant="editing ? 'outlined' : undefined"
                    ></v-text-field>
                  </div>
                  <div v-if="canShowIpAddress" class="pt-4">
                    <v-text-field
                      v-model="school.allow_ips"
                      :color="textFieldColor"
                      :label="allowIpsLabel"
                      placeholder="例）127.0.0.1,127.0.0.2"
                      hide-details
                      :readonly="!editing"
                      :disabled="!editing && !school.allow_ips"
                      :variant="editing ? 'outlined' : undefined"
                    ></v-text-field>
                  </div>
                </div>
                <v-row justify="center">
                  <Button
                    width="300"
                    height="60"
                    class="font-weight-bold mt-5"
                    size="x-large"
                    :variant="!editing ? 'outlined' : 'elevated'"
                    :disabled="!valid"
                    @click="update"
                  >
                    {{ submitButtonLabel }}
                  </Button>
                </v-row>
              </div>
            </v-card>
          </div>
        </v-form>
      </div>
    </template>
  </SimpleLayout>
</template>

<script setup lang="ts">
import { ref, computed, nextTick } from 'vue'
import { FacilityPageStore } from 'pages/facility_page/facility_page_store'
import { FacilityService } from 'pages/facility_page/services/facility_service'
import AddressSearchButton from 'shared/components/AddressSearchButton.vue'
import { Address } from 'http/modules/address'
import { Prefecture } from 'shared/models'
import SimpleLayout from 'shared/templates/SimpleLayout.vue'
import FacilityHeader from 'shared/components/header/FacilityHeader.vue'
import Button from 'shared/components/Button.vue'
import { MetaService } from 'shared/services/meta_service'

MetaService.addTitle({ mode: 'facility', page: 'facility' })
const prefectures = ref<Prefecture[]>()
const facilityForm = ref<HTMLFormElement>()

const editing = ref(false)
const isSuccess = ref(false)
const valid = ref(false)

FacilityService.loadFacility()

const isLoading = computed(() => FacilityPageStore.isLoading)
const messages = computed(() => FacilityPageStore.messages)
const school = computed(() => FacilityPageStore.school)
const canShowIpAddress = computed(() => school.value.allow_ips !== undefined)
FacilityService.loadPrefectures().then((data: Prefecture[]) => {
  prefectures.value = data
})
const description = computed(() =>
  editing.value ? '施設の詳細情報を登録してください' : '登録されている施設の情報です'
)
const alertType = computed(() => (isSuccess.value ? 'success' : 'warning'))
const hasMessage = computed(() => messages.value && messages.value.length > 0)
const phoneNumberLabel = computed(() => (editing.value ? '電話番号（ハイフン無し）' : '電話番号'))
const postalCodeLabel = computed(() => (editing.value ? '郵便番号（ハイフン無し）' : '郵便番号'))
const allowIpsLabel = computed(() =>
  editing.value ? '許可するIPアドレス【任意】' : '許可するIPアドレス'
)
const bankBranchTypes = ['普通預金', '当座預金']
const submitButtonLabel = computed(() => (editing.value ? '登録する' : '編集する'))

const textFieldColor = computed(() => (editing.value ? 'primary' : 'black'))

const validate = () => facilityForm.value?.validate()

const update = async () => {
  if (!editing.value) FacilityPageStore.clearMessages()
  isSuccess.value = editing.value ? await FacilityService.updateFacility(school.value) : true
  if (isSuccess.value) editing.value = !editing.value
  if (editing.value) validate()
  window.scrollTo(0, 0)
}

const searchAddress = (address: Address) => {
  school.value.prefecture_id = address.prefecture.id
  school.value.prefecture_name = address.prefecture.name
  school.value.city = address.city + address.town
  nextTick(() => validate())
}

// バリデーションのルールで「!editing.value」が`true`(表示モード)かを確認していますが、
// これは施設情報画面を「表示モード」で開いている場合はどのような値であってもバリデーションエラーにしないための処置となります。
const required = (name: string) => (value: string) =>
  !editing.value || !!value.trim() || `${name}を入力してください`

const rules = {
  phoneNumber: (value: string) =>
    !editing.value ||
    /^[0-9]{10,11}$/.test(value) ||
    'ハイフンなしの10桁か11桁の数字を入力してください',
  postalCode: (value: string) =>
    !editing.value || /^[0-9]{7}$/.test(value) || 'ハイフンなしの7桁の数字を入力してください',
  bankCode: (value: string) =>
    !editing.value || /^[0-9]{4}$/.test(value) || '4桁の数字を入力してください',
  bankBranchCode: (value: string) =>
    !editing.value || /^[0-9]{3}$/.test(value) || '3桁の数字を入力してください',
  bankAccountNumber: (value: string) =>
    !editing.value || /^[0-9]{7}$/.test(value) || '7桁の数字を入力してください',
  bankAccountHolderName: (value: string) =>
    !editing.value ||
    /^[ァ-ヶーｦ-ﾟ0-9０-９A-ZＡ-Ｚ().\-/,¥（）．－／，￥「」\x20\u3000]+$/.test(value) ||
    'カナ英数字記号を入力してください',
}
</script>

<style scoped>
/* v-alertを複数行表示にする標準機能がないため */
.multi-line {
  white-space: pre-line;
}
</style>
