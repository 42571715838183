<template>
  <div>
    <div class="d-flex my-6">
      <div
        class="text-highlighted rounded-circle rounded-number px-1 mr-2 d-flex justify-center align-center"
      >
        1
      </div>
      <div class="flex-grow-1">
        <div>
          Excelファイルのテンプレートをダウンロードしてください。<br />
          <span class="text-grey-darken-1"> ※未払いの子どもは出力されません。<br /> </span>
        </div>
        <Button class="mt-3" variant="outlined" @click="$emit('downloadExcel')">
          Excelテンプレートをダウンロード
        </Button>
      </div>
    </div>
    <div class="d-flex my-6 py-2">
      <div
        class="text-highlighted rounded-circle rounded-number mr-2 d-flex justify-center align-center"
      >
        2
      </div>
      <div>Excelファイルを記入してください。（<slot name="sampleEntryDialog" />）</div>
    </div>

    <div class="d-flex my-6 pb-2">
      <div
        class="text-highlighted rounded-circle rounded-number px-1 mr-2 d-flex justify-center align-center"
      >
        3
      </div>
      <div>
        記入済みのExcelファイルを以下の欄から取り込んで 、「CSVに変換」を押してください。<br />
        CSVに変換したファイルがお使いの端末にダウンロードされます。<br />
        <span class="text-grey-darken-1">
          ※ ステップ①でダウンロードしたテンプレートを使用してください。<br />
          ※ 変換前に、Excelファイルのフィルターを解除してください。
        </span>
        <div class="mt-4">
          <ConvertAlertPanel></ConvertAlertPanel>
        </div>
        <v-row class="mt-1">
          <v-col cols="6">
            <v-file-input
              v-model="file"
              color="primary"
              hide-details
              variant="outlined"
              truncate-length="50"
              prepend-icon=""
              chips
              clear-icon="mdi-close"
              label="Excelファイルを選択"
              accept=".xlsx"
            ></v-file-input>
          </v-col>
          <v-col cols="3">
            <Button
              size="x-large"
              variant="outlined"
              :disabled="!file || isConvertingToCsv"
              :loading="isConvertingToCsv"
              @click="
                $eventTracker.trackEvent('click_btn_invoice_register_template_conv', {}),
                  convertToCSV()
              "
            >
              CSVに変換
            </Button>
          </v-col>
        </v-row>
      </div>
    </div>

    <div class="d-flex my-6">
      <div
        class="text-highlighted rounded-circle rounded-number px-1 mr-2 d-flex justify-center align-center"
      >
        4
      </div>
      <div>
        記入済みのCSVファイルを以下の欄から取り込んでください。<br />
        ファイルを取り込み後、「取り込み内容の設定に進む」を押すと次のステップに進みます。<br />
        <span class="text-grey-darken-1"
          >※ファイル名末尾の拡張子が「.csv」のファイルのみ取り込むことができます。</span
        >

        <div class="mt-3">
          <v-file-input
            v-model="importTargetFile"
            color="primary"
            variant="outlined"
            truncate-length="50"
            chips
            label="CSVファイルを選択"
            accept=".csv"
            prepend-icon=""
            clear-icon="mdi-close"
            :rules="fileRules"
          ></v-file-input>
        </div>
      </div>
    </div>

    <div class="d-flex mb-5">
      <div class="rounded-number mr-2"></div>
      <div class="mb-2"></div>
      <Button
        size="large"
        :disabled="isUploading || !isValidFile"
        :loading="isUploading"
        @click="$eventTracker.trackEvent('click_btn_invoice_import_converted_csv', {}), uploadCsv()"
      >
        取り込み内容の設定に進む
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { ImportPanelStore } from '../import_panel_store'
import { CsvUploadService } from '../services/csv_upload_service'
import { ExcelConvertService } from '../services/excel_convert_service'
import ConvertAlertPanel from './ConvertAlertPanel.vue'
import Button from 'shared/components/Button.vue'
import { FileService } from 'shared/services/file_service'

const props = defineProps<{
  facilityId: number
}>()

defineEmits<{
  (event: 'downloadExcel'): void
}>()

const file = computed({
  get: () => ImportPanelStore.uploadedExcel,
  set: (value) => ImportPanelStore.updateUploadedExcel(value),
})

const fileRules = [
  (v: File[]) => {
    if (!v.length) return true
    return FileService.isCsvFile(v[0]) ? true : 'CSVファイルを選択してください'
  },
]
const importTargetFile = computed({
  get: () => ImportPanelStore.importTargetFile,
  set: (value) => {
    ImportPanelStore.updateImportTargetFile(value)
  },
})
const isValidFile = computed(() => {
  if (!importTargetFile.value.length) return false
  return FileService.isCsvFile(importTargetFile.value[0])
})

const isUploading = computed(() => ImportPanelStore.isUploading)

const isConvertingToCsv = computed(() => ImportPanelStore.isConvertingToCsv)

const convertToCSV = () => ExcelConvertService.convertTemplateFileFormat(props.facilityId)

const uploadCsv = () => CsvUploadService.uploadCsv()
</script>
<style scoped>
.rounded-number {
  width: 26px;
  height: 24px;
}
</style>
