import { ConvertToQueryParamsServiceReturn } from 'pages/export_invoice_items_page/model'
import { ExportInvoiceItemsPageStore } from 'pages/export_invoice_items_page/export_invoice_items_page_store'

export class ConvertToQueryParamsService {
  static convertToQueryParams(): ConvertToQueryParamsServiceReturn {
    const fromYearAndMonth = ExportInvoiceItemsPageStore.fromYearAndMonth
    const toYearAndMonth = ExportInvoiceItemsPageStore.toYearAndMonth
    const targetItem = ExportInvoiceItemsPageStore.targetItem
    const invoiceStatuses = ExportInvoiceItemsPageStore.invoiceStatuses
    const includeStatusType = ExportInvoiceItemsPageStore.includeStatusType
    const from = fromYearAndMonth.year + '-' + fromYearAndMonth.month?.toString().padStart(2, '0')
    const to = toYearAndMonth.year + '-' + toYearAndMonth.month?.toString().padStart(2, '0')
    const termType = targetItem === 'paidMonth' ? 'paid_at' : 'target_year_and_month'
    const includeKeyword = includeStatusType === 'include'
    const itemNames = ExportInvoiceItemsPageStore.selectedInvoiceItems
    const facilityIds =
      ExportInvoiceItemsPageStore.selectedFacilityId === 'all'
        ? []
        : [Number(ExportInvoiceItemsPageStore.selectedFacilityId)]

    return {
      from,
      to,
      termType,
      status: invoiceStatuses,
      includeKeyword,
      itemNames,
      facilityIds,
    }
  }
}
