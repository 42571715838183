import { ConvertToQueryParamsService } from 'pages/export_invoice_items_page/services/convert_to_query_params_service'
import { FacilityInvoiceItemsCsvApi } from 'http/modules/facility_invoice_items_csv'
import { RouteService } from 'shared/services/route_service'
import { GroupByDownloadParams } from 'pages/export_invoice_items_page/model'
import { SnackbarService } from 'shared/services/snackbar_service'
import { ScreenLoadingService } from 'shared/services/screen_loading_service'

export class FacilityDownloadInvoiceItemsService {
  /**
   * 施設 そのままダウンロード
   */
  static searchFilteredDownload() {
    const { from, to, termType, status, includeKeyword, itemNames } =
      ConvertToQueryParamsService.convertToQueryParams()
    const facilityId = FacilityDownloadInvoiceItemsService.getTargetFacilityId()

    try {
      FacilityInvoiceItemsCsvApi.index({
        facilityId,
        from,
        to,
        termType,
        itemNames,
        status,
        includeKeyword,
      })
    } catch (error) {
      SnackbarService.open('CSVのダウンロードに失敗しました', 'error')
    } finally {
      ScreenLoadingService.hideLoader()
    }
  }

  /**
   * 施設 集計してダウンロード
   * @param key どの項目で集計するか
   */
  static groupByDownload(key: GroupByDownloadParams['groupBy']) {
    const { from, to, termType, status, includeKeyword, itemNames } =
      ConvertToQueryParamsService.convertToQueryParams()
    const facilityId = FacilityDownloadInvoiceItemsService.getTargetFacilityId()

    try {
      FacilityInvoiceItemsCsvApi.index({
        facilityId,
        from,
        to,
        termType,
        itemNames,
        status,
        includeKeyword,
        groupBy: key,
      })
    } catch (error) {
      SnackbarService.open('CSVのダウンロードに失敗しました', 'error')
    } finally {
      ScreenLoadingService.hideLoader()
    }
  }

  private static getTargetFacilityId(): number {
    return Number(RouteService.getPathParamByIndex(1))
  }
}
