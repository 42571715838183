import { AxiosResponse } from 'axios'
import { HTTP } from 'http/common'
import { OemPartner } from 'shared/models'

export type ShowResponse = OemPartner

export class FacilityOemPartnerApi {
  /**
   * 施設が所属する法人のOEM提携先を返します
   */
  static show(facilityId: number): Promise<AxiosResponse<ShowResponse>> {
    return HTTP.get(`/facilities/${facilityId}/oem_partner`)
  }
}
