import { VuetifyOptions } from 'vuetify/lib/framework.mjs'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { ja } from 'vuetify/locale'
import DayJsAdapter from '@date-io/dayjs'
import jaJp from 'dayjs/locale/ja'

export const vuetifyOptions: VuetifyOptions = {
  components,
  directives,
  defaults: {
    VTextField: {
      color: 'primary',
      variant: 'underlined',
    },
    VCheckbox: {
      color: 'primary',
    },
  },
  locale: {
    locale: 'ja',
    messages: { ja },
  },
  date: {
    adapter: DayJsAdapter,
    locale: {
      ja: jaJp,
    },
    formats: {
      monthAndYear: 'YYYY年MM月',
    },
  },
  theme: {
    defaultTheme: 'preset',
    variations: {
      colors: ['primary'],
      lighten: 1,
      darken: 1, // darkenはアプリでは使用しないが、VuetifyOptionsの型情報では必須なので定義しておく
    },
    themes: {
      preset: {
        dark: false,
        colors: {
          background: '#f4f6f9',
          primary: '#0068b7',
          error: '#ff5252',
          success: '#429585',
          grey: '#787878',
          'surface-variant': '#757575',
        },
      },
    },
  },
}
