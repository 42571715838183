<template>
  <div>
    <div class="header">
      <h1>お支払い履歴</h1>
    </div>
    <section class="section invoices_by_month">
      <div class="section_title">
        <h2>{{ targetYear }}年のお支払い一覧</h2>
        <template v-if="unpaidInvoiceMonths.length > 0">
          <p>未払い月：{{ commaSeparatedUnpaidInvoiceMonths }}</p>
        </template>
      </div>
      <div class="select_year">
        年選択
        <div class="select_wrapper">
          <select v-model="targetYear" @change="changeYear">
            <option v-for="year in betweenYears" :key="year" :value="year">{{ year }}年</option>
          </select>
        </div>
      </div>

      <template v-if="errorMessages.length > 0">
        <div class="errorMessage">
          <ul class="errorMessage_list">
            <template v-for="(errorMessage, i) in errorMessages" :key="i">
              <li>{{ errorMessage }}</li>
            </template>
          </ul>
        </div>
      </template>
      <template v-else>
        <section class="section_invoices">
          <template v-for="(monthlyInvoiceData, i) in invoicesByMonth" :key="i">
            <div class="section_inner">
              <InvoiceList :monthly-invoice-data="monthlyInvoiceData" />
            </div>
          </template>
        </section>
      </template>
    </section>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { visit } from 'shared/utils/history'
import InvoiceList from 'pages/line_invoice_history_page/components/InvoiceList.vue'
import { MonthlyInvoiceData } from 'pages/line_invoice_history_page/model'

const props = defineProps<{
  targetYear: string
  unpaidInvoiceMonths: string[]
  betweenYears: number[]
  errorMessages: string[]
  invoicesByMonth: MonthlyInvoiceData[]
}>()

const targetYear = ref(props.targetYear)

const commaSeparatedUnpaidInvoiceMonths = props.unpaidInvoiceMonths
  .map((month) => `${Number(month)}月`)
  .join('、')

const changeYear = (event: Event) => {
  const targetYear = (event.target as HTMLSelectElement).value
  visit(`/line/invoices?target_year=${targetYear}`)
}
</script>
