<template>
  <div class="mr-6">
    <span class="text-body-1 font-weight-bold mr-4">{{ statusName }}</span>
    <span class="text-body-1 font-weight-bold mr-6">
      残<span
        >&nbsp;<span class="text-h5">{{ total }}</span
        >&nbsp;</span
      >施設</span
    >
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    statusName: String,
    total: Number,
  },
})
</script>
