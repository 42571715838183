<template>
  <div class="py-2 bg-red-lighten-1 text-center">
    <span class="text-white"
      >現在ご利用のブラウザでは、正常に動作しないおそれがあります。<a
        class="text-decoration-underline"
        href="https://www.google.co.jp/chrome/"
        target="_blank"
        >Google Chrome</a
      >をご利用ください。</span
    >
  </div>
</template>
