<template>
  <ApplicationLayout
    :oem-partner-logo-path="oemPartnerLogoPath"
    :error-message="errorMessage"
    :notice-message="noticeMessage"
  >
    <div class="panel panel-normal">
      <div class="panel_inner">
        <form
          id="new_school_account"
          class="new_school_account"
          action="/school_accounts/sign_in"
          accept-charset="UTF-8"
          method="post"
        >
          <input
            type="hidden"
            name="authenticity_token"
            :value="authenticityToken"
            autocomplete="off"
          />
          <div class="sign-Form">
            <div class="sign-Form_item">
              <div class="sign-Form_heading">
                <label for="school_account_email">ID（メールアドレス）</label>
              </div>
              <TextInput
                id="school_account_email"
                :value="email"
                :on-change="(value: string) => (email = value)"
                density="default"
                autofocus
                autocomplete="email"
                placeholder="IDを入力してください"
                type="email"
                name="school_account[email]"
              />
            </div>
            <div class="sign-Form_item">
              <div class="sign-Form_heading">
                <label for="school_account_password">パスワード</label>
              </div>
              <TextInput
                id="school_account_password"
                :value="password"
                :on-change="(value: string) => (password = value)"
                density="default"
                :type="isVisiblePassword ? 'text' : 'password'"
                :append-inner-icon="isVisiblePassword ? 'mdi-eye' : 'mdi-eye-off'"
                :click-icon="handlePasswordVisibility"
                autocomplete="current-password"
                placeholder="パスワードを入力してください"
                name="school_account[password]"
              />
            </div>
          </div>
          <p class="sign-Form_remindText">
            <a href="/school_accounts/password/new">パスワードをお忘れですか？</a>
          </p>
          <p class="sign-Form_buttonArea">
            <input
              type="submit"
              name="commit"
              value="ログイン"
              class="button-md button-normal"
              data-disable-with="ログイン"
            />
          </p>
        </form>
      </div>
    </div>
  </ApplicationLayout>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { MetaService } from 'shared/services/meta_service'
import ApplicationLayout from 'shared/templates/ApplicationLayout.vue'
import TextInput from 'shared/components/TextInput.vue'

MetaService.addTitle({ page: 'signIn' })

defineProps<{
  authenticityToken: string
  oemPartnerLogoPath?: string
  errorMessage?: string | string[]
  noticeMessage?: string | string[]
}>()

const email = ref('')
const password = ref('')
const isVisiblePassword = ref(false)

const handlePasswordVisibility = () => {
  isVisiblePassword.value = !isVisiblePassword.value
}
</script>
