<template>
  <div
    v-show="
      recentParentCvsPaymentFeeStatus.current_month || recentParentCvsPaymentFeeStatus.next_month
    "
  >
    <a :href="ExternalLink.cvsPaymentFeeHelpUrl" class="d-flex ga-1 align-center">
      <template v-if="recentParentCvsPaymentFeeStatus.current_month">
        <p>
          {{
            `コンビニ支払いは別途手数料として +${recentParentCvsPaymentFeeStatus.current_month.amount.toLocaleString()}円 かかります。`
          }}
        </p>
      </template>
      <template v-else-if="recentParentCvsPaymentFeeStatus.next_month">
        <p>
          {{
            `${getFormatDate(
              recentParentCvsPaymentFeeStatus.next_month.applied_at,
              'YYYY年MM月DD日'
            )}以降の請求でコンビニ支払いを選択された場合、別途手数料として${recentParentCvsPaymentFeeStatus.next_month.amount.toLocaleString()}円が発生します`
          }}
        </p>
      </template>
      <v-icon :color="alertColor" size="14" class="icon">mdi-chevron-right</v-icon>
    </a>
  </div>
</template>

<script setup lang="ts">
import { getFormatDate } from 'shared/utils/date'
import ExternalLink from 'shared/consts/external_link'

type CvsPaymentFeeStatus = {
  applied_at: string
  amount: number
}

defineProps<{
  recentParentCvsPaymentFeeStatus: {
    current_month: CvsPaymentFeeStatus | null
    next_month: CvsPaymentFeeStatus | null
  }
}>()

const alertColor = '#D70000'
</script>

<style scoped>
p {
  color: v-bind('alertColor');
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}
</style>
