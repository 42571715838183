<template>
  <div class="d-flex flex-column">
    <label for="line-status-filter" class="font-weight-medium text-body-2 text-grey mb-2"
      >LINE登録状況</label
    >
    <SelectInput
      id="line-status-filter"
      v-model="selectedItem"
      variant="outlined"
      density="compact"
      :items="items"
      hide-details
      item-text="label"
      item-value="value"
      @change="onChange"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import SelectInput from 'shared/components/SelectInput.vue'
import { ParentSearchConditionService } from 'pages/top_page/services/parent_search_condition_service'
import { TopPageStore } from 'pages/top_page/top_page_store'
import { LineStatusFilterValue } from 'pages/top_page/models'

const items = ParentSearchConditionService.getLineFilterOptions()
const selectedItem = computed({
  get: () => TopPageStore.searchCondition.lineStatus,
  set: (value) => ParentSearchConditionService.updateLineStatusCondition(value),
})
const onChange = (value: LineStatusFilterValue) => {
  ParentSearchConditionService.updateLineStatusCondition(value)
}
</script>

<style scoped lang="scss"></style>
