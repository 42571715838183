<template>
  <div>
    <div class="header">
      <h2>送信先メールアドレスを入力してください</h2>
    </div>
    <section class="section section_inner">
      <v-form ref="form" v-model="valid">
        <v-container>
          <v-row>
            <v-col class="ml-1">
              <div class="text-body-2">
                領収書はnoreply@enpay.co.jpから送信されます。<br />
                メールが届かない場合は、迷惑メールフィルター、ドメイン指定受信などの設定をご確認のうえ、noreply@enpay.co.jpからのメール受信を許可してください。
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="ml-1">
              <v-text-field
                v-model="email"
                label="メールアドレス"
                :rules="emailRules"
                variant="outlined"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <div class="d-flex justify-center">
          <!-- GAイベント名は40文字制限のため、GAでは`click_btn_sending_mail_attachment_receip`の様に`t`を1文字削って検索してください -->
          <Button
            :disabled="!valid"
            class="ml-1"
            @click="
              $eventTracker.trackEvent('click_btn_sending_mail_attachment_receip', {}), sendMail()
            "
          >
            領収書を送る
          </Button>
        </div>
      </v-form>
    </section>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import { ReceiptSendService } from '../services/receipt_send_services'
import Button from 'shared/components/Button.vue'
import type { VForm } from 'vuetify/components'

export default defineComponent({
  components: {
    Button,
  },
  props: {
    invoiceId: {
      type: String,
      required: true,
    },
  },
  setup(props, _) {
    // const
    const emailRules: any[] = [
      (v: string) => !!v || 'メールアドレスを入力してください',
      (v: string) =>
        /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/.test(v) ||
        'メールアドレスの形式で入力してください',
    ]

    // data
    const valid = ref(true)
    const email = ref('')

    const form = ref<typeof VForm | null>(null)

    // methods
    const sendMail = () => {
      if (!form.value) return
      form.value.validate()
      ReceiptSendService.sendReceipt(props.invoiceId, encodeURIComponent(email.value))
    }

    return {
      valid,
      email,
      emailRules,
      sendMail,
      form,
    }
  },
})
</script>
