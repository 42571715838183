import { LoadCodmonChildren } from 'pages/parents_page/model'
import { HTTP } from '../common'
import { AxiosResponse } from 'axios'

export type LoadCodmonInvoiceResponseRow = {
  type: string
  message: string
  child?: string
  invoice_item_name?: string
  target_count?: number
}

export type UnconnectedCodmon = {
  id: number
  first_name: string | null
  parent_name: string | null
  errors: string[]
}

export type UnconnectedCodmonMembers = {
  members: UnconnectedCodmon[]
}

export type UnconnectedCodmonResponse = UnconnectedCodmonMembers

export type LoadCodmonInvoiceResponse = {
  data: LoadCodmonInvoiceResponseRow[]
}

type LoadCodmonChildrenResponse = LoadCodmonChildren

export type CreateResponse = {
  id: number
  codomon_id: number
  first_name: string
  parent_last_name: string
  parent_first_name: string
}

export type DeleteResponse = {
  status: string
}

export default {
  loadUnconnectedChildren(facilityId: number): Promise<AxiosResponse<UnconnectedCodmonResponse>> {
    return HTTP.get(`/facilities/${facilityId}/codmon/parents_and_children/unconnected`)
  },

  create(
    facilityId: number,
    parentId: number,
    childId: number,
    codmonId: number
  ): Promise<AxiosResponse<CreateResponse>> {
    return HTTP.post(
      `/facilities/${facilityId}/school_parents/${parentId}/children/${childId}/codmon_connection?codmon_id=${codmonId}`
    )
  },

  delete(
    facilityId: number,
    parentId: number,
    childId: number
  ): Promise<AxiosResponse<DeleteResponse>> {
    return HTTP.delete(
      `/facilities/${facilityId}/school_parents/${parentId}/children/${childId}/codmon_connection`
    )
  },

  loadCodmonInvoices(
    facilityId: number,
    target_year_and_month: string
  ): Promise<LoadCodmonInvoiceResponse> {
    return HTTP.patch(
      `/facilities/${facilityId}/codmon/invoices_and_invoice_items/${target_year_and_month}`
    )
  },

  loadCodmonChildren(facilityId: number): Promise<AxiosResponse<LoadCodmonChildrenResponse>> {
    return HTTP.patch(`/facilities/${facilityId}/codmon/parents_and_children`)
  },
}
