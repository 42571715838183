import { AxiosResponse } from 'axios'
import { HTTP } from 'http/common'
import {
  FacilityIdParams,
  InvoiceItemsApiCommonParams,
  InvoiceItemsResponse,
  PagingParams,
} from 'pages/export_invoice_items_page/model'

type IndexResponse = InvoiceItemsResponse

type Params = InvoiceItemsApiCommonParams & FacilityIdParams['single'] & PagingParams['index']

export class FacilityInvoiceItemsApi {
  /**
   * @param facilityId 施設ID 必須
   * @param from 対象期間 任意
   * 開始年月 yyyy-mm形式
   * @param to 対象期間 任意
   * 終了年月 yyyy-mm形式
   * @param termType 対象期間 任意
   * target_year_and_month, paid_at
   * @param includeKeyword 検索キーワードを含むかどうか 任意
   * デフォルトは true
   * true の場合は OR検索、false の場合は NOT検索 の AND検索
   * @param itemNames 請求内容名 任意
   * カンマ区切りで複数キーワードを指定できる。
   * 例: 延長保育代,おやつ代
   * @param status 請求ステータス 任意
   * デフォルトは 'before_charge', 'unpaid', 'paid' の全てのステータス
   * カンマ区切りで複数指定できる。
   * 複数指定した場合は OR検索 になる。
   * @param page ページ番号 任意
   * ページネーション時に使う。指定しなければ1ページ目を取得します。
   * @param perPage 1ページあたりの件数 任意
   * ページネーション時に使う。指定しなければ50件を取得します。
   */
  static index({
    facilityId,
    from,
    to,
    termType,
    itemNames,
    status,
    includeKeyword,
    page,
    perPage,
  }: Params): Promise<AxiosResponse<IndexResponse>> {
    return HTTP.get(
      `/facilities/${facilityId}/invoice_items?page=${page}&per_page=${perPage}&term_type=${termType}&from=${from}&to=${to}&include_keyword=${includeKeyword}&item_names=${itemNames}&status=${status}`
    )
  }
}
