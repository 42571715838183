import { InvoiceItem, InvoiceItemInput, ItemInputChanges } from '../models'
import { TopPageStore } from '../top_page_store'
import rfdc from 'rfdc'
import { SchoolParentService } from './school_parent_service'
import { InvoiceItemService } from './invoice_item_service'
const clone = rfdc()

const defaultName: string = ''
const defaultCount: string = '1'
const defaultUnitPrice: string = ''

/**
 * InvoiceItemInputに関するロジックを持ちます。
 */
export class InvoiceItemInputService {
  /**
   * 渡されたInvoiceItemInputのname, count, unit_priceが全て初期値かどうかを判定します。
   */
  static isInputDefault(input: InvoiceItemInput): boolean {
    return (
      input.name === defaultName &&
      input.count === defaultCount &&
      input.unit_price === defaultUnitPrice
    )
  }

  /**
   * 初期状態のInvoiceItemInputオブジェクトを生成し返します。
   */
  static constructItemInput(childId?: number): InvoiceItemInput {
    return {
      name: defaultName,
      count: defaultCount,
      unit_price: defaultUnitPrice,
      school_parent_child_id: childId,
      codmon_invoice: false,
      codmon_payment_id: null,
      codmon_statement_id: null,
    }
  }

  /**
   * 初期状態のInvoiceItemInputが3つの配列を生成し返します。
   */
  static construct3ItemInputs(childId?: number): InvoiceItemInput[] {
    return [
      InvoiceItemInputService.constructItemInput(childId),
      InvoiceItemInputService.constructItemInput(childId),
      InvoiceItemInputService.constructItemInput(childId),
    ]
  }

  static constructInputByInvoiceItem(item: InvoiceItem, childId?: number): InvoiceItemInput {
    return {
      name: item.name,
      count: item.count.toString(),
      unit_price: item.unit_price.toString(),
      school_parent_child_id: childId,
      codmon_invoice: 'codmon_invoice' in item ? item.codmon_invoice : false,
      codmon_payment_id: 'codmon_payment_id' in item ? item.codmon_payment_id : null,
      codmon_statement_id: 'codmon_statement_id' in item ? item.codmon_statement_id : null,
    }
  }

  /**
   * 現在のフォームの入力値を取得します。リアクティブなオブジェクトが返ります。
   */
  static getItemInputs(parentId: number, childId?: number): InvoiceItemInput[] {
    return TopPageStore.schoolParentsMap
      .get(parentId)!
      .invoice_item_inputs.filter((input) => input.school_parent_child_id === childId)
  }

  /**
   * 指定したchildIdのindex番目の値が入っているinvoice_item_inputsを、
   * 渡されたitemInputの値に変更します。状態変数school_parentsが書き換えられます
   */
  static updateItemInputs(
    parentId: number,
    childId: number | undefined,
    itemInput: ItemInputChanges,
    index: number
  ): void {
    const keyMap = { name: 'name', count: 'count', unitPrice: 'unit_price' }
    const clonedParents = clone(TopPageStore.schoolParents)
    const targetParent = clonedParents.find((parent) => parent.id === parentId)!
    const targetInput = targetParent.invoice_item_inputs.filter(
      (input) => input.school_parent_child_id === childId
    )[index]

    const key = Object.entries(itemInput)[0][0] as 'name' | 'count' | 'unitPrice'
    targetInput[keyMap[key] as 'name' | 'count' | 'unit_price'] = Object.entries(itemInput)[0][1]
    // 単価の値が空欄の場合はcodmonの請求書項目ではないのでcodmon関連のプロパティを消す
    if (targetInput.codmon_invoice && targetInput.unit_price === '') {
      targetInput.codmon_invoice = false
      targetInput.codmon_payment_id = null
      targetInput.codmon_statement_id = null
    }
    TopPageStore.updateSchoolParents(clonedParents)
  }

  /**
   * InvoiceItemInputの小計を計算するためのメソッドです。
   * count * unitPriceした値を返します。0や負の数が返ることもあります。
   * ただし、countやunitPriceが数値でない場合はundefinedを返します。
   */
  static calculateInputSubtotal(count: string, unitPrice: string): number | undefined {
    // Number('')が0を返すため空文字を避ける
    // また、parseInt('123hoge')が123を返すため、Number()で一度数値に変換してからparseIntする
    const countNum = count === '' ? NaN : parseInt(String(Number(count)), 10)
    const unitPriceNum = unitPrice === '' ? NaN : parseInt(String(Number(unitPrice)), 10)
    if (isNaN(countNum) || isNaN(unitPriceNum)) {
      return undefined
    }
    return InvoiceItemService.calculateSubtotal(countNum, unitPriceNum)
  }

  /**
   * 指定したchildIdの請求書フォームの合計金額を計算し返します。不正な請求書項目は0円として計算されます。
   * 負の数が返ることがあります。未入力の場合はundefinedが返ります。
   */
  static calculateInputTotalAmountOfChild(parentId: number, childId?: number): number | undefined {
    return InvoiceItemInputService.getItemInputs(parentId, childId).reduce(
      (amount: number | undefined, input) => {
        const subtotal = InvoiceItemInputService.calculateInputSubtotal(
          input.count,
          input.unit_price
        )
        if (amount === undefined) return subtotal
        return amount + (subtotal ?? 0)
      },
      undefined
    )
  }

  /**
   * 入力フォームの合計金額を計算します。子供ごとに小計をとり、正の数のみ合計して算出します。
   * 全て未入力の場合にはundefinedが返ります。
   */
  static calculateInputTotalAmountByParentId(parentId: number): number | undefined {
    const parent = SchoolParentService.getParentById(parentId)
    if (!parent) return undefined
    return [undefined, ...parent.school_parent_children.map((child) => child.id)].reduce(
      (amount: number | undefined, childId) => {
        const amountOfChild = InvoiceItemInputService.calculateInputTotalAmountOfChild(
          parentId,
          childId
        )
        if (amountOfChild === undefined || amountOfChild < 0) return amount
        return amountOfChild + (amount ?? 0)
      },
      undefined
    )
  }

  /**
   * Parentに、指定したchildIdで請求書項目の入力欄を1つ増やす。
   */
  static addItemInputField(parentId: number, childId?: number): void {
    const clonedParents = clone(TopPageStore.schoolParents)
    clonedParents
      .find((parent) => parent.id === parentId)!
      .invoice_item_inputs.push({
        name: defaultName,
        count: defaultCount,
        unit_price: defaultUnitPrice,
        school_parent_child_id: childId,
        codmon_invoice: false,
        codmon_payment_id: null,
        codmon_statement_id: null,
      })
    TopPageStore.updateSchoolParents(clonedParents)
  }
}
