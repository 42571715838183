<template>
  <div>
    <v-card flat class="d-flex">
      <div style="width: 100%">
        <div class="pt-4 px-4">
          <FacilitySummaryStatus
            :unpaid-payer-count="unpaidPayerCount"
            :is-locked-invoice="isLockedInvoice"
            :has-before-charge="hasBeforeCharge"
          />
        </div>
        <v-card-title class="text-h5 font-weight-bold pt-2">{{ facilityName }}</v-card-title>
        <v-card-text class="pb-5" style="white-space: nowrap">
          <div class="d-flex align-end">
            <div class="mr-6">
              <span class="mr-4">請求金額</span>
              <span
                ><span class="text-h5">{{ displayAllSubtotal }}</span
                >&nbsp;円</span
              >
              <template v-if="displayUnpaidSubtotal !== '0'">
                <span class="text-red">（未払い&nbsp;{{ displayUnpaidSubtotal }}&nbsp;円）</span>
              </template>
            </div>
            <div>
              <span class="mr-4">対象者</span>
              <span
                ><span class="text-h5">{{ displayAllPayerCount }}</span
                >&nbsp;人</span
              >
              <template v-if="displayUnpaidPayerCount !== '0'">
                <span class="text-red">（未払い&nbsp;{{ displayUnpaidPayerCount }}&nbsp;人）</span>
              </template>
            </div>
            <v-spacer />
          </div>
        </v-card-text>
      </div>
      <div class="text-right d-inline-grid" style="width: 100%">
        <div class="pt-4 px-4">
          <template v-if="isLockedInvoice">
            <InvoiceUnlockButton @click="onClickUnlockButton" />
          </template>
          <template v-else>
            <InvoiceLockButton @click="onClickLockButton" />
          </template>
        </div>
        <v-card-text class="pb-5 align-self-end">
          <template v-if="!!lastSentDate">
            <span class="text-body-2 text-grey">最終請求日： {{ formatDate(lastSentDate) }}</span>
          </template>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent } from 'vue'
import FacilitySummaryStatus from './components/FacilitySummaryStatus.vue'
import InvoiceLockButton from './components/InvoiceLockButton.vue'
import InvoiceUnlockButton from './components/InvoiceUnlockButton.vue'
import dayjs from 'dayjs'

export default defineComponent({
  components: { FacilitySummaryStatus, InvoiceLockButton, InvoiceUnlockButton },
  props: {
    facilityName: { type: String, required: true },
    hasBeforeCharge: { type: Boolean, required: true },
    paidSubtotal: { type: Number, required: true },
    unpaidSubtotal: { type: Number, required: true },
    paidPayerCount: { type: Number, required: true },
    unpaidPayerCount: { type: Number, required: true },
    lastSentDate: { type: String, default: () => '' },
    isLockedInvoice: { type: Boolean, required: true },
  },
  emits: ['lockInvoices', 'unlockInvoices'],
  setup(props, context) {
    const separateWithComma = (value: number): string => value.toLocaleString('ja-Jp')

    const displayAllSubtotal = computed(() =>
      separateWithComma(props.paidSubtotal + props.unpaidSubtotal)
    )
    const displayUnpaidSubtotal = computed(() => separateWithComma(props.unpaidSubtotal))
    const displayAllPayerCount = computed(() =>
      separateWithComma(props.paidPayerCount + props.unpaidPayerCount)
    )
    const displayUnpaidPayerCount = computed(() => separateWithComma(props.unpaidPayerCount))

    const onClickLockButton = () => context.emit('lockInvoices')
    const onClickUnlockButton = () => context.emit('unlockInvoices')
    const formatDate = (dateString: string) =>
      dateString ? dayjs(dateString).format('YYYY年MM月DD日') : ''

    return {
      displayAllSubtotal,
      displayUnpaidSubtotal,
      displayAllPayerCount,
      displayUnpaidPayerCount,
      onClickLockButton,
      onClickUnlockButton,
      formatDate,
    }
  },
})
</script>
<style scoped>
.d-inline-grid {
  display: inline-grid;
}
</style>
