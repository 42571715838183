import { School } from 'http/modules/facility'
import { reactive } from 'vue'

type FacilityPageState = {
  isLoading: boolean
  messages: String[]
  school: School
}

const state = reactive<FacilityPageState>({
  isLoading: false,
  messages: [],
  school: {
    codmon_id: null,
    created_at: '',

    company_name: '',
    corporation_name: '',
    school_name: '',
    phone_number: '',

    postal_code: '',
    city: '',
    building: '',
    responsible_person_name: '',
    bank_name: '',
    bank_code: '',
    bank_branch_name: '',
    bank_branch_code: '',
    bank_branch_type: '',
    bank_account_number: '',
    bank_account_holder_name: '',
    allow_ips: undefined,
    prefecture_id: undefined,
    prefecture_name: '',
    locked_target_year_and_months: [],
    can_use_enpay_wallet: false,
    parent_convenience_store_fee_apply_status: {
      current_month: null,
      next_month: null,
    },
  },
})

export class FacilityPageStore {
  static get isLoading(): boolean {
    return state.isLoading
  }

  static updateIsLoading(isLoading: boolean): void {
    state.isLoading = isLoading
  }

  static updateMessages(messages: String[]): void {
    state.messages = messages
  }

  static clearMessages(): void {
    state.messages = []
  }

  static get messages(): String[] {
    return state.messages
  }

  static get school(): School {
    return state.school
  }

  static updateFacility(school: School): void {
    state.school = school
  }
}
