<template>
  <v-card class="mx-auto" flat>
    <div v-if="isLoading">
      <v-skeleton-loader type="card, list-item@4"></v-skeleton-loader>
    </div>
    <template v-else>
      <v-card-text>
        <div class="d-flex flex-column align-center">
          <div class="text-h6 font-weight-bold mb-2">振込合計額</div>
          <div class="text-h4 mb-4">{{ commaSeparate(summary.transfer_amount) }} 円</div>
        </div>
        <div
          v-for="field in transferSummaryFields.filter((f) => !f.condition || f.condition(summary))"
          :key="field.key"
          class="d-flex justify-space-between mb-2"
        >
          <div>{{ field.label }}</div>
          <div>{{ commaSeparate(summary[field.key]) }} 円</div>
        </div>
      </v-card-text>
    </template>
  </v-card>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue'
import { FilterService } from 'shared/services/filter_service'
import { TransferPageStore } from '../transfer_page_store'
import { CorporationsTransferSummary } from 'http/modules/corporations_transfer_summary'

export default defineComponent({
  setup() {
    const transferSummaryFields: [
      {
        key: keyof CorporationsTransferSummary
        label: string
        condition?: (summary: CorporationsTransferSummary) => boolean
      },
    ] = [
      { key: 'collect_total', label: '集金合計' },
      { key: 'settlement_fee', label: '決済手数料' },
      { key: 'enpay_service_charge', label: 'サービス利用料' },
      {
        key: 'convenience_store_fee_total',
        label: 'コンビニ支払い手数料',
        condition: (summary) => summary.convenience_store_fee_applied,
      },
      {
        key: 'transfer_fee_total',
        label: '振込手数料',
        condition: (summary) => summary.transfer_fee_applied,
      },
      { key: 'tax', label: '消費税' },
      { key: 'cumulative_unpaid_amount', label: '累積未精算額' },
    ]
    const summary = computed(() => TransferPageStore.corporationsTransferSummary)
    const isLoading = computed(() => TransferPageStore.isTransferSummaryLoading)

    const commaSeparate = (amount: number) => {
      if (amount === undefined) {
        return '---'
      }
      return FilterService.commaSeparate(amount)
    }
    return { transferSummaryFields, summary, isLoading, commaSeparate }
  },
})
</script>
