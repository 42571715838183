<template>
  <div>
    <div v-if="isLoading">
      <v-card v-for="i in 10" :key="i" class="mb-4">
        <v-skeleton-loader type="card, list-item"></v-skeleton-loader>
      </v-card>
    </div>
    <template v-else>
      <div
        v-if="facilitySummaries.length === 0"
        class="d-flex justify-center align-center flex-column"
      >
        <div class="text-h5 text-grey-darken-2 mt-8">検索結果は0件です</div>
        <v-icon color="lighten-4" class="no-data-icon">mdi-magnify</v-icon>
      </div>
      <template v-else>
        <FacilitySummaryCard
          v-for="facilitySummary in facilitySummaries"
          :key="facilitySummary.facility_id"
          :facility-name="facilitySummary.facility_name"
          :has-before-charge="facilitySummary.has_before_charge"
          :paid-subtotal="facilitySummary.subtotal.paid"
          :unpaid-subtotal="facilitySummary.subtotal.unpaid"
          :paid-payer-count="facilitySummary.payer_count.paid"
          :unpaid-payer-count="facilitySummary.payer_count.unpaid"
          :is-locked-invoice="facilitySummary.is_locked"
          :last-sent-date="facilitySummary.last_sent_date"
          class="mb-1"
          @lock-invoices="lockInvoices(facilitySummary.facility_id, facilitySummary.facility_name)"
          @unlock-invoices="
            unlockInvoices(facilitySummary.facility_id, facilitySummary.facility_name)
          "
        ></FacilitySummaryCard>
        <InfiniteLoading :identifier="identifier" @infinite="infiniteHandler">
          <template #spinner>
            <div>
              <div class="d-flex justify-center align-center infinite-loading-circlar-wrapper">
                <v-progress-circular indeterminate :size="70" color="primary"></v-progress-circular>
              </div>
            </div>
          </template>
          <template #no-more>
            <!-- デフォルトメッセージを表示させないため -->
            <div></div>
          </template>
          <template #no-results>
            <!-- デフォルトメッセージを表示させないため -->
            <div></div>
          </template>
        </InfiniteLoading>
      </template>
    </template>
  </div>
</template>
<script lang="ts">
import { computed, ComputedRef, defineComponent } from 'vue'
import { InvoiceSummary } from 'http/modules/corporations_invoices'
import { InvoiceManagementPageStore } from 'pages/invoice_management_page/invoice_management_page_store'
import FacilitySummaryCard from './facility_summary_card/FacilitySummaryCard.vue'
import InfiniteLoading from 'vue-infinite-loading'
import { InvoiceManagementService } from '../../services/invoice_management_service'
import { InvoiceLockService } from '../../services/invoice_lock_service'

export default defineComponent({
  components: { FacilitySummaryCard, InfiniteLoading },
  setup() {
    const facilitySummaries: ComputedRef<InvoiceSummary[]> = computed(
      () => InvoiceManagementPageStore.invoiceSummaries
    )
    const isLoading = computed(() => InvoiceManagementPageStore.isInvoiceSummariesLoading)

    // TODO: 共通化。FacilityAccountingSummaryListと実装が被っている
    const identifier = computed(() => InvoiceManagementPageStore.infiniteLoadingIdentifier)
    const infiniteHandler = ($state: any) => InvoiceManagementService.handleInfiniteLoad($state)

    const lockInvoices = (facilityId: number, facilityName: string) =>
      InvoiceLockService.lockInvoicesByFacility(facilityId, facilityName)

    const unlockInvoices = (facilityId: number, facilityName: string) =>
      InvoiceLockService.unlockInvoicesByFacility(facilityId, facilityName)
    return {
      facilitySummaries,
      isLoading,
      identifier,
      infiniteHandler,
      lockInvoices,
      unlockInvoices,
    }
  },
})
</script>
<style lang="scss" scoped>
.loading-circular-wrapper {
  height: 300px;
}
.infinite-loading-circlar-wrapper {
  height: 120px;
}
.no-data-icon {
  zoom: 6;
  color: #bbb !important;
}
</style>
