<template>
  <v-card class="card pa-0 w-100">
    <div class="pa-6">
      <div class="content-text pb-6">一括登録ファイルを登録しました。</div>
      <Button size="large" width="223px" :href="backPath"> 保護者一覧へ戻る </Button>
    </div>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Button from 'shared/components/Button.vue'

export default defineComponent({
  components: {
    Button,
  },
  props: {
    backPath: {
      type: String,
      required: true,
    },
    isDeprecatedBrowser: {
      type: Boolean,
      required: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.content-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.card {
  border-radius: 10px;
}
</style>
