import { TransferStatement } from 'shared/models'
import { HTTP } from 'http/common'
import { AxiosResponse } from 'axios'
import { TransferStatementHistory } from 'pages/transfer_statement_print_page/model'

export type IndexResponse = TransferStatementHistory[]

export type ShowResponse = {
  transfer_statement: TransferStatement
  start_calc_paid_date: string
  end_calc_paid_date: string
}

export class AdminTransferStatementHistoryApi {
  /**
   * @param monthlyTransferId
   */
  static index(monthlyTransferId: number): Promise<AxiosResponse<IndexResponse>> {
    return HTTP.get(`/admin/transfer_statements/${monthlyTransferId}/histories`)
  }

  /**
   * @param monthlyTransferId
   * @param historyId
   */
  static show(monthlyTransferId: number, historyId: number): Promise<AxiosResponse<ShowResponse>> {
    return HTTP.get(`/admin/transfer_statements/${monthlyTransferId}/histories/${historyId}`)
  }
}
