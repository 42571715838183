<template>
  <AppFrame :has-browser-alert="false">
    <template v-if="error">
      <div class="invoice-payment-methods">
        <h2 class="header-title">{{ error.error }}</h2>
        <p>{{ error.error_message }}</p>
      </div>
    </template>
    <template v-else>
      <div class="invoice-payment-methods">
        <h2 class="header-title">支払い方法の選択</h2>
        <a
          data-testid="credit_card_payment_url"
          :href="creditcardPaymentUrl"
          class="choose-payment-method"
        >
          <h3 class="choose-payment-method-title">クレジットカード</h3>
          <div class="choose-payment-method_creditcard"></div>
        </a>
        <a data-testid="cvs_payment_url" :href="cvsPaymentUrl" class="choose-payment-method">
          <h3 class="choose-payment-method-title">コンビニ</h3>
          <div class="choose-payment-method_cvs"></div>
        </a>
        <CvsPaymentFeeAlert
          :recent-parent-cvs-payment-fee-status="recentParentCvsFeeApplyStatus"
          class="cvs-payment-fee-alert"
        />
      </div>
    </template>
  </AppFrame>
</template>
<script setup lang="ts">
import AppFrame from 'shared/components/AppFrame.vue'
import CvsPaymentFeeAlert from 'shared/components/cvs_payment_fee_alert/CvsPaymentFeeAlert.vue'
import { CvsPaymentFeeStatus } from 'pages/line_invoice_history_page/model'

const props = defineProps<{
  errorProp?: {
    error: string
    error_message: string
  }
  urls: {
    creditcard_payment_url: string
    cvs_payment_url: string
  }
  recentParentCvsFeeApplyStatus: {
    current_month: CvsPaymentFeeStatus | null
    next_month: CvsPaymentFeeStatus | null
  }
}>()

const error = props.errorProp
const creditcardPaymentUrl = props.urls?.creditcard_payment_url
const cvsPaymentUrl = props.urls?.cvs_payment_url
</script>

<style scoped>
.cvs-payment-fee-alert {
  margin: 8px 0 4px;
}
</style>
