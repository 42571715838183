import { InvoiceManagementPageStore } from '../invoice_management_page_store'
import { CorporationApi } from 'http/modules/corporation'
import { CorporationsInvoicesApi, IndexResponseData } from 'http/modules/corporations_invoices'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { SnackbarService } from 'shared/services/snackbar_service'
import { InvoiceLockService } from './invoice_lock_service'
import { StateChanger } from 'vue-infinite-loading'
import { CorporationsAccountSettingApi } from 'http/modules/corporations_account_setting'

dayjs.extend(utc)
dayjs.extend(timezone)

export class InvoiceManagementService {
  static async loadInitialData() {
    await InvoiceManagementService.loadCorporationInfo()

    const initialTargetMonth = dayjs()
      .tz('Asia/Tokyo')
      .add(
        await (async () => {
          const value = (await CorporationsAccountSettingApi.show()).get(
            'invoice_page_initial_target_month'
          )
          if (value === 'last_month') return -1
          if (value === 'this_month') return 0
          return +1 // 'next_month'
        })(),
        'month'
      )
    InvoiceManagementPageStore.updateSummaryTargetYearMonth(initialTargetMonth)
    InvoiceManagementPageStore.updateSearchCursor(undefined)
    InvoiceManagementPageStore.updateSearchFacilityName('')
    InvoiceManagementPageStore.updateCollectionProgress('all')
    await InvoiceManagementService.loadInvoiceSummaries()
  }

  private static async loadCorporationInfo() {
    const apiResponse = await CorporationApi.self()
    // 請求日は振込予定日より一ヶ月前なので、選択可能月の範囲を一ヶ月前に広げる
    InvoiceManagementPageStore.updateFirstSelectableYearMonth(
      dayjs(apiResponse.data.oldest_facility_transferred_at).subtract(1, 'month').startOf('month')
    )
  }

  /**
   * 以下のように動作します。
   * 1. storeにあるsearchCursorを空に
   * 2. 検索し、結果を返却
   */
  static async loadInvoiceSummariesFirstPage(): Promise<void> {
    InvoiceManagementPageStore.updateSearchCursor(undefined)
    await InvoiceManagementService.loadInvoiceSummaries()
  }

  static async clearSearchConditions(): Promise<void> {
    InvoiceManagementPageStore.updateSearchFacilityName('')
    InvoiceManagementPageStore.updateCollectionProgress('all')
    InvoiceManagementPageStore.updateLockedStatusCondition('all')
    InvoiceManagementPageStore.updateHasBeforeChargeCondition(false)
    await InvoiceManagementService.loadInvoiceSummariesFirstPage()
  }

  /**
   * storeに持っている年月、検索のカーソル、検索文字列(施設名)、集金状況を使って検索し、
   * 返却されたリストと検索のカーソルをstoreに保持させます。
   */
  private static async loadInvoiceSummaries(): Promise<void> {
    InvoiceManagementPageStore.updateIsInvoiceSummariesLoading(true)
    try {
      const response = await InvoiceManagementService.searchInvoiceSummaries()
      InvoiceManagementPageStore.updateInvoiceSummaries(response.hits)
      InvoiceManagementPageStore.updateSearchCursor(response.next_cursor)

      InvoiceLockService.loadCorporationsFacilitySummary()
    } catch (error) {
      SnackbarService.open('請求サマリーの取得に失敗しました', 'error')
    } finally {
      InvoiceManagementPageStore.updateIsInvoiceSummariesLoading(false)
    }
  }

  /**
   * storeに持っている年月、検索のカーソル、検索文字列(施設名)、集金状況を使って検索し、
   * その結果を返します。
   */
  private static async searchInvoiceSummaries(): Promise<IndexResponseData> {
    const response = (
      await CorporationsInvoicesApi.index(
        InvoiceManagementPageStore.summaryTargetYearMonth.format('YYYY-MM'),
        InvoiceManagementPageStore.searchCursor,
        InvoiceManagementPageStore.searchFacilityName,
        InvoiceManagementPageStore.collectionProgress,
        InvoiceManagementPageStore.lockedStatusCondition,
        InvoiceManagementPageStore.hasBeforeChargeCondition ? 'before_charge' : undefined
      )
    ).data
    return response
  }

  /**
   * infiniteLoadの動きをコントロールします。
   * TODO: 共通化。pages/transfer_page/services/transfer_service.tsと実装が被っている。
   */
  static async handleInfiniteLoad(stateChanger: StateChanger) {
    if (InvoiceManagementPageStore.searchCursor === 0) {
      stateChanger.complete()
      return
    }
    const searchResult = await InvoiceManagementService.searchInvoiceSummaries()
    InvoiceManagementPageStore.updateSearchCursor(searchResult.next_cursor)
    if (searchResult.hits.length !== 0) {
      InvoiceManagementPageStore.updateInvoiceSummaries([
        ...InvoiceManagementPageStore.invoiceSummaries,
        ...searchResult.hits,
      ])
      stateChanger.loaded()
    } else {
      stateChanger.complete()
    }
  }
}
