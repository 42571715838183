<template>
  <section class="invoice-detail">
    <h1 class="invoice-detail-title">
      {{ invoiceDetail.target_year }} 年 {{ invoiceDetail.target_month }} 月 ご請求明細
    </h1>
    <section v-if="isPaidInvoice && invoiceDetail.is_cvs_payment_fee_target" class="title">
      <p>コンビニ支払い手数料</p>
      <p>{{ invoiceDetail.cvs_payment_fee_amount.toLocaleString() }} 円</p>
    </section>
    <template v-if="invoiceDetail.invoice_items_has_no_child.length > 0">
      <div>
        <div class="title">
          <span />
          <span>{{ invoiceDetail.total }}</span>
        </div>
        <table>
          <tbody>
            <tr>
              <th class="content">内容</th>
              <th class="count">数量</th>
              <th class="unit_price">単価</th>
              <th class="amount">金額</th>
            </tr>
            <template
              v-for="invoice_item in invoiceDetail.invoice_items_has_no_child"
              :key="invoice_item.id"
            >
              <tr>
                <td class="content">{{ invoice_item.name }}</td>
                <td class="count">{{ invoice_item.count }}</td>
                <td class="unit_price">{{ invoice_item.unit_price }}</td>
                <td class="amount">{{ invoice_item.subtotal }} 円</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </template>
    <template
      v-for="list_item in invoiceDetail.children_with_invoice_items"
      :key="list_item.child_id"
    >
      <div class="invoice_list">
        <div class="title">
          <span>{{ list_item.first_name + ' さん' }}</span>
          <span>{{ list_item.total }} 円</span>
        </div>
        <table>
          <tbody>
            <tr>
              <th class="content">内容</th>
              <th class="count">数量</th>
              <th class="unit_price">単価</th>
              <th class="amount">金額</th>
            </tr>
            <template v-for="invoice_item in list_item.invoice_items" :key="invoice_item.id">
              <tr>
                <td class="content">{{ invoice_item.name }}</td>
                <td class="count">{{ invoice_item.count }}</td>
                <td class="unit_price">{{ invoice_item.unit_price }}</td>
                <td class="amount">{{ invoice_item.subtotal }} 円</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </template>
    <template v-if="paypayCampaign.within_paypay_campaign">
      <div class="paypay_banner">
        <a :href="paypayCampaign.url">
          <image :src="paypayCampaign.banner_url" width="200" />
        </a>
      </div>
    </template>
  </section>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { InvoiceStatus } from 'shared/models'

type LineInvoicePageProps = {
  invoiceDetail: {
    target_year: number
    target_month: number
    total: string
    status: InvoiceStatus
    is_cvs_payment_fee_target: boolean
    cvs_payment_fee_amount: number
    invoice_items_has_no_child: {
      id: number
      name: string
      count: string
      unit_price: string
      subtotal: string
    }[]
    children_with_invoice_items: {
      child_id: number
      first_name: string
      total: string
      invoice_items: {
        id: number
        name: string
        count: string
        unit_price: string
        subtotal: string
      }[]
    }[]
  }
  paypayCampaign: {
    within_paypay_campaign: boolean
    url: string
    banner_url: string
  }
}

const props = defineProps<LineInvoicePageProps>()
const isPaidInvoice = computed(() => props.invoiceDetail.status === 'paid')
</script>

<style scoped lang="scss">
@import '../../../assets/stylesheets/design/variables';

.paypay_banner {
  text-align: center;
}

.invoice-detail {
  padding: 24px 16px;
  display: grid;
  row-gap: 24px;
}

.invoice-detail-title {
  color: $color-main;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
}

table {
  margin-top: 12px;
  width: 100%;
  border-collapse: collapse;
  padding: 0 16px;
  font-weight: 500;
  font-size: 14px;
  color: #787878;
}

table tr {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  column-gap: 8px;
  width: 100%;
  border-top: 1px solid #e2e2e2;
  padding-block: 8px;
}

table tr:last-child {
  border-bottom: 1px solid #e2e2e2;
}

.content {
  text-align: left;
  padding-left: 16px;
}

.count,
.unit_price,
.amount {
  text-align: right;
}
</style>
