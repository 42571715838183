import { InvoiceStatus } from 'shared/models'
import { HTTP } from '../common'
import { AxiosResponse } from 'axios'

export type InvoiceSummary = {
  facility_id: number
  facility_name: string
  subtotal: {
    paid: number
    unpaid: number
  }
  payer_count: {
    paid: number
    unpaid: number
  }
  last_sent_date: string
  is_locked: boolean
  has_before_charge: boolean
}

export type InvoiceSummaryToUpdate = Partial<Omit<InvoiceSummary, 'facility_id'>> &
  Pick<InvoiceSummary, 'facility_id'>

export type IndexResponseData = {
  hits: InvoiceSummary[]
  next_cursor: number
}

export type CollectionProgressCondition = 'all' | 'completed' | 'not_completed'
export type LockedStatusCondition = 'all' | 'locked' | 'not_locked'
export type InvoiceStatusCondition = InvoiceStatus

export class CorporationsInvoicesApi {
  /**
   * 条件に合致する施設リストを取得。
   * @param targetYearAndMonth
   * @param cursor
   * @param facilityName
   * @param collectionProgress
   * @param lockedStatusCondition
   * @param invoiceStatus ORではなくAND。invoiceStatusを指定すると、そのstatusのinvoiceを持つ施設のみがヒットする。
   * @param perPage
   * @returns
   */
  static index(
    targetYearAndMonth: string,
    cursor?: number,
    facilityName?: string,
    collectionProgress?: CollectionProgressCondition,
    lockedStatusCondition?: LockedStatusCondition,
    invoiceStatus?: InvoiceStatusCondition,
    perPage?: number
  ): Promise<AxiosResponse<IndexResponseData>> {
    return HTTP.get('/corporations/me/invoices', {
      params: {
        target_year_and_month: targetYearAndMonth,
        cursor: cursor ?? '',
        facility_name: facilityName ?? '',
        collection_progress: collectionProgress ?? '',
        locked_status: lockedStatusCondition ?? '',
        invoice_status: invoiceStatus ?? '',
        per_page: perPage ?? '',
      },
    })
  }
}
