import { ClassApi } from 'http/modules/class'
import { SnackbarService } from 'shared/services/snackbar_service'
import { RouteService } from 'shared/services/route_service'
import { ParentsEditPageStore } from 'pages/parents_page/parent_edit/parent_edit_page_store'

export class ClassManagementService {
  static async loadClasses(): Promise<void> {
    try {
      const schoolClasses = (await ClassApi.index(ClassManagementService.getTargetFacilityId()))
        .data.school_classes
      ParentsEditPageStore.updateSchoolClasses(schoolClasses)
    } catch {
      SnackbarService.open('クラス情報の読込に失敗しました', 'error', {
        variant: 'flat',
        showIcon: true,
        location: 'bottom right',
      })
    }
  }

  static getTargetFacilityId() {
    return Number(RouteService.getPathParamByIndex(1))
  }
}
