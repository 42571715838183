import { EditableParent, InputConfirmationError } from 'pages/parents_page/model'
import { groupBy } from 'shared/utils/groupBy'
import { DialogService } from 'shared/services/dialog_service'
import DuplicatedParentsOrChildrenMessage from 'pages/parents_page/areas/modal_message/DuplicatedParentsOrChildrenMessage.vue'
import DuplicatedParentsMessage from 'pages/parents_page/areas/modal_message/DuplicatedParentsMessage.vue'

type HandleDuplicateWarn = {
  warn: InputConfirmationError['errors']['warn'][number][]
  execute: () => void
  parents: EditableParent[]
}

export class FormSubmittingService {
  /**
   *  FacilityParentApi.input_confirmationへのリクエスト後、重複エラーが発生した際の処理
   *  @params parents 登録する親の情報
   */
  static groupingWarns = (warns: InputConfirmationError['errors']['warn']) => {
    const duplicateNamesOnInputs: InputConfirmationError['errors']['warn'] = []
    const duplicateParentAndChildNameOnDb: InputConfirmationError['errors']['warn'] = []
    const duplicateParentNamesBetweenNewRecordAndSavedRecordOnInputs: InputConfirmationError['errors']['warn'] =
      []
    const duplicateParentNamesBetweenNewRecordAndSavedRecordOnDb: InputConfirmationError['errors']['warn'] =
      []

    warns.forEach((warn) => {
      switch (warn.error_type) {
        case 'duplicate_names_on_inputs':
          duplicateNamesOnInputs.push(warn)
          break
        case 'duplicate_parent_and_child_name_on_db':
          duplicateParentAndChildNameOnDb.push(warn)
          break
        case 'duplicate_parent_names_between_new_record_and_saved_record_on_inputs':
          duplicateParentNamesBetweenNewRecordAndSavedRecordOnInputs.push(warn)
          break
        case 'duplicate_parent_names_between_new_record_and_saved_record_on_db':
          duplicateParentNamesBetweenNewRecordAndSavedRecordOnDb.push(warn)
          break
        default:
      }
    })

    return {
      duplicateNamesOnInputs,
      duplicateParentAndChildNameOnDb,
      duplicateParentNamesBetweenNewRecordAndSavedRecordOnInputs,
      duplicateParentNamesBetweenNewRecordAndSavedRecordOnDb,
    }
  }

  /**
   *  重複がある場合の警告表示
   *  A: 保護者・子どもの重複がある場合
   *  Aの方が重要なエラーのため、優先的に表示させる
   */
  static handleDuplicateParentAndChildNameWarns({
    warn,
    execute,
    parents,
  }: HandleDuplicateWarn): void {
    // 保護者の姓名、resource_nameを複合キーとして使用して、重複している行をグループ化する
    const duplicatedErrors = Object.values(
      groupBy(
        warn,
        (x) => `${x.resource_name}_${x.error_detail.first_name}_${x.error_detail.last_name}`
      )
    )

    DialogService.open({
      title: '重複している保護者・子どもがいます',
      body: {
        component: {
          name: DuplicatedParentsOrChildrenMessage,
          bind: { duplicatedErrors, parents },
        },
      },
      onConfirm: () => {
        execute()
      },
      modalWidth: 700,
      isShowCancel: true,
      okButtonText: 'このまま保存する',
      cancelButtonText: '戻って修正する',
    })
  }

  /**
   *  重複がある場合の警告表示
   *  B: 保護者のみの重複がある場合
   *  Aの次に表示させる
   */

  static handleDuplicateParentNamesBetweenNewRecordAndSavedRecordWarns({
    warn,
    execute,
    parents,
  }: HandleDuplicateWarn): void {
    // 保護者の姓名を複合キーとして使用して、重複している行をグループ化する
    const duplicatedErrors = Object.values(
      groupBy(warn, (x) => `${x.error_detail.first_name}_${x.error_detail.last_name}`)
    )

    // NOTE: parents配下の子どもの数だけカラムを作成する
    const maxColumnsCount = Math.max(...parents.map((parent) => parent.schoolParentChildren.length))

    DialogService.open({
      title: '重複している保護者がいます',
      body: {
        component: {
          name: DuplicatedParentsMessage,
          bind: { duplicatedErrors, maxColumnsCount, parents },
        },
      },
      modalWidth: 700,
      okButtonText: 'このまま保存する',
      cancelButtonText: '戻って修正する',
      onConfirm: () => {
        execute()
      },
    })
  }
}
