import { ClassManagementPageStore } from '../class_management_page_store'
import { SchoolClass, ClassApi } from 'http/modules/class'
import { DialogService } from 'shared/services/dialog_service'
import { SnackbarService } from 'shared/services/snackbar_service'
import { ClassOrdersApi } from 'http/modules/class_orders'
import { RouteService } from '../../../shared/services/route_service'

export class ClassManagementService {
  static async loadClasses(): Promise<void> {
    try {
      const schoolClasses = (await ClassApi.index(ClassManagementService.getTargetFacilityId()))
        .data.school_classes
      ClassManagementPageStore.updateSchoolClasses(schoolClasses)
    } catch {
      SnackbarService.open('クラス情報の読込に失敗しました', 'error')
    }
  }

  static deleteClass(schoolClass: SchoolClass): void {
    DialogService.openConfirm({
      title: 'クラスの削除',
      message: [`「${schoolClass.name}」クラスを削除してもよろしいですか？`],
      onConfirm: async () => {
        await ClassManagementService.executeDeleteClass(schoolClass.id)
        ClassManagementService.loadClasses()
      },
    })
  }

  private static async executeDeleteClass(schoolClassId: number): Promise<void> {
    try {
      const response = (
        await ClassApi.destroy(ClassManagementService.getTargetFacilityId(), schoolClassId)
      ).data
      if (response.status === 'ok') {
        SnackbarService.open(response.notice, 'info')
      } else {
        SnackbarService.open(response.alert, 'error')
      }
    } catch {
      SnackbarService.open('クラスの削除に失敗しました', 'error')
    }
  }

  static async registerClass(className: string): Promise<void> {
    ClassManagementPageStore.updateIsUpdating(true)
    // 入力値前後に空白が含まれることで、保護者一括登録がエラーになるので、入力値前後の空白を削除する
    const trimmedClassName = className.trim()
    try {
      const response = (
        await ClassApi.create(ClassManagementService.getTargetFacilityId(), trimmedClassName)
      ).data
      if (response.status === 'ok') {
        SnackbarService.open(response.notice, 'info')
      } else {
        SnackbarService.open(response.alert, 'error')
      }
      ClassManagementPageStore.updateIsShowRegistrationDialog(false)
      ClassManagementService.loadClasses()
    } catch {
      SnackbarService.open('クラスの登録に失敗しました', 'error')
    } finally {
      ClassManagementPageStore.updateIsUpdating(false)
    }
  }

  static async updateClassName(className: string): Promise<void> {
    ClassManagementPageStore.updateIsUpdating(true)
    // 入力値前後に空白が含まれることで、保護者一括登録がエラーになるので、入力値前後の空白を削除する
    const trimmedClassName = className.trim()
    try {
      const response = (
        await ClassApi.update(
          ClassManagementService.getTargetFacilityId(),
          ClassManagementPageStore.updateTargetClass!.id,
          trimmedClassName
        )
      ).data
      if (response.status === 'ok') {
        SnackbarService.open(response.notice, 'info')
      } else {
        SnackbarService.open(response.alert, 'error')
      }
      ClassManagementPageStore.updateIsShowUpdateDialog(false)
      ClassManagementService.loadClasses()
    } catch {
      SnackbarService.open('クラス名の更新に失敗しました', 'error')
    } finally {
      ClassManagementPageStore.updateIsUpdating(false)
    }
  }

  static async changeOrder(schoolClassIds: number[]): Promise<void> {
    try {
      await ClassOrdersApi.update(ClassManagementService.getTargetFacilityId(), schoolClassIds)
      ClassManagementService.loadClasses()
      SnackbarService.open('クラスの並び順を更新しました', 'info')
    } catch {
      SnackbarService.open('並び順の更新に失敗しました', 'error')
    }
  }

  private static getTargetFacilityId(): number {
    return Number(RouteService.getPathParamByIndex(1))
  }
}
