import { ConfigApi } from 'http/modules/config'
import { LdFlagId } from 'shared/models'
import { AdminConfigApi } from 'http/modules/admin_config'
import { RouteService } from 'shared/services/route_service'

export class LaunchDarklyService {
  static async getFlag(key: LdFlagId, facilityId?: number): Promise<boolean> {
    const isAdmin = RouteService.getPathParamByIndex(0) === 'admin'

    if (isAdmin) {
      return (await AdminConfigApi.ldFlag<boolean>(key, facilityId)).data.result
    }
    return (await ConfigApi.ldFlag<boolean>(key, facilityId)).data.result
  }
}
