<template>
  <div>
    <v-menu>
      <template #activator="{ props }">
        <Button v-bind="props" :class="`${mode}-button`" color="normal" height="48" width="72">
          <div class="d-flex flex-column justify-center">
            <div><v-icon size="small" class="mt-1" :color="colorName">mdi-help-circle</v-icon></div>
            <div class="text-caption" :class="`text-${colorName}`">サポート</div>
          </div>
        </Button>
      </template>
      <v-list>
        <v-list-item class="px-4" :href="manualpageUrl" data-turbolinks="false" target="_blank">
          <div class="context-menu-link d-flex justify-space-between ga-1">
            マニュアル

            <v-icon size="small">mdi-open-in-new</v-icon>
          </div>
        </v-list-item>
        <v-list-item class="px-4" :href="infoForParentsUrl" data-turbolinks="false" target="_blank">
          <div class="context-menu-link d-flex justify-space-between ga-1">
            保護者へのご案内資料
            <v-icon size="small">mdi-open-in-new</v-icon>
          </div>
        </v-list-item>
        <v-list-item class="px-4" :href="helppageUrl" data-turbolinks="false" target="_blank">
          <div class="context-menu-link d-flex justify-space-between ga-1">
            よくある質問
            <v-icon size="small">mdi-open-in-new</v-icon>
          </div>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script setup lang="ts">
import { OperationMode } from '../models'
import Button from 'shared/components/Button.vue'

/**
 * helppageUrl: APIアクセスして生成する必要がある、かつ法人と施設の分岐が発生するため、外から渡す
 */
type HelpMenuButtonPropType = {
  mode: OperationMode
  helppageUrl: string
  infoForParentsUrl: string
  manualpageUrl: string
}

const { mode } = defineProps<HelpMenuButtonPropType>()
const colorName = mode === 'facility' ? 'primary' : 'grey--darken-2'
</script>

<style lang="scss" scoped>
@import '../../../../../assets/stylesheets/design/variables';
.context-menu-link {
  color: inherit;
  width: 100%;
}
.corporation-button {
  background-color: $color-button-grey;
  border: 1px $color-button-border-grey solid;
}
.facility-button {
  background-color: $color-button-blue;
  border: 1px $color-button-border-grey solid;
}
.v-btn:not(.v-btn--round).v-size--default {
  min-width: 48px;
}
</style>
