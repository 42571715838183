import { reactive } from 'vue'

type PaginationState = {
  currentPage: number
}

const state = reactive<PaginationState>({
  currentPage: 1,
})

export class PaginationStore {
  static get currentPage(): number {
    return state.currentPage
  }

  static updateCurrentPage(currentPage: number) {
    state.currentPage = currentPage
  }
}
