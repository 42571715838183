import { DialogService } from 'shared/services/dialog_service'
import { ParentsPageStore } from 'pages/parents_page/parents_page_store'
import { ParentsService } from 'pages/parents_page/services/parents_service'
import DeleteConfirmationMessage from 'pages/parents_page/areas/modal_message/DeleteConfirmationMessage.vue'
import ResultDeleteParentsMessage from 'pages/parents_page/areas/modal_message/ResultDeleteParentsMessage.vue'
import { DeleteResult, QueryParams } from 'pages/parents_page/model'
import LoadingMessage from 'pages/parents_page/areas/modal_message/LoadingMessage.vue'

/**
 * 保護者一覧のダイアログに関するロジックを持ちます。
 */
export class ModalService {
  static deleteConfirmation(
    allParentsSelectedInPage: boolean,
    isSelectedAll: boolean,
    queryParams: Omit<QueryParams, 'page' | 'per_page'>
  ): void {
    const selectedParentIds = ParentsPageStore.selectedParentIds
    const selectedChildIds = ParentsPageStore.selectedChildIds

    const targetParentsCount = isSelectedAll
      ? ParentsPageStore.parentTotal.deletable.count.parents
      : selectedParentIds.length

    const targetChildrenCount = isSelectedAll
      ? ParentsPageStore.parentTotal.deletable.count.children
      : selectedChildIds.length

    DialogService.open({
      title: '選択した保護者・子どもを削除しますか？',
      body: {
        component: {
          name: DeleteConfirmationMessage,
          bind: { targetParentsCount, targetChildrenCount },
        },
      },
      modalWidth: 640,
      okButtonText: '削除する',
      okButtonColor: 'error',
      onConfirm: async () => {
        ModalService.loading()

        const [result] = await Promise.all([
          isSelectedAll
            ? ParentsService.bulkDelete(queryParams)
            : ParentsService.delete(selectedParentIds, selectedChildIds),
        ])
        ModalService.resultDeleteParents(result)
      },
    })
  }

  static resultDeleteParents(result: DeleteResult): void {
    DialogService.open({
      title: '削除結果',
      body: {
        component: {
          name: ResultDeleteParentsMessage,
          bind: { result },
        },
      },
      modalWidth: 640,
      okButtonText: '閉じる',
      isShowCancel: false,
      onConfirm: () => location.reload(),
    })
  }

  private static loading() {
    DialogService.open({
      title: '選択した保護者・子どもを削除しますか？',
      body: {
        component: {
          name: LoadingMessage,
          bind: { text: 'しばらくお待ちください。' },
        },
      },
      isShowCancel: false,
      isShowOk: false,
      isShowDivider: false,
    })
  }
}
