const Titles = {
  accountSettings: 'アカウント設定',
  batchClassChange: '一括クラス更新',
  classManagement: 'クラス一覧',
  corporationApply: '会社情報の新規登録',
  exportInvoiceItems: '請求内容ダウンロード',
  facility: '施設情報設定',
  importInvoices: '請求内容一括取り込み',
  importParents: '保護者一括登録',
  invoiceManagement: '請求管理',
  invoices: '請求一覧',
  itemNameCandidates: '請求内容マスタ設定',
  parentAdd: '新しく保護者・子どもを登録',
  parentEdit: '保護者・子どもを編集',
  parentIdPrint: 'LINE登録用ID詳細',
  parents: '保護者一覧',
  passwordReset: 'パスワードの再設定',
  receiptPrint: '領収書',
  receiptSend: '領収書送信',
  signIn: 'ログイン',
  transfer: '振込管理',
  transferStatementPrint: '振込明細書',
  transferStatements: '振込明細書一覧',
} as const

export default Titles
