<template>
  <form name="PayPayStartCall" :action="res.start_url" method="post">
    <input id="AccessID" type="hidden" name="AccessID" :value="res.access_id" />
    <input id="Token" type="hidden" name="Token" :value="res.token" />
  </form>
  <input id="temporary_url" type="hidden" name="temporary_url" :value="temporaryUrl" />
</template>

<script setup lang="ts">
import Liff from '@line/liff'
import { ref, onMounted } from 'vue'

defineProps<{
  res: {
    start_url: string
    access_id: string
    token: string
  }
  temporaryUrl: string
}>()

const isLoading = ref(true)
isLoading.value = false

onMounted(() => {
  if (Liff.getOS() !== 'ios') {
    // PayPayの決済画面へ遷移します
    const payPayStartCall = document.getElementsByName('PayPayStartCall')[0] as HTMLFormElement
    payPayStartCall.submit()
  } else {
    const temporaryUrlElement = document.getElementById('temporary_url') as HTMLInputElement
    if (temporaryUrlElement) {
      Liff.openWindow({
        url: temporaryUrlElement.value,
        external: true,
      })
      Liff.closeWindow()
    }
  }
})
</script>
