import { LineStatusFilterValue, PaymentStatusFilterValue, SchoolClassId } from '../models'
import { TopPageStore } from '../top_page_store'

const lineFilterOptions: {
  label: string
  value: LineStatusFilterValue
}[] = [
  { label: 'すべて表示', value: 'all' },
  { label: 'LINE登録済み', value: 'connected' },
  { label: 'LINE未登録', value: 'not_connected' },
]

/**
 * アコーディオンパネルのヘッダー部分に表示するクラス名の最大個数
 */
export const maxClassFilterSummaryCount = 7

/**
 * Parentの検索条件に関するロジックを持ちます
 */
export class ParentSearchConditionService {
  static updateClassCondition(schoolClassIds: SchoolClassId[]): void {
    TopPageStore.updateSearchCondition({
      ...TopPageStore.searchCondition,
      selectedClassIds: schoolClassIds,
    })
  }

  /**
   * "新園児"と"卒園児"という名前のクラス以外のクラスを全て選択状態にします
   */
  static selectAllClassExceptNewAndGrad(): void {
    ParentSearchConditionService.updateClassCondition(
      TopPageStore.schoolClasses
        .filter((schoolClass) => !['新園児', '卒園児'].includes(schoolClass.name))
        .map((schoolClass) => schoolClass.id)
    )
  }

  static resetClassFilter(): void {
    ParentSearchConditionService.updateClassCondition([])
  }

  static updatePaymentStatusCondition(paymentStatus: PaymentStatusFilterValue): void {
    TopPageStore.updateSearchCondition({
      ...TopPageStore.searchCondition,
      paymentStatus,
    })
  }

  static updateLineStatusCondition(lineStatus: LineStatusFilterValue): void {
    TopPageStore.updateSearchCondition({
      ...TopPageStore.searchCondition,
      lineStatus,
    })
  }

  static updateNameCondition(name: string): void {
    TopPageStore.updateSearchCondition({
      ...TopPageStore.searchCondition,
      name,
    })
  }

  static getSelectedClassNames(): string[] {
    return TopPageStore.searchCondition.selectedClassIds.map(
      (classId) =>
        TopPageStore.schoolClasses.find((schoolClass) => schoolClass.id === classId)!.name
    )
  }

  static getLineFilterOptions(): { label: string; value: LineStatusFilterValue }[] {
    return lineFilterOptions
  }

  static getSelectedLineFilterLabel(): string {
    return lineFilterOptions.find(
      (option) => option.value === TopPageStore.searchCondition.lineStatus
    )!.label
  }

  static generateClassFilterSummary(): string {
    const selectedClassNames = ParentSearchConditionService.getSelectedClassNames()
    if (selectedClassNames.length === 0) {
      return '未選択'
    }
    if (
      TopPageStore.schoolClasses
        .filter((schoolClass) => !['新園児', '卒園児'].includes(schoolClass.name))
        .every((schoolClass) =>
          TopPageStore.searchCondition.selectedClassIds.includes(schoolClass.id)
        )
    ) {
      return 'すべて表示'
    }
    return selectedClassNames.slice(0, maxClassFilterSummaryCount).join('、')
  }

  /**
   * 新園児・卒園児クラス以外の全てのクラスがチェックされているとき、trueを返します。
   */
  static isAllClassesSelectChecked(): boolean {
    return TopPageStore.schoolClasses
      .filter((schoolClass) => !['新園児', '卒園児'].includes(schoolClass.name))
      .every((schoolClass) =>
        TopPageStore.searchCondition.selectedClassIds.includes(schoolClass.id)
      )
  }
}
