import { reactive } from 'vue'
import { AvailableFacility } from 'http/modules/facility'
import { OemPartner } from 'shared/models'

/**
 * enpay webアプリ全体で使われる状態の型
 */
type AppState = {
  /** OemPartnerService#load で値を注入できます */
  oemPartner: OemPartner
  /** AuthService#getAvailableFacilitiesで値注入 */
  availableFacilities: AvailableFacility[]
  /** 利用可能な施設が既にロードされたかどうかを表します。trueならロード済み */
  hasFacilitiesLoaded: boolean
}

export class AppStore {
  static initialValue: AppState = {
    oemPartner: {
      id: undefined,
      name: 'enpay',
      logo_image_path: '/enpay_logo_1.svg',
      hero_logo_image_path: '/enpay_logo_2.svg',
    },
    availableFacilities: [],
    hasFacilitiesLoaded: false,
  }

  private static state = reactive<AppState>(AppStore.initialValue)

  static get oemPartner(): OemPartner {
    return AppStore.state.oemPartner
  }

  static updateOemPartner(oemPartner: OemPartner): void {
    AppStore.state.oemPartner = oemPartner
  }

  /**
   * このメソッドを直接呼ばず、AuthService#getgetAvailableFacilitiesを使用してください
   * 初回ロードかどうかのチェックと初回ロードフラグの更新を行うため。
   */
  static get availableFacilities(): AvailableFacility[] {
    return AppStore.state.availableFacilities
  }

  static updateAvailableFacilities(availableFacilities: AvailableFacility[]) {
    AppStore.state.availableFacilities = availableFacilities
  }

  static get hasFacilitiesLoaded(): boolean {
    return AppStore.state.hasFacilitiesLoaded
  }

  static updateHasFacilitiesLoaded(hasFacilitiesLoaded: boolean) {
    AppStore.state.hasFacilitiesLoaded = hasFacilitiesLoaded
  }
}
