import { TopPageStore } from '../top_page_store'
import rfdc from 'rfdc'
const clone = rfdc()

/**
 * 請求書を保存中かどうかのステータス管理に関するロジックを持ちます。
 */
export class InvoiceSavingStatusService {
  /**
   * 指定したparentIdsの請求書を保存中状態にします。
   * @returns 新たに保存中状態にできたparentIdsを配列で返します
   */
  static updateToSaving(parentIds: number[]): number[] {
    const savingParentIds = clone(TopPageStore.invoiceSavingParentIds)
    const notSavingParentIds = parentIds.filter((parentId) => !savingParentIds.includes(parentId))
    if (notSavingParentIds.length === 0) {
      return []
    }
    savingParentIds.push(...notSavingParentIds)
    TopPageStore.updateInvoiceSavingParentIds(savingParentIds)
    return notSavingParentIds
  }

  /**
   * 指定したparentIdsの請求書を非保存中状態にします。
   */
  static updateToNotSaving(parentIds: number[]): void {
    const savingParentIds = clone(TopPageStore.invoiceSavingParentIds)
    TopPageStore.updateInvoiceSavingParentIds(
      savingParentIds.filter((id) => !parentIds.includes(id))
    )
  }
}
