<template>
  <form class="d-flex align-center" onsubmit="return false;">
    <v-text-field
      v-model="searchInputValue"
      variant="outlined"
      density="compact"
      label="施設名・代表者名"
      hide-details
      class="bg-white"
    ></v-text-field>
    <Button
      type="submit"
      color="normal"
      variant="tonal"
      class="ml-2"
      :loading="isSearching"
      :disabled="isSearching"
      @click="search()"
    >
      検索
    </Button>
  </form>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import { TransferService } from '../services/transfer_service'
import { EventTracker } from 'shared/utils/_event_tracking'
import { TransferPageStore } from '../transfer_page_store'
import Button from 'shared/components/Button.vue'

export default defineComponent({
  components: { Button },
  setup() {
    const isSearching = ref<boolean>(false)
    const searchInputValue = ref<string>('')

    const search = () => {
      isSearching.value = true
      TransferPageStore.updateSearchKeyword(searchInputValue.value)

      // GAイベント名は40文字制限のため、GAでは`click_search_btn_on_corporation_transfer`の様に`_page`を5文字削って検索してください
      EventTracker.trackEvent('click_search_btn_on_corporation_transfer', {})

      TransferService.loadTransferSummariesFirstPageWithInputValue().finally(() => {
        isSearching.value = false
      })
    }
    return { search, isSearching, searchInputValue }
  },
})
</script>
<style lang="scss" scoped>
.search-form {
  max-width: 360px;
}
</style>
