<!-- 保護者一括登録 重複している保護者がいる場合に表示するメッセージ -->
<template>
  <div>
    <span class="text-red font-weight-bold"> 同一の保護者の場合は同じIDを入力してください。 </span>
    <br />
    <span> 別世帯として登録する場合はそのまま取り込むことができます。 </span>
    <div class="pt-4 mb-6 scrollable-horizontal">
      <template v-for="(error, index) in duplicatedErrors" :key="index + 'name'">
        <v-row class="row">
          <v-col cols="2" class="grey-out border pa-0">
            <p class="grid-content__text text-center ma-4">{{ index + 1 }}組目</p>
          </v-col>
          <v-col cols="2" class="border pa-0">
            <p class="grid-content__text text-center ma-4">保護者ID</p>
            <v-divider></v-divider>
            <p class="grid-content__text text-center ma-4">保護者名</p>
            <v-divider></v-divider>
            <p class="grid-content__text text-center ma-4">子ども名</p>
          </v-col>
          <!-- parentsの中で一番重複が多いrowを取得して、そのlengthに応じてv-colを作成する -->
          <template v-for="columnNumber of maxColumnsCount" :key="columnNumber">
            <v-col cols="4" class="border pa-0 border-eight" style="margin-left: -1px">
              <p class="grid-content__text text-left ma-4">
                {{ getParentId(error[columnNumber - 1]) }}
              </p>
              <v-divider></v-divider>
              <p class="grid-content__text text-left ma-4">
                {{ getParentName(error[columnNumber - 1]) }}
              </p>
              <v-divider></v-divider>
              <p class="grid-content__text text-left ma-4">
                {{ getChildName(error[columnNumber - 1]) }}
              </p>
            </v-col>
          </template>
        </v-row>
      </template>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { DuplicationWarning } from 'pages/import_parents_page/model'

export default defineComponent({
  props: {
    duplicatedErrors: Array as PropType<DuplicationWarning[][]>,
    maxColumnsCount: Number,
  },
  setup() {
    const getParentId = (parent?: DuplicationWarning) =>
      parent && parent.error_detail.parent_id ? parent.error_detail.parent_id : '-'
    const getParentName = (parent?: DuplicationWarning) =>
      parent
        ? `${parent.error_detail.parent_last_name} ${parent.error_detail.parent_first_name}さん`
        : '-'
    const getChildName = (parent?: DuplicationWarning) =>
      parent && parent.error_detail.child_name ? `${parent.error_detail.child_name}さん` : '-'

    return {
      getParentId,
      getParentName,
      getChildName,
    }
  },
})
</script>

<style scoped>
.scrollable-horizontal {
  overflow-x: auto;
  overflow-y: hidden;
}
.row {
  margin: -1px 0 0 0;
  flex-wrap: initial;
}
.grey-out {
  background: #f6f6f5;
  pointer-events: none;
}
.border {
  border: 1px solid #e0e0e0;
  border-right: 0px;
}
.border-right {
  border-right: 1px solid #e0e0e0;
}
.grid-content__text {
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  font-weight: 500;
}
</style>
