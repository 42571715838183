import { AxiosResponse } from 'axios'
import { HTTP } from '../common'
import { InvoicePageInitialTargetMonth } from 'shared/models'

export type IndexResponse = {
  invoice_page_initial_target_month: InvoicePageInitialTargetMonth
}

// TODO: Featureflagを削除したらIndexResponseに命名変更する
export type NewSettingIndexResponse = {
  account: {
    id: number
    email: string
  }
  settings: {
    invoice_page_initial_target_month: InvoicePageInitialTargetMonth
  }
}

export type AccountSettings = NewSettingIndexResponse
export type SaveResponse = {
  message: string
}

export class AccountSettingApi {
  static async index(): Promise<AccountSettings> {
    const response = (
      await HTTP.get<IndexResponse | NewSettingIndexResponse>(`/corporation_accounts/me/settings`)
    ).data
    // TODO: Featureflagを削除したらisNewSettingIndexResponseを消す
    return this.isNewSettingIndexResponse(response)
      ? response
      : { settings: { ...response }, account: { id: -1, email: '' } }
  }

  static save(setting: AccountSettings['settings']): Promise<AxiosResponse<SaveResponse>> {
    return HTTP.patch(`/corporation_accounts/me/settings`, { setting })
  }

  private static isNewSettingIndexResponse(
    response: IndexResponse | NewSettingIndexResponse
  ): response is NewSettingIndexResponse {
    return 'account' in response && 'settings' in response
  }
}
