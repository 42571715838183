import { ReceiptApi } from 'http/modules/receipt'
import { ScreenLoadingService } from 'shared/services/screen_loading_service'
import { SnackbarService } from 'shared/services/snackbar_service'
import { ReceiptSendPageStore } from '../receipt_send_page_store'

/**
 * 領収書のメール送信に関するロジックを持ちます。
 */
export class ReceiptSendService {
  static async sendReceipt(invoiceId: string, email: string): Promise<void> {
    if (!invoiceId || !email) {
      return
    }
    try {
      ScreenLoadingService.showLoader()
      await ReceiptApi.create(Number(invoiceId), email)
      ReceiptSendPageStore.updateIsMailSendDone(true)
    } catch {
      SnackbarService.open('領収書のメール送信に失敗しました', 'error')
    } finally {
      ScreenLoadingService.hideLoader()
    }
  }
}
