<template>
  <div class="header">
    <h2>{{ paymentMethod }}</h2>
    <div class="header-contents">
      <div class="price-title">
        <div class="icon">
          <v-icon icon="mdi-store" />
        </div>
        <h4>支払い金額</h4>
      </div>
      <div class="price">{{ invoicePrice }}<span>円</span></div>
    </div>
  </div>
  <section class="section">
    <div v-if="error" class="section_inner failed">
      <h3>{{ error }}</h3>
      <p v-show="errorMessage">{{ errorMessage }}</p>
    </div>
  </section>
</template>

<script setup lang="ts">
import { PaymentMethodValue } from 'shared/consts/string/payment_method'

export type LineErrorPaymentProps = {
  paymentMethod: PaymentMethodValue
  invoicePrice: number
  error?: string
  errorMessage?: string
}

const { paymentMethod } = defineProps<LineErrorPaymentProps>()
</script>
