<template>
  <form name="PayPayStartCall" :action="res.start_url" method="post">
    <input id="AccessID" type="hidden" name="AccessID" :value="res.access_id" />
    <input id="Token" type="hidden" name="Token" :value="res.token" />
  </form>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'

defineProps<{
  res: {
    start_url: string
    access_id: string
    token: string
  }
}>()

const isLoading = ref(true)

isLoading.value = false
// PayPay の決済画面へ遷移します
onMounted(() => {
  const payPayStartCall = document.getElementsByName('PayPayStartCall')[0] as HTMLFormElement
  payPayStartCall.submit()
})
</script>
