<template>
  <div class="d-flex justify-end">
    <Button
      :disabled="isEmpty || isLoading"
      size="large"
      variant="outlined"
      class="font-weight-bold fs-16 outlined-button mr-4"
      @click="
        isFacility
          ? $emit('onClickSearchFilteredDownload', 'export_raw_invoice_items_on_facility')
          : $emit('onClickSearchFilteredDownload', 'export_raw_invoice_items_on_corporation')
      "
    >
      <v-icon class="pr-2">mdi-download</v-icon>
      <span class="font-weight-bold fs-16">そのままダウンロード</span>
    </Button>

    <v-menu min-width="371px" max-width="371px">
      <template #activator="{ props }">
        <Button v-bind="props" :disabled="isEmpty || isLoading" size="large">
          <span class="font-weight-bold fs-16">集計してダウンロード</span>
          <v-icon class="pl-2">mdi-menu-down</v-icon>
        </Button>
      </template>
      <v-list>
        <v-list-item
          class="context-menu-link"
          @click="
            isFacility
              ? $emit('onClickGroupByDownload', ['item_name'], 'export_invoice_items_by_item')
              : $emit('onClickGroupByDownload', ['facility_id'], 'export_invoice_items_by_facility')
          "
        >
          <span class="font-weight-medium">{{
            isFacility ? '請求内容別合計をダウンロード' : '施設別合計をダウンロード'
          }}</span>
        </v-list-item>
        <v-list-item
          class="context-menu-link mb-2"
          @click="
            isFacility
              ? $emit(
                  'onClickGroupByDownload',
                  ['class_name', 'item_name'],
                  'export_invoice_items_by_item_x_class'
                )
              : $emit(
                  'onClickGroupByDownload',
                  ['facility_id', 'item_name'],
                  'export_invoice_items_by_facility_x_item'
                )
          "
        >
          <span class="font-weight-medium">{{
            isFacility ? 'クラス×請求内容別合計をダウンロード' : '施設×請求内容別合計をダウンロード'
          }}</span>
        </v-list-item>
        <v-divider />
        <div class="ma-4 card-help">
          <S3Image
            :path="isFacility ? facilityHelpImage : corporationHelpImage"
            style="width: 100%"
          />
          <div class="py-2 px-4 my-auto">
            <p class="font-weight-bold mb-4">
              {{
                isFacility
                  ? '請求内容やクラスごとの合計値を出して\nダウンロードできます。'
                  : '施設や施設×請求内容別の合計値を出して\nダウンロードできます。'
              }}
            </p>
            <a :href="releaseNoteUrl" target="_blank">
              <span class="font-weight-medium text-decoration-underline d-flex justify-end"
                >詳しくはこちら</span
              >
            </a>
          </div>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import Button from 'shared/components/Button.vue'
import S3Image from 'shared/components/s_3_image/S3Image.vue'
import ExternalLink from 'shared/consts/external_link'

export default defineComponent({
  components: { Button, S3Image },
  props: {
    isFacility: {
      type: Boolean,
      required: true,
    },
    totalCount: {
      type: Number,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['onClickSearchFilteredDownload', 'onClickGroupByDownload'],
  setup(props) {
    const facilityHelpImage = '/export_invoice_items/export_invoice_items_facility_help.svg'
    const corporationHelpImage = '/export_invoice_items/export_invoice_items_corporation_help.svg'
    const isEmpty = computed(() => props.totalCount === 0)
    const releaseNoteUrl = props.isFacility
      ? ExternalLink.exportInvoiceItemsFacilityReleaseNote
      : ExternalLink.exportInvoiceItemsCorporationReleaseNote

    return { facilityHelpImage, corporationHelpImage, isEmpty, releaseNoteUrl }
  },
})
</script>

<style scoped lang="scss">
.card-help {
  border-radius: 8px;
  background: var(--light-blue-100, #f4f6f9);
}
.v-list-item {
  padding: 0 24px;
}
.context-menu-link {
  color: inherit;
}
.fs-16 {
  font-size: 16px;
}
.outlined-button {
  line-height: 24px;
  background-color: #fff;
}
.v-menu__content {
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.16);
}
</style>
