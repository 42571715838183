import { Invoice, InvoiceItem, InvoiceItemInput, Parent, AmountOfInvoiceByStatus } from '../models'
import { TopPageStore } from '../top_page_store'
import { InvoiceItemService } from './invoice_item_service'
import { SchoolParentService } from './school_parent_service'
import InvoiceApi, { isAxiosError } from '../../../http/modules/invoice'
import { SnackbarService } from 'shared/services/snackbar_service'
import { InvoiceChargingStatusService } from './invoice_charging_status_service'
import { InvoiceItemInputService } from './invoice_item_input_service'
import { EventTracker } from 'shared/utils/_event_tracking'
import { DialogService } from 'shared/services/dialog_service'
import { TargetMonthService } from './target_month_service'
import { SchoolService } from './school_service'
import BulkChargeMessage from 'pages/top_page/components/BulkChargeMessage.vue'
import BulkCancelMessage from 'pages/top_page/components/BulkCancelMessage.vue'
import BulkChargeResultMessage from 'pages/top_page/components/BulkChargeResultMessage.vue'
import BulkCancelResultMessage from 'pages/top_page/components/BulkCancelResultMessage.vue'
import { ScreenLoadingService } from 'shared/services/screen_loading_service'

/* eslint-disable max-lines */
/**
 * Invoiceに関するロジックを持ちます。
 */
export class InvoiceService {
  /**
   * 一度も保存されていない請求書が存在するかどうかを判定し、もし存在する場合にはtrueを返します。
   * 請求未作成や支払い済の状態から新たに請求書を作成し、保存していない状況ではtrueが返ります。
   */
  static existNeverSavedInvoice(parentId: number): boolean {
    const parent = SchoolParentService.getParentById(parentId)
    return (
      (parent?.invoices.length === 0 || parent?.invoices[0].status === 'paid') &&
      parent.invoice_item_inputs.some((input) => !InvoiceItemInputService.isInputDefault(input))
    )
  }

  /**
   * paidのinvoiceであればそのinvoice_itemsを、そうでなければ編集中の可能性があるのでinvoice_item_inputsを
   * 指定したchildIdのものに絞り込み返します。
   */
  static getInvoiceItems(invoice: Invoice, childId?: number): InvoiceItem[] {
    return invoice.invoice_items.filter((item) => item.school_parent_child_id === childId)
  }

  /**
   * paidのinvoiceであればそのinvoice_itemsを、そうでなければ編集中の可能性があるのでinvoice_item_inputsを
   * 指定したchildIdのものに絞り込み返します。
   */
  static getInvoiceItemsLegacy(
    parent: Parent,
    invoice: Invoice,
    childId?: number
  ): InvoiceItem[] | InvoiceItemInput[] {
    if (invoice.status !== 'paid') {
      return parent.invoice_item_inputs.filter((item) => item.school_parent_child_id === childId)
    }
    return invoice.invoice_items.filter((item) => item.school_parent_child_id === childId)
  }

  static async jumpToImportPage() {
    const url = `/facilities/${SchoolService.getTargetFacilityId()}/invoices/import_requests?target_year_month=${TargetMonthService.getTargetMonthAsString()}`

    try {
      await InvoiceApi.checkReturnError()
      window.open(url, '_blank')
    } catch (error) {
      if (isAxiosError(error) && error.response !== undefined) {
        const message =
          error.response.status === 403
            ? '請求が締められているため、請求内容の編集はできません。'
            : '請求データ一括取り込み画面への移動に失敗しました。'
        SnackbarService.open(message, 'error')
      }
    }
  }

  private static getErrorMessage(errorType: string, isBulkOperation: boolean = false): string {
    const invoiceOperationType = isBulkOperation ? '一括請求' : '請求'
    return (
      {
        locked_invoices: `請求が締められているため、${invoiceOperationType}できませんでした。`,
        not_started_service: `サービス利用開始日前のため、${invoiceOperationType}できませんでした。ご契約内容をご確認ください。`,
      }[errorType] || `${invoiceOperationType}に失敗しました。`
    )
  }

  /**
   * 対象のparentIdの請求書をまず保存し、その後請求を行います。
   * 現在のフォームに基づき、請求を行います。
   */
  static async charge(parentId: number, sendToLine: boolean, isRemind: boolean): Promise<void> {
    const parent = SchoolParentService.getParentById(parentId)
    if (!parent) return
    InvoiceChargingStatusService.updateToCharging([parentId])
    try {
      if (!isRemind) {
        await InvoiceItemService.saveInvoiceItems([parentId])
      }
      const response = await InvoiceApi.charge(SchoolService.getTargetFacilityId(), parent, {
        target_year_and_month: TopPageStore.targetYear + '-' + TopPageStore.targetMonth,
        send_to_line: sendToLine,
      })
      const facilityUnpaidInvoicesUrl = `${
        location.origin
      }/facilities/${SchoolService.getTargetFacilityId()}/unpaid_invoices?ids=${
        response.data.invoice.id
      }`
      await SchoolParentService.loadSchoolParents([parentId])
      if (response.data.status === 'ok') {
        if (response.data.invoice.status === 'paid') {
          SnackbarService.open('1 件相殺しました。', 'info')
        } else if (response.data.send_to_line) {
          SnackbarService.open('【LINE】で請求しました。', 'info')
        } else {
          SnackbarService.open('【請求書】を発行しました。', 'info')
          window.open(facilityUnpaidInvoicesUrl)
        }
      }
    } catch (error) {
      if (isAxiosError(error) && error.response !== undefined) {
        const { error: errorType } = error.response.data
        SnackbarService.open(InvoiceService.getErrorMessage(errorType), 'error')
      }
    } finally {
      InvoiceChargingStatusService.updateToNotCharging([parentId])
    }
  }

  /**
   * 指定した親に対して、一括請求し、その後DBと状態(schoolParents)の同期を行います。
   * batchChargeAPIのレスポンスを返します。
   * 処理に失敗した場合はundefinedを返します。
   */
  static async bulkCharge(parentIds: number[], isRemind: boolean): Promise<any | undefined> {
    InvoiceChargingStatusService.updateToCharging(parentIds)
    try {
      const response = await InvoiceApi.batchCharge(SchoolService.getTargetFacilityId(), {
        target_year_and_month: TopPageStore.targetYear + '-' + TopPageStore.targetMonth,
        is_remind: isRemind,
        school_parent_ids: parentIds,
      })
      await SchoolParentService.loadSchoolParents(parentIds)
      return response.data
    } finally {
      InvoiceChargingStatusService.updateToNotCharging(parentIds)
    }
  }

  /**
   * 指定した親に対して、一括取り消しし、その後DBと状態(schoolParents)の同期を行います。
   * キャンセルが成功したinvoiceを返します。
   */
  static async bulkCancelRequest(parentIds: number[]) {
    InvoiceChargingStatusService.updateToCharging(parentIds)
    try {
      const response = await InvoiceApi.batchUpdateStatus({
        facilityId: SchoolService.getTargetFacilityId(),
        targetYearAndMonth: TopPageStore.targetYear + '-' + TopPageStore.targetMonth,
        schoolParentIds: parentIds,
        status: 'before_charge',
      })
      await SchoolParentService.loadSchoolParents(parentIds)
      return response.data
    } finally {
      InvoiceChargingStatusService.updateToNotCharging(parentIds)
    }
  }

  /**
   * 請求書の合計金額を計算します。
   * childIdごとの小計を算出し、正の数の小計を合計します。
   * 小計が負の数の場合は無視されます。
   */
  static calculateTotalAmountOfInvoice(
    invoiceId: number,
    includeConvenienceStoreFee: boolean
  ): number | undefined {
    const parent = SchoolParentService.getParentByInvoiceId(invoiceId)
    if (!parent) return undefined

    // 指定されたinvoiceIdがDBに保存されている最新のinvoiceで、
    // かつstatusがpaid(フォームが最新の状況と一致する)でなければ、
    // フォームを元に計算し結果を返す。
    if (
      parent.invoices.length > 0 &&
      parent.invoices[0].id === invoiceId &&
      parent.invoices[0].status !== 'paid'
    ) {
      return InvoiceItemInputService.calculateInputTotalAmountByParentId(parent.id)
    }

    // そうでなければinvoice_itemsを元に計算し結果を返す
    return [undefined, ...parent.school_parent_children.map((child) => child.id)].reduce(
      (amount: number | undefined, childId) => {
        const amountOfChild = InvoiceItemService.calculateTotalAmountOfChild(invoiceId, childId)
        if (amountOfChild === undefined || amountOfChild < 0) return amount
        const convenienceStoreFeeOfChild = includeConvenienceStoreFee
          ? InvoiceItemService.calculateConvenienceStoreFeeOfChild(invoiceId, childId)
          : 0
        return amountOfChild + (amount ?? 0) + convenienceStoreFeeOfChild
      },
      undefined
    )
  }

  /**
   * 請求書の合計金額を計算します。
   * childIdごとの小計を算出し、正の数の小計を合計します。
   * 小計が負の数の場合は無視されます。
   */
  static calculateTotalAmountOfInvoiceLegacy(invoiceId: number): number | undefined {
    const parent = SchoolParentService.getParentByInvoiceId(invoiceId)
    if (!parent) return undefined

    // 指定されたinvoiceIdがDBに保存されている最新のinvoiceで、
    // かつstatusがpaid(フォームが最新の状況と一致する)でなければ、
    // フォームを元に計算し結果を返す。
    if (
      parent.invoices.length > 0 &&
      parent.invoices[0].id === invoiceId &&
      parent.invoices[0].status !== 'paid'
    ) {
      return InvoiceItemInputService.calculateInputTotalAmountByParentId(parent.id)
    }

    // そうでなければinvoice_itemsを元に計算し結果を返す
    return [undefined, ...parent.school_parent_children.map((child) => child.id)].reduce(
      (amount: number | undefined, childId) => {
        const amountOfChild = InvoiceItemService.calculateTotalAmountOfChild(invoiceId, childId)
        if (amountOfChild === undefined || amountOfChild < 0) return amount
        const convenienceStoreFeeOfChild = InvoiceItemService.calculateConvenienceStoreFeeOfChild(
          invoiceId,
          childId
        )
        return amountOfChild + (amount ?? 0) + convenienceStoreFeeOfChild
      },
      undefined
    )
  }

  static calculateConvenienceStoreFeeOfInvoice(invoice: Invoice): number {
    const item = invoice.invoice_items.find((item) => item.is_convenience_store_fee)
    return item?.unit_price ?? 0
  }

  /**
   * 全ての親が持つ全ての請求のステータスごとの合計金額を計算するメソッド
   * allにはpaid, unpaid, before_chargeの合計額を足した値が入ります
   * statusがpaidの場合はDBのinvoice_itemと同じ値の入ったinvoice > invoice_itemsを基準に合計額を計算します
   * statusがunpaid, before_chargeの場合は、フォーム(item_inputs)の値から合計額を計算します
   */
  static calculateAmountOfInvoiceByStatus(): AmountOfInvoiceByStatus {
    const amountByStatus: AmountOfInvoiceByStatus = {
      all: undefined,
      paid: undefined,
      unpaid: undefined,
      before_charge: undefined,
    }
    TopPageStore.schoolParents.forEach((parent) => {
      parent.invoices.forEach((invoice) => {
        const invoiceTotalAmount = this.calculateTotalAmountOfInvoice(invoice.id, false)
        if (invoiceTotalAmount !== undefined) {
          amountByStatus[`${invoice.status}`] =
            invoiceTotalAmount + (amountByStatus[`${invoice.status}`] ?? 0)
          amountByStatus['all'] = invoiceTotalAmount + (amountByStatus['all'] ?? 0)
        }
      })
    })
    return amountByStatus
  }

  /**
   * 全ての親が持つ全ての請求のステータスごとの合計金額を計算するメソッド
   * allにはpaid, unpaid, before_chargeの合計額を足した値が入ります
   * statusがpaidの場合はDBのinvoice_itemと同じ値の入ったinvoice > invoice_itemsを基準に合計額を計算します
   * statusがunpaid, before_chargeの場合は、フォーム(item_inputs)の値から合計額を計算します
   */
  static calculateAmountOfInvoiceByStatusLegacy(): AmountOfInvoiceByStatus {
    const amountByStatus: AmountOfInvoiceByStatus = {
      all: undefined,
      paid: undefined,
      unpaid: undefined,
      before_charge: undefined,
    }
    TopPageStore.schoolParents.forEach((parent) => {
      parent.invoices.forEach((invoice) => {
        const invoiceTotalAmount = this.calculateTotalAmountOfInvoiceLegacy(invoice.id)
        if (invoiceTotalAmount !== undefined) {
          amountByStatus[`${invoice.status}`] =
            invoiceTotalAmount + (amountByStatus[`${invoice.status}`] ?? 0)
          amountByStatus['all'] = invoiceTotalAmount + (amountByStatus['all'] ?? 0)
        }
      })
    })
    return amountByStatus
  }

  static bulkRemind(): void {
    const remindTargetParents = SchoolParentService.getSearchedSchoolParents().filter(
      (parent) => parent!.isChecked
    )
    const lineInvoicesCount = remindTargetParents.filter((parent) => parent!.line_connected).length
    const invoicesCount = remindTargetParents.filter((parent) => !parent!.line_connected).length
    DialogService.open({
      title: '一括再請求',
      body: {
        component: {
          name: BulkChargeMessage,
          bind: {
            targetYear: TopPageStore.targetYear,
            targetMonth: TopPageStore.targetMonth,
            lineInvoicesCount,
            invoicesCount,
          },
        },
      },
      onConfirm: () => InvoiceService.executeBulkRemind(lineInvoicesCount, invoicesCount),
      modalWidth: 700,
    })
  }

  static bulkCancel(): void {
    const cancelTargetParents = SchoolParentService.getSearchedSchoolParents().filter(
      (parent) => parent!.isChecked
    )
    const lineInvoicesCount = cancelTargetParents.filter((parent) => parent!.line_connected).length
    const invoicesCount = cancelTargetParents.filter((parent) => !parent!.line_connected).length
    DialogService.open({
      title: '未払いの請求を請求保存中に変更',
      body: {
        component: {
          name: BulkCancelMessage,
          bind: {
            targetYear: TopPageStore.targetYear,
            targetMonth: TopPageStore.targetMonth,
            lineInvoicesCount,
            invoicesCount,
          },
        },
      },
      onConfirm: () => InvoiceService.executeBulkCancel(),
      modalWidth: 700,
      isConsentRequired: true,
      consentMessage: '請求保存中について確認した',
    })
  }

  /**
   * 検索結果にヒットしており、かつチェックされている(parent#isChecked)親に対して一括請求を行います。
   */
  // eslint-disable-next-line max-lines-per-function
  private static async executeBulkRemind(
    lineInvoicesCount: number,
    invoicesCount: number
  ): Promise<void> {
    EventTracker.trackEvent('click_btn_remind', {})
    const remindTargetParents = SchoolParentService.getSearchedSchoolParents().filter(
      (parent) => parent.isChecked
    )
    TopPageStore.updateIsBulkReminding(true)
    try {
      const response = await InvoiceService.bulkCharge(
        remindTargetParents.map((parent) => parent.id),
        true
      )
      if (response.status !== 'ok') throw new Error('Failed to bulk remind')
      const {
        school_parent_invoice_ids,
        school_parent_line_ids,
        school_parent_offset_ids,
        failed_line_count,
      } = response

      if (school_parent_invoice_ids?.length > 0) {
        const invoiceIdsParam = school_parent_invoice_ids
          .map((parentId: any) => SchoolParentService.getParentById(parentId)!.invoices[0].id)
          .join(',')
        window.open(
          `${
            location.origin
          }/facilities/${SchoolService.getTargetFacilityId()}/unpaid_invoices?ids=${invoiceIdsParam}`
        )
      }
      DialogService.open({
        title: '一括再請求完了',
        body: {
          component: {
            name: BulkChargeResultMessage,
            bind: {
              isRemind: true,
              isSentLineInvoice: lineInvoicesCount > 0,
              isSentInvoice: invoicesCount > 0,
              sentInvoiceCount: school_parent_invoice_ids?.length,
              successLineCount: school_parent_line_ids?.length,
              offsetInvoiceCount: school_parent_offset_ids?.length,
              failedLineCount: failed_line_count,
            },
          },
        },
        modalWidth: 700,
      })
    } catch (error) {
      SnackbarService.open('再請求の連絡を送信できませんでした。', 'error')
    } finally {
      TopPageStore.updateIsBulkReminding(false)
    }
  }

  /**
   * 検索結果にヒットしており、かつチェックされている(parent#isChecked)親に対して一括取り消しを行います。
   */
  private static async executeBulkCancel(): Promise<void> {
    EventTracker.trackEvent('click_btn_bulkcancel', {})
    const cancelTargetParents = SchoolParentService.getSearchedSchoolParents().filter(
      (parent) => parent.isChecked
    )
    TopPageStore.updateIsBulkReminding(true)
    try {
      const response = await InvoiceService.bulkCancelRequest(
        cancelTargetParents.map((parent) => parent.id)
      )

      DialogService.open({
        title: '一括取り消し完了',
        body: {
          component: {
            name: BulkCancelResultMessage,
            bind: { response },
          },
        },
        modalWidth: 700,
        isShowCancel: false,
      })
    } catch (error) {
      if (isAxiosError(error) && error.response !== undefined) {
        const message =
          error.response.status === 403
            ? '請求が締められているため、取り消しに失敗しました。'
            : '取り消しに失敗しました。お手数ですが画面を更新して再度お試しください。'
        SnackbarService.open(message, 'error')
      }
    } finally {
      TopPageStore.updateIsBulkReminding(false)
    }
  }

  /**
   * 未保存の請求を一括で保存する
   */
  static async bulkSave(): Promise<void> {
    EventTracker.trackEvent('click_btn_batch_save', {})
    ScreenLoadingService.showLoader()

    try {
      const targetParentIds: number[] = []
      TopPageStore.schoolParents.forEach((parent) => {
        if (
          [undefined, ...parent.school_parent_children.map((child) => child.id)].some((childId) =>
            InvoiceItemService.hasSavableItemInputs(parent.id, childId)
          )
        ) {
          targetParentIds.push(parent.id)
        }
      })
      const response = await InvoiceItemService.saveInvoiceItems(targetParentIds)
      const message: string[] = []
      if (response.data.count_saved_invoices !== 0) {
        message.push(`${response.data.count_saved_invoices}件の請求を保存しました。`)
      }
      if (response.data.count_canceled_invoices !== 0) {
        message.push(`${response.data.count_canceled_invoices}件の請求を取り消しました。`)
      }
      DialogService.openMessage({ title: '一括保存完了', message })
    } catch (error) {
      if (isAxiosError(error) && error.response !== undefined) {
        const message =
          error.response.status === 403
            ? '請求が締められているため、請求を保存できませんでした。'
            : '請求を保存できませんでした。'
        SnackbarService.open(message, 'error')
      }
    } finally {
      ScreenLoadingService.hideLoader()
    }
  }

  /**
   * 請求保存中の親へ一括で請求します。
   * ダイアログを表示し、OKが選択されたら請求をします。
   */
  static bulkChargeToBeforeChargeParents(): void {
    // 最新の保存済みの請求のステータスが'before_charge'の親を抽出
    const chargeTargetParents = TopPageStore.schoolParents.filter(
      (parent) => parent.invoices.length > 0 && parent.invoices[0].status === 'before_charge'
    )
    // FIXME たとえば100円の請求を作って保存した後に、相殺の請求書(-100円の項目を追加し合計0円にする)に変えて保存せず、
    // 一括請求ボタンを押した場合は、ここでoffsetParentsとして判定され、相殺の請求があります！と言われるが、実際はDBの値を使って
    // 請求されるので、相殺扱いにならないという不親切設計になっている
    const offsetParents = chargeTargetParents.filter(
      (parent) => InvoiceService.calculateTotalAmountOfInvoice(parent.invoices[0].id, false) === 0
    )
    const offsetParentsNames = offsetParents
      .map((parent) => parent.last_name + parent.first_name)
      .join('、')
    const lineInvoicesCount = chargeTargetParents.filter((parent) => parent!.line_connected).length
    const invoicesCount = chargeTargetParents.filter((parent) => !parent!.line_connected).length
    DialogService.open({
      title: '請求保存中の保護者に対する一括請求',
      body: {
        component: {
          name: BulkChargeMessage,
          bind: {
            targetYear: TopPageStore.targetYear,
            targetMonth: TopPageStore.targetMonth,
            lineInvoicesCount,
            invoicesCount,
            offsetParents,
            offsetParentsNames,
          },
        },
      },
      onConfirm: () =>
        InvoiceService.executeBulkChargeToBeforeChargeParents(lineInvoicesCount, invoicesCount),
      modalWidth: 700,
    })
  }

  /**
   * 請求保存中の親に対して一括請求します。
   */
  // eslint-disable-next-line max-lines-per-function
  static async executeBulkChargeToBeforeChargeParents(
    lineInvoicesCount: number,
    invoicesCount: number
  ): Promise<void> {
    EventTracker.trackEvent('click_btn_batch_invoice', {})
    ScreenLoadingService.showLoader()
    const chargeTargetParents = TopPageStore.schoolParents.filter(
      (parent) => parent.invoices.length > 0 && parent.invoices[0].status === 'before_charge'
    )
    try {
      const response = await InvoiceService.bulkCharge(
        chargeTargetParents.map((parent) => parent.id),
        false
      )
      if (response.status !== 'ok') throw new Error('Failed to bulk charge')
      const {
        school_parent_invoice_ids,
        school_parent_line_ids,
        school_parent_offset_ids,
        failed_line_count,
      } = response

      if (school_parent_invoice_ids?.length > 0) {
        const invoiceIdsParam = school_parent_invoice_ids
          .map((parentId: any) => SchoolParentService.getParentById(parentId)!.invoices[0].id)
          .join(',')
        window.open(
          `${
            location.origin
          }/facilities/${SchoolService.getTargetFacilityId()}/unpaid_invoices?ids=${invoiceIdsParam}`
        )
      }

      DialogService.open({
        title: '一括請求完了',
        body: {
          component: {
            name: BulkChargeResultMessage,
            bind: {
              isRemind: false,
              isSentLineInvoice: lineInvoicesCount > 0,
              isSentInvoice: invoicesCount > 0,
              sentInvoiceCount: school_parent_invoice_ids?.length,
              successLineCount: school_parent_line_ids?.length,
              offsetInvoiceCount: school_parent_offset_ids?.length,
              failedLineCount: failed_line_count,
            },
          },
        },
        modalWidth: 700,
      })
    } catch (error) {
      if (isAxiosError(error) && error.response !== undefined) {
        const { error: errorType } = error.response.data
        // 一括請求ができるのは、画面内に「保存中」の請求が存在する時
        // 保存中の請求があれば"画面からは"請求締めできないので、請求締めのエラーが出ることはないはず
        SnackbarService.open(InvoiceService.getErrorMessage(errorType, true), 'error')
      }
    } finally {
      ScreenLoadingService.hideLoader()
    }
  }

  static async deleteBeforeChargeInvoices(): Promise<void> {
    ScreenLoadingService.showLoader()
    try {
      await InvoiceApi.batchDelete(
        SchoolService.getTargetFacilityId(),
        TargetMonthService.getTargetMonthAsString()
      )
      await SchoolParentService.loadSchoolParents()
      SnackbarService.open('未請求の請求データを全て削除しました。', 'info')
    } catch (error) {
      SnackbarService.open('請求の削除に失敗しました。', 'error')
      console.error(error)
    } finally {
      ScreenLoadingService.hideLoader()
    }
  }

  /**
   * 未請求の請求書を削除してよいか確認するダイアログを表示します
   */
  static openBeforeChargeDeleteConfirmDialog(): void {
    DialogService.openConfirm({
      title: '未請求データ一括削除',
      message: [
        `${TopPageStore.targetYear}年${TopPageStore.targetMonth}月分の未請求の請求データを全て削除します。`,
        'よろしいでしょうか？',
      ],
      onConfirm: () => InvoiceService.deleteBeforeChargeInvoices(),
    })
  }
}
