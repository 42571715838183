import { AxiosResponse } from 'axios'
import { HTTP } from '../common'
import { AvailableFacility } from 'shared/models'

export type IndexResponse = {
  facilities: AvailableFacility[]
}

type FacilityScope = 'all' | 'has_permission'

export class CorporationFacilityApi {
  static index(scope: FacilityScope): Promise<AxiosResponse<IndexResponse>> {
    return HTTP.get(`/corporations/me/facilities?scope=${scope}`)
  }
}
