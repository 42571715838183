<template>
  <AppFrame :has-browser-alert="false">
    <PrintButtonBar class="mb-6"></PrintButtonBar>

    <div>
      <div v-if="isLoading" class="d-flex justify-center mt-10">
        <v-progress-circular indeterminate :size="70" color="primary"></v-progress-circular>
      </div>
      <template v-else>
        <template v-for="(invoice, i) in invoices" :key="`page_break__${invoice.id}`">
          <div :class="i > 0 ? 'page_break' : ''"></div>
          <InvoiceDocument
            :invoice-prop="invoice"
            :parent-convenience-store-fee-apply-status="parentConvenienceStoreFeeApplyStatus"
          ></InvoiceDocument>
        </template>
      </template>
    </div>
  </AppFrame>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { InvoicePrintPageStore } from '../invoice_print_page_store'
import { RouteService } from '../../../shared/services/route_service'
import InvoiceDocument from './InvoiceDocument.vue'
import { InvoiceService } from 'pages/invoice_print_page/services/invoice_service'
import PrintButtonBar from 'shared/components/PrintButtonBar.vue'
import AppFrame from 'shared/components/AppFrame.vue'
import { ConvenienceStoreFeeStatusService } from 'pages/invoice_print_page/services/convenience_store_fee_status_service'

const invoiceIds = RouteService.getQueryParamByKey('ids')?.split(',')
if (invoiceIds !== undefined && invoiceIds.length > 0) {
  InvoiceService.loadInvoice(invoiceIds)
}

ConvenienceStoreFeeStatusService.loadParentConvenienceFeeApplyStatus()

// computed
const invoices = computed(() => InvoicePrintPageStore.invoices)
const isLoading = computed(() => InvoicePrintPageStore.isLoading)
const parentConvenienceStoreFeeApplyStatus = computed(
  () => InvoicePrintPageStore.parentConvenienceStoreFeeApplyStatus
)
</script>
