<template>
  <div id="beamerButton">
    <Button color="normal" :class="`${mode}-button`" height="48" width="72">
      <div class="d-flex flex-column justify-center">
        <div><v-icon size="small" class="mt-1" :color="colorName">mdi-bell</v-icon></div>
        <div class="text-caption" :class="`text-${colorName}`">お知らせ</div>
      </div>
    </Button>
  </div>
</template>

<script setup lang="ts">
import { OperationMode } from '../models'
import Button from 'shared/components/Button.vue'

type BeamerButtonPropType = {
  mode: OperationMode
}

const { mode } = defineProps<BeamerButtonPropType>()
const colorName = mode === 'facility' ? 'primary' : 'grey--darken-2'
</script>

<style lang="scss" scoped>
@import '../../../../../assets/stylesheets/design/variables';
.corporation-button {
  background-color: $color-button-grey;
  border: 1px $color-button-border-grey solid;
}
.facility-button {
  background-color: $color-button-blue;
  border: 1px $color-button-border-grey solid;
}
.v-btn:not(.v-btn--round).v-size--default {
  min-width: 48px;
}
</style>
