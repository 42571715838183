<template>
  <v-tabs :color="colorName" hide-slider>
    <template v-for="menuOrDirectory in availableMenu">
      <template v-if="'menus' in menuOrDirectory">
        <!-- ディレクトリのとき -->
        <v-menu :key="menuOrDirectory.name" open-on-hover open-delay="0" close-delay="0">
          <template #activator="{ props }">
            <v-tab v-bind="props">
              <span class="text-body-1 text-primary">
                {{ menuOrDirectory.name }}&nbsp;<v-icon color="primary">mdi-chevron-down</v-icon>
              </span>
            </v-tab>
          </template>
          <v-list density="compact">
            <template v-for="menu in menuOrDirectory.menus" :key="menu.name">
              <v-list-item
                link
                :href="menu.url"
                :data-turbolinks="menu.id === 'invoice' ? 'false' : 'true'"
                :data-testid="menu.id"
              >
                <a class="context-menu-link text-body-1">{{ menu.name }}</a>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
      <template v-else>
        <!-- メニューのとき -->
        <v-tab
          :key="menuOrDirectory.name"
          :href="menuOrDirectory.url"
          :class="selectedMenuId === menuOrDirectory.id ? 'selected' : ''"
          :data-testid="menuOrDirectory.id"
        >
          <span
            class="text-body-1"
            :class="selectedMenuId === menuOrDirectory.id ? 'text-dark' : 'text-grey'"
            >{{ menuOrDirectory.name }}</span
          >
        </v-tab>
      </template>
    </template>
  </v-tabs>
</template>

<script setup lang="ts">
import { MenuDirectory, Menu } from 'shared/models'
import { OperationMode } from '../models'

type NavMenuPropType = {
  selectedMenuId?: string
  availableMenu: Array<MenuDirectory | Menu>
  mode: OperationMode
}
const { mode } = defineProps<NavMenuPropType>()
const colorName = mode === 'facility' ? 'primary' : 'black'
</script>

<style lang="scss" scoped>
.selected {
  border-bottom: 4px black solid;
}
</style>
