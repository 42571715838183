export class UserAgentService {
  /** Google Chromeでない場合にtrue */
  static isDeprecatedBrowser(): boolean {
    const isChrome = (): boolean => {
      const uaString = window.navigator.userAgent.toLowerCase()
      return uaString.indexOf('chrome') !== -1 && uaString.indexOf('edg') === -1
    }
    return !isChrome()
  }
}
