import { AxiosResponse } from 'axios'
import { HTTP } from '../common'
import { LdFlagId } from 'shared/models'

export type LdFlagResponse<T> = {
  result: T
}

export class AdminConfigApi {
  static ldFlag<T>(
    ldFlagId: LdFlagId,
    facilityId?: number
  ): Promise<AxiosResponse<LdFlagResponse<T>>> {
    return HTTP.get(
      `admin/configs/ldflags/${ldFlagId}?facility_id=${facilityId === undefined ? '' : facilityId}`
    )
  }
}
