/* eslint-disable max-lines */
/**
 * 保護者一覧に関するロジックを持ちます。
 */
import { FacilityParentApi, isAxiosError } from 'http/modules/facility_parent'
import { RouteService } from 'shared/services/route_service'
import { ParentsPageStore } from 'pages/parents_page/parents_page_store'
import { FacilityParentTotalApi } from 'http/modules/facility_parent_total'
import {
  ChildId,
  DeleteResult,
  DeleteResultResponse,
  Parent,
  QueryParams,
  SearchQuery,
} from 'pages/parents_page/model'
import { BulkOperationService } from 'pages/parents_page/services/bulk_operation_service'
import { CodmonService } from 'pages/parents_page/services/codmon_service'
import { LineConnectionApi } from 'http/modules/line_connection'
import { DialogService } from 'shared/services/dialog_service'
import DisconnectLineMessage from 'pages/parents_page/components/DisconnectLineMessage.vue'
import DisconnectCodmonMessage from 'pages/parents_page/components/DisconnectCodmonMessage.vue'
import CodmonApi from 'http/modules/codmon'
import ConnectCodmonMessage from 'pages/parents_page/components/ConnectCodmonMessage.vue'
import { SnackbarService } from 'shared/services/snackbar_service'
import { ParentUrlService } from 'pages/parents_page/services/parent_url_service'
import { PaginationStore } from 'shared/components/pagination/pagination_store'

export class ParentsService {
  /**
   * 保護者一覧画面の初期化処理を行います。
   * 保護者一覧画面に遷移した際に呼び出されます。
   */
  static initialize = (): void => {
    // クエリパラメータ入力済みならクエリパラメータの値をstoreに入れる
    // 未入力ならstoreの値の初期値をクエリパラメータに入れる(storeの値のデフォルト値自体が初期検索値)
    if (location.search.toString() !== '') {
      ParentUrlService.copyQueryParamsToStore()
      const queryParams = RouteService.getQueryParams()
      PaginationStore.updateCurrentPage(Number(queryParams['page']))
    }
    ParentsService.search(ParentsPageStore.searchQuery)
    CodmonService.checkCodmonId()
    BulkOperationService.resetParentsSelected()
    ParentsPageStore.updateBulkOperationMode(undefined)
  }

  static search = async (queryParams: Partial<SearchQuery>): Promise<void> => {
    const defaultQueryParams: SearchQuery = {
      class_id: '',
      parent_name: '',
      child_name: '',
      line_connected: 'all',
      page: '1',
      per_page: '50',
    }
    const searchQuery = { ...defaultQueryParams, ...queryParams }
    ParentsPageStore.updateIsSearching(true)
    const facilityId = ParentsService.getTargetFacilityId()
    const resultParentSummaries = (
      await FacilityParentApi.index({
        facilityId: facilityId,
        queryParams: ParentUrlService.encodeQueryParams(searchQuery),
      })
    ).data
    ParentsPageStore.updateParentSummaries(resultParentSummaries)

    await ParentsService.loadTotal(facilityId, queryParams)
    ParentsPageStore.updateIsSearching(false)
  }

  static async loadTotal(facilityId: number, queryParams: Partial<SearchQuery>): Promise<void> {
    const resultParentTotal = (
      await FacilityParentTotalApi.show({
        facilityId: facilityId,
        queryParams: queryParams,
      })
    ).data
    ParentsPageStore.updateParentTotal(resultParentTotal)
  }

  static getTargetFacilityId(): number {
    return Number(RouteService.getPathParamByIndex(1))
  }

  static getTargetParentId(): number {
    return Number(RouteService.getPathParamByIndex(3))
  }

  /**
   * 単体deleteを呼び出す関数
   * @param selectedChildIds
   * @param selectedParentIds
   */
  static async delete(
    selectedParentIds: Parent['id'][],
    selectedChildIds: ChildId[]
  ): Promise<DeleteResult> {
    // NOTE: 子どもを先に削除して、終わったら親を削除する
    const resultChildren = await ParentsService.deleteChildren(selectedChildIds)
    const resultParents = await ParentsService.deleteParents(selectedParentIds)

    // NOTE: reduceでidの配列を成功と失敗に分ける
    const [successChildIds, failedChildIds] = resultChildren.reduce(
      (acc, cur) => {
        if (cur.status === 'success') {
          acc[0].push(cur.id)
        } else {
          acc[1].push(cur.id)
        }
        return acc
      },
      [[] as number[], [] as number[]]
    )

    const [successParentIds, failedParentIds] = resultParents.reduce(
      (acc, cur) => {
        if (cur.status === 'success') {
          acc[0].push(cur.id)
        } else {
          acc[1].push(cur.id)
        }
        return acc
      },
      [[] as number[], [] as number[]]
    )

    return {
      parents: {
        deleted_ids: successParentIds || [],
        failed_deleted_ids: failedParentIds || [],
        children: {
          deleted_ids: successChildIds || [],
          failed_deleted_ids: failedChildIds || [],
        },
      },
    }
  }

  static disconnectLine = (
    parentId: number | undefined,
    firstName: string,
    lastName: string,
    onSuccess: () => void
  ) => {
    DialogService.open({
      title: 'LINE連携を解除しますか？',
      body: {
        component: {
          name: DisconnectLineMessage,
          bind: { firstName, lastName },
        },
      },
      async onConfirm() {
        if (!parentId) {
          return
        }
        await LineConnectionApi.delete(ParentsService.getTargetFacilityId(), parentId)

        onSuccess()

        ParentsService.successSnackbar(`${lastName}${firstName}さんのLINE連携を解除しました`)
      },
    })
  }

  static disconnectCodmon = (
    parentId: number | undefined,
    childId: number | undefined,
    lastName: string,
    childFirstName: string,
    onSuccess: () => void
  ) => {
    DialogService.open({
      title: 'コドモン連携を解除しますか？',
      body: {
        component: {
          name: DisconnectCodmonMessage,
          bind: { childFirstName, lastName },
        },
      },
      async onConfirm() {
        if (!parentId || !childId) {
          return
        }
        await CodmonApi.delete(ParentsService.getTargetFacilityId(), parentId, childId)

        // 情報をリロード
        await ParentsService.loadUnconnectedCodmonChildren()

        onSuccess()

        ParentsService.successSnackbar(
          `${lastName}${childFirstName}さんのコドモン連携を解除しました`
        )
        const queryParams = RouteService.getQueryParams()
        ParentsService.loadTotal(ParentsService.getTargetFacilityId(), queryParams)
      },
    })
  }

  // eslint-disable-next-line max-lines-per-function
  static connectCodmon = (
    parentId: number | undefined,
    childId: number | undefined,
    parentLastName: string,
    parentFirstName: string,
    childFirstName: string,
    onSuccess: () => void
  ) => {
    DialogService.open({
      title: 'コドモンデータ連携',
      body: {
        component: {
          name: ConnectCodmonMessage,
          bind: { parentLastName, parentFirstName, childFirstName },
        },
      },
      disabledBackdrop: true,
      okButtonText: '連携する',
      async onCancel() {
        // アニメーションがあるので，その分待機させる
        await setTimeout(() => ParentsPageStore.updateSelectedCodmonId(null), 500)
      },
      async onConfirm() {
        if (!parentId || !childId || ParentsPageStore.selectedCodmonId === null) {
          return
        }

        await CodmonApi.create(
          ParentsService.getTargetFacilityId(),
          parentId,
          childId,
          ParentsPageStore.selectedCodmonId
        )

        // もとに戻す
        await setTimeout(() => ParentsPageStore.updateSelectedCodmonId(null), 500)

        // 選択した園児をプルダウンから削除するためリロード
        await ParentsService.loadUnconnectedCodmonChildren()

        onSuccess()

        ParentsService.successSnackbar(
          `${parentLastName}${childFirstName}さんをコドモン連携しました`
        )

        const queryParams = RouteService.getQueryParams()
        ParentsService.loadTotal(ParentsService.getTargetFacilityId(), queryParams)
      },
    })
  }

  static loadUnconnectedCodmonChildren = async () => {
    const result = (await CodmonApi.loadUnconnectedChildren(ParentsService.getTargetFacilityId()))
      .data
    ParentsPageStore.updateUnconnectedCodmonChildren(result)
  }

  /**
   * 単体delete
   * 選択された子どもを削除します。
   * @param selectedChildIds
   */
  private static async deleteChildren(
    selectedChildIds: ChildId[]
  ): Promise<DeleteResultResponse[]> {
    const result = await Promise.all(
      selectedChildIds.map(async ({ parentId, childId }) => {
        try {
          const res = (
            await FacilityParentApi.deleteChild({
              facilityId: ParentsService.getTargetFacilityId(),
              parentId: parentId,
              childId: childId,
            })
          ).data
          return { status: 'success', id: res.id }
        } catch (e) {
          if (isAxiosError(e)) {
            const childId = e.response?.data ? (e.response?.data as DeleteResultResponse).id : -1
            return { status: 'failed', id: childId }
          }
          return { status: 'failed', id: -1 }
        }
      })
    )

    return result
  }

  /**
   * 単体delete
   * 選択された保護者を削除します。
   * @param selectedParentIds
   */
  private static async deleteParents(
    selectedParentIds: Parent['id'][]
  ): Promise<DeleteResultResponse[]> {
    const result = await Promise.all(
      selectedParentIds.map(async (parentId) => {
        try {
          const res = (
            await FacilityParentApi.deleteParent({
              facilityId: ParentsService.getTargetFacilityId(),
              parentId: parentId,
            })
          ).data
          return { status: 'success', id: res.id }
        } catch (e) {
          if (isAxiosError(e)) {
            const parentId = e.response?.data ? (e.response?.data as DeleteResultResponse).id : -1
            return { status: 'failed', id: parentId }
          }
          return { status: 'failed', id: -1 }
        }
      })
    )

    return result
  }

  /**
   * bulk delete
   * 検索条件に一致する全ての保護者、子どもを削除します。
   * @param queryParams 検索条件
   * @returns {Array} 削除に成功した保護者のID、子どものID & 削除に失敗した保護者のID、子どものIDの配列
   */
  static async bulkDelete(
    queryParams: Omit<QueryParams, 'page' | 'per_page'>
  ): Promise<DeleteResult> {
    const result = (
      await FacilityParentApi.bulkDelete({
        facilityId: ParentsService.getTargetFacilityId(),
        queryParams,
      })
    ).data

    return {
      parents: {
        deleted_ids: result.parents.success,
        failed_deleted_ids: result.parents.failed,
        children: {
          deleted_ids: result.children.success,
          failed_deleted_ids: result.children.failed,
        },
      },
    }
  }

  static async loadParent(_parentId: Parent['id']): Promise<void> {
    try {
      const parent = (
        await FacilityParentApi.index({
          facilityId: ParentsService.getTargetFacilityId(),
          queryParams: ParentsPageStore.searchQuery,
        })
      ).data
      ParentsPageStore.updateParentSummaries(parent)
    } catch {
      SnackbarService.open('保護者情報の取得に失敗しました', 'error', {
        variant: 'flat',
        showIcon: true,
        location: 'bottom right',
      })
    }
  }

  /**
   * 別ページでの処理が成功していれば、成功を示すSnackbarを表示します。
   * 一度表示した後は成功状態を元に戻すため、成功後初回ロード時のみの表示です。
   */
  static openSuccessSnackbar(): void {
    if (ParentsPageStore.isModifySuccess) {
      const message = RouteService.hasQueryParams('deleted_parents_id')
        ? '削除しました。'
        : '変更を保存しました。'
      ParentsService.successSnackbar(message)
      ParentsPageStore.updateIsModifySuccess(false)
    }
  }

  // 成功を示すSnackbar。スタイルの過渡期で保護者一覧に一律適用するのにラップしているだけなので、
  // 将来的にスタイルが統一されたらこちらは削除し、snackbarのinterfaceを変更してください。
  private static successSnackbar(message = '変更を保存しました。'): void {
    SnackbarService.open(message, 'success', {
      variant: 'flat',
      showIcon: true,
      location: 'bottom right',
    })
  }
}
