<template>
  <v-stepper-header>
    <template v-for="(step, index) in steps" :key="step.refName">
      <v-stepper-item
        :complete="getCorporationApplyPanelStep > index + 1"
        :value="index + 1"
        color="primary"
      >
        {{ step.title }}
      </v-stepper-item>
      <v-divider v-if="index + 1 !== steps.length" :key="step.refName + 'div'"></v-divider>
    </template>
  </v-stepper-header>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { CorporationApplyPageStore } from '../corporation_apply_page_store'

defineProps<{
  steps: {
    refName: string
    title: string
  }[]
}>()

const getCorporationApplyPanelStep = computed(() => CorporationApplyPageStore.panelStep)
</script>
