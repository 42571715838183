import { HTTP } from '../common'
import { TransferStatement } from 'shared/models'
import { AxiosResponse } from 'axios'

export type Prefecture = {
  name: string
}

export type School = {
  id: number
  school_name: string
  responsible_person_name: string
  formatted_postal_code: string
  company_name: string
  prefecture: Prefecture
  city: string
  building: string
  bank_name: string
  bank_branch_name: string
  bank_account_number: string
  bank_account_holder_name: string
}

export type ShowResponse = {
  transfer_statement: TransferStatement
  start_calc_paid_date: string
  end_calc_paid_date: string
}

export class TransferStatementApi {
  /**
   * @param targetYearAndMonth yyyy-MM 形式
   */
  static show(
    facility_Id: number,
    targetYearAndMonth: string
  ): Promise<AxiosResponse<ShowResponse>> {
    return HTTP.get(`/facilities/${facility_Id}/transfer_statements/${targetYearAndMonth}`)
  }
}
