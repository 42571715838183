<template>
  <div class="d-flex align-center">
    <S3Image :path="imagePath" class="mr-2" />
    <span class="font-weight-medium mb-1">{{ value }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import S3Image from 'shared/components/s_3_image/S3Image.vue'

export default defineComponent({
  components: {
    S3Image,
  },
  props: {
    imagePath: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
})
</script>
