<template>
  <LineErrorPayment v-bind="lineErrorPaymentProps" />
</template>

<script setup lang="ts">
import { LineErrorPayment } from 'shared/components/line_payment'
import { LineErrorPaymentProps } from 'shared/components/line_payment/LineErrorPayment.vue'
import PaymentMethod from 'shared/consts/string/payment_method'

const props = defineProps<Omit<LineErrorPaymentProps, 'paymentMethod'>>()
const lineErrorPaymentProps = {
  ...props,
  paymentMethod: PaymentMethod.linepay,
}
</script>
