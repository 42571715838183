<template>
  <FormContainer>
    <template #title>ID（メールアドレス）</template>
    <template #form-content>
      <v-row>
        <v-col cols="5">
          <div class="text-subtitle-1 font-weight-medium">現在のメールアドレス</div>
        </v-col>
        <v-spacer />
        <v-col cols="5">{{ currentEmail }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="5">
          <label class="text-subtitle-1 font-weight-medium mb-2" for="new-email-input">
            新しいメールアドレス
          </label>
          <div class="text-subtitle-1 font-weight-medium item-description">
            入力したメールアドレスに認証メールが送信されます。
          </div>
        </v-col>
        <v-spacer />
        <v-col cols="5">
          <TextInput
            id="new-email-input"
            data-testid="new-email-input"
            :value="newEmail"
            :on-change="(value: string) => (newEmail = value)"
            :rules="newEmailRules"
            density="default"
            placeholder="sample@enpay.co.jp"
            class="font-weight-medium"
          />
        </v-col>
      </v-row>
    </template>
    <template #actions="{ formRef }">
      <Button
        size="large"
        variant="outlined"
        :disabled="isButtonDisabled"
        @click="sendConfirmationEmail(formRef)"
        >認証メールを送信する</Button
      >
    </template>
  </FormContainer>
</template>
<script setup lang="ts">
import FormContainer from 'pages/account_settings_page/components/FormContainer.vue'
import TextInput from 'shared/components/TextInput.vue'
import Button from 'shared/components/Button.vue'
import { ref, computed } from 'vue'
import { AccountSettingEmailApi } from 'http/modules/account_setting_email'
import { SnackbarService } from 'shared/services/snackbar_service'
import { VForm } from 'vuetify/components'

const props = defineProps<{
  currentEmail: string
}>()

const newEmail = ref('')
const isSending = ref(false)
const newEmailRules = [
  (value: string) =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    ) || 'メールアドレスの形式で入力してください',
  (value: string) => value !== props.currentEmail || 'メールアドレスが同じです',
]
const isButtonDisabled = computed(() => {
  // validation結果取得
  const hasNewEmailError = newEmailRules.some((rule) => typeof rule(newEmail.value) === 'string')
  return hasNewEmailError || isSending.value
})

const resetEmailInput = (ref: VForm) => {
  newEmail.value = ''
  ref.reset()
}

const sendConfirmationEmail = async (ref: VForm | null) => {
  try {
    if (!ref) throw new Error('formRef is not found')

    isSending.value = true
    const params = {
      email: newEmail.value,
    }

    await AccountSettingEmailApi.update(params)
    resetEmailInput(ref)

    const message = '認証メールを送信しました。'
    SnackbarService.open(message, 'success', {
      variant: 'flat',
      showIcon: true,
      location: 'bottom right',
    })
  } catch (error) {
    const message = '認証メールの送信に失敗しました。'
    SnackbarService.open(message, 'error', {
      variant: 'flat',
      showIcon: true,
      location: 'bottom right',
    })
  } finally {
    isSending.value = false
  }
}
</script>
<style scoped>
.item-description {
  color: #727272;
}
</style>
