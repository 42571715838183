import { ExportInvoiceItemsPageStore } from 'pages/export_invoice_items_page/export_invoice_items_page_store'
import { SearchYearAndMonthType, SelectableMonths } from 'pages/export_invoice_items_page/model'
import { getAddMonth, getDate, getMonth, getSubtractMonth, getYear } from 'shared/utils/date'

/**
 * 検索条件の対象年月の選択範囲を計算するService
 */
export class CalculateYearAndMonthRangeService {
  /**
   * ドロップダウンで選択されている年月に応じて、ドロップダウンで選択可能な年月の範囲を計算する
   */
  static setSelectableYearAndMonthRange(): void {
    const from = getDate(CalculateYearAndMonthRangeService.concatSearchYearAndMonth('from'))
    const to = getDate(CalculateYearAndMonthRangeService.concatSearchYearAndMonth('to'))
    // 請求を作成できるのは、施設作成日の前月から、現在の月の2ヶ月後まで(請求一覧と同様)
    const creatableInvoiceStartDate = getSubtractMonth(
      ExportInvoiceItemsPageStore.oldestFacilityCreatedAt,
      1
    )
    const creatableInvoiceEndDate = getAddMonth(new Date(), 2)

    const selectableYears = CalculateYearAndMonthRangeService.calculateSelectableYear(
      creatableInvoiceStartDate,
      creatableInvoiceEndDate
    )

    const { selectableFromMonth, selectableToMonth } =
      CalculateYearAndMonthRangeService.calculateSelectableMonth(
        from,
        to,
        creatableInvoiceStartDate,
        creatableInvoiceEndDate
      )

    ExportInvoiceItemsPageStore.updateSelectableFromYearAndMonthRange({
      years: selectableYears,
      months: selectableFromMonth,
    })
    ExportInvoiceItemsPageStore.updateSelectableToYearAndMonthRange({
      years: selectableYears,
      months: selectableToMonth,
    })
  }

  /**
   * 検索条件の対象「年」の選択範囲を計算する
   * 「年」は開始年月と終了年月で共通の選択範囲を持つ
   */
  private static calculateSelectableYear(
    creatableInvoiceStartDate: Date,
    creatableInvoiceEndDate: Date
  ): number[] {
    const selectableYears = []

    for (
      let i = getYear(creatableInvoiceStartDate);
      i <= getYear(creatableInvoiceEndDate);
      i += 1
    ) {
      selectableYears.push(i)
    }

    return selectableYears
  }

  /**
   * 検索条件の対象「月」の選択範囲を計算する
   * 「月」は開始年月と終了年月で異なる選択範囲を持つ
   * 「開始月」は「開始年」を参照して、選択範囲を計算する
   * 「終了月」は「終了年」を参照して、選択範囲を計算する
   */
  private static calculateSelectableMonth(
    from: Date,
    to: Date,
    creatableInvoiceStartDate: Date,
    creatableInvoiceEndDate: Date
  ): SelectableMonths {
    const selectableFromMonths = []
    const selectableToMonths = []

    // 各「月」の選択範囲の始点・終点を計算する
    const availableFromRange = CalculateYearAndMonthRangeService.calculateAvailableRange(
      creatableInvoiceStartDate,
      creatableInvoiceEndDate,
      from
    )
    const availableToRange = CalculateYearAndMonthRangeService.calculateAvailableRange(
      creatableInvoiceStartDate,
      creatableInvoiceEndDate,
      to
    )

    for (let i = availableFromRange.startMonth; i <= availableFromRange.endMonth; i += 1) {
      selectableFromMonths.push(i)
    }
    for (let i = availableToRange.startMonth; i <= availableToRange.endMonth; i += 1) {
      selectableToMonths.push(i)
    }

    return {
      selectableFromMonth: selectableFromMonths,
      selectableToMonth: selectableToMonths,
    }
  }

  private static calculateAvailableRange(
    creatableInvoiceStartDate: Date,
    creatableInvoiceEndDate: Date,
    target: Date
  ) {
    let startMonth: number
    let endMonth: number

    // 請求作成可能な最初の日付の年と同じなら、選択候補の始点がその年の月から始まる
    // 請求作成可能な最も未来の日付の年と同じなら、選択候補の終点がその年の月で終わる
    const isCreatableInvoiceStartThisYear = getYear(creatableInvoiceStartDate) === getYear(target)
    const isCreatableInvoiceEndThisYear = getYear(creatableInvoiceEndDate) === getYear(target)

    if (isCreatableInvoiceStartThisYear) {
      startMonth = getMonth(creatableInvoiceStartDate)
    } else {
      startMonth = 1
    }

    if (isCreatableInvoiceEndThisYear) {
      // 選択している「年」が2ヶ月後の年と同じ場合
      endMonth = getMonth(creatableInvoiceEndDate)
    } else {
      // 選択している「年」が2ヶ月後の年と異なる場合(過去の年となる)
      endMonth = 12
    }
    return { startMonth, endMonth }
  }

  private static concatSearchYearAndMonth(target: SearchYearAndMonthType): string {
    switch (target) {
      case 'to':
        return `${ExportInvoiceItemsPageStore.toYearAndMonth.year}-${ExportInvoiceItemsPageStore.toYearAndMonth.month}`
      case 'from':
        return `${ExportInvoiceItemsPageStore.fromYearAndMonth.year}-${ExportInvoiceItemsPageStore.fromYearAndMonth.month}`
      default:
        throw new Error('invalid target')
    }
  }
}
