import { MenuDirectory, Menu } from 'shared/models'

export const allCorporationMenu: Array<MenuDirectory | Menu> = [
  {
    id: 'transfer',
    name: '振込管理',
    url: '/transfer',
  },
  {
    id: 'invoice_management',
    name: '請求管理',
    url: '/invoice_management',
  },
  {
    id: 'export_invoice_items',
    name: '請求内容ダウンロード',
    url: '/invoice_items/export_requests',
  },
]

export const allFacilityMenu: Array<MenuDirectory | Menu> = [
  {
    name: '請求管理',
    menus: [
      {
        id: 'invoice',
        name: '請求一覧',
        url: '/invoices',
      },
      {
        id: 'invoice_download',
        name: '請求内容ダウンロード',
        url: '/invoice_items/export_requests',
      },
      {
        id: 'transfer_statement',
        name: '振込明細書',
        url: '/transfer_statements',
      },
    ],
  },
  {
    name: '設定',
    menus: [
      {
        id: 'class',
        name: 'クラス一覧',
        url: '/classes',
      },
      {
        id: 'parent',
        name: '保護者一覧',
        url: '/parents',
      },
      {
        id: 'invoice_item_master',
        name: '請求内容マスタ設定',
        url: '/invoice_item_name_candidates',
      },
      {
        id: 'facility',
        name: '施設情報設定',
        url: '',
      },
    ],
  },
]
