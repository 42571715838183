<template>
  <SimpleLayout
    :title="!isImportCompleted ? '保護者一括 登録' : '保護者一括 登録完了'"
    is-expanded
    class="container"
    :has-alert="isDeprecatedBrowser"
  >
    <template #headerNav>
      <FacilityHeader />
    </template>

    <template #alert>
      <template v-if="isDeprecatedBrowser">
        <div class="fixed-alert">
          <UserAgentAlert />
        </div>
      </template>
    </template>

    <template #main>
      <ImportRequestPanel
        v-if="!isImportCompleted"
        :back-path="parentsChildrenManagementPagePath"
        :is-deprecated-browser="isDeprecatedBrowser"
        :csv-import-helppage-url="csvImportHelppageUrl"
        :excel-import-helppage-url="excelImportHelppageUrl"
        :is-loading="isLoading"
        class="container"
        @download-template="downloadTemplate"
        @handle-click-upload-file="handleClickUploadFile"
      />
      <CompletedImportRequestPanel
        v-if="isImportCompleted"
        :back-path="parentsChildrenManagementPagePath"
        :is-deprecated-browser="isDeprecatedBrowser"
        class="container"
      />
      <ScrollableDialog :is-show-modal="isShowDuplicatedDialog" />
      <Dialog v-if="!isShowDuplicatedDialog" />
    </template>
  </SimpleLayout>
</template>

<script lang="ts">
import { defineComponent, computed, onUnmounted } from 'vue'
import UserAgentAlert from 'shared/components/user_agent_alert/UserAgentAlert.vue'
import FacilityHeader from 'shared/components/header/FacilityHeader.vue'
import { UserAgentService } from 'shared/services/user_agent_service'
import CompletedImportRequestPanel from './CompletedImportRequestPanel.vue'
import ImportRequestPanel from './ImportRequestPanel.vue'
import { SchoolService } from 'pages/top_page/services/school_service'
import { ImportParentsPageStore } from 'pages/import_parents_page/import_parents_page_store'
import { ImportParentsService } from 'pages/import_parents_page/services/import_parents_service'
import SimpleLayout from 'shared/templates/SimpleLayout.vue'
import Dialog from 'shared/components/Dialog.vue'
import ScrollableDialog from 'shared/components/ScrollableDialog.vue'
import ExternalLink from 'shared/consts/external_link'
import { MetaService } from 'shared/services/meta_service'

export default defineComponent({
  components: {
    UserAgentAlert,
    FacilityHeader,
    CompletedImportRequestPanel,
    ImportRequestPanel,
    SimpleLayout,
    Dialog,
    ScrollableDialog,
  },
  // eslint-disable-next-line max-lines-per-function
  setup() {
    MetaService.addTitle({ mode: 'facility', page: 'importParents' })
    const isLoading = computed(() => ImportParentsPageStore.isLoading)
    const isShowDuplicatedDialog = computed({
      get: () => ImportParentsPageStore.isShowDuplicatedDialog,
      set: (value) => ImportParentsPageStore.updateIsShowDuplicatedDialog(value),
    })

    // methods
    const isDeprecatedBrowser = UserAgentService.isDeprecatedBrowser()
    const parentsChildrenManagementPagePath = `/facilities/${SchoolService.getTargetFacilityId()}/parents`
    const csvImportHelppageUrl = ExternalLink.openpageCsvImportParents
    const excelImportHelppageUrl = ExternalLink.openpageExcelImportParents
    const downloadTemplate = (fileType: 'csv' | 'excel') =>
      ImportParentsService.downloadTemplate(fileType)
    const handleClickUploadFile = () => ImportParentsService.handleClickUploadFile()
    const isImportCompleted = computed(() => ImportParentsPageStore.isImportCompleted)

    onUnmounted(() => {
      ImportParentsPageStore.updateImportTargetFile([])
      ImportParentsPageStore.updateIsImportCompleted(false)
      ImportParentsPageStore.updateImportFileErrors([])
      ImportParentsPageStore.updateImportFileErrorsCount(0)
    })

    return {
      isDeprecatedBrowser,
      parentsChildrenManagementPagePath,
      csvImportHelppageUrl,
      excelImportHelppageUrl,
      isLoading,
      isShowDuplicatedDialog,
      downloadTemplate,
      handleClickUploadFile,
      isImportCompleted,
    }
  },
})
</script>

<style scoped lang="scss">
.fixed-alert {
  position: fixed;
  width: 100%;
  top: 63px;
  z-index: initial;
}
.w-100 {
  width: 100%;
}

@media (1264px <= width) {
  .container {
    max-width: 1185px;
  }
}

@media (960px < width <= 1264px) {
  .container {
    max-width: 900px;
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding: 12px;
  width: 100%;
}
</style>
