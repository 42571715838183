import { CandidateColumnId, CsvImportStatus, ImportId } from 'shared/components/import_panel/models'
import { FacilityId } from 'shared/models'
import { HTTP } from '../common'

export type PostResponse = {
  data: {
    id: ImportId
  }
}

export type ShowResponse = {
  data: CsvImportStatus
}

export class CsvInvoicesApi {
  /**
   * @param targetYearMonth yyyy-MM 形式
   */
  static post(
    facilityId: number,
    columns: (CandidateColumnId | undefined)[],
    targetYearMonth: string,
    isFirstLineIgnored: boolean,
    file: File
  ): Promise<PostResponse> {
    const params = new FormData()
    params.append('uploaded_file', file)
    return HTTP.post(
      `/facilities/${facilityId}/csv_invoices?columns=${columns.join(
        ','
      )}&target_year_and_month=${targetYearMonth}&has_header=${isFirstLineIgnored}`,
      params
    )
  }

  static show(facilityId: FacilityId, importId: ImportId): Promise<ShowResponse> {
    return HTTP.get(`/facilities/${facilityId}/csv_invoices/${importId}`)
  }
}
