<template>
  <div>
    <div class="text-h5 mb-6">
      <div class="ml-4">
        それぞれの列がどのようなデータかを指定し、取り込みボタンを押してください。
      </div>
    </div>
    <v-alert v-if="!isUpdatable" color="warning" variant="outlined" class="mb-6">
      <div class="d-flex align-center">
        <v-icon start color="warning">mdi-information</v-icon>
        <div class="font-weight-bold">以下のデータに対応する列を指定してください。</div>
      </div>
      <dl class="mt-4" style="color: rgba(0, 0, 0, 0.87)">
        <dt>
          ■ 保護者一覧に「<span class="font-weight-bold">保護者名あり、</span
          ><span class="text-primary font-weight-bold">子ども名あり</span
          >」で登録している方へ請求する場合
        </dt>
        <dd class="ml-4">{{ notMappedChildRequiredCandidatesString }}</dd>
        <dt class="mt-2">
          ■ 保護者一覧に「<span class="font-weight-bold">保護者名あり、</span
          ><span class="text-red font-weight-bold">子ども名なし</span
          >」で登録している方へ請求する場合
        </dt>
        <dd class="ml-4">{{ notMappedParentRequiredCandidatesString }}</dd>
      </dl>
      <div class="d-flex flex-row align-center mt-6">
        <v-icon class="mr-1" size="small" color="primary">mdi-help-circle</v-icon>
        <span class="text-black text-body-1">データの指定について</span>
        <LinkText
          :link-url="helpPageUrl"
          is-open-new-tab
          @click="$eventTracker.trackEvent('click_btn_invoice_import_mapping_help', {})"
          >詳しくはこちら</LinkText
        >
      </div>
    </v-alert>
    <v-alert v-if="isUpdatable" color="info" variant="outlined" class="mb-6">
      <div class="d-flex align-center">
        <v-icon start color="info">mdi-information</v-icon>
        <div class="font-weight-bold">
          データの指定が完了しました。取り込みボタンを押してください。
        </div>
      </div>
    </v-alert>
    <div class="table-wrapper">
      <SimpleTable class="preview-table">
        <thead>
          <tr>
            <th v-for="(_, index) in csvFileMetadata.colCount" :key="index">
              <div class="d-flex align-center">
                <v-select
                  :model-value="mappedColumnIds[index]"
                  class="select"
                  variant="outlined"
                  density="compact"
                  clearable
                  clear-icon="mdi-close"
                  :hide-details="true"
                  :items="columnCandidates"
                  item-title="column_name"
                  item-value="column_id"
                  @update:model-value="changeMappedColumnIds(index, $event)"
                ></v-select>

                <v-tooltip v-if="autoMappingStatuses[index]" location="top">
                  <template #activator="{ props }">
                    <v-icon v-bind="props" color="info" class="text-h6 ml-2">
                      mdi-check-circle
                    </v-icon>
                  </template>
                  <span>自動で補完しました</span>
                </v-tooltip>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <!--
            以下の仕様を満たすように実装しています
            - 最大でmaxPreviewRowCount(10)行までデータを表示する
            - 11行以上のデータが存在する場合は中略を表す行を表示する
            - start_rowsとend_rowsには、total_countが10未満の場合重複があるのでそれを排除し表示する
          -->
          <tr
            v-for="(row, index) in csvFileMetadata.start_rows"
            :key="'start-' + index"
            :class="{ 'grey-out': index === 0 && isFirstLineIgnored }"
          >
            <td v-for="(cell, cellIndex) in row" :key="cellIndex">{{ cell }}</td>
          </tr>
          <tr v-if="csvFileMetadata.total_count > maxPreviewRowCount">
            <td :colspan="csvFileMetadata.colCount" class="text-center omission">
              <div class="d-flex justify-space-around">
                <v-icon v-for="index in 3" :key="index">mdi-dots-vertical</v-icon>
              </div>
            </td>
          </tr>
          <tr v-for="(row, index) in dedupedEndRows" :key="'end-' + index">
            <td v-for="(cell, cellIndex) in row" :key="cellIndex">{{ cell }}</td>
          </tr>
        </tbody>
      </SimpleTable>
    </div>
    <div class="pl-2">
      <v-checkbox
        v-model="isFirstLineIgnored"
        label="1行目は無視する（1行目がヘッダーの場合にチェックしてください）"
      ></v-checkbox>
    </div>
    <div>
      <v-tooltip location="top" :disabled="isUpdatable">
        <template #activator="{ props }">
          <!-- disabledがtrueの場合はeventのターゲットにならないため、v-btnではなくdivで囲んでターゲットに -->
          <div class="d-inline-block" v-bind="props">
            <Button
              size="large"
              :disabled="!isUpdatable || isImporting"
              :loading="isImporting"
              @click="executeImportCsv()"
            >
              取り込み
            </Button>
          </div>
        </template>
        <span>画面上部の案内に沿って、データの指定を行ってください</span>
      </v-tooltip>
      <Button
        color="normal"
        size="large"
        variant="text"
        :disabled="isImporting"
        @click="decrementStep()"
      >
        戻る
      </Button>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { ImportPanelStore } from '../import_panel_store'
import { ColumnMappingService } from '../services/column_mapping_service'
import SimpleTable from './SimpleTable.vue'
import { ImportPanelService, maxPreviewRowCount } from '../services/import_panel_service'
import { CandidateColumnId, CsvImportStatus } from '../models'
import Button from 'shared/components/Button.vue'
import LinkText from 'shared/components/LinkText.vue'
import ExternalLink from 'shared/consts/external_link'

/**
 * カラムマッピングを行うためのパネルです。
 */
export default defineComponent({
  components: { SimpleTable, Button, LinkText },
  props: {
    importCsv: {
      type: Function as unknown as () => (
        mappedColumnIds: (CandidateColumnId | undefined)[],
        isFirstLineIgnored: boolean,
        file: File
      ) => Promise<number>,
      required: true,
    },
    getImportStatus: {
      type: Function as unknown as () => (importId: number) => Promise<CsvImportStatus>,
      required: true,
    },
  },
  // eslint-disable-next-line max-lines-per-function
  setup(props) {
    const helpPageUrl = ExternalLink.openpageCsvImportInvoices

    // computed
    const columnCandidates = computed(() =>
      ImportPanelStore.columnCandidates.flatMap((candidate) => candidate.options)
    )
    const isFirstLineIgnored = computed({
      get: () => ImportPanelStore.isFirstLineIgnored,
      set: (value) => ImportPanelStore.updateIsFirstLineIgnored(value),
    })
    const mappedColumnIds = ImportPanelStore.mappedColumnIds
    const csvFileMetadata = computed(() => ImportPanelStore.csvFileMetadata)
    const dedupedEndRows = ColumnMappingService.dedupedEndRows()
    // 保護者への請求登録に必要なカラム（type: parent | common）のうち、マッピングできていないカラム情報を返す
    const notMappedParentRequiredCandidates = computed(() =>
      ColumnMappingService.notMappedCandidates().filter(
        (candidate) =>
          candidate.required && (candidate.type === 'parent' || candidate.type === 'common')
      )
    )
    // 保護者への請求登録に必要なカラムのうち、マッピングできていないカラムの表示用文字列を算出 ex) 保護者IDまたは保護者コード、請求内容、数量、単価
    const notMappedParentRequiredCandidatesString = computed(() =>
      notMappedParentRequiredCandidates.value
        .map((candidate) => candidate.options.map((option) => option.column_name).join('または'))
        .join('、')
    )
    // 子どもへの請求登録に必要なカラム（type: child | common）のうち、マッピングできていないカラム情報を返す
    const notMappedChildRequiredCandidates = computed(() =>
      ColumnMappingService.notMappedCandidates().filter(
        (candidate) =>
          candidate.required && (candidate.type === 'child' || candidate.type === 'common')
      )
    )
    // 子どもへの請求登録に必要なカラムのうち、マッピングできていないカラムの表示用文字列を算出 ex) 子どもIDまたは子どもコード、請求内容、数量、単価
    const notMappedChildRequiredCandidatesString = computed(() =>
      notMappedChildRequiredCandidates.value
        .map((candidate) => candidate.options.map((option) => option.column_name).join('または'))
        .join('、')
    )
    const autoMappingStatuses = computed(() => ImportPanelStore.autoMappingStatuses)
    const isImporting = computed(() => ImportPanelStore.isImporting)
    // 以下のいずれかが揃っている場合、取り込み可能と判断する
    // 1. 保護者への請求登録の必須カラムすべてがマッピングされている
    // 2. 子どもへの請求登録の必須カラムすべてがマッピングされている
    const isUpdatable = computed(
      () =>
        notMappedParentRequiredCandidates.value.length === 0 ||
        notMappedChildRequiredCandidates.value.length === 0
    )

    // methods
    const executeImportCsv = () =>
      ColumnMappingService.executeImportCsv(props.importCsv, props.getImportStatus)
    const changeMappedColumnIds = (index: number, $event: number | null) =>
      ColumnMappingService.changeMappedColumnIds(index, $event)
    const decrementStep = () => ImportPanelService.decrementStep()

    return {
      helpPageUrl,
      columnCandidates,
      maxPreviewRowCount,
      isFirstLineIgnored,
      mappedColumnIds,
      csvFileMetadata,
      dedupedEndRows,
      notMappedParentRequiredCandidates,
      notMappedParentRequiredCandidatesString,
      notMappedChildRequiredCandidates,
      notMappedChildRequiredCandidatesString,
      autoMappingStatuses,
      isImporting,
      isUpdatable,
      executeImportCsv,
      changeMappedColumnIds,
      decrementStep,
    }
  },
})
</script>
<style lang="scss" scoped>
.select {
  background-color: white;
  min-width: 190px;
}
.omission {
  height: 56px;
}
.ignore-lines-selector {
  width: 48px;
}
</style>
