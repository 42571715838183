/* eslint-disable max-lines */
import { ImportParentsPageStore } from 'pages/import_parents_page/import_parents_page_store'
import { RouteService } from 'shared/services/route_service'
import { DialogService } from 'shared/services/dialog_service'
import DuplicatedParentsChildrenMessage from 'pages/import_parents_page/components/DuplicatedParentsChildrenMessage.vue'
import DuplicatedParentsMessage from 'pages/import_parents_page/components/DuplicatedParentsMessage.vue'
import { EventTracker } from 'shared/utils/_event_tracking'
import { ParentImportRequestApi } from 'http/modules/parent_import_request'
import { ParentImportTemplateApi } from 'http/modules/parent_import_template'
import {
  isConfirmationBadRequest,
  isConfirmationError,
  isConfirmationWarning,
  ParentImportConfirmationApi,
} from 'http/modules/parent_import_confirmation'
import { isAxiosError } from 'http/modules/invoice'
import { SnackbarService } from 'shared/services/snackbar_service'
import { ScreenLoadingService } from 'shared/services/screen_loading_service'
import { SchoolService } from 'pages/top_page/services/school_service'
import { groupBy } from 'shared/utils/groupBy'
import { DuplicationWarning, ImportFileError } from 'pages/import_parents_page/model'
import { FileService } from 'shared/services/file_service'
import BadRequestMessage from 'pages/import_parents_page/components/BadRequestMessage.vue'
import ExternalLink from 'shared/consts/external_link'

/** 保護者一括登録に関するロジックを持ちます */
export class ImportParentsService {
  static getTargetFacilityId(): number {
    return Number(RouteService.getPathParamByIndex(1))
  }

  static downloadTemplate(fileType: 'csv' | 'excel') {
    const facilityId = this.getTargetFacilityId()
    try {
      ParentImportTemplateApi.show(facilityId, fileType)
    } catch (error) {
      SnackbarService.open('テンプレートのダウンロードに失敗しました', 'error')
    } finally {
      ScreenLoadingService.hideLoader()
    }
  }

  static async handleClickUploadFile(): Promise<void> {
    const importTargetFile = ImportParentsPageStore.importTargetFile
    if (!importTargetFile || !importTargetFile.length) return
    ImportParentsPageStore.updateIsLoading(true)

    try {
      const workerProcessingId = await this.startImportParents(importTargetFile[0], false)

      this.handleImportComplete(
        importTargetFile[0],
        SchoolService.getTargetFacilityId(),
        workerProcessingId
      )
    } catch (e) {
      if (!isAxiosError(e) || e.response === undefined || e.response.status === 500) {
        this.handleImportFailed()
        return
      }

      if (isConfirmationBadRequest(e)) {
        const helpPageUrl = ExternalLink.openpageTroubleshootingExcelAndCsv
        const {
          error: [error],
          // @ts-ignore
        } = e.response.data.errors
        this.handleBadRequest(error, helpPageUrl)
      } else if (isConfirmationError(e)) {
        // @ts-ignore
        const { error } = e.response.data.errors
        // エラー表示
        // @ts-ignore
        const importFileErrors = Object.values(groupBy(error, ({ row_num }) => row_num))
        // @ts-ignore
        ImportParentsPageStore.updateImportFileErrors(importFileErrors)
        ImportParentsPageStore.updateImportFileErrorsCount(error.length)
        ImportParentsPageStore.updateIsLoading(false)
        ImportParentsPageStore.updateImportTargetFile([])
      } else if (isConfirmationWarning(e)) {
        // 重複警告表示
        // @ts-ignore
        this.handleDuplicatedWarn(e.response.data.errors.warn)
      } else {
        this.handleImportFailed()
      }
    }
  }

  /**
   *  重複警告をスキップしたファイルアップロード処理
   */
  static async handleClickUploadFileWithoutDuplicatedWarn(): Promise<void> {
    const importTargetFile = ImportParentsPageStore.importTargetFile
    if (!importTargetFile) return
    ImportParentsPageStore.updateIsLoading(true)
    ImportParentsPageStore.updateIsShowDuplicatedDialog(false)

    try {
      const workerProcessingId = await this.startImportParents(importTargetFile[0], true)

      this.handleImportComplete(
        importTargetFile[0],
        SchoolService.getTargetFacilityId(),
        workerProcessingId
      )
    } catch (e) {
      if (isAxiosError(e) && e.response !== undefined) {
        this.handleImportFailed()
      }
    }
  }

  /**
   *  エラー内容によって表示させる文言を変更する
   */
  static getErrorTypeDetail(errorType: string): string {
    return (
      {
        non_existent_school_class_on_db: '登録されていないクラスが使用されています',
        non_existent_parent_id_on_db: '登録されていない保護者IDが使用されています',
        non_existent_child_id_on_db: '登録されていない子どもIDが使用されています',
        invalid_parent_and_child_relation_on_db: '親子関係が正しくありません',
        duplicate_parent_and_child_name_on_db: '同じ名前の保護者と子どもが存在します',
        invalid_parent_first_name: '保護者名が正しくありません',
        invalid_parent_last_name: '保護者姓が正しくありません',
        invalid_parent_code: '保護者コードが正しくありません',
        invalid_child_first_name: '子ども名が正しくありません',
        already_invoice_created: '保護者に対する請求が作られているため、子どもを追加できません',
        invalid_child_code: '子どもコードが正しくありません',
        empty_child_first_name: '子ども名が未入力です',
        empty_class_name: 'クラスが未入力です',
        different_names_between_same_id_on_file: '同一の保護者IDに対して、名前が異なっています',
        duplicate_parent_code_on_file: '保護者コードが重複しています',
        duplicate_child_code_on_file: '子どもコードが重複しています',
        different_parent_codes_between_same_id_on_file:
          '同じ保護者IDに対して、保護者コードが異なっています',
        different_child_codes_between_same_id_on_file:
          '同じ子どもIDに対して、子どもコードが異なっています',
        changed_codmon_parent_last_name: 'コドモン連携をしている場合は保護者姓は変更できません',
        changed_codmon_parent_first_name: 'コドモン連携をしている場合は保護者名は変更できません',
        changed_codmon_child_first_name: 'コドモン連携をしている場合は子ども名は変更できません',
      }[errorType] || '入力値が正しくありません'
    )
  }

  /**
   *  ParentImportRequestApi.createへのリクエストを行う
   *  workerProcessingIdを返り値として取得する
   */
  private static async startImportParents(
    importTargetFile: File,
    isConfirmed: boolean
  ): Promise<number> {
    if (!FileService.isCsvFile(importTargetFile) && !FileService.isExcelFile(importTargetFile)) {
      throw new Error('インポートに失敗しました')
    }

    if (!isConfirmed) {
      await ParentImportConfirmationApi.create(
        SchoolService.getTargetFacilityId(),
        importTargetFile
      )
    }

    const {
      data: { id: workerProcessingId },
    } = await ParentImportRequestApi.create(SchoolService.getTargetFacilityId(), importTargetFile)

    return workerProcessingId
  }

  /**
   *  重複があった時に発火する
   *  重複している保護者・子どもの名前をダイアログで表示する
   */
  private static handleDuplicatedWarn(warn: DuplicationWarning[]): void {
    const duplicateParentAndChildNameOnDbWarns = warn.filter(
      ({ error_type }) => error_type === 'duplicate_parent_and_child_name_on_db'
    )
    const duplicateNamesOnFileWarns = warn.filter(
      ({ error_type }) => error_type === 'duplicate_names_on_file'
    )
    const duplicateParentNamesBetweenNewRecordAndSavedRecordOnFileWarns = warn.filter(
      ({ error_type }) =>
        error_type === 'duplicate_parent_names_between_new_record_and_saved_record_on_file'
    )
    const duplicateParentNamesBetweenNewRecordAndSavedRecordOnDbWarns = warn.filter(
      ({ error_type }) =>
        error_type === 'duplicate_parent_names_between_new_record_and_saved_record_on_db'
    )

    switch (true) {
      // A: 保護者・子どもの重複がある場合
      case duplicateParentAndChildNameOnDbWarns.length > 0:
        this.handleDuplicateParentAndChildNameWarns(duplicateParentAndChildNameOnDbWarns)
        break
      // A: 保護者・子どもの重複がある場合
      case duplicateNamesOnFileWarns.length > 0:
        this.handleDuplicateParentAndChildNameWarns(duplicateNamesOnFileWarns)
        break
      // B: 保護者のみの重複がある場合
      case duplicateParentNamesBetweenNewRecordAndSavedRecordOnFileWarns.length > 0:
        this.handleDuplicateParentNamesBetweenNewRecordAndSavedRecordWarns(
          duplicateParentNamesBetweenNewRecordAndSavedRecordOnFileWarns
        )
        break
      // B: 保護者のみの重複がある場合
      case duplicateParentNamesBetweenNewRecordAndSavedRecordOnDbWarns.length > 0:
        this.handleDuplicateParentNamesBetweenNewRecordAndSavedRecordWarns(
          duplicateParentNamesBetweenNewRecordAndSavedRecordOnDbWarns
        )
        break
      default:
        throw new Error('警告の表示に失敗しました')
    }

    ImportParentsPageStore.updateIsShowDuplicatedDialog(true)
    ImportParentsPageStore.updateIsLoading(false)
  }

  /**
   *  重複がある場合の警告表示
   *  A: 保護者・子どもの重複がある場合
   *  Aの方が重要なエラーのため、優先的に表示させる
   */
  private static handleDuplicateParentAndChildNameWarns(warn: DuplicationWarning[]): void {
    // 保護者の姓名、子ども名を複合キーとして使用して、重複している行をグループ化する
    const duplicatedErrors = Object.values(
      groupBy(
        warn,
        ({ error_detail }) =>
          `${error_detail.parent_last_name}_${error_detail.parent_first_name}_${error_detail.child_name}`
      )
    )

    DialogService.open({
      title: '重複している保護者・子どもがいます',
      body: {
        component: {
          name: DuplicatedParentsChildrenMessage,
          bind: { duplicatedErrors },
        },
      },
      modalWidth: 700,
      okButtonText: 'このまま保存する',
      cancelButtonText: '戻って修正する',
      onConfirm: () => {
        this.handleClickUploadFileWithoutDuplicatedWarn()
      },
      onCancel: () => {
        ImportParentsPageStore.updateIsShowDuplicatedDialog(false)
        ImportParentsPageStore.updateImportTargetFile([])
      },
    })
  }

  /**
   *  重複がある場合の警告表示
   *  B: 保護者のみの重複がある場合
   *  Aの次に表示させる
   */
  private static handleDuplicateParentNamesBetweenNewRecordAndSavedRecordWarns(
    warn: DuplicationWarning[]
  ): void {
    // 保護者の姓名を複合キーとして使用して、重複している行をグループ化する
    const duplicatedErrors = Object.values(
      groupBy(
        warn,
        ({ error_detail }) => `${error_detail.parent_last_name}_${error_detail.parent_first_name}`
      )
    )

    const maxColumnsCount = Math.max(...duplicatedErrors.map((row) => row.length))

    DialogService.open({
      title: '重複している保護者がいます',
      body: {
        component: {
          name: DuplicatedParentsMessage,
          bind: { duplicatedErrors, maxColumnsCount },
        },
      },
      modalWidth: 700,
      okButtonText: 'このまま保存する',
      cancelButtonText: '戻って修正する',
      onConfirm: () => {
        this.handleClickUploadFileWithoutDuplicatedWarn()
      },
      onCancel: () => {
        ImportParentsPageStore.updateIsShowDuplicatedDialog(false)
        ImportParentsPageStore.updateImportTargetFile([])
      },
    })
  }

  /**
   *  取り込みが完了したときの処理
   */
  private static handleImportComplete(
    importTargetFile: File,
    facilityId: number,
    workerProcessingId: number
  ): void {
    // import処理開始後、2秒ごとにimport状況を取得し画面に反映する
    const intervalId = setInterval(async () => {
      const {
        data: { status: importStatus },
      } = await ParentImportRequestApi.show(facilityId, workerProcessingId)
      switch (importStatus) {
        case 'done':
          clearInterval(intervalId)
          ImportParentsPageStore.updateIsLoading(false)
          ImportParentsPageStore.updateIsImportCompleted(true)
          this.sendGaEvent(importTargetFile)
          break
        case 'error':
          clearInterval(intervalId)
          this.handleImportFailed()
          break
        case 'processing':
          break
        default:
          throw new Error('不正なステータスです')
      }
    }, 2000)
  }

  /**
   *  アップロードに失敗したときの処理
   */
  private static handleImportFailed(): void {
    SnackbarService.open('アップロードに失敗しました', 'error')
    ImportParentsPageStore.updateIsLoading(false)
  }

  private static handleBadRequest(error: ImportFileError, helpPageUrl: string): void {
    ImportParentsPageStore.updateIsLoading(false)
    ImportParentsPageStore.updateImportTargetFile([])
    if (error.error_type === 'invalid_worksheet') {
      DialogService.openMessage({
        title: 'このファイルは取り込めませんでした',
        message: [
          'テンプレートが間違っている可能性があります。',
          'もう一度ファイルの内容を確認し、取り込んでください。',
        ],
      })
    } else {
      DialogService.open({
        title: 'このファイルは取り込めませんでした',
        body: {
          component: {
            name: BadRequestMessage,
            bind: { helpPageUrl },
          },
        },
        isShowCancel: false,
      })
    }
  }

  /**
   *  GoogleAnalyticsのイベントを送信する
   */
  private static sendGaEvent(file: File): void {
    if (FileService.isCsvFile(file)) {
      EventTracker.trackEvent('import_parents_by_csv', {})
    } else if (FileService.isExcelFile(file)) {
      EventTracker.trackEvent('import_parents_by_excel', {})
    }
  }
}
