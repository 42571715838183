<template>
  <div class="filter-summary-container">
    <div v-if="isClassExists" class="filter-summary" role="group" aria-labelledby="classLabel">
      <ContainedLabel id="classLabel" class="filter-summary-label" text="クラス" />
      <div class="filter-summary-text">
        {{ classFilterSummary
        }}<span v-if="showOtherLabel" class="ml-2 text-grey-darken-2 text-body-2"
          >(他{{ otherClassFiltersCount }}件選択中)</span
        >
      </div>
    </div>
    <div class="filter-summary" role="group" aria-labelledby="lineLabel">
      <ContainedLabel id="lineLabel" class="filter-summary-label" text="LINE登録" />
      <div class="filter-summary-text">{{ lineFilterSummary }}</div>
    </div>
    <div class="filter-summary" role="group" aria-labelledby="nameLabel">
      <ContainedLabel id="nameLabel" class="filter-summary-label" text="名前" />
      <div class="filter-summary-text">{{ nameFilterSummary }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import {
  ParentSearchConditionService,
  maxClassFilterSummaryCount,
} from 'pages/top_page/services/parent_search_condition_service'
import { TopPageStore } from 'pages/top_page/top_page_store'
import ContainedLabel from 'shared/components/label/ContainedLabel.vue'

const isClassExists = computed(() => TopPageStore.schoolClasses.length > 0)
const classFilterSummary = computed(() => ParentSearchConditionService.generateClassFilterSummary())
/** 7件以上選択されており、かつ全ては選択されていないときに表示 */
const showOtherLabel = computed(
  () =>
    TopPageStore.searchCondition.selectedClassIds.length > maxClassFilterSummaryCount &&
    !ParentSearchConditionService.isAllClassesSelectChecked()
)
/** クラスを他x件を選択中、という表示のxの値 */
const otherClassFiltersCount = computed(
  () => TopPageStore.searchCondition.selectedClassIds.length - maxClassFilterSummaryCount
)
const lineFilterSummary = computed(() => ParentSearchConditionService.getSelectedLineFilterLabel())
const nameFilterSummary = computed(() => {
  const nameFilterValue = TopPageStore.searchCondition.name
  if (nameFilterValue === '') return '指定なし'
  return nameFilterValue
})
</script>

<style scoped lang="scss">
.filter-summary-container {
  display: grid;
  grid-template-columns: 1fr max-content max-content;
  column-gap: 24px;
  align-items: center;
  margin-inline: 16px;
}

.filter-summary {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.filter-summary-label {
  font-weight: bold;
  background-color: #e2e2e2;
  font-size: 14px;
  margin-top: 2px; // 微調整
  flex-shrink: 0;
}

.filter-summary-text {
  font-size: 16px;
  font-weight: 500;
}
</style>
