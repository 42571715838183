import { HTTP } from '../common'
import { AxiosResponse } from 'axios'
import { Prefecture } from 'shared/models'

export type ShowResponse = {
  prefectures: Prefecture[]
}

export class PrefectureApi {
  static index(): Promise<AxiosResponse<ShowResponse>> {
    return HTTP.get('/prefectures')
  }
}
