<template>
  <LinePaymentResult v-bind="lineResultPageProps" />
</template>

<script setup lang="ts">
import { LinePaymentResult } from 'shared/components/line_payment'
import type { LinePaymentResultProps } from 'shared/components/line_payment/LinePaymentResult.vue'
import PaymentMethod from 'shared/consts/string/payment_method'

const props = defineProps<Omit<LinePaymentResultProps, 'paymentMethod'>>()
const lineResultPageProps = {
  ...props,
  paymentMethod: PaymentMethod.merpay,
}
</script>
