import { reactive } from 'vue'
import { ImportFileError } from 'pages/import_parents_page/model'

type ImportParentsPageState = {
  /** 取り込みボタン押下時のローディングフラグ */
  isLoading: boolean
  /** 重複時のダイアログ表示フラグ */
  isShowDuplicatedDialog: boolean
  /** アップロードされたファイル */
  importTargetFile: File[]
  /** ファイルアップロード時のエラー */
  importFileErrors: ImportFileError[][]
  /** ファイルアップロード時のエラー合計数 */
  importFileErrorsCount: number
  /** ファイル取り込み完了フラグ */
  isImportCompleted: boolean
}

const state = reactive<ImportParentsPageState>({
  isLoading: false,
  isShowDuplicatedDialog: false,
  importTargetFile: [],
  importFileErrors: [],
  importFileErrorsCount: 0,
  isImportCompleted: false,
})

/**
 * 保護者一括登録ページの状態を操作するメソッドを持つStore。
 */
export class ImportParentsPageStore {
  static get isLoading(): ImportParentsPageState['isLoading'] {
    return state.isLoading
  }

  static updateIsLoading(isLoading: ImportParentsPageState['isLoading']): void {
    state.isLoading = isLoading
  }

  static get isShowDuplicatedDialog(): ImportParentsPageState['isShowDuplicatedDialog'] {
    return state.isShowDuplicatedDialog
  }

  static updateIsShowDuplicatedDialog(
    isShowDuplicatedDialog: ImportParentsPageState['isShowDuplicatedDialog']
  ): void {
    state.isShowDuplicatedDialog = isShowDuplicatedDialog
  }

  static get importTargetFile(): File[] {
    return state.importTargetFile
  }

  static updateImportTargetFile(importTargetFile: File[]): void {
    state.importTargetFile = importTargetFile
  }

  static get importFileErrors(): ImportParentsPageState['importFileErrors'] {
    return state.importFileErrors
  }

  static updateImportFileErrors(
    importFileErrors: ImportParentsPageState['importFileErrors']
  ): void {
    state.importFileErrors = importFileErrors
  }

  static get importFileErrorsCount(): ImportParentsPageState['importFileErrorsCount'] {
    return state.importFileErrorsCount
  }

  static updateImportFileErrorsCount(
    importFileErrorsCount: ImportParentsPageState['importFileErrorsCount']
  ): void {
    state.importFileErrorsCount = importFileErrorsCount
  }

  static get isImportCompleted(): ImportParentsPageState['isImportCompleted'] {
    return state.isImportCompleted
  }

  static updateIsImportCompleted(
    isImportCompleted: ImportParentsPageState['isImportCompleted']
  ): void {
    state.isImportCompleted = isImportCompleted
  }
}
